<script setup>
</script>
<template>
<div class="choose-screen">
  <h1>TalentSage LLC Privacy Policy</h1>
<h3>Last Updated: 2023 October 01</h3>
<p>This is the Privacy Policy (Privacy & Cookies) for TalentSage LLC (a Delaware registered company; company number: 4343380). TS is a company registered in the United States (US) with its servers located in the United Kingdom (UK).
Please carefully read this policy and contact us with any questions. <strong>We accept your first use of Our Site(s) as acceptance of our Privacy Policy.  
Only persons age 18 or older have permission to access Our Site(s).</strong></p>
<h1>Introduction</h1>
<p>TalentSage, LLC (“TS”) is a people skills development and services provider that delivers a range of people development services (content, surveys, courses, diagnostic tools, activities, coaching, workshops, training programs, software, websites, mobile applications, and APIs), collectively referred to as “Services”, “Our Services”, to improve individual, team and organizational performance. We deliver Our Services globally to Clients ranging from organizations to universities, Users ranging from administrators and faculty to coaches and individuals. We support some of these Clients in original and academic research as well as the improvement of their diagnostic tools. Our Services are conducted both on- and off-line.<br>
To provide Our Services, we may collect and/or process your personal data on behalf of our Clients and Users. However, your personal data will remain on our servers in the UK and will not be transferred to US for processing. 
This Privacy Policy explains the personal data we collect, store and manage through the provision, management, administration, participation, or use of Our Services and our website domain (<a href="https://www.talentsage.com" target="_blank">TalentSage.com</a>) any subdomains (e.g. Clientname.TalentSage.com and (<a href="https://www.talentsage.com" target="_blank">www.TalentSage.com</a>)) (collectively referred to as “Our Site(s)”) related to our business as a provider of people skill development and research services.</p>
<h1>Data Privacy Framework Policy </h1>
<p>TS is deeply committed to respecting and protecting the privacy of all personal data collected and/or processed in delivery of Our Services and on Our Site(s). As such, TS acts in accordance with the EU-U.S. <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework (DPF)</a>, the UK Extension to the EU-US DPF, and the Swiss-US <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework </a>as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from European Union member countries (and Iceland, Liechtenstein, and Norway), the United Kingdom (including Gibraltar) and Swizterland transferred to the Unites States pursuant to the Data Privacy Framework. TalentSage has certified that it adheres to the <a href="https://www.dataprivacyframework.gov/s/" target="_blank">DPF Principles</a> with respect to such data. If there is any conflict between the policies in this privacy policy and data subject rights under the <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework Principles</a>, the <a href="https://www.dataprivacyframework.gov/s/" target="_blank">DPF Principles</a> shall govern. To learn more about the <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework Principles</a>, and to view our certification page, please visit <a href="https://www.dataprivacyframework.gov/s/" target="_blank">https://www.dataprivacyframework.gov/s/.</a><br>
TalentSage, LLC is subject to the investigatory and enforcement powers of the Federal Trade Commission of the United States of America.<br>
In the context of any onward transfer of personal data, TS is responsible for the processing of personal information it receives under the DPF and subsequently transfers to any 3rd party acting as an agent on its behalf. TS shall remain liable under the Principles if its agent processes such personal information in a manner inconsistent with the Principles, unless TS proves it is not responsible for the event giving rise to the damage. 

TS also takes privacy one step further and treats all as an extension of the European Union’s General Data Protection Regulations (EU) 2016/679 (<a href="https://ico.org.uk/" target="_blank">GDPR</a>) and any further regulations such as Schrems II.  For purposes of GDPR, TS may be a data controller or processor, depending on the individual client and the Services contracted. </p>
<h1>What does this policy cover?</h1>
<p>This Privacy Policy applies to each person’s use of Our Services and Our Site(s). It covers the use and collection of acts associated with collecting, handling, rendering, communicating and storing your personal data to deliver the Services contracted. It covers all TS employees and contractors working on behalf of TS as well as any associated services and/or data such as, but not limited to, the following: 
Personal Data – Data or information about you that enables you to be identified. Under GDPR, personal data can refer to information such as User’s full name, email / postal addresses, mobile and telephone numbers, identity documents (e.g. passports, drivers licenses), identity numbers (e.g. National Insurance/Social Security, bank accounts), career and educational documents (e.g. qualifications, CVs, recruiting and employment information), electronic location data or other online identifiers.  
User Accounts - Some of Our Services require to us to create an account for each User. Each User’s Account contains records, data and credentials used to access and/or use certain areas and features of Our Site(s) and Our Services.<br>  
Other sites – At times, Our Site(s) may contain links to other websites. We may also store personal data to support fulfilment of our business relationships and necessary activities in our CRM system. In the event Our Site(s) contains a link to or from another website, please note that any such websites are outside our control and are not covered by this Privacy Policy. <strong>Please see Section Storing, Transferring or Sharing Personal Data, 3. Sharing data with 3rd parties and Links to 3rd parties</strong> for more details. </p>
<h1>Personal data we collect</h1>
<p>In short, we only collect and use the minimum Personal Data we believe to be required to perform the Services required or contracted. Although you do not have to provide all the personal data requested, if we ask and you refuse, we may be limited in our ability to provide all the Services you are seeking from us.
Some, none, or all of the individuals for whom TS has received names and email addresses may be located in Europe. Accordingly, TS treats all names and email addresses as if they were in the UK or the European Union for data privacy purposes.
Due to the nature of Our Services, we may receive some of your personal data from any institution, organization, coach or consultant that requests Our Services on your behalf (Processor for purposes of GDPR). If you complete one of our diagnostic surveys, we will also ask for and collect diagnostic survey responses, coaching responses, assignments and/or other learning and development related content (Controller for purposes of GDPR).</p>
<p>Depending upon your use of Our Services and Our Site(s), we may receive or collect some, or all, of the following personal and non-personal data: User’s full name; Contact information including email address(es) and mobile phone number(s); Employer and title; Demographic information such as nationality, age, gender (special information); Survey responses; Business services interests; Other information when you report a problem on Our Site(s); A record of the correspondence, if you contact us; Details of visits to Our Site(s) and transactions through fulfillment of your orders; Information provided by completing forms or questionnaires including, without limitation to, 360 surveys, personality profiles, skill development profiles and coach notes as well as individual, group and organizational level data; Work completed before, during or after workshops or Other Services which could include, but not be limited to, examples of personal correspondence, videos, images, drawings, or other miscellaneous personal data for development purposes; and, Information if, and when, you enter a competition or promotion sponsored by us.
We limit additional data to only that which is necessary for your respective type of services:
</p>
<ol>
<li><u>Visitors to TalentSage Website(s)</u> - When you visit the public part of Our Site(s), we do not record your Personal Data. When you visit some sections of Our Site(s), you may be prompted for Personal Data to access or complete a purchase of Our Services by a 3rd Party integration. You will control Personal Data shared and your storage preferences directly with our 3rd party providers.</li>
<br>
<li><u>Users involved in Our Services</u> - In order to participate in Our Services and for us to manage privacy preferences, Users (Participants, Nominees, Respondents, Coaches, Faculty, or Administrators) must be recognizable via a registered Account. The following basic information is required to register an Account: first name, last name, email, and inviting organization if not registered by yourself. All Account holders have control over their personal data stored in “My Profile” on Our Site(s) or by writing to DPO@TalentSage.com.   </li>
<br>
<li><u>Users involved in TalentSage Surveys and Reports</u> – If your organization or coach invites you to participate (Participant) in a survey(s), they will provide TS with your first and last name and your email address to create your account and request participation in the survey(s) on your behalf. As a Participant, depending on your survey type, you may be asked for additional demographic data (gender, country, age). If you are a person nominated by the Participant to provide feedback (Nominee), the Participant will have provided your first and last name with your email address. On submission of your survey results, you become a Respondent. We collect and store the personal data in addition to survey response(s) in order to return a report(s) to the Participant per the relevant Services contracted. </li>
<br>
<li><u>Participants who participate in research</u> – As part of Our Services, we sometimes conduct and support institutions who conduct original scientific research in the field of organizational behavior, leadership and talent development. Since the organizations and people who participant in Our Services represent valuable sources of data for this research, the information collected from them is securely stored and may become part of a research study or project. TS is deeply committed to protecting the rights, privacy, and dignity of every person who participants in these activities and as such keep the following safeguards in place: </li>
  <ol style="list-style-type: lower-alpha;">
    <li>TS follows the American Psychological Association’s (APA) code of ethics and works with scholarly research institutions’ Independent Review Board (IRB) to ensure the safe and secure use of individuals’ personal data for research. These codes focus on the individual rights of the subjects of the research.</li>
    <li>All data used for research is obtained through informed consent, either by us or by our contracting research institution, and processed in accordance with APA and/or IRB, data security and confidentiality measures, respectively.</li>
    <li>We do not collect data regarding race or ethnicity.</li>
    <li>Information collected about or from a Participant is considered confidential. Participants control data access to their results.</li>
    <li>Assessment data collected about or from a Participant as part of an activity is shared with and explained to the Participant in accordance with the level of access permitted by the Participant and Services contracted.</li>
    <li>Participants may receive feedback on their specific results subject to the individual’s consent to share the data with a specified qualified coach or advisor.</li>
    <li>Any further disclosure or sharing (peer group, internal department) is controlled by the Participant and subject to the Participant’s consent.</li>
    <li>TS does not release Participant information without the Participant’s (or an authorized representative of the Participant) permission. However, in the extremely rare case of a court order, TS reserves this right if legally compelled to do so. See <b>Storing, Transferring or Sharing Personal Data, 3. Sharing data with 3rd parties</b> for more details.</li>
    <li>Data collected by TS is stored in TS secure database(s) and may become part of the thousands of records creating a large enough sample to form a diagnostic instrument’s norm group. In some cases these norm groups may also be restricted to a subset of records for purposes of other meaningful norming. In other cases, the results may be anonymized for use in system testing. In all of these cases, records are anonymized to prevent unintended recognition of individual Respondents.</li>
    <li>Participants who do not want their results to be included in the research or norming database(s) can remove their data by emailing <a href="mailto:DPO@TalentSage.com" target="_blank">DPO@TalentSage.com</a> with the specific data removal request. However, once the Personal Data is removed from the database(s), the Participant will no longer be able to access their report using the respective data or use that data in any group profiles. </li>
  </ol>
<li><u>Participants in TalentSage events, workshops, webinars, courses</u> - We may collect a Participant’s name, email address, telephone number, title and other information to keep you informed of information related to Our Services and to serving you better. In cases where we record these events, we inform Participants of any potential photography, collected comments and recordings at the beginning of such activities. Depending on the type of event (in-person, virtual) we invite Participants to declare their preference (opt-in, opt-out) based on the type of event.  </li>
<br>
<li><u>Users who opt to receive marketing communications</u> - We may collect your name, email address, title, company or other contact information to respect and return information based on your communication preferences.</li>
<br>
<li><u>Users who opt to purchase our services online</u>- We collect users’ contact information such as name and email address but Our Site(s) do not collect or store any individual bank account or credit card details. Our 3rd party integrations collect and maintain account and credit card information based on your instructions. You control any data required, provided and stored with our GDPR compliant 3rd party providers.</li>
<br>
<li><u>Customers, suppliers and other business contacts</u> - We may collect your name, email address, title, company, other contact information, account information and communications to deliver the requested services and respect your communication preferences.</li> 
</ol>
<p>To provide Our Services, we may collect and/or process your personal data on behalf of our Clients and Users. However, your personal data will remain on our servers in the UK and will not be transferred to US for processing.</p>
<h1>Children’s data</h1>
<p>We understand the importance of protecting children’s privacy. Our Services do not address anyone under the age of 18 (Children). Only persons age 18 or older have permission to access Our Site. We never knowingly collect private information about individuals under the age of 18. <strong>If you are a parent or guardian who has learned that your child has provided us with personal data, please contact us immediately on <a href="mailto:DPO@TalentSage.com" target="_blank">DPO@TalentSage.com</a></strong> If we become aware that we have collected personal data from a someone under 18, we will take steps to remove that person’s information from our servers, immediately.</p>
<h1>Using Your Personal Data</h1>
<p>Under GDPR, we must always have a lawful basis for using Personal Data. This may be because the data is necessary for our performance of a contract with you, because you have consented to our use of your Personal Data, or because it is in our legitimate business interests to use it. Under these conditions, your Personal Data may be used for the following purposes:</p>
<ol>
<li>Providing and managing your Account and your access to Our Site</li>
<li>Entering a Contract and supplying our Services to you</li>
<li>Personalizing and tailoring your experience on Our Site and our Services</li>
<li>Notifying you of changes and additions to Our Services that may affect your Account</li>
<li>Communicating with you, including responding to emails or calls from you</li>
<li>Supplying you with information by email that you have opted-in to and can unsubscribe / opt-out at any time</li>
<li>Analyzing your use of Our Site and Services and gathering feedback to enable us to continually improve Our Site performance and your experience</li>
<li>Producing development reports over time for your personal completion of both nominated and self-initiated development activities</li>
<li>Contacting you (around the scheduled expiry of your Personal Data) for your decision on your desire to anonymize or personally extend Our Services and available options for continuing access to your Personal Data and Account</li>
<li>Creating anonymized, normative databases to provide you with context for your professional development results and for research purposes</li>
</ol>
<p>We never sell your Personal Data nor will we send you any unlawful marketing or spam. We do not use your personal information for automated decision making or profiling.</p>
<p>We will always work to fully protect your rights and comply with our obligations under GDPR and the Privacy and Electronic Communications (EC Directive) Regulations 2003. We will always give you the opportunity to opt-out of marketing communications.</p>
<h1>Cookies Policy</h1>
<p>Cookies are small text files placed on your computer, phone, or other device(s) by the website you are visiting when you visit certain parts of and/or use certain features of the site. Cookies allow web applications to respond to you as an individual and tailor their operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
<p>Our Site(s) use the minimum number of Cookies we believe necessary to deliver a secure, personalized service.</p>
<p>In limited circumstances, we may have to use 3rd party Cookies to provide and improve your experience of Our Site(s) and Our Services. All Cookies used on Our Site(s) are used in accordance with current Cookie Law (relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003).</p>
<ol>
<li><strong>Strictly necessary</strong>: Certain features of Our Site(s) depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”.</li>
<li><strong>Not strictly necessary</strong>: Some Cookies are not required for the functioning of Our Site(s).  Cookie Law deems these Cookies to be “not strictly necessary”.</li>
</ol>
<p>We will not ask for your consent for “strictly necessary” but we may or may not ask before placing any “not strictly necessary” Cookies on your computer or device. Where you may choose, we rely on your acceptance to continue with use of Our Site(s). Where we do not, we consider your continued use of Our Site(s) as acceptance of our Cookie Policy and use of Cookies to deliver Our Services contracted. If you do not accept our Cookie Policy, you must stop use of Our Site(s) immediately.</p>
<p>By consenting, you are enabling us to provide the best possible experience and Services to you and for us to provide the Services contracted to be delivered to you. However, you are not required to consent. Please be aware that if you wish to deny consent, certain features of Our Site(s) may not function fully or as intended, including but not limited to login, survey completion and report availability, your specific User information, and personalization.</p>
<p>The following link shows any 1st or 3rd party “strictly necessary” or “not strictly necessary” Cookies in current use that may be placed on your computer or device.
  <br>
  <a href="https://www.talentsage.com/cookiebot-declaration" target="_blank">https://www.talentsage.com/cookiebot-declaration</a>
</p>
<h1>Storing, transferring, or sharing personal data</h1>
<p>We are deeply committed to ensuring the security of processing and the ongoing confidentiality, integrity, availability, and resilience of systems and services, that relate to Personal Data that we hold. We take multiple precautions to prevent accidental, or unlawful destruction, loss, alteration, or unauthorized disclosure or access. In our roles as both Controller and Processor, we implement both technical and operational measures to ensure a level of security.</p>
<ul>
  <li>We and our approved 3rd party providers encrypt all data at rest (e.g. databases) and in transit (e.g. communications).</li>
  <li>Our Services are delivered from secure servers located in the UK. We encrypt all passwords and authorizations stored for your protection.</li>
  <li>We do not transfer your personal data outside the UK unless you are participating as part of a research institution. In this case, your data may/may not require transfer outside the UK and your institution will ensure your informed consent if your data may be used as part of their research studies.</li>
  <li>Our staff, associates, and consultants are also bound to comply with confidentiality provisions as described in our Privacy Policy.</li>
</ul>
<p>We retain your Personal Data for up to three (3) calendar years from the point your Services were initiated for or by you, unless otherwise contracted or instructed by or for you. We hold your Personal Data for this length of time to best serve you, should you request further Services from us.</p>
<p>Depending on the Services contracted by or for you, we will write to you upon expiry of your Services contracted to ask if you wish to extend your Services with us. If you agree the terms of this continuation, we will maintain your Account and Personal Data under the new terms. If you do not agree to the terms for extending Our Services, we will either anonymize, delete or destroy the relevant Personal Data, depending on the type of data expiring.</p>
<p>In addition, if you have completed any of our surveys, regardless of your preferences, we will also anonymize and retain a copy of your survey responses no later than the three-year (3 yr) mark for systems testing and to empirically validate and further enhance the basis of our normative databases and ongoing research. We limit and control all exceptional forms of data transfer or sharing as follows: </p>
<ol>
  <li><b>Data transfer for processing/research</b> - Occasionally, our Services or circumstances may require we transfer Personal Data for processing or for research studies. In these instances, we will only transfer your Personal Data for processing to countries deemed to provide an adequate level of personal data protection. Examples include the US/EU and US/Switzerland Data Privacy Framework (DPF) or others who have agreed contractually to be bound by our privacy policies. If a data transferral case arises, we will maintain a data register to ensure your GDPR rights and only work with specific organizations and individuals who are also trained in our tailored GDPR-compliant research and data protection process. We anonymized data for transfer wherever possible. In cases where we cannot (e.g. some research), we add extra layers of security to protect Personal Data.</li>
  <li>
    <p><b>Transferring data to Users</b> - We’ve taken your data privacy one step further when it comes to sharing your reports with others. You are in control of your Personal Data and reports. We’ve designed Our Site(s), Our Services and Our Business Processes to remove the transferral of Personal Data, reports and others involved in delivering your reports.
    <br>If there is any educational or development benefit to sharing your data, we will ask you for your permission to share with your development advisor. We only provide a copy of your data to you (wherever you sign in) upon download or to a limited set of authorized development advisor(s) (wherever they sign in) on their download, if you have personally granted report viewing rights to them individually for the relevant purpose(s) and period of your development program. We limit all permissions granted to the end of your development program as communicated to us by you or your commissioning organization or coach.</p>
  </li>
  <li>
  <b>Sharing data with 3rd parties</b> - In principle, when selecting service partners we only contract suppliers or organizations who are <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework (DPF)</a> <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Program (DPF)</a> and <a href="https://ico.org.uk/" target="_blank">GDPR</a> compliant, who are headquartered and governed by governments who establish reciprocal laws and agreements in line with <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework (DPF)</a> <a href="https://www.dataprivacyframework.gov/s/" target="_blank">DPF</a> and <a href="https://ico.org.uk/" target="_blank">GDPR</a> (such as or who have contractually committed to their ability and willingness to be bound by <a href="https://www.dataprivacyframework.gov/s/" target="_blank">DPF</a> and <a href="https://ico.org.uk/" target="_blank">GDPR</a> compliant policies.<br>
  Even data shared for research purposes is anonymized and governed by our partner research institution’s IRB policies and ethical research guidelines and practices.<br>
  However, please note that we have no control over how your Personal Data is collected, stored, or used by other organizations, services or websites. As such, we advise you to check the privacy policies of any such website before providing any data to them.<br>
  We will not share any of your Personal Data with any other (non-data processing/research, non-User) 3rd parties for any purposes, except for legal requirements. In some limited circumstances, we may be legally required to share certain Personal Data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.
</li>
</ol>
<p>If you have any questions about the particular data protection mechanism used by us when transferring your personal data to another country, please contact us using the details in the <b>Contacting TalentSage LLC section</b> of this policy.</p>
<h1>Links to 3rd parties</h1>
<p>In the event Our Site(s) contains a link to or from another website, please note that any such websites are outside our control and are not covered by this Privacy Policy.
<br>
  We have no control over how your Personal Data is collected, stored, or used by other organizations, services or websites. As such, we advise you to check the privacy policies of any such website before providing any data to them.
<br>
  Once you leave Our Site(s) via any link, we will not be responsible or liable for any information or Personal Data you disclose.</p>
<h1>Your data rights</h1>
<p>We will always work to uphold your Personal Data protection rights. As part of these rights under <a href="https://www.dataprivacyframework.gov/s/" target="_blank">Data Privacy Framework (DPF)</a> and the UK extension of the European Union’s General Data Protection Regulations (EU) 2016/679 (<a href="https://ico.org.uk/" target="_blank">GDPR</a>)  you have the right to: </p>
<ol>
<li>Be informed about our collection and use of your Personal Data</li>
<li>Access the Personal Data we hold about you</li>
<li>Have your Personal Data we hold be corrected in cases of inaccuracy or incompleteness</li>
<li>Be forgotten (i.e. ask us to delete or dispose of any Personal Data we have of yours)</li>
<li>Restrict (i.e. prevent) the processing of your Personal Data</li>
<li>Data portability (ask for a copy of your Personal Data to re-use with another service or business as long as it is not onerous or commercially prohibitive)</li>
<li>Object to us using your Personal Data for a particular purpose(s)</li>
<li>Require your Personal Data to be processed using electronic means and limit parameters associated with automated decision-making and profiling, if any were to be used</li>
<li>Ensure we use your Personal Data with your consent or for the performance of a contract</li>
</ol>
<p>Based on these rights, you may ask us not to process your Personal Data for marketing purposes by checking the appropriate box on forms and communications we use to collect your Personal Data or by withdrawing your consent at any time, by contacting our Data Protection Officer on <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a> to erase details we hold of your Personal Data at any time. Please be aware, opting out may affect our ability to maintain the services we were contracted to provide to you. In these cases, you are also giving your permission to remove the services provided.
  <br>
  For information about exercising your rights as outlined above, please contact us using the details provided in the Contacting TalentSage LLC section of this policy. Further information about your rights can also be obtained from the Information Commissioner’s Office in the UK, the European Data Protection Board (EDBP) or your local Citizens Advice Bureau.
</p>
<h1>Correcting wrong or incomplete personal data</h1>
<p>We care about the integrity of your Personal Data too. Once you have signed into your account successfully (using your user ID and password), you will find basic Account editing tools to allow you to control your Personal Data.</p>
<p>Should you find any incorrect or incomplete Personal Data you are unable to edit on your own, please contact the relevant party to ensure your original data is corrected.</p>
<ol>
<li><strong>Your leadership development partner</strong> (school, company, coach or organization) if you are using Our Services as part of a Program, Coaching or other Development experience as they provided your Personal Data to us. This will ensure your information is corrected for good. <em>Please copy </em><a href="mailto:Help@TalentSage.com"><em>Help@TalentSage.com</em></a><em>on your email to inform us and help us follow up with your provider.</em></li>
<li><strong>Our Help Desk</strong> directly on <a href="www.TalentSage.com" target="_blank"><em>Help@TalentSage.com</em></a>if you provided this information to us directly (e.g. not registered by a leadership development partner), so we may correct or complete your information in cases where you are unabl to do so on your own.</li>
</ol>
<p>In the unlikely event that we have disclosed any of the affected personal data to 3rd parties, we will inform the relevant 3rd party of any changes that need to be made to the relevant Personal Data.</p>
<h1>Requesting access to your data</h1>
<p>In compliance with the DPF and GDPR principles, you have the right to access information or data we hold about you. If  You can access your Personal Data through the following steps:</p>
<ol>
<li><strong>Personal Data provided to us</strong> – Please contact your learning development partner, and/or</li>
<li><strong>Personal Data created or held by us</strong> - Contact us using the details provided in the Contacting TalentSage LLC section of this policy to request details of and/or a copy of your Personal Data held by us (where any such personal data is held). This is known as a “Subject Access Request” (SAR).</li>
</ol>
<p>All SARs should be made in writing and sent to our DPO by one of the following 2 addresses to the “Attention of the TalentSage Data Protection Officer”.</p>
<ol>
<li><strong>Email address:</strong>                   <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></li>
<li><strong>Postal Address:</strong>                  12911 Royal George Avenue, Odessa, FL 33556 USA </li>
</ol>
<p>Coon Rapids, Minnesota 55433-3862</p>
<p>We will respond to your SAR within one (1) calendar month from the date of your request or the date of any additional information we need from you to process your request. We will aim to provide a complete response, including a copy of your Personal Data within that time.</p>
<p>In some complex cases, however, more time may be required. In these cases, we commit to providing your information within three (3) calendar months from the date we receive your request or from the date of any additional information we need from you to process your request. You will be kept informed of our progress.</p>
<p>There is not any charge for a SAR, unless your request is deemed ‘unfounded or excessive’ (e.g. repetitive). In this case, we may require a fee to cover our administrative costs in responding and will let you know in writing before conducting and incurring such fee.</p>
<h1>California “shine the light” law</h1>
<p>California “Shine the Light Law” (Civil Coad Section 1798.83) permits users who are California residents to request and obtain a list from us of what personal information (if any) we disclosed to 3rd parties for their direct marketing purposes in the previous calendar year as well as the names and addresses of those 3rd parties. Requests are limited to once a year and can be made free of charge.</p>
<p>Under Section 1798,83, neither TalentSage LLC nor TalentSage UK Ltd share any personal information with 3rd parties for direct marketing purposes.</p>
<h1>Complaints</h1>
<p>In compliance with the DPF and GDPR Principles, we commit to resolving any questions or complaints about your data privacy and our collection, use or transfer of your personal data to the best of our ability, pursuant to DPF and GDPR. If you have a query or complaint about your data privacy and/or our collection, use or transfer of your personal data, you should first contact our DPO, Stuart Pembery as directed in the Contacting TalentSage LLC Section of this policy. We will do our best to respond to and rectify your concerns.
  <br>
  If your complaint remains unresolved, TalentSage, LLC has further committed to refer unresolved privacy complaints under the DPF and GDPR Principles to an independent dispute resolution mechanism, the Data Privacy Framework Services, operated by BBB National Programs. If you do not receive timely acknowledgement of your complaint or if your complaint is not satisfactorily addressed, please visit <a href="https://bbbprograms.org/programs/all-programs/dpf-consumers/ProcessForConsumers" target="_blank">https://bbbprograms.org/programs/all-programs/dpf-consumers/ProcessForConsumers</a> for more information and to file a complaint. This service is provided free of charge to you.
  <br>
  If your DPF complaint cannot be resolved through the above channels, under certain conditions, you may invoke binding artibtraition for some residual claims not resolved by other redress mechanisms. For information on arbitration procedures, see <a href="https://www.dataprivacyframework.gov/s/article/G-Arbitration-Procedures-dpf?tabset-35584=2" target="_blank">https://www.dataprivacyframework.gov/s/article/G-Arbitration-Procedures-dpf?tabset-35584=2</a>.
  <br>
  In the case of European Union, UK and Swiss individuals with unresolved GDPR inquiries or complaints, you should
</p>
<h1>Governing law</h1>
<p>Without prejudice to the application of UK or European data protection laws and regulations, the laws of the State of Florida and as applicable, those of the United States of America govern the use of TalentSage LLC’s and TalentSage UK Ltd’s services.
 <br>
 All claims brought in the United States of America will be subject to the jurisdiction of the courts of the State of Florida without prejudice to your right to lodge a complaint with a competent data protection authority or with a competent court in the UK or European Union for data protection related matters.
</p>
<h1>Contacting TalentSage LLC</h1>
<p>Questions, comments or requests regarding this Privacy Policy are welcomed. To contact us about anything related to your personal data and data protection, including Data Subject Access Requests (SAR),</p>
<ol>
<li>Please use the following details</li>
<ol>
<li>Data Protection Officer: Stuart Pembery</li>
<li>Email address: <a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></li>
<li>Phone: +1 (628) 236-9135</li>
<li>Postal Address: 12911 Royal George Avenue, Odessa, FL 33556 USA</li>
</ol>
<li>Must include <strong>“For the attention of Data Protection Officer”</strong> in the subject of your correspondence</li>
</ol>
<h1>Changes to Our Privacy Policy</h1>
<p>We may change this Privacy Policy from time to time in cases where the law changes or where we change our business in a way that affects how we work with Personal Data. In such cases, any changes will be posted as soon as is practicable on this page in Our Site(s). <b>Because we cannot guarantee our communications reach you, it is your responsibility to check Our Site(s) periodically for any updates. For these reasons, we recommend you check this page regularly to stay current.</b>
<br>
The most recent date of any revisions will always be listed in the heading of this page in Our Site(s) for your convenience.
<br>
First use of Our Site(s)following any alterations will be deemed as indication of your acceptance of the revised terms of our Privacy Policy. 
<br>
A significant amount of time may also pass between your use(s) of Our Site(s). Occasionally, we’ll ask you to reconfirm your understanding of this policy to allow you to change your mind as your needs change. If you do not accept our Privacy Policy terms, you must stop using Our Site(s) immediately.
</p>
<h1>Information about TalentSage LLC</h1>
<p>Our Site(s) and Our Services are owned and operated by TalentSage LLC, a limited liability company incorporated in the state of Delaware, United States of America. Registered address 12911 Royal George Avenue, Odessa, FL 33556 USA</p>
<table width="601">
<tbody>
<tr>
<td width="189">
<p>Registered address</p>
</td>
<td width="412">
<p>12911 Royal George Avenue, Odessa, FL 33556 USA</p>
</td>
</tr>
<tr>
<td width="189">
<p>Commercial registration #</p>
</td>
<td width="412">
<p>4343380</p>
</td>
</tr>
<tr>
<td width="189">
<p>Data Protection Officer</p>
</td>
<td width="412">
<p>Stuart Pembery</p>
</td>
</tr>
<tr>
<td width="189">
<p>Email address</p>
</td>
<td width="412">
<p><a href="mailto:DPO@TalentSage.com">DPO@TalentSage.com</a></p>
</td>
</tr>
</tbody>
</table>
<h1>Resolving complaints</h1>
<p>Our Site(s) and Our Services are owned and operated by TalentSage LLC, a limited liability company incorporated in the state of Delaware, United States of America.</p>
</div>
</template>

<script>
export default {
  name: "ForgotPasswordChoose",
  data: () => ({}),
  methods: {},
  mounted(){},
};
</script>

<style scoped>
.choose-screen {
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 20px;
  padding:20px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
  box-shadow: 0px 2px 10px -4px #000000;
  color: #0E5071
}
.btn {
  background-color: #e67829;
  width: 100%;
  color: #fff;
  fill: #fff;
  font-size: 100%;
  line-height: 1.15;
  font-weight: 500;
  margin: 6px 0;
  border: 0;
  outline: 0;
  border-radius: 2px;
  cursor: pointer;
  letter-spacing: 0.03em;
  padding: 10px 26px;
  box-shadow: 0 0 2px rgb(0 0 0 / 12%), 0 2px 2px rgb(0 0 0 / 20%);
  transition: all 0.2s ease-in-out;
  margin-top: 5px;
  margin-bottom: 10px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #0E5071
}

h3 {
  color: #0E5071
}
.description {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #0E5071
}
table {
  border: 1px solid;
}
td {
  border: 1px solid;
}
td {
  padding: 10px;
}
p{
  color:#0E5071
}
h1{
  color: #0E5071
}
ol>li{
  color: #0E5071  
}
</style>
