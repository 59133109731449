<template>
  <div class="box">
    <div class="modal-overlay" @click="$emit('close-modal')">
      <div class="modal" @click.stop>
        <!-- Use v-html directive to render the email body as HTML -->
        <div v-html="email_body" class="email-content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailSentTableModal",
  props: ["emailBody"],
  data: () => ({
    email_body: "",
  }),
  mounted() {
    this.email_body = this.emailBody;
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  width: 800px; /* Increased width for broader email content view */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
}

.email-content {
  text-align: left;
}

</style>