<script setup>
import ButtonSubmit from "./ButtonSubmit.vue";
import CryptoJS from "crypto-js";
</script>

<template>
  <div>
      <!-- <iframe
        :src="`${report_link}?sex=${decryptedData.gender}&country=${decryptedData.country}&viewer_fullname=${viewer_fullname}&report_for_fullname=${decryptedData.full_name}&survey_assignment_id=${decryptedData.survey_assignment_id}&org_id=${decryptedData.org_id}&subOrg_id=${decryptedData.suborg_id}&program_id=${decryptedData.program_id}&iteration_id=${decryptedData.iteration_id}&survey_template_id=${decryptedData.survey_template_id}&coach_id=${decryptedData.coach_id}&rand=${rand}&org_name=${decryptedData.org_name}&suborg_name=${decryptedData.suborg_name}&program_name=${decryptedData.program_name}&iteration_name=${decryptedData.iteration_name}&qsort_type=${qsort_type}&is_pdf_available=${decryptedData.is_pdf_available}`"
        frameborder="0"
        id="myiframe"
        name="myiframe"
      ></iframe> -->
      <object
        :data="`${report_link}?sex=${decryptedData.gender}&country=${decryptedData.country}&viewer_fullname=${viewer_fullname}&report_for_fullname=${decryptedData.full_name}&survey_assignment_id=${decryptedData.survey_assignment_id}&org_id=${decryptedData.org_id}&subOrg_id=${decryptedData.suborg_id}&program_id=${decryptedData.program_id}&iteration_id=${decryptedData.iteration_id}&survey_template_id=${decryptedData.survey_template_id}&coach_id=${decryptedData.coach_id}&rand=${rand}&qsort_type=${qsort_type}&is_pdf_available=${decryptedData.is_pdf_available}&stream_id=${decryptedData.stream_id}&group_id=${decryptedData.group_id}&org_name=${decryptedData.org_name}&suborg_name=${decryptedData.suborg_name}&program_name=${decryptedData.program_name}&iteration_name=${decryptedData.iteration_name}`"
        type="text/html">
        Alternative Content
      </object>
  </div>
</template>

<script>
export default {
  component: [ButtonSubmit],
  data: () => ({
    passedRouteParams: {},
    rand: '',
    decryptedData: {},
    qsortBEP: "BEP",
    qsortGDP: "GDP",
    qsortOCM: "OCM",
    qsort_type: "",
    brandData: "",
    userData: "",
    report_link:"",
    viewer_fullname: ""
  }),
  async mounted() {
    console.log('details :', this.$route.query)
    this.passedRouteParams = this.$route.query.reportData
    this.brandData = JSON.parse(this.$route.query.brandData)
    this.userData = JSON.parse(this.$route.query.userData)
    this.decryptedData = JSON.parse(this.decrypt(this.passedRouteParams))
    this.rand = Math.random()
    console.log('from my report',this.decryptedData);
    this.report_link = this.brandData.website_url + this.decryptedData.report_file
    console.log('report link', this.report_link)
    this.viewer_fullname = this.userData.first_name + ' ' + this.userData.last_name
    if(this.$route.query.QsortBEP){
      console.log('this.$route.query.QsortBEP', this.$route.query.QsortBEP)
      this.qsort_type = "BEP"
    }

    if(this.$route.query.QsortOCM){
      this.qsort_type = "OCM"
    }

    if(this.$route.query.QsortGDP){
      this.qsort_type = "GDP"
    }
  },
  methods: {
    decrypt(src) {
        let passphrase = "rptData"
      const bytes = CryptoJS.AES.decrypt(src, passphrase);
      return bytes.toString(CryptoJS.enc.Utf8);
    },
    downloadPDF(){
      this.printFrame("myiframe")
    },
    printFrame(id) {
      var frm = document.getElementById(id).contentWindow;
      frm.focus();// focus on contentWindow is needed on some ie versions
      frm.print();
      return false;
  }
  }
  ,
};
</script>

<style scoped>
object {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  margin: 5px 0;
}
</style>
