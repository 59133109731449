<script setup>
    import ButtonSubmit from "./ButtonSubmit.vue";
    import DescriptionInline from "./DescriptionInline.vue";
    import Swal from "sweetalert2";
    // import api from '../api/api'
    </script>
      <template>
      <div class="body-container">
        <section id="surveytable">
          <h2>Survey Table</h2>
          <table id="table">
            <tr>
              <th>Type</th>
              <th>QNo</th>
              <th>Text</th>
              <th>Template</th>
              <th>Add/Delete</th>
            </tr>
            <tr
              v-for="(data, index) in surveyData"
              :key="index"
              style="cursor: pointer"
            >
              <td>
                <textarea
                  rows="4"
                  :title="data.Type"
                  class="type"
                  v-model="data.Type"
                ></textarea>
              </td>
              <td>
                <textarea
                  rows="4"
                  :title="data.QuestionNumber"
                  class="qno"
                  v-model="data.QuestionNumber"
                ></textarea>
              </td>
              <td>
                <textarea
                  rows="4"
                  cols="50"
                  :title="data.Text"
                  class="text"
                  v-model="data.Text"
                ></textarea>
              </td>
              <td>
                <textarea
                  rows="4"
                  :title="data.Template"
                  class="template"
                  v-model="data.Template"
                ></textarea>
                <div
                  v-if="data.Tag != 0 && typeof data.Tag != 'undefined'"
                  class="tag-container"
                >
                  Tag ID :
                  <input
                    type="text"
                    title="Coach = 20, Coach Group = 4, HR = 19"
                    v-model="data.Tag"
                    class="tag"
                    size="4"
                    style="margin-left: 5px"
                  />
                </div>
              </td>
              <td>
                <button class="btns" @click="addRow(index)">
                  Add <i class="fa-solid fa-plus"></i>
                </button>
                <!-- <button style="margin-left: 10px;" @click="Update(data.Type, data.QuestionNumber, data.Text, data.Template, i)">Edit <font-awesome-icon icon="pencil" /></button> -->
                <button class="btns" @click="Delete(index)">
                  Delete
                  <font-awesome-icon icon="trash" />
                </button>
              </td>
            </tr>
          </table>
          <input type="file" @change="previewFiles" multiple />
          <ButtonSubmit
            style="margin-top: 10px"
            @click.prevent="displayJSON()"
            label="Create JSON"
          />
          <ButtonSubmit
            style="margin-top: 10px; margin-left: 10px"
            @click.prevent="downloadCSV()"
            label="Save Survey"
          />
          <ButtonSubmit
            style="margin-top: 10px; margin-left: 10px"
            @click.prevent="gotoTop()"
            label="Scroll Up"
          />
        </section>
        <hr />
        <h2>JSON Display</h2>
        <section id="displayJSON">
          <textarea class="display" v-model="displaysurveyData"></textarea>
          <ButtonSubmit
            style="margin-left: 20px"
            @click.prevent="generateHTML()"
            label="Create HTML"
          />
        </section>
        <section id="displayHtml">
          <textarea class="display" :value="finalHTML"></textarea>
        </section>
      </div>
    </template>
      <script>
        export default {
          components: [ButtonSubmit, DescriptionInline],
          name: "CreateSurvey",
          data: () => ({
            surveyData: [{
              Type: "",
              QuestionNumber: "",
              Text: "",
              Template: "",
              Tag: 0,
            }, ],
            displaysurveyData: "",
            file: null,
            csvFile: null,
            parsedData: [],
            finalHTML: "",
          }),
          methods: {
            mounted() {},
            updated() {},
            downloadCSV() {
              const items = this.surveyData;
              const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
              const header = Object.keys(items[0]);
              const csv = [
                header.join("~"), // header row first
                ...items.map((row) => header.map((fieldName) => row[fieldName], replacer).join("~")),
              ].join("\r\n");
              console.log(csv);
              var hiddenElement = document.createElement("a");
              hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
              hiddenElement.target = "_blank";
              hiddenElement.download = "output.csv";
              hiddenElement.click();
            },
            previewFiles(event) {
              const reader = new FileReader();
              reader.readAsText(event.target.files[0]);
              reader.onload = () => {
                const text = reader.result;
                this.csvFile = text;
                let lines = [];
                const linesArray = this.csvFile.split(/\r\n|\n|\r/);
                // for trimming and deleting extra space
                linesArray.forEach((e) => {
                  const row = e.replace(/[\s]+[~]+|[~]+[\s]+/g, "~").trim();
                  // const row = e.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                  lines.push(row);
                });
                // for removing empty record
                const headers = lines[0].split("~");
                for (let i = 1; i < lines.length; i++) {
                  const obj = {};
                  const currentline = lines[i].split("~");
                  for (let j = 0; j <= headers.length; j++) {
                    obj[headers[j]] = currentline[j];
                  }
                  this.parsedData.push(obj);
                }
                this.surveyData = this.parsedData;
              };
            },
            Delete(index) {
              Swal.fire({
                text: `Are you sure you want to delete?`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  if (index > 0) {
                    this.surveyData.splice(index, 1); // 2nd parameter means remove one item only
                    this.$flashMessage.show({
                      type: "success",
                      title: `Row has been deleted!`,
                      message: "",
                    });
                  }
                }
              });
            },
            clearJSON() {
              (this.displaysurveyData = ""), (this.surveyData = []);
              this.$flashMessage.show({
                type: "error",
                title: "JSON cleared",
                message: "",
              });
            },
            displayJSON() {
              if (this.surveyData.length == 0) {
                this.$flashMessage.show({
                  type: "error",
                  title: "No data to parse!",
                  message: "",
                });
              } else {
                this.displaysurveyData = JSON.stringify(this.surveyData, null, 2);
                const el = document.getElementById("displayJSON");
                el.scrollIntoView({
                  behavior: "smooth"
                });
              }
            },
            gotoTop() {
              const el = document.getElementById("surveytable");
              el.scrollIntoView({
                behavior: "smooth"
              });
            },
            addRow(index) {
                const surveyData = {
                  Type: "",
                  QuestionNumber: "",
                  Text: "",
                  Template: "",
                  Tag: null,
                };
                this.surveyData.splice((index + 1), 0, surveyData);
                this.$flashMessage.show({
                  type: "success",
                  title: "Row added successfully!",
                  message: "",
                });
            },
            generateHTML() {
              let input = JSON.parse(this.displaysurveyData)
              let ids = []
              let displayCounter = 1
              let resolveCounter = 1
              let buttonCounter = 1
              let openCounter = 1
              let output = `
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
              <meta http-equiv="X-UA-Compatible" content="IE=edge" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <title></title>
              <style>
                  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap');
                  .main-container{
                    display: flex;
                    justify-content: center;
                    margin: 20px 100px;
                    font-size: 14px;
                    font-family: 'Open Sans', sans-serif;
                  }
                  .sub-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  }
                  .card-box{
                    border-top: 4px solid rgb(0, 67, 94);
                    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                    margin-bottom: 30px;
                    padding: 10px 20px;
                  }
                  .question-box{
                    border-top: 4px solid rgb(0, 67, 94);
                    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                    margin-bottom: 30px;
                    padding: 10px 20px;
                  }
                  .reorder-box{
                    border-top: 4px solid rgb(0, 67, 94);
                    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                    margin-bottom: 30px;
                    padding: 10px 20px;
                  }
                  .reorder-box:nth-of-type(54), 
                  .reorder-box:nth-of-type(55),
                  .reorder-box:nth-of-type(56),
                  .reorder-box:nth-of-type(57),
                  .reorder-box:nth-of-type(58) {
                    display: none;
                  }
                  .question-field {
                    font-weight: bold;
                  }
                  .free-text-question {
                    font-weight: bold;
                  }
                  .demographic-quesiton {
                    font-weight: bold;
                  }
                  .sharing-option{
                    font-weight: bold;
                    margin-bottom: 16px
                  }
                  .sharing-option p {
                    display:inline;
                  }
                  .button-section {
                    display: flex;
                    justify-content: space-around;
                    text-align: center;
                  }
                  .form-select, .form-control {
                    box-shadow: none !important;
                  }
                  .btn.btn-outline-secondary {
                    font-size: 14px;
                    font-weight: 600;
                    border: 2px solid #999;
                    border-radius: 10px;
                    box-shadow: none !important;
                  }
                  .btn.btn-outline-secondary:hover {
                    color: rgb(25, 25, 25);
                    background-color: rgb(210, 210, 210);
                  }
                  .btn-check:checked + .btn-outline-secondary {
                    color: rgb(25, 25, 25);
                    background-color: rgb(168, 168, 168);
                  }
                  @media (max-width: 875px) {
                    .button-section {
                      display: flex;
                      flex-direction: column;
                      text-align: center;
                    }
                    .btn.btn-outline-secondary{
                      width: 100%;
                    }
                  }
                  .reOrder-mainContainer {
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0;
                  }
                  .item {
                    border: 1px solid #ddd;
                    color: #34444f;
                    cursor: pointer;
                    position: relative;
                    user-select: none;
                    padding: 10px;
                    background-color: #0036e711;
                    touch-action: none;
                  }
                  .item.onDrag {
                    opacity: 1;
                    background-color: #f5f5f5;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                    background: transparent;
                    color: transparent;
                    border: 1px solid #4ca1af;
                  }
                  .item:last-child {
                    display: hidden;
                    line-height: 0;
                    height: 0;
                    overflow: hidden;
                    touch-action: none;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
                    background-color: white;
                    border: none;
                  }
                  .item:last-child::before {
                    border:none;
                    height:0;
                  }
                  .itemClip {
                    position: absolute;
                    background-color: white;
                    opacity: 1;
                    top: 0;
                    left: 0;
                    transform: translate(-50%, -50%);
                    transition: none;
                    background-color: white;
                  }
                  .hide {
                    display: none;
                  }
                  .textarea {
                    width: 350px;
                    height: 200px;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    display: relative;
                  }
                  .reOrder-subContainer {
                    display: flex;
                    height: auto;
                    padding: 15px;
                    gap: 5px;
                  }
                  .rank-no {
                    border: 1px solid #ddd;
                    text-align: center;
                    color: #333;
                    position: relative;
                    transition: all 0.3s;
                    user-select: none;
                    padding: 10px;
                  }
                  .unfocus {
                    color: #6c757d !important;
                    background-color: #ffff !important;
                    border-color: #999 !important;
                  }
                  .focus {
                    color: #ffff;
                    background-color: #6c757d;
                  }
                  .invalid-question {
                    color: #dc3545;
                  }
                  .valid-question {
                    color: #212529
                  }
                  .accordion{
                    margin: 20px 0;
                  }
                  .accordion-item{
                    border: none !important;
                  }
                  .accordion-button.collapsed{
                    background-color: aliceblue;
                    border-radius: 0 !important;
                  }
                  .accordion-button:not(.collapsed){
                    color: black !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-color: rgba(0,0,0,.125);
                  }
                  .accordion-button:focus {
                    box-shadow: none !important;
                    outline: none !important;
                    border-color: rgba(0,0,0,.125);
                  }
                  .section-number{
                    background-color: orange;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 56px;
                    font-size: 20px;
                    color: white;
                  }
                  .accordion-header{
                    display: flex;
                    align-items: center;
                    height: 56px;
                  }
                  .report-for-name{
                    margin-left: auto;
                    margin-right: 10px
                  }
                  .accordion-button::after{
                    margin: 0 !important;
                  }
                  /* Charts styles  */
                  .chart-container{
                    margin: auto;
                    margin-bottom: 40px;
                    margin-top: 40px;
                    font-weight: bold;
                    width: 40%;
                  }
                  .line-chart-container{
                    margin-left: auto;
                    margin-right: auto;
                    width: 60%;
                  }
                  .new-line-chart-container{
                    width: 100%;
                  }
                  .subtrait-chart-container{
                    margin-left: auto;
                    margin-right: auto;
                    width: 60%;
                  }
                  table {
                    display: table;
                    border-collapse: separate;
                    box-sizing: border-box;
                    text-indent: initial;
                    border-spacing: 2px;
                    border-color: grey;
                    margin-bottom: 20px;
                  }
                  table > tr {
                    vertical-align: middle;
                    display: table-row;
                    vertical-align: inherit;
                    border-color: inherit;
                  }
                  td {
                    display: table-cell;
                    vertical-align: inherit;
                  }
                  table, table.main-table {
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    border-top: 0 solid #e0e0e0;
                  }
                  td._data-heading, th.heading-label {
                    border-top: 1px solid #e0e0e0;
                    padding: 8px 16px;
                  }
                  .heading-label div{
                    display: flex;
                    align-items: center;
                  }
                  table, table.main-table {
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    border-top: 0 solid #e0e0e0;
                  }
                  table, table.main-table-faculty {
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    border-top: 0 solid #e0e0e0;
                  }
                  table, table.main-table-faculty {
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    border-top: 0 solid #e0e0e0;
                  }
                  div.bar-container {
                    display: flex;
                    justify-content: space-between;
                    min-width: 200px;
                  }
                  div.bar-container>div.bar-difference {
                    height: 0.7em;
                    margin: 1px 0;
                    min-width: 1px;
                    flex-grow: 57;
                    background-color: rgb(177, 151, 252);
                  }
                  .subtrait-box{
                    padding: 10px;
                    margin: 20px 0;
                    border-radius: 5px;
                    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
                  }            
                  .subtrait-box-vfp{
                    padding: 10px;
                    border-radius: 5px;
                    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
                  }     
                  .chart-container-vfp {
                    margin: auto;
                    margin-bottom: 40px;
                    margin-top: 40px;
                    font-weight: bold;
                    width: 50%;
                  }       
      
                  .input {
                    outline: 0;
                    border-width: 0 0 1px;
                    border-color: grey;
                    padding: 2px 5px;
                    margin: 10px 0px;
                    background: none;
                  }
                  .formBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin: 1em 0 10px;
                    width: 370px;
                  }
                  .btn-submit {
                    background-color: #e67829 !important;
                    color: #fff !important;
                    appearance: none;
                    border: 1px solid rgba(27, 31, 35, 0.15);
                    border-radius: 5px;
                    cursor: pointer;
                    padding: 6px 16px;
                  }
                  .btn-cancel {
                    background-color: #dbdbdb !important;
                    color: #000 !important;
                    appearance: none;
                    border: 1px solid rgba(27, 31, 35, 0.15);
                    border-radius: 5px;
                    cursor: pointer;
                    padding: 6px 16px;
                  }
                  .note-edit-mark__a {
                    color: #4594a8;
                    cursor: pointer;
                  }
                  /* adjust norm  */
                  .adjust-norm-container {
                    display: flex;
                    flex-direction: column;
                  }
                  .adjust-norm-box {
                    background: white;
                    margin: auto;
                    padding: 1px 30px 30px 30px;
                    align-self: center;
                    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
                    border-radius: 5px;
                  }
                  .adjust-norm-box__gender {
                    margin-bottom: 2px;
                  }
                  .adjust-norm-box__culture {
                    margin-top: 0;
                  }
                  .adjust-norm-box__gender--span, .adjust-norm-box__culture--span {
                    text-transform: capitalize;
                    font-weight: bold;
                    color: rgb(111, 140, 31);
                  }  /* end of adjust norm  */
      
                  /* Note  */
                  .note-box {
                    background: white;
                    margin: auto;
                    padding: 1px 30px 30px 30px;
                    align-self: center;
                    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
                    border-radius: 5px;
                  }
                  .note-box__textarea1, .note-box__textarea2 {
                    width: 100%;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                    resize: none;
                    box-sizing: border-box;
                    min-height: 50px;
                  }
                  .note-share-status {
                    color: rgb(102, 102, 102);
                  }
                  /* End of Note  */
                  .norm-wording{
                    text-align: left;
                  }
      
                  /*360 report*/
                  .table360{
                    display: table;
                    border-spacing: 2px;
                    border-color: grey;
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    font-family: sans-serif;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 80%;
                  }
                  .table-coach-TIPpingPoint-role-results{
                    display: table;
                    font-family: sans-serif;
                    border: solid black 3px;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 100%;
                  }
                  .table-coach-TIPpingpoint-overall-results{
                    display: table;
                    font-family: sans-serif;
                    border: solid black 3px;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 100%;
                  }
                  .coach-TIP-heading>th{
                    text-align: center;
                    border: solid black 3px;
                    padding: 10px;
                  }
                  .coach-TIP-items>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                    width: 120px;
                  }
                  .coach-TIP-person0>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-TIP-person1>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-TIP-person2>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-TIP-person3>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-TIP-person4>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-TIP-person5>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .table-coach-pressurepoint-link-chart{
                    display: table;
                    border-spacing: 2px;
                    border-color: grey;
                    border: 3px solid black;
                    border-collapse: collapse;
                    font-family: sans-serif;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 80%;
                  }
                  .pressurepoint-link-chart-title>th{
                    width: 20px;
                    border: 3px solid black;
                    text-align: center;
                    padding: 10px;
                    color: white;
                    background-color:#F47820;
                  }
                  .pressurepoint-link-chart-items>td{
                    width: 20px;
                    height: 50px;
                    border: 3px solid black;
                    text-align: center;
                    padding: 10px;
                  }
                  .table-coach-pressurepoint-role-results{
                    display: table;
                    font-family: sans-serif;
                    border: solid black 3px;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 100%;
                  }
                  .coach-PPP-heading>th{
                    text-align: center;
                    border: solid black 3px;
                    padding: 10px;
                  }
                  .coach-PPP-person0>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-PPP-person1>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-PPP-person2>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-PPP-person3>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-PPP-person4>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .coach-PPP-person5>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                  }
                  .table-faculty-TIPpingpoint-overall-results{
                    display: table;
                    font-family: sans-serif;
                    border: solid black 3px;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 100%;
                  }
                  .table-faculty-pressurepoint-link-chart{
                    display: table;
                    border-spacing: 2px;
                    border-color: grey;
                    border: 3px solid black;
                    border-collapse: collapse;
                    font-family: sans-serif;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 80%;
                  }
                  .faculty-TIP-items>td{
                    text-align: center;
                    border: solid black 1px;
                    padding: 10px;
                    width: 120px;
                  }
                  .faculty-TIP-heading>th{
                    text-align: center;
                    border: solid black 3px;
                    padding: 10px;
                  }
                  .table360-competency{
                    display: table;
                    border-spacing: 2px;
                    border-color: grey;
                    border: 1px solid #e0e0e0;
                    border-collapse: collapse;
                    font-family: sans-serif;
                    margin: auto;
                    margin-bottom: 10px;
                    width: 80%;
                  }
      
                  .table360>tr {
                    vertical-align: middle;
                    display: table-row;
                    vertical-align: inherit;
                  }
      
                  .table360>td {
                    display: table-cell;
                    vertical-align: inherit;
                  }
                  .break-column {
                    flex-basis: 100%;
                    width: 0;
                  }
                  .heading-text-barchart1{
                    font-weight: bold;
                    background-color: #F8F8F8;
                    border-top: 1px solid black;
                    border-bottom: 1px solid #e0e0e0;
                    min-width: 340px;
                    padding-left: 10px;
                  }
      
                  .heading-score-barchart1 {
                    border-top: 1px solid black;
                    font-weight: bold;
                    text-align: center;
                  }
      
                  .heading-Histogram-barchart1-barchart1 {
                    border-top: 1px solid black;
                    font-weight: bold;
                    text-align: center;
                  }
      
                  .heading-bar-barchart1 {
                    background-color: #F8F8F8;
                    border-top: 1px solid black;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-bar-barchart1 {
                    border-top: 1px solid #e0e0e0;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                    background-color: #F8F8F8;
                  }
      
                  .sub-heading-text-barchart1{
                    padding-right: 0;
                    font-size: small;
                    background-color: #F8F8F8;
                    padding-left: 15px;
                    border-top: 1px solid #e0e0e0;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-score-barchart1 {
                    border-top: 1px solid #e0e0e0;
                    text-align: center;
                  }
      
                  .sub-heading-Histogram-barchart1-barchart1 {
                    border-top: 1px solid #e0e0e0;
                    text-align: center;
                  }
      
                  /* Bar chart */
                  .bar-container-barchart1 {
                    display: flex;
                    min-width: 200px;
                    flex-flow: row wrap;
                    justify-content: space-around;
                  }
      
                  .bar-container-barchart1>div.bar-difference {
                    height: 0.7em;
                    margin: 1px 0;
                    min-width: 1px;
                  }
      
                  @keyframes bar-container-barchart1-animation {
                    0% {
                      flex-grow: 0;
                      opacity: 1;
                    }
      
                    100% {
                      opacity: 1;
                    }
                  }
      
                  .bar-container-barchart1>div.bar-difference {
                    animation-delay: 0.8s;
                  }
      
                  .bar-container-barchart1>div {
                    animation-delay: 0.9s;
                  }
      
                  /* Histogram-barchart1 */
                  .Histogram-barchart1 {
                    padding: 0;
                    margin: 0;
                    height: 25px;
                    display: flex;
                    list-style: none;
                    align-items: flex-end;
                    justify-content: center;
                    border-bottom: 1px solid white;
                  }
      
                  @keyframes Histogram-barchart1-animation {
                    0% {
                      height: 0;
                      opacity: 1;
                    }
      
                    100% {
                      opacity: 1;
                    }
                  }
      
                  .Histogram-barchart1>li {
                    display: inline-block;
                    background-color: currentColor;
                    width: 20px;
                    margin-left: 3px;
                    padding: 0;
                    opacity: 0;
                    animation: Histogram-barchart1-animation 0.2s ease forwards;
                    box-sizing: border-box;
                    border: 1px solid rgba(0, 0, 0, 0.4);
                  }
      
                  .Histogram-barchart1>li:nth-child(1) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart1>li:nth-child(2) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart1>li:nth-child(3) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart1>li:nth-child(4) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart1>li:nth-child(5) {
                    animation-delay: 0.2s;
                  }
      
                  .heading-label-barchart2 {
                    border-top: 1px solid white;
                    border-left: 1px solid white;
                    border-right: 1px solid white;
                    border-bottom: 1px solid black;
                  }
      
                  .heading-text-barchart2 {
                    border-top: 1px solid black;
                    padding-left: 10px;
                    font-size: 15px;
                  }
                .heading-text-barchart2-under{
                  font-size: 15px;
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    padding-left: 12px;
                }
                  .heading-score-barchart2 {
                    border-top: 1px solid black;
                    font-weight: bold;
                    text-align: center;
                  }
      
                  .heading-Histogram-barchart2-barchart2 {
                    border-top: 1px solid black;
                    font-weight: bold;
                    text-align: center;
                  }
      
                  .heading-bar-barchart2 {
                    border-top: 1px solid black;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-bar-barchart2 {
                    border-top: 1px solid #e0e0e0;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-text-barchart2 {
                    padding-right: 0;
                    font-size: small;
                    ;
                    padding-left: 15px;
                    width: 80px;
                    border-top: 1px solid #e0e0e0;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-text-barchart2-top {
                    border-top: 1px solid black;
                    padding-right: 15px;
                    font-size: small;
                    ;
                    padding-left: 15px;
                    width: 80px;
                    border-left: 1px solid #e0e0e0;
                    border-right: 1px solid #e0e0e0;
                  }
      
                  .sub-heading-score-barchart2 {
                    border-top: 1px solid #e0e0e0;
                    text-align: center;
                  }
      
                  .sub-heading-Histogram-barchart2-barchart2 {
                    border-top: 1px solid #e0e0e0;
                    text-align: center;
                  }
      
                  /* Bar chart */
                  .bar-container-barchart2 {
                    display: flex;
                    min-width: 200px;
                    flex-flow: row wrap;
                    justify-content: space-around;
                  }
      
                  .bar-container-barchart2>div.bar-difference {
                    height: 0.7em;
                    margin: 1px 0;
                    min-width: 1px;
                  }
      
                  @keyframes bar-container-barchart2-animation {
                    0% {
                      flex-grow: 0;
                      opacity: 1;
                    }
      
                    100% {
                      opacity: 1;
                    }
                  }
      
                  .bar-container-barchart2>div.bar-difference {
                    animation-delay: 0.8s;
                  }
      
                  .bar-container-barchart2>div {
                    animation-delay: 0.9s;
                  }
      
                  /* Histogram-barchart2 */
                  .Histogram-barchart2 {
                    padding: 0;
                    margin: 0;
                    height: 25px;
                    display: flex;
                    list-style: none;
                    align-items: flex-end;
                    justify-content: center;
                    border-bottom: 1px solid white;
                  }
      
                  @keyframes Histogram-barchart2-animation {
                    0% {
                      height: 0;
                      opacity: 1;
                    }
      
                    100% {
                      opacity: 1;
                    }
                  }
      
                  .Histogram-barchart2>li {
                    display: inline-block;
                    background-color: currentColor;
                    width: 20px;
                    margin-left: 3px;
                    padding: 0;
                    opacity: 0;
                    animation: Histogram-barchart2-animation 0.2s ease forwards;
                    box-sizing: border-box;
                    border: 1px solid rgba(0, 0, 0, 0.4);
                  }
      
                  .Histogram-barchart2>li:nth-child(1) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart2>li:nth-child(2) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart2>li:nth-child(3) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart2>li:nth-child(4) {
                    animation-delay: 0.2s;
                  }
      
                  .Histogram-barchart2>li:nth-child(5) {
                    animation-delay: 0.2s;
                  }
      
                  .heading-label-CII {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-peers {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-direct {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-pd {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-internal {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-external {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-ie {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .heading-label-CII-pdie {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                .heading-label-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
                  .heading-text-CII {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    font-size: 15px;
                    padding: 10px;;
                    max-width: 400px;
                    }
                  .heading-text-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
                  .heading-score-CII {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    font-weight: bold;
                    padding: 10px;
                  }
                  .heading-score-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
                  .sub-heading-text-CII {
                    padding-right: 0;
                    font-size: small;
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-text-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
                  .sub-heading-text-CII-top {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding-right: 0;
                    font-size: small;
                    padding: 10px;
                  }
                  .sub-heading-text-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
                  .sub-heading-score-CII {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-peers {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-direct {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-pd {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-internal {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-external {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-ie {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII-pdie {
                    border: 1px solid #e0e0e0;
                    border-right: 1px solid white;
                    padding: 10px;
                  }
                  .sub-heading-score-CII:nth-last-child(1) {
                    border-right: 1px solid #e0e0e0;
                    padding: 10px;
                }
      
                .heading-label-blindspots {
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  padding: 10px;
                }
      
                .heading-label-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .heading-text-blindspots {
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  font-size: 15px;
                  padding: 10px;
                  ;
                  max-width: 400px;
                }
      
                .heading-text-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .heading-score-blindspots {
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  font-weight: bold;
                  padding: 10px;
                }
      
                .heading-score-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .sub-heading-text-blindspots {
                  padding-right: 0;
                  font-size: small;
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  padding: 10px;
                }
      
                .sub-heading-text-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .sub-heading-text-blindspots-top {
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  padding-right: 0;
                  font-size: small;
                  padding: 10px;
                }
      
                .sub-heading-text-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .sub-heading-score-blindspots {
                  border: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  padding: 10px;
                }
      
                .sub-heading-score-blindspots:nth-last-child(1) {
                  border-right: 1px solid #e0e0e0;
                  padding: 10px;
                }
      
                .heading-label-OpenEndedQuestion {
                  border: 1px solid #e0e0e0;
                  padding: 10px;
                  min-width: 800px;
                  background-color: #F8F8F8;
                }
                .open-ended-container{
                  border-left: 1px solid white;
                  border-right: 1px solid white;
                  font-size: 13px;
                }
      
                .open-ended-container>ul>li{
                  margin: 5px;
                }
      
                .heading-label-rawscores {
                  border: none;
                  border-bottom: 1px solid black;
                  font-size: 13px;
                  padding-left: 15px;
                  padding-top: 30px;
                  padding-bottom: 30px;
                }
                .heading-num{
                  border: none;
                  border-bottom: 1px solid black;
                  min-width: 20px;
                  text-align: center;
                  font-size: 13px;
                }
                .heading-desc{
                  border: none;
                  border-bottom: 1px solid black;
                  min-width: 20px;
                  max-width: 40px;
                  text-align: center;
                  font-size: 13px;
                }
                .heading-text-rawscores {
                  border-top: 1px solid black;
                  border-left: 1px solid #e0e0e0;
                  border-right: 1px solid white;
                  max-width: 250px;
                  padding-left: 10px;
                  font-size: 13px;
                }
                .score {
                  border-top: 1px solid #e0e0e0;
                  border-left: 1px solid white;
                  border-right: 1px solid white;
                  text-align: center;
                  min-width: 20px;
                  font-size: 12px;
                }
                .score:nth-last-child(1){
                  border-right: 1px solid #e0e0e0;
                }
              .heading-score-rawscores{
                border-top: 1px solid black;
                text-align: center;
                min-width: 20px;
                font-size: 12px;
              }
                .sub-heading-text-rawscores {
                  padding: 5px;
                  font-size: 12px;
                  padding-left: 15px;
                  max-width: 100px;
                  border-top: 1px solid #e0e0e0;
                  border-left: 1px solid white;
                  border-right: 1px solid white;
                }
                .sub-heading-text-rawscores:nth-child(2){
                  border-top: 1px solid black;
                }
                .main-table tbody tr:nth-child(2) td:nth-child(1){
                  font-weight: bold;
                }
                .main-table-faculty tbody tr:nth-child(1) td:nth-child(1){
                  font-weight: bold;
                }
                #section2 div .main-table tbody tr:nth-child(2) td{
                  font-weight: normal;
                }
                .table-border {
                  border: 1px solid #e0e0e0;
                  padding: 0.5rem;
                }
                /* 360 coach report */
                .table360-summary-coach-report {
                  display: table;
                  border-spacing: 2px;
                  border-color: grey;
                  border: 1px solid #e0e0e0;
                  border-collapse: collapse;
                  font-family: sans-serif;
                  margin: auto;
                  margin-bottom: 10px;
                  width: 100%;
                }
                table.with-flag{
                  border: none;
                }
                .flag-row div{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                th.with-flag{
                  border: none;
                  padding-top: 0;
                }
                .country-flag-span-table img{
                  width: 20px;
                  margin-bottom: 5px;
                  margin-right: 2px;
                }
                .gender-span-table img{
                  width: 20px;
                  margin-bottom: 9px;
                }
                .front-page-client-logo-img {
                  height: 70px !important; 
                }
                .front-page-client-logo, .front-page-client-logo-static {
                  height: 100% !important;  
                  width: 30vw !important;  
                  object-fit: contain !important;  
                  object-position: 0 0 !important; 
                }
                @media print {
                  @page {
                    size: A4;
                  }
                  .pagebreak {
                    clear: both;
                    page-break-after: always;
                  }
                  .front-back-page {
                    display:block !important;
                  }
                  .print-pdf {
                    display:none !important;
                  }
                  .expand-all {
                    display:none !important;
                  }
                  .print-page {
                    width: 9.3in !important;
                    height: 11in !important;
                  }
                  .subtrait-box-vfp {
                    box-shadow: none !important;
                  }
                  .cii-table-column {
                    font-size: 6px !important;
                  }
                }
                /*QSORT Dynamic text */
                .cohort_scores_IA, .cohort_scores_CE, .cohort_scores_LC, .cohort_scores_GC, .cohort_scores_GNF, .cohort_scores_PC, .cohort_scores_RA {
                  color: #4694B1;
                }
                .custom-blue {
                  color: #4694B1;
                }
                .custom-orange {
                  color: #CF6C24;
                }
                .table360-Qsort{
                  display: table;
                  border-spacing: 2px;
                  border-color: grey;
                  border: 1px solid #e0e0e0;
                  border-collapse: collapse;
                  font-family: sans-serif;
                }
                .vfp-bg-chart {
                  display: flex;
                  justify-content: center;
                }
                .vfp-chart {
                  width: 720px;
                  height: 100px;
                  background-color: #ddd;
                  position: relative;
                }
                .vfp-bar {
                  height: 100%;
                  position: absolute;
                  background-color: #2196F3;
                }
                .line {
                  position: absolute;
                  height: 100%;
                  border-left: 3px solid #b5a6a6;
                }
                .line1 {
                  left: 120px;
                }
                .line2 {
                  left: 240px;
                }
                .line3 {
                  left: 360px;
                }
                .line4 {
                  left: 480px;
                }
                .line5 {
                  left: 600px;
                }
                .custom-bar-graph {
                  height: 10px;
                  margin: 20px 0;
                  position: relative;
                  width: 100%;
                }
                .custom-bar {
                  height: 100%;
                  float: left;
                  width: 25%;
                  position: relative;
                }
                .custom-bar-color::before {
                  content: "";
                  position: absolute;
                  top: -20px;
                  left: 25%;
                  width: 100%;
                  height: 20px;
                  z-index: -1; 
                }
                .custom-bar-text {
                  position: absolute;
                  top: -20px;
                  font-size: 12px;
                }
                .vertical-line {
                  position: absolute;
                  top: -10px;
                  bottom: -10px;
                  width: 2px;
                  background-color: #CCCCCC;
                }
                .vertical-line:nth-of-type(2) {
                  left: 25%;
                }
                .vertical-line:nth-of-type(4) {
                  left: 50%;
                }
                .vertical-line:nth-of-type(6) {
                  left: 75%;
                }
                .speedbox {
                    height: 175px;
                    width: 150px;
                    display: flex;
                    display: -webkit-flex;
                    flex-direction: column;
                    -webkit-flex-direction: column;
                    align-items: center;
                    -webkit-align-items: center;
                    position: relative;
                }
                .speedbox__groove {
                    height: 75px;
                    width: 150px;
                    background: transparent;
                    border-top-left-radius: 100px;
                    border-top-right-radius: 100px;
                    border: 20px solid #eee;
                    border-bottom: 0;
                    box-sizing: border-box;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .speedbox__score {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(0deg);
                    height: 150px;
                    width: 150px;
                    background: transparent;
                    border-radius: 50%;
                    box-sizing: border-box;
                    cursor: pointer;
                    z-index: 1;
                    transition: transform 0.3s ease;
                }
                .speedbox__base {
                  width: 240px;
                  height: 100px;
                  background: white;
                  position: relative;
                  top: 74px;
                  z-index: 20;
                }
                .speedbox__odo {
                  text-align: center;
                  position: absolute;
                  color: #5c6f7b;
                  bottom: 100px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                .speedbox__odo i {
                  font-size: 13px;
                  opacity: 0.6;
                }
                .speedbox__odo > div {
                  margin-bottom: 0;
                }
                .speedbox__odo span {
                  font-size: 0.7em;
                }
                .speedbox__down {
                  text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                  line-height: 1.2em;
                }
                .breakdown-keys{
                  padding: 12px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                }
                
                .breakdown-keys p{
                  font-size: 12px;
                  margin: 2px 1px;
                  text-align: right;
                }
                .cb-marker-o{
                  position: relative
                }
                .cb-marker-o::before, span[class^="trait-"]::before{
                  content: '';
                  position: absolute;
                  width: 5px;
                  height: 12px;
                  left: 0;
                  bottom: -8px;
                  background-color: rgb(84,130,53);
                  border: 2px solid white;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  z-index: 1;
                }
               
                .cb-marker-s{
                  position: relative
                }
                .cb-marker-s::before{
                  content: '';
                  position: absolute;
                  width: 5px;
                  height: 12px;
                  left: 0;
                  top: -8px;
                  background-color: rgb(197,90,17);
                  border: 2px solid white;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
                </style>
              <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet">
              </head>
              <body class="print-page">
                <div class="main-container">
                  <div class="sub-container">
                    <form id="submitSurvey" method="POST">
                  `;
              for (let i = 0; i < input.length; i++) {
                let data = input[i];
                if (data.Template === 'Notes') {
                  output += `
                    <!-- Add Note Modal -->
                    <div class="modal fade" id="addNoteModal" tabindex="-1" aria-labelledby="addNoteModal" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="addNoteModal">Add Note</h5>
                                    <input type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </div>
    
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <input type="hidden" class="add_note_section_num">
                                        <textarea class="form-control note-box__textarea1 create_note_content" placeholder="Enter a note"></textarea>
                                    </div>
    
                                    <div class="form-check create_share_with_others">
                                        <input class="form-check-input create_share_with_others" type="checkbox" name="create_checkbox_share_with_others" id="create_share_with_others">
                                        <label class="form-check-label" for="create_share_with_others">Share with others</label>
                                    </div>
    
                                    <p class="note-share-status addVisibleToYouAndToOthers">This note will only be visible to you.</p>
                                </div>
    
                                <div class="modal-footer">
                                    <input type="button" value="Close" class="btn btn-secondary" data-bs-dismiss="modal">
                                    <input type="button" value="Add" class="btn btn-primary addNoteBtn">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Update Note Modal -->
                    <div class="modal fade" id="editNoteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Edit Note</h5>
                                    <input type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                </div>
    
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <input type="hidden" class="edit_note_id">
                                        <input type="hidden" class="edit_note_section_num">
                                        <textarea class="form-control note-box__textarea2 edit_note_content"></textarea>
                                    </div>
    
                                    <div class="form-check edit_share_with_others">
                                        <input class="form-check-input edit_share_with_others" type="checkbox" name="edit_checkbox_share_with_others" id="edit_share_with_others">
                                        <label class="form-check-label" for="edit_share_with_others">Share with others</label>
                                    </div>
    
                                    <p class="note-share-status editVisibleToYouAndToOthers"></p>
                                </div>
    
                                <div class="modal-footer">
                                    <input type="button" value="Close" class="btn btn-secondary" data-bs-dismiss="modal">
                                    <input type="button" value="Update" class="btn btn-primary updateNoteBtn">
                                </div>
                            </div>
                        </div>
                    </div>
                  `;
                  break;
                }
              }
              for (let i = 0; i < input.length; i++) {
                let data = input[i];
                if (data.Template === 'Heading-text') {
                  output += `
                      
                      <div class="heading-text">
                        <h3>` + data.Text + `</h3>
                      </div>
                `;
                }
                if (data.Template === 'Description-text') {
                  output += `
                      
                      <div class="card-box">
                        <p id="desc-text">` + data.Text + `</p>
                      </div>
                `;
                }
                if (data.Template === 'LMC-Accuracy-5-N') {
                  output += this.generateQuestion(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Yes-No') {
                  output += this.generateQuestionYesNo(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Yes-No-Maybe') {
                  output += this.generateQuestionYesNoMaybe(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Input-Date') {
                  output += this.generateDateInput(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Scale-0-10') {
                  output += this.generateScale0to10(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Scale-1-10') {
                  output += this.generateScale1to10(data, false);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Accuracy-5-P') {
                  output += this.generateQuestion(data, true);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Effectiveness-6') {
                  output += this.generateQuestion360Effectiveness(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Effectiveness-6+1') {
                  output += this.generateQuestion360Effectiveness6plus1(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Effectiveness-7') {
                  output += this.generateQuestion360Effectiveness7(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Effectiveness-7+1') {
                  output += this.generateQuestion360Effectiveness7plus1(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Effectiveness-10+1') {
                  output += this.generateQuestion360Effectiveness10plus1(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Agreement-6') {
                  output += this.generateQuestion360Agreement(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'LMC-Characteristic-5') {
                  output += this.generateQuestionVFP(data);
                  ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Select_Country') {
                  output += `
                      
                      <div class="question-box">
                        <div class="demographic-quesiton">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        
                        <!-- Country List here from jquery -->
                        <div class="mb-3 country_list"></div>
                      </div>
                `
                }
                if (data.Template === 'Select_Gender') {
                  output += `
                      
                      <div class="question-box">
                        <div class="demographic-quesiton">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `" required>
                          <option value="" disabled selected>- Select -</option>
                          <option value="man">Man</option>
                          <option value="woman">Woman</option>
                          <option value="trans">Trans</option>
                          <option value="prefer a different term">Prefer a different term</option>
                          <option value="prefer not to say">Prefer not to say</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'Select_Age') {
                  output += `
                      
                      <div class="question-box">
                        <div class="demographic-quesiton">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `" required>
                          <option value="" disabled selected>- Select -</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="13">13</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                          <option value="25">25</option>
                          <option value="26">26</option>
                          <option value="27">27</option>
                          <option value="28">28</option>
                          <option value="29">29</option>
                          <option value="30">30</option>
                          <option value="31">31</option>
                          <option value="32">32</option>
                          <option value="33">33</option>
                          <option value="34">34</option>
                          <option value="35">35</option>
                          <option value="36">36</option>
                          <option value="37">37</option>
                          <option value="38">38</option>
                          <option value="39">39</option>
                          <option value="40">40</option>
                          <option value="41">41</option>
                          <option value="42">42</option>
                          <option value="43">43</option>
                          <option value="44">44</option>
                          <option value="45">45</option>
                          <option value="46">46</option>
                          <option value="47">47</option>
                          <option value="48">48</option>
                          <option value="49">49</option>
                          <option value="50">50</option>
                          <option value="51">51</option>
                          <option value="52">52</option>
                          <option value="53">53</option>
                          <option value="54">54</option>
                          <option value="55">55</option>
                          <option value="56">56</option>
                          <option value="57">57</option>
                          <option value="58">58</option>
                          <option value="59">59</option>
                          <option value="60">60</option>
                          <option value="61">61</option>
                          <option value="62">62</option>
                          <option value="63">63</option>
                          <option value="64">64</option>
                          <option value="65">65</option>
                          <option value="66">66</option>
                          <option value="67">67</option>
                          <option value="68">68</option>
                          <option value="69">69</option>
                          <option value="70">70</option>
                          <option value="71">71</option>
                          <option value="72">72</option>
                          <option value="73">73</option>
                          <option value="74">74</option>
                          <option value="75">75</option>
                          <option value="76">76</option>
                          <option value="77">77</option>
                          <option value="78">78</option>
                          <option value="79">79</option>
                          <option value="80">80</option>
                          <option value="81">81</option>
                          <option value="82">82</option>
                          <option value="83">83</option>
                          <option value="84">84</option>
                          <option value="85">85</option>
                          <option value="85">85</option>
                          <option value="86">86</option>
                          <option value="87">87</option>
                          <option value="88">88</option>
                          <option value="89">89</option>
                          <option value="90">90</option>
                          <option value="91">91</option>
                          <option value="92">92</option>
                          <option value="93">93</option>
                          <option value="94">94</option>
                          <option value="95">95</option>
                          <option value="96">96</option>
                          <option value="97">97</option>
                          <option value="98">98</option>
                          <option value="99">99</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'TagDisplay' && data.Tag === '20') {
                  output += `
                      
                      <div class="question-box" id="coach-access">
                        <div class="sharing-option">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `" required>
                          <option value="" disabled selected>- Select -</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'TagDisplay' && data.Tag === '4') {
                  output += `
                      
                      <div class="question-box" id="coach-group-access">
                        <div class="sharing-option">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `">
                          <option value="" disabled selected>- Select -</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'TagDisplay' && data.Tag === '19') {
                  output += `
                      
                      <div class="question-box" id="hr-access">
                        <div class="sharing-option">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `">
                          <option value="" disabled selected>- Select -</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'Select_YN') {
                  output += `
                      
                      <div class="question-box">
                        <div class="sharing-option">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <select class="form-select" name="Q` + data.QuestionNumber + `" required>
                          <option value="" disabled selected>- Select -</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                `
                }
                if (data.Template === 'Free-text') {
                  output += `
                      
                      <div class="question-box">
                        <div class="free-text-question" id="` + data.QuestionNumber + `-question">
                        ` + data.QuestionNumber + '. ' + data.Text + `
                        </div>
                        <input class="form-control" minlength="15" maxlength="2000" type="text" name="Q` + data.QuestionNumber + `" required>
                        <small class="form-text text-muted">0 characters remaining</small>
                      </div>
                `
                }
                if (data.Template === 'Re-order') {
                  output += `
                      
                        <div class="reorder-box">
                          <div class="reOrder-mainContainer">
                            <div class="reOrder-subContainer">
                              <div class="rank-col">
                                <div id="rank" class="rank"></div>
                              </div>
                              <div class="item-col">
                                <div id="box" class="box">
                                  <div id="itemClip" class="item itemClip hide">some item</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                `
                ids.push(data.QuestionNumber);
                }
                if (data.Template === 'Section') {
                  output += `
                      <div class="accordion" id="accordionPanelsStayOpenExample">
                        <div class="accordion-item">
                          <div class="accordion-header" id="panelsStayOpen-headingOne">
                            <div class="section-number">` + data.QuestionNumber + `</div>
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#section` + data.QuestionNumber + `"
                              aria-expanded="false"
                              aria-controls="section` + data.QuestionNumber + `"
                            >
                            ` + data.Text + ` <span class="report-for-name">&report_for_fullname</span>
                            </button>
                          </div>
                          <div
                            id="section` + data.QuestionNumber + `"
                            class="accordion-collapse collapse multi-collapse"
                            aria-labelledby="panelsStayOpen-headingOne"
                          >
                            <div class="accordion-body">
                  `
                }
                if (data.Template === 'Front-Page') {
                  output += `
                    <div class="front-back-page" style="display:none">
                      <div class="pagebreak">

                        <div class="container">
                          <div class="d-flex flex-column" style="height: 97vh;">
                              <div>
                                  <div class="front-page-client-logo-img mb-0">
                                      <img src="" class="front-page-client-logo" alt="Client Logo">
                                  </div>
                                  <p class="front-page-program-name mt-0"></p>
                              </div>

                              <div class="mt-auto mb-auto">
                                  <div class="row">
                                    <div class="col-6"></div>
                                    <div class="col-6">
                                      <p class="front-page-survey-title mb-0"></p>
                                      <p class="mt-0 mb-0">For <span class="front-page-survey-subject-name"></span></p>
                                      <p class="front-page-participant-report-release-date mt-0"></p>

                                      <div class="front-page-for-individual-report d-none">
                                          <div class="row align-items-center front-page-psup">
                                            <div class="col-9">
                                              Primary supervisors
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-psup-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-P">
                                            <div class="col-9">
                                              Peers
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-P-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-D">
                                            <div class="col-9">
                                              Direct report
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-D-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-PD">
                                            <div class="col-9">
                                              Peers/Direct report
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-PD-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-I">
                                            <div class="col-9">
                                              Internal stakeholders
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-I-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-E">
                                            <div class="col-9">
                                              External stakeholders
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-E-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-IE">
                                            <div class="col-9">
                                              Internal/External stakeholders
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-IE-value"></span>
                                            </div>
                                          </div>

                                          <div class="row align-items-center front-page-PDIE">
                                            <div class="col-9">
                                              Peers/Direct reports/Internal stakeholders/External stakeholders
                                            </div>
                                            <div class="col-3">
                                              <span class="front-page-PDIE-value"></span>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>

                              <div class="d-flex justify-content-between align-items-end">
                                  <div>
                                    <p class="mb-0">Powered by</p>
                                    <div class="mt-0" style="height:50px;">
                                      <img src="https://ts.talentsage.com/1ccaa508-f5eb-11ec-bb65-06c5d6b8da7c/logo.png" class="front-page-client-logo-static" alt="TalentSage Logo">
                                    </div>
                                  </div>

                                  <div>
                                    <p class="mb-0">Downloaded by <span class="front-page-downloaded-by"></span></p>
                                    <p class="mt-0">Downloaded on <span class="front-page-date-today"></span> </p>
                                  </div>
                              </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Back-Page') {
                  output += `
                    <div class="front-back-page" style="display:none">
                      <div class="container">
                          <div class="d-flex flex-column" style="height: 97vh;">
                              <div>
                                <p>About TalentSage</p>
                              </div>

                              <div class="mt-auto mb-auto text-center">
                                <div class="w-75 m-auto">
                                  <div style="height:50px;">
                                    <img src="https://ts.talentsage.com/1ccaa508-f5eb-11ec-bb65-06c5d6b8da7c/logo.png" class="front-page-client-logo-static" alt="TalentSage Logo">
                                  </div>

                                  <div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                  </div>

                                  <div>
                                    <p>CONTACT US</p>
                                    <p>enquiries@TalentSage.com</p>
                                    <p>help@TalentSage.com</p>
                                  </div>
                                </div>
                              </div>

                              <div class="d-flex justify-content-between align-items-end">
                                  <div>
                                    <p>www.TalentSage.com</p>
                                  </div>

                                  <div>
                                    <p>2018 TalentSageLLC <span>&copy;</span> All Rights Reserved</p>
                                  </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  `
                }
                if (data.Template === 'Heading-text-nobox') {
                  output += `
                    ` + data.Text + `
                  `
                }
                if (data.Template === 'Description-text-nobox') {
                  output += `
                    ` + data.Text + `
                  `
                }
                if (data.Template === 'Dynamic-Description-text-nobox') {
                  output += `
                    ` + data.Text + `
                  `
                }
                if (data.Template === 'Expand-All-Accordions') {
                  output += `
                    <div class="expand-all">
                      <div class="mt-5 d-flex justify-content-end">
                        <button 
                          class="btn btn-success me-2" 
                          type="button"
                          id="btnMakePdfAvailable"
                          >
                          <span>Make PDF Available</span>
                        </button> 
                        <button 
                          class="btn btn-primary" 
                          type="button" 
                          data-bs-toggle="collapse" 
                          data-bs-target=".multi-collapse" 
                          aria-expanded="false" 
                          aria-controls=""
                          id="expandAllBtn">
                          Expand All
                        </button>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Expand-All-with-Toggle-Bar-Chart') {
                  output += `
                    <div class="expand-all">
                      <div class="mt-5 d-flex justify-content-end">
                        <button 
                          class="btn btn-secondary toggle-bar-chart" 
                          type="button">
                          <span class="toggle-bar-chart-text">Show Bar and Cohort Chart</span>
                        </button> 
                        <button 
                          class="btn btn-success mx-2" 
                          type="button"
                          id="btnMakePdfAvailable"
                        >
                          <span>Make PDF Available</span>
                        </button> 
                        <button 
                          class="btn btn-primary" 
                          type="button" 
                          data-bs-toggle="collapse" 
                          data-bs-target=".multi-collapse" 
                          aria-expanded="false" 
                          aria-controls=""
                          id="expandAllBtn">
                          Expand All
                        </button>
                      </div>
                    </div>
                  `
                }
                //for 5Step Individual 
                if (data.Template === 'Gender-Culture-Individual') { 
                  output += `
                    <div class="adjust-norm-container">
                      <div class="adjust-norm-box">

                          <!-- this code is to check if there is already a data in canvas from the first load but it is always hidden, it is using in removing old radar-chart -->
                          <span class="d-none isRadarChartHasData"></span>

                          <p class="pt-3">Comparing to:</p>
      
                          <h6 class="adjust-norm-box__gender">Gender: <span class="adjust-norm-box__gender--span" id="gender_label"></span> <span id="gender-span"><img class="gender-icon" width="20px" alt="" style="margin-bottom:5px;"></span></h6>
                          <h6 class="adjust-norm-box__culture">Culture: <span class="adjust-norm-box__culture--span" id="culture_label"></span> <span id="country-flag-span"><img class="country-flag" width="20px" alt="" style="margin-bottom:5px;"></span></h6> 
                          <div class="d-none formBox" id="showAdjustForm">
                              <div class="mb-3">
                                  <select id="norms_gender" class="form-select" aria-label="Select gender">
                                      <option disabled>Select gender</option>
                                      <option value="man">Man</option>
                                      <option value="woman">Woman</option>
                                      <option value="trans">Trans</option>
                                      <option value="prefer a different term">Prefer a different term</option>
                                      <option value="prefer not to say">Prefer not to say</option>
                                  </select>
                              </div>
      
                              <!-- Country List here from jquery -->
                              <div class="mb-3 country_list"></div>
      
                              <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="compare_results">
                                  <label class="form-check-label" for="compare_results">Compare your results with Global Business peers</label>
                              </div>
      
                              <div class="mt-1">
                                  <input type="button" value="Adjust" class="btn btn-submit" id="adjustNormBtnSubmit">
                                  <input type="button" value="Cancel" class="btn btn-cancel" id="adjustNormBtnCancel">
                              </div>
                          </div>
                    
                          <div id="showAdjustBtn">
                              <input type="button" value="Adjust your norm" class="btn btn-submit" id="adjustNormBtn">
                          </div>
                      </div>
                    </div>
                  `
                }
                //for 5Step tipping and pressure point
                if (data.Template === 'Gender-Culture') { 
                  output += `
                    <div class="adjust-norm-container">
                      <div class="adjust-norm-box">

                          <!-- this code is to check if there is already a data in canvas from the first load but it is always hidden, it is using in removing old radar-chart -->
                          <span class="d-none isRadarChartHasData"></span>

                          <p class="pt-3">Comparing to:</p>
      
                          <h6 class="adjust-norm-box__gender">Gender: <span class="adjust-norm-box__gender--span" id="gender_label"></span> <span id="gender-span"><img class="gender-icon" width="20px" alt="" style="margin-bottom:5px;"></span></h6>
                          <h6 class="adjust-norm-box__culture">Culture: <span class="adjust-norm-box__culture--span" id="culture_label"></span> <span id="country-flag-span"><img class="country-flag" width="20px" alt="" style="margin-bottom:5px;"></span></h6> 
                          <div class="d-none formBox" id="showAdjustForm">
                              <div class="mb-3">
                                  <select id="norms_gender" class="form-select" aria-label="Select gender">
                                      <option disabled>Select gender</option>
                                      <option value="man">Man</option>
                                      <option value="woman">Woman</option>
                                      <option value="trans">Trans</option>
                                      <option value="prefer a different term">Prefer a different term</option>
                                      <option value="prefer not to say">Prefer not to say</option>
                                  </select>
                              </div>
      
                              <!-- Country List here from jquery -->
                              <div class="mb-3 country_list"></div>
      
                              <div class="form-check">
                                  <input class="form-check-input" type="checkbox" value="" id="compare_results">
                                  <label class="form-check-label" for="compare_results">Compare your results with Global Business peers</label>
                              </div>
      
                              <div class="mt-1">
                                  <input type="button" value="Adjust" class="btn btn-submit" id="adjustNormBtnSubmit">
                                  <input type="button" value="Cancel" class="btn btn-cancel" id="adjustNormBtnCancel">
                              </div>
                          </div>
                    
                          <div id="showAdjustBtn">
                              <input type="button" value="Adjust your norm" class="btn btn-submit" id="adjustNormBtn">
                          </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Print-PDF-Button') { 
                      output += 
                      `
                      <div style="display:flex; justify-content: center;" class="print-pdf">
                        <div class="btn btn-primary" style="border: 1px; box-shadow: 2px 4px #ccc;" onclick="printPDF();">Download Report PDF</div>
                      </div>
                      `
                }
                if (data.Template === 'Bar-Chart') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {  
                    output += `
                      <table class="main-table with-flag">
                        <tr>
                          <td class="flag-row"></td>
                          <td class="flag-row"></td>
                          <td class="flag-row">
                            <div>
                              <span class="country-flag-span-table"><img class="country-flag" alt="flag"></span>
                              <span class="gender-span-table"><img class="gender-icon" alt="gender"></span>
                            </div>
                          </td>
                          <td class="flag-row"></td>
                          <td class="flag-row"></td>
                        </tr>
    
                        <tr>
                          <th class="heading-label with-flag">
                            <div></div>
                          </th>
                          <th class="heading-label with-flag">
                            <div></div>
                          </th>
                          <th class="heading-label with-flag">
                            <div>
                              <div>
                                <div>` + res.heading1 + `</div>
                              </div>
                            </div>
                          </th>
                          <th class="heading-label with-flag">
                            <div>` + res.heading2 + `</div>
                          </th>
                          <th class="heading-label with-flag">
                            <div>` + res.heading3 + `</div>
                          </th>
                        </tr>
                    `
                    res.Elements.forEach((response) => {
                      output += `
                        <tr>
                          <td class="_data-heading" style="padding-right:0;">` + response.Description + `</td>
                          <td class="_data-heading" style="padding-right:0;">
                            <div class="bar-container">
                              <div class="bar-difference percentile-` + response.Trait + `"></div>
                              <div class="percentile-` + response.Trait + `-white-space"></div>
                            </div>
                          </td>
                          <td class="_data-heading">
                            <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="percentile_` + response.Trait + `"></span></div>
                          </td>
                          <td class="_data-heading">
                            <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="_data-heading">
                            <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="cohort_` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                    })
                    output += `
                      </table>
                    `
                  }
                  
                }
                if (data.Template === 'Radar-Chart') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div class="chart-container">
                          <canvas class="` + res.SuperTrait + `"></canvas>
                      </div>
                    `
                  }
                }
                if (data.Template === 'Bar-Chart-360-Head') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                      <div class="text-center 360_head_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                        <table class="table360 360_head d-none">
                          <tr>
                            <th class="heading-label">
                              <div style="display: flex; align-items: center;"></div>
                            </th>
                            <th class="heading-label">
                              <div style="display: flex; align-items: center;"></div>
                            </th>
                            <th class="heading-label">
                              <div style="display: flex; align-items: center; font-weight: bold;">
                                <div>
                                  <div>`+ res.heading1 +`</div>
                                </div>
                              </div>
                            </th>
                            <th class="heading-label">
                              <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading2 +`</div>
                            </th> 
                            <th class="heading-label">
                              <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading3 +`</div>
                            </th> 
                          </tr>
                      `
                      res.Elements.forEach(response => {
                        output += 
                        `
                          <tr class="head-bar-chart-`+response.Trait+`">
                            <td class="heading-text-barchart1" style="font-weight: bold;">`+ response.Description +`</td>
                            <td class="heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainOverall-` + response.Trait + `" style="background-color: #66D9E8; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainOverall-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference mainCohort-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainCohort-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="heading-score-barchart1">
                              <div style="color:#66D9E8"><span style="font-weight: bold;" class="all_1000_` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781);"><span style="font-weight: bold;" class="cohort_all_1000_` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-head" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-head" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-head" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-head" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-head" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          
                          <tr class="head-bar-chart-`+response.Trait+`-Psup">
                            <td class="sub-heading-text-barchart1">Primary supervisors</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainPsup-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainPsup-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortPsup-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortPsup-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="psup1_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_psup1_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-Psup" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-Psup" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-Psup" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-Psup" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-Psup" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          
                          <tr class="head-bar-chart-`+response.Trait+`-P">
                            <td class="sub-heading-text-barchart1">Peers</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainP-` + response.Trait + `" style="background-color: #B197FC; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainP-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortP-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortP-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#B197FC; font-size: small;"><span style="font-weight: bold;" class="peer2_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_peer2_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-P" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-P" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-P" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-P" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-P" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          
                          <tr class="head-bar-chart-`+response.Trait+`-D">
                            <td class="sub-heading-text-barchart1">Direct report</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainD-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainD-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortD-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortD-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="direct3_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_direct3_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-D" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-D" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-D" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-D" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-D" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
    
                          <tr class="head-bar-chart-`+response.Trait+`-PD">
                            <td class="sub-heading-text-barchart1">Peers/Direct report</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainPD-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainPD-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortPD-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortPD-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="PD996_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_PD996_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-PD" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-PD" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-PD" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-PD" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-PD" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
                          
                          
                          <tr  class="head-bar-chart-`+response.Trait+`-I">
                            <td class="sub-heading-text-barchart1">Internal stakeholders</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainI-` + response.Trait + `" style="background-color: #63E6BE; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainI-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortI-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortI-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#63E6BE; font-size: small;"><span style="font-weight: bold;" class="internal4_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_internal4_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-I" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-I" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-I" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-I" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-I" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          
                          <tr class="head-bar-chart-`+response.Trait+`-E">
                            <td class="sub-heading-text-barchart1">External stakeholders</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainE-` + response.Trait + `" style="background-color: #63E6BE; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainE-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortE-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#63E6BE; font-size: small;"><span style="font-weight: bold;" class="external5_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_external5_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-E" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-E" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-E" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-E" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-E" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          <tr class="head-bar-chart-`+response.Trait+`-IE">
                            <td class="sub-heading-text-barchart1">Internal/External stakeholders</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainIE-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainIE-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortIE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortIE-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="IE997_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_IE997_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-IE" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-IE" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-IE" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-IE" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-IE" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
                          <tr class="head-bar-chart-`+response.Trait+`-PDIE">
                            <td class="sub-heading-text-barchart1" style="padding-right:0; font-size: small;">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainPDIE-` + response.Trait + `" style="background-color: #B197FC; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainPDIE-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortPDIE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortPDIE-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#B197FC; font-size: small;"><span style="font-weight: bold;" class="PDIE998_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_PDIE998_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                              </ul>
                            </td>

                          </tr>
      
                          
                          <tr class="head-bar-chart-`+response.Trait+`-Self">
                            <td class="sub-heading-text-barchart1">Self</td>
                            <td class="sub-heading-bar-barchart1">
                              <div class="bar-container-barchart1">
                                <div class="bar-difference mainSelf-` + response.Trait + `" style="background-color: #E599F7; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="mainSelf-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohortSelf-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                <div class="cohortSelf-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart1">
                              <div style="color:#E599F7; font-size: small;"><span style="font-weight: bold;" class="ind_` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart1">
                              <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_self0_` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-Self" style="color: grey;"></li>
                              </ul>
                            </td>

                          </tr>
      
                        `
                      });
                      output += 
                        `
                      </table>
                        `
                    }
                }
                if (data.Template === 'Bar-Chart-360-Sub') {
                  let obj = JSON.parse(data.Text)
      
                  for (const res of obj) {
                    output += `
                    <div class="text-center 360_sub_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360 360_sub d-none">
                        <tr>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center;"></div>
                          </th>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center;"></div>
                          </th>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center;"></div>
                          </th>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center; font-weight: bold;">
                              <div>
                                <div>`+ res.heading1 +`</div>
                              </div>
                            </div>
                          </th>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading2 +`</div>
                          </th>
                          <th class="heading-label-barchart2">
                            <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading3 +`</div>
                          </th>
                        </tr>
                      `
                      res.Elements.forEach(response => {
                        output += 
                        `
                          <tr class="MAIN">
                            <td class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                          </tr>
                          <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          <tr class="sub-bar-chart-P-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Peers</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: #B197FC; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#B197FC; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
      
                          <tr class="sub-bar-chart-D-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Direct report</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
    
                          <tr class="sub-bar-chart-PD-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
                          <tr class="sub-bar-chart-I-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: #63E6BE; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: #63E6BE; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
      
                          <tr class="sub-bar-chart-E-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: #63E6BE; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#63E6BE; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                              </ul>
                            </td>

                          </tr>                  
      
                          <tr class="sub-bar-chart-IE-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                            <td class="heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                            </td>
                            <td class="heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                            <td class="sub-heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: #B197FC; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart2">
                              <div style="color: #B197FC; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>
      
      
                          <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Total</td>
                            <td class="sub-heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: #66D9E8; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart2">
                              <div style="color: #66D9E8; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                            </td>

                            <td class="sub-heading-Histogram-barchart1-barchart1">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                              </ul>
                            </td>

                          </tr>
      
      
                          <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                            <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                            <td class="sub-heading-bar-barchart2">
                              <div class="bar-container-barchart2">
                                <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: #E599F7; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                <div class="break-column"></div>
                                <div class="bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                              </div>
                            </td>
      
                            <td class="sub-heading-score-barchart2">
                              <div style="color: #E599F7; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                            </td>
                            <td class="sub-heading-score-barchart2">
                              <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                            </td>

                          <td class="sub-heading-Histogram-barchart1-barchart1">
                            <ul class="Histogram-barchart1">
                              <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                              <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                              <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                              <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                              <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                            </ul>
                          </td>

                          </tr>

                      `
                    });
                      output += 
                        `
                          </table>
                        `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Head-Self') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                      <div class="text-center radarchart_head_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                        <div class="chart-container radar_head d-none">
                        `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Head-Cohort') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                      <div class="text-center radarchart_cohort_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                        <div class="chart-container radar_cohort d-none">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Self') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="chart-container">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Cohort') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="chart-container">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Table-360-Competency') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                      <div class="text-center competency_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360-competency d-none">
                        <tr>
                          <th class="heading-label-CII">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">
                              <div>
                                <div>`+ res.heading2 +`</div>
                              </div>
                            </div>
                          </th>
                          <th class="heading-label-CII-peers text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-CII-direct text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                          </th>
                          <th class="heading-label-CII-pd text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                          </th>
                          <th class="heading-label-CII-internal text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading6 +`</div>
                          </th>
                          <th class="heading-label-CII-external text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading7 +`</div>
                          </th>
                          <th class="heading-label-CII-ie text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading8 +`</div>
                          </th>
                          <th class="heading-label-CII-pdie text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading9 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading10 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading11 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading12 +`</div>
                          </th>
                        </tr>
                    <!-- 1 line here -->
                    `
                      res.Elements.forEach(response => {
                        output +=
                        `
                        <tr>
                          <td class="heading-text-CII">`+ response.Description +`</td>
                          <td class="sub-heading-score-CII text-center">
                            <div class="text-center"><span class="competency-psup1` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-peers text-center">
                            <div style="font-weight: bold"><span class="competency-peer2_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-direct text-center">
                            <div style="font-weight: bold"><span class="competency-direct3_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-pd text-center">
                            <div style="font-weight: bold"><span class="competency-PD996_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-internal text-center">
                            <div style="font-weight: bold"><span class="competency-internal4_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-external text-center">
                            <div style="font-weight: bold"><span class="competency-external5_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-ie text-center">
                            <div style="font-weight: bold"><span class="competency-IE997_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-pdie text-center">
                            <div style="font-weight: bold"><span class="competency-PDIE998_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII text-center">
                            <div><span class="competency-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII text-center">
                            <div><span class="competency-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII text-center">
                            <div style="font-weight: bold"><span class="competency-cohort_all_1000_` + response.Trait + `"></span></div>
                          </td>
                      </tr>
                        `
                      });
                    output +=
                      `
                        </table>
                      `
                }
                }
                if (data.Template === 'Bar-Chart-360-Highest') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                        `
                        <div class="text-center highest_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="table360 highest_table d-none">
                          <thead>
                            <tr class="highest-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="highest-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                              <tr class="highest-row` + response.Trait + ` highest-sortme">
                                <td class="sub-heading-score-blindspots highest-averageScore">
                                  <div><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
    
                                <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="highest-ind_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="highest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="highest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                </td>
                              </tr>
                            `
                          });
                          output +=
                        `
                      </tbody>
                    </table>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-360-Lowest') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                        `
                        <div class="text-center lowest_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="table360 lowest_table d-none">
                          <thead>
                            <tr class="lowest-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="lowest-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                              <tr class="lowest-row` + response.Trait + ` lowest-sortme">
                                <td class="sub-heading-score-blindspots lowest-averageScore">
                                  <div><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
    
                                <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="lowest-ind_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="lowest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div><span class="lowest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                </td>
                              </tr>
                            `
                          });
                          output +=
                        `
                      </tbody>
                    </table>
                      `
                  }
                }
                if (data.Template === 'Table-360-Overestimated'){ 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360"> 
                      <p class ="d-none overestimated-none" style="font-weight: bold;">None</p>
                      <thead>
                        <tr class="overestimated-row-head">
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading2 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="overestimate-wrapper">
                      `
                      res.Elements.forEach(response => {
                        output +=
                        `
                      <!-- 1 line here -->
                        <tr class="overestimated-row` + response.Trait + `">
                          <td class="heading-text-blindspots">`+ response.Description +`</td>
                          <td class="sub-heading-score-blindspots">
                            <div><span class="overestimated-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div style="font-weight: bold"><span class="overestimated-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div><span class="overestimated-difference` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                      });
                      output +=
                      `
                      </tbody>
                    </table>
                      `
                }
                }
                if (data.Template === 'Table-360-Underestimated') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360">
                      <p class ="d-none underestimated-none" style="font-weight: bold;">None</p>
                      <thead>
                        <tr class="underestimated-row-head">
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading2 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="underestimate-wrapper">
                      `
                      res.Elements.forEach(response => {
                        output +=
                        `
                      <!-- 1 line here -->
                        <tr class="underestimated-row` + response.Trait + `">
                          <td class="heading-text-blindspots">`+ response.Description +`</td>
                          <td class="sub-heading-score-blindspots">
                            <div><span class="underestimated-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div style="font-weight: bold"><span class="underestimated-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div><span class="underestimated-difference` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                      });
                      output +=
                      `
                      </tbody>
                    </table>
                      `
                }
                }

                
                if (data.Template === 'Table-360-Open-ended') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360">
                    <!-- start of item  -->
                          `
                          res.Elements.forEach(response => {
                          output +=
                          `
                          <tr>
                          <td class="heading-label-OpenEndedQuestion">
                            `+ response.Description +`
                          </td>
                          <tr>
                          <td class="open-ended-container">
                            <ul id="sort-open-ended">
                              <li class="open-ended-1` + response.Trait + `"></li>
                              <li class="open-ended-2` + response.Trait + `"></li>
                              <li class="open-ended-3` + response.Trait + `"></li>
                              <li class="open-ended-4` + response.Trait + `"></li>
                              <li class="open-ended-5` + response.Trait + `"></li>
                              <li class="open-ended-6` + response.Trait + `"></li>
                              <li class="open-ended-7` + response.Trait + `"></li>
                              <li class="open-ended-8` + response.Trait + `"></li>
                              <li class="open-ended-9` + response.Trait + `"></li>
                              <li class="open-ended-10` + response.Trait + `"></li>
                              <li class="open-ended-11` + response.Trait + `"></li>
                              <li class="open-ended-12` + response.Trait + `"></li>
                              <li class="open-ended-13` + response.Trait + `"></li>
                              <li class="open-ended-14` + response.Trait + `"></li>
                              <li class="open-ended-15` + response.Trait + `"></li>
                              <li class="open-ended-16` + response.Trait + `"></li>
                              <li class="open-ended-17` + response.Trait + `"></li>
                              <li class="open-ended-18` + response.Trait + `"></li>
                              <li class="open-ended-19` + response.Trait + `"></li>
                              <li class="open-ended-20` + response.Trait + `"></li>
                            </ul>
                          </td>
                        </tr>
                      </tr>
                      `
                      });
                      output +=
                      `
                        </table>
                      `
                  }
                }
                if (data.Template === 'Table-360-Open-ended-New') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360">
                    <!-- start of item  -->
                          `
                          res.Elements.forEach(response => {
                          output +=
                          `
                          <tr>
                          <td class="heading-label-OpenEndedQuestion">
                            `+ response.Description +`
                          </td>
                          <tr>
                          <td class="open-ended-container">
                            <ul id="sort-open-ended">
                              <li class="open-ended-1` + response.Trait + `"></li>
                              <li class="open-ended-2` + response.Trait + `"></li>
                              <li class="open-ended-3` + response.Trait + `"></li>
                              <li class="open-ended-4` + response.Trait + `"></li>
                              <li class="open-ended-5` + response.Trait + `"></li>
                              <li class="open-ended-6` + response.Trait + `"></li>
                              <li class="open-ended-7` + response.Trait + `"></li>
                              <li class="open-ended-8` + response.Trait + `"></li>
                              <li class="open-ended-9` + response.Trait + `"></li>
                              <li class="open-ended-10` + response.Trait + `"></li>
                              <li class="open-ended-11` + response.Trait + `"></li>
                              <li class="open-ended-12` + response.Trait + `"></li>
                              <li class="open-ended-13` + response.Trait + `"></li>
                              <li class="open-ended-14` + response.Trait + `"></li>
                              <li class="open-ended-15` + response.Trait + `"></li>
                              <li class="open-ended-16` + response.Trait + `"></li>
                              <li class="open-ended-17` + response.Trait + `"></li>
                              <li class="open-ended-18` + response.Trait + `"></li>
                              <li class="open-ended-19` + response.Trait + `"></li>
                              <li class="open-ended-20` + response.Trait + `"></li>
                            </ul>
                          </td>
                        </tr>
                      </tr>
                      `
                      });
                      output +=
                      `
                        </table>
                      `
                  }
                }
                

                if (data.Template === 'Dynamic-Message-Head') { 
                    output += `
                    <span class="Head-Dynamic-Message">
                    </span>
                    `
                }
                if (data.Template === 'Table-5step-NEO') { 
                    output += `
                    <center>
                      <table style="border-collapse: collapse;">
                        <thead>
                          <tr>
                            <th>5 STeP</th>
                            <th>NEO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Emotional Reactiveness</td>
                            <td style="background-color: #efefef;">Neuroticism</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Anger</td>
                            <td style="border: 1px solid #efefef">Angry, Hostility</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Friendliness</td>
                            <td style="background-color: #efefef;">Warmth</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Sociability</td>
                            <td style="border: 1px solid #efefef">Gregariousness</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Activity Level</td>
                            <td style="background-color: #efefef;">Activity</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Cheerfulness</td>
                            <td style="border: 1px solid #efefef">Positive emotions</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Imagination</td>
                            <td style="background-color: #efefef;">Fantasy</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Artistic Interests</td>
                            <td style="border: 1px solid #efefef">Aesthetics</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Emotionality</td>
                            <td style="background-color: #efefef;">Feelings</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Adventurousness</td>
                            <td style="border: 1px solid #efefef">Action</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Cognition</td>
                            <td style="background-color: #efefef;">Ideas</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Broad-mindedness</td>
                            <td style="border: 1px solid #efefef">Values</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Candor</td>
                            <td style="background-color: #efefef;">Straightforwardness</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Sympathy</td>
                            <td style="border: 1px solid #efefef">Tender-mindedness</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Self-belief</td>
                            <td style="background-color: #efefef;">Competence</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="border: 1px solid #efefef">Orderliness</td>
                            <td style="border: 1px solid #efefef">Order</td>
                          </tr>
                          <tr style="border: 1px solid #dddddd;">
                            <td style="padding-right: 10px; background-color: #efefef;">Planning</td>
                            <td style="background-color: #efefef;">Deliberation</td>
                          </tr>
                        </tbody>
                        </table>
                      </center>
                    `
                }
                if (data.Template === 'Dynamic-Message-Sub') { 
                    output += `
                    <span class="Sub-Dynamic-Message">
                    </span>
                    `
                }
                if (data.Template === 'Table-Raw-Scores-KDY') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Knows and Develops Yourself</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-DTO') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Develop Through Others</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-CP') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Communicate Powerfully</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-SCP') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Solve Complex Problems</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-LIC') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Lead and Implement Change</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-LDF') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Leads with Digital Fluency</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Table-Raw-Scores-OCA') {
                  output += `
                    <table class="table360">
                      <tr style="background-color: #F8F8F8;">
                        <th class="heading-label-rawscores">
                          <div style="display: flex; align-items: center;">Organizational Climate Assesment</div>
                        </th>
                        <th class="heading-label-rawscores">
                          <div></div>
                        </th>
                        <th class="heading-num">
                          <div>1</div>
                        </th>
                        <th class="heading-num">
                          <div>2</div>
                        </th>
                        <th class="heading-num">
                          <div>3</div>
                        </th>
                        <th class="heading-num">
                          <div>4</div>
                        </th>
                        <th class="heading-num">
                          <div>5</div>
                        </th>
                        <th class="heading-desc">
                          <div>
                            <div>
                              <div>My overall score</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-desc">
                          <div>Cohort mean score</div>
                        </th>
                      </tr>
                  <!-- new item -->
                      <tr>
                        <td class="heading-text-rawscores" rowspan="4">I understand myself and am clear on my personal values
                          and goals.</td>
                        <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>1</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>-</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                        <td class="heading-score-rawscores">
                          <div>3.0</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                          stakeholders/External stakeholders (4)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.3</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
      
                      <tr  style="font-weight: bold;">
                        <td class="sub-heading-text-rawscores">Total (5)</td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>2</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>3.8</div>
                        </td>
                      </tr>
                      <tr>
                        <td class="sub-heading-text-rawscores">Self(1)</td>
                        <td class="score">
                          <div>1</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>-</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                        <td class="score">
                          <div>4.0</div>
                        </td>
                      </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I recognise and appreciate the strengths of others.
                    </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I am cool under pressure.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I seek feedback and learning opportunities.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I work well with people from a variety of professional
                      backgrounds.</td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Peers/Direct reports/Internal
                      stakeholders/External stakeholders (4)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.3</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
      
                  <tr  style="font-weight: bold;">
                    <td class="sub-heading-text-rawscores">Total (5)</td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>2</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>3.8</div>
                    </td>
                  </tr>
                  <tr>
                    <td class="sub-heading-text-rawscores">Self(1)</td>
                    <td class="score">
                      <div>1</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>-</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                    <td class="score">
                      <div>4.0</div>
                    </td>
                  </tr>
                  <!-- new item -->
                  <tr>
                    <td class="heading-text-rawscores" rowspan="4">I lead by leveraging my leadership style and playing to my
                      strengths as well as the strengths of my team.
                      </td>
                    <td class="sub-heading-text-rawscores">Primary supervisors (1)</td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>1</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>-</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                    <td class="heading-score-rawscores">
                      <div>3.0</div>
                    </td>
                  </tr>
                  <tr>
                  </table>
                  `
                }
                if (data.Template === 'Box-(Anxiety)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="anxiety"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Anger)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="anger"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Sadness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="sadness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Self-consciousness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="self-consciousness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Self-indulgence)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="self-indulgence"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Vulnerability)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="vulnerability"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Friendliness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="friendliness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Sociability)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="sociability"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Assertiveness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="assertiveness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Activity Level)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="activity-level"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Excitement-seeking)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="excitement-seeking"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Cheerfulness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="cheerfulness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Imagination)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="imagination"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Artistic Interests)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="artistic-Interests"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Emotionality)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="emotionality"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Adventurousness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="adventurousness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Cognition)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="cognition"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Broad-mindedness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="broad-mindedness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Trust)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="trust"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Candor)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="candor"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Altruism)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="altruism"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Cooperation)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="cooperation"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Modesty)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="modesty"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Sympathy)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="sympathy"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Self-belief)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="self-belief"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Orderliness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="orderliness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Dutifulness)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="dutifulness"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Achievement-striving)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="achievement-striving"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Self-discipline)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="self-discipline"></p>
                    </div>
                  `
                }
                if (data.Template === 'Box-(Planning)') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="planning"></p>
                    </div>
                  `
                }
                if (data.Template === 'Line-Chart-Sub-ER') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Line-Chart-Sub-E') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Line-Chart-Sub-O') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Line-Chart-Sub-A') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Line-Chart-Sub-C') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-Coach-SuperTrait') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                          <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-Coach-Subtrait') { 
                  output += `
                    <div class="subtrait-chart-container">
                      <h5 class="text-center">Sub-trait cohort mean score</h5>
                      <div style="display: flex; justify-content: space-around;" > 
                        <div style="font-weight: 100;">ER</div>
                        <div style="font-weight: 100;">E</div>
                        <div style="font-weight: 100;">O</div>
                        <div style="font-weight: 100;">A</div>
                        <div style="font-weight: 100;">C</div>
                      </div>
                  `
                    let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        `
                    }
                  output += `
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Rationalist') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Rationalist</div>
                        <img class="img-rationalist" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-rationalist">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Solo-Operator') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Solo Operator</div>
                        <img class="img-speedometer-scale-solo-operator" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-solo-operator">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Agilator') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Agilator</div>
                        <img class="img-speedometer-scale-agilator" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-agilator">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Imagineer') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Imagineer</div>
                        <img class="img-speedometer-scale-imagineer" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-imagineer">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Strong-and-Stable') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Strong and Steady</div>
                        <img class="img-speedometer-scale-strong-and-stable" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-strong-and-stable">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Empathor') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Empathor</div>
                        <img class="img-speedometer-scale-empathor" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-empathor">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Team-Builder') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Team Builder</div>
                        <img class="img-speedometer-scale-team-builder" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-team-builder">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Boss-being') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Boss-being</div>
                        <img class="img-speedometer-scale-boss-being" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-boss-being">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Standard-Bearer') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Standard Bearer</div>
                        <img class="img-speedometer-scale-standard-bearer" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-standard-bearer">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Realist') { 
                  output += `
                    <div style="display:flex; justify-content:center;">
                      <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                        <div style="font-weight:bold">Realist</div>
                        <img class="img-speedometer-scale-realist" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                        <div class="score-speedometer-scale-realist">N/A</div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-All-Scale') { 
                  output += `
                    <div>
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role1" style="font-weight:bold">Rationalist</div>
                          <img class="all-scale-img1" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score1">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role2" style="font-weight:bold">Solo Operator</div>
                          <img class="all-scale-img2" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score2">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role3" style="font-weight:bold">Agilator</div>
                          <img class="all-scale-img3" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score3">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role4" style="font-weight:bold">Imagineer</div>
                          <img class="all-scale-img4" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score4">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role5" style="font-weight:bold">Strong and Steady</div>
                          <img class="all-scale-img5" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score5">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role6" style="font-weight:bold">Empathor</div>
                          <img class="all-scale-img6" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score6">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role7" style="font-weight:bold">Team Builder</div>
                          <img class="all-scale-img7" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score7">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role8" style="font-weight:bold">Boss-being</div>
                          <img class="all-scale-img8" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score8">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role9" style="font-weight:bold">Standard Bearer</div>
                          <img class="all-scale-img9" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score9">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role10" style="font-weight:bold">Realist</div>
                          <img class="all-scale-img10" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score10">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Comparison-Chart-Task-vs-Relationship') { 
                  output += `
      
                  <div style="display: flex;justify-content:space-evenly;">
                    <div style="display:flex; flex-direction:column; width: 500px;">
                      <div style="text-align:center;"><span style="font-weight:bold;" class ="task-vs-relationship-dynamic-title">Tasks Tasks and Relationships equally<span/></div>
                    <img class ="img-task-vs-relationship"src = "./Chart-Images/Bar/60.jpg" style = "margin-bottom: 10px; border: 1px solid black; box-shadow: 4px 5px #ccc;">
                    <div style="display:flex; justify-content: space-between; margin-bottom:30px;"><p>Task Focus</p><p>Relationship Focus</p></div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Comparison-Chart-Team-vs-Individual') { 
                  output += `
      
                  <div style="display:flex;justify-content:space-evenly;">
                    <div style="display:flex; flex-direction:column; width: 500px;">
                      <div style="text-align:center;"><span style="font-weight:bold;" class ="team-vs-individual-dynamic-title">Structure and Autonomy equally</span></div>
                    <img class ="img-team-vs-individual"src = "./Chart-Images/Bar/60.jpg" style = "margin-bottom: 10px; border: 1px solid black; box-shadow: 4px 5px #ccc;">
                    <div style="display:flex; justify-content: space-between; margin-bottom:30px;"><p>Team Structure</p><p>Individual Autonomy</p></div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Rationalist-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Rationalist_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Rationalist-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Rationalist_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-SoloOperator-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="SoloOperator_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-SoloOperator-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="SoloOperator_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Agilator-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Agilator_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Agilator-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Agilator_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Imagineer-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Imagineer_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Imagineer-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Imagineer_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-StrongandStable-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StrongandStable_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-StrongandStable-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StrongandStable_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Empathor-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Empathor_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Empathor-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Empathor_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-TeamBuilder-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="TeamBuilder_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-TeamBuilder-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="TeamBuilder_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Boss-being-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Bossbeing_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Boss-being-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Bossbeing_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-StandardBearer-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StandardBearer_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-StandardBearer-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StandardBearer_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Realist-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Realist_Subscale1"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Realist-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Realist_Subscale2"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-TeamvsIndividual') { 
                  output += `
                    <div>
                      <p id="TeamvsIndividual"></p>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-TaskvsRelationship') { 
                  output += `
                    <div>
                      <p id="TaskVSRelationship"></p>
                    </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Strictly-Literal') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Strictly Literal</div>
                      <img class="img-speedometer-strictly-literal" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-strictly-literal">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Disappearing') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Disappearing</div>
                      <img class="img-speedometer-disappearing" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-disappearing">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Pliable') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Pliable</div>
                      <img class="img-speedometer-pliable" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-pliable">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Dreaming') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Dreaming</div>
                      <img class="img-speedometer-dreaming" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-dreaming">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Emotionally-Numb') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Emotionally-Numb</div>
                      <img class="img-speedometer-emotionally-numb" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-emotionally-numb">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Emotive') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Emotive</div>
                      <img class="img-speedometer-emotive" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-emotive">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Amenable') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Amenable</div>
                      <img class="img-speedometer-amenable" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-amenable">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Dominating') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Dominating</div>
                      <img class="img-speedometer-dominating" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-dominating">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Conforming') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Conforming</div>
                      <img class="img-speedometer-conforming" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-conforming">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-Scale-Coldly-Logical') { 
                  output += `
                  <div style="display: flex;justify-content: center;">
                    <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                      <div style="font-weight:bold">Coldly-Logical</div>
                      <img class="img-speedometer-coldly-logical" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                      <div class="score-speedometer-coldly-logical">N/A</div>
                    </div>
                  </div>
                  `
                }
                if (data.Template === 'Speedometer-All-Scale-Pressure-Point') { 
                  output += `
                    <div>
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role1" style="font-weight:bold">Strictly Literal</div>
                          <img class="all-scale-img1" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score1">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role2" style="font-weight:bold">Disappearing</div>
                          <img class="all-scale-img2" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score2">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role3" style="font-weight:bold">Pliable</div>
                          <img class="all-scale-img3" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score3">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role4" style="font-weight:bold">Dreaming</div>
                          <img class="all-scale-img4" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score4">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role5" style="font-weight:bold">Emotionally Numb</div>
                          <img class="all-scale-img5" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score5">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role6" style="font-weight:bold">Emotive</div>
                          <img class="all-scale-img6" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score6">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role7" style="font-weight:bold">Amenable</div>
                          <img class="all-scale-img7" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score7">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role8" style="font-weight:bold">Dominating</div>
                          <img class="all-scale-img8" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score8">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role9" style="font-weight:bold">Conforming</div>
                          <img class="all-scale-img9" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score9">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role10" style="font-weight:bold">Coldly Logical</div>
                          <img class="all-scale-img10" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score10">N/A</div>
                        </div>
                      </div>
                    </div>
                        `
                    }
                // Pressure Point
                if (data.Template === 'Dynamic-Message-EmotionallyNumb-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="EmotionallyNumb_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Belief in myself</div>
                          <img class="img-Belief-in-myself" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Belief-in-myself">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-EmotionallyNumb-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="EmotionallyNumb_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Confidence in myself</div>
                          <img class="img-Confidence-in-myself" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Confidence-in-myself">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Disappearing-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Disappearing_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Avoiding the team</div>
                          <img class="img-Avoiding-the-team" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Avoiding-the-team">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Disappearing-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Disappearing_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Disengaging the team</div>
                          <img class="img-Disengaging-the-team" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Disengaging-the-team">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-StrictlyLiteral-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StrictlyLiteral_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Sticking to the data</div>
                          <img class="img-Sticking-to-the-data" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Sticking-to-the-data">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-StrictlyLiteral-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="StrictlyLiteral_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Proven ideas</div>
                          <img class="img-Proven-ideas" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Proven-ideas">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-ColdlyLogical-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="ColdlyLogical_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Self-protection</div>
                          <img class="img-Self-protection" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Self-protection">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-ColdlyLogical-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="ColdlyLogical_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Self-preservation</div>
                          <img class="img-Self-preservation" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Self-preservation">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Pliable-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Pliable_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Changing responsibility</div>
                          <img class="img-Changing-responsibility" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Changing-responsibility">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Pliable-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Pliable_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Changing structure</div>
                          <img class="img-Changing-structure" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Changing-structure">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Emotive-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Emotive_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">What My Team Thinks of Me</div>
                          <img class="img-What-My-Team-Thinks-of-Me" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-What-My-Team-Thinks-of-Me">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Emotive-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Emotive_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Feeling Negative Thoughts</div>
                          <img class="img-Feeling-Negative-Thoughts" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Feeling-Negative-Thoughts">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Dominating-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Dominating_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Controlling the Team</div>
                          <img class="img-Controlling-the-Team" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Controlling-the-Team">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Dominating-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Dominating_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Engaging the Team</div>
                          <img class="img-Engaging-the-Team" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Engaging-the-Team">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Dreaming-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Dreaming_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Lost in Ideas</div>
                          <img class="img-Lost-in-Ideas" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Lost-in-Ideas">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Dreaming-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Dreaming_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Being Dramatic</div>
                          <img class="img-Being-Dramatic" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Being-Dramatic">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Amenable-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Amenable_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Trust in the Team</div>
                          <img class="img-Trust-in-the-Team" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Trust-in-the-Team">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Amenable-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Amenable_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Self versus othersʼ interests</div>
                          <img class="img-Self-versus-others-interests" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Self-versus-others-interests">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
      
                if (data.Template === 'Dynamic-Message-Conforming-Subscale1-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Conforming_Subscale1"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Compliance</div>
                          <img class="img-Compliance" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Compliance">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Dynamic-Message-Conforming-Subscale2-with-box') { 
                  output += `
                    <div class="subtrait-box">
                      ` + data.Text + `
                      <p id="Conforming_Subscale2"></p>
                      <div style="display: flex;justify-content: center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div style="font-weight:bold">Structure</div>
                          <img class="img-Structure" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="score-Structure">N/A</div>
                        </div>
                      </div>
                    </div>
                  `
                }
                if (data.Template === 'Radar-Chart-360-Coach-Report') { 
                  let obj = JSON.parse(data.Text)
    
                  output += `
                    <div class="text-center radar-chart-360-coach-report-spinner">
                      <div class="spinner-border text-danger mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                    <div class="chart-container d-none radar-chart-360-coach-report-table">
                  `
    
                  for (const res of obj) {
                    output += `
                      <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                    `
                  }
    
                  output += `
                    </div>
                  `
                }
                if (data.Template === 'Table-360-Coach-Report-Competency') { 
                
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += 
                    `
                    <div class="text-center table-360-coach-report-competency-spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                    <table class="table360 table-border d-none table-360-coach-report-competency-table">
                      <tr>
                        <th class="table-border text-center" rowspan="2">Competency Importance Index</th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-0" colspan="2"></th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-1" colspan="2"></th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-2" colspan="2"></th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-3" colspan="2"></th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-4" colspan="2"></th>
                        <th class="d-none table-border text-center table-360-coach-report-competency-initial-5" colspan="2"></th>
                      </tr>
    
                      <tr>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-0" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-0" >My Respondent's Ranking</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-1" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-1" >My Respondent's Ranking</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-2" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-2" >My Respondent's Ranking</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-3" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-3" >My Respondent's Ranking</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-4" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-4" >My Respondent's Ranking</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-5" >Primary Supervisors</th>
                        <th style="font-size: 12px;" class="d-none table-border text-center table-360-coach-report-competency-label-5" >My Respondent's Ranking</th>
                      </tr>
                  `
                    res.Elements.forEach(response => {
                      output +=
                      `
                        <tr>
                          <td class="table-border">`+response.Description+`</td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-0-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-0-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-1-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-1-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-2-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-2-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-3-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-3-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-4-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-4-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-psup-score-5-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center table-360-coach-report-competency-respondent-score-5-`+response.Trait+`"></td>
                        </tr>
                      `
                    });
                  output +=
                    `
                    </table>
                    `
                  }
                }
                if (data.Template === 'Line-Chart-360-Coach-Report') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center line-chart-360-coach-report-spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <div class="line-chart-container d-none line-chart-360-coach-report-canvas">
                          <table class="table360 table-border mb-5">
                            <tr>
                                <th class="table-border text-center"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-0"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-1"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-2"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-3"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-4"></th>
                                <th class="d-none table-border text-center line-chart-report-360-initial-5"></th>
                            </tr>
    
                            <tr>
                                <td class="table-border">Primary Supervisors</td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-0"></td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-1"></td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-2"></td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-3"></td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-4"></td>
                                <td class="d-none table-border text-center line-chart-report-360-psup-5"></td>
                            </tr>
                            <tr>
                                <td class="table-border">Total Respondents</td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-0"></td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-1"></td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-2"></td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-3"></td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-4"></td>
                                <td class="d-none table-border text-center line-chart-report-360-respondent-5"></td>
                            </tr>
                          </table>
    
                          <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Chart-360-Summary-Coach-Report') {
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div class="text-center chart-360-summary-coach-report-spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360-summary-coach-report table-border d-none chart-360-summary-coach-report-table">
                        <tr>
                          <th class="table-border text-center" rowspan="2" colspan="2">Competency of Behavior Scores</th>
                          <th class="d-none table-border text-center coach-report-360-initial-0"></th>
                          <th class="d-none table-border text-center coach-report-360-initial-1"></th>
                          <th class="d-none table-border text-center coach-report-360-initial-2"></th>
                          <th class="d-none table-border text-center coach-report-360-initial-3"></th>
                          <th class="d-none table-border text-center coach-report-360-initial-4"></th>
                          <th class="d-none table-border text-center coach-report-360-initial-5"></th>
                        </tr>
    
                        <tr>
                          <th class="d-none table-border text-center coach-report-360-label-0" >My overall score</th>
                          <th class="d-none table-border text-center coach-report-360-label-1" >My overall score</th>
                          <th class="d-none table-border text-center coach-report-360-label-2" >My overall score</th>
                          <th class="d-none table-border text-center coach-report-360-label-3" >My overall score</th>
                          <th class="d-none table-border text-center coach-report-360-label-4" >My overall score</th>
                          <th class="d-none table-border text-center coach-report-360-label-5" >My overall score</th>
                      </tr>
                    `
                    res.Elements.forEach((response) => {
                      output += 
                      `
                        <tr>
                          <td class="table-border" rowspan="3">`+ response.Description +`</td>
                          <td class="table-border">PS</td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-0-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-1-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-2-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-3-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-4-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-psup-score-5-`+response.Trait+`"></td>
                        </tr>
                        <tr>
                          <td class="table-border" style="width:150px;">All Others</td>
                          <td class="d-none table-border text-center coach-report-360-all-score-0-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-all-score-1-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-all-score-2-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-all-score-3-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-all-score-4-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-all-score-5-`+response.Trait+`"></td>
                        </tr>
                        <tr>
                          <td class="table-border">Self</td>
                          <td class="d-none table-border text-center coach-report-360-self-score-0-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-self-score-1-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-self-score-2-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-self-score-3-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-self-score-4-`+response.Trait+`"></td>
                          <td class="d-none table-border text-center coach-report-360-self-score-5-`+response.Trait+`"></td>
                        </tr>
                        `
                    });
                      output += 
                        `
                          </table>
                        `
                  }
                }
                if (data.Template === 'Line-Chart-360-Coach-Report-Blind-Spot') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center line-chart-360-coach-report-blind-spot-spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <div class="line-chart-container d-none line-chart-360-coach-report-blind-spot-canvas">
                            <div>
                              <h6 style="margin-bottom: 0px; text-align:center;">Difference between Self and Others' Views</h6>
                              <p style="margin-top: 0px; font-weight: 100; text-align:center;">(positive score indicates over confidence; negative score indicates hidden strength) </p>
                            </div>
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Coach-PressurePoint-Breakdown-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-faculty-TIPpingpoint-overall-results">
                      <tr class ="faculty-TIP-heading">
                        <td></td>
                        <th> Very Low </th>
                        <th> Low </td>
                        <th> Typical </th>
                        <th> High </th>
                        <th> Very High </th>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#F47820;"><strong>Emotionally Numb</strong></td>
                        <td><span class="EmotionallyNumb_VeryLow"></span></td>
                        <td><span class="EmotionallyNumb_Low"></span></td>
                        <td><span class="EmotionallyNumb_Medium"></span></td>
                        <td><span class="EmotionallyNumb_High"></span></td>
                        <td><span class="EmotionallyNumb_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#F47820;"><strong>Emotive</strong></td>
                        <td><span class="Emotive_VeryLow"></span></td>
                        <td><span class="Emotive_Low"></span></td>
                        <td><span class="Emotive_Medium"></span></td>
                        <td><span class="Emotive_High"></span></td>
                        <td><span class="Emotive_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#32bfb8;"><strong>Disappearing</strong></td>
                        <td><span class="Disappearing_VeryLow"></span></td>
                        <td><span class="Disappearing_Low"></span></td>
                        <td><span class="Disappearing_Medium"></span></td>
                        <td><span class="Disappearing_High"></span></td>
                        <td><span class="Disappearing_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#32bfb8;"><strong>Dominating</strong></td>
                        <td><span class="Dominating_VeryLow"></span></td>
                        <td><span class="Dominating_Low"></span></td>
                        <td><span class="Dominating_Medium"></span></td>
                        <td><span class="Dominating_High"></span></td>
                        <td><span class="Dominating_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#a363d8;"><strong>Strictly Literal</strong></td>
                        <td><span class="StrictlyLiteral_VeryLow"></span></td>
                        <td><span class="StrictlyLiteral_Low"></span></td>
                        <td><span class="StrictlyLiteral_Medium"></span></td>
                        <td><span class="StrictlyLiteral_High"></span></td>
                        <td><span class="StrictlyLiteral_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#a363d8;"><strong>Dreaming</strong></td>
                        <td><span class="Dreaming_VeryLow"></span></td>
                        <td><span class="Dreaming_Low"></span></td>
                        <td><span class="Dreaming_Medium"></span></td>
                        <td><span class="Dreaming_High"></span></td>
                        <td><span class="Dreaming_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#40a3d9;"><strong>Coldly Logical</strong></td>
                        <td><span class="ColdlyLogical_VeryLow"></span></td>
                        <td><span class="ColdlyLogical_Low"></span></td>
                        <td><span class="ColdlyLogical_Medium"></span></td>
                        <td><span class="ColdlyLogical_High"></span></td>
                        <td><span class="ColdlyLogical_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#40a3d9;"><strong>Amenable</strong></td>
                        <td><span class="Amenable_VeryLow"></span></td>
                        <td><span class="Amenable_Low"></span></td>
                        <td><span class="Amenable_Medium"></span></td>
                        <td><span class="Amenable_High"></span></td>
                        <td><span class="Amenable_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#e9a928;"><strong>Pliable</strong></td>
                        <td><span class="Pliable_VeryLow"></span></td>
                        <td><span class="Pliable_Low"></span></td>
                        <td><span class="Pliable_Medium"></span></td>
                        <td><span class="Pliable_High"></span></td>
                        <td><span class="Pliable_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#e9a928;"><strong>Conforming</strong></td>
                        <td><span class="Conforming_VeryLow"></span></td>
                        <td><span class="Conforming_Low"></span></td>
                        <td><span class="Conforming_Medium"></span></td>
                        <td><span class="Conforming_High"></span></td>
                        <td><span class="Conforming_VeryHigh"></span></td>
                      </tr>
                      
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Coach-PressurePoint-Link-Chart') { 
                    output += `
                    <table class="table-coach-pressurepoint-link-chart">
                      <tr class="pressurepoint-link-chart-title">
                        <th><h3>Super Trait</h3></th>
                        <th><h3>Very Low Scores</h3></th>
                        <th><h3>Low Scores</h3></th>
                        <th><h3>High Scores</h3></th>
                        <th><h3>Very High Scores</h3></th>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#F47820;">Emotional Reactivity</h5>Propriety to experience and understand negative emotion such as worry, frustration moodness, social concerns, self-indulgence and stress.</td>
                        <td><h5 style="color:#F47820;">Emotionally Numb</h5>Calm, Patient, Even tempered, Self-confident, Resistent to stress. <br><br><strong><span class="Total_ER_VeryLow"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotionally Numb</h5>Calm, Patient, Even tempered, Self-confident, Resistent to stress. <br><br><strong><span class="Total_ER_Low"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotive</h5>Anxious, Angry, Moody/Sad, Vulnerable, Self-concious <br><br><strong><span class="Total_ER_High"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotive</h5>Anxious, Angry, Moody/Sad, Vulnerable, Self-concious <br><br><strong><span class="Total_ER_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#32bfb8;">Extraversion</h5>Propriety to get energy from being around others, and to be dominating groups.</td>
                        <td><h5 style="color:#32bfb8;">Disappearing</h5>Reserved, Prefers privacy, Passive, Slow-paced, Uncomplicated needs, Serious, Sober <br><br><strong><span class="Total_E_VeryLow"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Disappearing</h5>Reserved, Prefers privacy, Passive, Slow-paced, Uncomplicated needs, Serious, Sober <br><br><strong><span class="Total_E_Low"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Dominating</h5>Friendly, Warm, Sociable, Assertive, Dominant, High-activity, Excitement-seeking, Cheerful, Enthusiastic <br><br><strong><span class="Total_E_High"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Dominating</h5>Friendly, Warm, Sociable, Assertive, Dominant, High-activity, Excitement-seeking, Cheerful, Enthusiastic <br><br><strong><span class="Total_E_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#a363d8;">Openness to experience</h5>Inclination to take interest in new and/or unusual things</td>
                        <td><h5 style="color:#a363d8;">Strictly Literal</h5>Down to earth, Prosaic, Narrow range of activities & interests, Practical, Technical, Conventional <br><br><strong><span class="Total_O_VeryLow"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Strictly Literal</h5>Down to earth, Prosaic, Narrow range of activities & interests, Practical, Technical, Conventional <br><br><strong><span class="Total_O_Low"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Dreaming</h5>Imaginative, Creative, Artistic Interests, Authentic, Adventurous, Wide range of interests, Intellectually curious, Broad-minded, Unconventional <br><br><strong><span class="Total_O_High"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Dreaming</h5>Imaginative, Creative, Artistic Interests, Authentic, Adventurous, Wide range of interests, Intellectually curious, Broad-minded, Unconventional <br><br><strong><span class="Total_O_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h4 style="color:#40a3d9;">Agreeableness</h4>Tendency to "go along to get a long" and place high values on getting along well with others.</td>
                        <td><h4 style="color:#40a3d9;">Cold Logical</h4>Suspicious, Cautious, Indirect, Look after self, Competitive, Arrogant, Tough-minded<br><br><strong><span class="Total_A_VeryLow"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Cold Logical</h4>Suspicious, Cautious, Indirect, Look after self, Competitive, Arrogant, Tough-minded<br><br><strong><span class="Total_A_Low"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Amendable</h4>Trusting, Frank, Transparent with others, Altruistic, Cooperative, Modest, Humble, Sympathetic <br><br><strong><span class="Total_A_High"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Amendable</h4>Trusting, Frank, Transparent with others, Altruistic, Cooperative, Modest, Humble, Sympathetic <br><br><strong><span class="Total_A_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h4 style="color:#e9a928;">Conscientiousness</h4>Inclination to lead a structured and organized life</td>
                        <td><h4 style="color:#e9a928;">Pliable</h4>Dislike of precise detail, Inefficient, Disorganized, Flexible, Aimless, Bends the rules, Procrastinating, Spontaneous<br><br><strong><span class="Total_C_VeryLow"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Pliable</h4>Dislike of precise detail, Inefficient, Disorganized, Flexible, Aimless, Bends the rules, Procrastinating, Spontaneous<br><br><strong><span class="Total_C_Low"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Conforming</h4>Efficacious, Resourceful, Orderly, Methodical, Achievement striving, Dutiful, Self-disciplined, Planned, Deliberate<br><br><strong><span class="Total_C_High"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Conforming</h4>Efficacious, Resourceful, Orderly, Methodical, Achievement striving, Dutiful, Self-disciplined, Planned, Deliberate<br><br><strong><span class="Total_C_VeryHigh"></span></strong></td>
                      </tr>
    
                    </table>
                    `
                }
                if (data.Template === 'Coach-PressurePoint-Role-results-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-pressurepoint-role-results">
                      <tr class ="coach-PPP-heading">
                        <th> Coachee </th>
                        <th style="color:#F47820;"> Emotionally Numb </td>
                        <th style="color:#F47820;"> Emotive </th>
                        <th style="color:#32bfb8;"> Disappearing </th>
                        <th style="color:#32bfb8;"> Dominating </th>
                        <th style="color:#a363d8;"> Strictly Literal </th>
                        <th style="color:#a363d8;"> Dreaming </th>
                        <th style="color:#40a3d9;"> Coldly Logical </th>
                        <th style="color:#40a3d9;"> Amenable </th>
                        <th style="color:#e9a928;"> Pliable </th>
                        <th style="color:#e9a928;"> Conforming </th>
                      </tr>
                      <tr class ="coach-PPP-person0 d-none">
                        <td><span class="person0_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person0_EmotionallyNumb"></span></td>
                        <td style="color:#F47820;"><strong><span class="person0_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person0_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person0_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person0_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person0_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person0_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person0_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person0_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person0_Conforming"></strong></span></td>
                      </tr>
                      <tr class ="coach-PPP-person1 d-none">
                        <td><span class="person1_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person1_EmotionallyNumb"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person1_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person1_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person1_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person1_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person1_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person1_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person1_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person1_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person1_Conforming"></strong></span></td>
                      </tr>
                      <tr class ="coach-PPP-person2 d-none">
                        <td><span class="person2_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person2_EmotionallyNumb"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person2_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person2_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person2_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person2_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person2_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person2_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person2_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person2_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person2_Conforming"></strong></span></td>
                      </tr>
                      <tr class ="coach-PPP-person3 d-none">
                        <td><span class="person3_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person3_EmotionallyNumb"></span></td>
                        <td style="color:#F47820;"><strong><span class="person3_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person3_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person3_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person3_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person3_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person3_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person3_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person3_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person3_Conforming"></strong></span></td>
                      </tr>
                      <tr class ="coach-PPP-person4 d-none">
                        <td><span class="person4_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person4_EmotionallyNumb"></span></td>
                        <td style="color:#F47820;"><strong><span class="person4_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person4_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person4_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person4_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person4_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person4_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person4_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person4_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person4_Conforming"></strong></span></td>
                      </tr>
                      <tr class ="coach-PPP-person5 d-none">
                        <td><span class="person5_Initials"></span></td>
                        <td style="color:#F47820;"><strong><span class="person5_EmotionallyNumb"></span></td>
                        <td style="color:#F47820;"><strong><span class="person5_Emotive"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person5_Disappearing"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person5_Dominating"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person5_StrictlyLiteral"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person5_Dreaming"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person5_ColdlyLogical"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person5_Amenable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person5_Pliable"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person5_Conforming"></strong></span></td>
                      </tr>
                     </table>
                      <div><h5>VL = Very Low</h5></div>
                      <div><h5> L = Low</h5></div>
                      <div><h5> T = Typical</h5></div>
                      <div><h5> H = High</h5></div>
                      <div><h5>VH = Very High</h5></div>
                     </div>
                    `
                  
                }
                if (data.Template === 'Coach-TIPping-Speedometer-all-Chart') { 
                    output += `
                    <div>
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role1" style="font-weight:bold">Rationalist</div>
                          <img class="all-scale-img1" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score1">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role2" style="font-weight:bold">Solo Operator</div>
                          <img class="all-scale-img2" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score2">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role3" style="font-weight:bold">Agilator</div>
                          <img class="all-scale-img3" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score3">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role4" style="font-weight:bold">Imagineer</div>
                          <img class="all-scale-img4" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score4">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role5" style="font-weight:bold">Strong and Steady</div>
                          <img class="all-scale-img5" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score5">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role6" style="font-weight:bold">Empathor</div>
                          <img class="all-scale-img6" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score6">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role7" style="font-weight:bold">Team Builder</div>
                          <img class="all-scale-img7" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score7">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role8" style="font-weight:bold">Boss-being</div>
                          <img class="all-scale-img8" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score8">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role9" style="font-weight:bold">Standard Bearer</div>
                          <img class="all-scale-img9" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score9">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role10" style="font-weight:bold">Realist</div>
                          <img class="all-scale-img10" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score10">N/A</div>
                        </div>
                      </div>
                    </div>
                    `
                }
                if (data.Template === 'Coach-TIPpingPoint-Overall-results-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-TIPpingpoint-overall-results">
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th style="background-color:b4c6e7;"> Task </th>
                        <th style="background-color:b4c6e7;"> Relationships </td>
                        <th style="background-color:dae196;"> Individual Autonomy </th>
                        <th style="background-color:dae196;"> Team Structure </th>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_VeryHigh"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_VeryHigh"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_VeryHigh"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_High"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_High"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_High"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Typical/Medium</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_Typical"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_Typical"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_Typical"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_Low"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_Low"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_Low"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_VeryLow"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_VeryLow"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_VeryLow"></span></td>
                      </tr>
                      
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Coach-TIPpingPoint-Roles-results-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-TIPpingPoint-role-results">
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th> Task </th>
                        <th> Relationships </td>
                        <th> Individual Autonomy </th>
                        <th> Team Structure </th>
                        <th style="color:#32bfb8;"> Solo Operator </th>
                        <th style="color:#e9a928;"> Agilator </th>
                        <th style="color:#a363d8;"> Imagineer </th>
                        <th style="color:#F47820;""> Empathor </th>
                        <th style="color:#40a3d9;"> Team Builder </th>
                        <th style="color:#32bfb8;"> Boss-Being </th>
                        <th style="color:#e9a928;"> Standard Bearer </th>
                        <th style="color:#a363d8;"> Realist </th>
                        <th style="color:#F47820;"> Strong and Steady </th>
                        <th style="color:#40a3d9;"> Rationalist </th>
                      </tr>
                      <tr class ="coach-TIP-person0 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person0_Initials"></span></strong></td>
                        <td><span class="person0_Task"></span></td>
                        <td><span class="person0_Relationships"></span></td>
                        <td><span class="person0_IndividualAutonomy"></span></td>
                        <td><span class="person0_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person0_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person0_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person0_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person0_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person0_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person0_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person0_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person0_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person0_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person0_Rationalist"></strong></span></td>
                      </tr>
                      <tr class ="coach-TIP-person1 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person1_Initials"></span></strong></td>
                        <td><span class="person1_Task"></span></td>
                        <td><span class="person1_Relationships"></span></td>
                        <td><span class="person1_IndividualAutonomy"></span></td>
                        <td><span class="person1_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person1_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person1_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person1_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person1_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person1_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person1_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person1_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person1_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person1_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person1_Rationalist"></strong></span></td>
                      </tr>
                      <tr class ="coach-TIP-person2 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person2_Initials"></span></strong></td>
                        <td><span class="person2_Task"></span></td>
                        <td><span class="person2_Relationships"></span></td>
                        <td><span class="person2_IndividualAutonomy"></span></td>
                        <td><span class="person2_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person2_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person2_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person2_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person2_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person2_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person2_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person2_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person2_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person2_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person2_Rationalist"></strong></span></td>
                      </tr>
                      <tr class ="coach-TIP-person3 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person3_Initials"></span></strong></td>
                        <td><span class="person3_Task"></span></td>
                        <td><span class="person3_Relationships"></span></td>
                        <td><span class="person3_IndividualAutonomy"></span></td>
                        <td><span class="person3_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person3_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person3_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person3_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person3_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person3_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person3_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person3_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person3_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person3_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person3_Rationalist"></strong></span></td>
                      </tr>
                      <tr class ="coach-TIP-person4 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person4_Initials"></span></strong></td>
                        <td><span class="person4_Task"></span></td>
                        <td><span class="person4_Relationships"></span></td>
                        <td><span class="person4_IndividualAutonomy"></span></td>
                        <td><span class="person4_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person4_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person4_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person4_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person4_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person4_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person4_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person4_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person4_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person4_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person4_Rationalist"></strong></span></td>
                      </tr>
                      <tr class ="coach-TIP-person5 d-none">
                        <td style="border-right: 3px solid black"><strong><span class="person5_Initials"></span></strong></td>
                        <td><span class="person5_Task"></span></td>
                        <td><span class="person5_Relationships"></span></td>
                        <td><span class="person5_IndividualAutonomy"></span></td>
                        <td><span class="person5_TeamStructure"></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person5_SoloOperator"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person5_Agilator"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person5_Imagineer"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person5_Empathor"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person5_TeamBuilder"></strong></span></td>
                        <td style="color:#32bfb8;"><strong><span class="person5_BossBeing"></strong></span></td>
                        <td style="color:#e9a928;"><strong><span class="person5_StandardBearer"></strong></span></td>
                        <td style="color:#a363d8;"><strong><span class="person5_Realist"></strong></span></td>
                        <td style="color:#F47820;"><strong><span class="person5_StrongAndSteady"></strong></span></td>
                        <td style="color:#40a3d9;"><strong><span class="person5_Rationalist"></strong></span></td>
                      </tr>
                     </table>
                      <div><h5>VL = Very Low</h5></div>
                      <div><h5> L = Low</h5></div>
                      <div><h5> T = Typical</h5></div>
                      <div><h5> H = High</h5></div>
                      <div><h5>VH = Very High</h5></div>
                     </div>
                    `
                }
                if (data.Template === 'Coach-TIPpingPoint-Roles-results-Chart2') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-TIPpingpoint-overall-results">
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th colspan="3" style="background-color:b4c6e7;"> Individual Autonomy </th>
                        <th colspan="2" style="background-color:dae196;"> Relationship </td>
                        <th colspan="3" style="background-color:b4c6e7;"> Team Structure </th>
                        <th colspan="2" style="background-color:dae196;"> Task </th>
                      </tr>
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th style="color:#32bfb8; background-color:e7eff3;"> Solo Operator </th>
                        <th style="color:#e9a928; background-color:e7eff3;"> Agilator </th>
                        <th style="color:#a363d8; background-color:e7eff3;"> Imagineer </th>
                        <th style="color:#F47820; background-color:dae196;"> Empathor </th>
                        <th style="color:#40a3d9; background-color:dae196;"> Team Builder </th>
                        <th style="color:#32bfb8; background-color:e7eff3;"> Boss-Being </th>
                        <th style="color:#e9a928; background-color:e7eff3;"> Standard Bearer </th>
                        <th style="color:#a363d8; background-color:e7eff3;"> Realist </th>
                        <th style="color:#F47820; background-color:dae196;"> Strong and Steady </th>
                        <th style="color:#40a3d9; background-color:dae196;"> Rationalist </th>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_VeryHigh"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_High"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_High"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_High"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_High"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_High"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Typical/Medium</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_Typical"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_Low"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_Low"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_Low"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_Low"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_Low"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_VeryLow"></span></td>
                      </tr>
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Faculty-PressurePoint-Link-Chart') { 
                    output += `
                    <table class="table-faculty-pressurepoint-link-chart">
                      <tr class="pressurepoint-link-chart-title">
                        <th><h3>Super Trait</h3></th>
                        <th><h3>Very Low Scores</h3></th>
                        <th><h3>Low Scores</h3></th>
                        <th><h3>High Scores</h3></th>
                        <th><h3>Very High Scores</h3></th>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#F47820;">Emotional Reactivity</h5>Propriety to experience and understand negative emotion such as worry, frustration moodness, social concerns, self-indulgence and stress.</td>
                        <td><h5 style="color:#F47820;">Emotionally Numb</h5>Calm, Patient, Even tempered, Self-confident, Resistent to stress. <br><br><strong><span class="Total_ER_VeryLow"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotionally Numb</h5>Calm, Patient, Even tempered, Self-confident, Resistent to stress. <br><br><strong><span class="Total_ER_Low"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotive</h5>Anxious, Angry, Moody/Sad, Vulnerable, Self-concious <br><br><strong><span class="Total_ER_High"></span></strong></td>
                        <td><h5 style="color:#F47820;">Emotive</h5>Anxious, Angry, Moody/Sad, Vulnerable, Self-concious <br><br><strong><span class="Total_ER_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#32bfb8;">Extraversion</h5>Propriety to get energy from being around others, and to be dominating groups.</td>
                        <td><h5 style="color:#32bfb8;">Disappearing</h5>Reserved, Prefers privacy, Passive, Slow-paced, Uncomplicated needs, Serious, Sober <br><br><strong><span class="Total_E_VeryLow"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Disappearing</h5>Reserved, Prefers privacy, Passive, Slow-paced, Uncomplicated needs, Serious, Sober <br><br><strong><span class="Total_E_Low"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Dominating</h5>Friendly, Warm, Sociable, Assertive, Dominant, High-activity, Excitement-seeking, Cheerful, Enthusiastic <br><br><strong><span class="Total_E_High"></span></strong></td>
                        <td><h5 style="color:#32bfb8;">Dominating</h5>Friendly, Warm, Sociable, Assertive, Dominant, High-activity, Excitement-seeking, Cheerful, Enthusiastic <br><br><strong><span class="Total_E_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h5 style="color:#a363d8;">Openness to experience</h5>Inclination to take interest in new and/or unusual things</td>
                        <td><h5 style="color:#a363d8;">Strictly Literal</h5>Down to earth, Prosaic, Narrow range of activities & interests, Practical, Technical, Conventional <br><br><strong><span class="Total_O_VeryLow"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Strictly Literal</h5>Down to earth, Prosaic, Narrow range of activities & interests, Practical, Technical, Conventional <br><br><strong><span class="Total_O_Low"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Dreaming</h5>Imaginative, Creative, Artistic Interests, Authentic, Adventurous, Wide range of interests, Intellectually curious, Broad-minded, Unconventional <br><br><strong><span class="Total_O_High"></span></strong></td>
                        <td><h5 style="color:#a363d8;">Dreaming</h5>Imaginative, Creative, Artistic Interests, Authentic, Adventurous, Wide range of interests, Intellectually curious, Broad-minded, Unconventional <br><br><strong><span class="Total_O_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h4 style="color:#40a3d9;">Agreeableness</h4>Tendency to "go along to get a long" and place high values on getting along well with others.</td>
                        <td><h4 style="color:#40a3d9;">Cold Logical</h4>Suspicious, Cautious, Indirect, Look after self, Competitive, Arrogant, Tough-minded<br><br><strong><span class="Total_A_VeryLow"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Cold Logical</h4>Suspicious, Cautious, Indirect, Look after self, Competitive, Arrogant, Tough-minded<br><br><strong><span class="Total_A_Low"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Amendable</h4>Trusting, Frank, Transparent with others, Altruistic, Cooperative, Modest, Humble, Sympathetic <br><br><strong><span class="Total_A_High"></span></strong></td>
                        <td><h4 style="color:#40a3d9;">Amendable</h4>Trusting, Frank, Transparent with others, Altruistic, Cooperative, Modest, Humble, Sympathetic <br><br><strong><span class="Total_A_VeryHigh"></span></strong></td>
                      </tr>
    
                      <tr class="pressurepoint-link-chart-items">
                        <td><h4 style="color:#e9a928;">Conscientiousness</h4>Inclination to lead a structured and organized life</td>
                        <td><h4 style="color:#e9a928;">Pliable</h4>Dislike of precise detail, Inefficient, Disorganized, Flexible, Aimless, Bends the rules, Procrastinating, Spontaneous<br><br><strong><span class="Total_C_VeryLow"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Pliable</h4>Dislike of precise detail, Inefficient, Disorganized, Flexible, Aimless, Bends the rules, Procrastinating, Spontaneous<br><br><strong><span class="Total_C_Low"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Conforming</h4>Efficacious, Resourceful, Orderly, Methodical, Achievement striving, Dutiful, Self-disciplined, Planned, Deliberate<br><br><strong><span class="Total_C_High"></span></strong></td>
                        <td><h4 style="color:#e9a928;">Conforming</h4>Efficacious, Resourceful, Orderly, Methodical, Achievement striving, Dutiful, Self-disciplined, Planned, Deliberate<br><br><strong><span class="Total_C_VeryHigh"></span></strong></td>
                      </tr>
    
                    </table>
                    `
                }
                if (data.Template === 'Faculty-PressurePoint-Breakdown-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-faculty-TIPpingpoint-overall-results">
                      <tr class ="faculty-TIP-heading">
                        <td></td>
                        <th> Very Low </th>
                        <th> Low </td>
                        <th> Typical </th>
                        <th> High </th>
                        <th> Very High </th>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#F47820;"><strong>Emotionally Numb</strong></td>
                        <td><span class="EmotionallyNumb_VeryLow"></span></td>
                        <td><span class="EmotionallyNumb_Low"></span></td>
                        <td><span class="EmotionallyNumb_Medium"></span></td>
                        <td><span class="EmotionallyNumb_High"></span></td>
                        <td><span class="EmotionallyNumb_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#F47820;"><strong>Emotive</strong></td>
                        <td><span class="Emotive_VeryLow"></span></td>
                        <td><span class="Emotive_Low"></span></td>
                        <td><span class="Emotive_Medium"></span></td>
                        <td><span class="Emotive_High"></span></td>
                        <td><span class="Emotive_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#32bfb8;"><strong>Disappearing</strong></td>
                        <td><span class="Disappearing_VeryLow"></span></td>
                        <td><span class="Disappearing_Low"></span></td>
                        <td><span class="Disappearing_Medium"></span></td>
                        <td><span class="Disappearing_High"></span></td>
                        <td><span class="Disappearing_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#32bfb8;"><strong>Dominating</strong></td>
                        <td><span class="Dominating_VeryLow"></span></td>
                        <td><span class="Dominating_Low"></span></td>
                        <td><span class="Dominating_Medium"></span></td>
                        <td><span class="Dominating_High"></span></td>
                        <td><span class="Dominating_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#a363d8;"><strong>Strictly Literal</strong></td>
                        <td><span class="StrictlyLiteral_VeryLow"></span></td>
                        <td><span class="StrictlyLiteral_Low"></span></td>
                        <td><span class="StrictlyLiteral_Medium"></span></td>
                        <td><span class="StrictlyLiteral_High"></span></td>
                        <td><span class="StrictlyLiteral_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#a363d8;"><strong>Dreaming</strong></td>
                        <td><span class="Dreaming_VeryLow"></span></td>
                        <td><span class="Dreaming_Low"></span></td>
                        <td><span class="Dreaming_Medium"></span></td>
                        <td><span class="Dreaming_High"></span></td>
                        <td><span class="Dreaming_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#40a3d9;"><strong>Coldly Logical</strong></td>
                        <td><span class="ColdlyLogical_VeryLow"></span></td>
                        <td><span class="ColdlyLogical_Low"></span></td>
                        <td><span class="ColdlyLogical_Medium"></span></td>
                        <td><span class="ColdlyLogical_High"></span></td>
                        <td><span class="ColdlyLogical_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#40a3d9;"><strong>Amenable</strong></td>
                        <td><span class="Amenable_VeryLow"></span></td>
                        <td><span class="Amenable_Low"></span></td>
                        <td><span class="Amenable_Medium"></span></td>
                        <td><span class="Amenable_High"></span></td>
                        <td><span class="Amenable_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#e9a928;"><strong>Pliable</strong></td>
                        <td><span class="Pliable_VeryLow"></span></td>
                        <td><span class="Pliable_Low"></span></td>
                        <td><span class="Pliable_Medium"></span></td>
                        <td><span class="Pliable_High"></span></td>
                        <td><span class="Pliable_VeryHigh"></span></td>
                      </tr>
                      <tr class="faculty-TIP-items">
                        <td style="border-right: 3px solid black; color:#e9a928;"><strong>Conforming</strong></td>
                        <td><span class="Conforming_VeryLow"></span></td>
                        <td><span class="Conforming_Low"></span></td>
                        <td><span class="Conforming_Medium"></span></td>
                        <td><span class="Conforming_High"></span></td>
                        <td><span class="Conforming_VeryHigh"></span></td>
                      </tr>
                      
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Faculty-TIPpingPoint-Overall-results-Chart') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-TIPpingpoint-overall-results">
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th style="background-color:b4c6e7;"> Task </th>
                        <th style="background-color:b4c6e7;"> Relationships </td>
                        <th style="background-color:dae196;"> Individual Autonomy </th>
                        <th style="background-color:dae196;"> Team Structure </th>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_VeryHigh"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_VeryHigh"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_VeryHigh"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_High"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_High"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_High"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Typical/Medium</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_Typical"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_Typical"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_Typical"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_Low"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_Low"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_Low"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Task_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Relationship_VeryLow"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Individual_VeryLow"></span></td>
                        <td style="background-color:f5f8e1;"><span class="Team_VeryLow"></span></td>
                      </tr>
                      
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Faculty-TIPping-Speedometer-all-Chart') { 
                    output += `
                    <div>
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role1" style="font-weight:bold">Rationalist</div>
                          <img class="all-scale-img1" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score1">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role2" style="font-weight:bold">Solo Operator</div>
                          <img class="all-scale-img2" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score2">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role3" style="font-weight:bold">Agilator</div>
                          <img class="all-scale-img3" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score3">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role4" style="font-weight:bold">Imagineer</div>
                          <img class="all-scale-img4" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score4">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role5" style="font-weight:bold">Strong and Steady</div>
                          <img class="all-scale-img5" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score5">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-left:1rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role6" style="font-weight:bold">Empathor</div>
                          <img class="all-scale-img6" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score6">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role7" style="font-weight:bold">Team Builder</div>
                          <img class="all-scale-img7" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score7">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role8" style="font-weight:bold">Boss-being</div>
                          <img class="all-scale-img8" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score8">N/A</div>
                        </div>
                      </div>
      
                      <div style="display:flex; justify-content:center;">
                        <div style="text-align:center; width:200px; margin-bottom:3rem; margin-right:1rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role9" style="font-weight:bold">Standard Bearer</div>
                          <img class="all-scale-img9" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score9">N/A</div>
                        </div>
      
                        <div style="text-align:center; width:200px; margin-bottom:3rem; background: rgba(238, 238, 238, .5);">
                          <div class="all-scale-role10" style="font-weight:bold">Realist</div>
                          <img class="all-scale-img10" src="./Chart-Images/Speedometer/0.png" style="width: 200px;">
                          <div class="all-scale-score10">N/A</div>
                        </div>
                      </div>
                    </div>
                    `
                }
                if (data.Template === 'Faculty-TIPpingPoint-Roles-results-Chart2') { 
                    output += `
                    <div style="margin-top: 50px;">
                    <table class = "table-coach-TIPpingpoint-overall-results">
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th colspan="3" style="background-color:b4c6e7;"> Individual Autonomy </th>
                        <th colspan="2" style="background-color:dae196;"> Relationship </td>
                        <th colspan="3" style="background-color:b4c6e7;"> Team Structure </th>
                        <th colspan="2" style="background-color:dae196;"> Task </th>
                      </tr>
                      <tr class ="coach-TIP-heading">
                        <td></td>
                        <th style="color:#32bfb8; background-color:e7eff3;"> Solo Operator </th>
                        <th style="color:#e9a928; background-color:e7eff3;"> Agilator </th>
                        <th style="color:#a363d8; background-color:e7eff3;"> Imagineer </th>
                        <th style="color:#F47820; background-color:dae196;"> Empathor </th>
                        <th style="color:#40a3d9; background-color:dae196;"> Team Builder </th>
                        <th style="color:#32bfb8; background-color:e7eff3;"> Boss-Being </th>
                        <th style="color:#e9a928; background-color:e7eff3;"> Standard Bearer </th>
                        <th style="color:#a363d8; background-color:e7eff3;"> Realist </th>
                        <th style="color:#F47820; background-color:dae196;"> Strong and Steady </th>
                        <th style="color:#40a3d9; background-color:dae196;"> Rationalist </th>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_VeryHigh"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_VeryHigh"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_VeryHigh"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>High</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_High"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_High"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_High"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_High"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_High"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_High"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Typical/Medium</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_Typical"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_Typical"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_Typical"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_Low"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_Low"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_Low"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_Low"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_Low"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_Low"></span></td>
                      </tr>
                      <tr class="coach-TIP-items">
                        <td style="border-right: 3px solid black"><strong>Very Low</strong></td>
                        <td style="background-color:e7eff3;"><span class="Solo_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Agilator_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Imagineer_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="Empathor_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="TeamBuilder_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="BossBeing_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="StandardBearer_VeryLow"></span></td>
                        <td style="background-color:e7eff3;"><span class="Realist_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="StrongAndSteady_VeryLow"></span></td>
                        <td style="background-color:dae196;"><span class="Rationalist_VeryLow"></span></td>
                      </tr>
                     </table>
                     </div>
                    `
                }
                if (data.Template === 'Bar-chart-Faculty-Sub') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {  
                      output += `
                      <table class="main-table-faculty">
                    `
                    res.Elements.forEach((response) => {
                      output += `
                        <tr>
                          <td class="_data-heading" style="padding-right:0; width: 250px;">` + response.Description + `</td>
                          <td class="_data-heading" style="padding-right:0;">
                            <div class="bar-container">
                              <div class="bar-difference percentile-` + response.Trait + `"></div>
                              <div class="percentile-` + response.Trait + `-white-space"></div>
                            </div>
                          </td>
                          <td class="_data-heading">
                            <div style="color:#` + response.Color + `; text-align: center;"><span class="cohort_` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                    })
                    output += `
                      </table>
                    `
                  }
                  
                }
                if (data.Template === 'Bar-Chart-360-Faculty-Head') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += 
                    `
                      <div class="text-center bar-chart-360-faculty-head-spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360 d-none bar-chart-360-faculty-head-table">
                        <tr>
                          <th class="table-border text-center"></th>
                          <th class="table-border text-center">`+ res.heading1 +`</th>
                          <th class="table-border text-center">`+ res.heading2 +`</th>
                        </tr>
                      `
                      res.Elements.forEach(response => {
                        output += 
                        `
                          <tr class="">
                            <td class="table-border" style="border-top: 2px solid #000; font-weight:bold;">`+ response.Description +`</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-`+response.Trait+`" style="border-top: 2px solid #000;"></td>
                            <td class="table-border text-center" style="border-top: 2px solid #000;">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-head" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-head" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-head" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-head" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-head" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Primary supervisors</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-psup1-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-Psup" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Peers</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-peer2-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-P" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-P" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-P" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-P" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-P" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Direct reports</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-direct3-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-D" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-D" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-D" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-D" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-D" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Internal stakeholders</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-internal4-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-I" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-I" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-I" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-I" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-I" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">External stakeholders</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-external5-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-E" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-E" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-E" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-E" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-E" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Participants(self)</td>
                            <td class="table-border text-center faculty-head-cohort-mean-score-participant-self-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-Self" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-Self" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
                        `
                      });
                      output += 
                        `
                      </table>
                    `
                  }
                }
                if(data.Template === 'Bar-Chart-360-Faculty-Sub') {
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += 
                    `
                      <div class="text-center bar-chart-360-faculty-sub-spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360 d-none bar-chart-360-faculty-sub-table">
                        <tr>
                          <th class="table-border text-center"></th>
                          <th class="table-border text-center">`+ res.heading1 +`</th>
                          <th class="table-border text-center">`+ res.heading2 +`</th>
                        </tr>
                      `
                      res.Elements.forEach(response => {
                        output += 
                        `
                          <tr class="">
                            <td class="table-border" style="border-top: 2px solid #000; font-weight:bold;">`+ response.Description +`</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-`+response.Trait+`" style="border-top: 2px solid #000;"></td>
                            <td class="table-border text-center" style="border-top: 2px solid #000;">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Primary supervisors</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-psup1-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Peers</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-peer2-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Direct reports</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-direct3-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Internal stakeholders</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-internal4-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">External stakeholders</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-external5-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
    
                          <tr class="">
                            <td class="table-border" style="padding-left: 2rem;">Participants(self)</td>
                            <td class="table-border text-center faculty-sub-cohort-mean-score-participant-self-`+response.Trait+`"></td>
                            <td class="table-border text-center">
                              <ul class="Histogram-barchart1">
                                <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                              </ul>
                            </td>
                          </tr>
                        `
                      });
                      output += 
                        `
                      </table>
                    `
                  }
                }
                if(data.Template === 'Table-360-Faculty-Report-Competency') {
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += 
                    `
                    <div class="text-center table-360-faculty-report-competency-spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                    <table class="table360-competency d-none table-360-faculty-report-competency-table">
                      <tr>
                        <th class="heading-label-CII">
                          <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                        </th>
                        <th class="heading-label-CII">
                          <div style="display: flex; align-items: center;">
                            <div>
                              <div>`+ res.heading2 +`</div>
                            </div>
                          </div>
                        </th>
                        <th class="heading-label-CII-peers">
                          <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                        </th>
                        <th class="heading-label-CII-direct">
                          <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                        </th>
                        <th class="heading-label-CII-pd">
                          <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                        </th>
                        <th class="heading-label-CII-internal">
                          <div style="display: flex; align-items: center;">`+ res.heading6 +`</div>
                        </th>
                        <th class="heading-label-CII-external">
                          <div style="display: flex; align-items: center;">`+ res.heading7 +`</div>
                        </th>
                        <th class="heading-label-CII-ie">
                          <div style="display: flex; align-items: center;">`+ res.heading8 +`</div>
                        </th>
                      </tr>
                  `
                    res.Elements.forEach(response => {
                      output +=
                      `
                      <tr>
                        <td class="heading-text-CII">`+ response.Description +`</td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-psup1-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-peer2-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-direct3-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-internal4-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-external5-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-participant-self-` + response.Trait + `"></span></div>
                        </td>
                        <td class="sub-heading-score-CII">
                          <div><span class="faculty-competency-cohort-all-1000-` + response.Trait + `"></span></div>
                        </td>
                    </tr>
                      `
                    });
                    output +=
                    `
                      </table>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Faculty-Report') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center radar-360-faculty-report-spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <div class="chart-container d-none radar-360-faculty-report-chart">
                        `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-Faculty-SuperTrait') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="line-chart-container">
                          <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-Faculty-Subtrait') { 
                  output += `
                    <div class="subtrait-chart-container">
                      <h5 class="text-center">Sub-trait cohort mean score</h5>
                      <div style="display: flex; justify-content: space-around;" > 
                        <div style="font-weight: 100;">ER</div>
                        <div style="font-weight: 100;">E</div>
                        <div style="font-weight: 100;">O</div>
                        <div style="font-weight: 100;">A</div>
                        <div style="font-weight: 100;">C</div>
                      </div>
                  `
                    let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        `
                    }
                  output += `
                    </div>
                  `
                }
                if (data.Template === 'Table-Qsort-Bar-Chart-Sub') {
                  output += `
                    <div class="text-center qsort_spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  `
                  output += `<div class="qsort-wrapper qsort_table d-none">`
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.AllCompanies.forEach((response) => {
                        output += `
                          <div class="qsort-sortme">
                            <span class="correlation-data-for-sorting-`+response.CompanyAcronym+` d-none"></span>
                            `+response.htmlData+`
                          </div>
                        `
                      })
                    }
                  output += `</div>`
                }
                if (data.Template === 'Table-Qsort-Bar-Chart-Head') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center qsort_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="qsort_table d-none">
                          <thead>
                            <tr>
                              <th class="heading-label-blindspots" colspan="2">
                                `+ res.heading1 +`
                              </th>
                              <th id="personalScore" class="heading-label-blindspots">
                                `+ res.heading2 +`
                              </th>
                              <th id="groupScore" class="heading-label-blindspots">
                                `+ res.heading3 +`
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="Table-Qsort-BarChart-Section2"></tbody>
                    </table>
                      `
                  }
                }
                if (data.Template === 'Table-Qsort-Correlation') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center qsort_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="qsort_table d-none">
                          <thead>
                            <tr>
                              <th class="heading-label-blindspots">
                                `+ res.heading1 +`
                              </th>
                              <th class="heading-label-blindspots">
                                `+ res.heading2 +`
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="Table-Qsort-Correlation-`+res.CompanyType+`"></tbody>
                        </table>
                      `
                  }
                }
                if (data.Template === 'Table-Qsort-Difference') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center qsort_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <div class="Has-`+res.Level+`-Result-`+res.CompanyType+` d-none">
                          <p>The following positive differences suggest that your group has a significantly <b>`+res.Level+`</b> level of these characteristics as compared to the type: <b><span class="`+res.Level+`-companyName-`+res.CompanyType+`"></span>.</b> </p>
                          <table class="qsort_table d-none">
                            <thead>
                              <tr>
                                <th class="heading-label-blindspots">
                                  #
                                </th>
                                <th class="heading-label-blindspots">
                                  `+ res.heading1 +`
                                </th>
                                <th class="heading-label-blindspots">
                                  `+ res.heading2 +`
                                </th>
                                <th class="heading-label-blindspots">
                                  `+ res.heading3 +`
                                </th>
                              </tr>
                            </thead>
      
                            <tbody class="Table-Qsort-`+res.Level+`-Difference-`+res.CompanyType+`"></tbody>
                          </table>
                        </div>
                        <p class="No-`+res.Level+`-Result-`+res.CompanyType+` d-none">There are no characteristics which are significantly `+res.Level+`er than the type: <b><span class="`+res.Level+`-companyName-`+res.CompanyType+`"></span>.</b></p>
                      `
                  }
                }
                if (data.Template === 'Group-Characteristics') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="text-center qsort_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <div class="subtrait-box qsort_table d-none">
                          <p><b>`+ res.heading1 +`</b></p>
                          <ul class="group-char-container">
                          </ul>
                        </div>
                      `
                  }
                }
                if(data.Template === 'Company-Description'){
                  const obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div id="`+res.CompanyType+`Description"></div>
                    `
                  }
                }
                if(data.Template === 'Company-Characteristic'){
                  const obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div id="`+res.CompanyType+`Characteristic"></div>
                    `
                  }
                }
                if(data.Template === 'Company-PotentialActions'){
                  const obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div id="`+res.CompanyType+`PotentialActions"></div>
                    `
                  }
                }            
                if (data.Template === 'VFP-Speedometer-Conformity') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Conformity</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-conformity"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-conformity"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Tradition') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Tradition</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-tradition"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-tradition"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }   
                if (data.Template === 'VFP-Speedometer-Security') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Security</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-security"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-security"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Power') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Power</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-power"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-power"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Achievement') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Achievement</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-achievement"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-achievement"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Indulgence') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Indulgence</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-indulgence"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-indulgence"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Stimulation') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Stimulation</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-stimulation"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-stimulation"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Self-direction') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Self-direction</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-self-direction"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-self-direction"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-Welfare-of-all') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Welfare of all</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-welfare-of-all"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-welfare-of-all"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }   
                if (data.Template === 'VFP-Speedometer-Benevolence') { 
                  output += `
                    <div style="text-align: center; width: 150px;">
                      <div style="font-weight: bold;">Benevolence</div>
                      <div class="speedbox">
                        <div class="speedbox__score score-vfp-speedometer-benevolence"></div>
                        <div class="speedbox__groove"></div>
                        <div class="speedbox__odo">
                          <div class="speedbox__down breakpoint-vfp-speedometer-benevolence"></div>
                        </div>
                        <div class="speedbox__base"></div>
                      </div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Speedometer-All-Scale') { 
                    output += `
                      <div class="text-center vfp_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <div class="vfp_data d-none mt-5">
                        <div style="display:flex; justify-content:center;">
                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-0"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-0"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-0"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-0"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>
                  
                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-1"></div>
                            <div class="speedbox" style="margin-left: 1rem; margin-right: 1rem;">
                              <div class="speedbox__score all-scale-vfp-score-1"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-1"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-1"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-2"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-2"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-2"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-2"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-3"></div>
                            <div class="speedbox" style="margin-left: 1rem; margin-right: 1rem;">
                              <div class="speedbox__score all-scale-vfp-score-3"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-3"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-3"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-4"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-4"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-4"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-4"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>
                        </div>

                        <div style="display:flex; justify-content:center;">
                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-5"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-5"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-5"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-5"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>
                  
                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-6"></div>
                            <div class="speedbox" style="margin-left: 1rem; margin-right: 1rem;">
                              <div class="speedbox__score all-scale-vfp-score-6"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-6"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-6"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-7"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-7"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-7"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-7"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-8"></div>
                            <div class="speedbox" style="margin-left: 1rem; margin-right: 1rem;">
                              <div class="speedbox__score all-scale-vfp-score-8"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-8"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-8"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>

                          <div>
                            <div style="font-weight: bold; text-align: center;" class="all-scale-vfp-role-9"></div>
                            <div class="speedbox">
                              <div class="speedbox__score all-scale-vfp-score-9"></div>
                              <div class="speedbox__groove"></div>
                              <div class="speedbox__odo">
                                <div class="all-scale-vfp-score-value-9"></div>
                                <div class="speedbox__down all-scale-vfp-breakpoint-9"></div>
                              </div>
                              <div class="speedbox__base"></div>
                            </div>
                          </div>
                        </div>

                      </div>
                    `
                  
                }
                if (data.Template === 'Comparison-Power-Distance') { 
                  output += `
                    <div class="text-center vfp_spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <div class="vfp_data d-none" style="margin-top: 50px;">
                      <div style="text-align: center;"><b>Power distance</b></div>
                      <div class="vfp-bg-chart">
                        <div class="vfp-chart">
                          <div class="vfp-bar vfp-bar-power-distance" style="background: linear-gradient(to right, #0E5071, #32C225); width: 240px; left: 480px;"></div>
                          <div class="line line1 vfp-position1-power-distance"></div>
                          <div class="line line2 vfp-position2-power-distance"></div>
                          <div class="line line3 vfp-position3-power-distance"></div>
                          <div class="line line4 vfp-position4-power-distance"></div>
                          <div class="line line5 vfp-position5-power-distance"></div>
                        </div>
                      </div>
                      <div style="text-align: center; margin-bottom: 30px;" class="vfp-message-power-distance"></div>
                    </div>
                  `
                }
                if (data.Template === 'Comparison-Goal-Orientation') { 
                  output += `
                    <div class="text-center vfp_spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <div class="vfp_data d-none">
                      <div style="text-align: center;"><b>Goal orientation</b></div>
                      <div class="vfp-bg-chart">
                        <div class="vfp-chart">
                          <div class="vfp-bar vfp-bar-goal-orientation" style="background: linear-gradient(to right, #0E5071, #32C225); width: 240px; left: 480px;"></div>
                          <div class="line line1 vfp-position1-goal-orientation"></div>
                          <div class="line line2 vfp-position2-goal-orientation"></div>
                          <div class="line line3 vfp-position3-goal-orientation"></div>
                          <div class="line line4 vfp-position4-goal-orientation"></div>
                          <div class="line line5 vfp-position5-goal-orientation"></div>
                        </div>
                      </div>
                      <div style="text-align: center; margin-bottom: 30px;" class="vfp-message-goal-orientation"></div>
                    </div>
                  `
                }
                if (data.Template === 'Comparison-Cultural-Imposition') { 
                  output += `
                    <div class="text-center vfp_spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <div class="vfp_data d-none">
                      <div style="text-align: center;"><b>Cultural imposition</b></div>
                      <div class="vfp-bg-chart">
                        <div class="vfp-chart">
                          <div class="vfp-bar vfp-bar-cultural-imposition" style="background: linear-gradient(to right, #A463D8, #F47820); width: 240px; left: 480px;"></div>
                          <div class="line line1 vfp-position1-cultural-imposition"></div>
                          <div class="line line2 vfp-position2-cultural-imposition"></div>
                          <div class="line line3 vfp-position3-cultural-imposition"></div>
                          <div class="line line4 vfp-position4-cultural-imposition"></div>
                          <div class="line line5 vfp-position5-cultural-imposition"></div>
                        </div>
                      </div>
                      <div style="text-align: center; margin-bottom: 30px;" class="vfp-message-cultural-imposition"></div>
                    </div>
                  `
                }
                if (data.Template === 'Comparison-Uncertainty-Avoidance') { 
                  output += `
                    <div class="text-center vfp_spinner">
                      <div class="spinner-border text-danger mb-5 mt-5" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>

                    <div class="vfp_data d-none">
                      <div style="text-align: center;"><b>Uncertainty avoidance</b></div>
                      <div class="vfp-bg-chart">
                        <div class="vfp-chart">
                          <div class="vfp-bar vfp-bar-uncertainty-avoidance" style="background: linear-gradient(to right, #A463D8, #F47820); width: 240px; left: 480px;"></div>
                          <div class="line line1 vfp-position1-uncertainty-avoidance"></div>
                          <div class="line line2 vfp-position2-uncertainty-avoidance"></div>
                          <div class="line line3 vfp-position3-uncertainty-avoidance"></div>
                          <div class="line line4 vfp-position4-uncertainty-avoidance"></div>
                          <div class="line line5 vfp-position5-uncertainty-avoidance"></div>
                        </div>
                      </div>
                      <div style="text-align: center; margin-bottom: 30px;" class="vfp-message-uncertainty-avoidance"></div>
                    </div>
                  `
                }    
                if (data.Template === 'VFP-Radar-Chart') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div class="text-center vfp_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <div class="vfp_data chart-container-vfp subtrait-box-vfp d-none">
                        <p class="custom-orange"><span style="font-weight: 400;">` + res.Title + `</span></p>
                        <canvas class="VFP_Radar_Chart"></canvas>
                      </div>
                    `
                  }
                }    
                // for New 360 Reports - April 24 2023 - started work 
                if (data.Template === 'Bar-Chart-New-360-Head') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                      <div class="row">
                        <div class="col-6">
                          <h5 class="mb-3">`+ res.heading3+`</h5>

                          <div class="text-center f5-spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>

                          <table class="table d-none f5-table" style="border-top: 1px solid #ccc;">
                            <thead>
                              <tr>
                                <th class="text-center" scope="col"><span style="color: rgb(184, 113, 26)">S</span> = Self</th>
                                <th class="text-center" scope="col" style="color: black">O = Average All Others</th>
                              </tr>
                            </thead>

                            <tbody>
                              `
                                res.Competencies.forEach((resCompetency) => {
                                  output += `
                                    <tr>
                                      <td style="width: 15%">`+ resCompetency.Name+`</td>
                                      <td style="padding-right: 35px;">
                                        
                                        <div style="position:relative; bottom: 5">
                                          <div class="custom-bar-Others-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                            <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                          </div>
                                        </div>

                                        <div class="custom-bar-graph">
                                          <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                          <div class="vertical-line"></div>

                                          <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                          <div class="vertical-line"></div>

                                          <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                          <div class="vertical-line"></div>

                                          <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                        </div>

                                        <div style="position:relative; bottom: 23">
                                          <div class="custom-bar-Self-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                            <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                          </div>
                                        </div>

                                      </td>
                                    </tr>
                                  `
                                })
                              output += `
                            </tbody>
                          </table>
                        </div>

                        <div class="col-6">
                          <!-- Top Behaviours   -->
                          <div>
                            <h5 class="mb-3">`+ res.heading4+`</h5>

                            <div class="text-center f5-spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>

                            <ul class="d-none f5-table top-behaviours-wrapper">
                              `
                                for (const res of obj) {
                                  res.Elements.forEach((response) => {
                                    output += `
                                      <div class="top-behaviours-sortme">
                                        <li class="top_behaviours_` + response.Trait + ` top-behaviours-average-score"></li>
                                        <li>`+response.Behaviour+` (`+response.Competency+`)</li>
                                      </div>
                                    `
                                  })
                                }
                              output += `
                            </ul>
                          </div>

                          <!-- Underappreciated strengths   -->
                          <div>
                            <h5 class="mb-3">`+ res.heading5+`</h5>

                            <div class="text-center f5-spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>

                            <ul class="d-none f5-table" id="potential-blind-spots-wrapper">
                              <p class="d-none potential-blind-spots-message"><i>Your understanding of your strengths aligns with that of your nominees</i></p>
                              `
                                for (const res of obj) {
                                  res.Elements.forEach((response) => {
                                    output += `
                                      <li class="d-none potential-blind-spots-all-rows potential-blind-spots-row-` + response.Trait + `">`+response.Behaviour+`(`+response.Competency+`)</li>
                                    `
                                  })
                                }
                              output += `
                            </ul>
                          </div>

                          <!-- Potential Blindspots   -->
                          <div>
                            <h5 class="mb-3">`+ res.heading6+`</h5>

                            <div class="text-center f5-spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>

                            <ul class="d-none f5-table" id="undiscovered-strengths-wrapper">
                              <p class="d-none undiscovered-strengths-message">You are already aware of your strengths and use them effectively</p>
                              `
                                for (const res of obj) {
                                  res.Elements.forEach((response) => {
                                    output += `
                                      <li class="d-none undiscovered-strengths-all-rows undiscovered-strengths-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`)</li>
                                    `
                                  })
                                }
                              output += `
                            </ul>
                          </div>

                        </div>
                      </div>

                      <!-- Competency Importance Index - Section 2 -->
                       <div class="row mt-2" style="margin-left: 2px; margin-right: 2px;">
                        <!-- <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                          <h5 class="mb-3">`+ res.ciiHeading1+`</h5> -->
                          <!-- Competency Importance Index -->
                         <!-- <div class="text-center f5-spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <table class="f5-table d-none" style="width: 100%">
                            <tr>
                              <th class="heading-label-CII"></th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading2 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading3 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading4 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading5 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading6 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="cii-table-column" style="display: flex; align-items: center; font-size: 12px;">`+ res.ciiHeading7 +`</div>
                              </th>
                          
                            </tr> -->
                            `
                              // res.ciiElements.forEach(response => {
                              //   console.log(response)
                              //   console.log("Obj",obj)
                              //     output +=`
                              //     <tr style="border-bottom: 1px solid #ededed">
                              //       <td style="text-align: center; color:`+ res.Colors.color_s +`" class="heading-text-CII color-cii-`+ response.Description +`">`+ response.Description +`</td>                            
                              //       <!-- <td style="text-align: center; color:`+ res.Colors.color_o +`" class="sub-heading-text-CII_new_kdy_${response.Description}"></td>                            
                                  
                              //        <td style="text-align: center" class="sub-heading-text-CII_new_dto_${response.Description}"></td>                            
                              //       <td style="text-align: center" class="sub-heading-text-CII_new_cp_${response.Description}"></td>                            
                              //       <td style="text-align: center" class="sub-heading-text-CII_new_scp_${response.Description}"></td>                            
                              //       <td style="text-align: center" class="sub-heading-text-CII_new_lic_${response.Description}"></td>                            
                              //       <td style="text-align: center" class="sub-heading-text-CII_new_ldf_${response.Description}"></td>                            
                              //     </tr> -->
                                  
                              //     `
                              //     res.Competencies.forEach(competency =>{
                              //       output +=`
                                           
                              //       <td style="text-align: center" class="sub-heading-text-CII_new_${competency.Acronym}_${response.Description}_new"></td> 
                                    
                              //       `
                              //     })
                              // });
                            output += `
                            <!-- </tr>
                          </table>
                        </div> -->

                        <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                        <div class="text-center competency_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <h5 class="mb-3">`+ res.ciiHeading1+`</h5>
                      <table class="table360-competency d-none" style="width: 100%">
                        <!-- <tr>
                         <th class="heading-label-CII">
                            <div style="display: flex; align-items: center;">`+ res.ciiHeading1 +`</div>
                          </th>
                          <th class="heading-label-CII d-none">
                            <div style="display: flex; align-items: center;">
                              <div>
                                <div>Primary supervisors</div>
                              </div>
                            </div>
                          </th>
                          <th class="heading-label-CII-peers d-none">
                            <div style="display: flex; align-items: center;">Peers</div>
                          </th>
                          <th class="heading-label-CII-direct d-none">
                            <div style="display: flex; align-items: center;">Direct Report</div>
                          </th>
                          <th class="heading-label-CII-pd d-none">
                            <div style="display: flex; align-items: center;">Peers/Direct Report</div>
                          </th>
                          <th class="heading-label-CII-internal d-none">
                            <div style="display: flex; align-items: center;">Internal stakeholders</div>
                          </th>
                          <th class="heading-label-CII-external d-none">
                            <div style="display: flex; align-items: center;">External stakeholders</div>
                          </th>
                          <th class="heading-label-CII-ie d-none">
                            <div style="display: flex; align-items: center;">Internal/External stakeholders</div>
                          </th>
                          <th class="heading-label-CII-pdie d-none">
                            <div style="display: flex; align-items: center;">Peers/Direct reports/Internal stakeholders/External stakeholders</div>
                          </th>
                          <th class="heading-label-CII">
                            <div style="display: flex; align-items: center;">O</div>
                          </th>
                          <th class="heading-label-CII">
                            <div style="display: flex; align-items: center;">S</div>
                          </th>
                          <th class="heading-label-CII  d-none">
                            <div style="display: flex; align-items: center;">Cohort ranking</div>
                          </th> 

                        </tr> -->
                        <tr>
                          <th></th>
                    <!-- 1 line here -->
                    `
                    res.ciiCompetencies.forEach(competencyHeader=>{
                      output +=`
                          
                            <th class="heading-label-CII">
                            <div style="display: flex; align-items: center; font-size: 12px; text-align: center;">`+competencyHeader.Description+`</div>
                            </th>
                          
                      `
                    })
                    output +=`</tr>
                          <tr style="color: `+res.Colors.color_s+`">
                            <td class="heading-text-CII">S</td>
                    `
                   
                    res.ciiCompetencies.forEach(response => {
                      output +=`
                      
                      <td class="sub-heading-score-CII text-center">
                        <div><span class="competency-ind_` + response.Trait + `" style="text-align: center;"></span></div>
                      </td>
                      
                      `
                    })
                    output +=`</tr>
                              <tr>
                                <td class="heading-text-CII">O</td>`
                    res.ciiCompetencies.forEach(response => {
                      output +=`
                      <td class="sub-heading-score-CII text-center">
                        <div><span class="competency-all_nom999_` + response.Trait + `" style="text-align: center;"></span></div>
                      </td>
                      `
                    })
                    output +=`</tr>`
                    

                   
                    // console.log("ciiCompetencies: ", res)
                    // res.ciiCompetencies.forEach(response => {
                    //     output +=
                    //     `
                    //     <tr>
                    //       <td class="heading-text-CII">`+ response.Description +`</td>
                    //       <td class="sub-heading-score-CII d-none">
                    //         <div><span class="competency-psup1` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-peers d-none">
                    //         <div style="font-weight: bold"><span class="competency-peer2_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-direct d-none">
                    //         <div style="font-weight: bold"><span class="competency-direct3_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-pd d-none">
                    //         <div style="font-weight: bold"><span class="competency-PD996_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-internal d-none">
                    //         <div style="font-weight: bold"><span class="competency-internal4_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-external d-none">
                    //         <div style="font-weight: bold"><span class="competency-external5_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-ie d-none">
                    //         <div style="font-weight: bold"><span class="competency-IE997_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII-pdie d-none">
                    //         <div style="font-weight: bold"><span class="competency-PDIE998_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII">
                    //         <div><span class="competency-all_nom999_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII">
                    //         <div><span class="competency-ind_` + response.Trait + `"></span></div>
                    //       </td>
                    //       <td class="sub-heading-score-CII d-none">
                    //         <div style="font-weight: bold"><span class="competency-cohort_all_1000_` + response.Trait + `"></span></div>
                    //       </td>
                    //   </tr>
                    //     `
                    //   });
                    
                    output +=
                      `
                        </table>
                        </div>
                      `



                        // for OCS
                      output +=`
                        <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                          <h5 class="mb-3">`+ res.ocsTitle+`</h5>

                          <div class="text-center f5-spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <div class="d-none f5-table new-line-chart-container" >
                              <canvas class="` + res.ocsSuperTrait + `"></canvas>
                          </div>
                        </div>
                      </div> 

                    `
                  }
                } 

                if (data.Template === 'Dynamic-Message-Head-New') { 
                    output += `
                    <span class="Head-Dynamic-Message">
                    </span>
                    `
                }
                
                // Radar chart for section 2
                if (data.Template === 'Radar-Chart-New-360-Head-Self') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                      <div class="text-center radarchart_head_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                        <div class="chart-container radar_head d-none">
                        `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-New-360-Head-Cohort') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                      <div class="text-center radarchart_cohort_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                        <div class="chart-container radar_cohort d-none">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                //Highest and Lowest
                if (data.Template === 'Bar-Chart-New-360-Highest') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                        `
                        <div class="text-center highest_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="table360 highest_table d-none">
                          <thead>
                            <tr class="highest-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="highest-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                              <tr class="highest-row` + response.Trait + ` highest-sortme">
                                <td class="sub-heading-score-blindspots highest-averageScore">
                                  <div><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
    
                                <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="highest-ind_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="highest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="highest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                </td>
                              </tr>
                            `
                          });
                          output +=
                        `
                      </tbody>
                    </table>
                      `
                  }
                }
                if (data.Template === 'Bar-Chart-New-360-Lowest') {
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                        `
                        <div class="text-center lowest_spinner">
                          <div class="spinner-border text-danger mb-5 mt-5" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                        <table class="table360 lowest_table d-none">
                          <thead>
                            <tr class="lowest-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                              </th>
                            </tr>
                          </thead>
    
                          <tbody class="lowest-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                              <tr class="lowest-row` + response.Trait + ` lowest-sortme">
                                <td class="sub-heading-score-blindspots lowest-averageScore">
                                  <div><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
    
                                <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="lowest-ind_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="lowest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-blindspots">
                                  <div class="text-center"><span class="lowest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                </td>
                              </tr>
                            `
                          });
                          output +=
                        `
                      </tbody>
                    </table>
                      `
                  }
                }
                // Organizational Climate Breakdown - TL
                if (data.Template === 'Radar-Chart-New-360-Self') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="chart-container">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                if (data.Template === 'Radar-Chart-New-360-Cohort') {
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += `
                        <div class="chart-container">
                         `+res.Title+`
                            <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                        </div>
                      `
                  }
                }
                //Blind spot
                if (data.Template === 'Table-360-New-Overestimated'){ 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360"> 
                      <p class ="d-none overestimated-none" style="font-weight: bold;">None</p>
                      <thead>
                        <tr class="overestimated-row-head">
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="overestimate-wrapper">
                      `
                      res.Elements.forEach(response => {
                        output +=
                        `
                      <!-- 1 line here -->
                        <tr class="overestimated-row` + response.Trait + `">
                          <td class="heading-text-blindspots">`+ response.Description +`</td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center"><span class="overestimated-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center" style="font-weight: bold"><span class="overestimated-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center"><span class="overestimated-difference` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                      });
                      output +=
                      `
                      </tbody>
                    </table>
                      `
                }
                }
                if (data.Template === 'Table-360-New-Underestimated') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                    <table class="table360">
                      <p class ="d-none underestimated-none" style="font-weight: bold;">None</p>
                      <thead>
                        <tr class="underestimated-row-head">
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-blindspots">
                            <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="underestimate-wrapper">
                      `
                      res.Elements.forEach(response => {
                        output +=
                        `
                      <!-- 1 line here -->
                        <tr class="underestimated-row` + response.Trait + `">
                          <td class="heading-text-blindspots">`+ response.Description +`</td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center"><span class="underestimated-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center" style="font-weight: bold"><span class="underestimated-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-blindspots">
                            <div class="text-center"><span class="underestimated-difference` + response.Trait + `"></span></div>
                          </td>
                        </tr>
                      `
                      });
                      output +=
                      `
                      </tbody>
                    </table>
                      `
                }
                }
                // Competency Climate - TL
                if (data.Template === 'Table-New-360-Competency') { 
                  let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      output += 
                      `
                      <div class="text-center competency_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      <table class="table360-competency d-none">
                        <tr>
                          <th class="heading-label-CII">
                            <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">
                              <div>
                                <div>`+ res.heading2 +`</div>
                              </div>
                            </div>
                          </th>
                          <th class="heading-label-CII-peers text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                          </th>
                          <th class="heading-label-CII-direct text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                          </th>
                          <th class="heading-label-CII-pd text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                          </th>
                          <th class="heading-label-CII-internal text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading6 +`</div>
                          </th>
                          <th class="heading-label-CII-external text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading7 +`</div>
                          </th>
                          <th class="heading-label-CII-ie text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading8 +`</div>
                          </th>
                          <th class="heading-label-CII-pdie text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading9 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading10 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading11 +`</div>
                          </th>
                          <th class="heading-label-CII text-center">
                            <div style="display: flex; align-items: center;">`+ res.heading12 +`</div>
                          </th>
                        </tr>
                    <!-- 1 line here -->
                    `
                      res.Elements.forEach(response => {
                        output +=
                        `
                        <tr>
                          <td class="heading-text-CII">`+ response.Description +`</td>
                          <td class="sub-heading-score-CII">
                            <div class="text-center"><span class="competency-psup1` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-peers">
                            <div class="text-center" style="font-weight: bold"><span class="competency-peer2_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-direct">
                            <div class="text-center" style="font-weight: bold"><span class="competency-direct3_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-pd">
                            <div class="text-center" style="font-weight: bold"><span class="competency-PD996_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-internal">
                            <div class="text-center" style="font-weight: bold"><span class="competency-internal4_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-external">
                            <div class="text-center" style="font-weight: bold"><span class="competency-external5_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-ie">
                            <div class="text-center" style="font-weight: bold"><span class="competency-IE997_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII-pdie">
                            <div class="text-center" style="font-weight: bold"><span class="competency-PDIE998_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII">
                            <div class="text-center"><span class="competency-all_nom999_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII">
                            <div class="text-center"><span class="competency-ind_` + response.Trait + `"></span></div>
                          </td>
                          <td class="sub-heading-score-CII">
                            <div class="text-center" style="font-weight: bold"><span class="competency-cohort_all_1000_` + response.Trait + `"></span></div>
                          </td>
                      </tr>
                        `
                      });
                    output +=
                      `
                        </table>
                      `
                }
                }
                //Net Promoter
                // section 2 - Net Promoter
                if (data.Template === 'Bar-Chart-New-360-Head-Net') {
                      output +=`
                          <h6>Welcome, Heidi</h6>
                          <p class="net-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ornare, augue a dapibus commodo, velit urna congue diam, in dignissim turpis lorem quis nisi.</p>

                          <table>
                            <tr>
                              <td>
                                <p class="net-rating">Your CollabRating</p>
                              <td>

                              <td>
                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: #D92121;"></div>
                                  <div class="vertical-line"></div>
                                  <div class="custom-bar custom-bar-color" style="background-color: #FF9900;"></div>
                                  <div class="vertical-line"></div>
                                  <div class="custom-bar custom-bar-color" style="background-color: #79B465;"></div>
                                </div>
                              <td>

                              <td>
                                <p>S=Self</p>
                                <p>O=Others</p>
                              </td>
                            </tr>
                          </table>

                          <hr />

                          <p class="net-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ornare, augue a dapibus commodo, velit urna congue diam, in dignissim turpis lorem quis nisi.</p>

                          <table>
                            <tr>
                              <th>Dimensions</th>
                              <th>S=Self, O=Others</th>
                              <th></th>
                            </tr>
                            <tr>
                              <td>Trust in Others</td>
                              <td>
                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: #D92121;"></div>
                                  <div class="vertical-line"></div>
                                  <div class="custom-bar custom-bar-color" style="background-color: #FF9900;"></div>
                                  <div class="vertical-line"></div>
                                  <div class="custom-bar custom-bar-color" style="background-color: #79B465;"></div>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </table>
                      
                        `
                    }
                if (data.Template === 'Bar-Chart-New-360-Sub') {
                  let obj = JSON.parse(data.Text)
      
                  for (const res of obj) {
                    output += `
                      <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                      <div class="text-center 360_sub_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      
                      <div class="360_sub d-none">
                        <table class="showSliders table behavior_table_`+ res.Elements[0].Trait +`" style="border-top: 1px solid #ccc;">
                          <thead>
                            <tr>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">`+res.Elements[0].Description+`</th>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">
                                <div class="custom-bar-graph ">
                                  <div style="position:relative; top: -28px">
                                    <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                      <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                    </div>
                                  </div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                  <div class="vertical-line" style="left: 25%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                  <div class="vertical-line" style="left: 50%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                  <div class="vertical-line" style="left: 75%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                  <div style="position:relative; bottom: -8px">
                                    <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                      <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                    </div>
                                  </div>
                                </div>
                              </th>
                              <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                <!-- Breakdown keys -->
                                <div class="breakdown-keys">
                                  <h6 style="text-align: right">My Respondents</h6>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-O" style="color: `+res.Colors.color_o+`">O = Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                </div>  
                              </th>
                            </tr>

                            <tr>
                              <th scope="col" class="text-center">Behaviours</th>
                              <th scope="col"></th>
                              <th scope="col" style="padding-left:50px;"></th>
                              <th scope="col" style="font-size: 12px!important;" class="text-center">Average All Others</th>
                            </tr>
                          </thead>

                          <tbody>
                            `
                              res.Elements.map(behavior=>{
                                output +=`
                                    <tr>
                                      <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+behavior.Description+`</td>  
                                      <td style="width: 40%;">

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PS">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-PS" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PS"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                          
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-P ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-P" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-P"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-D ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-D" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-D"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  


                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PD">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-PD" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PD"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-I">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-I" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-I"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-E ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-E" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-E"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-IE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-IE" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-IE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-PDIE" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PDIE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-Others">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-Others" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-Others"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                            <div class="vertical-line" style="left: 25%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                            <div class="vertical-line" style="left: 50%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                            <div class="vertical-line" style="left: 75%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-S ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-S" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-S"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  
                                      </td>  
                                      
                                      <td style="padding-left:50px; padding-top: 0px;">
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PS" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-P" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-D" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PD" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-I" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-E" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-IE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-Others" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div></div> <!-- No Histogram for Self -->
                                      </td>

                                      <td style="width: 10%; vertical-align: middle; text-align: center;">
                                        <span class="averageOthers-`+behavior.Trait+`"></span>  
                                      </td>
                                    </tr>`
                                  })
                            output += `
                          </tbody>
                        </table>
                      </div>
                      <!-- end - EMil -->

                      <div class="360_sub d-none">
                        <table class="table360 showBarChart" style="width:100%;">
                          <tr>
                            <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements[0].Description+`</th>
                            <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;">
                              <div class="custom-bar-graph ">
                                <div style="position:relative; top: -28px">
                                  <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                <div class="vertical-line" style="left: 25%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                <div class="vertical-line" style="left: 50%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                <div class="vertical-line" style="left: 75%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                <div style="position:relative; bottom: -8px">
                                  <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                              <!-- Breakdown keys -->
                              <div class="breakdown-keys">
                                <h6 style="text-align: right">My Respondents</h6>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-O" style="color: `+res.Colors.color_o+`">O = Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                              </div>  
                            </th>
                          </tr>

                          <tr>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=" text-center">My Respondents' Score</th>
                            <th class=" text-center">My Respondents' Distribution</th>
                            <th class=" text-center">Cohort Respondents' Score</th>
                          <!--<th class=" text-center d-none">Cohort Score</th>-->
                          </tr>
                            `
                            res.Elements.forEach(response => {
                              output += 
                              `
                                <tr class="MAIN">
                                  <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                                </tr>
                                <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                  <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="heading-score-barchart2">
                                    <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="heading-score-barchart2" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-P-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-D-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                  </td>

                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-I-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-E-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>                  
            
                                <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->

                                </tr>
            
                                <!-- Change total to Average all others -->
                                <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1 " style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1 d-none">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
                              `
                            });
                          output += `
                        </table>
                      </div>
                    `
                  }
                }
                if (data.Template === 'Section-End') { 
                    output += `
                          </div>
                        </div>
                      </div>
                    </div>
                    `
                }
                if (data.Template === 'Notes') { 
                    output += `
    
                    
                            <!-- 
                            Note Body 
                            <div class="note-box">
                                <div class="displayNotes_` + displayCounter++ + `"></div>
                                
                                <div class="accordion mt-2" id="accordionResolved">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingResolved">
                                            <button class="accordion-button collapsed report-for-name" data-section="1" type="button" data-bs-toggle="collapse" data-bs-target="#resolve`+ buttonCounter++ +`" aria-expanded="false">
                                                Resolved Notes
                                                <span class="report-for-name"></span>
                                            </button>
                                        </h2>
      
                                        <div id="resolve`+ openCounter++ +`" class="accordion-collapse collapse" aria-labelledby="headingResolved" data-bs-parent="#accordionResolved">
                                            <div class="accordion-body">
                                                <div class="displayResolvedNotes_` + resolveCounter++ + `"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            end of Note Body  
                            -->
                            
                            
    
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pagebreak"> </div>
                    `
                  output += `
                            
                  `
                }
              }
              // show submit button
              for (let i = 0; i < input.length; i++) {
                let data = input[i];
      
                if (data.Template === "LMC-Effectiveness-6" || data.Template === 'LMC-Effectiveness-6+1' || data.Template === 'LMC-Effectiveness-7+1' || data.Template === 'LMC-Effectiveness-7' || data.Template === "LMC-Accuracy-5-P" || data.Template === "Select_YN" || data.Template === 'LMC-Characteristic-5') { 
                  output += `
                    <button type="submit" class="btn btn-secondary" id="submit_btn">Submit</button>
                  `
                  break
                }
              }
              output += `
                      </form>
                    </div>
                  </div>
                  <scriptSuffixToRemove src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="crossorigin="anonymous"></scriptSuffixToRemove>
                  <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-A3rJD856KowSb7dwlZdYEkO39Gagi7vIsF0jrRAoQmDKKtQBHUuLZ9AsSv4jD4Xa" crossorigin="anonymous"></scriptSuffixToRemove>
                  <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></scriptSuffixToRemove>
                  <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></scriptSuffixToRemove>
                  <scriptSuffixToRemove src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.8.2/chart.min.js"></scriptSuffixToRemove>
                  <scriptSuffixToRemove>` + this.addJS(ids) + `</scriptSuffixToRemove>
                </body>
              </html>
          `;
              output = output.replaceAll("SuffixToRemove", "");
              this.finalHTML = output;
            },
            generateQuestion(data, isPositivelyWorded) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions(data.QuestionNumber, isPositivelyWorded) + `</div>
                </div>
              `
              return output;
            },
            generateScale0to10(data, isPositivelyWorded) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptionsScale0To10(data.QuestionNumber, isPositivelyWorded) + `</div>
                </div>
              `
              return output;
            },
            generateScale1to10(data, isPositivelyWorded) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptionsScale1To10(data.QuestionNumber, isPositivelyWorded) + `</div>
                </div>
              `
              return output;
            },
            generateOptionsScale0To10(questionNumber) {
              let optionsMap = [
                  { score: 0, label: '0' },
                  { score: 1, label: '1' },
                  { score: 2, label: '2' },
                  { score: 3, label: '3' },
                  { score: 4, label: '4' },
                  { score: 5, label: '5' },
                  { score: 6, label: '6' },
                  { score: 7, label: '7' },
                  { score: 8, label: '8' },
                  { score: 9, label: '9' },
                  { score: 10, label: '10' }
              ];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                  let data = optionsMap[i];
                  output += `
                      <div class="form-check form-check-inline">
                          <input
                              type="radio"
                              class="btn-check"
                              name="Q${questionNumber}"
                              id="${questionNumber}-${data.score}"
                              value=${data.score}
                              required
                              oninvalid="$('#${questionNumber}-${data.score}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                              onclick="$('#${questionNumber}-${data.score}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                          />
                          <label class="btn btn-outline-secondary" id="${questionNumber}-${data.score}-label" for="${questionNumber}-${data.score}">${data.label}</label>
                      </div>
                  `;
              }
              return output;
            },
            generateOptionsScale1To10(questionNumber) {
              let optionsMap = [
                  { score: 1, label: '1' },
                  { score: 2, label: '2' },
                  { score: 3, label: '3' },
                  { score: 4, label: '4' },
                  { score: 5, label: '5' },
                  { score: 6, label: '6' },
                  { score: 7, label: '7' },
                  { score: 8, label: '8' },
                  { score: 9, label: '9' },
                  { score: 10, label: '10' }
              ];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                  let data = optionsMap[i];
                  output += `
                      <div class="form-check form-check-inline">
                          <input
                              type="radio"
                              class="btn-check"
                              name="Q${questionNumber}"
                              id="${questionNumber}-${data.score}"
                              value=${data.score}
                              required
                              oninvalid="$('#${questionNumber}-${data.score}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                              onclick="$('#${questionNumber}-${data.score}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                          />
                          <label class="btn btn-outline-secondary" id="${questionNumber}-${data.score}-label" for="${questionNumber}-${data.score}">${data.label}</label>
                      </div>
                  `;
              }
              return output;
            },
            generateDateInput(data) {
              let output = `
              <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + `. ` + data.Text + `</p>
                  <div class="input-group">
                      <span class="input-group-text" id="dateLabel${data.QuestionNumber}">Input Date</span>
                      <input type="date" class="form-control" id="` + data.QuestionNumber + `-date" name="Q` + data.QuestionNumber + `" required
                          oninvalid="$('#` + data.QuestionNumber + `-date').addClass('is-invalid'); $('#dateLabel` + data.QuestionNumber + `').addClass('invalid-label');"
                          onchange="$('#` + data.QuestionNumber + `-date').removeClass('is-invalid'); $('#dateLabel` + data.QuestionNumber + `').removeClass('invalid-label').addClass('valid-label');" />
                      <div class="invalid-feedback">Please select a date.</div>
                  </div>
              </div>
              `;
                return output;
            },
            generateQuestionYesNo(data, isPositivelyWorded) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptionsYesNo(data.QuestionNumber, isPositivelyWorded) + `</div>
                </div>
              `
              return output;
            },
            generateOptionsYesNo(questionNumber) {
              let optionsMap = [{
                  label: 'YES',
                  value: 1
              }, {
                  label: 'NO',
                  value: 2
              }];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                  let data = optionsMap[i];
                  output += `
                      <div class="form-check form-check-inline">
                          <input
                              type="radio"
                              class="btn-check"
                              name="Q${questionNumber}"
                              id="${questionNumber}-${data.value}"
                              value=${data.value}
                              required
                              oninvalid="$('#${questionNumber}-${data.value}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                              onclick="$('#${questionNumber}-${data.value}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                          />
                          <label class="btn btn-outline-secondary" id="${questionNumber}-${data.value}-label" for="${questionNumber}-${data.value}">${data.label}</label>
                      </div>
                  `;
              }
              return output;
            },
            generateQuestionYesNoMaybe(data, isPositivelyWorded) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptionsYesNoMaybe(data.QuestionNumber, isPositivelyWorded) + `</div>
                </div>
              `
              return output;
            },
            generateOptionsYesNoMaybe(questionNumber) {
              const optionsMap = [
                  { label: 'YES', value: 1 },
                  { label: 'NO', value: 2 },
                  { label: 'MAYBE', value: 3 }
              ];
              let output = '';
              for (const option of optionsMap) {
                  output += `
                      <div class="form-check form-check-inline">
                          <input
                              type="radio"
                              class="btn-check"
                              name="Q${questionNumber}"
                              id="${questionNumber}-${option.value}"
                              value=${option.value}
                              required
                              oninvalid="$('#${questionNumber}-${option.value}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                              onclick="$('#${questionNumber}-${option.value}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                          />
                          <label class="btn btn-outline-secondary" id="${questionNumber}-${option.value}-label" for="${questionNumber}-${option.value}">${option.label}</label>
                      </div>
                  `;
              }
              return output;
            },
            generateOptions(questionNumber, positive = false) {
              let optionsMap = [{
                letter: positive ? 'a' : 'e',
                value: positive ? 1 : 5,
                label: 'Very Inaccurate'
              }, {
                letter: positive ? 'b' : 'd',
                value: positive ? 2 : 4,
                label: 'Moderately Inaccurate'
              }, {
                letter: 'c',
                value: 3,
                label: 'Neither Accurate Nor Inaccurate'
              }, {
                letter: positive ? 'd' : 'b',
                value: positive ? 4 : 2,
                label: 'Moderately Accurate'
              }, {
                letter: positive ? 'e' : 'a',
                value: positive ? 5 : 1,
                label: 'Very Accurate'
              }];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                output += `
              
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="btn-check"
                  name="Q` + questionNumber + `"
                  id="` + questionNumber + data.letter + `"
                  value="` + data.value + `"
                  required
                  oninvalid="$('#` + questionNumber + data.letter + `-label').addClass('unfocus'); $('#` + questionNumber +`-question').addClass('invalid-question');"
                  onclick="$('#` + questionNumber + data.letter + `-label').removeClass('unfocus').addClass('focus'); $('#` + questionNumber +`-question').removeClass('invalid-question').addClass('valid-question');"
                />
                <label class="btn btn-outline-secondary" id="` + questionNumber + data.letter + `-label" for="` + questionNumber + data.letter + `">` + data.label + `</label>
              </div>
              `;
              }
              return output;
            },
            generateQuestion360Effectiveness(data) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Effectiveness(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateQuestion360Effectiveness6plus1(data) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Effectiveness6plus1(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateQuestion360Effectiveness7(data) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Effectiveness7(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateQuestion360Effectiveness7plus1(data) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Effectiveness7plus1(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateQuestion360Effectiveness10plus1(data) {
              let output = `
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Effectiveness10plus1(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateOptions360Effectiveness6plus1(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Strongly Disagree'
              }, {
                letter: 'b',
                value: 2,
                label: 'Disagree'
              }, {
                letter: 'c',
                value: 3,
                label: 'Slightly Disagree'
              }, {
                letter: 'd',
                value: 4,
                label: 'Neither Agree nor Disagree'
              }, {
                letter: 'e',
                value: 5,
                label: 'Slightly Agree'
              }, {
                letter: 'f',
                value: 6,
                label: 'Agree'
              }, {
                letter: 'g',
                value: 0,
                label: `Don't know`
              }];

              let output = `
                <style>
                  .btn-standard-size {
                    width: 150px; /* Initial width */
                  }
                  .btn-middle-size {
                    width: 250px; /* Initial width */
                  }
                  @media (max-width: 768px) {
                    .btn-standard-size {
                      width: 120px; /* Adjusted width for medium screens */
                    }
                  }
                  @media (max-width: 480px) {
                    .btn-standard-size {
                      width: 100px; /* Adjusted width for small screens */
                    }
                  }
                  /* Media query for screens with a maximum width of 1480px */
                  @media (max-width: 1480px) {
                    /* Style adjustments for the button section */
                    .button-section {
                      display: flex !important;
                      flex-direction: column !important;
                      text-align: center !important;
                    }
                    
                    /* Ensure button takes full width */
                    .btn.btn-outline-secondary {
                      width: 100% !important;
                    }
                  }
                </style>
              `;

              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                let btnClass = 'btn-standard-size';

                if (i === 3) { // Assuming the middle button is the 4th one in the array (index 3)
                  btnClass = 'btn-middle-size';
                }

                output += `
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="btn-check"
                    name="Q${questionNumber}"
                    id="${questionNumber}${data.letter}"
                    value="${data.value}"
                    required
                    oninvalid="$('#${questionNumber}${data.letter}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                    onclick="$('#${questionNumber}${data.letter}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                  />
                  <label class="btn btn-outline-secondary ${btnClass}" id="${questionNumber}${data.letter}-label" for="${questionNumber}${data.letter}">${data.label}</label>
                </div>
                `;
              }
              return output;
            },
            generateOptions360Effectiveness7plus1(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Strongly Disagree'
              }, {
                letter: 'b',
                value: 2,
                label: 'Disagree'
              }, {
                letter: 'c',
                value: 3,
                label: 'Slightly Disagree'
              }, {
                letter: 'd',
                value: 4,
                label: 'Neither Agree nor Disagree'
              }, {
                letter: 'e',
                value: 5,
                label: 'Slightly Agree'
              }, {
                letter: 'f',
                value: 6,
                label: 'Agree'
              }, {
                letter: 'g',
                value: 7,
                label: 'Strongly Agree'
              }, {
                letter: 'h',
                value: 0,
                label: `Don't know`
              }];

              let output = `
                <style>
                  .btn-standard-size {
                    width: 150px; /* Initial width */
                  }
                  .btn-middle-size {
                    width: 250px; /* Initial width */
                  }
                  @media (max-width: 768px) {
                    .btn-standard-size {
                      width: 120px; /* Adjusted width for medium screens */
                    }
                  }
                  @media (max-width: 480px) {
                    .btn-standard-size {
                      width: 100px; /* Adjusted width for small screens */
                    }
                  }
                  /* Media query for screens with a maximum width of 1655px */
                  @media (max-width: 1655px) {
                    /* Style adjustments for the button section */
                    .button-section {
                      display: flex !important;
                      flex-direction: column !important;
                      text-align: center !important;
                    }
                    
                    /* Ensure button takes full width */
                    .btn.btn-outline-secondary {
                      width: 100% !important;
                    }
                  }
                </style>
              `;

              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                let btnClass = 'btn-standard-size';
                
                if (i === 3) { // Assuming the middle button is the 4th one in the array (index 3)
                  btnClass = 'btn-middle-size';
                }
                
                output += `
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="btn-check"
                    name="Q${questionNumber}"
                    id="${questionNumber}${data.letter}"
                    value="${data.value}"
                    required
                    oninvalid="$('#${questionNumber}${data.letter}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                    onclick="$('#${questionNumber}${data.letter}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                  />
                  <label class="btn btn-outline-secondary ${btnClass}" id="${questionNumber}${data.letter}-label" for="${questionNumber}${data.letter}">${data.label}</label>
                </div>
                `;
              }
              return output;
            },
            generateOptions360Effectiveness10plus1(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Completely Disagree'
              }, {
                letter: 'b',
                value: 2,
                label: 'Strongly Disagree'
              }, {
                letter: 'c',
                value: 3,
                label: 'Disagree'
              }, {
                letter: 'd',
                value: 4,
                label: 'Somewhat Disagree'
              }, {
                letter: 'e',
                value: 5,
                label: 'Slightly Disagree'
              }, {
                letter: 'f',
                value: 6,
                label: 'Slightly Agree'
              }, {
                letter: 'g',
                value: 7,
                label: 'Somewhat Agree'
              }, {
                letter: 'h',
                value: 8,
                label: 'Agree'
              }, {
                letter: 'i',
                value: 9,
                label: 'Strongly Agree'
              }, {
                letter: 'j',
                value: 10,
                label: 'Completely Agree'
              }, {
                letter: 'k',
                value: 0,
                label: `Don't know`
              }
            ];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                output += `
              
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="btn-check"
                  name="Q` + questionNumber + `"
                  id="` + questionNumber + data.letter + `"
                  value="` + data.value + `"
                  required
                  oninvalid="$('#` + questionNumber + data.letter + `-label').addClass('unfocus'); $('#` + questionNumber +`-question').addClass('invalid-question');"
                  onclick="$('#` + questionNumber + data.letter + `-label').removeClass('unfocus').addClass('focus'); $('#` + questionNumber +`-question').removeClass('invalid-question').addClass('valid-question');"
                />
                <label class="btn btn-outline-secondary" id="` + questionNumber + data.letter + `-label" for="` + questionNumber + data.letter + `">` + data.label + `</label>
              </div>
              `;
              }
              return output;
            },
            generateOptions360Effectiveness7(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Strongly Disagree'
              }, {
                letter: 'b',
                value: 2,
                label: 'Disagree'
              }, {
                letter: 'c',
                value: 3,
                label: 'Slightly Disagree'
              }, {
                letter: 'd',
                value: 4,
                label: 'Neither Agree nor Disagree'
              }, {
                letter: 'e',
                value: 5,
                label: 'Slightly Agree'
              }, {
                letter: 'f',
                value: 6,
                label: 'Agree'
              }, {
                letter: 'g',
                value: 7,
                label: 'Strongly Agree'
              }];

              let output = `
                <style>
                  .btn-standard-size {
                    width: 150px; /* Initial width */
                  }
                  .btn-middle-size {
                    width: 250px; /* Initial width */
                  }
                  @media (max-width: 768px) {
                    .btn-standard-size {
                      width: 120px; /* Adjusted width for medium screens */
                    }
                  }
                  @media (max-width: 480px) {
                    .btn-standard-size {
                      width: 100px; /* Adjusted width for small screens */
                    }
                  }
                  /* Media query for screens with a maximum width of 1475px */
                  @media (max-width: 1475px) {
                    /* Style adjustments for the button section */
                    .button-section {
                      display: flex !important;
                      flex-direction: column !important;
                      text-align: center !important;
                    }
                    
                    /* Ensure button takes full width */
                    .btn.btn-outline-secondary {
                      width: 100% !important;
                    }
                  }
                </style>
              `;

              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                let btnClass = 'btn-standard-size';
                
                if (i === 3) { // Assuming the middle button is the 4th one in the array (index 3)
                  btnClass = 'btn-middle-size';
                }
                
                output += `
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    class="btn-check"
                    name="Q${questionNumber}"
                    id="${questionNumber}${data.letter}"
                    value="${data.value}"
                    required
                    oninvalid="$('#${questionNumber}${data.letter}-label').addClass('unfocus'); $('#${questionNumber}-question').addClass('invalid-question');"
                    onclick="$('#${questionNumber}${data.letter}-label').removeClass('unfocus').addClass('focus'); $('#${questionNumber}-question').removeClass('invalid-question').addClass('valid-question');"
                  />
                  <label class="btn btn-outline-secondary ${btnClass}" id="${questionNumber}${data.letter}-label" for="${questionNumber}${data.letter}">${data.label}</label>
                </div>
                `;
              }
              return output;
            },
            generateOptions360Effectiveness(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Very Ineffective'
              }, {
                letter: 'b',
                value: 2,
                label: 'Ineffective'
              }, {
                letter: 'c',
                value: 3,
                label: 'Neither effective nor ineffective'
              }, {
                letter: 'd',
                value: 4,
                label: 'Effective'
              }, {
                letter: 'e',
                value: 5,
                label: 'Very Effective'
              }, {
                letter: 'f',
                value: 0,
                label: 'No Evidence / Don\'t know'
              }];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                output += `
              
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="btn-check"
                  name="Q` + questionNumber + `"
                  id="` + questionNumber + data.letter + `"
                  value="` + data.value + `"
                  required
                  oninvalid="$('#` + questionNumber + data.letter + `-label').addClass('unfocus'); $('#` + questionNumber +`-question').addClass('invalid-question');"
                  onclick="$('#` + questionNumber + data.letter + `-label').removeClass('unfocus').addClass('focus'); $('#` + questionNumber +`-question').removeClass('invalid-question').addClass('valid-question');"
                />
                <label class="btn btn-outline-secondary" id="` + questionNumber + data.letter + `-label" for="` + questionNumber + data.letter + `">` + data.label + `</label>
              </div>
              `;
              }
              return output;
            },
            generateQuestion360Agreement(data) {
              let output = ` 
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptions360Agreement(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateOptions360Agreement(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'Strongly Agree'
              }, {
                letter: 'b',
                value: 2,
                label: 'Agree'
              }, {
                letter: 'c',
                value: 3,
                label: 'Undecided'
              }, {
                letter: 'd',
                value: 4,
                label: 'Disagree'
              }, {
                letter: 'e',
                value: 5,
                label: 'Strongly Disagree'
              }, {
                letter: 'f',
                value: 0,
                label: 'No Evidence / Don\'t know'
              }];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                output += `
              
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="btn-check"
                  name="Q` + questionNumber + `"
                  id="` + questionNumber + data.letter + `"
                  value="` + data.value + `"
                  required
                  oninvalid="$('#` + questionNumber + data.letter + `-label').addClass('unfocus'); $('#` + questionNumber +`-question').addClass('invalid-question');"
                  onclick="$('#` + questionNumber + data.letter + `-label').removeClass('unfocus').addClass('focus'); $('#` + questionNumber +`-question').removeClass('invalid-question').addClass('valid-question');"
                />
                <label class="btn btn-outline-secondary" id="` + questionNumber + data.letter + `-label" for="` + questionNumber + data.letter + `">` + data.label + `</label>
              </div>
              `;
              }
              return output;
            },
            generateQuestionVFP(data) {
              let output = ` 
                <div class="question-box">
                  <p class="question-field" id="` + data.QuestionNumber + `-question">` + data.QuestionNumber + '. ' + data.Text + `</p>
                  <div class="button-section">` + this.generateOptionsVFP(data.QuestionNumber) + `</div>
                </div>
              `
              return output;
            },
            generateOptionsVFP(questionNumber) {
              let optionsMap = [{
                letter: 'a',
                value: 1,
                label: 'A is highly characteristic'
              }, {
                letter: 'b',
                value: 2,
                label: 'A is somewhat more characteristic'
              }, {
                letter: 'c',
                value: 3,
                label: 'Both are equally characteristic'
              }, {
                letter: 'd',
                value: 4,
                label: 'B is somewhat more characteristic'
              }, {
                letter: 'e',
                value: 5,
                label: 'B is highly characteristic'
              }];
              let output = '';
              for (let i = 0; i < optionsMap.length; i++) {
                let data = optionsMap[i];
                output += `
              
              <div class="form-check form-check-inline">
                <input
                  type="radio"
                  class="btn-check"
                  name="Q` + questionNumber + `"
                  id="` + questionNumber + data.letter + `"
                  value="` + data.value + `"
                  required
                  oninvalid="$('#` + questionNumber + data.letter + `-label').addClass('unfocus'); $('#` + questionNumber +`-question').addClass('invalid-question');"
                  onclick="$('#` + questionNumber + data.letter + `-label').removeClass('unfocus').addClass('focus'); $('#` + questionNumber +`-question').removeClass('invalid-question').addClass('valid-question');"
                />
                <label class="btn btn-outline-secondary" id="` + questionNumber + data.letter + `-label" for="` + questionNumber + data.letter + `">` + data.label + `</label>
              </div>
              `;
              }
              return output;
            },
            addJS(ids) {
              let url = 'https://be.talentsage.com'
              let baseUrl = window.location.protocol + '//' + window.location.host;
              if(baseUrl == "https://tstest.gcm3.com") {
                url = 'https://betest.gcm3.com'
              }
              else if(baseUrl == "https://uat-ts.talentsage.com/") {
                url = 'https://uatbe.talentsage.com'
              }
              else {
                url = 'https://be.talentsage.com'
              }
              
              let input = JSON.parse(this.displaysurveyData)
              let output = `
                // function getCookie(name) {
                //   let cookieName = name + "=";
                //   let cookies = document.cookie.split(';');
                //   for (let i = 0; i < cookies.length; i++) {
                //     let cookie = cookies[i].trim();
                //     if (cookie.indexOf(cookieName) === 0) {
                //       return cookie.substring(cookieName.length, cookie.length);
                //     }
                //   }
                //   return null;
                // }

                function printPDF(){
                      window.print()
                }
                function countCharacters() {
                  let max = $(this).attr("maxlength");
                  let length = $(this).val().length;
                  let counter = max - length;
                  let helper = $(this).next(".form-text");
                  // Switch to the singular if there's exactly 1 character remaining
                  if (counter !== 1) {
                    helper.text(counter + " characters remaining");
                  } else {
                    helper.text(counter + " character remaining");
                  }
                  // Make it red if there are 0 characters remaining
                  if (counter === 0) {
                    helper.removeClass("text-muted");
                    helper.addClass("text-danger");
                  } else {
                    helper.removeClass("text-danger");
                    helper.addClass("text-muted");
                  }
                }
                $(document).ready(async function(){
                  // const cookieExists = getCookie("a3b3dd4702b847b440c49c1198d77f85") !== null;
                  // if (cookieExists) {
                  //   console.log('there is a cookie')
                  // } else {
                  //   console.log('there is no cookie')
                  //   return Swal.fire({
                  //     text: 'You must be logged in to access the report!',
                  //     icon: "warning",
                  //     showConfirmButton: false,
                  //     allowOutsideClick: false
                  //   })
                  // }
          
                  let config = {
                    headers: {
                      token: 'cCW7PW2CRotxuALrBuMob5lXgVhY4xo'
                    }
                  }
                  let survey_assignment_id = new URL(location.href).searchParams.get('survey_assignment_id')
                  let ind_id = new URL(location.href).searchParams.get('ind_id')
                  let org_id = new URL(location.href).searchParams.get('org_id')
                  let subOrg_id = new URL(location.href).searchParams.get('subOrg_id')
                  let viewer_fullname = new URL(location.href).searchParams.get('viewer_fullname')
                  let report_for_fullname = new URL(location.href).searchParams.get('report_for_fullname')
                  let sex = new URL(location.href).searchParams.get('sex')
                  let country = new URL(location.href).searchParams.get('country')
                  let program_id = new URL(location.href).searchParams.get('program_id')
                  let iteration_id = new URL(location.href).searchParams.get('iteration_id')
                  let stream_id = new URL(location.href).searchParams.get('stream_id')
                  let group_id = new URL(location.href).searchParams.get('group_id')
                  let coach_id = new URL(location.href).searchParams.get('coach_id')
                  let survey_template_id = new URL(location.href).searchParams.get('survey_template_id')
                  let qsort_type = new URL(location.href).searchParams.get('qsort_type')
                  let reqReOrder = true
                  let noDuplicates = true
                  let completeAnswer = true
                  let run_mode = new URL(location.href).searchParams.get('run_mode')
                  let org_acronym = new URL(location.href).searchParams.get('org_acronym')
                  let org_name = new URL(location.href).searchParams.get('org_name')
                  let suborg_name = new URL(location.href).searchParams.get('suborg_name')
                  let program_name = new URL(location.href).searchParams.get('program_name')
                  let iteration_name = new URL(location.href).searchParams.get('iteration_name')
                  let report_template_name = new URL(location.href).searchParams.get('report_template_name')
                  let participant_report_release_date = new URL(location.href).searchParams.get('participant_report_release_date')
                  let client_logo = new URL(location.href).searchParams.get('client_logo')
                  let brand_bgcolor = new URL(location.href).searchParams.get('brand_bgcolor')
                  let expandAll = new URL(location.href).searchParams.get('expand_all')
                  let isPdfAvailable = new URL(location.href).searchParams.get('is_pdf_available')

                  let printPDFonlyAndNoData = false;

                  if (expandAll === "true") {
                            $('.hidden-content').css('display', 'block');
                            setTimeout(function () {
                              $('#expandAllBtn').click();
                            }, 1000);
                  }

                  // click the make pdf availble button
                  $(document).on('click', '#btnMakePdfAvailable', function(e) {
                    if(isPdfAvailable == 1) {
                      return Swal.fire({
                        text: 'Your PDF is already available.',
                        icon: "success",
                        confirmButtonColor: '#3085d6',
                      })
                    }
                    else if(isPdfAvailable == 0) {
                      //update the survey assignment, is_pdf_available to 1
                      axios
                        .put(` + '`' + `${url}` + '/update-survey-pdf-available/${survey_assignment_id}' + '`, {}, config)' + `
                        .then((res) => {
                          return Swal.fire({
                            text: 'Your PDF is now available.',
                            icon: "success",
                            confirmButtonColor: '#3085d6',
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                    }
                    else {
                      return Swal.fire({
                        text: 'Something went wrong with your PDF, Please try again later.',
                        icon: "error",
                        confirmButtonColor: '#3085d6',
                      })
                    }
                  })
    
                  if (run_mode === "ReadOnly") {
                    $('input').attr('disabled','disabled')
                    $('select').attr('disabled','disabled')
                    $('button').attr('disabled','disabled')
                  }
    
                  $(".front-page-client-logo-img").css("background-color", brand_bgcolor);
                  $(".front-page-client-logo").attr("src", client_logo)
                  $('.front-page-program-name').text(program_name)
                  $('.front-page-survey-title').text(report_template_name)
                  $('.front-page-survey-subject-name').text(report_for_fullname)
                  $('.front-page-participant-report-release-date').text(participant_report_release_date)
                  $('.front-page-downloaded-by').text(viewer_fullname)

                  let today = new Date()
                  let dd = today.getDate()
                  let mm = today.getMonth() + 1
                  const yyyy = today.getFullYear()

                  if(mm < 10) mm = '0' +mm 
                  if(dd < 10) dd = '0' +dd

                  $('.front-page-date-today').text(dd+ '/' +mm+ '/' +yyyy)
      
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;output_name/g, viewer_fullname)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;report_for_fullname/g, report_for_fullname)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;org_acronym/g, org_acronym)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;org_name/g, org_name)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;suborg_name/g, suborg_name)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;program_name/g, program_name)
                  document.body.innerHTML = document.body.innerHTML.replace(/&amp;iteration_name/g, iteration_name)

                  // toggle button for F5 New 360 Report
                  let buttonState = localStorage.getItem("button-state"); // retrieve button state from localStorage
                  if (buttonState === "on") {
                    $(".toggle-bar-chart").addClass("active");
                    // console.log("show bar chart");
                    $('.showBarChart').show()
                    $('.showSliders').hide()
                    $(".toggle-bar-chart-text").text('Show Scale View')
                  } else {
                    // console.log("hide bar chart");
                    $('.showBarChart').hide()
                    $('.showSliders').show()
                    $(".toggle-bar-chart-text").text('Show Bar and Cohort Chart')
                  }

                  $(".toggle-bar-chart").click(function(e){
                    e.preventDefault()
                    if ($(this).hasClass("active")) {
                      $(this).removeClass("active");
                      localStorage.setItem("button-state", "off"); // store button state in localStorage
                      // console.log("hide bar chart");
                      $('.showBarChart').hide()
                      $('.showSliders').show()
                      $(".toggle-bar-chart-text").text('Show Bar and Cohort Chart')
                    } else {
                      $(this).addClass("active");
                      localStorage.setItem("button-state", "on"); // store button state in localStorage
                      // console.log("show bar chart");
                      $('.showBarChart').show()
                      $('.showSliders').hide()
                      $(".toggle-bar-chart-text").text('Show Scale View')
                    }
                  });
                `
              // for 360 report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Bar-Chart-360-Head') { 
                output += `
                axios
                  .post(
                    '${url}/r360-report-data2a',
                    {
                      survey_assignment_id: survey_assignment_id
                    },
                    config                                            
                  )
                  .then((res) => {
                    console.log(res)
                  `
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                      if (data.Template === 'Table-360-Open-ended') { 
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output +=`
                          function checkonlySpaces(str) {
                                return str.trim().length === 0;
                              }
                          `
                          res.Elements.forEach((response) => {
                            output += 
                            `
                              if(res.data.length < 1){
                                $(".open-ended-1` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[0] != 'undefined'){
                                if(res.data[0].font === "bold"){
                                  $(".open-ended-1` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-1` + response.Trait + `").html(res.data[0].` + response.Trait + `)
                              }
      
                              if(res.data.length < 2){
                                $(".open-ended-2` + response.Trait + `").css("display", "none")
                              }j
                              if(typeof res.data[1] != 'undefined'){
                                if(res.data[1].font === "bold"){
                                  $(".open-ended-2` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-2` + response.Trait + `").html(res.data[1].` + response.Trait + `)
                              }
      
                              if(res.data.length < 3){
                                $(".open-ended-3` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[2] != 'undefined'){
                                if(res.data[2].font === "bold"){
                                  $(".open-ended-3` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-3` + response.Trait + `").html(res.data[2].` + response.Trait + `)
                              }
      
                              if(res.data.length < 4){
                                $(".open-ended-4` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[3] != 'undefined'){
                                if(res.data[3].font === "bold"){
                                  $(".open-ended-4` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-4` + response.Trait + `").html(res.data[3].` + response.Trait + `)
                              }
      
                              if(res.data.length < 5){
                                $(".open-ended-5` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[4] != 'undefined'){
                                if(res.data[4].font === "bold"){
                                  $(".open-ended-5` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-5` + response.Trait + `").html(res.data[4].` + response.Trait + `)
                              }
                              
                              if(res.data.length < 6){
                                $(".open-ended-6` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[5] != 'undefined'){
                                $(".open-ended-6` + response.Trait + `").html(res.data[5].` + response.Trait + `)
                              }
      
                              if(res.data.length < 7){
                                $(".open-ended-7` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[6] != 'undefined'){
                                $(".open-ended-7` + response.Trait + `").html(res.data[6].` + response.Trait + `)
                              }
      
                              if(res.data.length < 8){
                                $(".open-ended-8` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[7] != 'undefined'){
                                $(".open-ended-8` + response.Trait + `").html(res.data[7].` + response.Trait + `)
                              }
                                  
                              if(res.data.length < 9){
                                $(".open-ended-9` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[8] != 'undefined'){
                                $(".open-ended-9` + response.Trait + `").html(res.data[8].` + response.Trait + `)
                              }
      
                              if(res.data.length < 10){
                                $(".open-ended-10` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[9] != 'undefined'){
                                $(".open-ended-10` + response.Trait + `").html(res.data[9].` + response.Trait + `)
                              }
      
                              if(res.data.length < 11){
                                $(".open-ended-11` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[10] != 'undefined'){
                                $(".open-ended-11` + response.Trait + `").html(res.data[10].` + response.Trait + `)
                              }
      
                              if(res.data.length < 12){
                                $(".open-ended-12` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[11] != 'undefined'){
                                $(".open-ended-12` + response.Trait + `").html(res.data[11].` + response.Trait + `)
                              }
      
                              if(res.data.length < 13){
                                $(".open-ended-13` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[12] != 'undefined'){
                                $(".open-ended-13` + response.Trait + `").html(res.data[12].` + response.Trait + `)
                              }
      
                              if(res.data.length < 14){
                                $(".open-ended-14` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[13] != 'undefined'){
                                $(".open-ended-14` + response.Trait + `").html(res.data[13].` + response.Trait + `)
                              }
      
                              if(res.data.length < 15){
                                $(".open-ended-15` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[14] != 'undefined'){
                                $(".open-ended-15` + response.Trait + `").html(res.data[14].` + response.Trait + `)
                              }
                              if(res.data.length < 16){
                                $(".open-ended-16` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[15] != 'undefined'){
                                $(".open-ended-16` + response.Trait + `").html(res.data[15].` + response.Trait + `)
                              }
                              if(res.data.length < 17){
                                $(".open-ended-17` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[16] != 'undefined'){
                                $(".open-ended-17` + response.Trait + `").html(res.data[16].` + response.Trait + `)
                              }
                              if(res.data.length < 18){
                                $(".open-ended-18` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[17] != 'undefined'){
                                $(".open-ended-18` + response.Trait + `").html(res.data[17].` + response.Trait + `)
                              }
                              if(res.data.length < 19){
                                $(".open-ended-19` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[18] != 'undefined'){
                                $(".open-ended-19` + response.Trait + `").html(res.data[18].` + response.Trait + `)
                              }
                              if(res.data.length < 20){
                                $(".open-ended-20` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[19] != 'undefined'){
                                $(".open-ended-20` + response.Trait + `").html(res.data[19].` + response.Trait + `)
                              }
                              
                              if(checkonlySpaces($(".open-ended-1` + response.Trait + `").html()) == true){
                                $(".open-ended-1` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-2` + response.Trait + `").html()) == true){
                                $(".open-ended-2` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-3` + response.Trait + `").html()) == true){
                                $(".open-ended-3` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-4` + response.Trait + `").html()) == true){
                                $(".open-ended-4` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-5` + response.Trait + `").html()) == true){
                                $(".open-ended-5` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-6` + response.Trait + `").html()) == true){
                                $(".open-ended-6` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-7` + response.Trait + `").html()) == true){
                                $(".open-ended-7` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-8` + response.Trait + `").html()) == true){
                                $(".open-ended-8` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-9` + response.Trait + `").html()) == true){
                                $(".open-ended-9` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-10` + response.Trait + `").html()) == true){
                                $(".open-ended-10` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-11` + response.Trait + `").html()) == true){
                                $(".open-ended-11` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-12` + response.Trait + `").html()) == true){
                                $(".open-ended-12` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-13` + response.Trait + `").html()) == true){
                                $(".open-ended-13` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-14` + response.Trait + `").html()) == true){
                                $(".open-ended-14` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-15` + response.Trait + `").html()) == true){
                                $(".open-ended-15` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-16` + response.Trait + `").html()) == true){
                                $(".open-ended-16` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-17` + response.Trait + `").html()) == true){
                                $(".open-ended-17` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-18` + response.Trait + `").html()) == true){
                                $(".open-ended-18` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-19` + response.Trait + `").html()) == true){
                                $(".open-ended-19` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-20` + response.Trait + `").html()) == true){
                                $(".open-ended-20` + response.Trait + `").html('No response provided')
                              }
                            `
                          })
                        }
                      }
                     
                      
                    }
                  output += `
                  })
                  .catch((err) => {
                    console.log(err)
                  })
            
                axios
                  .post(
                    '${url}/r360-report-data2',
                    {
                      "survey_assignment_id": survey_assignment_id,
                      "org_id": org_id,
                      "suborg_id": subOrg_id,
                      "program_id": program_id,
                      "iteration_id": iteration_id
                  },
                    config                                            
                  )
                  .then((res) => {
                      console.log(res.data)
                      const ordinal = (number) => {
                      const ordinalRules = new Intl.PluralRules("en", {
                        type: "ordinal"
                      });
                      const suffixes = {
                          one: "st",
                          two: "nd",
                          few: "rd",
                          other: "th"
                        };
                        const suffix = suffixes[ordinalRules.select(number)];
                        return (number + suffix);
                      }
        
                      function arrayRemoveZero(arr, value) { 
                        return arr.filter(function(ele){ 
                            return ele != value; 
                        });
                      }
                      function checkGroups(A, B, C, D) {
                                const group1 = [A, B];
                                const group2 = [C, D];

                                const group1HasZeroAndOne = group1.includes(0) && group1.includes(1);
                                const group2HasZeroAndOne = group2.includes(0) && group2.includes(1);
                                const group1HasTwoZeros = group1.every(val => val === 0);
                                const group2HasTwoZeros = group2.every(val => val === 0);

                                return group1HasZeroAndOne || group2HasZeroAndOne || (group1HasTwoZeros && group2HasTwoZeros);
                              }
                      `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Radar-Chart-360-Head-Self') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                            function getDataSet(){
                              var dataset = [];
    
                            let Self = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let Psup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(139, 233, 153, 0.2)',
                                      borderColor: '#8be999',
                                      pointBackgroundColor: '#8be999',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let Peers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(177,151,252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let DirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let PeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PeersDirectReport
    
                              let Internal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let External = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'aquamarine',
                                      pointBackgroundColor: 'aquamarine',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let InternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of InternalExternal
                              
                              let PDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(176, 151, 252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,0,PeersDirectReport);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,0,Peers);
                                    dataset.splice(3,0,DirectReport);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,0,InternalExternal);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,0,Internal);
                                    dataset.splice(5,0,External);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    dataset = []
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,0,PDIE);
                                  } else {
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                  }
                                    $('.radar_head').removeClass('d-none')
                                    $('.radarchart_head_spinner').addClass('d-none')  
                                return dataset;
                              }
    
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getDataSet (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Radar-Chart-360-Head-Cohort') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                            function getcohortDataSet(){
                              var cohortdataset = [];
    
                            let cohortSelf = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let cohortPsup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(139, 233, 153, 0.2)',
                                      borderColor: '#8be999',
                                      pointBackgroundColor: '#8be999',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let cohortPeers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(177,151,252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let cohortDirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let cohortPeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortPeersDirectReport
    
                              let cohortInternal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let cohortExternal = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'aquamarine',
                                      pointBackgroundColor: 'aquamarine',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let cohortInternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortInternalExternal
                              
                              let cohortPDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(176, 151, 252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,0,cohortPeersDirectReport);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,0,cohortPeers);
                                    cohortdataset.splice(3,0,cohortDirectReport);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,0,cohortInternalExternal);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,0,cohortInternal);
                                    cohortdataset.splice(5,0,cohortExternal);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    cohortdataset = []
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,0,cohortPDIE);
                                  } else {
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                  }
                                  $('.radar_cohort').removeClass('d-none')
                                  $('.radarchart_cohort_spinner').addClass('d-none')
                                return cohortdataset;
                              }
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getcohortDataSet (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Radar-Chart-360-Self') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            output += `
                            function getClimateDataset(){
                              var dataset = [];
    
                            let Self = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let Psup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(139, 233, 153, 0.2)',
                                      borderColor: '#8be999',
                                      pointBackgroundColor: '#8be999',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let Peers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(177,151,252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let DirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let PeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PeersDirectReport
    
                              let Internal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let External = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'aquamarine',
                                      pointBackgroundColor: 'aquamarine',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let InternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of InternalExternal
                              
                              let PDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(176, 151, 252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,0,PeersDirectReport);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,0,Peers);
                                    dataset.splice(3,0,DirectReport);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,0,InternalExternal);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,0,Internal);
                                    dataset.splice(5,0,External);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    dataset = []
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,0,PDIE);
                                  } else {
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                  }
                                    $('.radar_head').removeClass('d-none')
                                    $('.radarchart_head_spinner').addClass('d-none')  
                                return dataset;
                              }
    
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getClimateDataset (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Radar-Chart-360-Cohort') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                             
                            output += `
                            function getClimatecohortDataset(){
                              var cohortdataset = [];
    
                            let cohortSelf = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let cohortPsup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(139, 233, 153, 0.2)',
                                      borderColor: '#8be999',
                                      pointBackgroundColor: '#8be999',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let cohortPeers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(177,151,252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let cohortDirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let cohortPeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortPeersDirectReport
    
                              let cohortInternal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let cohortExternal = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(99,230,190,0.2)',
                                      borderColor: 'aquamarine',
                                      pointBackgroundColor: 'aquamarine',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let cohortInternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154,0.2)',
                                      borderColor: 'green',
                                      pointBackgroundColor: 'green',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortInternalExternal
                              
                              let cohortPDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(176, 151, 252, 0.2)',
                                      borderColor: 'purple',
                                      pointBackgroundColor: 'purple',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,0,cohortPeersDirectReport);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,0,cohortPeers);
                                    cohortdataset.splice(3,0,cohortDirectReport);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,0,cohortInternalExternal);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,0,cohortInternal);
                                    cohortdataset.splice(5,0,cohortExternal);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    cohortdataset = []
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,0,cohortPDIE);
                                  } else {
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                  }
                                  $('.radar_cohort').removeClass('d-none')
                                  $('.radarchart_cohort_spinner').addClass('d-none')
                                return cohortdataset;
                              }
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getClimatecohortDataset (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Bar-Chart-360-Head') { 
                          output += `
                            let avgCount = 1
                            let avgCohortCount = 1
                            let isDisplay_P = 0
                            let isDisplay_D = 0
                            let isDisplay_PD = 0
                            let isDisplay_I = 0
                            let isDisplay_E = 0
                            let isDisplay_IE = 0
                            let isDisplay_PDIE = 0
    
                            if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_PD = 1
                            } else {
                              isDisplay_P = 1
                              isDisplay_D = 1
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                            }
                            if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_IE = 1
                            } else {
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                              isDisplay_I = 1
                              isDisplay_E = 1
                            }
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                avgCount = 2
                                avgCohortCount = 2
                                isDisplay_PDIE = 1
                              }
                            `
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                                let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                                let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                                let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                                let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                                // MAIN 
    
                                let avgHeadRollup` + response.Trait + ` = 0
                                let cohortHeadavgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
    
                                  let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                  
                                  let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                  avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                  cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }

                                // Total
                                $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
      
                                $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
    
                                  let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                 
                                  let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                                  }  else {
                                      avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else{
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }  
                                  
                                  // Total
                                  $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                  $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                  $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  }
    
                                  else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                 
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                    
                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  }
    
    
                                  if (isDisplay_PDIE === 1) {
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }

                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    }
    
                                    else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                 
                                  
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                    
                                    
                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    }
                                
                                // PRIMARY SUPERVISOR
                                $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers|Direct report
                                $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Internal stakeholder|External stakeholders
                                $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Internal stakeholder
                                $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Self
                                $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }

                                $('.front-page-for-individual-report').removeClass('d-none')
                                $('.front-page-psup-value').text(res.data[0].psup1_N)
                                $('.front-page-P-value').text(res.data[0].peer2_N)
                                $('.front-page-D-value').text(res.data[0].direct3_N)
                                $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                                $('.front-page-I-value').text(res.data[0].internal4_N)
                                $('.front-page-E-value').text(res.data[0].external5_N)
                                $('.front-page-IE-value').text(res.data[0].IE997_N)
                                $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                                  $(".front-page-P").css("display", "none")
                                  $(".front-page-D").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                                  $(".front-page-PD").css("display", "none")
                                }
                                
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                                  $(".front-page-I").css("display", "none")
                                  $(".front-page-E").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                                  $(".front-page-IE").css("display", "none")
                                }
                                
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                                  $(".front-page-P").css("display", "none")
                                  $(".front-page-D").css("display", "none")
                                  $(".front-page-I").css("display", "none")
                                  $(".front-page-E").css("display", "none")
                                  $(".front-page-PD").css("display", "none")
                                  $(".front-page-IE").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                                  $(".front-page-PDIE").css("display", "none")
                                }
                              `
                            })
                          }
                          output += `
                            $('.360_head').removeClass('d-none')
                            $('.360_head_spinner').addClass('d-none')
                          `
                        }
                        if (data.Template === 'Bar-Chart-360-Sub') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                // PRIMARY SUPERVISOR
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Peers|Direct reports
                                $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Internal stakeholder
                                $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                let avgRollup` + response.Trait + ` = 0
                                let cohortavgRollup` + response.Trait + ` = 0
                                let cohortSelfAvgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
    
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                  } 
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }

                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                if (isDisplay_PDIE === 1) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                } 
                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                  
                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
                                
      
                                // Self
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                                
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                }
    
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                                }
    
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }
                               if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                               }
                              `
                            })
                          }
                          output += `
                            $('.360_sub').removeClass('d-none')
                            $('.360_sub_spinner').addClass('d-none')
                          `
                        }
                        if (data.Template === 'Bar-Chart-360-Highest') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                $(".highest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".highest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".highest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                                $(".highest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                              `
                            })
                            output +=`
                              $('.highest-sortme').sort(function(a, b) {
                                if (a.textContent > b.textContent) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                              }).appendTo($('.highest-wrapper'));
    
                              const highestStartLength = 5
                              const highestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                              $('.highest-sortme').slice(highestStartLength, highestMaxLength).hide();
                              $('.highest-averageScore').hide()
    
                              $('.highest_table').removeClass('d-none')
                              $('.highest_spinner').addClass('d-none')
                            `
                          }
                        }
                        if (data.Template === 'Bar-Chart-360-Lowest') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                $(".lowest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".lowest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".lowest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                                $(".lowest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                              `
                            })
                            output +=`
                              $('.lowest-sortme').sort(function(a, b) {
                                if (a.textContent < b.textContent) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                              }).appendTo($('.lowest-wrapper'));
    
                              const lowestStartLength = 5
                              const lowestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                              $('.lowest-sortme').slice(lowestStartLength, lowestMaxLength).hide();
                              $('.lowest-averageScore').hide()
    
                              $('.lowest_table').removeClass('d-none')
                              $('.lowest_spinner').addClass('d-none')
                            `
                          }
                        }
                        if (data.Template === 'Table-360-Competency') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                    $(".competency-ind_` + response.Trait + `").html("N/A")
                                }
                                else{
                                    $(".competency-ind_` + response.Trait + `").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                                }
                                if(res.data[0].psup1_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-psup1` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-psup1` + response.Trait + `").html(ordinal(Math.round(res.data[0].psup1_` + response.Trait + `)))
                                }
                                if(res.data[0].peer2_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-peer2_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-peer2_` + response.Trait + `").html(ordinal(Math.round(res.data[0].peer2_` + response.Trait + `)))
                                }
                                if(res.data[0].direct3_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-direct3_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-direct3_` + response.Trait + `").html(ordinal(Math.round(res.data[0].direct3_` + response.Trait + `)))
                                }
                                if(res.data[0].PD996_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-PD996_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-PD996_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PD996_` + response.Trait + `)))
                                }
                                if(res.data[0].internal4_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-internal4_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-internal4_` + response.Trait + `").html(ordinal(Math.round(res.data[0].internal4_` + response.Trait + `)))
                                }
                                if(res.data[0].external5_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-external5_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-external5_` + response.Trait + `").html(ordinal(Math.round(res.data[0].external5_` + response.Trait + `)))
                                }
                                if(res.data[0].IE997_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-IE997_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-IE997_` + response.Trait + `").html(ordinal(Math.round(res.data[0].IE997_` + response.Trait + `)))
                                }
                                if(res.data[0].PDIE998_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-PDIE998_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-PDIE998_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PDIE998_` + response.Trait + `)))
                                }
                                if(res.data[0].all_nom999_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-all_nom999_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-all_nom999_` + response.Trait + `").html(ordinal(Math.round(res.data[0].all_nom999_` + response.Trait + `)))
                                }
                                if(res.data[0].cohort_all_1000_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-cohort_all_1000_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-cohort_all_1000_` + response.Trait + `").html(ordinal(Math.round(res.data[0].cohort_all_1000_` + response.Trait + `)))
                                }
                              `
                            })
                            output += `
                            if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
    
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $('.heading-label-CII-peers').addClass('d-none')
                                  $('.sub-heading-score-CII-peers').addClass('d-none')
                                  $('.heading-label-CII-direct').addClass('d-none')
                                  $('.sub-heading-score-CII-direct').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-pd').addClass('d-none')
                                  $('.sub-heading-score-CII-pd').addClass('d-none')
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                 $('.heading-label-CII-internal').addClass('d-none')
                                  $('.sub-heading-score-CII-internal').addClass('d-none')
                                  $('.heading-label-CII-external').addClass('d-none')
                                  $('.sub-heading-score-CII-external').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-ie').addClass('d-none')
                                  $('.sub-heading-score-CII-ie').addClass('d-none')
                                }
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $('.heading-label-CII-peers').addClass('d-none')
                                  $('.sub-heading-score-CII-peers').addClass('d-none')
                                  $('.heading-label-CII-direct').addClass('d-none')
                                  $('.sub-heading-score-CII-direct').addClass('d-none')
                                  $('.heading-label-CII-internal').addClass('d-none')
                                  $('.sub-heading-score-CII-internal').addClass('d-none')
                                  $('.heading-label-CII-external').addClass('d-none')
                                  $('.sub-heading-score-CII-external').addClass('d-none')
                                  $('.heading-label-CII-pd').addClass('d-none')
                                  $('.sub-heading-score-CII-pd').addClass('d-none')
                                  $('.heading-label-CII-ie').addClass('d-none')
                                  $('.sub-heading-score-CII-ie').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-pdie').addClass('d-none')
                                  $('.sub-heading-score-CII-pdie').addClass('d-none')
                                } 
                            `
                            output +=
                              `
                            //start PSup ranking
                            if(res.data[0].psup1_N > 1){
                              let psup1_arrCounter = ['','','','','','']
                              let psup1_arr6 = ['','','','','','']
                              let psup1_arr5 = ['','','','','','']
                              let psup1_arr4 = ['','','','','','']
                              let psup1_arr3 = ['','','','','','']
                              let psup1_arr2 = ['','','','','','']
                              let psup1_arr1 = ['','','','','','']
                              let psup1_arrForMaxMin = ['','','','','','']
                              let psup1_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-psup1CII6").html() == "1st"){
                                psup1_arr6[0] = psup1_arr6[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII6").html() == "2nd"){
                                psup1_arr6[1] = psup1_arr6[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII6").html() == "3rd"){
                                psup1_arr6[2] = psup1_arr6[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[1]+'3'
                              }
                              if($(".competency-psup1CII6").html() == "4th"){
                                psup1_arr6[3] = psup1_arr6[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII6").html() == "5th"){
                                psup1_arr6[4] = psup1_arr6[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII6").html() == "6th"){
                                psup1_arr6[5] = psup1_arr6[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-psup1CII5").html() == "1st"){
                                psup1_arr5[0] = psup1_arr5[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII5").html() == "2nd"){
                                psup1_arr5[1] = psup1_arr5[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII5").html() == "3rd"){
                                psup1_arr5[2] = psup1_arr5[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII5").html() == "4th"){
                                psup1_arr5[3] = psup1_arr5[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII5").html() == "5th"){
                                psup1_arr5[4] = psup1_arr5[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII5").html() == "6th"){
                                psup1_arr5[5] = psup1_arr5[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-psup1CII4").html() == "1st"){
                                psup1_arr4[0] = psup1_arr4[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII4").html() == "2nd"){
                                psup1_arr4[1] = psup1_arr4[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII4").html() == "3rd"){
                                psup1_arr4[2] = psup1_arr4[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII4").html() == "4th"){
                                psup1_arr4[3] = psup1_arr4[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII4").html() == "5th"){
                                psup1_arr4[4] = psup1_arr4[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII4").html() == "6th"){
                                psup1_arr4[5] = psup1_arr4[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-psup1CII3").html() == "1st"){
                                psup1_arr3[0] = psup1_arr3[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII3").html() == "2nd"){
                                psup1_arr3[1] = psup1_arr3[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII3").html() == "3rd"){
                                psup1_arr3[2] = psup1_arr3[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII3").html() == "4th"){
                                psup1_arr3[3] = psup1_arr3[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII3").html() == "5th"){
                                psup1_arr3[4] = psup1_arr3[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII3").html() == "6th"){
                                psup1_arr3[5] = psup1_arr3[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-psup1CII2").html() == "1st"){
                                psup1_arr2[0] = psup1_arr2[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII2").html() == "2nd"){
                                psup1_arr2[1] = psup1_arr2[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII2").html() == "3rd"){
                                psup1_arr2[2] = psup1_arr2[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII2").html() == "4th"){
                                psup1_arr2[3] = psup1_arr2[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII2").html() == "5th"){
                                psup1_arr2[4] = psup1_arr2[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII2").html() == "6th"){
                                psup1_arr2[5] = psup1_arr2[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-psup1CII1").html() == "1st"){
                                psup1_arr1[0] = psup1_arr1[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII1").html() == "2nd"){
                                psup1_arr1[1] = psup1_arr1[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII1").html() == "3rd"){
                                psup1_arr1[2] = psup1_arr1[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII1").html() == "4th"){
                                psup1_arr1[3] = psup1_arr1[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII1").html() == "5th"){
                                psup1_arr1[4] = psup1_arr1[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII1").html() == "6th"){
                                psup1_arr1[5] = psup1_arr1[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
             
                            //condition here
                            psup1_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  psup1_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue0 = Math.min(...psup1_arrForMaxMinCleaned)
                              let maxValue0 = Math.max(...psup1_arrForMaxMinCleaned)
                              let firstCount0 = 0
                              let secondCount0 = 0
                              let thirdCount0 = 0
                              let fourthCount0 = 0
                              let fifthCount0 = 0
                              let sixthCount0 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(psup1_arr6[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII6").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII6").html("=1st")
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII5").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII5").html("=1st")
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII4").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII4").html("=1st")
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII3").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII3").html("=1st")
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII2").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII2").html("=1st")
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII1").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(psup1_arr6[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(psup1_arr6[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(psup1_arr6[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(psup1_arr6[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(psup1_arr6[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                              //end of loop
                          }
                          //End of PSup Ranking
                        }
                        // end of PSup if statement
    
                          //start cohort ranking
                              let cohortCII_arrCounter = ['','','','','','']
                              let cohortCII_arr6 = ['','','','','','']
                              let cohortCII_arr5 = ['','','','','','']
                              let cohortCII_arr4 = ['','','','','','']
                              let cohortCII_arr3 = ['','','','','','']
                              let cohortCII_arr2 = ['','','','','','']
                              let cohortCII_arr1 = ['','','','','','']
                              let cohortCII_arrForMaxMin = ['','','','','','']
                              let cohortCII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-cohort_all_1000_CII6").html() == "1st"){
                                cohortCII_arr6[0] = cohortCII_arr6[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "2nd"){
                                cohortCII_arr6[1] = cohortCII_arr6[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "3rd"){
                                cohortCII_arr6[2] = cohortCII_arr6[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "4th"){
                                cohortCII_arr6[3] = cohortCII_arr6[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "5th"){
                                cohortCII_arr6[4] = cohortCII_arr6[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "6th"){
                                cohortCII_arr6[5] = cohortCII_arr6[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-cohort_all_1000_CII5").html() == "1st"){
                                cohortCII_arr5[0] = cohortCII_arr5[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "2nd"){
                                cohortCII_arr5[1] = cohortCII_arr5[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "3rd"){
                                cohortCII_arr5[2] = cohortCII_arr5[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "4th"){
                                cohortCII_arr5[3] = cohortCII_arr5[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "5th"){
                                cohortCII_arr5[4] = cohortCII_arr5[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "6th"){
                                cohortCII_arr5[5] = cohortCII_arr5[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-cohort_all_1000_CII4").html() == "1st"){
                                cohortCII_arr4[0] = cohortCII_arr4[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "2nd"){
                                cohortCII_arr4[1] = cohortCII_arr4[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "3rd"){
                                cohortCII_arr4[2] = cohortCII_arr4[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "4th"){
                                cohortCII_arr4[3] = cohortCII_arr4[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "5th"){
                                cohortCII_arr4[4] = cohortCII_arr4[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "6th"){
                                cohortCII_arr4[5] = cohortCII_arr4[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-cohort_all_1000_CII3").html() == "1st"){
                                cohortCII_arr3[0] = cohortCII_arr3[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "2nd"){
                                cohortCII_arr3[1] = cohortCII_arr3[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "3rd"){
                                cohortCII_arr3[2] = cohortCII_arr3[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "4th"){
                                cohortCII_arr3[3] = cohortCII_arr3[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "5th"){
                                cohortCII_arr3[4] = cohortCII_arr3[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "6th"){
                                cohortCII_arr3[5] = cohortCII_arr3[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-cohort_all_1000_CII2").html() == "1st"){
                                cohortCII_arr2[0] = cohortCII_arr2[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "2nd"){
                                cohortCII_arr2[1] = cohortCII_arr2[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "3rd"){
                                cohortCII_arr2[2] = cohortCII_arr2[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "4th"){
                                cohortCII_arr2[3] = cohortCII_arr2[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "5th"){
                                cohortCII_arr2[4] = cohortCII_arr2[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "6th"){
                                cohortCII_arr2[5] = cohortCII_arr2[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-cohort_all_1000_CII1").html() == "1st"){
                                cohortCII_arr1[0] = cohortCII_arr1[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "2nd"){
                                cohortCII_arr1[1] = cohortCII_arr1[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "3rd"){
                                cohortCII_arr1[2] = cohortCII_arr1[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "4th"){
                                cohortCII_arr1[3] = cohortCII_arr1[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "5th"){
                                cohortCII_arr1[4] = cohortCII_arr1[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "6th"){
                                cohortCII_arr1[5] = cohortCII_arr1[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
             
                            //condition here
                            cohortCII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  cohortCII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue = Math.min(...cohortCII_arrForMaxMinCleaned)
                              let maxValue = Math.max(...cohortCII_arrForMaxMinCleaned)
                              let firstCount = 0
                              let secondCount = 0
                              let thirdCount = 0
                              let fourthCount = 0
                              let fifthCount = 0
                              let sixthCount = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(cohortCII_arr6[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII6").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=1st")
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII5").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=1st")
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII4").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=1st")
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII3").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=1st")
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII2").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=1st")
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII1").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(cohortCII_arr6[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(cohortCII_arr6[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(cohortCII_arr6[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(cohortCII_arr6[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(cohortCII_arr6[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                              //end of loop
                              }
                            //End of Cohort Ranking
    
                          //start my ranking
                              let all_nomCII_arrCounter = ['','','','','','']
                              let all_nomCII_arr6 = ['','','','','','']
                              let all_nomCII_arr5 = ['','','','','','']
                              let all_nomCII_arr4 = ['','','','','','']
                              let all_nomCII_arr3 = ['','','','','','']
                              let all_nomCII_arr2 = ['','','','','','']
                              let all_nomCII_arr1 = ['','','','','','']
                              let all_nomCII_arrForMaxMin = ['','','','','','']
                              let all_nomCII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-all_nom999_CII6").html() == "1st"){
                                all_nomCII_arr6[0] = all_nomCII_arr6[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII6").html() == "2nd"){
                                all_nomCII_arr6[1] = all_nomCII_arr6[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII6").html() == "3rd"){
                                all_nomCII_arr6[2] = all_nomCII_arr6[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII6").html() == "4th"){
                                all_nomCII_arr6[3] = all_nomCII_arr6[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII6").html() == "5th"){
                                all_nomCII_arr6[4] = all_nomCII_arr6[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII6").html() == "6th"){
                                all_nomCII_arr6[5] = all_nomCII_arr6[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-all_nom999_CII5").html() == "1st"){
                                all_nomCII_arr5[0] = all_nomCII_arr5[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII5").html() == "2nd"){
                                all_nomCII_arr5[1] = all_nomCII_arr5[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII5").html() == "3rd"){
                                all_nomCII_arr5[2] = all_nomCII_arr5[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII5").html() == "4th"){
                                all_nomCII_arr5[3] = all_nomCII_arr5[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII5").html() == "5th"){
                                all_nomCII_arr5[4] = all_nomCII_arr5[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII5").html() == "6th"){
                                all_nomCII_arr5[5] = all_nomCII_arr5[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-all_nom999_CII4").html() == "1st"){
                                all_nomCII_arr4[0] = all_nomCII_arr4[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII4").html() == "2nd"){
                                all_nomCII_arr4[1] = all_nomCII_arr4[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII4").html() == "3rd"){
                                all_nomCII_arr4[2] = all_nomCII_arr4[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII4").html() == "4th"){
                                all_nomCII_arr4[3] = all_nomCII_arr4[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII4").html() == "5th"){
                                all_nomCII_arr4[4] = all_nomCII_arr4[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII4").html() == "6th"){
                                all_nomCII_arr4[5] = all_nomCII_arr4[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-all_nom999_CII3").html() == "1st"){
                                all_nomCII_arr3[0] = all_nomCII_arr3[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII3").html() == "2nd"){
                                all_nomCII_arr3[1] = all_nomCII_arr3[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII3").html() == "3rd"){
                                all_nomCII_arr3[2] = all_nomCII_arr3[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII3").html() == "4th"){
                                all_nomCII_arr3[3] = all_nomCII_arr3[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII3").html() == "5th"){
                                all_nomCII_arr3[4] = all_nomCII_arr3[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII3").html() == "6th"){
                                all_nomCII_arr3[5] = all_nomCII_arr3[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-all_nom999_CII2").html() == "1st"){
                                all_nomCII_arr2[0] = all_nomCII_arr2[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII2").html() == "2nd"){
                                all_nomCII_arr2[1] = all_nomCII_arr2[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII2").html() == "3rd"){
                                all_nomCII_arr2[2] = all_nomCII_arr2[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII2").html() == "4th"){
                                all_nomCII_arr2[3] = all_nomCII_arr2[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII2").html() == "5th"){
                                all_nomCII_arr2[4] = all_nomCII_arr2[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII2").html() == "6th"){
                                all_nomCII_arr2[5] = all_nomCII_arr2[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-all_nom999_CII1").html() == "1st"){
                                all_nomCII_arr1[0] = all_nomCII_arr1[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII1").html() == "2nd"){
                                all_nomCII_arr1[1] = all_nomCII_arr1[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII1").html() == "3rd"){
                                all_nomCII_arr1[2] = all_nomCII_arr1[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII1").html() == "4th"){
                                all_nomCII_arr1[3] = all_nomCII_arr1[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII1").html() == "5th"){
                                all_nomCII_arr1[4] = all_nomCII_arr1[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII1").html() == "6th"){
                                all_nomCII_arr1[5] = all_nomCII_arr1[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            all_nomCII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  all_nomCII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue2 = Math.min(...all_nomCII_arrForMaxMinCleaned)
                              let maxValue2 = Math.max(...all_nomCII_arrForMaxMinCleaned)
                              let firstCount2 = 0
                              let secondCount2 = 0
                              let thirdCount2 = 0
                              let fourthCount2 = 0
                              let fifthCount2 = 0
                              let sixthCount2 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(all_nomCII_arr6[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII6").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=1st")
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII5").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=1st")
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII4").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=1st")
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII3").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=1st")
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII2").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=1st")
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII1").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(all_nomCII_arr6[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(all_nomCII_arr6[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(all_nomCII_arr6[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(all_nomCII_arr6[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(all_nomCII_arr6[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                              //end of loop
                              }
                            //end of my Ranking
    
                            //start Peers
                            if(res.data[0].peer2_N > 1){
                              let peer2_CII_arrCounter = ['','','','','','']
                              let peer2_CII_arr6 = ['','','','','','']
                              let peer2_CII_arr5 = ['','','','','','']
                              let peer2_CII_arr4 = ['','','','','','']
                              let peer2_CII_arr3 = ['','','','','','']
                              let peer2_CII_arr2 = ['','','','','','']
                              let peer2_CII_arr1 = ['','','','','','']
                              let peer2_CII_arrForMaxMin = ['','','','','','']
                              let peer2_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-peer2_CII6").html() == "1st"){
                                peer2_CII_arr6[0] = peer2_CII_arr6[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII6").html() == "2nd"){
                                peer2_CII_arr6[1] = peer2_CII_arr6[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII6").html() == "3rd"){
                                peer2_CII_arr6[2] = peer2_CII_arr6[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII6").html() == "4th"){
                                peer2_CII_arr6[3] = peer2_CII_arr6[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII6").html() == "5th"){
                                peer2_CII_arr6[4] = peer2_CII_arr6[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII6").html() == "6th"){
                                peer2_CII_arr6[5] = peer2_CII_arr6[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-peer2_CII5").html() == "1st"){
                                peer2_CII_arr5[0] = peer2_CII_arr5[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII5").html() == "2nd"){
                                peer2_CII_arr5[1] = peer2_CII_arr5[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII5").html() == "3rd"){
                                peer2_CII_arr5[2] = peer2_CII_arr5[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII5").html() == "4th"){
                                peer2_CII_arr5[3] = peer2_CII_arr5[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII5").html() == "5th"){
                                peer2_CII_arr5[4] = peer2_CII_arr5[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII5").html() == "6th"){
                                peer2_CII_arr5[5] = peer2_CII_arr5[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-peer2_CII4").html() == "1st"){
                                peer2_CII_arr4[0] = peer2_CII_arr4[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII4").html() == "2nd"){
                                peer2_CII_arr4[1] = peer2_CII_arr4[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII4").html() == "3rd"){
                                peer2_CII_arr4[2] = peer2_CII_arr4[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII4").html() == "4th"){
                                peer2_CII_arr4[3] = peer2_CII_arr4[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII4").html() == "5th"){
                                peer2_CII_arr4[4] = peer2_CII_arr4[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII4").html() == "6th"){
                                peer2_CII_arr4[5] = peer2_CII_arr4[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-peer2_CII3").html() == "1st"){
                                peer2_CII_arr3[0] = peer2_CII_arr3[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII3").html() == "2nd"){
                                peer2_CII_arr3[1] = peer2_CII_arr3[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII3").html() == "3rd"){
                                peer2_CII_arr3[2] = peer2_CII_arr3[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII3").html() == "4th"){
                                peer2_CII_arr3[3] = peer2_CII_arr3[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII3").html() == "5th"){
                                peer2_CII_arr3[4] = peer2_CII_arr3[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII3").html() == "6th"){
                                peer2_CII_arr3[5] = peer2_CII_arr3[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-peer2_CII2").html() == "1st"){
                                peer2_CII_arr2[0] = peer2_CII_arr2[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII2").html() == "2nd"){
                                peer2_CII_arr2[1] = peer2_CII_arr2[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII2").html() == "3rd"){
                                peer2_CII_arr2[2] = peer2_CII_arr2[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII2").html() == "4th"){
                                peer2_CII_arr2[3] = peer2_CII_arr2[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII2").html() == "5th"){
                                peer2_CII_arr2[4] = peer2_CII_arr2[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII2").html() == "6th"){
                                peer2_CII_arr2[5] = peer2_CII_arr2[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-peer2_CII1").html() == "1st"){
                                peer2_CII_arr1[0] = peer2_CII_arr1[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII1").html() == "2nd"){
                                peer2_CII_arr1[1] = peer2_CII_arr1[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII1").html() == "3rd"){
                                peer2_CII_arr1[2] = peer2_CII_arr1[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII1").html() == "4th"){
                                peer2_CII_arr1[3] = peer2_CII_arr1[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII1").html() == "5th"){
                                peer2_CII_arr1[4] = peer2_CII_arr1[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII1").html() == "6th"){
                                peer2_CII_arr1[5] = peer2_CII_arr1[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            peer2_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  peer2_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue10 = Math.min(...peer2_CII_arrForMaxMinCleaned)
                              let maxValue10 = Math.max(...peer2_CII_arrForMaxMinCleaned)
                              let firstCount10 = 0
                              let secondCount10 = 0
                              let thirdCount10 = 0
                              let fourthCount10 = 0
                              let fifthCount10 = 0
                              let sixthCount10 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(peer2_CII_arr6[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII6").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII6").html("=1st")
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII5").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII5").html("=1st")
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII4").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII4").html("=1st")
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII3").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII3").html("=1st")
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII2").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII2").html("=1st")
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII1").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(peer2_CII_arr6[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(peer2_CII_arr6[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(peer2_CII_arr6[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(peer2_CII_arr6[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(peer2_CII_arr6[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of peers if
                            // end of Peers
    
                            //start Direct Reports
                            if(res.data[0].direct3_N > 1){
                              let direct3_CII_arrCounter = ['','','','','','']
                              let direct3_CII_arr6 = ['','','','','','']
                              let direct3_CII_arr5 = ['','','','','','']
                              let direct3_CII_arr4 = ['','','','','','']
                              let direct3_CII_arr3 = ['','','','','','']
                              let direct3_CII_arr2 = ['','','','','','']
                              let direct3_CII_arr1 = ['','','','','','']
                              let direct3_CII_arrForMaxMin = ['','','','','','']
                              let direct3_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-direct3_CII6").html() == "1st"){
                                direct3_CII_arr6[0] = direct3_CII_arr6[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII6").html() == "2nd"){
                                direct3_CII_arr6[1] = direct3_CII_arr6[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII6").html() == "3rd"){
                                direct3_CII_arr6[2] = direct3_CII_arr6[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII6").html() == "4th"){
                                direct3_CII_arr6[3] = direct3_CII_arr6[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII6").html() == "5th"){
                                direct3_CII_arr6[4] = direct3_CII_arr6[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII6").html() == "6th"){
                                direct3_CII_arr6[5] = direct3_CII_arr6[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-direct3_CII5").html() == "1st"){
                                direct3_CII_arr5[0] = direct3_CII_arr5[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII5").html() == "2nd"){
                                direct3_CII_arr5[1] = direct3_CII_arr5[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII5").html() == "3rd"){
                                direct3_CII_arr5[2] = direct3_CII_arr5[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII5").html() == "4th"){
                                direct3_CII_arr5[3] = direct3_CII_arr5[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII5").html() == "5th"){
                                direct3_CII_arr5[4] = direct3_CII_arr5[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII5").html() == "6th"){
                                direct3_CII_arr5[5] = direct3_CII_arr5[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-direct3_CII4").html() == "1st"){
                                direct3_CII_arr4[0] = direct3_CII_arr4[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII4").html() == "2nd"){
                                direct3_CII_arr4[1] = direct3_CII_arr4[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII4").html() == "3rd"){
                                direct3_CII_arr4[2] = direct3_CII_arr4[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII4").html() == "4th"){
                                direct3_CII_arr4[3] = direct3_CII_arr4[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII4").html() == "5th"){
                                direct3_CII_arr4[4] = direct3_CII_arr4[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII4").html() == "6th"){
                                direct3_CII_arr4[5] = direct3_CII_arr4[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-direct3_CII3").html() == "1st"){
                                direct3_CII_arr3[0] = direct3_CII_arr3[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII3").html() == "2nd"){
                                direct3_CII_arr3[1] = direct3_CII_arr3[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII3").html() == "3rd"){
                                direct3_CII_arr3[2] = direct3_CII_arr3[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII3").html() == "4th"){
                                direct3_CII_arr3[3] = direct3_CII_arr3[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII3").html() == "5th"){
                                direct3_CII_arr3[4] = direct3_CII_arr3[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII3").html() == "6th"){
                                direct3_CII_arr3[5] = direct3_CII_arr3[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-direct3_CII2").html() == "1st"){
                                direct3_CII_arr2[0] = direct3_CII_arr2[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII2").html() == "2nd"){
                                direct3_CII_arr2[1] = direct3_CII_arr2[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII2").html() == "3rd"){
                                direct3_CII_arr2[2] = direct3_CII_arr2[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII2").html() == "4th"){
                                direct3_CII_arr2[3] = direct3_CII_arr2[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII2").html() == "5th"){
                                direct3_CII_arr2[4] = direct3_CII_arr2[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII2").html() == "6th"){
                                direct3_CII_arr2[5] = direct3_CII_arr2[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-direct3_CII1").html() == "1st"){
                                direct3_CII_arr1[0] = direct3_CII_arr1[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII1").html() == "2nd"){
                                direct3_CII_arr1[1] = direct3_CII_arr1[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII1").html() == "3rd"){
                                direct3_CII_arr1[2] = direct3_CII_arr1[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII1").html() == "4th"){
                                direct3_CII_arr1[3] = direct3_CII_arr1[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII1").html() == "5th"){
                                direct3_CII_arr1[4] = direct3_CII_arr1[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII1").html() == "6th"){
                                direct3_CII_arr1[5] = direct3_CII_arr1[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            direct3_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  direct3_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue11 = Math.min(...direct3_CII_arrForMaxMinCleaned)
                              let maxValue11 = Math.max(...direct3_CII_arrForMaxMinCleaned)
                              let firstCount11 = 0
                              let secondCount11 = 0
                              let thirdCount11 = 0
                              let fourthCount11 = 0
                              let fifthCount11 = 0
                              let sixthCount11 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(direct3_CII_arr6[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII6").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII6").html("=1st")
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII5").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII5").html("=1st")
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII4").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII4").html("=1st")
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII3").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII3").html("=1st")
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII2").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII2").html("=1st")
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII1").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(direct3_CII_arr6[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(direct3_CII_arr6[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(direct3_CII_arr6[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(direct3_CII_arr6[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(direct3_CII_arr6[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                                //end of loop
                              }
                            }
                            // end of direct reports if
                            // end of direct reports
    
                          //start Peers/Direct
                          if(res.data[0].PD996_N > 1){
                              let PD996_CII_arrCounter = ['','','','','','']
                              let PD996_CII_arr6 = ['','','','','','']
                              let PD996_CII_arr5 = ['','','','','','']
                              let PD996_CII_arr4 = ['','','','','','']
                              let PD996_CII_arr3 = ['','','','','','']
                              let PD996_CII_arr2 = ['','','','','','']
                              let PD996_CII_arr1 = ['','','','','','']
                              let PD996_CII_arrForMaxMin = ['','','','','','']
                              let PD996_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-PD996_CII6").html() == "1st"){
                                PD996_CII_arr6[0] = PD996_CII_arr6[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII6").html() == "2nd"){
                                PD996_CII_arr6[1] = PD996_CII_arr6[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII6").html() == "3rd"){
                                PD996_CII_arr6[2] = PD996_CII_arr6[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII6").html() == "4th"){
                                PD996_CII_arr6[3] = PD996_CII_arr6[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII6").html() == "5th"){
                                PD996_CII_arr6[4] = PD996_CII_arr6[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII6").html() == "6th"){
                                PD996_CII_arr6[5] = PD996_CII_arr6[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-PD996_CII5").html() == "1st"){
                                PD996_CII_arr5[0] = PD996_CII_arr5[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII5").html() == "2nd"){
                                PD996_CII_arr5[1] = PD996_CII_arr5[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII5").html() == "3rd"){
                                PD996_CII_arr5[2] = PD996_CII_arr5[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII5").html() == "4th"){
                                PD996_CII_arr5[3] = PD996_CII_arr5[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII5").html() == "5th"){
                                PD996_CII_arr5[4] = PD996_CII_arr5[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII5").html() == "6th"){
                                PD996_CII_arr5[5] = PD996_CII_arr5[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-PD996_CII4").html() == "1st"){
                                PD996_CII_arr4[0] = PD996_CII_arr4[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII4").html() == "2nd"){
                                PD996_CII_arr4[1] = PD996_CII_arr4[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII4").html() == "3rd"){
                                PD996_CII_arr4[2] = PD996_CII_arr4[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII4").html() == "4th"){
                                PD996_CII_arr4[3] = PD996_CII_arr4[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII4").html() == "5th"){
                                PD996_CII_arr4[4] = PD996_CII_arr4[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII4").html() == "6th"){
                                PD996_CII_arr4[5] = PD996_CII_arr4[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-PD996_CII3").html() == "1st"){
                                PD996_CII_arr3[0] = PD996_CII_arr3[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII3").html() == "2nd"){
                                PD996_CII_arr3[1] = PD996_CII_arr3[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII3").html() == "3rd"){
                                PD996_CII_arr3[2] = PD996_CII_arr3[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII3").html() == "4th"){
                                PD996_CII_arr3[3] = PD996_CII_arr3[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII3").html() == "5th"){
                                PD996_CII_arr3[4] = PD996_CII_arr3[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII3").html() == "6th"){
                                PD996_CII_arr3[5] = PD996_CII_arr3[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-PD996_CII2").html() == "1st"){
                                PD996_CII_arr2[0] = PD996_CII_arr2[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII2").html() == "2nd"){
                                PD996_CII_arr2[1] = PD996_CII_arr2[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII2").html() == "3rd"){
                                PD996_CII_arr2[2] = PD996_CII_arr2[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII2").html() == "4th"){
                                PD996_CII_arr2[3] = PD996_CII_arr2[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII2").html() == "5th"){
                                PD996_CII_arr2[4] = PD996_CII_arr2[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII2").html() == "6th"){
                                PD996_CII_arr2[5] = PD996_CII_arr2[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-PD996_CII1").html() == "1st"){
                                PD996_CII_arr1[0] = PD996_CII_arr1[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII1").html() == "2nd"){
                                PD996_CII_arr1[1] = PD996_CII_arr1[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII1").html() == "3rd"){
                                PD996_CII_arr1[2] = PD996_CII_arr1[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII1").html() == "4th"){
                                PD996_CII_arr1[3] = PD996_CII_arr1[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII1").html() == "5th"){
                                PD996_CII_arr1[4] = PD996_CII_arr1[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII1").html() == "6th"){
                                PD996_CII_arr1[5] = PD996_CII_arr1[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            PD996_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  PD996_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue12 = Math.min(...PD996_CII_arrForMaxMinCleaned)
                              let maxValue12 = Math.max(...PD996_CII_arrForMaxMinCleaned)
                              let firstCount12 = 0
                              let secondCount12 = 0
                              let thirdCount12 = 0
                              let fourthCount12 = 0
                              let fifthCount12 = 0
                              let sixthCount12 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(PD996_CII_arr6[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII6").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII6").html("=1st")
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII5").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII5").html("=1st")
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII4").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII4").html("=1st")
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII3").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII3").html("=1st")
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII2").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII2").html("=1st")
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII1").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(PD996_CII_arr6[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(PD996_CII_arr6[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(PD996_CII_arr6[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(PD996_CII_arr6[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(PD996_CII_arr6[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of peers/direct reports if
                            // end of peers/direct reports
    
                            //start Internal
                          if(res.data[0].internal4_N > 1){
                              let internal4_CII_arrCounter = ['','','','','','']
                              let internal4_CII_arr6 = ['','','','','','']
                              let internal4_CII_arr5 = ['','','','','','']
                              let internal4_CII_arr4 = ['','','','','','']
                              let internal4_CII_arr3 = ['','','','','','']
                              let internal4_CII_arr2 = ['','','','','','']
                              let internal4_CII_arr1 = ['','','','','','']
                              let internal4_CII_arrForMaxMin = ['','','','','','']
                              let internal4_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-internal4_CII6").html() == "1st"){
                                internal4_CII_arr6[0] = internal4_CII_arr6[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII6").html() == "2nd"){
                                internal4_CII_arr6[1] = internal4_CII_arr6[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII6").html() == "3rd"){
                                internal4_CII_arr6[2] = internal4_CII_arr6[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII6").html() == "4th"){
                                internal4_CII_arr6[3] = internal4_CII_arr6[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII6").html() == "5th"){
                                internal4_CII_arr6[4] = internal4_CII_arr6[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII6").html() == "6th"){
                                internal4_CII_arr6[5] = internal4_CII_arr6[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-internal4_CII5").html() == "1st"){
                                internal4_CII_arr5[0] = internal4_CII_arr5[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII5").html() == "2nd"){
                                internal4_CII_arr5[1] = internal4_CII_arr5[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII5").html() == "3rd"){
                                internal4_CII_arr5[2] = internal4_CII_arr5[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII5").html() == "4th"){
                                internal4_CII_arr5[3] = internal4_CII_arr5[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII5").html() == "5th"){
                                internal4_CII_arr5[4] = internal4_CII_arr5[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII5").html() == "6th"){
                                internal4_CII_arr5[5] = internal4_CII_arr5[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-internal4_CII4").html() == "1st"){
                                internal4_CII_arr4[0] = internal4_CII_arr4[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII4").html() == "2nd"){
                                internal4_CII_arr4[1] = internal4_CII_arr4[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII4").html() == "3rd"){
                                internal4_CII_arr4[2] = internal4_CII_arr4[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII4").html() == "4th"){
                                internal4_CII_arr4[3] = internal4_CII_arr4[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII4").html() == "5th"){
                                internal4_CII_arr4[4] = internal4_CII_arr4[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII4").html() == "6th"){
                                internal4_CII_arr4[5] = internal4_CII_arr4[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-internal4_CII3").html() == "1st"){
                                internal4_CII_arr3[0] = internal4_CII_arr3[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII3").html() == "2nd"){
                                internal4_CII_arr3[1] = internal4_CII_arr3[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII3").html() == "3rd"){
                                internal4_CII_arr3[2] = internal4_CII_arr3[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII3").html() == "4th"){
                                internal4_CII_arr3[3] = internal4_CII_arr3[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII3").html() == "5th"){
                                internal4_CII_arr3[4] = internal4_CII_arr3[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII3").html() == "6th"){
                                internal4_CII_arr3[5] = internal4_CII_arr3[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-internal4_CII2").html() == "1st"){
                                internal4_CII_arr2[0] = internal4_CII_arr2[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII2").html() == "2nd"){
                                internal4_CII_arr2[1] = internal4_CII_arr2[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII2").html() == "3rd"){
                                internal4_CII_arr2[2] = internal4_CII_arr2[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII2").html() == "4th"){
                                internal4_CII_arr2[3] = internal4_CII_arr2[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII2").html() == "5th"){
                                internal4_CII_arr2[4] = internal4_CII_arr2[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII2").html() == "6th"){
                                internal4_CII_arr2[5] = internal4_CII_arr2[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-internal4_CII1").html() == "1st"){
                                internal4_CII_arr1[0] = internal4_CII_arr1[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII1").html() == "2nd"){
                                internal4_CII_arr1[1] = internal4_CII_arr1[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII1").html() == "3rd"){
                                internal4_CII_arr1[2] = internal4_CII_arr1[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII1").html() == "4th"){
                                internal4_CII_arr1[3] = internal4_CII_arr1[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII1").html() == "5th"){
                                internal4_CII_arr1[4] = internal4_CII_arr1[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII1").html() == "6th"){
                                internal4_CII_arr1[5] = internal4_CII_arr1[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            internal4_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  internal4_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue13 = Math.min(...internal4_CII_arrForMaxMinCleaned)
                              let maxValue13 = Math.max(...internal4_CII_arrForMaxMinCleaned)
                              let firstCount13 = 0
                              let secondCount13 = 0
                              let thirdCount13 = 0
                              let fourthCount13 = 0
                              let fifthCount13 = 0
                              let sixthCount13 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(internal4_CII_arr6[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII6").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII6").html("=1st")
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII5").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII5").html("=1st")
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII4").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII4").html("=1st")
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII3").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII3").html("=1st")
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII2").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII2").html("=1st")
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII1").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(internal4_CII_arr6[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(internal4_CII_arr6[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(internal4_CII_arr6[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(internal4_CII_arr6[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(internal4_CII_arr6[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of internal if
                            // end of internal
    
                            //start External
                          if(res.data[0].external5_N > 1){
                              let external5_CII_arrCounter = ['','','','','','']
                              let external5_CII_arr6 = ['','','','','','']
                              let external5_CII_arr5 = ['','','','','','']
                              let external5_CII_arr4 = ['','','','','','']
                              let external5_CII_arr3 = ['','','','','','']
                              let external5_CII_arr2 = ['','','','','','']
                              let external5_CII_arr1 = ['','','','','','']
                              let external5_CII_arrForMaxMin = ['','','','','','']
                              let external5_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-external5_CII6").html() == "1st"){
                                external5_CII_arr6[0] = external5_CII_arr6[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII6").html() == "2nd"){
                                external5_CII_arr6[1] = external5_CII_arr6[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII6").html() == "3rd"){
                                external5_CII_arr6[2] = external5_CII_arr6[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII6").html() == "4th"){
                                external5_CII_arr6[3] = external5_CII_arr6[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII6").html() == "5th"){
                                external5_CII_arr6[4] = external5_CII_arr6[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII6").html() == "6th"){
                                external5_CII_arr6[5] = external5_CII_arr6[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-external5_CII5").html() == "1st"){
                                external5_CII_arr5[0] = external5_CII_arr5[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII5").html() == "2nd"){
                                external5_CII_arr5[1] = external5_CII_arr5[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII5").html() == "3rd"){
                                external5_CII_arr5[2] = external5_CII_arr5[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII5").html() == "4th"){
                                external5_CII_arr5[3] = external5_CII_arr5[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII5").html() == "5th"){
                                external5_CII_arr5[4] = external5_CII_arr5[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII5").html() == "6th"){
                                external5_CII_arr5[5] = external5_CII_arr5[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-external5_CII4").html() == "1st"){
                                external5_CII_arr4[0] = external5_CII_arr4[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII4").html() == "2nd"){
                                external5_CII_arr4[1] = external5_CII_arr4[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII4").html() == "3rd"){
                                external5_CII_arr4[2] = external5_CII_arr4[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII4").html() == "4th"){
                                external5_CII_arr4[3] = external5_CII_arr4[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII4").html() == "5th"){
                                external5_CII_arr4[4] = external5_CII_arr4[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII4").html() == "6th"){
                                external5_CII_arr4[5] = external5_CII_arr4[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-external5_CII3").html() == "1st"){
                                external5_CII_arr3[0] = external5_CII_arr3[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII3").html() == "2nd"){
                                external5_CII_arr3[1] = external5_CII_arr3[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII3").html() == "3rd"){
                                external5_CII_arr3[2] = external5_CII_arr3[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII3").html() == "4th"){
                                external5_CII_arr3[3] = external5_CII_arr3[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII3").html() == "5th"){
                                external5_CII_arr3[4] = external5_CII_arr3[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII3").html() == "6th"){
                                external5_CII_arr3[5] = external5_CII_arr3[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-external5_CII2").html() == "1st"){
                                external5_CII_arr2[0] = external5_CII_arr2[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII2").html() == "2nd"){
                                external5_CII_arr2[1] = external5_CII_arr2[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII2").html() == "3rd"){
                                external5_CII_arr2[2] = external5_CII_arr2[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII2").html() == "4th"){
                                external5_CII_arr2[3] = external5_CII_arr2[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII2").html() == "5th"){
                                external5_CII_arr2[4] = external5_CII_arr2[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII2").html() == "6th"){
                                external5_CII_arr2[5] = external5_CII_arr2[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-external5_CII1").html() == "1st"){
                                external5_CII_arr1[0] = external5_CII_arr1[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII1").html() == "2nd"){
                                external5_CII_arr1[1] = external5_CII_arr1[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII1").html() == "3rd"){
                                external5_CII_arr1[2] = external5_CII_arr1[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII1").html() == "4th"){
                                external5_CII_arr1[3] = external5_CII_arr1[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII1").html() == "5th"){
                                external5_CII_arr1[4] = external5_CII_arr1[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII1").html() == "6th"){
                                external5_CII_arr1[5] = external5_CII_arr1[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            external5_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  external5_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue14 = Math.min(...external5_CII_arrForMaxMinCleaned)
                              let maxValue14 = Math.max(...external5_CII_arrForMaxMinCleaned)
                              let firstCount14 = 0
                              let secondCount14 = 0
                              let thirdCount14 = 0
                              let fourthCount14 = 0
                              let fifthCount14 = 0
                              let sixthCount14 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(external5_CII_arr6[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII6").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII6").html("=1st")
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII5").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII5").html("=1st")
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII4").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII4").html("=1st")
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII3").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII3").html("=1st")
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII2").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII2").html("=1st")
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII1").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(external5_CII_arr6[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(external5_CII_arr6[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(external5_CII_arr6[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(external5_CII_arr6[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(external5_CII_arr6[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of external if
                            // end of external
    
                            //start internal/external
                          if(res.data[0].IE997_N > 1){
                              let IE997_CII_arrCounter = ['','','','','','']
                              let IE997_CII_arr6 = ['','','','','','']
                              let IE997_CII_arr5 = ['','','','','','']
                              let IE997_CII_arr4 = ['','','','','','']
                              let IE997_CII_arr3 = ['','','','','','']
                              let IE997_CII_arr2 = ['','','','','','']
                              let IE997_CII_arr1 = ['','','','','','']
                              let IE997_CII_arrForMaxMin = ['','','','','','']
                              let IE997_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-IE997_CII6").html() == "1st"){
                                IE997_CII_arr6[0] = IE997_CII_arr6[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII6").html() == "2nd"){
                                IE997_CII_arr6[1] = IE997_CII_arr6[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII6").html() == "3rd"){
                                IE997_CII_arr6[2] = IE997_CII_arr6[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII6").html() == "4th"){
                                IE997_CII_arr6[3] = IE997_CII_arr6[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII6").html() == "5th"){
                                IE997_CII_arr6[4] = IE997_CII_arr6[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII6").html() == "6th"){
                                IE997_CII_arr6[5] = IE997_CII_arr6[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-IE997_CII5").html() == "1st"){
                                IE997_CII_arr5[0] = IE997_CII_arr5[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII5").html() == "2nd"){
                                IE997_CII_arr5[1] = IE997_CII_arr5[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII5").html() == "3rd"){
                                IE997_CII_arr5[2] = IE997_CII_arr5[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII5").html() == "4th"){
                                IE997_CII_arr5[3] = IE997_CII_arr5[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII5").html() == "5th"){
                                IE997_CII_arr5[4] = IE997_CII_arr5[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII5").html() == "6th"){
                                IE997_CII_arr5[5] = IE997_CII_arr5[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-IE997_CII4").html() == "1st"){
                                IE997_CII_arr4[0] = IE997_CII_arr4[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII4").html() == "2nd"){
                                IE997_CII_arr4[1] = IE997_CII_arr4[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII4").html() == "3rd"){
                                IE997_CII_arr4[2] = IE997_CII_arr4[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII4").html() == "4th"){
                                IE997_CII_arr4[3] = IE997_CII_arr4[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII4").html() == "5th"){
                                IE997_CII_arr4[4] = IE997_CII_arr4[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII4").html() == "6th"){
                                IE997_CII_arr4[5] = IE997_CII_arr4[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-IE997_CII3").html() == "1st"){
                                IE997_CII_arr3[0] = IE997_CII_arr3[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII3").html() == "2nd"){
                                IE997_CII_arr3[1] = IE997_CII_arr3[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII3").html() == "3rd"){
                                IE997_CII_arr3[2] = IE997_CII_arr3[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII3").html() == "4th"){
                                IE997_CII_arr3[3] = IE997_CII_arr3[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII3").html() == "5th"){
                                IE997_CII_arr3[4] = IE997_CII_arr3[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII3").html() == "6th"){
                                IE997_CII_arr3[5] = IE997_CII_arr3[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-IE997_CII2").html() == "1st"){
                                IE997_CII_arr2[0] = IE997_CII_arr2[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII2").html() == "2nd"){
                                IE997_CII_arr2[1] = IE997_CII_arr2[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII2").html() == "3rd"){
                                IE997_CII_arr2[2] = IE997_CII_arr2[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII2").html() == "4th"){
                                IE997_CII_arr2[3] = IE997_CII_arr2[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII2").html() == "5th"){
                                IE997_CII_arr2[4] = IE997_CII_arr2[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII2").html() == "6th"){
                                IE997_CII_arr2[5] = IE997_CII_arr2[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-IE997_CII1").html() == "1st"){
                                IE997_CII_arr1[0] = IE997_CII_arr1[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII1").html() == "2nd"){
                                IE997_CII_arr1[1] = IE997_CII_arr1[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII1").html() == "3rd"){
                                IE997_CII_arr1[2] = IE997_CII_arr1[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII1").html() == "4th"){
                                IE997_CII_arr1[3] = IE997_CII_arr1[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII1").html() == "5th"){
                                IE997_CII_arr1[4] = IE997_CII_arr1[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII1").html() == "6th"){
                                IE997_CII_arr1[5] = IE997_CII_arr1[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            IE997_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  IE997_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue15 = Math.min(...IE997_CII_arrForMaxMinCleaned)
                              let maxValue15 = Math.max(...IE997_CII_arrForMaxMinCleaned)
                              let firstCount15 = 0
                              let secondCount15 = 0
                              let thirdCount15 = 0
                              let fourthCount15 = 0
                              let fifthCount15 = 0
                              let sixthCount15 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(IE997_CII_arr6[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII6").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII6").html("=1st")
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII5").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII5").html("=1st")
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII4").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII4").html("=1st")
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII3").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII3").html("=1st")
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII2").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII2").html("=1st")
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII1").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(IE997_CII_arr6[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(IE997_CII_arr6[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(IE997_CII_arr6[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(IE997_CII_arr6[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(IE997_CII_arr6[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of internal/external if
                            // end of internal/external
    
    
                          //start my PDIE
                              let PDIECII_arrCounter = ['','','','','','']
                              let PDIECII_arr6 = ['','','','','','']
                              let PDIECII_arr5 = ['','','','','','']
                              let PDIECII_arr4 = ['','','','','','']
                              let PDIECII_arr3 = ['','','','','','']
                              let PDIECII_arr2 = ['','','','','','']
                              let PDIECII_arr1 = ['','','','','','']
                              let PDIECII_arrForMaxMin = ['','','','','','']
                              let PDIECII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-PDIE998_CII6").html() == "1st"){
                                PDIECII_arr6[0] = PDIECII_arr6[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII6").html() == "2nd"){
                                PDIECII_arr6[1] = PDIECII_arr6[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII6").html() == "3rd"){
                                PDIECII_arr6[2] = PDIECII_arr6[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII6").html() == "4th"){
                                PDIECII_arr6[3] = PDIECII_arr6[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII6").html() == "5th"){
                                PDIECII_arr6[4] = PDIECII_arr6[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII6").html() == "6th"){
                                PDIECII_arr6[5] = PDIECII_arr6[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-PDIE998_CII5").html() == "1st"){
                                PDIECII_arr5[0] = PDIECII_arr5[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII5").html() == "2nd"){
                                PDIECII_arr5[1] = PDIECII_arr5[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII5").html() == "3rd"){
                                PDIECII_arr5[2] = PDIECII_arr5[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII5").html() == "4th"){
                                PDIECII_arr5[3] = PDIECII_arr5[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII5").html() == "5th"){
                                PDIECII_arr5[4] = PDIECII_arr5[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII5").html() == "6th"){
                                PDIECII_arr5[5] = PDIECII_arr5[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-PDIE998_CII4").html() == "1st"){
                                PDIECII_arr4[0] = PDIECII_arr4[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII4").html() == "2nd"){
                                PDIECII_arr4[1] = PDIECII_arr4[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII4").html() == "3rd"){
                                PDIECII_arr4[2] = PDIECII_arr4[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII4").html() == "4th"){
                                PDIECII_arr4[3] = PDIECII_arr4[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII4").html() == "5th"){
                                PDIECII_arr4[4] = PDIECII_arr4[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII4").html() == "6th"){
                                PDIECII_arr4[5] = PDIECII_arr4[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-PDIE998_CII3").html() == "1st"){
                                PDIECII_arr3[0] = PDIECII_arr3[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII3").html() == "2nd"){
                                PDIECII_arr3[1] = PDIECII_arr3[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII3").html() == "3rd"){
                                PDIECII_arr3[2] = PDIECII_arr3[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII3").html() == "4th"){
                                PDIECII_arr3[3] = PDIECII_arr3[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII3").html() == "5th"){
                                PDIECII_arr3[4] = PDIECII_arr3[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII3").html() == "6th"){
                                PDIECII_arr3[5] = PDIECII_arr3[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-PDIE998_CII2").html() == "1st"){
                                PDIECII_arr2[0] = PDIECII_arr2[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII2").html() == "2nd"){
                                PDIECII_arr2[1] = PDIECII_arr2[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII2").html() == "3rd"){
                                PDIECII_arr2[2] = PDIECII_arr2[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII2").html() == "4th"){
                                PDIECII_arr2[3] = PDIECII_arr2[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII2").html() == "5th"){
                                PDIECII_arr2[4] = PDIECII_arr2[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII2").html() == "6th"){
                                PDIECII_arr2[5] = PDIECII_arr2[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-PDIE998_CII1").html() == "1st"){
                                PDIECII_arr1[0] = PDIECII_arr1[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII1").html() == "2nd"){
                                PDIECII_arr1[1] = PDIECII_arr1[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII1").html() == "3rd"){
                                PDIECII_arr1[2] = PDIECII_arr1[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII1").html() == "4th"){
                                PDIECII_arr1[3] = PDIECII_arr1[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII1").html() == "5th"){
                                PDIECII_arr1[4] = PDIECII_arr1[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII1").html() == "6th"){
                                PDIECII_arr1[5] = PDIECII_arr1[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            PDIECII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  PDIECII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue3 = Math.min(...PDIECII_arrForMaxMinCleaned)
                              let maxValue3 = Math.max(...PDIECII_arrForMaxMinCleaned)
                              let firstCount3 = 0
                              let secondCount3 = 0
                              let thirdCount3 = 0
                              let fourthCount3 = 0
                              let fifthCount3 = 0
                              let sixthCount3 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(PDIECII_arr6[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII6").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=1st")
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII5").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=1st")
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII4").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=1st")
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII3").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=1st")
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII2").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=1st")
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII1").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(PDIECII_arr6[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(PDIECII_arr6[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(PDIECII_arr6[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(PDIECII_arr6[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(PDIECII_arr6[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                              //end of loop
                              }
                            //end of PDIE
    
                              $('.table360-competency').removeClass('d-none')
                              $('.competency_spinner').addClass('d-none')
                              `
                          }
                        }
                        if (data.Template === 'Table-360-Overestimated') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".overestimated-row` + response.Trait + `").css("display","none")
                                }
                                  $(".overestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".overestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  $(".overestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                                if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) > -1.5 ){
                                  $(".overestimated-row` + response.Trait + `").css("display","none")
                                }
                              `
                            })
                            output +=`
                            overestimateChild = $('#overestimate-wrapper>tr:not([style*="display: none"]')
                            if(overestimateChild.length === 0){
                              $(".overestimated-none").removeClass('d-none')
                              $(".overestimated-row-head").addClass('d-none')
                            } else {
                              $(".overestimated-none").addClass('d-none')
                              $(".overestimated-row-head").removeClass('d-none')
                            }
                            overestimateChild.sort(function(a, b) {
                                return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                            }).appendTo($("#overestimate-wrapper"));
                            `
                          }
                        }
                        if (data.Template === 'Table-360-Underestimated') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".underestimated-row` + response.Trait + `").css("display","none")
                                }
                                $(".underestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".underestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".underestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                                if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) < 1.5 ){
                                  $(".underestimated-row` + response.Trait + `").css("display","none")
                                  }
                              `
                            })
                            output +=`
                            underestimateChild = $('#underestimate-wrapper>tr:not([style*="display: none"]')
                            if(underestimateChild.length === 0){
                              $(".underestimated-none").removeClass('d-none')
                              $(".underestimated-row-head").addClass('d-none')
                            } else {
                              $(".underestimated-none").addClass('d-none')
                              $(".underestimated-row-head").removeClass('d-none')
                            }
                            underestimateChild.sort(function(a, b) {
                                return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                            }).appendTo($("#underestimate-wrapper"));
                            `
                          }
                        }
                        if (data.Template === 'Dynamic-Message-Head') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                              output += 
                              `
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
    
                              if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                              } else {
                                $(".Head-Dynamic-Message").html("` + res.Message + `")
                              }
                              `
                          }
                        }
                        if (data.Template === 'Dynamic-Message-Sub') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                              output += 
                              `
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
    
                              if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                              } else {
                                $(".Sub-Dynamic-Message").html("` + res.Message + `")
                              }
                              `
                          }
                        }
                      }
                      output +=`
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                    
                  `
                }
              }
              // add histogram for 360 individual
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                // part 1 of Histogram
                if(data.Template === 'Add-Histogram'){
                  output += `
                  axios
                  .post(
                    '${url}/r360-report-data2',
                    {
                      "survey_assignment_id": survey_assignment_id,
                      "org_id": org_id,
                      "suborg_id": subOrg_id,
                      "program_id": program_id,
                      "iteration_id": iteration_id
                  },
                    config                                            
                  )
                  .then((res) => {
                  `
                  // part 2 of Histogram
                  if (data.Template === 'Add-Histogram') { 
                  output += `
                  function checkGroups(A, B, C, D) {
                                const group1 = [A, B];
                                const group2 = [C, D];

                                const group1HasZeroAndOne = group1.includes(0) && group1.includes(1);
                                const group2HasZeroAndOne = group2.includes(0) && group2.includes(1);
                                const group1HasTwoZeros = group1.every(val => val === 0);
                                const group2HasTwoZeros = group2.every(val => val === 0);

                                return group1HasZeroAndOne || group2HasZeroAndOne || (group1HasTwoZeros && group2HasTwoZeros);
                              }
                  let avgCount = 1
                            let avgCohortCount = 1
                            let isDisplay_P = 0
                            let isDisplay_D = 0
                            let isDisplay_PD = 0
                            let isDisplay_I = 0
                            let isDisplay_E = 0
                            let isDisplay_IE = 0
                            let isDisplay_PDIE = 0
    
                            if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_PD = 1
                            } else {
                              isDisplay_P = 1
                              isDisplay_D = 1
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                            }
                            if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_IE = 1
                            } else {
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                              isDisplay_I = 1
                              isDisplay_E = 1
                            }
                            if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              avgCount = 2
                              avgCohortCount = 2
                              isDisplay_PDIE = 1
                            }
                  axios
                    .post(` + '`' + `${url}` + '/r360-histogram-report-endpoint/${survey_assignment_id}' + '`, {}, config)' + `
                    .then((resHistogram) => {
                      console.log(resHistogram.data)
                    `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        // part 3 of Histogram
                        if (data.Template === 'Add-Histogram') { 
                          output += `
                        function removeElementsWithValue(arr, val) {
                            var i = arr.length;
                            while (i--) {
                                if (arr[i] === val) {
                                    arr.splice(i, 1);
                                }
                            }
                            return arr;
                        }
                        function rankRange(num, arr) {
                          // remove duplicate values
                          let newArr = [...new Set(removeElementsWithValue(arr, 0))];
                          // sort array
                          newArr.sort((a, b) => a - b);
                          // find the index of the input number in the sorted array
                          let index = newArr.indexOf(num);
                          // calculate the rank as the index + 1
                          let rank = index + 1;
                          // calculate the range as a percentage of the rank divided by the length of the array
                          let range = (rank / newArr.length) * 100;
                          // return the range capped at 100
                          return Math.min(range, 100);
                        }

                          `
                        }
                        // part 4 of Histogram
                        if (data.Template === 'Add-Histogram') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                              //individual array
                                  let array`+ response.Trait +`_Self = []
                                  let array`+ response.Trait +`_PSup = []
                                  let array`+ response.Trait +`_P = []
                                  let array`+ response.Trait +`_D = []
                                  let array`+ response.Trait +`_I = []
                                  let array`+ response.Trait +`_E = []
                                  let array`+ response.Trait +`_PD = []
                                  let array`+ response.Trait +`_IE = []
                                  let array`+ response.Trait +`_PDIE = []
                                  let array`+ response.Trait +`_Others = []
                                  //cohort array
                                  let arrayCohort`+ response.Trait +`_Self = []
                                  let arrayCohort`+ response.Trait +`_PSup = []
                                  let arrayCohort`+ response.Trait +`_P = []
                                  let arrayCohort`+ response.Trait +`_D = []
                                  let arrayCohort`+ response.Trait +`_I = []
                                  let arrayCohort`+ response.Trait +`_E = []
                                  let arrayCohort`+ response.Trait +`_PD = []
                                  let arrayCohort`+ response.Trait +`_IE = []
                                  let arrayCohort`+ response.Trait +`_PDIE = []
                                  let arrayCohort`+ response.Trait +`_Others = []

                                  // score 1
                                  let score1`+ response.Trait +`_Self = 0
                                  let score1`+ response.Trait +`_PSup = 0
                                  let score1`+ response.Trait +`_P = 0
                                  let score1`+ response.Trait +`_D = 0
                                  let score1`+ response.Trait +`_I = 0
                                  let score1`+ response.Trait +`_E = 0
                                  let score1`+ response.Trait +`_PD = 0
                                  let score1`+ response.Trait +`_IE = 0
                                  let score1`+ response.Trait +`_PDIE = 0
                                  let score1`+ response.Trait +`_Others = 0
                                  // score 2
                                  let score2`+ response.Trait +`_Self = 0
                                  let score2`+ response.Trait +`_PSup = 0
                                  let score2`+ response.Trait +`_P = 0
                                  let score2`+ response.Trait +`_D = 0
                                  let score2`+ response.Trait +`_I = 0
                                  let score2`+ response.Trait +`_E = 0
                                  let score2`+ response.Trait +`_PD = 0
                                  let score2`+ response.Trait +`_IE = 0
                                  let score2`+ response.Trait +`_PDIE = 0
                                  let score2`+ response.Trait +`_Others = 0
                                  // score 3
                                  let score3`+ response.Trait +`_Self = 0
                                  let score3`+ response.Trait +`_PSup = 0
                                  let score3`+ response.Trait +`_P = 0
                                  let score3`+ response.Trait +`_D = 0
                                  let score3`+ response.Trait +`_I = 0
                                  let score3`+ response.Trait +`_E = 0
                                  let score3`+ response.Trait +`_PD = 0
                                  let score3`+ response.Trait +`_IE = 0
                                  let score3`+ response.Trait +`_PDIE = 0
                                  let score3`+ response.Trait +`_Others = 0
                                  // score 4
                                  let score4`+ response.Trait +`_Self = 0
                                  let score4`+ response.Trait +`_PSup = 0
                                  let score4`+ response.Trait +`_P = 0
                                  let score4`+ response.Trait +`_D = 0
                                  let score4`+ response.Trait +`_I = 0
                                  let score4`+ response.Trait +`_E = 0
                                  let score4`+ response.Trait +`_PD = 0
                                  let score4`+ response.Trait +`_IE = 0
                                  let score4`+ response.Trait +`_PDIE = 0
                                  let score4`+ response.Trait +`_Others = 0
                                  // score 5
                                  let score5`+ response.Trait +`_Self = 0
                                  let score5`+ response.Trait +`_PSup = 0
                                  let score5`+ response.Trait +`_P = 0
                                  let score5`+ response.Trait +`_D = 0
                                  let score5`+ response.Trait +`_I = 0
                                  let score5`+ response.Trait +`_E = 0
                                  let score5`+ response.Trait +`_PD = 0
                                  let score5`+ response.Trait +`_IE = 0
                                  let score5`+ response.Trait +`_PDIE = 0
                                  let score5`+ response.Trait +`_Others = 0

                                  // cohort score 1
                                  let cohortScore1`+ response.Trait +`_Self = 0
                                  let cohortScore1`+ response.Trait +`_PSup = 0
                                  let cohortScore1`+ response.Trait +`_P = 0
                                  let cohortScore1`+ response.Trait +`_D = 0
                                  let cohortScore1`+ response.Trait +`_I = 0
                                  let cohortScore1`+ response.Trait +`_E = 0
                                  let cohortScore1`+ response.Trait +`_PD = 0
                                  let cohortScore1`+ response.Trait +`_IE = 0
                                  let cohortScore1`+ response.Trait +`_PDIE = 0
                                  let cohortScore1`+ response.Trait +`_Others = 0
                                  // cohort score 2
                                  let cohortScore2`+ response.Trait +`_Self = 0
                                  let cohortScore2`+ response.Trait +`_PSup = 0
                                  let cohortScore2`+ response.Trait +`_P = 0
                                  let cohortScore2`+ response.Trait +`_D = 0
                                  let cohortScore2`+ response.Trait +`_I = 0
                                  let cohortScore2`+ response.Trait +`_E = 0
                                  let cohortScore2`+ response.Trait +`_PD = 0
                                  let cohortScore2`+ response.Trait +`_IE = 0
                                  let cohortScore2`+ response.Trait +`_PDIE = 0
                                  let cohortScore2`+ response.Trait +`_Others = 0
                                  // cohort score 3
                                  let cohortScore3`+ response.Trait +`_Self = 0
                                  let cohortScore3`+ response.Trait +`_PSup = 0
                                  let cohortScore3`+ response.Trait +`_P = 0
                                  let cohortScore3`+ response.Trait +`_D = 0
                                  let cohortScore3`+ response.Trait +`_I = 0
                                  let cohortScore3`+ response.Trait +`_E = 0
                                  let cohortScore3`+ response.Trait +`_PD = 0
                                  let cohortScore3`+ response.Trait +`_IE = 0
                                  let cohortScore3`+ response.Trait +`_PDIE = 0
                                  let cohortScore3`+ response.Trait +`_Others = 0
                                  // cohort score 4
                                  let cohortScore4`+ response.Trait +`_Self = 0
                                  let cohortScore4`+ response.Trait +`_PSup = 0
                                  let cohortScore4`+ response.Trait +`_P = 0
                                  let cohortScore4`+ response.Trait +`_D = 0
                                  let cohortScore4`+ response.Trait +`_I = 0
                                  let cohortScore4`+ response.Trait +`_E = 0
                                  let cohortScore4`+ response.Trait +`_PD = 0
                                  let cohortScore4`+ response.Trait +`_IE = 0
                                  let cohortScore4`+ response.Trait +`_PDIE = 0
                                  let cohortScore4`+ response.Trait +`_Others = 0
                                  // cohort score 5
                                  let cohortScore5`+ response.Trait +`_Self = 0
                                  let cohortScore5`+ response.Trait +`_PSup = 0
                                  let cohortScore5`+ response.Trait +`_P = 0
                                  let cohortScore5`+ response.Trait +`_D = 0
                                  let cohortScore5`+ response.Trait +`_I = 0
                                  let cohortScore5`+ response.Trait +`_E = 0
                                  let cohortScore5`+ response.Trait +`_PD = 0
                                  let cohortScore5`+ response.Trait +`_IE = 0
                                  let cohortScore5`+ response.Trait +`_PDIE = 0
                                  let cohortScore5`+ response.Trait +`_Others = 0

                                  for (let i=0; i<resHistogram.data.length; i++){
                                    // get the score 1
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score1`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }

                                    // get the score 2
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score2`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }

                                    // get the score 3
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score3`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }

                                    // get the score 4
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score4`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }

                                    // get the score 5
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_PD = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_IE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      score5`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_Others = resHistogram.data[i].qty
                                    }

                                  //put data into array
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_Self.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_Self.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_PSup.push(resHistogram.data[i].qty)
                                    } if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_PSup.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_P.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_P.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_D.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_D.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_I.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_I.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_E.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_E.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_PD.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_PD.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_IE.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_IE.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                      array`+ response.Trait +`_Others.push(resHistogram.data[i].qty)
                                    }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_Others.push(resHistogram.data[i].qty)
                                    }
                                  }
                                  // add numbers in 1 row for ranking 0 to 100%
                                  array`+ response.Trait +`_Self.push(score1`+ response.Trait +`_Self+score2`+ response.Trait +`_Self+score3`+ response.Trait +`_Self+score4`+ response.Trait +`_Self+score5`+ response.Trait +`_Self)
                                  array`+ response.Trait +`_PSup.push(score1`+ response.Trait +`_PSup+score2`+ response.Trait +`_PSup+score3`+ response.Trait +`_PSup+score4`+ response.Trait +`_PSup+score5`+ response.Trait +`_PSup)
                                  array`+ response.Trait +`_P.push(score1`+ response.Trait +`_P+score2`+ response.Trait +`_P+score3`+ response.Trait +`_P+score4`+ response.Trait +`_P+score5`+ response.Trait +`_P)
                                  array`+ response.Trait +`_D.push(score1`+ response.Trait +`_D+score2`+ response.Trait +`_D+score3`+ response.Trait +`_D+score4`+ response.Trait +`_D+score5`+ response.Trait +`_D)
                                  array`+ response.Trait +`_I.push(score1`+ response.Trait +`_I+score2`+ response.Trait +`_I+score3`+ response.Trait +`_I+score4`+ response.Trait +`_I+score5`+ response.Trait +`_I)
                                  array`+ response.Trait +`_E.push(score1`+ response.Trait +`_E+score2`+ response.Trait +`_E+score3`+ response.Trait +`_E+score4`+ response.Trait +`_E+score5`+ response.Trait +`_E)
                                  array`+ response.Trait +`_PD.push(score1`+ response.Trait +`_PD+score2`+ response.Trait +`_PD+score3`+ response.Trait +`_PD+score4`+ response.Trait +`_PD+score5`+ response.Trait +`_PD)
                                  array`+ response.Trait +`_IE.push(score1`+ response.Trait +`_IE+score2`+ response.Trait +`_IE+score3`+ response.Trait +`_IE+score4`+ response.Trait +`_IE+score5`+ response.Trait +`_IE)
                                  array`+ response.Trait +`_PDIE.push(score1`+ response.Trait +`_PDIE+score2`+ response.Trait +`_PDIE+score3`+ response.Trait +`_PDIE+score4`+ response.Trait +`_PDIE+score5`+ response.Trait +`_PDIE)
                                  array`+ response.Trait +`_Others.push(score1`+ response.Trait +`_Others+score2`+ response.Trait +`_Others+score3`+ response.Trait +`_Others+score4`+ response.Trait +`_Others+score5`+ response.Trait +`_Others)
                                  //cohort
                                  arrayCohort`+ response.Trait +`_Self.push(cohortScore1`+ response.Trait +`_Self+cohortScore2`+ response.Trait +`_Self+cohortScore3`+ response.Trait +`_Self+cohortScore4`+ response.Trait +`_Self+cohortScore5`+ response.Trait +`_Self)
                                  arrayCohort`+ response.Trait +`_PSup.push(cohortScore1`+ response.Trait +`_PSup+cohortScore2`+ response.Trait +`_PSup+cohortScore3`+ response.Trait +`_PSup+cohortScore4`+ response.Trait +`_PSup+cohortScore5`+ response.Trait +`_PSup)
                                  arrayCohort`+ response.Trait +`_P.push(cohortScore1`+ response.Trait +`_P+cohortScore2`+ response.Trait +`_P+cohortScore3`+ response.Trait +`_P+cohortScore4`+ response.Trait +`_P+cohortScore5`+ response.Trait +`_P)
                                  arrayCohort`+ response.Trait +`_D.push(cohortScore1`+ response.Trait +`_D+cohortScore2`+ response.Trait +`_D+cohortScore3`+ response.Trait +`_D+cohortScore4`+ response.Trait +`_D+cohortScore5`+ response.Trait +`_D)
                                  arrayCohort`+ response.Trait +`_I.push(cohortScore1`+ response.Trait +`_I+cohortScore2`+ response.Trait +`_I+cohortScore3`+ response.Trait +`_I+cohortScore4`+ response.Trait +`_I+cohortScore5`+ response.Trait +`_I)
                                  arrayCohort`+ response.Trait +`_E.push(cohortScore1`+ response.Trait +`_E+cohortScore2`+ response.Trait +`_E+cohortScore3`+ response.Trait +`_E+cohortScore4`+ response.Trait +`_E+cohortScore5`+ response.Trait +`_E)
                                  arrayCohort`+ response.Trait +`_PD.push(cohortScore1`+ response.Trait +`_PD+cohortScore2`+ response.Trait +`_PD+cohortScore3`+ response.Trait +`_PD+cohortScore4`+ response.Trait +`_PD+cohortScore5`+ response.Trait +`_PD)
                                  arrayCohort`+ response.Trait +`_IE.push(cohortScore1`+ response.Trait +`_IE+cohortScore2`+ response.Trait +`_IE+cohortScore3`+ response.Trait +`_IE+cohortScore4`+ response.Trait +`_IE+cohortScore5`+ response.Trait +`_IE)
                                  arrayCohort`+ response.Trait +`_PDIE.push(cohortScore1`+ response.Trait +`_PDIE+cohortScore2`+ response.Trait +`_PDIE+cohortScore3`+ response.Trait +`_PDIE+cohortScore4`+ response.Trait +`_PDIE+cohortScore5`+ response.Trait +`_PDIE)
                                  arrayCohort`+ response.Trait +`_Others.push(cohortScore1`+ response.Trait +`_Others+cohortScore2`+ response.Trait +`_Others+cohortScore3`+ response.Trait +`_Others+cohortScore4`+ response.Trait +`_Others+cohortScore5`+ response.Trait +`_Others)
                              `
                            })
                          }
                        }
                        // part 5 of Histogram
                        if (data.Template === 'Add-Histogram') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `

                                  // for Rolled-up Total
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                              
                                //section 2 compute average per score
                                  let arrayMain_`+ response.Trait +` = []

                                    let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PD + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                                  
                                    let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PD + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                                  
                                    let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PD + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                                  
                                    let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PD + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                                  
                                    let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PD + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)

                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)
                                  
                                //section 3-8 compute average per score
                                  arraySub_`+ response.Trait +` = []

                                    let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_PD + score1`+ response.Trait +`_I + score1`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                                  
                                    let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_PD + score2`+ response.Trait +`_I + score2`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                                  
                                    let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_PD + score3`+ response.Trait +`_I + score3`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                                  
                                    let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_PD + score4`+ response.Trait +`_I + score4`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                                  
                                    let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_PD + score5`+ response.Trait +`_I + score5`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                                  
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)
                                //rank computed here section 2
                                  let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                //section 2
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                                //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                                 
                                }
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  
                                //section 2 compute average per score
                                  let arrayMain_`+ response.Trait +` = []

                                    let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D)
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                                  
                                    let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D)
                                    arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                                  
                                    let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D)
                                    arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                                  
                                    let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D)
                                    arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                                  
                                    let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D)
                                    arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                                  
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                                //section 3-8 compute average per score
                                  arraySub_`+ response.Trait +` = []

                                    let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_IE + score1`+ response.Trait +`_P + score1`+ response.Trait +`_D)
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                                  
                                    let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_IE + score2`+ response.Trait +`_P + score2`+ response.Trait +`_D)
                                    arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                                  
                                    let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_IE + score3`+ response.Trait +`_P + score3`+ response.Trait +`_D)
                                    arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                                  
                                    let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_IE + score4`+ response.Trait +`_P + score4`+ response.Trait +`_D)
                                    arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                                  
                                    let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_IE + score5`+ response.Trait +`_P + score5`+ response.Trait +`_D)
                                    arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                                  
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                                //rank computed here section 2
                                  let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                //section 2
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                                //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');

                                }
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  //section 2 compute average per score
                                  let arrayMain_`+ response.Trait +` = []

                                    let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_PD)
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                                  
                                    let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_PD)
                                    arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                                  
                                    let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_PD)
                                    arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                                  
                                    let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_PD)
                                    arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                                  
                                    let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_PD)
                                    arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                                  
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                                //section 3-8 compute average per score
                                  arraySub_`+ response.Trait +` = []

                                    let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_IE + score1`+ response.Trait +`_PD)
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                                  
                                    let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_IE + score2`+ response.Trait +`_PD)
                                    arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                                  
                                    let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_IE + score3`+ response.Trait +`_PD)
                                    arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                                  
                                    let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_IE + score4`+ response.Trait +`_PD)
                                    arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                                  
                                    let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_IE + score5`+ response.Trait +`_PD)
                                    arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                                  
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                                //rank computed here section 2
                                  let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                //section 2
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                                //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                                  
                                  }
                                
                                if (isDisplay_PDIE === 1) {
                                  let arrayMain_`+ response.Trait +` = []

                                    let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PDIE)
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                                  
                                    let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PDIE)
                                    arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                                  
                                    let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PDIE)
                                    arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                                  
                                    let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PDIE)
                                    arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                                  
                                    let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PDIE)
                                    arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                                  
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                                //section 3-8 compute average per score
                                  arraySub_`+ response.Trait +` = []

                                    let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_PDIE)
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                                  
                                    let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_PDIE)
                                    arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                                  
                                    let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_PDIE)
                                    arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                                  
                                    let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_PDIE)
                                    arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                                  
                                    let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_PDIE)
                                    arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                                  
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                                //rank computed here section 2
                                  let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                //section 2
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                                //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                                 
                                  }

                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                                  let arrayMain_`+ response.Trait +` = []

                                    let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                                  
                                    let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                                  
                                    let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                                  
                                    let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                                  
                                    let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                                    arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                                  
                                    arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                                //section 3-8 compute average per score
                                  arraySub_`+ response.Trait +` = []

                                    let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_P + score1`+ response.Trait +`_D + score1`+ response.Trait +`_I + score1`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                                  
                                    let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_P + score2`+ response.Trait +`_D + score2`+ response.Trait +`_I + score2`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                                  
                                    let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_P + score3`+ response.Trait +`_D + score3`+ response.Trait +`_I + score3`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                                  
                                    let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_P + score4`+ response.Trait +`_D + score4`+ response.Trait +`_I + score4`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                                  
                                    let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_P + score5`+ response.Trait +`_D + score5`+ response.Trait +`_I + score5`+ response.Trait +`_E)
                                    arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                                  
                                    arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                                //rank computed here section 2
                                  let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                                //section 2
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                                //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                                //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');

                                }
                              `
                            })
                          }
                        }
                        // part 5.1 of Histogram
                        if (data.Template === 'Add-Histogram') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                                //self
                                  let histogramScore1_`+ response.Trait +`_Self = rankRange(cohortScore1`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore2_`+ response.Trait +`_Self = rankRange(cohortScore2`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore3_`+ response.Trait +`_Self = rankRange(cohortScore3`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore4_`+ response.Trait +`_Self = rankRange(cohortScore4`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore5_`+ response.Trait +`_Self = rankRange(cohortScore5`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-Self").css('height', histogramScore1_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score2-`+ response.Trait +`-Self").css('height', histogramScore2_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score3-`+ response.Trait +`-Self").css('height', histogramScore3_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score4-`+ response.Trait +`-Self").css('height', histogramScore4_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score5-`+ response.Trait +`-Self").css('height', histogramScore5_`+ response.Trait +`_Self +'%');
                                  //self section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_Self = rankRange(score1`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                                  let histogramScore2_`+ response.Trait +`_sub_Self = rankRange(score2`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                                  let histogramScore3_`+ response.Trait +`_sub_Self = rankRange(score3`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                                  let histogramScore4_`+ response.Trait +`_sub_Self = rankRange(score4`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                                  let histogramScore5_`+ response.Trait +`_sub_Self = rankRange(score5`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Self").css('height', histogramScore1_`+ response.Trait +`_sub_Self +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Self").css('height', histogramScore2_`+ response.Trait +`_sub_Self +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Self").css('height', histogramScore3_`+ response.Trait +`_sub_Self +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Self").css('height', histogramScore4_`+ response.Trait +`_sub_Self +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Self").css('height', histogramScore5_`+ response.Trait +`_sub_Self +'%');

                                  //psup section 2
                                  let histogramScore1_`+ response.Trait +`_PSup = rankRange(cohortScore1`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore2_`+ response.Trait +`_PSup = rankRange(cohortScore2`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore3_`+ response.Trait +`_PSup = rankRange(cohortScore3`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore4_`+ response.Trait +`_PSup = rankRange(cohortScore4`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore5_`+ response.Trait +`_PSup = rankRange(cohortScore5`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-Psup").css('height', histogramScore1_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score2-`+ response.Trait +`-Psup").css('height', histogramScore2_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score3-`+ response.Trait +`-Psup").css('height', histogramScore3_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score4-`+ response.Trait +`-Psup").css('height', histogramScore4_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score5-`+ response.Trait +`-Psup").css('height', histogramScore5_`+ response.Trait +`_PSup +'%');
                                  //psup section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_PSup = rankRange(score1`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                                  let histogramScore2_`+ response.Trait +`_sub_PSup = rankRange(score2`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                                  let histogramScore3_`+ response.Trait +`_sub_PSup = rankRange(score3`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                                  let histogramScore4_`+ response.Trait +`_sub_PSup = rankRange(score4`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                                  let histogramScore5_`+ response.Trait +`_sub_PSup = rankRange(score5`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Psup").css('height', histogramScore1_`+ response.Trait +`_sub_PSup +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Psup").css('height', histogramScore2_`+ response.Trait +`_sub_PSup +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Psup").css('height', histogramScore3_`+ response.Trait +`_sub_PSup +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Psup").css('height', histogramScore4_`+ response.Trait +`_sub_PSup +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Psup").css('height', histogramScore5_`+ response.Trait +`_sub_PSup +'%');

                                  //peers section 2
                                  let histogramScore1_`+ response.Trait +`_P = rankRange(cohortScore1`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore2_`+ response.Trait +`_P = rankRange(cohortScore2`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore3_`+ response.Trait +`_P = rankRange(cohortScore3`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore4_`+ response.Trait +`_P = rankRange(cohortScore4`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore5_`+ response.Trait +`_P = rankRange(cohortScore5`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-P").css('height', histogramScore1_`+ response.Trait +`_P +'%');
                                  $(".histogram-score2-`+ response.Trait +`-P").css('height', histogramScore2_`+ response.Trait +`_P +'%');
                                  $(".histogram-score3-`+ response.Trait +`-P").css('height', histogramScore3_`+ response.Trait +`_P +'%');
                                  $(".histogram-score4-`+ response.Trait +`-P").css('height', histogramScore4_`+ response.Trait +`_P +'%');
                                  $(".histogram-score5-`+ response.Trait +`-P").css('height', histogramScore5_`+ response.Trait +`_P +'%');
                                  //peers section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_P = rankRange(score1`+ response.Trait +`_P, array`+ response.Trait +`_P)
                                  let histogramScore2_`+ response.Trait +`_sub_P = rankRange(score2`+ response.Trait +`_P, array`+ response.Trait +`_P)
                                  let histogramScore3_`+ response.Trait +`_sub_P = rankRange(score3`+ response.Trait +`_P, array`+ response.Trait +`_P)
                                  let histogramScore4_`+ response.Trait +`_sub_P = rankRange(score4`+ response.Trait +`_P, array`+ response.Trait +`_P)
                                  let histogramScore5_`+ response.Trait +`_sub_P = rankRange(score5`+ response.Trait +`_P, array`+ response.Trait +`_P)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-P").css('height', histogramScore1_`+ response.Trait +`_sub_P +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-P").css('height', histogramScore2_`+ response.Trait +`_sub_P +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-P").css('height', histogramScore3_`+ response.Trait +`_sub_P +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-P").css('height', histogramScore4_`+ response.Trait +`_sub_P +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-P").css('height', histogramScore5_`+ response.Trait +`_sub_P +'%');

                                  //direct reports section 2
                                  let histogramScore1_`+ response.Trait +`_D = rankRange(cohortScore1`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore2_`+ response.Trait +`_D = rankRange(cohortScore2`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore3_`+ response.Trait +`_D = rankRange(cohortScore3`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore4_`+ response.Trait +`_D = rankRange(cohortScore4`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore5_`+ response.Trait +`_D = rankRange(cohortScore5`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-D").css('height', histogramScore1_`+ response.Trait +`_D +'%');
                                  $(".histogram-score2-`+ response.Trait +`-D").css('height', histogramScore2_`+ response.Trait +`_D +'%');
                                  $(".histogram-score3-`+ response.Trait +`-D").css('height', histogramScore3_`+ response.Trait +`_D +'%');
                                  $(".histogram-score4-`+ response.Trait +`-D").css('height', histogramScore4_`+ response.Trait +`_D +'%');
                                  $(".histogram-score5-`+ response.Trait +`-D").css('height', histogramScore5_`+ response.Trait +`_D +'%');
                                  //direct reports section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_D = rankRange(score1`+ response.Trait +`_D, array`+ response.Trait +`_D)
                                  let histogramScore2_`+ response.Trait +`_sub_D = rankRange(score2`+ response.Trait +`_D, array`+ response.Trait +`_D)
                                  let histogramScore3_`+ response.Trait +`_sub_D = rankRange(score3`+ response.Trait +`_D, array`+ response.Trait +`_D)
                                  let histogramScore4_`+ response.Trait +`_sub_D = rankRange(score4`+ response.Trait +`_D, array`+ response.Trait +`_D)
                                  let histogramScore5_`+ response.Trait +`_sub_D = rankRange(score5`+ response.Trait +`_D, array`+ response.Trait +`_D)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-D").css('height', histogramScore1_`+ response.Trait +`_sub_D +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-D").css('height', histogramScore2_`+ response.Trait +`_sub_D +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-D").css('height', histogramScore3_`+ response.Trait +`_sub_D +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-D").css('height', histogramScore4_`+ response.Trait +`_sub_D +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-D").css('height', histogramScore5_`+ response.Trait +`_sub_D +'%');
                                  
                                  //internal stakeholders section 2
                                  let histogramScore1_`+ response.Trait +`_I = rankRange(cohortScore1`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore2_`+ response.Trait +`_I = rankRange(cohortScore2`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore3_`+ response.Trait +`_I = rankRange(cohortScore3`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore4_`+ response.Trait +`_I = rankRange(cohortScore4`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore5_`+ response.Trait +`_I = rankRange(cohortScore5`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-I").css('height', histogramScore1_`+ response.Trait +`_I +'%');
                                  $(".histogram-score2-`+ response.Trait +`-I").css('height', histogramScore2_`+ response.Trait +`_I +'%');
                                  $(".histogram-score3-`+ response.Trait +`-I").css('height', histogramScore3_`+ response.Trait +`_I +'%');
                                  $(".histogram-score4-`+ response.Trait +`-I").css('height', histogramScore4_`+ response.Trait +`_I +'%');
                                  $(".histogram-score5-`+ response.Trait +`-I").css('height', histogramScore5_`+ response.Trait +`_I +'%');
                                  //internal stakeholders section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_I = rankRange(score1`+ response.Trait +`_I, array`+ response.Trait +`_I)
                                  let histogramScore2_`+ response.Trait +`_sub_I = rankRange(score2`+ response.Trait +`_I, array`+ response.Trait +`_I)
                                  let histogramScore3_`+ response.Trait +`_sub_I = rankRange(score3`+ response.Trait +`_I, array`+ response.Trait +`_I)
                                  let histogramScore4_`+ response.Trait +`_sub_I = rankRange(score4`+ response.Trait +`_I, array`+ response.Trait +`_I)
                                  let histogramScore5_`+ response.Trait +`_sub_I = rankRange(score5`+ response.Trait +`_I, array`+ response.Trait +`_I)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-I").css('height', histogramScore1_`+ response.Trait +`_sub_I +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-I").css('height', histogramScore2_`+ response.Trait +`_sub_I +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-I").css('height', histogramScore3_`+ response.Trait +`_sub_I +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-I").css('height', histogramScore4_`+ response.Trait +`_sub_I +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-I").css('height', histogramScore5_`+ response.Trait +`_sub_I +'%');

                                  //external stakeholders section 2
                                  let histogramScore1_`+ response.Trait +`_E = rankRange(cohortScore1`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore2_`+ response.Trait +`_E = rankRange(cohortScore2`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore3_`+ response.Trait +`_E = rankRange(cohortScore3`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore4_`+ response.Trait +`_E = rankRange(cohortScore4`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore5_`+ response.Trait +`_E = rankRange(cohortScore5`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-E").css('height', histogramScore1_`+ response.Trait +`_E +'%');
                                  $(".histogram-score2-`+ response.Trait +`-E").css('height', histogramScore2_`+ response.Trait +`_E +'%');
                                  $(".histogram-score3-`+ response.Trait +`-E").css('height', histogramScore3_`+ response.Trait +`_E +'%');
                                  $(".histogram-score4-`+ response.Trait +`-E").css('height', histogramScore4_`+ response.Trait +`_E +'%');
                                  $(".histogram-score5-`+ response.Trait +`-E").css('height', histogramScore5_`+ response.Trait +`_E +'%');
                                  //external stakeholders section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_E = rankRange(score1`+ response.Trait +`_E, array`+ response.Trait +`_E)
                                  let histogramScore2_`+ response.Trait +`_sub_E = rankRange(score2`+ response.Trait +`_E, array`+ response.Trait +`_E)
                                  let histogramScore3_`+ response.Trait +`_sub_E = rankRange(score3`+ response.Trait +`_E, array`+ response.Trait +`_E)
                                  let histogramScore4_`+ response.Trait +`_sub_E = rankRange(score4`+ response.Trait +`_E, array`+ response.Trait +`_E)
                                  let histogramScore5_`+ response.Trait +`_sub_E = rankRange(score5`+ response.Trait +`_E, array`+ response.Trait +`_E)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-E").css('height', histogramScore1_`+ response.Trait +`_sub_E +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-E").css('height', histogramScore2_`+ response.Trait +`_sub_E +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-E").css('height', histogramScore3_`+ response.Trait +`_sub_E +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-E").css('height', histogramScore4_`+ response.Trait +`_sub_E +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-E").css('height', histogramScore5_`+ response.Trait +`_sub_E +'%');

                                 
                                  //peers/direct section 2
                                  let histogramScore1_`+ response.Trait +`_PD = rankRange(cohortScore1`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                  let histogramScore2_`+ response.Trait +`_PD = rankRange(cohortScore2`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                  let histogramScore3_`+ response.Trait +`_PD = rankRange(cohortScore3`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                  let histogramScore4_`+ response.Trait +`_PD = rankRange(cohortScore4`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                  let histogramScore5_`+ response.Trait +`_PD = rankRange(cohortScore5`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-PD").css('height', histogramScore1_`+ response.Trait +`_PD +'%');
                                  $(".histogram-score2-`+ response.Trait +`-PD").css('height', histogramScore2_`+ response.Trait +`_PD +'%');
                                  $(".histogram-score3-`+ response.Trait +`-PD").css('height', histogramScore3_`+ response.Trait +`_PD +'%');
                                  $(".histogram-score4-`+ response.Trait +`-PD").css('height', histogramScore4_`+ response.Trait +`_PD +'%');
                                  $(".histogram-score5-`+ response.Trait +`-PD").css('height', histogramScore5_`+ response.Trait +`_PD +'%');
                                  //peers/direct section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_PD = rankRange(score1`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                                  let histogramScore2_`+ response.Trait +`_sub_PD = rankRange(score2`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                                  let histogramScore3_`+ response.Trait +`_sub_PD = rankRange(score3`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                                  let histogramScore4_`+ response.Trait +`_sub_PD = rankRange(score4`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                                  let histogramScore5_`+ response.Trait +`_sub_PD = rankRange(score5`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-PD").css('height', histogramScore1_`+ response.Trait +`_sub_PD +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-PD").css('height', histogramScore2_`+ response.Trait +`_sub_PD +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-PD").css('height', histogramScore3_`+ response.Trait +`_sub_PD +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-PD").css('height', histogramScore4_`+ response.Trait +`_sub_PD +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-PD").css('height', histogramScore5_`+ response.Trait +`_sub_PD +'%');

                                  //internal/external section 2
                                  let histogramScore1_`+ response.Trait +`_IE = rankRange(cohortScore1`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                  let histogramScore2_`+ response.Trait +`_IE = rankRange(cohortScore2`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                  let histogramScore3_`+ response.Trait +`_IE = rankRange(cohortScore3`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                  let histogramScore4_`+ response.Trait +`_IE = rankRange(cohortScore4`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                  let histogramScore5_`+ response.Trait +`_IE = rankRange(cohortScore5`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-IE").css('height', histogramScore1_`+ response.Trait +`_IE +'%');
                                  $(".histogram-score2-`+ response.Trait +`-IE").css('height', histogramScore2_`+ response.Trait +`_IE +'%');
                                  $(".histogram-score3-`+ response.Trait +`-IE").css('height', histogramScore3_`+ response.Trait +`_IE +'%');
                                  $(".histogram-score4-`+ response.Trait +`-IE").css('height', histogramScore4_`+ response.Trait +`_IE +'%');
                                  $(".histogram-score5-`+ response.Trait +`-IE").css('height', histogramScore5_`+ response.Trait +`_IE +'%');
                                  //internal/external section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_IE = rankRange(score1`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                                  let histogramScore2_`+ response.Trait +`_sub_IE = rankRange(score2`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                                  let histogramScore3_`+ response.Trait +`_sub_IE = rankRange(score3`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                                  let histogramScore4_`+ response.Trait +`_sub_IE = rankRange(score4`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                                  let histogramScore5_`+ response.Trait +`_sub_IE = rankRange(score5`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-IE").css('height', histogramScore1_`+ response.Trait +`_sub_IE +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-IE").css('height', histogramScore2_`+ response.Trait +`_sub_IE +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-IE").css('height', histogramScore3_`+ response.Trait +`_sub_IE +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-IE").css('height', histogramScore4_`+ response.Trait +`_sub_IE +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-IE").css('height', histogramScore5_`+ response.Trait +`_sub_IE +'%');

                                  //peers/direct/internal/external section 2
                                  let histogramScore1_`+ response.Trait +`_PDIE = rankRange(cohortScore1`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                  let histogramScore2_`+ response.Trait +`_PDIE = rankRange(cohortScore2`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                  let histogramScore3_`+ response.Trait +`_PDIE = rankRange(cohortScore3`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                  let histogramScore4_`+ response.Trait +`_PDIE = rankRange(cohortScore4`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                  let histogramScore5_`+ response.Trait +`_PDIE = rankRange(cohortScore5`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                  //section 2
                                  $(".histogram-score1-`+ response.Trait +`-PDIE").css('height', histogramScore1_`+ response.Trait +`_PDIE +'%');
                                  $(".histogram-score2-`+ response.Trait +`-PDIE").css('height', histogramScore2_`+ response.Trait +`_PDIE +'%');
                                  $(".histogram-score3-`+ response.Trait +`-PDIE").css('height', histogramScore3_`+ response.Trait +`_PDIE +'%');
                                  $(".histogram-score4-`+ response.Trait +`-PDIE").css('height', histogramScore4_`+ response.Trait +`_PDIE +'%');
                                  $(".histogram-score5-`+ response.Trait +`-PDIE").css('height', histogramScore5_`+ response.Trait +`_PDIE +'%');
                                  //peers/direct/internal/external section 3-8
                                  let histogramScore1_`+ response.Trait +`_sub_PDIE = rankRange(score1`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                                  let histogramScore2_`+ response.Trait +`_sub_PDIE = rankRange(score2`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                                  let histogramScore3_`+ response.Trait +`_sub_PDIE = rankRange(score3`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                                  let histogramScore4_`+ response.Trait +`_sub_PDIE = rankRange(score4`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                                  let histogramScore5_`+ response.Trait +`_sub_PDIE = rankRange(score5`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-PDIE").css('height', histogramScore1_`+ response.Trait +`_sub_PDIE +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-PDIE").css('height', histogramScore2_`+ response.Trait +`_sub_PDIE +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-PDIE").css('height', histogramScore3_`+ response.Trait +`_sub_PDIE +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-PDIE").css('height', histogramScore4_`+ response.Trait +`_sub_PDIE +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-PDIE").css('height', histogramScore5_`+ response.Trait +`_sub_PDIE +'%');

                                  //All Nominees section3-8
                                  let histogramScore1_`+ response.Trait +`_sub_Others = rankRange(score1`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                                  let histogramScore2_`+ response.Trait +`_sub_Others = rankRange(score2`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                                  let histogramScore3_`+ response.Trait +`_sub_Others = rankRange(score3`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                                  let histogramScore4_`+ response.Trait +`_sub_Others = rankRange(score4`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                                  let histogramScore5_`+ response.Trait +`_sub_Others = rankRange(score5`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-sub-Others").css('height', histogramScore1_`+ response.Trait +`_sub_Others +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Others").css('height', histogramScore2_`+ response.Trait +`_sub_Others +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Others").css('height', histogramScore3_`+ response.Trait +`_sub_Others +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Others").css('height', histogramScore4_`+ response.Trait +`_sub_Others +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Others").css('height', histogramScore5_`+ response.Trait +`_sub_Others +'%');
                                 
                              `
                            })
                          }
                        }
                        // part 6 of Histogram COHORT DATA 
                        if (data.Template === 'Add-Histogram') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `

                                // for Rolled-up Total Cohort
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  //section 3-8 compute average per COHORT score
                                  cohortArraySub_`+ response.Trait +` = []

                                  let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PD + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                                
                                  let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PD + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                                
                                  let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PD + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                                
                                  let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PD + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                                
                                  let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PD + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                                
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                              
                                  //rank computed here section3-8 COHORT
                                  let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                                
                                }
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  //section 3-8 compute average per COHORT score
                                  cohortArraySub_`+ response.Trait +` = []

                                  let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                                
                                  let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                                
                                  let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                                
                                  let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                                
                                  let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                                
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                              
                                  //rank computed here section3-8
                                  let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');

                                }
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  //section 3-8 compute average per COHORT score
                                  cohortArraySub_`+ response.Trait +` = []

                                  let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_PD)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                                
                                  let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_PD)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                                
                                  let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_PD)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                                
                                  let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_PD)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                                
                                  let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_PD)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                                
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                              
                                  //rank computed here section3-8 COHORT
                                  let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                                
                                }
                                
                                if (isDisplay_PDIE === 1) {
                                  // section 3-8 compute average per COHORT score
                                  cohortArraySub_`+ response.Trait +` = []

                                  let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PDIE)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                                
                                  let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PDIE)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                                
                                  let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PDIE)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                                
                                  let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PDIE)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                                
                                  let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PDIE)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                                
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                              
                                  // rank computed here section3-8 COHORT
                                  let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                                
                                }

                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  // section 3-8 compute average per COHORT score
                                  cohortArraySub_`+ response.Trait +` = []

                                  let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                                
                                  let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                                
                                  let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                                
                                  let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                                
                                  let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                                
                                  cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                              
                                  // rank computed here section3-8 COHORT
                                  let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                                  //section3-8
                                  $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                                  $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');

                                }
                              `
                            })
                          }
                        }
                        // part 6.1 of Histogram COHORT DATA 
                        if (data.Template === 'Add-Histogram') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `

                                //self section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                let histogramScore2_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                let histogramScore3_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                let histogramScore4_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                let histogramScore5_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ response.Trait +`_cohortSub_Self +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ response.Trait +`_cohortSub_Self +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ response.Trait +`_cohortSub_Self +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ response.Trait +`_cohortSub_Self +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ response.Trait +`_cohortSub_Self +'%');

                                //psup section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                let histogramScore2_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                let histogramScore3_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                let histogramScore4_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                let histogramScore5_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                //section3-8 
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PSup +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PSup +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PSup +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PSup +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PSup +'%');

                                //peers section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore1`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                let histogramScore2_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore2`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                let histogramScore3_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore3`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                let histogramScore4_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore4`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                let histogramScore5_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore5`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-P").css('height', histogramScore1_`+ response.Trait +`_cohortSub_P +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-P").css('height', histogramScore2_`+ response.Trait +`_cohortSub_P +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-P").css('height', histogramScore3_`+ response.Trait +`_cohortSub_P +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-P").css('height', histogramScore4_`+ response.Trait +`_cohortSub_P +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-P").css('height', histogramScore5_`+ response.Trait +`_cohortSub_P +'%');

                                //direct reports section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore1`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                let histogramScore2_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore2`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                let histogramScore3_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore3`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                let histogramScore4_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore4`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                let histogramScore5_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore5`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-D").css('height', histogramScore1_`+ response.Trait +`_cohortSub_D +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-D").css('height', histogramScore2_`+ response.Trait +`_cohortSub_D +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-D").css('height', histogramScore3_`+ response.Trait +`_cohortSub_D +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-D").css('height', histogramScore4_`+ response.Trait +`_cohortSub_D +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-D").css('height', histogramScore5_`+ response.Trait +`_cohortSub_D +'%');

                                //internal stakeholders section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore1`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                let histogramScore2_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore2`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                let histogramScore3_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore3`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                let histogramScore4_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore4`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                let histogramScore5_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore5`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-I").css('height', histogramScore1_`+ response.Trait +`_cohortSub_I +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-I").css('height', histogramScore2_`+ response.Trait +`_cohortSub_I +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-I").css('height', histogramScore3_`+ response.Trait +`_cohortSub_I +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-I").css('height', histogramScore4_`+ response.Trait +`_cohortSub_I +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-I").css('height', histogramScore5_`+ response.Trait +`_cohortSub_I +'%');

                                //external stakeholders section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore1`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                let histogramScore2_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore2`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                let histogramScore3_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore3`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                let histogramScore4_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore4`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                let histogramScore5_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore5`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-E").css('height', histogramScore1_`+ response.Trait +`_cohortSub_E +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-E").css('height', histogramScore2_`+ response.Trait +`_cohortSub_E +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-E").css('height', histogramScore3_`+ response.Trait +`_cohortSub_E +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-E").css('height', histogramScore4_`+ response.Trait +`_cohortSub_E +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-E").css('height', histogramScore5_`+ response.Trait +`_cohortSub_E +'%');

                                //peers/direct section 3-8 cohort
                                let histogramScore1_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                let histogramScore2_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                let histogramScore3_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                let histogramScore4_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                let histogramScore5_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PD +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PD +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PD +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PD +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PD +'%');

                                //internal/external section 3-8
                                let histogramScore1_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                let histogramScore2_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                let histogramScore3_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                let histogramScore4_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                let histogramScore5_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ response.Trait +`_cohortSub_IE +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ response.Trait +`_cohortSub_IE +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ response.Trait +`_cohortSub_IE +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ response.Trait +`_cohortSub_IE +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ response.Trait +`_cohortSub_IE +'%');

                                //peers/direct/internal/external section 3-8
                                let histogramScore1_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                let histogramScore2_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                let histogramScore3_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                let histogramScore4_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                let histogramScore5_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                                //section3-8
                                $(".histogram-score1-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PDIE +'%');
                                $(".histogram-score2-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PDIE +'%');
                                $(".histogram-score3-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PDIE +'%');
                                $(".histogram-score4-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PDIE +'%');
                                $(".histogram-score5-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PDIE +'%');



                              `
                            })
                          }
                        }
                      }
                      output += `
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                    `
                  }
                  output += `
                  })
                  .catch((err) => {
                    console.log(err)
                  })
                  `
                }
              }
              // for big 5 coach report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Bar-Chart-Coach-SuperTrait') { 
                  output += `
                  axios
                    .post(
                      '${url}/b5-coach-report-data',
                      {
                        "coach_id": coach_id,
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id,
                        "stream_id": stream_id,
                        "group_id": group_id
                      },
                      config                                            
                    )
                    .then((res) => {
                      console.log(res.data)
                    `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Bar-Chart-Coach-SuperTrait') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            output += `
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                data: {
                                  labels: [
                            `
                                  res.Elements.forEach((response) => {
                                    output += `
                                      "`+response+`",
                                    `
                                  })
                            output += `
                                  ],
                                  datasets: [
                            `
                                    output += `
                                      { 
                                        type: 'bar',
                                        backgroundColor: [
                                          `
                                            res.Color.forEach((response) => {
                                              output += `
                                                "#`+response+`",
                                              `
                                            })
                                          output += `
                                        ],
                                        data: [ 
                                          res.data[0].cohort_ER, res.data[0].cohort_E, res.data[0].cohort_O, res.data[0].cohort_A, res.data[0].cohort_C
                                        ],
                                        barPercentage: 0.5
                                      },
                                    `
                            output+=`
                                  ],
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        legend : false
                                    },
                                    scales: {
                                      x: {
                                        title: {
                                            display: true,
                                            text: 'Supertrait Main Score',
                                            color: '#000',
                                            font: {
                                                family: 'Open Sans',
                                                size: 20,
                                                weight: 'bold',
                                            }
                                        },
                                        position : "top",
                                    },
                                      y: {
                                          min: 1,
                                          max: 5,
                                          ticks: {
                                              stepSize: 1,
                                          }
                                      }
                                    }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Bar-Chart-Coach-Subtrait') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                data: {
                                  labels: [
                            `
                                  res.Subtrait.forEach((response) => {
                                    output += `
                                      "`+response+`",
                                    `
                                  })
                            output += `
                                  ],
                                  datasets: [
                            `
                                    output += `
                                      {
                                        type: 'line',
                                        backgroundColor: "#000",
                                        borderColor: "#000",
                                        data: [
                                          res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER,
                                          res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E,
                                          res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O,
                                          res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A,
                                          res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C
                                        ],
                                      },
                                      { 
                                        type: 'bar',
                                        backgroundColor: [
                                          `
                                            res.Color.forEach((response) => {
                                              output += `
                                                "`+response+`",
                                              `
                                            })
                                          output += `
                                        ],
                                        data: [
                                          res.data[0].cohort_ER1, res.data[0].cohort_ER2, res.data[0].cohort_ER3, res.data[0].cohort_ER4, res.data[0].cohort_ER5, res.data[0].cohort_ER6, 5.5,
                                          res.data[0].cohort_E1, res.data[0].cohort_E2, res.data[0].cohort_E3, res.data[0].cohort_E4, res.data[0].cohort_E5, res.data[0].cohort_E6, 5.5,
                                          res.data[0].cohort_O1, res.data[0].cohort_O2, res.data[0].cohort_O3, res.data[0].cohort_O4, res.data[0].cohort_O5, res.data[0].cohort_O6, 5.5,
                                          res.data[0].cohort_A1, res.data[0].cohort_A2, res.data[0].cohort_A3, res.data[0].cohort_A4, res.data[0].cohort_A5, res.data[0].cohort_A6, 5.5,
                                          res.data[0].cohort_C1, res.data[0].cohort_C2, res.data[0].cohort_C3, res.data[0].cohort_C4, res.data[0].cohort_C5, res.data[0].cohort_C6, 5.5
                                        ], 
                                        barPercentage: 0.5
                                      },
                                    `
                            output+=`
                                  ],
                                },
                                options: {
                                  responsive: true,
                                  plugins: {
                                      legend : false
                                  },
                                  scales: {
                                      y: {
                                          min: 1,
                                          max: 5.5,
                                          ticks: {
                                              stepSize: 1,
                                          }
                                      },
                                      x: {
                                        ticks: {
                                            autoSkip: false,
                                            maxRotation: 90,
                                            minRotation: 90
                                        }
                                      }
                                  },
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Line-Chart-Sub-ER') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
      
                            output += `
                              if(res.data.length >= 6) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.SixPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                              output += `
                              }
                              else if(res.data.length == 5) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FivePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 4) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FourPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 3) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.ThreePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 2) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.TwoPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 1) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.OnePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_ER = (res.data[`+index+`].percentile_ER <= 5) ? 5 : (res.data[`+index+`].percentile_ER >= 95) ? 95 : res.data[`+index+`].percentile_ER, 
                                                      res.data[`+index+`].percentile_ER1 = (res.data[`+index+`].percentile_ER1 <= 5) ? 5 : (res.data[`+index+`].percentile_ER1 >= 95) ? 95 : res.data[`+index+`].percentile_ER1, 
                                                      res.data[`+index+`].percentile_ER2 = (res.data[`+index+`].percentile_ER2 <= 5) ? 5 : (res.data[`+index+`].percentile_ER2 >= 95) ? 95 : res.data[`+index+`].percentile_ER2, 
                                                      res.data[`+index+`].percentile_ER3 = (res.data[`+index+`].percentile_ER3 <= 5) ? 5 : (res.data[`+index+`].percentile_ER3 >= 95) ? 95 : res.data[`+index+`].percentile_ER3, 
                                                      res.data[`+index+`].percentile_ER4 = (res.data[`+index+`].percentile_ER4 <= 5) ? 5 : (res.data[`+index+`].percentile_ER4 >= 95) ? 95 : res.data[`+index+`].percentile_ER4, 
                                                      res.data[`+index+`].percentile_ER5 = (res.data[`+index+`].percentile_ER5 <= 5) ? 5 : (res.data[`+index+`].percentile_ER5 >= 95) ? 95 : res.data[`+index+`].percentile_ER5, 
                                                      res.data[`+index+`].percentile_ER6 = (res.data[`+index+`].percentile_ER6 <= 5) ? 5 : (res.data[`+index+`].percentile_ER6 >= 95) ? 95 : res.data[`+index+`].percentile_ER6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                            `
                          }
                        }
                        if (data.Template === 'Line-Chart-Sub-E') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
      
                            output += `
                              if(res.data.length >= 6) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.SixPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                              output += `
                              }
                              else if(res.data.length == 5) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FivePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 4) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FourPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 3) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.ThreePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 2) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.TwoPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 1) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.OnePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_E = (res.data[`+index+`].percentile_E <= 5) ? 5 : (res.data[`+index+`].percentile_E >= 95) ? 95 : res.data[`+index+`].percentile_E, 
                                                      res.data[`+index+`].percentile_E1 = (res.data[`+index+`].percentile_E1 <= 5) ? 5 : (res.data[`+index+`].percentile_E1 >= 95) ? 95 : res.data[`+index+`].percentile_E1, 
                                                      res.data[`+index+`].percentile_E2 = (res.data[`+index+`].percentile_E2 <= 5) ? 5 : (res.data[`+index+`].percentile_E2 >= 95) ? 95 : res.data[`+index+`].percentile_E2, 
                                                      res.data[`+index+`].percentile_E3 = (res.data[`+index+`].percentile_E3 <= 5) ? 5 : (res.data[`+index+`].percentile_E3 >= 95) ? 95 : res.data[`+index+`].percentile_E3, 
                                                      res.data[`+index+`].percentile_E4 = (res.data[`+index+`].percentile_E4 <= 5) ? 5 : (res.data[`+index+`].percentile_E4 >= 95) ? 95 : res.data[`+index+`].percentile_E4, 
                                                      res.data[`+index+`].percentile_E5 = (res.data[`+index+`].percentile_E5 <= 5) ? 5 : (res.data[`+index+`].percentile_E5 >= 95) ? 95 : res.data[`+index+`].percentile_E5, 
                                                      res.data[`+index+`].percentile_E6 = (res.data[`+index+`].percentile_E6 <= 5) ? 5 : (res.data[`+index+`].percentile_E6 >= 95) ? 95 : res.data[`+index+`].percentile_E6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                            `
                          }
                        }
                        if (data.Template === 'Line-Chart-Sub-O') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
      
                            output += `
                              if(res.data.length >= 6) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.SixPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                              output += `
                              }
                              else if(res.data.length == 5) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FivePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 4) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FourPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 3) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.ThreePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 2) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.TwoPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 1) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.OnePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_O = (res.data[`+index+`].percentile_O <= 5) ? 5 : (res.data[`+index+`].percentile_O >= 95) ? 95 : res.data[`+index+`].percentile_O, 
                                                      res.data[`+index+`].percentile_O1 = (res.data[`+index+`].percentile_O1 <= 5) ? 5 : (res.data[`+index+`].percentile_O1 >= 95) ? 95 : res.data[`+index+`].percentile_O1, 
                                                      res.data[`+index+`].percentile_O2 = (res.data[`+index+`].percentile_O2 <= 5) ? 5 : (res.data[`+index+`].percentile_O2 >= 95) ? 95 : res.data[`+index+`].percentile_O2, 
                                                      res.data[`+index+`].percentile_O3 = (res.data[`+index+`].percentile_O3 <= 5) ? 5 : (res.data[`+index+`].percentile_O3 >= 95) ? 95 : res.data[`+index+`].percentile_O3, 
                                                      res.data[`+index+`].percentile_O4 = (res.data[`+index+`].percentile_O4 <= 5) ? 5 : (res.data[`+index+`].percentile_O4 >= 95) ? 95 : res.data[`+index+`].percentile_O4, 
                                                      res.data[`+index+`].percentile_O5 = (res.data[`+index+`].percentile_O5 <= 5) ? 5 : (res.data[`+index+`].percentile_O5 >= 95) ? 95 : res.data[`+index+`].percentile_O5, 
                                                      res.data[`+index+`].percentile_O6 = (res.data[`+index+`].percentile_O6 <= 5) ? 5 : (res.data[`+index+`].percentile_O6 >= 95) ? 95 : res.data[`+index+`].percentile_O6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                            `
                          }
                        }
                        if (data.Template === 'Line-Chart-Sub-A') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
      
                            output += `
                              if(res.data.length >= 6) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.SixPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                              output += `
                              }
                              else if(res.data.length == 5) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FivePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 4) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FourPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 3) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.ThreePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 2) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.TwoPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 1) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.OnePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_A = (res.data[`+index+`].percentile_A <= 5) ? 5 : (res.data[`+index+`].percentile_A >= 95) ? 95 : res.data[`+index+`].percentile_A, 
                                                      res.data[`+index+`].percentile_A1 = (res.data[`+index+`].percentile_A1 <= 5) ? 5 : (res.data[`+index+`].percentile_A1 >= 95) ? 95 : res.data[`+index+`].percentile_A1, 
                                                      res.data[`+index+`].percentile_A2 = (res.data[`+index+`].percentile_A2 <= 5) ? 5 : (res.data[`+index+`].percentile_A2 >= 95) ? 95 : res.data[`+index+`].percentile_A2, 
                                                      res.data[`+index+`].percentile_A3 = (res.data[`+index+`].percentile_A3 <= 5) ? 5 : (res.data[`+index+`].percentile_A3 >= 95) ? 95 : res.data[`+index+`].percentile_A3, 
                                                      res.data[`+index+`].percentile_A4 = (res.data[`+index+`].percentile_A4 <= 5) ? 5 : (res.data[`+index+`].percentile_A4 >= 95) ? 95 : res.data[`+index+`].percentile_A4, 
                                                      res.data[`+index+`].percentile_A5 = (res.data[`+index+`].percentile_A5 <= 5) ? 5 : (res.data[`+index+`].percentile_A5 >= 95) ? 95 : res.data[`+index+`].percentile_A5, 
                                                      res.data[`+index+`].percentile_A6 = (res.data[`+index+`].percentile_A6 <= 5) ? 5 : (res.data[`+index+`].percentile_A6 >= 95) ? 95 : res.data[`+index+`].percentile_A6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                            `
                          }
                        }
                        if (data.Template === 'Line-Chart-Sub-C') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
      
                            output += `
                              if(res.data.length >= 6) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.SixPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                              output += `
                              }
                              else if(res.data.length == 5) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FivePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 4) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.FourPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 3) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.ThreePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 2) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.TwoPeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                              else if(res.data.length == 1) {
                              `
                                output += `
                                  let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                  ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                    type: 'line',
                                    data: {
                                      labels: [
                                `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "` + response.Description + `",
                                          `
                                        })
                                output += `
                                      ],
                                      datasets: [
                                `
                                          res.OnePeoples.forEach((response, index) => {
                                            output += `
                                              { 
                                                    label: [
                                                      Array.from(res.data[`+index+`].first_name)[0]+ '.' +Array.from(res.data[`+index+`].last_name)[0]+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                      res.data[`+index+`].percentile_C = (res.data[`+index+`].percentile_C <= 5) ? 5 : (res.data[`+index+`].percentile_C >= 95) ? 95 : res.data[`+index+`].percentile_C, 
                                                      res.data[`+index+`].percentile_C1 = (res.data[`+index+`].percentile_C1 <= 5) ? 5 : (res.data[`+index+`].percentile_C1 >= 95) ? 95 : res.data[`+index+`].percentile_C1, 
                                                      res.data[`+index+`].percentile_C2 = (res.data[`+index+`].percentile_C2 <= 5) ? 5 : (res.data[`+index+`].percentile_C2 >= 95) ? 95 : res.data[`+index+`].percentile_C2, 
                                                      res.data[`+index+`].percentile_C3 = (res.data[`+index+`].percentile_C3 <= 5) ? 5 : (res.data[`+index+`].percentile_C3 >= 95) ? 95 : res.data[`+index+`].percentile_C3, 
                                                      res.data[`+index+`].percentile_C4 = (res.data[`+index+`].percentile_C4 <= 5) ? 5 : (res.data[`+index+`].percentile_C4 >= 95) ? 95 : res.data[`+index+`].percentile_C4, 
                                                      res.data[`+index+`].percentile_C5 = (res.data[`+index+`].percentile_C5 <= 5) ? 5 : (res.data[`+index+`].percentile_C5 >= 95) ? 95 : res.data[`+index+`].percentile_C5, 
                                                      res.data[`+index+`].percentile_C6 = (res.data[`+index+`].percentile_C6 <= 5) ? 5 : (res.data[`+index+`].percentile_C6 >= 95) ? 95 : res.data[`+index+`].percentile_C6
                                                    ],
                                                    fill: false,
                                              },
                                            `
                                          })
                                  output+=`
                                      ],
                                    },
                                    options: {
                                      responsive: true,
                                      indexAxis: 'y',
                                      plugins: {
                                          legend : {
                                              display: true,
                                              position: "bottom"
                                          }
                                      },
                                      scales: {
                                          x: {
                                              title: {
                                                  display: true,
                                                  text: 'Percentile',
                                                  color: '#000',
                                                  font: {
                                                      family: 'Open Sans',
                                                      size: 20,
                                                      weight: 'bold',
                                                  }
                                              },
                                              position : "top",
                                              min: 0,
                                              max: 100,
                                              ticks: {
                                                  stepSize: 25
                                              }
                                          }
                                      },
                                    }
                                  })
                                `
                                output += `
                              }
                            `
                          }
                        }
      
                      }
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }
              // for TIPping point coach report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Coach-TIPpingPoint-Overall-results-Chart') { 
                  output += `
                  axios
                    .post(
                      '${url}/b5-coach-report-data',
                      {
                        "coach_id": coach_id,
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id,
                        "stream_id": stream_id,
                        "group_id": group_id
                      },
                      config                                            
                    )
                    .then((res) => {
                      console.log(res.data)
                    `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Coach-TIPpingPoint-Roles-results-Chart') { 
                          output += `
                          for(let b=0; b<res.data.length; b++){
                            $(".person"+b+"_Initials").html(res.data[b].first_name.substring(0,1) + ". " + res.data[b].last_name.substring(0,1) + ".")
                            $(".coach-TIP-person"+b).removeClass("d-none")
    
                            //Task vs Relationship
                            let totalTvsR = res.data[b].percentile_ER + res.data[b].percentile_A + res.data[b].percentile_E
                            let meanTvsR = totalTvsR / 3
                              if(meanTvsR <= 25) {
                                $(".person"+b+"_Task").html("VH")
                                $(".person"+b+"_Relationships").html("VL")
                              }
                              else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                $(".person"+b+"_Task").html("H")
                                $(".person"+b+"_Relationships").html("L")
                              }
                              else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                $(".person"+b+"_Task").html("T")
                                $(".person"+b+"_Relationships").html("T")
                              }
                              else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                $(".person"+b+"_Task").html("L")
                                $(".person"+b+"_Relationships").html("H")
                              }
                              else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                $(".person"+b+"_Task").html("VL")
                                $(".person"+b+"_Relationships").html("VH")
                              }
    
                            //Individual Autonomy vs Team Structure
                            let totalTeamvsIndividual = (100 - res.data[b].percentile_O) + res.data[b].percentile_C + res.data[b].percentile_E
                            let meanTeamvsIndividual = totalTeamvsIndividual / 3
                            if(meanTeamvsIndividual <= 25) {
                                $(".person"+b+"_IndividualAutonomy").html("VH")
                                $(".person"+b+"_TeamStructure").html("VL")
                              }
                              else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                $(".person"+b+"_IndividualAutonomy").html("H")
                                $(".person"+b+"_TeamStructure").html("L")
                              }
                              else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                $(".person"+b+"_IndividualAutonomy").html("T")
                                $(".person"+b+"_TeamStructure").html("T")
                              }
                              else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                $(".person"+b+"_IndividualAutonomy").html("L")
                                $(".person"+b+"_TeamStructure").html("H")
                              }
                              else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                $(".person"+b+"_IndividualAutonomy").html("VL")
                                $(".person"+b+"_TeamStructure").html("VH")
                              }
                            
                          // Agilator
                            if(res.data[b].percentile_C <= 25) {
                              $(".person"+b+"_Agilator").html("VH")
                            }
                            else if(res.data[b].percentile_C == 25.1 || res.data[b].percentile_C <= 35) {
                              $(".person"+b+"_Agilator").html("H")
                            }
                            else if(res.data[b].percentile_C == 35.1 || res.data[b].percentile_C <= 45) {
                              $(".person"+b+"_Agilator").html("T")
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55) {
                              $(".person"+b+"_Agilator").html("L")
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 100) {
                              $(".person"+b+"_Agilator").html("VL")
                            }
                          // Boss-being
                            if(res.data[b].percentile_E <= 45) {
                              $(".person"+b+"_BossBeing").html("VL")
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              $(".person"+b+"_BossBeing").html("L")
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 65) {
                              $(".person"+b+"_BossBeing").html("T")
                            }
                            else if(res.data[b].percentile_E == 65.1 || res.data[b].percentile_E <= 75) {
                              $(".person"+b+"_BossBeing").html("H")
                            }
                            else if(res.data[b].percentile_E == 75.1 || res.data[b].percentile_E <= 100) {
                              $(".person"+b+"_BossBeing").html("VH")
                            }
        
                          // Empathor
                            if(res.data[b].percentile_ER <= 45) {
                              $(".person"+b+"_Empathor").html("VL")
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              $(".person"+b+"_Empathor").html("L")
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 65) {
                              $(".person"+b+"_Empathor").html("T")
                            }
                            else if(res.data[b].percentile_ER == 65.1 || res.data[b].percentile_ER <= 75) {
                              $(".person"+b+"_Empathor").html("H")
                            }
                            else if(res.data[b].percentile_ER == 75.1 || res.data[b].percentile_ER <= 100) {
                              $(".person"+b+"_Empathor").html("VH")
                            }
        
                          // Imagineer
                            if(res.data[b].percentile_O <= 45) {
                              $(".person"+b+"_Imagineer").html("VL")
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55.0) {
                              $(".person"+b+"_Imagineer").html("L")
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 65) {
                              $(".person"+b+"_Imagineer").html("T")
                            }
                            else if(res.data[b].percentile_O == 65.1 || res.data[b].percentile_O <= 75) {
                              $(".person"+b+"_Imagineer").html("H")
                            }
                            else if(res.data[b].percentile_O == 75.1 || res.data[b].percentile_O <= 100) {
                              $(".person"+b+"_Imagineer").html("VH")
                            }
        
                          // Rationalist
                            if(res.data[b].percentile_A <= 25) {
                              $(".person"+b+"_Rationalist").html("VH")
                            }
                            else if(res.data[b].percentile_A == 25.1 || res.data[b].percentile_A <= 35) {
                              $(".person"+b+"_Rationalist").html("H")
                            }
                            else if(res.data[b].percentile_A == 35.1 || res.data[b].percentile_A <= 45) {
                              $(".person"+b+"_Rationalist").html("T")
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              $(".person"+b+"_Rationalist").html("L")
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 100) {
                              $(".person"+b+"_Rationalist").html("VL")
                            }
        
                          // Realist
                            if(res.data[b].percentile_O <= 25) {
                              $(".person"+b+"_Realist").html("VH")
                            }
                            else if(res.data[b].percentile_O == 25.1 || res.data[b].percentile_O <= 35) {
                              $(".person"+b+"_Realist").html("H")
                            }
                            else if(res.data[b].percentile_O == 35.1 || res.data[b].percentile_O <= 45) {
                              $(".person"+b+"_Realist").html("T")
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55) {
                              $(".person"+b+"_Realist").html("L")
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 100) {
                              $(".person"+b+"_Realist").html("VL")
                            }
        
                          // Solo-Operator
                            if(res.data[b].percentile_E <= 25) {
                              $(".person"+b+"_SoloOperator").html("VH")
                            }
                            else if(res.data[b].percentile_E == 25.1 || res.data[b].percentile_E <= 35) {
                              $(".person"+b+"_SoloOperator").html("H")
                            }
                            else if(res.data[b].percentile_E == 35.1 || res.data[b].percentile_E <= 45) {
                              $(".person"+b+"_SoloOperator").html("T")
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              $(".person"+b+"_SoloOperator").html("L")
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 100) {
                              $(".person"+b+"_SoloOperator").html("VL")
                            }
        
                          // Standard-Bearer
                            if(res.data[b].percentile_C <= 45) {
                              $(".person"+b+"_StandardBearer").html("VL")
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55.0) {
                              $(".person"+b+"_StandardBearer").html("L")
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 65) {
                              $(".person"+b+"_StandardBearer").html("T")
                            }
                            else if(res.data[b].percentile_C == 65.1 || res.data[b].percentile_C <= 75) {
                              $(".person"+b+"_StandardBearer").html("H")
                            }
                            else if(res.data[b].percentile_C == 75.1 || res.data[b].percentile_C <= 100) {
                              $(".person"+b+"_StandardBearer").html("VH")
                            }
        
                          // Strong-and-Steady
                            if(res.data[b].percentile_ER <= 25) {
                              $(".person"+b+"_StrongAndSteady").html("VH")
                            }
                            else if(res.data[b].percentile_ER == 25.1 || res.data[b].percentile_ER <= 35) {
                              $(".person"+b+"_StrongAndSteady").html("H")
                            }
                            else if(res.data[b].percentile_ER == 35.1 || res.data[b].percentile_ER <= 45) {
                              $(".person"+b+"_StrongAndSteady").html("T")
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              $(".person"+b+"_StrongAndSteady").html("L")
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 100) {
                              $(".person"+b+"_StrongAndSteady").html("VL")
                            }
        
                          // Team-Builder
                            if(res.data[b].percentile_A <= 45) {
                              $(".person"+b+"_TeamBuilder").html("VL")
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              $(".person"+b+"_TeamBuilder").html("L")
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 65) {
                              $(".person"+b+"_TeamBuilder").html("T")
                            }
                            else if(res.data[b].percentile_A == 65.1 || res.data[b].percentile_A <= 75) {
                              $(".person"+b+"_TeamBuilder").html("H")
                            }
                            else if(res.data[b].percentile_A == 75.1 || res.data[b].percentile_A <= 100) {
                              $(".person"+b+"_TeamBuilder").html("VH")
                            }
    
                          //end of loop
                          }
                          `
                        }
                        if (data.Template === 'Coach-TIPpingPoint-Overall-results-Chart') { 
                          output += `
                          //Task vs Relationship
                          let Task_VeryLow = 0
                          let Task_Low = 0
                          let Task_Typical = 0
                          let Task_High = 0
                          let Task_VeryHigh = 0
                          let Relationship_VeryLow = 0
                          let Relationship_Low = 0
                          let Relationship_Typical = 0
                          let Relationship_High = 0
                          let Relationship_VeryHigh = 0
    
                          //Individual vs Team
                          let Individual_VeryLow = 0
                          let Individual_Low = 0
                          let Individual_Typical = 0
                          let Individual_High = 0
                          let Individual_VeryHigh = 0
                          let Team_VeryLow = 0
                          let Team_Low = 0
                          let Team_Typical = 0
                          let Team_High = 0
                          let Team_VeryHigh = 0
    
                          for(let j=0; j<res.data.length; j++){
                          //Task vs Relationship
                            let totalTvsR = res.data[j].percentile_ER + res.data[j].percentile_A + res.data[j].percentile_E
                            let meanTvsR = totalTvsR / 3
                              if(meanTvsR <= 25) {
                                Task_VeryHigh = Task_VeryHigh + 1
                                Relationship_VeryLow = Relationship_VeryLow + 1
                              }
                              else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                Task_High = Task_High + 1
                                Relationship_Low = Relationship_Low + 1
                              }
                              else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                Task_Typical = Task_Typical + 1
                                Relationship_Typical = Relationship_Typical + 1
                              }
                              else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                Task_Low = Task_Low + 1
                                Relationship_High = Relationship_High + 1
                              }
                              else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                Task_VeryLow = Task_VeryLow + 1
                                Relationship_VeryHigh = Relationship_VeryHigh + 1
                              }
    
                            //Individual Autonomy vs Team Structure
                            let totalTeamvsIndividual = (100 - res.data[j].percentile_O) + res.data[j].percentile_C + res.data[j].percentile_E
                            let meanTeamvsIndividual = totalTeamvsIndividual / 3
                            if(meanTeamvsIndividual <= 25) {
                                Individual_VeryHigh = Individual_VeryHigh + 1
                                Team_VeryLow = Team_VeryLow + 1
                              }
                              else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                Individual_High = Individual_High + 1
                                Team_Low = Team_Low + 1
                              }
                              else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                Individual_Typical = Individual_Typical + 1
                                Team_Typical = Team_Typical + 1
                              }
                              else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                Individual_Low = Individual_Low + 1
                                Team_High = Team_High + 1
                              }
                              else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                Individual_VeryLow = Individual_VeryLow + 1
                                Team_VeryHigh = Team_VeryHigh + 1
                              }
                            // end of loop
                            }
                            $(".Task_VeryHigh").html(Task_VeryHigh)
                            $(".Relationship_VeryHigh").html(Relationship_VeryHigh)
                            $(".Individual_VeryHigh").html(Individual_VeryHigh)
                            $(".Team_VeryHigh").html(Team_VeryHigh)
    
                            $(".Task_High").html(Task_High)
                            $(".Relationship_High").html(Relationship_High)
                            $(".Individual_High").html(Individual_High)
                            $(".Team_High").html(Team_High)
    
                            $(".Task_Typical").html(Task_Typical)
                            $(".Relationship_Typical").html(Relationship_Typical)
                            $(".Individual_Typical").html(Individual_Typical)
                            $(".Team_Typical").html(Team_Typical)
    
                            $(".Task_Low").html(Task_Low)
                            $(".Relationship_Low").html(Relationship_Low)
                            $(".Individual_Low").html(Individual_Low)
                            $(".Team_Low").html(Team_Low)
    
                            $(".Task_VeryLow").html(Task_VeryLow)
                            $(".Relationship_VeryLow").html(Relationship_VeryLow)
                            $(".Individual_VeryLow").html(Individual_VeryLow)
                            $(".Team_VeryLow").html(Team_VeryLow)
                          `
                        }
                        if (data.Template === 'Coach-TIPping-Speedometer-all-Chart') { 
                          output += `
                          let arrData = []
                          let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                          let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                          let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                          let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                          // Total
                          let Total_percentile_ER = 0
                          let Total_percentile_E = 0
                          let Total_percentile_O= 0
                          let Total_percentile_A = 0
                          let Total_percentile_C = 0
                          // count
                          let Count_people = res.data.length
    
                          for(let h=0; h<res.data.length; h++){
                            Total_percentile_ER = Total_percentile_ER + res.data[h].percentile_ER
                            Total_percentile_E = Total_percentile_E + res.data[h].percentile_E
                            Total_percentile_O = Total_percentile_O + res.data[h].percentile_O
                            Total_percentile_A = Total_percentile_A + res.data[h].percentile_A
                            Total_percentile_C = Total_percentile_C + res.data[h].percentile_C
                          }
                          // Average
                          let AVG_percentile_ER = Total_percentile_ER / Count_people
                          let AVG_percentile_E = Total_percentile_E / Count_people
                          let AVG_percentile_O = Total_percentile_O / Count_people
                          let AVG_percentile_A = Total_percentile_A / Count_people
                          let AVG_percentile_C = Total_percentile_C / Count_people
    
                          // Speedometer-Scale-Agilator
                          if(AVG_percentile_C <= 25) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-agilator').text("Very High")
                            num3 = 1
                            score3 = "Very High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
                          else if(AVG_percentile_C == 25.1 || AVG_percentile_C <= 35) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-agilator').text("High")
                            num3 = 2
                            score3 = "High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(AVG_percentile_C == 35.1 || AVG_percentile_C <= 45) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-agilator').text("Moderate")
                            num3 = 3
                            score3 = "Moderate"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(AVG_percentile_C == 45.1 || AVG_percentile_C <= 55) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-agilator').text("Low")
                            num3 = 4
                            score3 = "Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(AVG_percentile_C == 55.1 || AVG_percentile_C <= 100) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-agilator').text("Very Low")
                            num3 = 5
                            score3 = "Very Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
      
                          // Speedometer-Scale-Boss-being
                          if(AVG_percentile_E <= 45) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-boss-being').text("Very Low")
                            num8 = 5
                            score8 = "Very Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
                          else if(AVG_percentile_E == 45.1 || AVG_percentile_E <= 55) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-boss-being').text("Low")
                            num8 = 4
                            score8 = "Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(AVG_percentile_E == 55.1 || AVG_percentile_E <= 65) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-boss-being').text("Moderate")
                            num8 = 3
                            score8 = "Moderate"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(AVG_percentile_E == 65.1 || AVG_percentile_E <= 75) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-boss-being').text("High")
                            num8 = 2
                            score8 = "High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(AVG_percentile_E == 75.1 || AVG_percentile_E <= 100) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-boss-being').text("Very High")
                            num8 = 1
                            score8 = "Very High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
      
                          // Speedometer-Scale-Empathor
                          if(AVG_percentile_ER <= 45) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-empathor').text("Very Low")
                            num6 = 5
                            score6 = "Very Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
                          else if(AVG_percentile_ER == 45.1 || AVG_percentile_ER <= 55) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-empathor').text("Low")
                            num6 = 4
                            score6 = "Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(AVG_percentile_ER == 55.1 || AVG_percentile_ER <= 65) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-empathor').text("Moderate")
                            num6 = 3
                            score6 = "Moderate"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(AVG_percentile_ER == 65.1 || AVG_percentile_ER <= 75) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-empathor').text("High")
                            num6 = 2
                            score6 = "High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(AVG_percentile_ER == 75.1 || AVG_percentile_ER <= 100) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-empathor').text("Very High")
                            num6 = 1
                            score6 = "Very High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
      
                          // Speedometer-Scale-Imagineer
                          if(AVG_percentile_O <= 45) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-imagineer').text("Very Low")
                            num4 = 5
                            score4 = "Very Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
                          else if(AVG_percentile_O == 45.1 || AVG_percentile_O <= 55.0) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-imagineer').text("Low")
                            num4 = 4
                            score4 = "Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(AVG_percentile_O == 55.1 || AVG_percentile_O <= 65) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-imagineer').text("Moderate")
                            num4 = 3
                            score4 = "Moderate"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(AVG_percentile_O == 65.1 || AVG_percentile_O <= 75) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-imagineer').text("High")
                            num4 = 2
                            score4 = "High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(AVG_percentile_O == 75.1 || AVG_percentile_O <= 100) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-imagineer').text("Very High")
                            num4 = 1
                            score4 = "Very High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
      
                          // Speedometer-Scale-Rationalist
                          if(AVG_percentile_A <= 25) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-rationalist').text("Very High")
                            num1 = 1
                            score1 = "Very High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
                          else if(AVG_percentile_A == 25.1 || AVG_percentile_A <= 35) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-rationalist').text("High")
                            num1 = 2
                            score1 = "High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(AVG_percentile_A == 35.1 || AVG_percentile_A <= 45) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-rationalist').text("Moderate")
                            num1 = 3
                            score1 = "Moderate"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(AVG_percentile_A == 45.1 || AVG_percentile_A <= 55) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-rationalist').text("Low")
                            num1 = 4
                            score1 = "Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(AVG_percentile_A == 55.1 || AVG_percentile_A <= 100) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-rationalist').text("Very Low")
                            num1 = 5
                            score1 = "Very Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
      
                          // Speedometer-Scale-Realist
                          if(AVG_percentile_O <= 25) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-realist').text("Very High")
                            num10 = 1
                            score10 = "Very High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
                          else if(AVG_percentile_O == 25.1 || AVG_percentile_O <= 35) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-realist').text("High")
                            num10 = 2
                            score10 = "High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(AVG_percentile_O == 35.1 || AVG_percentile_O <= 45) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-realist').text("Moderate")
                            num10 = 3
                            score10 = "Moderate"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(AVG_percentile_O == 45.1 || AVG_percentile_O <= 55) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-realist').text("Low")
                            num10 = 4
                            score10 = "Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(AVG_percentile_O == 55.1 || AVG_percentile_O <= 100) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-realist').text("Very Low")
                            num10 = 5
                            score10 = "Very Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
      
                          // Speedometer-Scale-Solo-Operator
                          if(AVG_percentile_E <= 25) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-solo-operator').text("Very High")
                            num2 = 1
                            score2 = "Very High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
                          else if(AVG_percentile_E == 25.1 || AVG_percentile_E <= 35) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-solo-operator').text("High")
                            num2 = 2
                            score2 = "High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(AVG_percentile_E == 35.1 || AVG_percentile_E <= 45) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-solo-operator').text("Moderate")
                            num2 = 3
                            score2 = "Moderate"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(AVG_percentile_E == 45.1 || AVG_percentile_E <= 55) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-solo-operator').text("Low")
                            num2 = 4
                            score2 = "Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(AVG_percentile_E == 55.1 || AVG_percentile_E <= 100) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-solo-operator').text("Very Low")
                            num2 = 5
                            score2 = "Very Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
      
                          // Speedometer-Scale-Standard-Bearer
                          if(AVG_percentile_C <= 45) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very Low")
                            num9 = 5
                            score9 = "Very Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
                          else if(AVG_percentile_C == 45.1 || AVG_percentile_C <= 55.0) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-standard-bearer').text("Low")
                            num9 = 4
                            score9 = "Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(AVG_percentile_C == 55.1 || AVG_percentile_C <= 65) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-standard-bearer').text("Moderate")
                            num9 = 3
                            score9 = "Moderate"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(AVG_percentile_C == 65.1 || AVG_percentile_C <= 75) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-standard-bearer').text("High")
                            num9 = 2
                            score9 = "High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(AVG_percentile_C == 75.1 || AVG_percentile_C <= 100) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very High")
                            num9 = 1
                            score9 = "Very High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
      
                          // Speedometer-Scale-Strong-and-Stable
                          if(AVG_percentile_ER <= 25) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very High")
                            num5 = 1
                            score5 = "Very High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
                          else if(AVG_percentile_ER == 25.1 || AVG_percentile_ER <= 35) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-strong-and-stable').text("High")
                            num5 = 2
                            score5 = "High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(AVG_percentile_ER == 35.1 || AVG_percentile_ER <= 45) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Moderate")
                            num5 = 3
                            score5 = "Moderate"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(AVG_percentile_ER == 45.1 || AVG_percentile_ER <= 55) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Low")
                            num5 = 4
                            score5 = "Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(AVG_percentile_ER == 55.1 || AVG_percentile_ER <= 100) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very Low")
                            num5 = 5
                            score5 = "Very Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
      
                          // Speedometer-Scale-Team-Builder
                          if(AVG_percentile_A <= 45) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-speedometer-scale-team-builder').text("Very Low")
                            num7 = 5
                            score7 = "Very Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
                          else if(AVG_percentile_A == 45.1 || AVG_percentile_A <= 55) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-speedometer-scale-team-builder').text("Low")
                            num7 = 4
                            score7 = "Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(AVG_percentile_A == 55.1 || AVG_percentile_A <= 65) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-speedometer-scale-team-builder').text("Moderate")
                            num7 = 3
                            score7 = "Moderate"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(AVG_percentile_A == 65.1 || AVG_percentile_A <= 75) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-speedometer-scale-team-builder').text("High")
                            num7 = 2
                            score7 = "High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(AVG_percentile_A == 75.1 || AVG_percentile_A <= 100) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-speedometer-scale-team-builder').text("Very High")
                            num7 = 1
                            score7 = "Very High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
      
                          let objSpeedometerAllScale = [
                            { key: num3, png: png3, score: score3, role: role3 },
                            { key: num8, png: png8, score: score8, role: role8 },
                            { key: num6, png: png6, score: score6, role: role6 },
                            { key: num4, png: png4, score: score4, role: role4 },
                            { key: num1, png: png1, score: score1, role: role1 },
                            { key: num10, png: png10, score: score10, role: role10 },
                            { key: num2, png: png2, score: score2, role: role2 },
                            { key: num9, png: png9, score: score9, role: role9 },
                            { key: num5, png: png5, score: score5, role: role5 },
                            { key: num7, png: png7, score: score7, role: role7 }
                          ]
      
                          let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                          for(let l=0; l<10; l++) {
                            $('.all-scale-role'+(l+1)).text(sortedData[l].role)
                            $('.all-scale-img'+(l+1)).attr("src", sortedData[l].png)
                            $('.all-scale-score'+(l+1)).text(sortedData[l].score)
                          }
                          `
                        } 
                        if (data.Template === 'Coach-TIPpingPoint-Roles-results-Chart2') { 
                          output += `
                          // Counts
                          let Solo_VeryLow = 0
                          let Solo_Low = 0
                          let Solo_Typical = 0
                          let Solo_High = 0
                          let Solo_VeryHigh = 0
    
                          let Agilator_VeryLow = 0
                          let Agilator_Low = 0
                          let Agilator_Typical = 0
                          let Agilator_High = 0
                          let Agilator_VeryHigh = 0
    
                          let Imagineer_VeryLow = 0
                          let Imagineer_Low = 0
                          let Imagineer_Typical = 0
                          let Imagineer_High = 0
                          let Imagineer_VeryHigh = 0
    
                          let Empathor_VeryLow = 0
                          let Empathor_Low = 0
                          let Empathor_Typical = 0
                          let Empathor_High = 0
                          let Empathor_VeryHigh = 0
    
                          let TeamBuilder_VeryLow = 0
                          let TeamBuilder_Low = 0
                          let TeamBuilder_Typical = 0
                          let TeamBuilder_High = 0
                          let TeamBuilder_VeryHigh = 0
    
                          let BossBeing_VeryLow = 0
                          let BossBeing_Low = 0
                          let BossBeing_Typical = 0
                          let BossBeing_High = 0
                          let BossBeing_VeryHigh = 0
    
                          let StandardBearer_VeryLow = 0
                          let StandardBearer_Low = 0
                          let StandardBearer_Typical = 0
                          let StandardBearer_High = 0
                          let StandardBearer_VeryHigh = 0
    
                          let Realist_VeryLow = 0
                          let Realist_Low = 0
                          let Realist_Typical = 0
                          let Realist_High = 0
                          let Realist_VeryHigh = 0
    
                          let StrongAndSteady_VeryLow = 0
                          let StrongAndSteady_Low = 0
                          let StrongAndSteady_Typical = 0
                          let StrongAndSteady_High = 0
                          let StrongAndSteady_VeryHigh = 0
    
                          let Rationalist_VeryLow = 0
                          let Rationalist_Low = 0
                          let Rationalist_Typical = 0
                          let Rationalist_High = 0
                          let Rationalist_VeryHigh = 0
    
    
                          for(let b=0; b<res.data.length; b++){
                          // Agilator
                            if(res.data[b].percentile_C <= 25) {
                              Agilator_VeryHigh = Agilator_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_C == 25.1 || res.data[b].percentile_C <= 35) {
                              Agilator_High = Agilator_High + 1
                            }
                            else if(res.data[b].percentile_C == 35.1 || res.data[b].percentile_C <= 45) {
                              Agilator_Typical = Agilator_Typical + 1
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55) {
                              Agilator_Low = Agilator_Low + 1
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 100) {
                              Agilator_VeryLow = Agilator_VeryLow + 1
                            }
                          // Boss-being
                            if(res.data[b].percentile_E <= 45) {
                              BossBeing_VeryLow = BossBeing_VeryLow + 1
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              BossBeing_Low = BossBeing_Low + 1
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 65) {
                              BossBeing_Typical= BossBeing_Typical+ 1
                            }
                            else if(res.data[b].percentile_E == 65.1 || res.data[b].percentile_E <= 75) {
                              BossBeing_High= BossBeing_High+ 1
                            }
                            else if(res.data[b].percentile_E == 75.1 || res.data[b].percentile_E <= 100) {
                              BossBeing_VeryHigh = BossBeing_VeryHigh + 1
                            }
        
                          // Empathor
                            if(res.data[b].percentile_ER <= 45) {
                              Empathor_VeryLow = Empathor_VeryLow + 1
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              Empathor_Low = Empathor_Low + 1
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 65) {
                              Empathor_Typical = Empathor_Typical + 1
                            }
                            else if(res.data[b].percentile_ER == 65.1 || res.data[b].percentile_ER <= 75) {
                              Empathor_High = Empathor_High + 1
                            }
                            else if(res.data[b].percentile_ER == 75.1 || res.data[b].percentile_ER <= 100) {
                              Empathor_VeryHigh = Empathor_VeryHigh + 1
                            }
        
                          // Imagineer
                            if(res.data[b].percentile_O <= 45) {
                              Imagineer_VeryLow = Imagineer_VeryLow + 1
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55.0) {
                              Imagineer_Low = Imagineer_Low + 1
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 65) {
                              Imagineer_Typical = Imagineer_Typical + 1
                            }
                            else if(res.data[b].percentile_O == 65.1 || res.data[b].percentile_O <= 75) {
                              Imagineer_High = Imagineer_High + 1
                            }
                            else if(res.data[b].percentile_O == 75.1 || res.data[b].percentile_O <= 100) {
                              Imagineer_VeryHigh= Imagineer_VeryHigh+ 1
                            }
        
                          // Rationalist
                            if(res.data[b].percentile_A <= 25) {
                              Rationalist_VeryHigh = Rationalist_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_A == 25.1 || res.data[b].percentile_A <= 35) {
                              Rationalist_High = Rationalist_High + 1
                            }
                            else if(res.data[b].percentile_A == 35.1 || res.data[b].percentile_A <= 45) {
                              Rationalist_Typical = Rationalist_Typical + 1
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              Rationalist_Low = Rationalist_Low + 1
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 100) {
                              Rationalist_VeryLow = Rationalist_VeryLow + 1
                            }
        
                          // Realist
                            if(res.data[b].percentile_O <= 25) {
                              Realist_VeryHigh = Realist_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_O == 25.1 || res.data[b].percentile_O <= 35) {
                              Realist_High = Realist_High + 1
                            }
                            else if(res.data[b].percentile_O == 35.1 || res.data[b].percentile_O <= 45) {
                              Realist_Typical = Realist_Typical + 1
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55) {
                              Realist_Low = Realist_Low + 1
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 100) {
                              Realist_VeryLow = Realist_VeryLow + 1
                            }
        
                          // Solo-Operator
                            if(res.data[b].percentile_E <= 25) {
                              Solo_VeryHigh = Solo_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_E == 25.1 || res.data[b].percentile_E <= 35) {
                              Solo_High = Solo_High + 1
                            }
                            else if(res.data[b].percentile_E == 35.1 || res.data[b].percentile_E <= 45) {
                              Solo_Typical = Solo_Typical + 1
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              Solo_Low = Solo_Low + 1
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 100) {
                              Solo_VeryLow = Solo_VeryLow + 1
                            }
        
                          // Standard-Bearer
                            if(res.data[b].percentile_C <= 45) {
                              StandardBearer_VeryLow = StandardBearer_VeryLow + 1
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55.0) {
                              StandardBearer_Low = StandardBearer_Low + 1
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 65) {
                              StandardBearer_Typical = StandardBearer_Typical + 1
                            }
                            else if(res.data[b].percentile_C == 65.1 || res.data[b].percentile_C <= 75) {
                              StandardBearer_High = StandardBearer_High + 1
                            }
                            else if(res.data[b].percentile_C == 75.1 || res.data[b].percentile_C <= 100) {
                              StandardBearer_VeryHigh = StandardBearer_VeryHigh + 1
                            }
        
                          // Strong-and-Steady
                            if(res.data[b].percentile_ER <= 25) {
                              StrongAndSteady_VeryHigh = StrongAndSteady_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_ER == 25.1 || res.data[b].percentile_ER <= 35) {
                              StrongAndSteady_High = StrongAndSteady_High + 1
                            }
                            else if(res.data[b].percentile_ER == 35.1 || res.data[b].percentile_ER <= 45) {
                              StrongAndSteady_Typical = StrongAndSteady_Typical + 1
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              StrongAndSteady_Low = StrongAndSteady_Low + 1
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 100) {
                              StrongAndSteady_VeryLow = StrongAndSteady_VeryLow + 1
                            }
        
                          // Team-Builder
                            if(res.data[b].percentile_A <= 45) {
                              TeamBuilder_VeryLow = TeamBuilder_VeryLow + 1
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              TeamBuilder_Low = TeamBuilder_Low + 1
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 65) {
                              TeamBuilder_Typical = TeamBuilder_Typical + 1
                            }
                            else if(res.data[b].percentile_A == 65.1 || res.data[b].percentile_A <= 75) {
                              TeamBuilder_High = TeamBuilder_High + 1
                            }
                            else if(res.data[b].percentile_A == 75.1 || res.data[b].percentile_A <= 100) {
                              TeamBuilder_VeryHigh = TeamBuilder_VeryHigh + 1
                            }
                            // end of loop
                            }
                            $(".Solo_VeryHigh").html(Solo_VeryHigh)
                            $(".Agilator_VeryHigh").html(Agilator_VeryHigh)
                            $(".Imagineer_VeryHigh").html(Imagineer_VeryHigh)
                            $(".Empathor_VeryHigh").html(Empathor_VeryHigh)
                            $(".TeamBuilder_VeryHigh").html(TeamBuilder_VeryHigh)
                            $(".BossBeing_VeryHigh").html(BossBeing_VeryHigh)
                            $(".StandardBearer_VeryHigh").html(StandardBearer_VeryHigh)
                            $(".Realist_VeryHigh").html(Realist_VeryHigh)
                            $(".StrongAndSteady_VeryHigh").html(StrongAndSteady_VeryHigh)
                            $(".Rationalist_VeryHigh").html(Rationalist_VeryHigh)
    
                            $(".Solo_High").html(Solo_High)
                            $(".Agilator_High").html(Agilator_High)
                            $(".Imagineer_High").html(Imagineer_High)
                            $(".Empathor_High").html(Empathor_High)
                            $(".TeamBuilder_High").html(TeamBuilder_High)
                            $(".BossBeing_High").html(BossBeing_High)
                            $(".StandardBearer_High").html(StandardBearer_High)
                            $(".Realist_High").html(Realist_High)
                            $(".StrongAndSteady_High").html(StrongAndSteady_High)
                            $(".Rationalist_High").html(Rationalist_High)
    
                            $(".Solo_Typical").html(Solo_Typical)
                            $(".Agilator_Typical").html(Agilator_Typical)
                            $(".Imagineer_Typical").html(Imagineer_Typical)
                            $(".Empathor_Typical").html(Empathor_Typical)
                            $(".TeamBuilder_Typical").html(TeamBuilder_Typical)
                            $(".BossBeing_Typical").html(BossBeing_Typical)
                            $(".StandardBearer_Typical").html(StandardBearer_Typical)
                            $(".Realist_Typical").html(Realist_Typical)
                            $(".StrongAndSteady_Typical").html(StrongAndSteady_Typical)
                            $(".Rationalist_Typical").html(Rationalist_Typical)
    
                            $(".Solo_Low").html(Solo_Low)
                            $(".Agilator_Low").html(Agilator_Low)
                            $(".Imagineer_Low").html(Imagineer_Low)
                            $(".Empathor_Low").html(Empathor_Low)
                            $(".TeamBuilder_Low").html(TeamBuilder_Low)
                            $(".BossBeing_Low").html(BossBeing_Low)
                            $(".StandardBearer_Low").html(StandardBearer_Low)
                            $(".Realist_Low").html(Realist_Low)
                            $(".StrongAndSteady_Low").html(StrongAndSteady_Low)
                            $(".Rationalist_Low").html(Rationalist_Low)
    
                            $(".Solo_VeryLow").html(Solo_VeryLow)
                            $(".Agilator_VeryLow").html(Agilator_VeryLow)
                            $(".Imagineer_VeryLow").html(Imagineer_VeryLow)
                            $(".Empathor_VeryLow").html(Empathor_VeryLow)
                            $(".TeamBuilder_VeryLow").html(TeamBuilder_VeryLow)
                            $(".BossBeing_VeryLow").html(BossBeing_VeryLow)
                            $(".StandardBearer_VeryLow").html(StandardBearer_VeryLow)
                            $(".Realist_VeryLow").html(Realist_VeryLow)
                            $(".StrongAndSteady_VeryLow").html(StrongAndSteady_VeryLow)
                            $(".Rationalist_VeryLow").html(Rationalist_VeryLow)
                          `
                        }
                      }
              output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }
              // for 5SteP faculty report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Bar-chart-Faculty-Sub') { 
                  output += `
                  axios
                  .post(
                      '${url}/b5-faculty-report-data',
                      {
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id
                      },
                      config                                            
                    )
                    .then((res) => {
                      console.log(res.data)
                    `
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i] 
                        if (data.Template === 'Bar-chart-Faculty-Sub') { 
                          let obj = JSON.parse(data.Text)
                          for (const data of obj) {
                            data.Elements.forEach((response) => {
                          output += `
                            $(".cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1))
                            $(".percentile-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_' + ``+response.Trait+``+ '})' + '`' + `, "background-color": "#`+ response.Color +`"})
                            $(".percentile-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_' + ``+response.Trait+``+ '}))' + '`' + `})
                          `
                              })
                            }
                        }
                        if (data.Template === 'Bar-Chart-Faculty-SuperTrait') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            output += `
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                data: {
                                  labels: [
                            `
                                  res.Elements.forEach((response) => {
                                    output += `
                                      "`+response+`",
                                    `
                                  })
                            output += `
                                  ],
                                  datasets: [
                            `
                                    output += `
                                      { 
                                        type: 'bar',
                                        backgroundColor: [
                                          `
                                            res.Color.forEach((response) => {
                                              output += `
                                                "#`+response+`",
                                              `
                                            })
                                          output += `
                                        ],
                                        data: [ 
                                          res.data[0].cohort_ER, res.data[0].cohort_E, res.data[0].cohort_O, res.data[0].cohort_A, res.data[0].cohort_C
                                        ],
                                        barPercentage: 0.5
                                      },
                                    `
                            output+=`
                                  ],
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        legend : false
                                    },
                                    scales: {
                                      x: {
                                        title: {
                                            display: true,
                                            text: 'Supertrait Main Score',
                                            color: '#000',
                                            font: {
                                                family: 'Open Sans',
                                                size: 20,
                                                weight: 'bold',
                                            }
                                        },
                                        position : "top",
                                    },
                                      y: {
                                          min: 1,
                                          max: 5,
                                          ticks: {
                                              stepSize: 1,
                                          }
                                      }
                                    }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Bar-Chart-Faculty-Subtrait') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                data: {
                                  labels: [
                            `
                                  res.Subtrait.forEach((response) => {
                                    output += `
                                      "`+response+`",
                                    `
                                  })
                            output += `
                                  ],
                                  datasets: [
                            `
                                    output += `
                                      {
                                        type: 'line',
                                        backgroundColor: "#000",
                                        borderColor: "#000",
                                        data: [
                                          res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER, res.data[0].cohort_ER,
                                          res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E, res.data[0].cohort_E,
                                          res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O, res.data[0].cohort_O,
                                          res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A, res.data[0].cohort_A,
                                          res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C, res.data[0].cohort_C
                                        ],
                                      },
                                      { 
                                        type: 'bar',
                                        backgroundColor: [
                                          `
                                            res.Color.forEach((response) => {
                                              output += `
                                                "`+response+`",
                                              `
                                            })
                                          output += `
                                        ],
                                        data: [
                                          res.data[0].cohort_ER1, res.data[0].cohort_ER2, res.data[0].cohort_ER3, res.data[0].cohort_ER4, res.data[0].cohort_ER5, res.data[0].cohort_ER6, 5.5,
                                          res.data[0].cohort_E1, res.data[0].cohort_E2, res.data[0].cohort_E3, res.data[0].cohort_E4, res.data[0].cohort_E5, res.data[0].cohort_E6, 5.5,
                                          res.data[0].cohort_O1, res.data[0].cohort_O2, res.data[0].cohort_O3, res.data[0].cohort_O4, res.data[0].cohort_O5, res.data[0].cohort_O6, 5.5,
                                          res.data[0].cohort_A1, res.data[0].cohort_A2, res.data[0].cohort_A3, res.data[0].cohort_A4, res.data[0].cohort_A5, res.data[0].cohort_A6, 5.5,
                                          res.data[0].cohort_C1, res.data[0].cohort_C2, res.data[0].cohort_C3, res.data[0].cohort_C4, res.data[0].cohort_C5, res.data[0].cohort_C6, 5.5
                                        ], 
                                        barPercentage: 0.5
                                      },
                                    `
                            output+=`
                                  ],
                                },
                                options: {
                                  responsive: true,
                                  plugins: {
                                      legend : false
                                  },
                                  scales: {
                                      y: {
                                          min: 1,
                                          max: 5.5,
                                          ticks: {
                                              stepSize: 1,
                                          }
                                      },
                                      x: {
                                        ticks: {
                                            autoSkip: false,
                                            maxRotation: 90,
                                            minRotation: 90
                                        }
                                      }
                                  },
                                }
                              })
                            `
                          }
                        }
                    }
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                  break
                }
              }
                // for TIPping point faculty report
                for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Faculty-TIPpingPoint-Overall-results-Chart') { 
                  output += `
                  axios
                  .post(
                      '${url}/b5-faculty-report-data',
                      {
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id
                      },
                      config                                            
                    )
                    .then((res) => {
                      console.log(res.data)
                    `
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i] 
                        if (data.Template === 'Faculty-TIPpingPoint-Roles-results-Chart2') { 
                          output += `
                          // Counts
                          let Solo_VeryLow = 0
                          let Solo_Low = 0
                          let Solo_Typical = 0
                          let Solo_High = 0
                          let Solo_VeryHigh = 0
    
                          let Agilator_VeryLow = 0
                          let Agilator_Low = 0
                          let Agilator_Typical = 0
                          let Agilator_High = 0
                          let Agilator_VeryHigh = 0
    
                          let Imagineer_VeryLow = 0
                          let Imagineer_Low = 0
                          let Imagineer_Typical = 0
                          let Imagineer_High = 0
                          let Imagineer_VeryHigh = 0
    
                          let Empathor_VeryLow = 0
                          let Empathor_Low = 0
                          let Empathor_Typical = 0
                          let Empathor_High = 0
                          let Empathor_VeryHigh = 0
    
                          let TeamBuilder_VeryLow = 0
                          let TeamBuilder_Low = 0
                          let TeamBuilder_Typical = 0
                          let TeamBuilder_High = 0
                          let TeamBuilder_VeryHigh = 0
    
                          let BossBeing_VeryLow = 0
                          let BossBeing_Low = 0
                          let BossBeing_Typical = 0
                          let BossBeing_High = 0
                          let BossBeing_VeryHigh = 0
    
                          let StandardBearer_VeryLow = 0
                          let StandardBearer_Low = 0
                          let StandardBearer_Typical = 0
                          let StandardBearer_High = 0
                          let StandardBearer_VeryHigh = 0
    
                          let Realist_VeryLow = 0
                          let Realist_Low = 0
                          let Realist_Typical = 0
                          let Realist_High = 0
                          let Realist_VeryHigh = 0
    
                          let StrongAndSteady_VeryLow = 0
                          let StrongAndSteady_Low = 0
                          let StrongAndSteady_Typical = 0
                          let StrongAndSteady_High = 0
                          let StrongAndSteady_VeryHigh = 0
    
                          let Rationalist_VeryLow = 0
                          let Rationalist_Low = 0
                          let Rationalist_Typical = 0
                          let Rationalist_High = 0
                          let Rationalist_VeryHigh = 0
    
    
                          for(let b=0; b<res.data.length; b++){
                          // Agilator
                            if(res.data[b].percentile_C <= 25) {
                              Agilator_VeryHigh = Agilator_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_C == 25.1 || res.data[b].percentile_C <= 35) {
                              Agilator_High = Agilator_High + 1
                            }
                            else if(res.data[b].percentile_C == 35.1 || res.data[b].percentile_C <= 45) {
                              Agilator_Typical = Agilator_Typical + 1
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55) {
                              Agilator_Low = Agilator_Low + 1
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 100) {
                              Agilator_VeryLow = Agilator_VeryLow + 1
                            }
                          // Boss-being
                            if(res.data[b].percentile_E <= 45) {
                              BossBeing_VeryLow = BossBeing_VeryLow + 1
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              BossBeing_Low = BossBeing_Low + 1
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 65) {
                              BossBeing_Typical= BossBeing_Typical+ 1
                            }
                            else if(res.data[b].percentile_E == 65.1 || res.data[b].percentile_E <= 75) {
                              BossBeing_High= BossBeing_High+ 1
                            }
                            else if(res.data[b].percentile_E == 75.1 || res.data[b].percentile_E <= 100) {
                              BossBeing_VeryHigh = BossBeing_VeryHigh + 1
                            }
        
                          // Empathor
                            if(res.data[b].percentile_ER <= 45) {
                              Empathor_VeryLow = Empathor_VeryLow + 1
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              Empathor_Low = Empathor_Low + 1
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 65) {
                              Empathor_Typical = Empathor_Typical + 1
                            }
                            else if(res.data[b].percentile_ER == 65.1 || res.data[b].percentile_ER <= 75) {
                              Empathor_High = Empathor_High + 1
                            }
                            else if(res.data[b].percentile_ER == 75.1 || res.data[b].percentile_ER <= 100) {
                              Empathor_VeryHigh = Empathor_VeryHigh + 1
                            }
        
                          // Imagineer
                            if(res.data[b].percentile_O <= 45) {
                              Imagineer_VeryLow = Imagineer_VeryLow + 1
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55.0) {
                              Imagineer_Low = Imagineer_Low + 1
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 65) {
                              Imagineer_Typical = Imagineer_Typical + 1
                            }
                            else if(res.data[b].percentile_O == 65.1 || res.data[b].percentile_O <= 75) {
                              Imagineer_High = Imagineer_High + 1
                            }
                            else if(res.data[b].percentile_O == 75.1 || res.data[b].percentile_O <= 100) {
                              Imagineer_VeryHigh= Imagineer_VeryHigh+ 1
                            }
        
                          // Rationalist
                            if(res.data[b].percentile_A <= 25) {
                              Rationalist_VeryHigh = Rationalist_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_A == 25.1 || res.data[b].percentile_A <= 35) {
                              Rationalist_High = Rationalist_High + 1
                            }
                            else if(res.data[b].percentile_A == 35.1 || res.data[b].percentile_A <= 45) {
                              Rationalist_Typical = Rationalist_Typical + 1
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              Rationalist_Low = Rationalist_Low + 1
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 100) {
                              Rationalist_VeryLow = Rationalist_VeryLow + 1
                            }
        
                          // Realist
                            if(res.data[b].percentile_O <= 25) {
                              Realist_VeryHigh = Realist_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_O == 25.1 || res.data[b].percentile_O <= 35) {
                              Realist_High = Realist_High + 1
                            }
                            else if(res.data[b].percentile_O == 35.1 || res.data[b].percentile_O <= 45) {
                              Realist_Typical = Realist_Typical + 1
                            }
                            else if(res.data[b].percentile_O == 45.1 || res.data[b].percentile_O <= 55) {
                              Realist_Low = Realist_Low + 1
                            }
                            else if(res.data[b].percentile_O == 55.1 || res.data[b].percentile_O <= 100) {
                              Realist_VeryLow = Realist_VeryLow + 1
                            }
        
                          // Solo-Operator
                            if(res.data[b].percentile_E <= 25) {
                              Solo_VeryHigh = Solo_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_E == 25.1 || res.data[b].percentile_E <= 35) {
                              Solo_High = Solo_High + 1
                            }
                            else if(res.data[b].percentile_E == 35.1 || res.data[b].percentile_E <= 45) {
                              Solo_Typical = Solo_Typical + 1
                            }
                            else if(res.data[b].percentile_E == 45.1 || res.data[b].percentile_E <= 55) {
                              Solo_Low = Solo_Low + 1
                            }
                            else if(res.data[b].percentile_E == 55.1 || res.data[b].percentile_E <= 100) {
                              Solo_VeryLow = Solo_VeryLow + 1
                            }
        
                          // Standard-Bearer
                            if(res.data[b].percentile_C <= 45) {
                              StandardBearer_VeryLow = StandardBearer_VeryLow + 1
                            }
                            else if(res.data[b].percentile_C == 45.1 || res.data[b].percentile_C <= 55.0) {
                              StandardBearer_Low = StandardBearer_Low + 1
                            }
                            else if(res.data[b].percentile_C == 55.1 || res.data[b].percentile_C <= 65) {
                              StandardBearer_Typical = StandardBearer_Typical + 1
                            }
                            else if(res.data[b].percentile_C == 65.1 || res.data[b].percentile_C <= 75) {
                              StandardBearer_High = StandardBearer_High + 1
                            }
                            else if(res.data[b].percentile_C == 75.1 || res.data[b].percentile_C <= 100) {
                              StandardBearer_VeryHigh = StandardBearer_VeryHigh + 1
                            }
        
                          // Strong-and-Steady
                            if(res.data[b].percentile_ER <= 25) {
                              StrongAndSteady_VeryHigh = StrongAndSteady_VeryHigh + 1
                            }
                            else if(res.data[b].percentile_ER == 25.1 || res.data[b].percentile_ER <= 35) {
                              StrongAndSteady_High = StrongAndSteady_High + 1
                            }
                            else if(res.data[b].percentile_ER == 35.1 || res.data[b].percentile_ER <= 45) {
                              StrongAndSteady_Typical = StrongAndSteady_Typical + 1
                            }
                            else if(res.data[b].percentile_ER == 45.1 || res.data[b].percentile_ER <= 55) {
                              StrongAndSteady_Low = StrongAndSteady_Low + 1
                            }
                            else if(res.data[b].percentile_ER == 55.1 || res.data[b].percentile_ER <= 100) {
                              StrongAndSteady_VeryLow = StrongAndSteady_VeryLow + 1
                            }
        
                          // Team-Builder
                            if(res.data[b].percentile_A <= 45) {
                              TeamBuilder_VeryLow = TeamBuilder_VeryLow + 1
                            }
                            else if(res.data[b].percentile_A == 45.1 || res.data[b].percentile_A <= 55) {
                              TeamBuilder_Low = TeamBuilder_Low + 1
                            }
                            else if(res.data[b].percentile_A == 55.1 || res.data[b].percentile_A <= 65) {
                              TeamBuilder_Typical = TeamBuilder_Typical + 1
                            }
                            else if(res.data[b].percentile_A == 65.1 || res.data[b].percentile_A <= 75) {
                              TeamBuilder_High = TeamBuilder_High + 1
                            }
                            else if(res.data[b].percentile_A == 75.1 || res.data[b].percentile_A <= 100) {
                              TeamBuilder_VeryHigh = TeamBuilder_VeryHigh + 1
                            }
                            // end of loop
                            }
                            $(".Solo_VeryHigh").html(Solo_VeryHigh)
                            $(".Agilator_VeryHigh").html(Agilator_VeryHigh)
                            $(".Imagineer_VeryHigh").html(Imagineer_VeryHigh)
                            $(".Empathor_VeryHigh").html(Empathor_VeryHigh)
                            $(".TeamBuilder_VeryHigh").html(TeamBuilder_VeryHigh)
                            $(".BossBeing_VeryHigh").html(BossBeing_VeryHigh)
                            $(".StandardBearer_VeryHigh").html(StandardBearer_VeryHigh)
                            $(".Realist_VeryHigh").html(Realist_VeryHigh)
                            $(".StrongAndSteady_VeryHigh").html(StrongAndSteady_VeryHigh)
                            $(".Rationalist_VeryHigh").html(Rationalist_VeryHigh)
    
                            $(".Solo_High").html(Solo_High)
                            $(".Agilator_High").html(Agilator_High)
                            $(".Imagineer_High").html(Imagineer_High)
                            $(".Empathor_High").html(Empathor_High)
                            $(".TeamBuilder_High").html(TeamBuilder_High)
                            $(".BossBeing_High").html(BossBeing_High)
                            $(".StandardBearer_High").html(StandardBearer_High)
                            $(".Realist_High").html(Realist_High)
                            $(".StrongAndSteady_High").html(StrongAndSteady_High)
                            $(".Rationalist_High").html(Rationalist_High)
    
                            $(".Solo_Typical").html(Solo_Typical)
                            $(".Agilator_Typical").html(Agilator_Typical)
                            $(".Imagineer_Typical").html(Imagineer_Typical)
                            $(".Empathor_Typical").html(Empathor_Typical)
                            $(".TeamBuilder_Typical").html(TeamBuilder_Typical)
                            $(".BossBeing_Typical").html(BossBeing_Typical)
                            $(".StandardBearer_Typical").html(StandardBearer_Typical)
                            $(".Realist_Typical").html(Realist_Typical)
                            $(".StrongAndSteady_Typical").html(StrongAndSteady_Typical)
                            $(".Rationalist_Typical").html(Rationalist_Typical)
    
                            $(".Solo_Low").html(Solo_Low)
                            $(".Agilator_Low").html(Agilator_Low)
                            $(".Imagineer_Low").html(Imagineer_Low)
                            $(".Empathor_Low").html(Empathor_Low)
                            $(".TeamBuilder_Low").html(TeamBuilder_Low)
                            $(".BossBeing_Low").html(BossBeing_Low)
                            $(".StandardBearer_Low").html(StandardBearer_Low)
                            $(".Realist_Low").html(Realist_Low)
                            $(".StrongAndSteady_Low").html(StrongAndSteady_Low)
                            $(".Rationalist_Low").html(Rationalist_Low)
    
                            $(".Solo_VeryLow").html(Solo_VeryLow)
                            $(".Agilator_VeryLow").html(Agilator_VeryLow)
                            $(".Imagineer_VeryLow").html(Imagineer_VeryLow)
                            $(".Empathor_VeryLow").html(Empathor_VeryLow)
                            $(".TeamBuilder_VeryLow").html(TeamBuilder_VeryLow)
                            $(".BossBeing_VeryLow").html(BossBeing_VeryLow)
                            $(".StandardBearer_VeryLow").html(StandardBearer_VeryLow)
                            $(".Realist_VeryLow").html(Realist_VeryLow)
                            $(".StrongAndSteady_VeryLow").html(StrongAndSteady_VeryLow)
                            $(".Rationalist_VeryLow").html(Rationalist_VeryLow)
                          `
                        }
                        if (data.Template === 'Faculty-TIPping-Speedometer-all-Chart') { 
                          output += `
                          let arrData = []
                          let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                          let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                          let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                          let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                          // Total
                          let Total_percentile_ER = 0
                          let Total_percentile_E = 0
                          let Total_percentile_O= 0
                          let Total_percentile_A = 0
                          let Total_percentile_C = 0
                          // count
                          let Count_people = res.data.length
    
                          for(let h=0; h<res.data.length; h++){
                            Total_percentile_ER = Total_percentile_ER + res.data[h].percentile_ER
                            Total_percentile_E = Total_percentile_E + res.data[h].percentile_E
                            Total_percentile_O = Total_percentile_O + res.data[h].percentile_O
                            Total_percentile_A = Total_percentile_A + res.data[h].percentile_A
                            Total_percentile_C = Total_percentile_C + res.data[h].percentile_C
                          }
                          // Average
                          let AVG_percentile_ER = Total_percentile_ER / Count_people
                          let AVG_percentile_E = Total_percentile_E / Count_people
                          let AVG_percentile_O = Total_percentile_O / Count_people
                          let AVG_percentile_A = Total_percentile_A / Count_people
                          let AVG_percentile_C = Total_percentile_C / Count_people
    
                          // Speedometer-Scale-Agilator
                          if(AVG_percentile_C <= 25) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-agilator').text("Very High")
                            num3 = 1
                            score3 = "Very High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
                          else if(AVG_percentile_C == 25.1 || AVG_percentile_C <= 35) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-agilator').text("High")
                            num3 = 2
                            score3 = "High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(AVG_percentile_C == 35.1 || AVG_percentile_C <= 45) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-agilator').text("Moderate")
                            num3 = 3
                            score3 = "Moderate"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(AVG_percentile_C == 45.1 || AVG_percentile_C <= 55) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-agilator').text("Low")
                            num3 = 4
                            score3 = "Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(AVG_percentile_C == 55.1 || AVG_percentile_C <= 100) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-agilator').text("Very Low")
                            num3 = 5
                            score3 = "Very Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
      
                          // Speedometer-Scale-Boss-being
                          if(AVG_percentile_E <= 45) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-boss-being').text("Very Low")
                            num8 = 5
                            score8 = "Very Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
                          else if(AVG_percentile_E == 45.1 || AVG_percentile_E <= 55) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-boss-being').text("Low")
                            num8 = 4
                            score8 = "Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(AVG_percentile_E == 55.1 || AVG_percentile_E <= 65) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-boss-being').text("Moderate")
                            num8 = 3
                            score8 = "Moderate"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(AVG_percentile_E == 65.1 || AVG_percentile_E <= 75) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-boss-being').text("High")
                            num8 = 2
                            score8 = "High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(AVG_percentile_E == 75.1 || AVG_percentile_E <= 100) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-boss-being').text("Very High")
                            num8 = 1
                            score8 = "Very High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
      
                          // Speedometer-Scale-Empathor
                          if(AVG_percentile_ER <= 45) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-empathor').text("Very Low")
                            num6 = 5
                            score6 = "Very Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
                          else if(AVG_percentile_ER == 45.1 || AVG_percentile_ER <= 55) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-empathor').text("Low")
                            num6 = 4
                            score6 = "Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(AVG_percentile_ER == 55.1 || AVG_percentile_ER <= 65) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-empathor').text("Moderate")
                            num6 = 3
                            score6 = "Moderate"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(AVG_percentile_ER == 65.1 || AVG_percentile_ER <= 75) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-empathor').text("High")
                            num6 = 2
                            score6 = "High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(AVG_percentile_ER == 75.1 || AVG_percentile_ER <= 100) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-empathor').text("Very High")
                            num6 = 1
                            score6 = "Very High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
      
                          // Speedometer-Scale-Imagineer
                          if(AVG_percentile_O <= 45) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-imagineer').text("Very Low")
                            num4 = 5
                            score4 = "Very Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
                          else if(AVG_percentile_O == 45.1 || AVG_percentile_O <= 55.0) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-imagineer').text("Low")
                            num4 = 4
                            score4 = "Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(AVG_percentile_O == 55.1 || AVG_percentile_O <= 65) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-imagineer').text("Moderate")
                            num4 = 3
                            score4 = "Moderate"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(AVG_percentile_O == 65.1 || AVG_percentile_O <= 75) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-imagineer').text("High")
                            num4 = 2
                            score4 = "High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(AVG_percentile_O == 75.1 || AVG_percentile_O <= 100) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-imagineer').text("Very High")
                            num4 = 1
                            score4 = "Very High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
      
                          // Speedometer-Scale-Rationalist
                          if(AVG_percentile_A <= 25) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-rationalist').text("Very High")
                            num1 = 1
                            score1 = "Very High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
                          else if(AVG_percentile_A == 25.1 || AVG_percentile_A <= 35) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-rationalist').text("High")
                            num1 = 2
                            score1 = "High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(AVG_percentile_A == 35.1 || AVG_percentile_A <= 45) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-rationalist').text("Moderate")
                            num1 = 3
                            score1 = "Moderate"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(AVG_percentile_A == 45.1 || AVG_percentile_A <= 55) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-rationalist').text("Low")
                            num1 = 4
                            score1 = "Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(AVG_percentile_A == 55.1 || AVG_percentile_A <= 100) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-rationalist').text("Very Low")
                            num1 = 5
                            score1 = "Very Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
      
                          // Speedometer-Scale-Realist
                          if(AVG_percentile_O <= 25) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-realist').text("Very High")
                            num10 = 1
                            score10 = "Very High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
                          else if(AVG_percentile_O == 25.1 || AVG_percentile_O <= 35) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-realist').text("High")
                            num10 = 2
                            score10 = "High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(AVG_percentile_O == 35.1 || AVG_percentile_O <= 45) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-realist').text("Moderate")
                            num10 = 3
                            score10 = "Moderate"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(AVG_percentile_O == 45.1 || AVG_percentile_O <= 55) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-realist').text("Low")
                            num10 = 4
                            score10 = "Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(AVG_percentile_O == 55.1 || AVG_percentile_O <= 100) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-realist').text("Very Low")
                            num10 = 5
                            score10 = "Very Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
      
                          // Speedometer-Scale-Solo-Operator
                          if(AVG_percentile_E <= 25) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-solo-operator').text("Very High")
                            num2 = 1
                            score2 = "Very High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
                          else if(AVG_percentile_E == 25.1 || AVG_percentile_E <= 35) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-solo-operator').text("High")
                            num2 = 2
                            score2 = "High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(AVG_percentile_E == 35.1 || AVG_percentile_E <= 45) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-solo-operator').text("Moderate")
                            num2 = 3
                            score2 = "Moderate"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(AVG_percentile_E == 45.1 || AVG_percentile_E <= 55) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-solo-operator').text("Low")
                            num2 = 4
                            score2 = "Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(AVG_percentile_E == 55.1 || AVG_percentile_E <= 100) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-solo-operator').text("Very Low")
                            num2 = 5
                            score2 = "Very Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
      
                          // Speedometer-Scale-Standard-Bearer
                          if(AVG_percentile_C <= 45) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very Low")
                            num9 = 5
                            score9 = "Very Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
                          else if(AVG_percentile_C == 45.1 || AVG_percentile_C <= 55.0) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-standard-bearer').text("Low")
                            num9 = 4
                            score9 = "Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(AVG_percentile_C == 55.1 || AVG_percentile_C <= 65) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-standard-bearer').text("Moderate")
                            num9 = 3
                            score9 = "Moderate"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(AVG_percentile_C == 65.1 || AVG_percentile_C <= 75) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-standard-bearer').text("High")
                            num9 = 2
                            score9 = "High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(AVG_percentile_C == 75.1 || AVG_percentile_C <= 100) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very High")
                            num9 = 1
                            score9 = "Very High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
      
                          // Speedometer-Scale-Strong-and-Stable
                          if(AVG_percentile_ER <= 25) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very High")
                            num5 = 1
                            score5 = "Very High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
                          else if(AVG_percentile_ER == 25.1 || AVG_percentile_ER <= 35) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-strong-and-stable').text("High")
                            num5 = 2
                            score5 = "High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(AVG_percentile_ER == 35.1 || AVG_percentile_ER <= 45) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Moderate")
                            num5 = 3
                            score5 = "Moderate"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(AVG_percentile_ER == 45.1 || AVG_percentile_ER <= 55) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Low")
                            num5 = 4
                            score5 = "Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(AVG_percentile_ER == 55.1 || AVG_percentile_ER <= 100) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very Low")
                            num5 = 5
                            score5 = "Very Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
      
                          // Speedometer-Scale-Team-Builder
                          if(AVG_percentile_A <= 45) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-speedometer-scale-team-builder').text("Very Low")
                            num7 = 5
                            score7 = "Very Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
                          else if(AVG_percentile_A == 45.1 || AVG_percentile_A <= 55) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-speedometer-scale-team-builder').text("Low")
                            num7 = 4
                            score7 = "Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(AVG_percentile_A == 55.1 || AVG_percentile_A <= 65) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-speedometer-scale-team-builder').text("Moderate")
                            num7 = 3
                            score7 = "Moderate"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(AVG_percentile_A == 65.1 || AVG_percentile_A <= 75) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-speedometer-scale-team-builder').text("High")
                            num7 = 2
                            score7 = "High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(AVG_percentile_A == 75.1 || AVG_percentile_A <= 100) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-speedometer-scale-team-builder').text("Very High")
                            num7 = 1
                            score7 = "Very High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
      
                          let objSpeedometerAllScale = [
                            { key: num3, png: png3, score: score3, role: role3 },
                            { key: num8, png: png8, score: score8, role: role8 },
                            { key: num6, png: png6, score: score6, role: role6 },
                            { key: num4, png: png4, score: score4, role: role4 },
                            { key: num1, png: png1, score: score1, role: role1 },
                            { key: num10, png: png10, score: score10, role: role10 },
                            { key: num2, png: png2, score: score2, role: role2 },
                            { key: num9, png: png9, score: score9, role: role9 },
                            { key: num5, png: png5, score: score5, role: role5 },
                            { key: num7, png: png7, score: score7, role: role7 }
                          ]
      
                          let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                          for(let l=0; l<10; l++) {
                            $('.all-scale-role'+(l+1)).text(sortedData[l].role)
                            $('.all-scale-img'+(l+1)).attr("src", sortedData[l].png)
                            $('.all-scale-score'+(l+1)).text(sortedData[l].score)
                          }
                          `
                        } 
                        if (data.Template === 'Faculty-TIPpingPoint-Overall-results-Chart') {
                        output += `
                        //Task vs Relationship
                          let Task_VeryLow = 0
                          let Task_Low = 0
                          let Task_Typical = 0
                          let Task_High = 0
                          let Task_VeryHigh = 0
                          let Relationship_VeryLow = 0
                          let Relationship_Low = 0
                          let Relationship_Typical = 0
                          let Relationship_High = 0
                          let Relationship_VeryHigh = 0
    
                          //Individual vs Team
                          let Individual_VeryLow = 0
                          let Individual_Low = 0
                          let Individual_Typical = 0
                          let Individual_High = 0
                          let Individual_VeryHigh = 0
                          let Team_VeryLow = 0
                          let Team_Low = 0
                          let Team_Typical = 0
                          let Team_High = 0
                          let Team_VeryHigh = 0
    
                          for(let j=0; j<res.data.length; j++){
                          //Task vs Relationship
                            let totalTvsR = res.data[j].percentile_ER + res.data[j].percentile_A + res.data[j].percentile_E
                            let meanTvsR = totalTvsR / 3
                              if(meanTvsR <= 25) {
                                Task_VeryHigh = Task_VeryHigh + 1
                                Relationship_VeryLow = Relationship_VeryLow + 1
                              }
                              else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                Task_High = Task_High + 1
                                Relationship_Low = Relationship_Low + 1
                              }
                              else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                Task_Typical = Task_Typical + 1
                                Relationship_Typical = Relationship_Typical + 1
                              }
                              else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                Task_Low = Task_Low + 1
                                Relationship_High = Relationship_High + 1
                              }
                              else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                Task_VeryLow = Task_VeryLow + 1
                                Relationship_VeryHigh = Relationship_VeryHigh + 1
                              }
    
                            //Individual Autonomy vs Team Structure
                            let totalTeamvsIndividual = (100 - res.data[j].percentile_O) + res.data[j].percentile_C + res.data[j].percentile_E
                            let meanTeamvsIndividual = totalTeamvsIndividual / 3
                            if(meanTeamvsIndividual <= 25) {
                                Individual_VeryHigh = Individual_VeryHigh + 1
                                Team_VeryLow = Team_VeryLow + 1
                              }
                              else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                Individual_High = Individual_High + 1
                                Team_Low = Team_Low + 1
                              }
                              else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                Individual_Typical = Individual_Typical + 1
                                Team_Typical = Team_Typical + 1
                              }
                              else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                Individual_Low = Individual_Low + 1
                                Team_High = Team_High + 1
                              }
                              else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                Individual_VeryLow = Individual_VeryLow + 1
                                Team_VeryHigh = Team_VeryHigh + 1
                              }
                            // end of loop
                            }
                            $(".Task_VeryHigh").html(Task_VeryHigh)
                            $(".Relationship_VeryHigh").html(Relationship_VeryHigh)
                            $(".Individual_VeryHigh").html(Individual_VeryHigh)
                            $(".Team_VeryHigh").html(Team_VeryHigh)
    
                            $(".Task_High").html(Task_High)
                            $(".Relationship_High").html(Relationship_High)
                            $(".Individual_High").html(Individual_High)
                            $(".Team_High").html(Team_High)
    
                            $(".Task_Typical").html(Task_Typical)
                            $(".Relationship_Typical").html(Relationship_Typical)
                            $(".Individual_Typical").html(Individual_Typical)
                            $(".Team_Typical").html(Team_Typical)
    
                            $(".Task_Low").html(Task_Low)
                            $(".Relationship_Low").html(Relationship_Low)
                            $(".Individual_Low").html(Individual_Low)
                            $(".Team_Low").html(Team_Low)
    
                            $(".Task_VeryLow").html(Task_VeryLow)
                            $(".Relationship_VeryLow").html(Relationship_VeryLow)
                            $(".Individual_VeryLow").html(Individual_VeryLow)
                            $(".Team_VeryLow").html(Team_VeryLow)
                        `
                    }
                  } 
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }
              // for pressure point faculty report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Faculty-PressurePoint-Link-Chart') { 
                  output += `
                  axios
                    .post(
                      '${url}/b5-faculty-report-data',
                      {
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id
                      },
                      config                                            
                    )
                    .then((res) => {
                    ` 
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i] 
                        if (data.Template === 'Faculty-PressurePoint-Link-Chart') { 
                          output += `
                          let EmotionallyNumb_VeryLow = 0
                          let EmotionallyNumb_Low = 0
                          let EmotionallyNumb_VeryHigh= 0
                          let EmotionallyNumb_High= 0
                          let Emotive_VeryLow = 0
                          let Emotive_Low = 0
                          let Emotive_VeryHigh = 0
                          let Emotive_High = 0
    
                          let Disappearing_VeryLow = 0
                          let Disappearing_Low = 0
                          let Disappearing_VeryHigh = 0
                          let Disappearing_High = 0
                          let Dominating_VeryLow = 0
                          let Dominating_Low = 0
                          let Dominating_VeryHigh = 0
                          let Dominating_High = 0
    
                          let StrictlyLiteral_VeryLow = 0
                          let StrictlyLiteral_Low = 0
                          let StrictlyLiteral_VeryHigh = 0
                          let StrictlyLiteral_High = 0
                          let Dreaming_VeryLow = 0
                          let Dreaming_Low = 0
                          let Dreaming_VeryHigh = 0
                          let Dreaming_High = 0
    
                          let ColdLogical_VeryLow = 0
                          let ColdLogical_Low = 0
                          let ColdLogical_VeryHigh = 0
                          let ColdLogical_High = 0
                          let Amendable_VeryLow = 0
                          let Amendable_Low = 0
                          let Amendable_VeryHigh = 0
                          let Amendable_High = 0
    
                          let Pliable_VeryLow = 0
                          let Pliable_Low = 0
                          let Pliable_VeryHigh = 0
                          let Pliable_High = 0
                          let Conforming_VeryLow = 0
                          let Conforming_Low = 0
                          let Conforming_VeryHigh = 0
                          let Conforming_High = 0
    
                        for(let b=0; b<res.data.length; b++){
                          let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
                            // Emotionally Numb
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 0.8
                                }
    
                                if(low_percentile_score_ER <= 20) {
                                  EmotionallyNumb_VeryHigh = EmotionallyNumb_VeryHigh + 1
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  EmotionallyNumb_High = EmotionallyNumb_High + 1
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  EmotionallyNumb_Low = EmotionallyNumb_Low + 1
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  EmotionallyNumb_VeryLow = EmotionallyNumb_VeryLow + 1
                                }
    
                            // Emotive
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  Emotive_VeryLow = Emotive_VeryLow + 1
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  Emotive_Low = Emotive_Low + 1
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  Emotive_High = Emotive_High + 1
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  Emotive_VeryHigh = Emotive_VeryHigh + 1
                                }
    
                            // Disappearing
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[b].percentile_E * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[b].percentile_E * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[b].percentile_E * 0.8
                                }
            
                                if(low_percentile_score_E <= 20) {
                                  Disappearing_VeryHigh = Disappearing_VeryHigh + 1
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  Disappearing_High = Disappearing_High + 1
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  Disappearing_Low = Disappearing_Low + 1
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  Disappearing_VeryLow = Disappearing_VeryLow + 1
                                }
    
                            // Dominating
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[b].percentile_E * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[b].percentile_E * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[b].percentile_E * 1.2
                                }
            
                                if(high_percentile_score_E <= 20) {
                                  Dominating_VeryLow = Dominating_VeryLow + 1
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  Dominating_Low = Dominating_Low + 1
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  Dominating_High = Dominating_High + 1
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  Dominating_VeryHigh = Dominating_VeryHigh + 1
                                }
                            // Strictly Literal
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[b].percentile_O * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[b].percentile_O * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[b].percentile_O * 0.8
                                }
            
                                if(low_percentile_score_O <= 20) {
                                  StrictlyLiteral_VeryHigh = StrictlyLiteral_VeryHigh + 1
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  StrictlyLiteral_High = StrictlyLiteral_High + 1
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  StrictlyLiteral_Low = StrictlyLiteral_Low + 1
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  StrictlyLiteral_VeryLow = StrictlyLiteral_VeryLow + 1
                                }
    
                            // Dreaming
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[b].percentile_O * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[b].percentile_O * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[b].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  Dreaming_VeryLow = Dreaming_VeryLow + 1
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  Dreaming_Low = Dreaming_Low + 1
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  Dreaming_High = Dreaming_High + 1
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  Dreaming_VeryHigh = Dreaming_VeryHigh + 1
                                }
    
                            // Cold Logical
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[b].percentile_A * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[b].percentile_A * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[b].percentile_A * 0.8
                                }
            
                                if(low_percentile_score_A <= 20) {
                                  ColdLogical_VeryHigh = ColdLogical_VeryHigh + 1
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  ColdLogical_High = ColdLogical_High + 1
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  ColdLogical_Low = ColdLogical_Low + 1
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  ColdLogical_VeryLow = ColdLogical_VeryLow + 1
                                }
    
                            // Amenable
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[b].percentile_A * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[b].percentile_A * 1.0
                                }
                                else if(res.data[b].percentile_ER== 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[b].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  Amendable_VeryLow = Amendable_VeryLow + 1
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  Amendable_Low = Amendable_Low + 1
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  Amendable_High = Amendable_High + 1
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  Amendable_VeryHigh = Amendable_VeryHigh + 1
                                }
    
                            // Pliable
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[b].percentile_C * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[b].percentile_C * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[b].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  Pliable_VeryHigh = Pliable_VeryHigh + 1
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  Pliable_High = Pliable_High + 1
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  Pliable_Low = Pliable_Low + 1
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  Pliable_VeryLow = Pliable_VeryLow + 1
                                }
    
                            // Conforming
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[b].percentile_C * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[b].percentile_C * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[b].percentile_C * 1.2
                                }
            
                                if(high_percentile_score_C <= 20) {
                                  Conforming_VeryLow = Conforming_VeryLow + 1
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  Conforming_Low = Conforming_Low + 1
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  Conforming_High = Conforming_High + 1
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  Conforming_VeryHigh = Conforming_VeryHigh + 1
                                }
                          //end of loop
                        }
                        // print to Super Traits
                        $(".Total_ER_VeryLow").html("Total: " + EmotionallyNumb_VeryLow)
                        $(".Total_ER_Low").html("Total: " + EmotionallyNumb_Low)
                        $(".Total_ER_VeryHigh").html("Total: " + Emotive_VeryHigh)
                        $(".Total_ER_High").html("Total: " + Emotive_High)
    
                        $(".Total_E_VeryLow").html("Total: " + Disappearing_VeryLow)
                        $(".Total_E_Low").html("Total: " + Disappearing_Low)
                        $(".Total_E_VeryHigh").html("Total: " + Dominating_VeryHigh)
                        $(".Total_E_High").html("Total: " + Dominating_High)
    
                        $(".Total_O_VeryLow").html("Total: " + StrictlyLiteral_VeryLow)
                        $(".Total_O_Low").html("Total: " + StrictlyLiteral_Low)
                        $(".Total_O_VeryHigh").html("Total: " + Dreaming_VeryHigh)
                        $(".Total_O_High").html("Total: " + Dreaming_High)
    
                        $(".Total_A_VeryLow").html("Total: " + ColdLogical_VeryLow)
                        $(".Total_A_Low").html("Total: " + ColdLogical_Low)
                        $(".Total_A_VeryHigh").html("Total: " + Amendable_VeryHigh)
                        $(".Total_A_High").html("Total: " + Amendable_High)
    
                        $(".Total_C_VeryLow").html("Total: " + Pliable_VeryLow)
                        $(".Total_C_Low").html("Total: " + Pliable_Low)
                        $(".Total_C_VeryHigh").html("Total: " + Conforming_VeryHigh)
                        $(".Total_C_High").html("Total: " + Conforming_High)
                        `
                        }
                      if (data.Template === 'Faculty-PressurePoint-Breakdown-Chart') { 
                          output +=
                          `
                          let EmotionallyNumb1_VeryLow = 0
                          let EmotionallyNumb1_Low = 0
                          let EmotionallyNumb1_Medium = 0
                          let EmotionallyNumb1_VeryHigh = 0
                          let EmotionallyNumb1_High= 0
                          let Emotive1_VeryLow = 0
                          let Emotive1_Low = 0
                          let Emotive1_Medium = 0
                          let Emotive1_VeryHigh = 0
                          let Emotive1_High = 0
    
                          let Disappearing1_VeryLow = 0
                          let Disappearing1_Low = 0
                          let Disappearing1_Medium = 0
                          let Disappearing1_VeryHigh = 0
                          let Disappearing1_High = 0
                          let Dominating1_VeryLow = 0
                          let Dominating1_Low = 0
                          let Dominating1_Medium = 0
                          let Dominating1_VeryHigh = 0
                          let Dominating1_High = 0
    
                          let StrictlyLiteral1_VeryLow = 0
                          let StrictlyLiteral1_Low = 0
                          let StrictlyLiteral1_Medium = 0
                          let StrictlyLiteral1_VeryHigh = 0
                          let StrictlyLiteral1_High = 0
                          let Dreaming1_VeryLow = 0
                          let Dreaming1_Low = 0
                          let Dreaming1_Medium = 0
                          let Dreaming1_VeryHigh = 0
                          let Dreaming1_High = 0
    
                          let ColdlyLogical1_VeryLow = 0
                          let ColdlyLogical1_Low = 0
                          let ColdlyLogical1_Medium = 0
                          let ColdlyLogical1_VeryHigh = 0
                          let ColdlyLogical1_High = 0
                          let Amenable1_VeryLow = 0
                          let Amenable1_Low = 0
                          let Amenable1_Medium = 0
                          let Amenable1_VeryHigh = 0
                          let Amenable1_High = 0
    
                          let Pliable1_VeryLow = 0
                          let Pliable1_Low = 0
                          let Pliable1_Medium = 0
                          let Pliable1_VeryHigh = 0
                          let Pliable1_High = 0
                          let Conforming1_VeryLow = 0
                          let Conforming1_Low = 0
                          let Conforming1_Medium = 0
                          let Conforming1_VeryHigh = 0
                          let Conforming1_High = 0
    
                        for(let h=0; h<res.data.length; h++){
                          let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
                            // Emotionally Numb
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 0.8
                                }
    
                                if(low_percentile_score_ER <= 20) {
                                  EmotionallyNumb1_VeryHigh = EmotionallyNumb1_VeryHigh + 1
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  EmotionallyNumb1_High = EmotionallyNumb1_High + 1
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                  EmotionallyNumb1_Medium = EmotionallyNumb1_Medium + 1
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  EmotionallyNumb1_Low = EmotionallyNumb1_Low + 1
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  EmotionallyNumb1_VeryLow = EmotionallyNumb1_VeryLow + 1
                                }
    
                            // Emotive1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  Emotive1_VeryLow = Emotive1_VeryLow + 1
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  Emotive1_Low = Emotive1_Low + 1
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                  Emotive1_Medium = Emotive1_Medium + 1
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  Emotive1_High = Emotive1_High + 1
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  Emotive1_VeryHigh = Emotive1_VeryHigh + 1
                                }
    
                            // Disappearing1
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[h].percentile_E * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[h].percentile_E * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[h].percentile_E * 0.8
                                }
            
                                if(low_percentile_score_E <= 20) {
                                  Disappearing1_VeryHigh = Disappearing1_VeryHigh + 1
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  Disappearing1_High = Disappearing1_High + 1
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                  Disappearing1_Medium = Disappearing1_Medium + 1
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  Disappearing1_Low = Disappearing1_Low + 1
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  Disappearing1_VeryLow = Disappearing1_VeryLow + 1
                                }
    
                            // Dominating1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[h].percentile_E * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[h].percentile_E * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[h].percentile_E * 1.2
                                }
            
                                if(high_percentile_score_E <= 20) {
                                  Dominating1_VeryLow = Dominating1_VeryLow + 1
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  Dominating1_Low = Dominating1_Low + 1
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                  Dominating1_Medium = Dominating1_Medium + 1
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  Dominating1_High = Dominating1_High + 1
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  Dominating1_VeryHigh = Dominating1_VeryHigh + 1
                                }
                            // Strictly Literal
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[h].percentile_O * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[h].percentile_O * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[h].percentile_O * 0.8
                                }
            
                                if(low_percentile_score_O <= 20) {
                                  StrictlyLiteral1_VeryHigh = StrictlyLiteral1_VeryHigh + 1
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  StrictlyLiteral1_High = StrictlyLiteral1_High + 1
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                  StrictlyLiteral1_Medium = StrictlyLiteral1_Medium + 1
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  StrictlyLiteral1_Low = StrictlyLiteral1_Low + 1
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  StrictlyLiteral1_VeryLow = StrictlyLiteral1_VeryLow + 1
                                }
    
                            // Dreaming1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[h].percentile_O * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[h].percentile_O * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[h].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  Dreaming1_VeryLow = Dreaming1_VeryLow + 1
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  Dreaming1_Low = Dreaming1_Low + 1
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                  Dreaming1_Medium = Dreaming1_Medium + 1
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  Dreaming1_High = Dreaming1_High + 1
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  Dreaming1_VeryHigh = Dreaming1_VeryHigh + 1
                                }
    
                            // Cold Logical
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[h].percentile_A * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[h].percentile_A * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[h].percentile_A * 0.8
                                }
            
                                if(low_percentile_score_A <= 20) {
                                  ColdlyLogical1_VeryHigh = ColdlyLogical1_VeryHigh + 1
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  ColdlyLogical1_High = ColdlyLogical1_High + 1
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                  ColdlyLogical1_Medium = ColdlyLogical1_Medium + 1
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  ColdlyLogical1_Low = ColdlyLogical1_Low + 1
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  ColdlyLogical1_VeryLow = ColdlyLogical1_VeryLow + 1
                                }
    
                            // Amenable
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[h].percentile_A * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[h].percentile_A * 1.0
                                }
                                else if(res.data[h].percentile_ER== 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[h].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  Amenable1_VeryLow = Amenable1_VeryLow + 1
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  Amenable1_Low = Amenable1_Low + 1
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                  Amenable1_Medium = Amenable1_Medium + 1
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  Amenable1_High = Amenable1_High + 1
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  Amenable1_VeryHigh = Amenable1_VeryHigh + 1
                                }
    
                            // Pliable1
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[h].percentile_C * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[h].percentile_C * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[h].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  Pliable1_VeryHigh = Pliable1_VeryHigh + 1
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  Pliable1_High = Pliable1_High + 1
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                  Pliable1_Medium = Pliable1_Medium + 1
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  Pliable1_Low = Pliable1_Low + 1
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  Pliable1_VeryLow = Pliable1_VeryLow + 1
                                }
    
                            // Conforming1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[h].percentile_C * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[h].percentile_C * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[h].percentile_C * 1.2
                                }
            
                                if(high_percentile_score_C <= 20) {
                                  Conforming1_VeryLow = Conforming1_VeryLow + 1
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  Conforming1_Low = Conforming1_Low + 1
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                  Conforming1_Medium = Conforming1_Medium + 1
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  Conforming1_High = Conforming1_High + 1
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  Conforming1_VeryHigh = Conforming1_VeryHigh + 1
                                }
                          //end of loop
                        }
                        // print to Super Traits
                        $(".EmotionallyNumb_VeryLow").html(EmotionallyNumb1_VeryLow)
                        $(".EmotionallyNumb_Low").html(EmotionallyNumb1_Low)
                        $(".EmotionallyNumb_Medium").html(EmotionallyNumb1_Medium)
                        $(".EmotionallyNumb_High").html(EmotionallyNumb1_High)
                        $(".EmotionallyNumb_VeryHigh").html(EmotionallyNumb1_VeryHigh)
    
                        $(".Emotive_VeryLow").html(Emotive1_VeryLow)
                        $(".Emotive_Low").html(Emotive1_Low)
                        $(".Emotive_Medium").html(Emotive1_Medium)
                        $(".Emotive_High").html(Emotive1_High)
                        $(".Emotive_VeryHigh").html(Emotive1_VeryHigh)
    
                        $(".Disappearing_VeryLow").html(Disappearing1_VeryLow)
                        $(".Disappearing_Low").html(Disappearing1_Low)
                        $(".Disappearing_Medium").html(Disappearing1_Medium)
                        $(".Disappearing_High").html(Disappearing1_High)
                        $(".Disappearing_VeryHigh").html(Disappearing1_VeryHigh)
    
                        $(".Dominating_VeryLow").html(Dominating1_VeryLow)
                        $(".Dominating_Low").html(Dominating1_Low)
                        $(".Dominating_Medium").html(Dominating1_Medium)
                        $(".Dominating_High").html(Dominating1_High)
                        $(".Dominating_VeryHigh").html(Dominating1_VeryHigh)
    
                        $(".StrictlyLiteral_VeryLow").html(StrictlyLiteral1_VeryLow)
                        $(".StrictlyLiteral_Low").html(StrictlyLiteral1_Low)
                        $(".StrictlyLiteral_Medium").html(StrictlyLiteral1_Medium)
                        $(".StrictlyLiteral_High").html(StrictlyLiteral1_High)
                        $(".StrictlyLiteral_VeryHigh").html(StrictlyLiteral1_VeryHigh)
    
                        $(".Dreaming_VeryLow").html(Dreaming1_VeryLow)
                        $(".Dreaming_Low").html(Dreaming1_Low)
                        $(".Dreaming_Medium").html(Dreaming1_Medium)
                        $(".Dreaming_High").html(Dreaming1_High)
                        $(".Dreaming_VeryHigh").html(Dreaming1_VeryHigh)
    
                        $(".ColdlyLogical_VeryLow").html(ColdlyLogical1_VeryLow)
                        $(".ColdlyLogical_Low").html(ColdlyLogical1_Low)
                        $(".ColdlyLogical_Medium").html(ColdlyLogical1_Medium)
                        $(".ColdlyLogical_High").html(ColdlyLogical1_High)
                        $(".ColdlyLogical_VeryHigh").html(ColdlyLogical1_VeryHigh)
    
                        $(".Amenable_VeryLow").html(Amenable1_VeryLow)
                        $(".Amenable_Low").html(Amenable1_Low)
                        $(".Amenable_Medium").html(Amenable1_Medium)
                        $(".Amenable_High").html(Amenable1_High)
                        $(".Amenable_VeryHigh").html(Amenable1_VeryHigh)
    
                        $(".Pliable_VeryLow").html(Pliable1_VeryLow)
                        $(".Pliable_Low").html(Pliable1_Low)
                        $(".Pliable_Medium").html(Pliable1_Medium)
                        $(".Pliable_High").html(Pliable1_High)
                        $(".Pliable_VeryHigh").html(Pliable1_VeryHigh)
    
                        $(".Conforming_VeryLow").html(Conforming1_VeryLow)
                        $(".Conforming_Low").html(Conforming1_Low)
                        $(".Conforming_Medium").html(Conforming1_Medium)
                        $(".Conforming_High").html(Conforming1_High)
                        $(".Conforming_VeryHigh").html(Conforming1_VeryHigh)
                          ` 
                        }
                      }
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }
              // for pressure point coach report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Coach-PressurePoint-Role-results-Chart') { 
                  output += `
                  axios
                    .post(
                      '${url}/b5-coach-report-data',
                      {
                        "coach_id": coach_id,
                        "iteration_id": iteration_id,
                        "survey_template_id": survey_template_id,
                        "stream_id": stream_id,
                        "group_id": group_id
                      },
                      config                                            
                    )
                    .then((res) => {
                    `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i] 
                        if (data.Template === 'Coach-PressurePoint-Breakdown-Chart') { 
                          output +=
                          `
                          let EmotionallyNumb1_VeryLow = 0
                          let EmotionallyNumb1_Low = 0
                          let EmotionallyNumb1_Medium = 0
                          let EmotionallyNumb1_VeryHigh = 0
                          let EmotionallyNumb1_High= 0
                          let Emotive1_VeryLow = 0
                          let Emotive1_Low = 0
                          let Emotive1_Medium = 0
                          let Emotive1_VeryHigh = 0
                          let Emotive1_High = 0
    
                          let Disappearing1_VeryLow = 0
                          let Disappearing1_Low = 0
                          let Disappearing1_Medium = 0
                          let Disappearing1_VeryHigh = 0
                          let Disappearing1_High = 0
                          let Dominating1_VeryLow = 0
                          let Dominating1_Low = 0
                          let Dominating1_Medium = 0
                          let Dominating1_VeryHigh = 0
                          let Dominating1_High = 0
    
                          let StrictlyLiteral1_VeryLow = 0
                          let StrictlyLiteral1_Low = 0
                          let StrictlyLiteral1_Medium = 0
                          let StrictlyLiteral1_VeryHigh = 0
                          let StrictlyLiteral1_High = 0
                          let Dreaming1_VeryLow = 0
                          let Dreaming1_Low = 0
                          let Dreaming1_Medium = 0
                          let Dreaming1_VeryHigh = 0
                          let Dreaming1_High = 0
    
                          let ColdlyLogical1_VeryLow = 0
                          let ColdlyLogical1_Low = 0
                          let ColdlyLogical1_Medium = 0
                          let ColdlyLogical1_VeryHigh = 0
                          let ColdlyLogical1_High = 0
                          let Amenable1_VeryLow = 0
                          let Amenable1_Low = 0
                          let Amenable1_Medium = 0
                          let Amenable1_VeryHigh = 0
                          let Amenable1_High = 0
    
                          let Pliable1_VeryLow = 0
                          let Pliable1_Low = 0
                          let Pliable1_Medium = 0
                          let Pliable1_VeryHigh = 0
                          let Pliable1_High = 0
                          let Conforming1_VeryLow = 0
                          let Conforming1_Low = 0
                          let Conforming1_Medium = 0
                          let Conforming1_VeryHigh = 0
                          let Conforming1_High = 0
    
                        for(let h=0; h<res.data.length; h++){
                          let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
                            // Emotionally Numb
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[h].percentile_ER * 0.8
                                }
    
                                if(low_percentile_score_ER <= 20) {
                                  EmotionallyNumb1_VeryHigh = EmotionallyNumb1_VeryHigh + 1
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  EmotionallyNumb1_High = EmotionallyNumb1_High + 1
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                  EmotionallyNumb1_Medium = EmotionallyNumb1_Medium + 1
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  EmotionallyNumb1_Low = EmotionallyNumb1_Low + 1
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  EmotionallyNumb1_VeryLow = EmotionallyNumb1_VeryLow + 1
                                }
    
                            // Emotive1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[h].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  Emotive1_VeryLow = Emotive1_VeryLow + 1
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  Emotive1_Low = Emotive1_Low + 1
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                  Emotive1_Medium = Emotive1_Medium + 1
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  Emotive1_High = Emotive1_High + 1
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  Emotive1_VeryHigh = Emotive1_VeryHigh + 1
                                }
    
                            // Disappearing1
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[h].percentile_E * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[h].percentile_E * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[h].percentile_E * 0.8
                                }
            
                                if(low_percentile_score_E <= 20) {
                                  Disappearing1_VeryHigh = Disappearing1_VeryHigh + 1
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  Disappearing1_High = Disappearing1_High + 1
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                  Disappearing1_Medium = Disappearing1_Medium + 1
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  Disappearing1_Low = Disappearing1_Low + 1
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  Disappearing1_VeryLow = Disappearing1_VeryLow + 1
                                }
    
                            // Dominating1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[h].percentile_E * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[h].percentile_E * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[h].percentile_E * 1.2
                                }
            
                                if(high_percentile_score_E <= 20) {
                                  Dominating1_VeryLow = Dominating1_VeryLow + 1
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  Dominating1_Low = Dominating1_Low + 1
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                  Dominating1_Medium = Dominating1_Medium + 1
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  Dominating1_High = Dominating1_High + 1
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  Dominating1_VeryHigh = Dominating1_VeryHigh + 1
                                }
                            // Strictly Literal
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[h].percentile_O * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[h].percentile_O * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[h].percentile_O * 0.8
                                }
            
                                if(low_percentile_score_O <= 20) {
                                  StrictlyLiteral1_VeryHigh = StrictlyLiteral1_VeryHigh + 1
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  StrictlyLiteral1_High = StrictlyLiteral1_High + 1
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                  StrictlyLiteral1_Medium = StrictlyLiteral1_Medium + 1
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  StrictlyLiteral1_Low = StrictlyLiteral1_Low + 1
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  StrictlyLiteral1_VeryLow = StrictlyLiteral1_VeryLow + 1
                                }
    
                            // Dreaming1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[h].percentile_O * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[h].percentile_O * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[h].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  Dreaming1_VeryLow = Dreaming1_VeryLow + 1
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  Dreaming1_Low = Dreaming1_Low + 1
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                  Dreaming1_Medium = Dreaming1_Medium + 1
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  Dreaming1_High = Dreaming1_High + 1
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  Dreaming1_VeryHigh = Dreaming1_VeryHigh + 1
                                }
    
                            // Cold Logical
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[h].percentile_A * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[h].percentile_A * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[h].percentile_A * 0.8
                                }
            
                                if(low_percentile_score_A <= 20) {
                                  ColdlyLogical1_VeryHigh = ColdlyLogical1_VeryHigh + 1
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  ColdlyLogical1_High = ColdlyLogical1_High + 1
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                  ColdlyLogical1_Medium = ColdlyLogical1_Medium + 1
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  ColdlyLogical1_Low = ColdlyLogical1_Low + 1
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  ColdlyLogical1_VeryLow = ColdlyLogical1_VeryLow + 1
                                }
    
                            // Amenable
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[h].percentile_A * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[h].percentile_A * 1.0
                                }
                                else if(res.data[h].percentile_ER== 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[h].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  Amenable1_VeryLow = Amenable1_VeryLow + 1
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  Amenable1_Low = Amenable1_Low + 1
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                  Amenable1_Medium = Amenable1_Medium + 1
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  Amenable1_High = Amenable1_High + 1
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  Amenable1_VeryHigh = Amenable1_VeryHigh + 1
                                }
    
                            // Pliable1
                                if(res.data[h].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[h].percentile_C * 1.2
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[h].percentile_C * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[h].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  Pliable1_VeryHigh = Pliable1_VeryHigh + 1
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  Pliable1_High = Pliable1_High + 1
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                  Pliable1_Medium = Pliable1_Medium + 1
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  Pliable1_Low = Pliable1_Low + 1
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  Pliable1_VeryLow = Pliable1_VeryLow + 1
                                }
    
                            // Conforming1
                                if(res.data[h].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[h].percentile_C * 0.8
                                }
                                else if(res.data[h].percentile_ER == 20.1 || res.data[h].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[h].percentile_C * 1.0
                                }
                                else if(res.data[h].percentile_ER == 80 || res.data[h].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[h].percentile_C * 1.2
                                }
            
                                if(high_percentile_score_C <= 20) {
                                  Conforming1_VeryLow = Conforming1_VeryLow + 1
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  Conforming1_Low = Conforming1_Low + 1
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                  Conforming1_Medium = Conforming1_Medium + 1
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  Conforming1_High = Conforming1_High + 1
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  Conforming1_VeryHigh = Conforming1_VeryHigh + 1
                                }
                          //end of loop
                        }
                        // print to Super Traits
                        $(".EmotionallyNumb_VeryLow").html(EmotionallyNumb1_VeryLow)
                        $(".EmotionallyNumb_Low").html(EmotionallyNumb1_Low)
                        $(".EmotionallyNumb_Medium").html(EmotionallyNumb1_Medium)
                        $(".EmotionallyNumb_High").html(EmotionallyNumb1_High)
                        $(".EmotionallyNumb_VeryHigh").html(EmotionallyNumb1_VeryHigh)
    
                        $(".Emotive_VeryLow").html(Emotive1_VeryLow)
                        $(".Emotive_Low").html(Emotive1_Low)
                        $(".Emotive_Medium").html(Emotive1_Medium)
                        $(".Emotive_High").html(Emotive1_High)
                        $(".Emotive_VeryHigh").html(Emotive1_VeryHigh)
    
                        $(".Disappearing_VeryLow").html(Disappearing1_VeryLow)
                        $(".Disappearing_Low").html(Disappearing1_Low)
                        $(".Disappearing_Medium").html(Disappearing1_Medium)
                        $(".Disappearing_High").html(Disappearing1_High)
                        $(".Disappearing_VeryHigh").html(Disappearing1_VeryHigh)
    
                        $(".Dominating_VeryLow").html(Dominating1_VeryLow)
                        $(".Dominating_Low").html(Dominating1_Low)
                        $(".Dominating_Medium").html(Dominating1_Medium)
                        $(".Dominating_High").html(Dominating1_High)
                        $(".Dominating_VeryHigh").html(Dominating1_VeryHigh)
    
                        $(".StrictlyLiteral_VeryLow").html(StrictlyLiteral1_VeryLow)
                        $(".StrictlyLiteral_Low").html(StrictlyLiteral1_Low)
                        $(".StrictlyLiteral_Medium").html(StrictlyLiteral1_Medium)
                        $(".StrictlyLiteral_High").html(StrictlyLiteral1_High)
                        $(".StrictlyLiteral_VeryHigh").html(StrictlyLiteral1_VeryHigh)
    
                        $(".Dreaming_VeryLow").html(Dreaming1_VeryLow)
                        $(".Dreaming_Low").html(Dreaming1_Low)
                        $(".Dreaming_Medium").html(Dreaming1_Medium)
                        $(".Dreaming_High").html(Dreaming1_High)
                        $(".Dreaming_VeryHigh").html(Dreaming1_VeryHigh)
    
                        $(".ColdlyLogical_VeryLow").html(ColdlyLogical1_VeryLow)
                        $(".ColdlyLogical_Low").html(ColdlyLogical1_Low)
                        $(".ColdlyLogical_Medium").html(ColdlyLogical1_Medium)
                        $(".ColdlyLogical_High").html(ColdlyLogical1_High)
                        $(".ColdlyLogical_VeryHigh").html(ColdlyLogical1_VeryHigh)
    
                        $(".Amenable_VeryLow").html(Amenable1_VeryLow)
                        $(".Amenable_Low").html(Amenable1_Low)
                        $(".Amenable_Medium").html(Amenable1_Medium)
                        $(".Amenable_High").html(Amenable1_High)
                        $(".Amenable_VeryHigh").html(Amenable1_VeryHigh)
    
                        $(".Pliable_VeryLow").html(Pliable1_VeryLow)
                        $(".Pliable_Low").html(Pliable1_Low)
                        $(".Pliable_Medium").html(Pliable1_Medium)
                        $(".Pliable_High").html(Pliable1_High)
                        $(".Pliable_VeryHigh").html(Pliable1_VeryHigh)
    
                        $(".Conforming_VeryLow").html(Conforming1_VeryLow)
                        $(".Conforming_Low").html(Conforming1_Low)
                        $(".Conforming_Medium").html(Conforming1_Medium)
                        $(".Conforming_High").html(Conforming1_High)
                        $(".Conforming_VeryHigh").html(Conforming1_VeryHigh)
                          ` 
                        }
                        if (data.Template === 'Coach-PressurePoint-Link-Chart') { 
                          output += `
                          let EmotionallyNumb_VeryLow = 0
                          let EmotionallyNumb_Low = 0
                          let EmotionallyNumb_VeryHigh= 0
                          let EmotionallyNumb_High= 0
                          let Emotive_VeryLow = 0
                          let Emotive_Low = 0
                          let Emotive_VeryHigh = 0
                          let Emotive_High = 0
    
                          let Disappearing_VeryLow = 0
                          let Disappearing_Low = 0
                          let Disappearing_VeryHigh = 0
                          let Disappearing_High = 0
                          let Dominating_VeryLow = 0
                          let Dominating_Low = 0
                          let Dominating_VeryHigh = 0
                          let Dominating_High = 0
    
                          let StrictlyLiteral_VeryLow = 0
                          let StrictlyLiteral_Low = 0
                          let StrictlyLiteral_VeryHigh = 0
                          let StrictlyLiteral_High = 0
                          let Dreaming_VeryLow = 0
                          let Dreaming_Low = 0
                          let Dreaming_VeryHigh = 0
                          let Dreaming_High = 0
    
                          let ColdLogical_VeryLow = 0
                          let ColdLogical_Low = 0
                          let ColdLogical_VeryHigh = 0
                          let ColdLogical_High = 0
                          let Amendable_VeryLow = 0
                          let Amendable_Low = 0
                          let Amendable_VeryHigh = 0
                          let Amendable_High = 0
    
                          let Pliable_VeryLow = 0
                          let Pliable_Low = 0
                          let Pliable_VeryHigh = 0
                          let Pliable_High = 0
                          let Conforming_VeryLow = 0
                          let Conforming_Low = 0
                          let Conforming_VeryHigh = 0
                          let Conforming_High = 0
    
                        for(let b=0; b<res.data.length; b++){
                          let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
                            // Emotionally Numb
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[b].percentile_ER * 0.8
                                }
    
                                if(low_percentile_score_ER <= 20) {
                                  EmotionallyNumb_VeryHigh = EmotionallyNumb_VeryHigh + 1
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  EmotionallyNumb_High = EmotionallyNumb_High + 1
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  EmotionallyNumb_Low = EmotionallyNumb_Low + 1
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  EmotionallyNumb_VeryLow = EmotionallyNumb_VeryLow + 1
                                }
    
                            // Emotive
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[b].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  Emotive_VeryLow = Emotive_VeryLow + 1
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  Emotive_Low = Emotive_Low + 1
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  Emotive_High = Emotive_High + 1
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  Emotive_VeryHigh = Emotive_VeryHigh + 1
                                }
    
                            // Disappearing
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[b].percentile_E * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[b].percentile_E * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[b].percentile_E * 0.8
                                }
            
                                if(low_percentile_score_E <= 20) {
                                  Disappearing_VeryHigh = Disappearing_VeryHigh + 1
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  Disappearing_High = Disappearing_High + 1
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  Disappearing_Low = Disappearing_Low + 1
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  Disappearing_VeryLow = Disappearing_VeryLow + 1
                                }
    
                            // Dominating
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[b].percentile_E * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[b].percentile_E * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[b].percentile_E * 1.2
                                }
            
                                if(high_percentile_score_E <= 20) {
                                  Dominating_VeryLow = Dominating_VeryLow + 1
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  Dominating_Low = Dominating_Low + 1
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  Dominating_High = Dominating_High + 1
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  Dominating_VeryHigh = Dominating_VeryHigh + 1
                                }
                            // Strictly Literal
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[b].percentile_O * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[b].percentile_O * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[b].percentile_O * 0.8
                                }
            
                                if(low_percentile_score_O <= 20) {
                                  StrictlyLiteral_VeryHigh = StrictlyLiteral_VeryHigh + 1
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  StrictlyLiteral_High = StrictlyLiteral_High + 1
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  StrictlyLiteral_Low = StrictlyLiteral_Low + 1
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  StrictlyLiteral_VeryLow = StrictlyLiteral_VeryLow + 1
                                }
    
                            // Dreaming
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[b].percentile_O * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[b].percentile_O * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[b].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  Dreaming_VeryLow = Dreaming_VeryLow + 1
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  Dreaming_Low = Dreaming_Low + 1
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  Dreaming_High = Dreaming_High + 1
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  Dreaming_VeryHigh = Dreaming_VeryHigh + 1
                                }
    
                            // Cold Logical
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[b].percentile_A * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[b].percentile_A * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[b].percentile_A * 0.8
                                }
            
                                if(low_percentile_score_A <= 20) {
                                  ColdLogical_VeryHigh = ColdLogical_VeryHigh + 1
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  ColdLogical_High = ColdLogical_High + 1
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  ColdLogical_Low = ColdLogical_Low + 1
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  ColdLogical_VeryLow = ColdLogical_VeryLow + 1
                                }
    
                            // Amenable
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[b].percentile_A * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[b].percentile_A * 1.0
                                }
                                else if(res.data[b].percentile_ER== 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[b].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  Amendable_VeryLow = Amendable_VeryLow + 1
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  Amendable_Low = Amendable_Low + 1
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  Amendable_High = Amendable_High + 1
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  Amendable_VeryHigh = Amendable_VeryHigh + 1
                                }
    
                            // Pliable
                                if(res.data[b].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[b].percentile_C * 1.2
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[b].percentile_C * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[b].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  Pliable_VeryHigh = Pliable_VeryHigh + 1
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  Pliable_High = Pliable_High + 1
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  Pliable_Low = Pliable_Low + 1
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  Pliable_VeryLow = Pliable_VeryLow + 1
                                }
    
                            // Conforming
                                if(res.data[b].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[b].percentile_C * 0.8
                                }
                                else if(res.data[b].percentile_ER == 20.1 || res.data[b].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[b].percentile_C * 1.0
                                }
                                else if(res.data[b].percentile_ER == 80 || res.data[b].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[b].percentile_C * 1.2
                                }
            
                                if(high_percentile_score_C <= 20) {
                                  Conforming_VeryLow = Conforming_VeryLow + 1
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  Conforming_Low = Conforming_Low + 1
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  Conforming_High = Conforming_High + 1
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  Conforming_VeryHigh = Conforming_VeryHigh + 1
                                }
                          //end of loop
                        }
                        // print to Super Traits
                        $(".Total_ER_VeryLow").html("Total: " + EmotionallyNumb_VeryLow)
                        $(".Total_ER_Low").html("Total: " + EmotionallyNumb_Low)
                        $(".Total_ER_VeryHigh").html("Total: " + Emotive_VeryHigh)
                        $(".Total_ER_High").html("Total: " + Emotive_High)
    
                        $(".Total_E_VeryLow").html("Total: " + Disappearing_VeryLow)
                        $(".Total_E_Low").html("Total: " + Disappearing_Low)
                        $(".Total_E_VeryHigh").html("Total: " + Dominating_VeryHigh)
                        $(".Total_E_High").html("Total: " + Dominating_High)
    
                        $(".Total_O_VeryLow").html("Total: " + StrictlyLiteral_VeryLow)
                        $(".Total_O_Low").html("Total: " + StrictlyLiteral_Low)
                        $(".Total_O_VeryHigh").html("Total: " + Dreaming_VeryHigh)
                        $(".Total_O_High").html("Total: " + Dreaming_High)
    
                        $(".Total_A_VeryLow").html("Total: " + ColdLogical_VeryLow)
                        $(".Total_A_Low").html("Total: " + ColdLogical_Low)
                        $(".Total_A_VeryHigh").html("Total: " + Amendable_VeryHigh)
                        $(".Total_A_High").html("Total: " + Amendable_High)
    
                        $(".Total_C_VeryLow").html("Total: " + Pliable_VeryLow)
                        $(".Total_C_Low").html("Total: " + Pliable_Low)
                        $(".Total_C_VeryHigh").html("Total: " + Conforming_VeryHigh)
                        $(".Total_C_High").html("Total: " + Conforming_High)
                          `
                        }
                        if (data.Template === 'Coach-PressurePoint-Role-results-Chart') { 
                          output += `
                          for(let j=0; j<res.data.length; j++){
                            $(".person"+j+"_Initials").html(res.data[j].first_name.substring(0,1) + ". " + res.data[j].last_name.substring(0,1) + ".")
                            $(".coach-PPP-person"+j).removeClass("d-none")
    
                            let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
                            // Emotionally Numb
                                if(res.data[j].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[j].percentile_ER * 1.2
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[j].percentile_ER * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[j].percentile_ER * 0.8
                                }
    
                                if(low_percentile_score_ER <= 20) {
                                  $(".person"+j+"_EmotionallyNumb").html("VH")
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  $(".person"+j+"_EmotionallyNumb").html("H")
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                  $(".person"+j+"_EmotionallyNumb").html("T")
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  $(".person"+j+"_EmotionallyNumb").html("L")
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  $(".person"+j+"_EmotionallyNumb").html("VL")
                                }
    
                            // Emotive
                                if(res.data[j].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[j].percentile_ER * 0.8
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[j].percentile_ER * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[j].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  $(".person"+j+"_Emotive").html("VL")
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  $(".person"+j+"_Emotive").html("L")
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                  $(".person"+j+"_Emotive").html("T")
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  $(".person"+j+"_Emotive").html("H")
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  $(".person"+j+"_Emotive").html("VH")
                                }
    
                            // Disappearing
                                if(res.data[j].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[j].percentile_E * 1.2
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[j].percentile_E * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[j].percentile_E * 0.8
                                }
            
                                if(low_percentile_score_E <= 20) {
                                  $(".person"+j+"_Disappearing").html("VH")
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  $(".person"+j+"_Disappearing").html("H")
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                  $(".person"+j+"_Disappearing").html("T")
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  $(".person"+j+"_Disappearing").html("L")
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  $(".person"+j+"_Disappearing").html("VL")
                                }
    
                            // Dominating
                                if(res.data[j].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[j].percentile_E * 0.8
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[j].percentile_E * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[j].percentile_E * 1.2
                                }
            
                                if(high_percentile_score_E <= 20) {
                                  $(".person"+j+"_Dominating").html("VL")
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  $(".person"+j+"_Dominating").html("L")
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                  $(".person"+j+"_Dominating").html("T")
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  $(".person"+j+"_Dominating").html("H")
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  $(".person"+j+"_Dominating").html("VH")
                                }
                            // Strictly Literal
                                if(res.data[j].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[j].percentile_O * 1.2
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[j].percentile_O * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[j].percentile_O * 0.8
                                }
            
                                if(low_percentile_score_O <= 20) {
                                  $(".person"+j+"_StrictlyLiteral").html("VH")
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  $(".person"+j+"_StrictlyLiteral").html("H")
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                  $(".person"+j+"_StrictlyLiteral").html("T")
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  $(".person"+j+"_StrictlyLiteral").html("L")
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  $(".person"+j+"_StrictlyLiteral").html("VL")
                                }
    
                            // Dreaming
                                if(res.data[j].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[j].percentile_O * 0.8
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[j].percentile_O * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[j].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  $(".person"+j+"_Dreaming").html("VL")
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  $(".person"+j+"_Dreaming").html("L")
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                  $(".person"+j+"_Dreaming").html("T")
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  $(".person"+j+"_Dreaming").html("H")
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  $(".person"+j+"_Dreaming").html("VH")
                                }
    
                            // Coldly Logical
                                if(res.data[j].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[j].percentile_A * 1.2
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[j].percentile_A * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[j].percentile_A * 0.8
                                }
            
                                if(low_percentile_score_A <= 20) {
                                  $(".person"+j+"_ColdlyLogical").html("VH")
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  $(".person"+j+"_ColdlyLogical").html("H")
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                  $(".person"+j+"_ColdlyLogical").html("T")
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  $(".person"+j+"_ColdlyLogical").html("L")
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  $(".person"+j+"_ColdlyLogical").html("VL")
                                }
    
                            // Amenable
                                if(res.data[j].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[j].percentile_A * 0.8
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[j].percentile_A * 1.0
                                }
                                else if(res.data[j].percentile_ER== 80 || res.data[j].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[j].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  $(".person"+j+"_Amenable").html("VL")
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  $(".person"+j+"_Amenable").html("L")
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                  $(".person"+j+"_Amenable").html("T")
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  $(".person"+j+"_Amenable").html("H")
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  $(".person"+j+"_Amenable").html("VH")
                                }
    
                            // Pliable
                                if(res.data[j].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[j].percentile_C * 1.2
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[j].percentile_C * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[j].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  $(".person"+j+"_Pliable").html("VH")
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  $(".person"+j+"_Pliable").html("H")
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                  $(".person"+j+"_Pliable").html("T")
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  $(".person"+j+"_Pliable").html("L")
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  $(".person"+j+"_Pliable").html("VL")
                                }
    
                            // Conforming
                                if(res.data[j].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[j].percentile_C * 0.8
                                }
                                else if(res.data[j].percentile_ER == 20.1 || res.data[j].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[j].percentile_C * 1.0
                                }
                                else if(res.data[j].percentile_ER == 80 || res.data[j].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[j].percentile_C * 1.2
                                }
            
                                if(high_percentile_score_C <= 20) {
                                  $(".person"+j+"_Conforming").html("VL")
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  $(".person"+j+"_Conforming").html("L")
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                  $(".person"+j+"_Conforming").html("T")
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  $(".person"+j+"_Conforming").html("H")
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  $(".person"+j+"_Conforming").html("VH")
                                }
                          }
                          `
                        }
                      }
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }
      
              // for 360 coach report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Line-Chart-360-Coach-Report') { 
                  output += `
                    axios
                      .post(
                        '${url}/r360-coach-report-data',
                        {
                          coach_id: coach_id,
                          iteration_id: iteration_id,
                          stream_id: stream_id,
                          group_id: group_id,
                          survey_template_id: survey_template_id
                        },
                        config                                            
                      )
                      .then((res) => {
                        console.log(res.data)
                        `
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
    
                            // Section 1
                            if (data.Template === 'Line-Chart-360-Coach-Report') { 
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += `
                                  // table
                                  for(let i=0; i<res.data.length; i++) {
                                    $('.line-chart-report-360-initial-'+i).removeClass('d-none')
                                    $('.line-chart-report-360-initial-'+i).text(res.data[i].fname+ '.' +res.data[i].lname+ '.')
    
                                    // psup
                                    $(".line-chart-report-360-psup-" +i).text(Math.abs(res.data[i].psup1_N).toFixed(1) ? Math.abs(res.data[i].psup1_N).toFixed(1) : 0)
                                    $(".line-chart-report-360-respondent-" +i).text(Math.abs(res.data[i].PDIE998_N).toFixed(1) ? Math.abs(res.data[i].PDIE998_N).toFixed(1) : 0)
    
                                    $(".line-chart-report-360-psup-" +i).removeClass('d-none')
                                    $(".line-chart-report-360-respondent-" +i).removeClass('d-none')
                                  }
    
    
                                  // line chart
                                  if(res.data.length >= 6) {
                                  `
                                    output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.SixPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                  output += `
                                  }
                                  else if(res.data.length == 5) {
                                  `
                                      output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.FivePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                    output += `
                                  }
                                  else if(res.data.length == 4) {
                                    `
                                      output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.FourPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                    output += `
                                  }
                                  else if(res.data.length == 3) {
                                    `
                                      output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.ThreePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                    output += `
                                  }
                                  else if(res.data.length == 2) {
                                    `
                                      output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.TwoPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                    output += `
                                  }
                                  else if(res.data.length == 1) {
                                    `
                                      output += `
                                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                        type: 'line',
                                        data: {
                                          labels: [
                                    `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "` + response.Description + `",
                                              `
                                            })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.OnePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "#`+response+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: false,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          responsive: true,
                                          indexAxis: 'y',
                                          plugins: {
                                              legend : {
                                                  display: true,
                                                  position: "bottom"
                                              }
                                          },
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: 'High level competency scores',
                                                      color: '#000',
                                                      font: {
                                                          family: 'Open Sans',
                                                          size: 20,
                                                          weight: 'bold',
                                                      }
                                                  },
                                                  position : "top",
                                                  min: 0,
                                                  max: 5,
                                                  ticks: {
                                                      stepSize: 1
                                                  }
                                              }
                                          },
                                        }
                                      })
                                    `
                                    output += `
                                  }
                                  $('.line-chart-360-coach-report-canvas').removeClass('d-none')
                                  $('.line-chart-360-coach-report-spinner').addClass('d-none')
                                `
                              }
                            }
                            // Section 2-7
                            if (data.Template === 'Chart-360-Summary-Coach-Report') { 
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                res.Elements.forEach((response) => {
                                  output += `
                                    for(let i=0; i<res.data.length; i++) {
                                      $('.coach-report-360-label-'+i).removeClass('d-none')
    
                                      $('.coach-report-360-initial-'+i).removeClass('d-none')
                                      $('.coach-report-360-initial-'+i).text(res.data[i].fname+ '.' +res.data[i].lname+ '.')
    
                                      // my overall score
                                      $(".coach-report-360-psup-score-" +i+ "-`+response.Trait+`").text(Math.abs(res.data[i].psup1_`+response.Trait+`).toFixed(1) ? Math.abs(res.data[i].psup1_`+response.Trait+`).toFixed(1) : 0)
                                      $(".coach-report-360-all-score-" +i+ "-`+response.Trait+`").text(Math.abs(res.data[i].PDIE998_`+response.Trait+`).toFixed(1) ? Math.abs(res.data[i].PDIE998_`+response.Trait+`).toFixed(1) : 0)
                                      $(".coach-report-360-self-score-" +i+ "-`+response.Trait+`").text(Math.abs(res.data[i].ind_`+response.Trait+`).toFixed(1) ? Math.abs(res.data[i].ind_`+response.Trait+`).toFixed(1) : 0)
    
                                      // show the data of my overall score 
                                      $(".coach-report-360-psup-score-" +i+ "-`+response.Trait+`").removeClass('d-none')
                                      $(".coach-report-360-all-score-" +i+ "-`+response.Trait+`").removeClass('d-none')
                                      $(".coach-report-360-self-score-" +i+ "-`+response.Trait+`").removeClass('d-none')
                                    }
    
                                    $('.chart-360-summary-coach-report-table').removeClass('d-none')
                                    $('.chart-360-summary-coach-report-spinner').addClass('d-none')
                                  `
                                })
                              }
                            }
                            // Section 8
                            if (data.Template === 'Radar-Chart-360-Coach-Report') { 
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                               
                                output += `
                                if(res.data.length >= 6) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.SixPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                if(res.data.length == 5) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.FivePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                if(res.data.length == 4) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.FourPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                if(res.data.length == 3) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.ThreePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                if(res.data.length == 2) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.TwoPeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                if(res.data.length == 1) {
                                  `
                                    output += `
                                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart($('.` + res.SuperTrait + `` + res.Title + `'), {
                                        type: 'radar',
                                        data: {
                                          labels: [
                                    `
                                          res.Elements.forEach((response) => {
                                            output += `
                                              "`+response.Description+`",
                                            `
                                          })
                                    output += `
                                          ],
                                          datasets: [
                                    `
                                              res.OnePeoples.forEach((response, index) => {
                                                output += `
                                                  { 
                                                    label: [
                                                      res.data[`+index+`].fname+ '.' +res.data[`+index+`].lname+ '.',
                                                    ],
                                                    backgroundColor: "`+response.rgb+`",
                                                    pointBackgroundColor: 'gray',
                                                    borderColor: "#`+response.hex+`",
                                                    data: [
                                                    `
                                                      res.Elements.forEach((response) => {
                                                        output += `
                                                          Math.abs(res.data[`+index+`].ind_`+response.Trait+`).toFixed(1),
                                                        `
                                                      })
                                                    output += `
                                                    ],
                                                    fill: true,
                                                  },
                                                `
                                              })
                                      output+=`
                                          ],
                                        },
                                        options: {
                                          scales: {
                                            r: {
                                              beginAtZero: true,
                                              min: 0,
                                              max: 5,
                                              ticks: {
                                              stepSize: 1,
                                              }
                                            },
                                          }
                                        }
                                      })
                                    `
                                  output += `
                                }
                                
    



                                  $('.radar-chart-360-coach-report-table').removeClass('d-none')
                                  $('.radar-chart-360-coach-report-spinner').addClass('d-none')
                                `
                              }
                            }
                            // Section 9 
                            if(data.Template === 'Table-360-Coach-Report-Competency') {
                              output += `
                                function processOrdinalLevels(dataArray) {
                                  // Check if the array contains only null values
                                  if (dataArray.every((value) => value === null)) {
                                    return Array(dataArray.length).fill('N/A');
                                  }

                                  // Count the occurrences of each number in the array
                                  const numberCounts = {};
                                  for (const num of dataArray) {
                                    if (num in numberCounts) {
                                      numberCounts[num]++;
                                    } else {
                                      numberCounts[num] = 1;
                                    }
                                  }

                                  // Create a sorted array of unique numbers in ascending order
                                  const uniqueSortedNumbers = Array.from(new Set(dataArray)).sort((a, b) => a - b);

                                  // Create a mapping of numbers to their levels based on their order
                                  const numberToLevel = {};
                                  let currentLevel = 1;

                                  for (const num of uniqueSortedNumbers) {
                                    numberToLevel[num] = currentLevel;
                                    currentLevel += numberCounts[num];
                                  }

                                  // Function to add the appropriate ordinal suffix or replace with "N/A" for null values
                                  function addOrdinalSuffix(num) {
                                    if (num === null) return 'N/A'; // Replace null with "N/A"
                                    if (num === 1) return '1st';
                                    if (num === 2) return '2nd';
                                    if (num === 3) return '3rd';
                                    return num + 'th';
                                  }

                                  // Assign levels with correct ordinal suffix or "N/A" for null values to the original array
                                  const levelsAsOrdinals = dataArray.map((num) => addOrdinalSuffix(numberToLevel[num]));

                                  // Count the occurrences of each ordinal level
                                  const levelCounts = {};

                                  for (const level of levelsAsOrdinals) {
                                    if (levelCounts[level]) {
                                      levelCounts[level]++;
                                    } else {
                                      levelCounts[level] = 1;
                                    }
                                  }

                                  // Replace all "1st" with "=1st" if there are more than one "1st"
                                  const firstCount = levelCounts['1st'];
                                  if (firstCount > 1) {
                                    for (let j = 0; j < levelsAsOrdinals.length; j++) {
                                      if (levelsAsOrdinals[j] === '1st') {
                                        levelsAsOrdinals[j] = '=1st';
                                      }
                                    }
                                  }

                                  // Replace ordinal levels if they occur more than once up to 20th
                                  for (let i = 1; i <= 20; i++) {
                                    const ordinal = i + 'th';
                                    if (levelCounts[ordinal] > 1) {
                                      for (let j = 0; j < levelsAsOrdinals.length; j++) {
                                        if (levelsAsOrdinals[j] === ordinal) {
                                          levelsAsOrdinals[j] = '=' + ordinal;
                                        }
                                      }
                                    }
                                  }
                                  return levelsAsOrdinals;
                                }

                                let psup = [];
                                let respondents = [];
                                for (let i = 0; i < res.data.length; i++) {
                                  psup[i] = [];
                                  respondents[i] = [];
                                }
                              `
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                res.Elements.forEach((response) => {
                                  output += `
                                    for(let i=0; i<res.data.length; i++) {
                                      psup[i].push(res.data[0].psup1_` + response.Trait + `)
                                      respondents[i].push(res.data[0].all_nom999_` + response.Trait + `)

                                      $('.table-360-coach-report-competency-initial-'+i).text(res.data[i].fname+ '.' +res.data[i].lname+ '.')
    
                                      $(".table-360-coach-report-competency-initial-" +i).removeClass('d-none')
                                      $(".table-360-coach-report-competency-label-" +i).removeClass('d-none')
                                      $(".table-360-coach-report-competency-psup-score-" +i+ "-`+response.Trait+`").removeClass('d-none')
                                      $(".table-360-coach-report-competency-respondent-score-" +i+ "-`+response.Trait+`").removeClass('d-none')
                                      $(".table-360-coach-report-competency-label-" +i+ "-`+response.Trait+`").removeClass('d-none')

                                      if(res.data[i].psup1_` + response.Trait + ` === null || res.data[i].psup1_` + response.Trait + ` == 0){
                                          $(".table-360-coach-report-competency-psup-score-" +i+ "-`+response.Trait+`").html("N/A")
                                      }

                                      if(res.data[i].all_nom999_` + response.Trait + ` === null || res.data[i].all_nom999_` + response.Trait + ` == 0){
                                          $(".table-360-coach-report-competency-respondent-score-" +i+ "-`+response.Trait+`").html("N/A")
                                      }
                                    }
                                    $('.table-360-coach-report-competency-table').removeClass('d-none')
                                    $('.table-360-coach-report-competency-spinner').addClass('d-none')
                                  `
                                })
                                output +=
                              `                
                                let Newpsup = [];
                                let Newrespondents = [];

                                for (let i = 0; i < res.data.length; i++) {
                                  Newpsup[i] = processOrdinalLevels(psup[i])
                                  Newrespondents[i] = processOrdinalLevels(respondents[i])
                                }
                              `
                              for (const res1 of obj) {
                                res1.Elements.forEach((response, index) => {
                                  output += `
                                  for(let i=0; i<res.data.length; i++) {
                                    $(".table-360-coach-report-competency-psup-score-" +i+ "-`+response.Trait+`").html(Newpsup[i][${index}])
                                    $(".table-360-coach-report-competency-respondent-score-" +i+ "-`+response.Trait+`").html(Newrespondents[i][${index}])
                                  }
                                  `
                                }
                              )}
                              }
                            }
                            
                          }
                        output += `
                      })
                  `
                }
              }

              // for 360 faculty report histogram
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Add-Histogram-Faculty') { 
                  output += `
                  axios
                    .post(` + '`' + `${url}` + '/r360-histogram-report-endpoint-faculty/${iteration_id}' + '`, {}, config)' + `
                    .then((resHistogram) => {
                      console.log(resHistogram.data)
                    `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Add-Histogram-Faculty') { 
                          output += `
                            function removeElementsWithValue(arr, val) {
                                var i = arr.length;
                                while (i--) {
                                    if (arr[i] === val) {
                                        arr.splice(i, 1);
                                    }
                                }
                                return arr;
                            }
                            function rankRange(num, arr) {
                              // remove duplicate values
                              let newArr = [...new Set(removeElementsWithValue(arr, 0))];
                              // sort array
                              newArr.sort((a, b) => a - b);
                              // find the index of the input number in the sorted array
                              let index = newArr.indexOf(num);
                              // calculate the rank as the index + 1
                              let rank = index + 1;
                              // calculate the range as a percentage of the rank divided by the length of the array
                              let range = (rank / newArr.length) * 100;
                              // return the range capped at 100
                              return Math.min(range, 100);
                            }
                          `
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                                  //cohort array
                                  let arrayCohort`+ response.Trait +`_Self = []
                                  let arrayCohort`+ response.Trait +`_PSup = []
                                  let arrayCohort`+ response.Trait +`_P = []
                                  let arrayCohort`+ response.Trait +`_D = []
                                  let arrayCohort`+ response.Trait +`_I = []
                                  let arrayCohort`+ response.Trait +`_E = []
                                  let arrayCohort`+ response.Trait +`_ALL = []

                                  // cohort score 1
                                  let cohortScore1`+ response.Trait +`_Self = 0
                                  let cohortScore1`+ response.Trait +`_PSup = 0
                                  let cohortScore1`+ response.Trait +`_P = 0
                                  let cohortScore1`+ response.Trait +`_D = 0
                                  let cohortScore1`+ response.Trait +`_I = 0
                                  let cohortScore1`+ response.Trait +`_E = 0
                                  let cohortScore1`+ response.Trait +`_ALL = 0
                                  // cohort score 2
                                  let cohortScore2`+ response.Trait +`_Self = 0
                                  let cohortScore2`+ response.Trait +`_PSup = 0
                                  let cohortScore2`+ response.Trait +`_P = 0
                                  let cohortScore2`+ response.Trait +`_D = 0
                                  let cohortScore2`+ response.Trait +`_I = 0
                                  let cohortScore2`+ response.Trait +`_E = 0
                                  let cohortScore2`+ response.Trait +`_ALL = 0
                                  // cohort score 3
                                  let cohortScore3`+ response.Trait +`_Self = 0
                                  let cohortScore3`+ response.Trait +`_PSup = 0
                                  let cohortScore3`+ response.Trait +`_P = 0
                                  let cohortScore3`+ response.Trait +`_D = 0
                                  let cohortScore3`+ response.Trait +`_I = 0
                                  let cohortScore3`+ response.Trait +`_E = 0
                                  let cohortScore3`+ response.Trait +`_ALL = 0
                                  // cohort score 4
                                  let cohortScore4`+ response.Trait +`_Self = 0
                                  let cohortScore4`+ response.Trait +`_PSup = 0
                                  let cohortScore4`+ response.Trait +`_P = 0
                                  let cohortScore4`+ response.Trait +`_D = 0
                                  let cohortScore4`+ response.Trait +`_I = 0
                                  let cohortScore4`+ response.Trait +`_E = 0
                                  let cohortScore4`+ response.Trait +`_ALL = 0
                                  // cohort score 5
                                  let cohortScore5`+ response.Trait +`_Self = 0
                                  let cohortScore5`+ response.Trait +`_PSup = 0
                                  let cohortScore5`+ response.Trait +`_P = 0
                                  let cohortScore5`+ response.Trait +`_D = 0
                                  let cohortScore5`+ response.Trait +`_I = 0
                                  let cohortScore5`+ response.Trait +`_E = 0
                                  let cohortScore5`+ response.Trait +`_ALL = 0

                                  for (let i=0; i<resHistogram.data.length; i++){
                                    // get the score 1
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore1`+ response.Trait +`_ALL = resHistogram.data[i].qty
                                    }

                                    // get the score 2
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore2`+ response.Trait +`_ALL = resHistogram.data[i].qty
                                    }

                                    // get the score 3
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore3`+ response.Trait +`_ALL = resHistogram.data[i].qty
                                    }

                                    // get the score 4
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore4`+ response.Trait +`_ALL = resHistogram.data[i].qty
                                    }

                                    // get the score 5
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_Self = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_PSup = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_P = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_D = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_I = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_E = resHistogram.data[i].qty
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      cohortScore5`+ response.Trait +`_ALL = resHistogram.data[i].qty
                                    }

                                    // put values into respective arrays
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_Self.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_PSup.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_P.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_D.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_I.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_E.push(resHistogram.data[i].qty)
                                    }
                                    if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1000 && resHistogram.data[i].survey_assignment_id == 0)){
                                      arrayCohort`+ response.Trait +`_ALL.push(resHistogram.data[i].qty)
                                    }
                                  }

                                  arrayCohort`+ response.Trait +`_Self.push(cohortScore1`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_Self)
                                  arrayCohort`+ response.Trait +`_PSup.push(cohortScore1`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PSup)
                                  arrayCohort`+ response.Trait +`_P.push(cohortScore1`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_P)
                                  arrayCohort`+ response.Trait +`_D.push(cohortScore1`+ response.Trait +`_D + cohortScore2`+ response.Trait +`_D + cohortScore3`+ response.Trait +`_D + cohortScore4`+ response.Trait +`_D + cohortScore5`+ response.Trait +`_D)
                                  arrayCohort`+ response.Trait +`_I.push(cohortScore1`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_I)
                                  arrayCohort`+ response.Trait +`_E.push(cohortScore1`+ response.Trait +`_E + cohortScore2`+ response.Trait +`_E + cohortScore3`+ response.Trait +`_E + cohortScore4`+ response.Trait +`_E + cohortScore5`+ response.Trait +`_E)
                                  arrayCohort`+ response.Trait +`_ALL.push(cohortScore1`+ response.Trait +`_ALL + cohortScore2`+ response.Trait +`_ALL + cohortScore3`+ response.Trait +`_ALL + cohortScore4`+ response.Trait +`_ALL + cohortScore5`+ response.Trait +`_ALL)
                                
                                  //self
                                  let histogramScore1_`+ response.Trait +`_Self = rankRange(cohortScore1`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore2_`+ response.Trait +`_Self = rankRange(cohortScore2`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore3_`+ response.Trait +`_Self = rankRange(cohortScore3`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore4_`+ response.Trait +`_Self = rankRange(cohortScore4`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  let histogramScore5_`+ response.Trait +`_Self = rankRange(cohortScore5`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-Self").css('height', histogramScore1_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score2-`+ response.Trait +`-Self").css('height', histogramScore2_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score3-`+ response.Trait +`-Self").css('height', histogramScore3_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score4-`+ response.Trait +`-Self").css('height', histogramScore4_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score5-`+ response.Trait +`-Self").css('height', histogramScore5_`+ response.Trait +`_Self +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-Self").css('height', histogramScore1_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Self").css('height', histogramScore2_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Self").css('height', histogramScore3_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Self").css('height', histogramScore4_`+ response.Trait +`_Self +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Self").css('height', histogramScore5_`+ response.Trait +`_Self +'%');

                                  //psup
                                  let histogramScore1_`+ response.Trait +`_PSup = rankRange(cohortScore1`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore2_`+ response.Trait +`_PSup = rankRange(cohortScore2`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore3_`+ response.Trait +`_PSup = rankRange(cohortScore3`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore4_`+ response.Trait +`_PSup = rankRange(cohortScore4`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                                  let histogramScore5_`+ response.Trait +`_PSup = rankRange(cohortScore5`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)

                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-Psup").css('height', histogramScore1_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score2-`+ response.Trait +`-Psup").css('height', histogramScore2_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score3-`+ response.Trait +`-Psup").css('height', histogramScore3_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score4-`+ response.Trait +`-Psup").css('height', histogramScore4_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score5-`+ response.Trait +`-Psup").css('height', histogramScore5_`+ response.Trait +`_PSup +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-Psup").css('height', histogramScore1_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-Psup").css('height', histogramScore2_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-Psup").css('height', histogramScore3_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-Psup").css('height', histogramScore4_`+ response.Trait +`_PSup +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-Psup").css('height', histogramScore5_`+ response.Trait +`_PSup +'%');

                                  //peers
                                  let histogramScore1_`+ response.Trait +`_P = rankRange(cohortScore1`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore2_`+ response.Trait +`_P = rankRange(cohortScore2`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore3_`+ response.Trait +`_P = rankRange(cohortScore3`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore4_`+ response.Trait +`_P = rankRange(cohortScore4`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  let histogramScore5_`+ response.Trait +`_P = rankRange(cohortScore5`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-P").css('height', histogramScore1_`+ response.Trait +`_P +'%');
                                  $(".histogram-score2-`+ response.Trait +`-P").css('height', histogramScore2_`+ response.Trait +`_P +'%');
                                  $(".histogram-score3-`+ response.Trait +`-P").css('height', histogramScore3_`+ response.Trait +`_P +'%');
                                  $(".histogram-score4-`+ response.Trait +`-P").css('height', histogramScore4_`+ response.Trait +`_P +'%');
                                  $(".histogram-score5-`+ response.Trait +`-P").css('height', histogramScore5_`+ response.Trait +`_P +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-P").css('height', histogramScore1_`+ response.Trait +`_P +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-P").css('height', histogramScore2_`+ response.Trait +`_P +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-P").css('height', histogramScore3_`+ response.Trait +`_P +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-P").css('height', histogramScore4_`+ response.Trait +`_P +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-P").css('height', histogramScore5_`+ response.Trait +`_P +'%');

                                  //direct reports
                                  let histogramScore1_`+ response.Trait +`_D = rankRange(cohortScore1`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore2_`+ response.Trait +`_D = rankRange(cohortScore2`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore3_`+ response.Trait +`_D = rankRange(cohortScore3`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore4_`+ response.Trait +`_D = rankRange(cohortScore4`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  let histogramScore5_`+ response.Trait +`_D = rankRange(cohortScore5`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-D").css('height', histogramScore1_`+ response.Trait +`_D +'%');
                                  $(".histogram-score2-`+ response.Trait +`-D").css('height', histogramScore2_`+ response.Trait +`_D +'%');
                                  $(".histogram-score3-`+ response.Trait +`-D").css('height', histogramScore3_`+ response.Trait +`_D +'%');
                                  $(".histogram-score4-`+ response.Trait +`-D").css('height', histogramScore4_`+ response.Trait +`_D +'%');
                                  $(".histogram-score5-`+ response.Trait +`-D").css('height', histogramScore5_`+ response.Trait +`_D +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-D").css('height', histogramScore1_`+ response.Trait +`_D +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-D").css('height', histogramScore2_`+ response.Trait +`_D +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-D").css('height', histogramScore3_`+ response.Trait +`_D +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-D").css('height', histogramScore4_`+ response.Trait +`_D +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-D").css('height', histogramScore5_`+ response.Trait +`_D +'%');
                                  
                                  //internal stakeholders
                                  let histogramScore1_`+ response.Trait +`_I = rankRange(cohortScore1`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore2_`+ response.Trait +`_I = rankRange(cohortScore2`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore3_`+ response.Trait +`_I = rankRange(cohortScore3`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore4_`+ response.Trait +`_I = rankRange(cohortScore4`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  let histogramScore5_`+ response.Trait +`_I = rankRange(cohortScore5`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-I").css('height', histogramScore1_`+ response.Trait +`_I +'%');
                                  $(".histogram-score2-`+ response.Trait +`-I").css('height', histogramScore2_`+ response.Trait +`_I +'%');
                                  $(".histogram-score3-`+ response.Trait +`-I").css('height', histogramScore3_`+ response.Trait +`_I +'%');
                                  $(".histogram-score4-`+ response.Trait +`-I").css('height', histogramScore4_`+ response.Trait +`_I +'%');
                                  $(".histogram-score5-`+ response.Trait +`-I").css('height', histogramScore5_`+ response.Trait +`_I +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-I").css('height', histogramScore1_`+ response.Trait +`_I +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-I").css('height', histogramScore2_`+ response.Trait +`_I +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-I").css('height', histogramScore3_`+ response.Trait +`_I +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-I").css('height', histogramScore4_`+ response.Trait +`_I +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-I").css('height', histogramScore5_`+ response.Trait +`_I +'%');

                                  //external stakeholders
                                  let histogramScore1_`+ response.Trait +`_E = rankRange(cohortScore1`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore2_`+ response.Trait +`_E = rankRange(cohortScore2`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore3_`+ response.Trait +`_E = rankRange(cohortScore3`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore4_`+ response.Trait +`_E = rankRange(cohortScore4`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  let histogramScore5_`+ response.Trait +`_E = rankRange(cohortScore5`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-E").css('height', histogramScore1_`+ response.Trait +`_E +'%');
                                  $(".histogram-score2-`+ response.Trait +`-E").css('height', histogramScore2_`+ response.Trait +`_E +'%');
                                  $(".histogram-score3-`+ response.Trait +`-E").css('height', histogramScore3_`+ response.Trait +`_E +'%');
                                  $(".histogram-score4-`+ response.Trait +`-E").css('height', histogramScore4_`+ response.Trait +`_E +'%');
                                  $(".histogram-score5-`+ response.Trait +`-E").css('height', histogramScore5_`+ response.Trait +`_E +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub-E").css('height', histogramScore1_`+ response.Trait +`_E +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub-E").css('height', histogramScore2_`+ response.Trait +`_E +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub-E").css('height', histogramScore3_`+ response.Trait +`_E +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub-E").css('height', histogramScore4_`+ response.Trait +`_E +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub-E").css('height', histogramScore5_`+ response.Trait +`_E +'%');

                                  //ALL
                                  let histogramScore1_`+ response.Trait +`_ALL = rankRange(cohortScore1`+ response.Trait +`_ALL, arrayCohort`+ response.Trait +`_ALL)
                                  let histogramScore2_`+ response.Trait +`_ALL = rankRange(cohortScore2`+ response.Trait +`_ALL, arrayCohort`+ response.Trait +`_ALL)
                                  let histogramScore3_`+ response.Trait +`_ALL = rankRange(cohortScore3`+ response.Trait +`_ALL, arrayCohort`+ response.Trait +`_ALL)
                                  let histogramScore4_`+ response.Trait +`_ALL = rankRange(cohortScore4`+ response.Trait +`_ALL, arrayCohort`+ response.Trait +`_ALL)
                                  let histogramScore5_`+ response.Trait +`_ALL = rankRange(cohortScore5`+ response.Trait +`_ALL, arrayCohort`+ response.Trait +`_ALL)
                                  //section 1
                                  $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_ALL +'%');
                                  //section 2-7
                                  $(".histogram-score1-`+ response.Trait +`-sub").css('height', histogramScore1_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score2-`+ response.Trait +`-sub").css('height', histogramScore2_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score3-`+ response.Trait +`-sub").css('height', histogramScore3_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score4-`+ response.Trait +`-sub").css('height', histogramScore4_`+ response.Trait +`_ALL +'%');
                                  $(".histogram-score5-`+ response.Trait +`-sub").css('height', histogramScore5_`+ response.Trait +`_ALL +'%');
                              `
                            })
                          }
                        }
                      }
                    output += `
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                  `
                }
              }

              // for QSORT Individual Report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Qsort-Individual') { 
                  output += `
                    axios
                      .get(` + '`' + `${url}` + '/qsort-report/survey-assignment/${survey_assignment_id}/iteration/${iteration_id}/${qsort_type}' + '`, config)' + `
                      .then((res) => {
                          console.log(res.data)
                          if(res.data.length == 0) {
                            return Swal.fire({
                              icon: 'info',
                              title: '',
                              html: '<p>No records found!</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          //Table-Qsort-Correlation
                          function getQsortCorrelation(qsortCorrelationClassName, resData) {
                            let outputHTML = ''
                            
                            if(resData.length == 0) {
                                outputHTML += '<div class="text-center text-danger p-3">No results found!</div>'
                            }
                            else {
                              resData.map((res, index) => {
                                outputHTML += ` +
                                  '`' +
                                  '<tr>' +
                                    '<td class="sub-heading-score-blindspots">${res.CompanyName}</td>' +
                                    '<td class="sub-heading-score-blindspots">${parseFloat(res.Correlation).toFixed(2)}</td>' +
                                  '</tr>' +
                                  '`' +
                                `
                              })
                            }
        
                            $(qsortCorrelationClassName).html(outputHTML)
                          }

                          
                        `
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
                            // Section 2
                            if (data.Template === 'Table-Qsort-Bar-Chart-Head') {
                              output += `
                                $('#personalScore').css('color','rgb(177,151,252)')
                                $('.s2-responses-group-average-score').text(res.data.section2Correlation)

                                if(res.data.section2Correlation < 0.25) {
                                  $('.s2-dynamic-score').text('low')
                                }
                                else if(res.data.section2Correlation >= 0.25 && res.data.section2Correlation < 0.50) {
                                  $('.s2-dynamic-score').text('moderate')
                                }
                                else if(res.data.section2Correlation >= 0.50 ) {
                                  $('.s2-dynamic-score').text('strong')
                                }

                                // <= 2.5 is Low
                                // > 2.5 and <= 3.5 is Typical
                                // > 3.5 is High
                                
                                res.data.cohort_scale_data.map((result, index) => {
                                  if(result.ScaleValue <= 2.5) {
                                    $('.group_level_scale_'+index).text('Low')
                                  }
                                  else if(result.ScaleValue > 2.5 && result.ScaleValue <= 3.5) {
                                    $('.group_level_scale_'+index).text('Typical')
                                  }
                                  else if(result.ScaleValue > 3.5) {
                                    $('.group_level_scale_'+index).text('High')
                                  }
                                })
                              
                                //Table-Qsort Section 2
                                let outputHTML = ''
                                res.data.raw_scale_data.map((resScale, index) => {
                                  outputHTML += ` +
                                    '`' +
                                    '<tr>' +
                                      '<td class="sub-heading-score-blindspots">${resScale.ScaleName}</td>' +
                                      '<td class="sub-heading-score-blindspots">' +
                                        '<div class="bar-container-barchart1">' +
                                          '<div class="bar-difference" style="flex-grow: calc(20 * ${resScale.ScaleValue}); background-color: rgb(177,151,252); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                          '<div style="flex-grow: calc(100 - calc(20 * ${resScale.ScaleValue}))"></div>' +
                                          '<div class="break-column"></div>' +
                                          '<div class="bar-difference" style="flex-grow: calc(20 * ${res.data.cohort_scale_data[index].ScaleValue}); background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                          '<div style="flex-grow: calc(100 - calc(20 * ${res.data.cohort_scale_data[index].ScaleValue}))"></div>' +
                                        '</div>' +
                                      '</td>' +
                                      '<td class="sub-heading-score-blindspots" style="color: rgb(177,151,252);">${parseFloat(resScale.ScaleValue).toFixed(1)}</td>' +
                                      '<td class="sub-heading-score-blindspots">${parseFloat(res.data.cohort_scale_data[index].ScaleValue).toFixed(1)}</td>' +
                                    '</tr>' +
                                    '`' +
                                  `
                                })
                                $('.Table-Qsort-BarChart-Section2').html(outputHTML)
                              `
                            }
                            //Section 2
                            if (data.Template === 'Group-Characteristics') {
                              output += `
                                // Group Characteristics section 2 bullet list
                                let outputHTML2 = ''
                                let resChar = res.data.cohortCharacteristics
                                $.getJSON(` + '`' + 'QsortCardItems_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  resChar.map((res, index) => {
                                    for (const jdata of jsonData) { 
                                      if(res.CardNumber == jdata.CardNumber && res.CardMessage == 'SuggestionHigh') {
                                        outputHTML2 += ` +
                                          '`' +
                                          '<li>' +
                                            '${jdata.SuggestionHigh}' +
                                          '</li>' +
                                          '`' +
                                        `
                                      }
                                      else if(res.CardNumber == jdata.CardNumber && res.CardMessage == 'SuggestionLow') {
                                        outputHTML2 += ` +
                                          '`' +
                                          '<li>' +
                                            '${jdata.SuggestionLow}' +
                                          '</li>' +
                                          '`' +
                                        `
                                      }
                                    }
                                  })
                                  $('.group-char-container').html(outputHTML2)
                                })
                              `
                            }
                            // Section 3 and 4
                            if (data.Template === 'Company-Description') { 
                              const obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`Description').append(data.Description)
                                  })
                                `
                              }
                            }
                            if (data.Template === 'Company-Characteristic') { 
                              const obj = JSON.parse(data.Text)
                              for(const res of obj){
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`Characteristic').append(data.Characteristics)
                                  })
                                `
                              }
                            }
                            if (data.Template === 'Company-PotentialActions') { 
                              const obj = JSON.parse(data.Text)
                              for(const res of obj){
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`PotentialActions').append(data.PotentialAction)
                                  })
                                `
                              }
                            }
                            // Section 3, 4 and 5
                            if (data.Template === 'Table-Qsort-Correlation') {
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += ` 
                                  getQsortCorrelation('.Table-Qsort-Correlation-`+res.CompanyType+`', res.data.sorted_correlation_`+res.CompanyType+`)
                                `
                              }
                            }
                            // Section 5 all, Section 3 and 4 Table Difference
                            if (data.Template === 'Table-Qsort-Bar-Chart-Sub') { 
                              output += `
                                // table heading of Difference 
                                $('.heading-number').text('#')

                                // Table-Qsort Bar Chart Section 5
                                function barChartSection5(barChartClassName, resData, resCohortData) {
                                  let outputHTML = ''
                                  
                                  if(resData.length == 0) {
                                    outputHTML += '<div class="text-center text-danger p-3">No results found!</div>'
                                  }
                                  else {
                                    resData.map((res, index) => {
                                      outputHTML += ` +
                                        '`' +
                                        '<tr>' +
                                          '<td class="sub-heading-score-blindspots">${res.ScaleName}</td>' +
                                          '<td class="sub-heading-score-blindspots">' +
                                            '<div class="bar-container-barchart1">' +
                                              '<div class="bar-difference" style="flex-grow: calc(20 * ${res.ScaleValue}); background-color: rgb(177,151,252); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                              '<div style="flex-grow: calc(100 - calc(20 * ${res.ScaleValue}))"></div>' +
                                              '<div class="break-column"></div>' +
                                              '<div class="bar-difference" style="flex-grow: calc(20 * ${resCohortData[index].ScaleValue}); background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                              '<div style="flex-grow: calc(100 - calc(20 * ${resCohortData[index].ScaleValue}))"></div>' +
                                            '</div>' +
                                          '</td>' +
                                          '<td class="sub-heading-score-blindspots" style="color: rgb(177,151,252);">${parseFloat(res.ScaleValue).toFixed(1)}</td>' +
                                          '<td class="sub-heading-score-blindspots">${parseFloat(resCohortData[index].ScaleValue).toFixed(1)}</td>' +
                                        '</tr>' +
                                        '`' +
                                      `
                                    })
                                  }
                                  $(barChartClassName).html(outputHTML)
                                }
                                // Table-Qsort Bar Chart Section 5
                                res.data.sorted_correlation_hypothetical.map((result, index) => {
                                  `
                                    let barChart = JSON.parse(data.Text)
                                    for (const resBarChart of barChart) {
                                      resBarChart.AllCompanies.forEach((response) => {
                                        output += `
                                          if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {

                                            //dynamic table heading of bar chart in section 5 based on the company
                                            $('.Table-Qsort-Header-BarChart-Section5-`+response.CompanyAcronym+`').html(`+
                                              '`' +
                                                '<tr>' +
                                                  '<th class="heading-label-blindspots" colspan="2"> Scale </th>' +
                                                  '<th class="heading-label-blindspots">'+ 
                                                    ` 
                                                    `+response.CompanyName+`
                                                    `+
                                                  '</th>' +
                                                  '<th class="heading-label-blindspots"> Group Score </th>' +
                                                '</tr>' +
                                              '`' +
                                            `)
                                            
                                            // plus 50 is just a logic to work the sorting of negative number
                                            // this is hidden, this is just to work the sorting of whole content in section 5
                                            $('.correlation-data-for-sorting-`+response.CompanyAcronym+`').text(result.Correlation + 50)

                                            barChartSection5('.Table-Qsort-BarChart-Section5-`+response.CompanyAcronym+`', result.NormScale, res.data.cohort_scale_data)

                                            // Your groupʼs average scale correlation with those of the hypothetical type is
                                            $('.hypothetical_type_`+response.CompanyAcronym+`').text(parseFloat(result.Correlation).toFixed(2))
                                          }
                                        `
                                      })
                                    }
                                  output += `
                                })

                                // Section 5 For this hypothetical type, the main scale on which your group differs is: Risk Appetite.
                                res.data.maxDifferentScaleHypothetical.map((result, index) => {
                                  `
                                    let hypotheticalType = JSON.parse(data.Text)
                                    for (const resHypo of hypotheticalType) {
                                      resHypo.AllCompanies.forEach((response) => {
                                        output += `
                                          if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                            $('.hypothetical-main-scale-`+response.CompanyAcronym+`').text(result.ScaleName)
                                          }
                                        `
                                      })
                                    }
                                  output += `
                                })

                                // sort the content in section 5 from Highest to Lowest based on the sorting of company
                                $('.qsort-sortme').sort(function(a, b) {
                                  if (a.textContent > b.textContent) {
                                      return -1;
                                  } else {
                                      return 1;
                                  }
                                }).appendTo($('.qsort-wrapper'));

                                //  Table Difference
                                $.getJSON(` + '`' + 'QsortCardItems_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  console.log(jsonData)
                                
                                  //High Difference Section 5
                                  function getQsortDifference(tableDifferenceClassName, hasResultCompany, noResultCompany, resData, companyName, companyClassName, suggestionFromJsonFile) {
                                    let outputHTML = ''
                                    let suggestionMessage = ''

                                    if(resData.length == 0) {
                                      $(noResultCompany).removeClass('d-none')
                                    }
                                    else {
                                      $(hasResultCompany).removeClass('d-none')
                                      resData.map((res, index) => {
                                        for (const jdata of jsonData) { 
                                          if(res.NormCardNumber == jdata.CardNumber) {
                                            outputHTML += ` +
                                              '`' +
                                              '<tr>' +
                                                '<td class="sub-heading-score-blindspots">${index+1}</td>' +
                                                '<td class="sub-heading-score-blindspots">${jdata.Label}</td>' +
                                                '<td class="sub-heading-score-blindspots">${suggestionMessage = suggestionFromJsonFile == "high" ? jdata.SuggestionHigh : jdata.SuggestionLow }</td>' +
                                                '<td class="sub-heading-score-blindspots">${res.Subtracted}</td>' +
                                              '</tr>' +
                                              '`' +
                                            `
                                          }
                                        }
                                      })
                                    }
                                    $(companyClassName).text(companyName)
                                    $(tableDifferenceClassName).html(outputHTML)
                                  }

                                  `
                                    // Difference Table Section 3 and 4 High and Low
                                    for (let i = 0; i < input.length; i++) {
                                      const data = input[i]
                                      if (data.Template === 'Table-Qsort-Difference') {
                                        let obj = JSON.parse(data.Text)
                                        for (const res of obj) {
                                          output += ` 
                                            getQsortDifference('.Table-Qsort-`+res.Level+`-Difference-`+res.CompanyType+`', '.Has-`+res.Level+`-Result-`+res.CompanyType+`', '.No-`+res.Level+`-Result-`+res.CompanyType+`', res.data.`+res.Level+`_cohort_norm_difference_`+res.CompanyType+`_top1[0].Filtered`+res.Level+`DifferenceData, res.data.`+res.Level+`_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName, '.`+res.Level+`-companyName-`+res.CompanyType+`', '`+res.Level+`')
                                          `
                                        }

                                        output += `
                                          // sum of high and low top 1 Difference table section 3
                                          $('.best-fit-hyphotetical-type-points').text(res.data.high_cohort_norm_difference_hypothetical_top1[0].FilteredhighDifferenceData.length + res.data.low_cohort_norm_difference_hypothetical_top1[0].FilteredlowDifferenceData.length)
                                        
                                          // sum of high and low top 1 Difference table section 4
                                          $('.best-fit-organization-type-points').text(res.data.high_cohort_norm_difference_historical_top1[0].FilteredhighDifferenceData.length + res.data.low_cohort_norm_difference_historical_top1[0].FilteredlowDifferenceData.length)
                                        `
                                      }
                                    }
                                  output += `

                                  // High Difference Table Section 5
                                  res.data.high_cohort_norm_difference.map((result, index) => {
                                    `
                                      let objHighDifference = JSON.parse(data.Text)
                                      for (const resDif of objHighDifference) {
                                        resDif.AllCompanies.forEach((response) => {
                                          output += `
                                            if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                              getQsortDifference('.Table-Qsort-High-Difference-`+response.CompanyAcronym+`', '.Has-High-Result-`+response.CompanyAcronym+`', '.No-High-Result-`+response.CompanyAcronym+`', res.data.high_cohort_norm_difference[index].FilteredhighDifferenceData, '', '', 'high')
                                            
                                              // The number of your group's characteristics which differ from those of this type by more than 2 points is:
                                              $('.hyphotetical_points_`+response.CompanyAcronym+`').text(res.data.high_cohort_norm_difference[index].FilteredhighDifferenceData.length)
                                            }
                                          `
                                        })
                                      }
                                    output += `
                                  })

                                  // Low Difference Table Section 5
                                  res.data.low_cohort_norm_difference.map((result, index) => {
                                    `
                                      let objLowDifference = JSON.parse(data.Text)
                                      for (const resDif of objLowDifference) {
                                        resDif.AllCompanies.forEach((response) => {
                                          output += `
                                            if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                              getQsortDifference('.Table-Qsort-Low-Difference-`+response.CompanyAcronym+`', '.Has-Low-Result-`+response.CompanyAcronym+`', '.No-Low-Result-`+response.CompanyAcronym+`', res.data.low_cohort_norm_difference[index].FilteredlowDifferenceData, '', '', 'low')
                                            
                                              // The number of your group's characteristics which differ from those of this type by more than 2 points is:
                                              let getValue_`+response.CompanyAcronym+` = $('.hyphotetical_points_`+response.CompanyAcronym+`').text()
                                              $('.hyphotetical_points_`+response.CompanyAcronym+`').text(parseInt(getValue_`+response.CompanyAcronym+`) + res.data.low_cohort_norm_difference[index].FilteredlowDifferenceData.length)
                                            }
                                          `
                                        })
                                      }
                                    output += `
                                  })
                                }) //$.getJSON('qsortCardItems.json')

                                // Group Characteristics in Section 5
                                $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  console.log(jsonData)

                                  res.data.sorted_correlation_hypothetical.map((res, index) => {
                                    for (const jdata of jsonData.hypothetical) { 
                                      if(res.CompanyName == jdata.CompanyName) {
                                        `
                                          let objGC = JSON.parse(data.Text)
                                          for (const gc of objGC) {
                                            gc.AllCompanies.forEach((response) => {
                                              output += `
                                                if(res.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                                  $('.hypotheticalCharacteristic_`+response.CompanyAcronym+`').append(jdata.Characteristics)
                                                  $('.hypotheticalSuffix_`+response.CompanyAcronym+`').append(jdata.Suffix)
                                                }
                                              `
                                            })
                                          }
                                        output += `
                                      }
                                    }
                                  })
                                })

                              `
                            }
                          }
                        output += `
                          $('.qsort_table').removeClass('d-none')
                          $('.qsort_spinner').addClass('d-none')
                      })
                      .catch((err) => {
                        console.log(err)
                        if(err.code == "ERR_NETWORK") {
                          return Swal.fire({
                            icon: 'info',
                            title: '',
                            html: '<p>Network Error, Please try again later or contact help@talentsage.com!</p>',
                            customClass: 'norm-wording'
                          })
                        }
                      })
                  `
                }//if Qsort Individual
              } //for loop

              // for QSORT Cohort Report
              for(let i = 0; i < input.length; i++){
                const data = input[i];
                if (data.Template === 'Qsort-Cohort') { 
                  output += `
                    axios
                      .get(` + '`' + `${url}` + '/qsort-cohort-report/iteration/${iteration_id}/${qsort_type}' + '`, config)' + `
                      .then((res) => {
                          console.log(res.data)
                          if(res.data.length == 0) {
                            return Swal.fire({
                              icon: 'info',
                              title: '',
                              html: '<p>No records found!</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          //Table-Qsort-Correlation
                          function getQsortCorrelation(qsortCorrelationClassName, resData) {
                            let outputHTML = ''
                            
                            if(resData.length == 0) {
                                outputHTML += '<div class="text-center text-danger p-3">No results found!</div>'
                            }
                            else {
                              resData.map((res, index) => {
                                outputHTML += ` +
                                  '`' +
                                  '<tr>' +
                                    '<td class="sub-heading-score-blindspots">${res.CompanyName}</td>' +
                                    '<td class="sub-heading-score-blindspots">${parseFloat(res.Correlation).toFixed(2)}</td>' +
                                  '</tr>' +
                                  '`' +
                                `
                              })
                            }
        
                            $(qsortCorrelationClassName).html(outputHTML)
                          }

                          
                        `
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
                            // Section 2
                            if (data.Template === 'Table-Qsort-Bar-Chart-Head') {
                              output += `
                                // hide personal score column in section 2
                                $('#personalScore').css('display','none')

                                // <= 2.5 is Low
                                // > 2.5 and <= 3.5 is Typical
                                // > 3.5 is High
                                
                                res.data.cohort_scale_data.map((result, index) => {
                                  if(result.ScaleValue <= 2.5) {
                                    $('.group_level_scale_'+index).text('Low')
                                  }
                                  else if(result.ScaleValue > 2.5 && result.ScaleValue <= 3.5) {
                                    $('.group_level_scale_'+index).text('Typical')
                                  }
                                  else if(result.ScaleValue > 3.5) {
                                    $('.group_level_scale_'+index).text('High')
                                  }
                                })
                              
                                //Table-Qsort Section 2
                                let outputHTML = ''
                                res.data.cohort_scale_data.map((resScale, index) => {
                                  outputHTML += ` +
                                    '`' +
                                    '<tr>' +
                                      '<td class="sub-heading-score-blindspots">${resScale.ScaleName}</td>' +
                                      '<td class="sub-heading-score-blindspots">' +
                                        '<div class="bar-container-barchart1">' +
                                          '<div class="bar-difference" style="flex-grow: calc(20 * ${res.data.cohort_scale_data[index].ScaleValue}); background-color: rgb(177,151,252); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                          '<div style="flex-grow: calc(100 - calc(20 * ${res.data.cohort_scale_data[index].ScaleValue}))"></div>' +
                                        '</div>' +
                                      '</td>' +
                                      '<td class="sub-heading-score-blindspots" style="color:rgb(177,151,252);">${parseFloat(res.data.cohort_scale_data[index].ScaleValue).toFixed(1)}</td>' +
                                    '</tr>' +
                                    '`' +
                                  `
                                })
                                $('.Table-Qsort-BarChart-Section2').html(outputHTML)
                              `
                            }
                            //Section 2
                            if (data.Template === 'Group-Characteristics') {
                              output += `
                                // Group Characteristics section 2 bullet list
                                let outputHTML2 = ''
                                let resChar = res.data.cohortCharacteristics
                                $.getJSON(` + '`' + 'QsortCardItems_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  resChar.map((res, index) => {
                                    for (const jdata of jsonData) { 
                                      if(res.CardNumber == jdata.CardNumber && res.CardMessage == 'SuggestionHigh') {
                                        outputHTML2 += ` +
                                          '`' +
                                          '<li>' +
                                            '${jdata.SuggestionHigh}' +
                                          '</li>' +
                                          '`' +
                                        `
                                      }
                                      else if(res.CardNumber == jdata.CardNumber && res.CardMessage == 'SuggestionLow') {
                                        outputHTML2 += ` +
                                          '`' +
                                          '<li>' +
                                            '${jdata.SuggestionLow}' +
                                          '</li>' +
                                          '`' +
                                        `
                                      }
                                    }
                                  })
                                  $('.group-char-container').html(outputHTML2)
                                })
                              `
                            }
                            // Section 3
                            if (data.Template === 'Company-Description') { 
                              const obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`Description').append(data.Description)
                                  })
                                `
                              }
                            }
                            if (data.Template === 'Company-Characteristic') { 
                              const obj = JSON.parse(data.Text)
                              for(const res of obj){
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`Characteristic').append(data.Characteristics)
                                  })
                                `
                              }
                            }
                            if (data.Template === 'Company-PotentialActions') { 
                              const obj = JSON.parse(data.Text)
                              for(const res of obj){
                                output += `
                                  $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
  
                                    let data = jsonData.`+res.CompanyType+`.find((item) => item.CompanyName === res.data.high_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName)
  
                                    $('#`+res.CompanyType+`PotentialActions').append(data.PotentialAction)
                                  })
                                `
                              }
                            }
                            // Section 3, 4 and 5
                            if (data.Template === 'Table-Qsort-Correlation') {
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += ` 
                                  getQsortCorrelation('.Table-Qsort-Correlation-`+res.CompanyType+`', res.data.sorted_correlation_`+res.CompanyType+`)
                                `
                              }
                            }
                            // Section 5 all, Section 3 and 4 Table Difference
                            if (data.Template === 'Table-Qsort-Bar-Chart-Sub') { 
                              output += `
                                // table heading of Difference 
                                $('.heading-number').text('#')

                                // Table-Qsort Bar Chart Section 5
                                function barChartSection5(barChartClassName, resData, resCohortData) {
                                  let outputHTML = ''
                                  
                                  if(resData.length == 0) {
                                    outputHTML += '<div class="text-center text-danger p-3">No results found!</div>'
                                  }
                                  else {
                                    resData.map((res, index) => {
                                      outputHTML += ` +
                                        '`' +
                                        '<tr>' +
                                          '<td class="sub-heading-score-blindspots">${res.ScaleName}</td>' +
                                          '<td class="sub-heading-score-blindspots">' +
                                            '<div class="bar-container-barchart1">' +
                                              '<div class="bar-difference" style="flex-grow: calc(20 * ${res.ScaleValue}); background-color: rgb(177,151,252); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                              '<div style="flex-grow: calc(100 - calc(20 * ${res.ScaleValue}))"></div>' +
                                              '<div class="break-column"></div>' +
                                              '<div class="bar-difference" style="flex-grow: calc(20 * ${resCohortData[index].ScaleValue}); background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>' +
                                              '<div style="flex-grow: calc(100 - calc(20 * ${resCohortData[index].ScaleValue}))"></div>' +
                                            '</div>' +
                                          '</td>' +
                                          '<td class="sub-heading-score-blindspots" style="color: rgb(177,151,252);">${parseFloat(res.ScaleValue).toFixed(1)}</td>' +
                                          '<td class="sub-heading-score-blindspots">${parseFloat(resCohortData[index].ScaleValue).toFixed(1)}</td>' +
                                        '</tr>' +
                                        '`' +
                                      `
                                    })
                                  }
                                  $(barChartClassName).html(outputHTML)
                                }
                                // Table-Qsort Bar Chart Section 5
                                res.data.sorted_correlation_hypothetical.map((result, index) => {
                                  `
                                    let barChart = JSON.parse(data.Text)
                                    for (const resBarChart of barChart) {
                                      resBarChart.AllCompanies.forEach((response) => {
                                        output += `
                                          if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {

                                            //dynamic table heading of bar chart in section 5 based on the company
                                            $('.Table-Qsort-Header-BarChart-Section5-`+response.CompanyAcronym+`').html(`+
                                              '`' +
                                                '<tr>' +
                                                  '<th class="heading-label-blindspots" colspan="2"> Scale </th>' +
                                                  '<th class="heading-label-blindspots">'+ 
                                                    ` 
                                                    `+response.CompanyName+`
                                                    `+
                                                  '</th>' +
                                                  '<th class="heading-label-blindspots"> Group Score </th>' +
                                                '</tr>' +
                                              '`' +
                                            `)
                                            
                                            // plus 50 is just a logic to work the sorting of negative number
                                            // this is hidden, this is just to work the sorting of whole content in section 5
                                            $('.correlation-data-for-sorting-`+response.CompanyAcronym+`').text(result.Correlation + 50)

                                            barChartSection5('.Table-Qsort-BarChart-Section5-`+response.CompanyAcronym+`', result.NormScale, res.data.cohort_scale_data)

                                            // Your groupʼs average scale correlation with those of the hypothetical type is
                                            $('.hypothetical_type_`+response.CompanyAcronym+`').text(parseFloat(result.Correlation).toFixed(2))
                                          }
                                        `
                                      })
                                    }
                                  output += `
                                })

                                // Section 5 For this hypothetical type, the main scale on which your group differs is: Risk Appetite.
                                res.data.maxDifferentScaleHypothetical.map((result, index) => {
                                  `
                                    let hypotheticalType = JSON.parse(data.Text)
                                    for (const resHypo of hypotheticalType) {
                                      resHypo.AllCompanies.forEach((response) => {
                                        output += `
                                          if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                            $('.hypothetical-main-scale-`+response.CompanyAcronym+`').text(result.ScaleName)
                                          }
                                        `
                                      })
                                    }
                                  output += `
                                })

                                // sort the content in section 5 from Highest to Lowest based on the sorting of company
                                $('.qsort-sortme').sort(function(a, b) {
                                  if (a.textContent > b.textContent) {
                                      return -1;
                                  } else {
                                      return 1;
                                  }
                                }).appendTo($('.qsort-wrapper'));

                                $.getJSON(` + '`' + 'QsortCardItems_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  console.log(jsonData)
                                
                                  //High Difference Section 5
                                  function getQsortDifference(tableDifferenceClassName, hasResultCompany, noResultCompany, resData, companyName, companyClassName, suggestionFromJsonFile) {
                                    let outputHTML = ''
                                    let suggestionMessage = ''

                                    if(resData.length == 0) {
                                      $(noResultCompany).removeClass('d-none')
                                    }
                                    else {
                                      $(hasResultCompany).removeClass('d-none')
                                      resData.map((res, index) => {
                                        for (const jdata of jsonData) { 
                                          if(res.NormCardNumber == jdata.CardNumber) {
                                            outputHTML += ` +
                                              '`' +
                                              '<tr>' +
                                                '<td class="sub-heading-score-blindspots">${index+1}</td>' +
                                                '<td class="sub-heading-score-blindspots">${jdata.Label}</td>' +
                                                '<td class="sub-heading-score-blindspots">${suggestionMessage = suggestionFromJsonFile == "high" ? jdata.SuggestionHigh : jdata.SuggestionLow }</td>' +
                                                '<td class="sub-heading-score-blindspots">${res.Subtracted}</td>' +
                                              '</tr>' +
                                              '`' +
                                            `
                                          }
                                        }
                                      })
                                    }
                                    $(companyClassName).text(companyName)
                                    $(tableDifferenceClassName).html(outputHTML)
                                  }

                                  `
                                    // Difference Table Section 3 and 4 High and Low
                                    for (let i = 0; i < input.length; i++) {
                                      const data = input[i]
                                      if (data.Template === 'Table-Qsort-Difference') {
                                        let obj = JSON.parse(data.Text)
                                        for (const res of obj) {
                                          output += ` 
                                            getQsortDifference('.Table-Qsort-`+res.Level+`-Difference-`+res.CompanyType+`', '.Has-`+res.Level+`-Result-`+res.CompanyType+`', '.No-`+res.Level+`-Result-`+res.CompanyType+`', res.data.`+res.Level+`_cohort_norm_difference_`+res.CompanyType+`_top1[0].Filtered`+res.Level+`DifferenceData, res.data.`+res.Level+`_cohort_norm_difference_`+res.CompanyType+`_top1[0].CompanyName, '.`+res.Level+`-companyName-`+res.CompanyType+`', '`+res.Level+`')
                                          `
                                        }

                                        output += `
                                          // sum of high and low top 1 Difference table section 3
                                          $('.best-fit-hyphotetical-type-points').text(res.data.high_cohort_norm_difference_hypothetical_top1[0].FilteredhighDifferenceData.length + res.data.low_cohort_norm_difference_hypothetical_top1[0].FilteredlowDifferenceData.length)
                                        
                                          // sum of high and low top 1 Difference table section 4
                                          $('.best-fit-organization-type-points').text(res.data.high_cohort_norm_difference_historical_top1[0].FilteredhighDifferenceData.length + res.data.low_cohort_norm_difference_historical_top1[0].FilteredlowDifferenceData.length)
                                        `
                                      }
                                    }
                                  output += `

                                  // High Difference Table Section 5
                                  res.data.high_cohort_norm_difference.map((result, index) => {
                                    `
                                      let objHighDifference = JSON.parse(data.Text)
                                      for (const resDif of objHighDifference) {
                                        resDif.AllCompanies.forEach((response) => {
                                          output += `
                                            if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                              getQsortDifference('.Table-Qsort-High-Difference-`+response.CompanyAcronym+`', '.Has-High-Result-`+response.CompanyAcronym+`', '.No-High-Result-`+response.CompanyAcronym+`', res.data.high_cohort_norm_difference[index].FilteredhighDifferenceData, '', '', 'high')
                                            
                                              // The number of your group's characteristics which differ from those of this type by more than 2 points is:
                                              $('.hyphotetical_points_`+response.CompanyAcronym+`').text(res.data.high_cohort_norm_difference[index].FilteredhighDifferenceData.length)
                                            }
                                          `
                                        })
                                      }
                                    output += `
                                  })

                                  // Low Difference Table Section 5
                                  res.data.low_cohort_norm_difference.map((result, index) => {
                                    `
                                      let objLowDifference = JSON.parse(data.Text)
                                      for (const resDif of objLowDifference) {
                                        resDif.AllCompanies.forEach((response) => {
                                          output += `
                                            if(result.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                              getQsortDifference('.Table-Qsort-Low-Difference-`+response.CompanyAcronym+`', '.Has-Low-Result-`+response.CompanyAcronym+`', '.No-Low-Result-`+response.CompanyAcronym+`', res.data.low_cohort_norm_difference[index].FilteredlowDifferenceData, '', '', 'low')
                                            
                                              // The number of your group's characteristics which differ from those of this type by more than 2 points is:
                                              let getValue_`+response.CompanyAcronym+` = $('.hyphotetical_points_`+response.CompanyAcronym+`').text()
                                              $('.hyphotetical_points_`+response.CompanyAcronym+`').text(parseInt(getValue_`+response.CompanyAcronym+`) + res.data.low_cohort_norm_difference[index].FilteredlowDifferenceData.length)
                                            }
                                          `
                                        })
                                      }
                                    output += `
                                  })

                                }) //$.getJSON('qsortCardItems.json')

                                // Group Characteristics in Section 5
                                $.getJSON(` + '`' + 'Characteristics_${qsort_type}.json' + '`' + `, (jsonData) => {
                                  console.log(jsonData)

                                  res.data.sorted_correlation_hypothetical.map((res, index) => {
                                    for (const jdata of jsonData.hypothetical) { 
                                      if(res.CompanyName == jdata.CompanyName) {
                                        `
                                          let objGC = JSON.parse(data.Text)
                                          for (const gc of objGC) {
                                            gc.AllCompanies.forEach((response) => {
                                              output += `
                                                if(res.CompanyName.toLowerCase() == '`+response.CompanyName+`'.toLowerCase()) {
                                                  $('.hypotheticalCharacteristic_`+response.CompanyAcronym+`').append(jdata.Characteristics)
                                                  $('.hypotheticalSuffix_`+response.CompanyAcronym+`').append(jdata.Suffix)
                                                }
                                              `
                                            })
                                          }
                                        output += `
                                      }
                                    }
                                  })
                                })

                              `
                            }
                          }
                        output += `
                          $('.qsort_table').removeClass('d-none')
                          $('.qsort_spinner').addClass('d-none')
                      })
                      .catch((err) => {
                        console.log(err)
                        if(err.code == "ERR_NETWORK") {
                          return Swal.fire({
                            icon: 'info',
                            title: '',
                            html: '<p>Network Error, Please try again later or contact help@talentsage.com!</p>',
                            customClass: 'norm-wording'
                          })
                        }
                      })
                  `
                }//if Qsort Individual
              } //for loop


              // for VFP Individual Report
              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                if (data.Template === 'VFP-Radar-Chart') { 

                  output += `
                    axios
                      .get(` + '`' + `${url}` + '/vfp-report/${survey_assignment_id}' + '`, config)' + `
                      .then((res) => {
                        console.log(res.data)

                        if(res.data.length == 0) {
                          return Swal.fire({
                            icon: 'info',
                            title: '',
                            html: '<p>No records found!</p>',
                            customClass: 'norm-wording'
                          })
                        }

                        // section 2 bar chart power distance
                        $('.vfp-bar-power-distance').css('left', res.data.powerDistanceValue);
                        $('.vfp-message-power-distance').text(res.data.powerDistanceMessage);
                        $('.vfp-'+res.data.powerDistancePosition+'-power-distance').removeClass('line');
                        
                        // section 2 bar chart goal orientation
                        $('.vfp-bar-goal-orientation').css('left', res.data.goalOrientationValue);
                        $('.vfp-message-goal-orientation').text(res.data.goalOrientationMessage);
                        $('.vfp-'+res.data.goalOrientationPosition+'-goal-orientation').removeClass('line');

                        // section 2 bar chart cultural imposition
                        $('.vfp-bar-cultural-imposition').css('left', res.data.culturalImpositionValue);
                        $('.vfp-message-cultural-imposition').text(res.data.culturalImpositionMessage);
                        $('.vfp-'+res.data.culturalImpositionPosition+'-cultural-imposition').removeClass('line');
                        
                        // section 2 bar chart uncertainty avoidance
                        $('.vfp-bar-uncertainty-avoidance').css('left', res.data.uncertaintyAvoidanceValue);
                        $('.vfp-message-uncertainty-avoidance').text(res.data.uncertaintyAvoidanceMessage);
                        $('.vfp-'+res.data.uncertaintyAvoidancePosition+'-uncertainty-avoidance').removeClass('line');

                        // Very Low: 1 - 2.399
                        // Low: 2.4 - 2.799
                        // Moderate: 2.8 - 3.2
                        // High: 3.211 - 3.6
                        // Very High: 3.611 - 5

                        //section 2 speedometer
                        res.data.sortedPersonalValues.map((result, index) => {
                          if(result.Value < 2.4) {
                            $(".all-scale-vfp-role-" +index).text(result.Name == "Welfare of all (Universalism)" ? "Welfare of all" : result.Name)
                            
                            let scoreValue = (parseFloat(result.Value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".all-scale-vfp-score-"+index).css("transform", "rotate(" + scoreSpeed + "deg)");

                            $(".all-scale-vfp-breakpoint-" +index).text('Very Low')
                          }
                          else if(2.4 <= result.Value && result.Value < 2.8) {
                            $(".all-scale-vfp-role-" +index).text(result.Name == "Welfare of all (Universalism)" ? "Welfare of all" : result.Name)
                            
                            let scoreValue = (parseFloat(result.Value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".all-scale-vfp-score-"+index).css("transform", "rotate(" + scoreSpeed + "deg)");

                            $(".all-scale-vfp-breakpoint-" +index).text('Low')
                          }
                          else if(2.8 <= result.Value && result.Value <= 3.2) {
                            $(".all-scale-vfp-role-" +index).text(result.Name == "Welfare of all (Universalism)" ? "Welfare of all" : result.Name)
                            
                            let scoreValue = (parseFloat(result.Value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".all-scale-vfp-score-"+index).css("transform", "rotate(" + scoreSpeed + "deg)");

                            $(".all-scale-vfp-breakpoint-" +index).text('Moderate')
                          }
                          else if(3.2 < result.Value && result.Value < 3.6) {
                            $(".all-scale-vfp-role-" +index).text(result.Name == "Welfare of all (Universalism)" ? "Welfare of all" : result.Name)
                            
                            let scoreValue = (parseFloat(result.Value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".all-scale-vfp-score-"+index).css("transform", "rotate(" + scoreSpeed + "deg)");

                            $(".all-scale-vfp-breakpoint-" +index).text('High')
                          }
                          else if(result.Value >= 3.6) {
                            $(".all-scale-vfp-role-" +index).text(result.Name == "Welfare of all (Universalism)" ? "Welfare of all" : result.Name)
                            
                            let scoreValue = (parseFloat(result.Value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".all-scale-vfp-score-"+index).css("transform", "rotate(" + scoreSpeed + "deg)");

                            $(".all-scale-vfp-breakpoint-" +index).text('Very High')
                          }
                            
                          let borderColor = "#eee"
                          if(result.Name == 'Conformity' ) {
                            borderColor = "#A463D8"
                          }
                          if(result.Name == 'Tradition' ) {
                            borderColor = "#A463D8"
                          }
                          if(result.Name == 'Security' ) {
                            borderColor = "#A463D8"
                          }
                          if(result.Name == 'Power' ) {
                            borderColor = "#32C225"
                          }
                          if(result.Name == 'Achievement' ) {
                            borderColor = "#32C225"
                          }
                          if(result.Name == 'Indulgence' ) {
                            borderColor = "#F47820"
                          }
                          if(result.Name == 'Stimulation' ) {
                            borderColor = "#F47820"
                          }
                          if(result.Name == 'Self-direction' ) {
                            borderColor = "#F47820"
                          }
                          if(result.Name == 'Welfare of all (Universalism)' ) {
                            borderColor = "#0E5071"
                          }
                          if(result.Name == 'Benevolence' ) {
                            borderColor = "#0E5071"
                          }
                          $(".all-scale-vfp-score-"+index).css("border", "20px solid "+borderColor);
                          $(".all-scale-vfp-score-"+index).css("border-color", "transparent transparent "+borderColor+ " "+borderColor);
                          $(".all-scale-vfp-score-value-"+index).text(parseFloat(result.Value).toFixed(1))
                        })

                        // section 3-12
                        function vfpCondition(name, value, scaleClassName, borderColor) {
                          if(value < 2.4) {
                            $(".vfp-score-"+scaleClassName).text(parseFloat(value).toFixed(1))
                            $(".breakpoint-vfp-speedometer-"+scaleClassName).text('Very Low')

                            let scoreValue = (parseFloat(value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".score-vfp-speedometer-"+scaleClassName).css("transform", "rotate(" + scoreSpeed + "deg)");
                          }
                          else if(2.4 <= value && value < 2.8) {
                            $(".vfp-score-"+scaleClassName).text(parseFloat(value).toFixed(1))
                            $(".breakpoint-vfp-speedometer-"+scaleClassName).text('Low')

                            let scoreValue = (parseFloat(value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".score-vfp-speedometer-"+scaleClassName).css("transform", "rotate(" + scoreSpeed + "deg)");
                          }
                          else if(2.8 <= value && value <= 3.2) {
                            $(".vfp-score-"+scaleClassName).text(parseFloat(value).toFixed(1))
                            $(".breakpoint-vfp-speedometer-"+scaleClassName).text('Moderate')

                            let scoreValue = (parseFloat(value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".score-vfp-speedometer-"+scaleClassName).css("transform", "rotate(" + scoreSpeed + "deg)");
                          }
                          else if(3.2 < value && value < 3.6) {
                            $(".vfp-score-"+scaleClassName).text(parseFloat(value).toFixed(1))
                            $(".breakpoint-vfp-speedometer-"+scaleClassName).text('High')

                            let scoreValue = (parseFloat(value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".score-vfp-speedometer-"+scaleClassName).css("transform", "rotate(" + scoreSpeed + "deg)");
                          }
                          else if(value >= 3.6) {
                            $(".vfp-score-"+scaleClassName).text(parseFloat(value).toFixed(1))
                            $(".breakpoint-vfp-speedometer-"+scaleClassName).text('Very High')

                            let scoreValue = (parseFloat(value).toFixed(1) - 1) * 25
                            let scoreSpeed = Math.round(scoreValue * 180 / 100) - 45;
                            $(".score-vfp-speedometer-"+scaleClassName).css("transform", "rotate(" + scoreSpeed + "deg)");
                          }
                          $(".score-vfp-speedometer-"+scaleClassName).css("border", "20px solid "+borderColor);
                          $(".score-vfp-speedometer-"+scaleClassName).css("border-color", "transparent transparent "+borderColor+ " "+borderColor);
                        }

                        // section 3-12
                        res.data.unsortedPersonalValues.map((result) => { 
                          if(result.Name == 'Conformity' ) {
                            vfpCondition(result.Name, result.Value, "conformity", "#A463D8")
                          }
                          if(result.Name == 'Tradition' ) {
                            vfpCondition(result.Name, result.Value, "tradition", "#A463D8")
                          }
                          if(result.Name == 'Security' ) {
                            vfpCondition(result.Name, result.Value, "security", "#A463D8")
                          }
                          if(result.Name == 'Power' ) {
                            vfpCondition(result.Name, result.Value, "power", "#32C225")
                          }
                          if(result.Name == 'Achievement' ) {
                            vfpCondition(result.Name, result.Value, "achievement", "#32C225")
                          }
                          if(result.Name == 'Indulgence' ) {
                            vfpCondition(result.Name, result.Value, "indulgence", "#F47820")
                          }
                          if(result.Name == 'Stimulation' ) {
                            vfpCondition(result.Name, result.Value, "stimulation", "#F47820")
                          }
                          if(result.Name == 'Self-direction' ) {
                            vfpCondition(result.Name, result.Value, "self-direction", "#F47820")
                          }
                          if(result.Name == 'Welfare of all (Universalism)' ) {
                            vfpCondition(result.Name, result.Value, "welfare-of-all", "#0E5071")
                          }
                          if(result.Name == 'Benevolence' ) {
                            vfpCondition(result.Name, result.Value, "benevolence", "#0E5071")
                          }
                        });

                        `
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i];
                            if (data.Template === 'VFP-Radar-Chart') { 
                              let obj = JSON.parse(data.Text)
                              for (const res of obj) {
                                output += `
                                  new Chart($('.VFP_Radar_Chart'), {
                                    type: 'radar',
                                    data: {
                                      labels: [
                                        `
                                            res.Elements.forEach((response) => {
                                              output += `
                                                "`+response+`",
                                              `
                                            })
                                        output += `
                                      ],
                                      datasets: [
                                        {
                                          label: '',
                                          data: [
                                            parseFloat(res.data.unsortedPersonalValues[0].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[1].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[2].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[3].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[4].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[5].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[6].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[7].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[8].Value).toFixed(1),
                                            parseFloat(res.data.unsortedPersonalValues[9].Value).toFixed(1)
                                          ],
                                          fill: true,
                                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                          borderColor: 'rgb(255, 99, 132)',
                                          pointBackgroundColor: 'rgb(255, 99, 132)',
                                          pointBorderColor: '#fff',
                                          pointHoverBackgroundColor: '#fff',
                                          pointHoverBorderColor: 'rgb(255, 99, 132)',
                                        }
                                      ],
                                    },
                                    options: {
                                      plugins:{
                                        legend:{
                                            display:false
                                        }
                                      },
                                      scales: {
                                        r: {
                                          pointLabels: {
                                            color: ['#A463D8', '#A463D8', '#A463D8', '#32C225', '#32C225', '#F47820', '#F47820', '#F47820', '#0E5071', '#0E5071']
                                          },
                                          beginAtZero: true,
                                          min: 0,
                                          max: 5,
                                          ticks: {
                                            stepSize: 1,
                                          }
                                        },
                                      }
                                    }
                                  })
                                `
                              }
                            }
                          }
                        output += `
                          setTimeout(() => {
                            $('.vfp_data').removeClass('d-none')
                            $('.vfp_spinner').addClass('d-none')
                          },1000)
                      }) // vfp axios.get.then
                      .catch((err) => {
                        console.log(err)
                        if(err.code == "ERR_NETWORK") {
                          return Swal.fire({
                            icon: 'info',
                            title: '',
                            html: '<p>Network Error, Please try again later or contact help@talentsage.com!</p>',
                            customClass: 'norm-wording'
                          })
                        }
                      })
                  `
                } // if (data.Template === 'VFP-Radar-Chart') { 
              } // for VFP Individual Report


              // for New 360 Individual Report
              for (let i = 0; i < input.length; i++) {
                const data = input[i];

                if (data.Template === 'Bar-Chart-New-360-Head') {
                  output += `

                  axios
                  .post(
                    '${url}/r360-report-data2a',
                    {
                      survey_assignment_id: survey_assignment_id
                    },
                    config                                            
                  )
                  .then((res) => {
                    console.log(res)
                  `
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                      
                     
                     if (data.Template === 'Table-360-Open-ended-New') { 
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output +=`
                          function checkonlySpaces(str) {
                                return str.trim().length === 0;
                              }
                          `
                          res.Elements.forEach((response) => {
                            output += 
                            `
                              if(res.data.length < 1){
                                $(".open-ended-1` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[0] != 'undefined'){
                                if(res.data[0].font === "bold"){
                                  $(".open-ended-1` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-1` + response.Trait + `").html(res.data[0].` + response.Trait + `)
                              }
      
                              if(res.data.length < 2){
                                $(".open-ended-2` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[1] != 'undefined'){
                                if(res.data[1].font === "bold"){
                                  $(".open-ended-2` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-2` + response.Trait + `").html(res.data[1].` + response.Trait + `)
                              }
      
                              if(res.data.length < 3){
                                $(".open-ended-3` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[2] != 'undefined'){
                                if(res.data[2].font === "bold"){
                                  $(".open-ended-3` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-3` + response.Trait + `").html(res.data[2].` + response.Trait + `)
                              }
      
                              if(res.data.length < 4){
                                $(".open-ended-4` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[3] != 'undefined'){
                                if(res.data[3].font === "bold"){
                                  $(".open-ended-4` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-4` + response.Trait + `").html(res.data[3].` + response.Trait + `)
                              }
      
                              if(res.data.length < 5){
                                $(".open-ended-5` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[4] != 'undefined'){
                                if(res.data[4].font === "bold"){
                                  $(".open-ended-5` + response.Trait + `").css("font-weight", "bold")
                                }
                                $(".open-ended-5` + response.Trait + `").html(res.data[4].` + response.Trait + `)
                              }
                              
                              if(res.data.length < 6){
                                $(".open-ended-6` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[5] != 'undefined'){
                                $(".open-ended-6` + response.Trait + `").html(res.data[5].` + response.Trait + `)
                              }
      
                              if(res.data.length < 7){
                                $(".open-ended-7` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[6] != 'undefined'){
                                $(".open-ended-7` + response.Trait + `").html(res.data[6].` + response.Trait + `)
                              }
      
                              if(res.data.length < 8){
                                $(".open-ended-8` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[7] != 'undefined'){
                                $(".open-ended-8` + response.Trait + `").html(res.data[7].` + response.Trait + `)
                              }
                                  
                              if(res.data.length < 9){
                                $(".open-ended-9` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[8] != 'undefined'){
                                $(".open-ended-9` + response.Trait + `").html(res.data[8].` + response.Trait + `)
                              }
      
                              if(res.data.length < 10){
                                $(".open-ended-10` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[9] != 'undefined'){
                                $(".open-ended-10` + response.Trait + `").html(res.data[9].` + response.Trait + `)
                              }
      
                              if(res.data.length < 11){
                                $(".open-ended-11` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[10] != 'undefined'){
                                $(".open-ended-11` + response.Trait + `").html(res.data[10].` + response.Trait + `)
                              }
      
                              if(res.data.length < 12){
                                $(".open-ended-12` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[11] != 'undefined'){
                                $(".open-ended-12` + response.Trait + `").html(res.data[11].` + response.Trait + `)
                              }
      
                              if(res.data.length < 13){
                                $(".open-ended-13` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[12] != 'undefined'){
                                $(".open-ended-13` + response.Trait + `").html(res.data[12].` + response.Trait + `)
                              }
      
                              if(res.data.length < 14){
                                $(".open-ended-14` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[13] != 'undefined'){
                                $(".open-ended-14` + response.Trait + `").html(res.data[13].` + response.Trait + `)
                              }
      
                              if(res.data.length < 15){
                                $(".open-ended-15` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[14] != 'undefined'){
                                $(".open-ended-15` + response.Trait + `").html(res.data[14].` + response.Trait + `)
                              }
                              if(res.data.length < 16){
                                $(".open-ended-16` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[15] != 'undefined'){
                                $(".open-ended-16` + response.Trait + `").html(res.data[15].` + response.Trait + `)
                              }
                              if(res.data.length < 17){
                                $(".open-ended-17` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[16] != 'undefined'){
                                $(".open-ended-17` + response.Trait + `").html(res.data[16].` + response.Trait + `)
                              }
                              if(res.data.length < 18){
                                $(".open-ended-18` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[17] != 'undefined'){
                                $(".open-ended-18` + response.Trait + `").html(res.data[17].` + response.Trait + `)
                              }
                              if(res.data.length < 19){
                                $(".open-ended-19` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[18] != 'undefined'){
                                $(".open-ended-19` + response.Trait + `").html(res.data[18].` + response.Trait + `)
                              }
                              if(res.data.length < 20){
                                $(".open-ended-20` + response.Trait + `").css("display", "none")
                              }
                              if(typeof res.data[19] != 'undefined'){
                                $(".open-ended-20` + response.Trait + `").html(res.data[19].` + response.Trait + `)
                              }
                              
                              if(checkonlySpaces($(".open-ended-1` + response.Trait + `").html()) == true){
                                $(".open-ended-1` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-2` + response.Trait + `").html()) == true){
                                $(".open-ended-2` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-3` + response.Trait + `").html()) == true){
                                $(".open-ended-3` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-4` + response.Trait + `").html()) == true){
                                $(".open-ended-4` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-5` + response.Trait + `").html()) == true){
                                $(".open-ended-5` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-6` + response.Trait + `").html()) == true){
                                $(".open-ended-6` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-7` + response.Trait + `").html()) == true){
                                $(".open-ended-7` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-8` + response.Trait + `").html()) == true){
                                $(".open-ended-8` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-9` + response.Trait + `").html()) == true){
                                $(".open-ended-9` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-10` + response.Trait + `").html()) == true){
                                $(".open-ended-10` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-11` + response.Trait + `").html()) == true){
                                $(".open-ended-11` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-12` + response.Trait + `").html()) == true){
                                $(".open-ended-12` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-13` + response.Trait + `").html()) == true){
                                $(".open-ended-13` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-14` + response.Trait + `").html()) == true){
                                $(".open-ended-14` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-15` + response.Trait + `").html()) == true){
                                $(".open-ended-15` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-16` + response.Trait + `").html()) == true){
                                $(".open-ended-16` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-17` + response.Trait + `").html()) == true){
                                $(".open-ended-17` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-18` + response.Trait + `").html()) == true){
                                $(".open-ended-18` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-19` + response.Trait + `").html()) == true){
                                $(".open-ended-19` + response.Trait + `").html('No response provided')
                              }
                              if(checkonlySpaces($(".open-ended-20` + response.Trait + `").html()) == true){
                                $(".open-ended-20` + response.Trait + `").html('No response provided')
                              }
                            `
                          })
                        }
                      }
                     
                      
                    }
                  output += `
                  })
                  .catch((err) => {
                    console.log(err)
                  })

                    axios
                    .post(
                      '${url}/r360-report-data2',
                      {
                        "survey_assignment_id": survey_assignment_id,
                        "org_id": org_id,
                        "suborg_id": subOrg_id,
                        "program_id": program_id,
                        "iteration_id": iteration_id
                      },
                      config                                            
                    )
                    .then((res) => {
                      console.log(res.data)
                      const ordinal = (number) => {
                        const ordinalRules = new Intl.PluralRules("en", {
                          type: "ordinal"
                        });
                        const suffixes = {
                          one: "st",
                          two: "nd",
                          few: "rd",
                          other: "th"
                        };
                        const suffix = suffixes[ordinalRules.select(number)];
                        return (number + suffix);
                      }
        
                      function arrayRemoveZero(arr, value) { 
                        return arr.filter(function(ele){ 
                            return ele != value; 
                        });
                      }
                        function checkGroups(A, B, C, D) {
                          const group1 = [A, B];
                          const group2 = [C, D];

                          const group1HasZeroAndOne = group1.includes(0) && group1.includes(1);
                          const group2HasZeroAndOne = group2.includes(0) && group2.includes(1);
                          const group1HasTwoZeros = group1.every(val => val === 0);
                          const group2HasTwoZeros = group2.every(val => val === 0);

                          return group1HasZeroAndOne || group2HasZeroAndOne || (group1HasTwoZeros && group2HasTwoZeros);
                        }
                      `
                      // section 2
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i];
                        
                        if (data.Template === 'Bar-Chart-New-360-Head') { 
                          output += `
                            let avgCount = 1
                            let avgCohortCount = 1
                            let isDisplay_P = 0
                            let isDisplay_D = 0
                            let isDisplay_PD = 0
                            let isDisplay_I = 0
                            let isDisplay_E = 0
                            let isDisplay_IE = 0
                            let isDisplay_PDIE = 0
    
                            if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_PD = 1
                            } else {
                              isDisplay_P = 1
                              isDisplay_D = 1
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                            }
                            if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                              avgCount = avgCount + 1
                              avgCohortCount = avgCohortCount + 1
                              isDisplay_IE = 1
                            } else {
                              avgCount = avgCount + 2
                              avgCohortCount = avgCohortCount + 2
                              isDisplay_I = 1
                              isDisplay_E = 1
                            }
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                avgCount = 2
                                avgCohortCount = 2
                                isDisplay_PDIE = 1
                              }
                            `
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                                let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                                let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                                let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                                let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                                // MAIN 
    
                                let avgHeadRollup` + response.Trait + ` = 0
                                let cohortHeadavgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
    
                                  let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                  
                                  let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                  avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                  cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }

                                // Total
                                $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
      
                                $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
    
                                  let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                 
                                  let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                                  }  else {
                                      avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else{
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }  
                                  
                                  // Total
                                  $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                  $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                  $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  }
    
                                  else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                                 
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                    
                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  }
    
    
                                  if (isDisplay_PDIE === 1) {
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }

                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    }
    
                                    else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
    
                                    let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                    let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                    let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                 
                                  
                                    let HeadzeroCount` + response.Trait + ` = 0
                                  if (headRoundOff_ind_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                                  }

                                  let HeadzeroCohortCount` + response.Trait + ` = 0

                                  if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                                    avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                    
                                    
                                    // Total
                                    $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                                    $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)
    
                                    $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                    $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                    }
                                
                                // PRIMARY SUPERVISOR
                                $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers|Direct report
                                $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Internal stakeholder|External stakeholders
                                $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Internal stakeholder
                                $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Self
                                $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }

                                $('.front-page-for-individual-report').removeClass('d-none')
                                $('.front-page-psup-value').text(res.data[0].psup1_N)
                                $('.front-page-P-value').text(res.data[0].peer2_N)
                                $('.front-page-D-value').text(res.data[0].direct3_N)
                                $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                                $('.front-page-I-value').text(res.data[0].internal4_N)
                                $('.front-page-E-value').text(res.data[0].external5_N)
                                $('.front-page-IE-value').text(res.data[0].IE997_N)
                                $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                                  $(".front-page-P").css("display", "none")
                                  $(".front-page-D").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                                  $(".front-page-PD").css("display", "none")
                                }
                                
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                                  $(".front-page-I").css("display", "none")
                                  $(".front-page-E").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                                  $(".front-page-IE").css("display", "none")
                                }
                                
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                                  $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                                  $(".front-page-P").css("display", "none")
                                  $(".front-page-D").css("display", "none")
                                  $(".front-page-I").css("display", "none")
                                  $(".front-page-E").css("display", "none")
                                  $(".front-page-PD").css("display", "none")
                                  $(".front-page-IE").css("display", "none")
                                } else {
                                  $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                                  $(".front-page-PDIE").css("display", "none")
                                }
                              `
                            })
                          }
                          output += `
                            $('.360_head').removeClass('d-none')
                            $('.360_head_spinner').addClass('d-none')
                          `
                        }

                        if (data.Template === 'Dynamic-Message-Head-New') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                              output += 
                              `
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
    
                              if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                              } else {
                                $(".Head-Dynamic-Message").html("` + res.Message + `")
                              }
                              `
                          }
                        }

                        if (data.Template === 'Bar-Chart-New-360-Head') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                // PRIMARY SUPERVISOR
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Peers|Direct reports
                                $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Internal stakeholder
                                $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                let avgRollup` + response.Trait + ` = 0
                                let cohortavgRollup` + response.Trait + ` = 0
                                let cohortSelfAvgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  console.log('head')
                                  console.log('PD + I + E')

                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
    
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                  } 
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  console.log('head')
                                  console.log('IE + P + D')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  console.log('head')
                                  console.log('PD + IE')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }

                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                if (isDisplay_PDIE === 1) {
                                  console.log('head')
                                  console.log('PDIE')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                } 
                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  console.log('head')
                                  console.log('P + D + I + E')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                  
                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
                                
      
                                // Self
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                               
                                if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                }
    
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                                }
    
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }
                               if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                               }
                               
                              `
                              
                            })
                          }
                          output += `
                            $('.360_sub').removeClass('d-none')
                            $('.360_sub_spinner').addClass('d-none')
                          `
                        }
                        
                        // slider section 2
                        if (data.Template === 'Bar-Chart-New-360-Head') { 
                                let obj = JSON.parse(data.Text)
                                for (const res of obj) {
                                  res.Competencies.forEach((resCompetency) => {
                                    output += `
                                      //
                                      let avgRollup` + resCompetency.Acronym + ` = 0
                                let cohortavgRollup` + resCompetency.Acronym + ` = 0
                                let cohortSelfAvgRollup` + resCompetency.Acronym + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  let roundOff_ind_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                
                                  let zeroCount` + resCompetency.Acronym + ` = 0
                                  if (roundOff_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_PD996_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_internal4_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_external5_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }

                                  let zeroCohortCount` + resCompetency.Acronym + ` = 0

                                  if (roundOff_cohort_self0_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }

                                  if (avgCount === zeroCount` + resCompetency.Acronym + `) {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_PD996_` + resCompetency.Acronym + ` + roundOff_internal4_` + resCompetency.Acronym + ` + roundOff_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_PD996_` + resCompetency.Acronym + ` + roundOff_internal4_` + resCompetency.Acronym + ` + roundOff_external5_` + resCompetency.Acronym + `) / (avgCount - zeroCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `) / (avgCohortCount - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `) / ((avgCohortCount+1) - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)  
                                  }
                                  
                                  
          
                                  } 
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  let roundOff_ind_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  
                                  let zeroCount` + resCompetency.Acronym + ` = 0
                                  if (roundOff_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_direct3_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_peer2_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_IE997_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }

                                  let zeroCohortCount` + resCompetency.Acronym + ` = 0

                                  if (roundOff_cohort_self0_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }

                                  if (avgCount === zeroCount` + resCompetency.Acronym + `) {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_IE997_` + resCompetency.Acronym + ` + roundOff_direct3_` + resCompetency.Acronym + ` + roundOff_peer2_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_IE997_` + resCompetency.Acronym + ` + roundOff_direct3_` + resCompetency.Acronym + ` + roundOff_peer2_` + resCompetency.Acronym + `) / (avgCount - zeroCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + `) / (avgCohortCount - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1) 
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + `) / ((avgCohortCount+1) - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1) 
                                  }
                                  
                                  
                               
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  let roundOff_ind_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + resCompetency.Acronym + ` = 0
                                  if (roundOff_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_PD996_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_IE997_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }

                                  let zeroCohortCount` + resCompetency.Acronym + ` = 0

                                  if (roundOff_cohort_self0_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }

                                  if (avgCount === zeroCount` + resCompetency.Acronym + `) {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_IE997_` + resCompetency.Acronym + ` + roundOff_PD996_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_IE997_` + resCompetency.Acronym + ` + roundOff_PD996_` + resCompetency.Acronym + `) / (avgCount - zeroCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + `) / (avgCohortCount - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1) 
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_IE997_` + resCompetency.Acronym + ` + roundOff_cohort_PD996_` + resCompetency.Acronym + `) / ((avgCohortCount+1) - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1) 
                                  }

                                   }
    
                                if (isDisplay_PDIE === 1) {
                                  let roundOff_ind_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + resCompetency.Acronym + ` = 0
                                  if (roundOff_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_PDIE998_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }

                                  let zeroCohortCount` + resCompetency.Acronym + ` = 0

                                  if (roundOff_cohort_self0_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }

                                  if (avgCount === zeroCount` + resCompetency.Acronym + `) {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_PDIE998_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_PDIE998_` + resCompetency.Acronym + `) / (avgCount - zeroCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PDIE998_` + resCompetency.Acronym + `) / (avgCohortCount - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_PDIE998_` + resCompetency.Acronym + `) / ((avgCohortCount+1) - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  
                                 } 
                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  let roundOff_ind_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_external5_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + resCompetency.Acronym + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + resCompetency.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + resCompetency.Acronym + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + resCompetency.Acronym + ` = 0
                                  if (roundOff_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_internal4_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_external5_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_direct3_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_peer2_` + resCompetency.Acronym + ` === 0) {
                                    zeroCount` + resCompetency.Acronym + ` = zeroCount` + resCompetency.Acronym + ` + 1
                                  }

                                  let zeroCohortCount` + resCompetency.Acronym + ` = 0

                                  if (roundOff_cohort_self0_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + resCompetency.Acronym + ` === 0) {
                                    zeroCohortCount` + resCompetency.Acronym + ` = zeroCohortCount` + resCompetency.Acronym + ` + 1
                                  }

                                  if (avgCount === zeroCount` + resCompetency.Acronym + `) {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_peer2_` + resCompetency.Acronym + ` + roundOff_direct3_` + resCompetency.Acronym + ` + roundOff_internal4_` + resCompetency.Acronym + ` + roundOff_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    avgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_psup1_` + resCompetency.Acronym + ` + roundOff_peer2_` + resCompetency.Acronym + ` + roundOff_direct3_` + resCompetency.Acronym + ` + roundOff_internal4_` + resCompetency.Acronym + ` + roundOff_external5_` + resCompetency.Acronym + `) / (avgCount - zeroCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `) / (avgCohortCount - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + resCompetency.Acronym + `) {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs(roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + resCompetency.Acronym + ` = Math.abs((roundOff_cohort_self0_` + resCompetency.Acronym + ` + roundOff_cohort_psup1_` + resCompetency.Acronym + ` + roundOff_cohort_peer2_` + resCompetency.Acronym + ` + roundOff_cohort_direct3_` + resCompetency.Acronym + ` + roundOff_cohort_internal4_` + resCompetency.Acronym + ` + roundOff_cohort_external5_` + resCompetency.Acronym + `) / ((avgCohortCount+1) - zeroCohortCount` + resCompetency.Acronym + `)).toFixed(1)
                                  }
                                  
                                  
                                  }

                                if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                             
                                //
                                $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(avgRollup`+resCompetency.Acronym+`).toFixed(1));
                                $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));                                

                                $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( parseFloat(avgRollup`+resCompetency.Acronym+`).toFixed(1) - 1) * 25)+'%');
                                $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1) - 1) * 25)+'%');

                                `
                              })
                                }
                                }
                        if (data.Template === 'Bar-Chart-New-360-Sub') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                // PRIMARY SUPERVISOR
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Peers|Direct reports
                                $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Internal stakeholder
                                $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // let avgRollup` + response.Trait + ` = 0
                                // let cohortavgRollup` + response.Trait + ` = 0
                                // let cohortSelfAvgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  console.log('PD + I + E')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }
                                  
                                  //display - Emil
                                    // $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")
                                    // $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                                    // $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                                    // $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                                    // $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                                    // $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                                    // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                                    // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                                    // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                                    // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                                    


                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
    
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                  } 
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  console.log('IE + P + D')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  
                                  //display - Emil
                                  // $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")

                                  // $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                                 
                                  // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  console.log('IE + PD')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }

                                  //display - Emil
                                  // $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")

                                  // $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")

                                  // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                                  

                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                if (isDisplay_PDIE === 1) {
                                  console.log('PDIE')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }

                                  //display - Emil
                                  // $(".custom-bar-graph-`+response.Trait+`-PDIE").removeClass("d-none")

                                  // $(".bd-key-`+res.Elements[0].Trait+`-PDIE").removeClass("d-none")

                                  // $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())

                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                } 
                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  console.log('P + D + I + E')
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                  //display - Emil
                                  // $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                                  // $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                                  // $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                                  // $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                                  // $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                                  // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())


                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
                                // dito ilalagay code
                                //$(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                                // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())

                                $(".bd-`+res.Elements[0].Trait+`-value-P").text((index,currentText)=>{
                                  return res.data[0].peer2_N=== null ? 0 : +parseFloat(res.data[0].peer2_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-D").text((index,currentText)=>{
                                  return res.data[0].direct3_N=== null ? 0 : +parseFloat(res.data[0].direct3_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-I").text((index,currentText)=>{
                                  return res.data[0].internal4_N=== null ? 0 : +parseFloat(res.data[0].internal4_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-E").text((index,currentText)=>{
                                  return res.data[0].external5_N=== null ? 0 : +parseFloat(res.data[0].external5_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text((index,currentText)=>{
                                  return res.data[0].PDIE998_N === null ? 0 : +parseFloat(res.data[0].PDIE998_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-IE").text((index,currentText)=>{
                                  return res.data[0].IE997_N=== null ? 0 : +parseFloat(res.data[0].IE997_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-PD").text((index,currentText)=>{
                                  return res.data[0].PD996_N=== null ? 0 : +parseFloat(res.data[0].PD996_N).toFixed()     
                                })
                                $(".bd-`+res.Elements[0].Trait+`-value-O").text((index,currentText)=>{
                                  return res.data[0].all_nom999_N=== null ? 0 : +parseFloat(res.data[0].all_nom999_N).toFixed()     
                                })
                                  

                                //Display - Emil
                                
                                // Primary Supervisor
                                $(".custom-bar-graph-`+response.Trait+`-PS").removeClass("d-none")
                                $(".span-`+response.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                
                                $(".bd-key-`+ res.Elements[0].Trait +`-PS").removeClass("d-none")
                                //$(".bd-`+res.Elements[0].Trait+`-value-PS").text(+parseFloat(res.data[0].psup1_N).toFixed())
                                $(".bd-`+res.Elements[0].Trait+`-value-PS").text((index,currentText)=>{
                                  return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                                })
                                
                       

                                if(parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-PS").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                  
                                  
                                //Direct Reports
                                $(".span-`+response.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))

                                if(parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-D").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                

                                //Internal and External Stakeholders
                                $(".span-`+response.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                if(parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-IE").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                

                                //Internal Stakeholders
                                $(".span-`+response.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))

                                if(parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-I").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                

                                //External Stakeholders
                                $(".span-`+response.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))

                                if(parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-E").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                              

                                // Self
                                $(".custom-bar-graph-`+response.Trait+`-S").removeClass("d-none")
                                $(".span-`+response.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".bd-`+res.Elements[0].Trait+`-value-S").text((index,currentText)=>{
                                  return res.data[0].ind_N=== null ? 0 : +parseFloat(res.data[0].ind_N).toFixed()     
                                })
   

                                if(parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-S").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                

                                //Peers
                                $(".span-`+response.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))

                                if(parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-P").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }
                                

                                //Peers and Direct Reports
                                $(".span-`+response.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                if(parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-PD").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }

                                //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                                $(".span-`+response.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                if(parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-PDIE").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                                }

                                //All Nominees
                                $(".span-`+response.Trait+`-value-Others").text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                                if(parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1)) < 1) {
                                  $(".position-`+response.Trait+`-Others").css('left', '-1%')
                                }
                                else {
                                  $(".position-`+response.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1) - 1) * 25 + '%')
                                }
                               

                                // Average Others
                                $(".custom-bar-graph-`+response.Trait+`-Others").removeClass("d-none")
                                 $('.averageOthers-`+response.Trait+`').text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                                 $('.averageOthers-`+response.Trait+`').css('color','rgb(84,130,53)')
                                  // End of Display - Emil


      
                                // Self
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                                
                              if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                $(".custom-bar-graph-`+response.Trait+`-P").addClass("d-none")
                                $(".bd-key-`+res.Elements[0].Trait+`-P").addClass("d-none")
                                
                                $(".custom-bar-graph-`+response.Trait+`-D").addClass("d-none")
                                $(".bd-key-`+res.Elements[0].Trait+`-D").addClass("d-none")

                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                  $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")

                                  $(".custom-bar-graph-`+response.Trait+`-I").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-E").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-I").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-E").addClass("d-none")

                                } else {
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")

                                  $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                                }
    
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")

                                  $(".custom-bar-graph-`+response.Trait+`-P").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-D").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-I").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-E").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                                  $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")

                                  $(".bd-key-`+res.Elements[0].Trait+`-P").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-D").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-I").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-E").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                                  $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")

                                } else {
                                  $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")

                                  $(".custom-bar-graph-`+response.Trait+`-PDIE").addClass("d-none")

                                  $(".bd-key-`+res.Elements[0].Trait+`-PDIE").addClass("d-none")
                                }
    
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }
                               if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                               }
                              `
                            })
                          }
                          output += `
                            $('.360_sub').removeClass('d-none')
                            $('.360_sub_spinner').addClass('d-none')
                          `
                        }
                        // Top-Behaviours section 2
                        if(data.Template === 'Bar-Chart-New-360-Head'){
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                                $(".top_behaviours_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                              `
                            })
                            output += `
                              $('.top-behaviours-sortme').sort(function(a, b) {
                                if (a.textContent > b.textContent) {
                                  return -1;
                                } else {
                                  return 1;
                                }
                              }).appendTo($('.top-behaviours-wrapper'));

                              const topBehavioursStartLength = 3
                              const topBehavioursMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                              $('.top-behaviours-sortme').slice(topBehavioursStartLength, topBehavioursMaxLength).hide();
                              $('.top-behaviours-average-score').hide()
                            `
                          }
                        }
                        // for Undiscovered-Strengths section 2
                        if(data.Template === 'Bar-Chart-New-360-Head'){
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                                if( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) >= 2 ){
                                  $(".undiscovered-strengths-row-` + response.Trait + `").removeClass("d-none")
                                }
                              `
                            })

                            output +=`
                              const undiscoveredStrengthsAllHidden = $("#undiscovered-strengths-wrapper li:not(.d-none)").length === 0;
                              if (undiscoveredStrengthsAllHidden) {
                                $('.undiscovered-strengths-all-rows').addClass('d-none')
                                $('.undiscovered-strengths-message').removeClass('d-none')
                                console.log("All <li> elements have class 'd-none'.");
                              } else {
                                console.log("Not all <li> elements have class 'd-none'.");
                              }
                            `
                          }
                        }
                        // for Potential-Blind-Spots section 2
                        if(data.Template === 'Bar-Chart-New-360-Head'){
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
                                if( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                                  $(".potential-blind-spots-row-` + response.Trait + `").removeClass("d-none")
                                }
                              `
                            })

                            output +=`
                              const isPotentialBlindSpotsAllHidden = $("#potential-blind-spots-wrapper li:not(.d-none)").length === 0;
                              if (isPotentialBlindSpotsAllHidden) {
                                $('.potential-blind-spots-all-rows').addClass('d-none')
                                $('.potential-blind-spots-message').removeClass('d-none')
                                console.log("All <li> elements have class 'd-none'.");
                              } else {
                                console.log("Not all <li> elements have class 'd-none'.");
                              }
                            `
                          }
                        }
                        //for Organizational Climate Snaphot section 2
                        if (data.Template === 'Bar-Chart-New-360-Head') { 
                          let obj = JSON.parse(data.Text)
                          
                          for (const res of obj) {
                            console.log("Obj:",res)
                            output += `
                                let ` + res.ocsSuperTrait + `chart = document.querySelectorAll('.` + res.ocsSuperTrait + `')
                                new Chart(`+ res.ocsSuperTrait +`chart,{
                                  type: "line",
                                  data:{
                                    labels: [
                                        ['ER','Emotional', 'Reactivity'],
                                        ['E','Extraversion'],
                                        ['O','Openness', 'to Change'],
                                        ['A','Agreeableness'], 
                                        ['C','Conscientiousness']
                                    ],
                                    datasets:[{
                                        label: "S",
                                        data: [
                                          res.data[0].ind_ERX ? res.data[0].ind_ERX : 0, 
                                          res.data[0].ind_EX ? res.data[0].ind_EX : 0, 
                                          res.data[0].ind_OX ? res.data[0].ind_OX : 0, 
                                          res.data[0].ind_AX ? res.data[0].ind_AX : 0, 
                                          res.data[0].ind_CX ? res.data[0].ind_CX : 0
                                        ],
                                        borderColor: '`+ res.Colors.color_s +`'
                                      },
                                      {
                                        label: "O",
                                        data: [
                                          res.data[0].all_nom999_ERX ? res.data[0].all_nom999_ERX : 0,
                                          res.data[0].all_nom999_EX ? res.data[0].all_nom999_EX : 0,
                                          res.data[0].all_nom999_OX ? res.data[0].all_nom999_OX : 0,
                                          res.data[0].all_nom999_AX ? res.data[0].all_nom999_AX : 0,
                                          res.data[0].all_nom999_CX ? res.data[0].all_nom999_CX : 0
                                        ],
                                        borderColor: '`+ res.Colors.color_o +`'
                                      }]
                                  },
                                  options: {
                                    scales: {
                                      xAxis: {position: 'top'},
                                      y: {
                                        beginAtZero: true,
                                        min: 0,
                                        max: 5,
                                        ticks: {
                                          stepSize: 1,
                                        }
                                      }
                                    },
                                    plugins: {
                                      legend: {
                                        position: "left"
                                      }
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false
                                  }
                                })
                              ` 
                          }
                        }
                        
                        // Competency Importance Index -ranking in section 2
                        if (data.Template === 'Bar-Chart-New-360-Head') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.ciiCompetencies.forEach((response) => {
                              res.Competencies.forEach(competency=>{
                                
                              
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                    $(".competency-ind_` + response.Trait + `").html("N/A")
                                    $(".sub-heading-text-CII_new_`+competency.Trait+`_s-new").html("N/A")
                                    
                                    
                                }
                                else{
                                    $(".competency-ind_` + response.Trait + `").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                                    $(".sub-heading-text-CII_new_`+competency.Trait+`_s_new").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                                }
                                if(res.data[0].psup1_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-psup1` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-psup1` + response.Trait + `").html(ordinal(Math.round(res.data[0].psup1_` + response.Trait + `)))
                                }
                                if(res.data[0].peer2_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-peer2_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-peer2_` + response.Trait + `").html(ordinal(Math.round(res.data[0].peer2_` + response.Trait + `)))
                                }
                                if(res.data[0].direct3_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-direct3_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-direct3_` + response.Trait + `").html(ordinal(Math.round(res.data[0].direct3_` + response.Trait + `)))
                                }
                                if(res.data[0].PD996_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-PD996_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-PD996_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PD996_` + response.Trait + `)))
                                }
                                if(res.data[0].internal4_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-internal4_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-internal4_` + response.Trait + `").html(ordinal(Math.round(res.data[0].internal4_` + response.Trait + `)))
                                }
                                if(res.data[0].external5_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-external5_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-external5_` + response.Trait + `").html(ordinal(Math.round(res.data[0].external5_` + response.Trait + `)))
                                }
                                if(res.data[0].IE997_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-IE997_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-IE997_` + response.Trait + `").html(ordinal(Math.round(res.data[0].IE997_` + response.Trait + `)))
                                }
                                if(res.data[0].PDIE998_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-PDIE998_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-PDIE998_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PDIE998_` + response.Trait + `)))
                                }
                                if(res.data[0].all_nom999_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-all_nom999_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-all_nom999_` + response.Trait + `").html(ordinal(Math.round(res.data[0].all_nom999_` + response.Trait + `)))
                                }
                                if(res.data[0].cohort_all_1000_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".competency-cohort_all_1000_` + response.Trait + `").html("N/A")
                                }
                                else{
                                  $(".competency-cohort_all_1000_` + response.Trait + `").html(ordinal(Math.round(res.data[0].cohort_all_1000_` + response.Trait + `)))
                                }
                              `
                            })
                          })
                            output += `
                            if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
    
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $('.heading-label-CII-peers').addClass('d-none')
                                  $('.sub-heading-score-CII-peers').addClass('d-none')
                                  $('.heading-label-CII-direct').addClass('d-none')
                                  $('.sub-heading-score-CII-direct').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-pd').addClass('d-none')
                                  $('.sub-heading-score-CII-pd').addClass('d-none')
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                 $('.heading-label-CII-internal').addClass('d-none')
                                  $('.sub-heading-score-CII-internal').addClass('d-none')
                                  $('.heading-label-CII-external').addClass('d-none')
                                  $('.sub-heading-score-CII-external').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-ie').addClass('d-none')
                                  $('.sub-heading-score-CII-ie').addClass('d-none')
                                }
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $('.heading-label-CII-peers').addClass('d-none')
                                  $('.sub-heading-score-CII-peers').addClass('d-none')
                                  $('.heading-label-CII-direct').addClass('d-none')
                                  $('.sub-heading-score-CII-direct').addClass('d-none')
                                  $('.heading-label-CII-internal').addClass('d-none')
                                  $('.sub-heading-score-CII-internal').addClass('d-none')
                                  $('.heading-label-CII-external').addClass('d-none')
                                  $('.sub-heading-score-CII-external').addClass('d-none')
                                  $('.heading-label-CII-pd').addClass('d-none')
                                  $('.sub-heading-score-CII-pd').addClass('d-none')
                                  $('.heading-label-CII-ie').addClass('d-none')
                                  $('.sub-heading-score-CII-ie').addClass('d-none')
                                } else {
                                  $('.heading-label-CII-pdie').addClass('d-none')
                                  $('.sub-heading-score-CII-pdie').addClass('d-none')
                                } 
                            `
                            output +=
                              `
                            //start PSup ranking
                            if(res.data[0].psup1_N > 1){
                              let psup1_arrCounter = ['','','','','','']
                              let psup1_arr6 = ['','','','','','']
                              let psup1_arr5 = ['','','','','','']
                              let psup1_arr4 = ['','','','','','']
                              let psup1_arr3 = ['','','','','','']
                              let psup1_arr2 = ['','','','','','']
                              let psup1_arr1 = ['','','','','','']
                              let psup1_arrForMaxMin = ['','','','','','']
                              let psup1_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-psup1CII6").html() == "1st"){
                                psup1_arr6[0] = psup1_arr6[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII6").html() == "2nd"){
                                psup1_arr6[1] = psup1_arr6[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII6").html() == "3rd"){
                                psup1_arr6[2] = psup1_arr6[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[1]+'3'
                              }
                              if($(".competency-psup1CII6").html() == "4th"){
                                psup1_arr6[3] = psup1_arr6[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII6").html() == "5th"){
                                psup1_arr6[4] = psup1_arr6[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII6").html() == "6th"){
                                psup1_arr6[5] = psup1_arr6[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-psup1CII5").html() == "1st"){
                                psup1_arr5[0] = psup1_arr5[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII5").html() == "2nd"){
                                psup1_arr5[1] = psup1_arr5[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII5").html() == "3rd"){
                                psup1_arr5[2] = psup1_arr5[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII5").html() == "4th"){
                                psup1_arr5[3] = psup1_arr5[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII5").html() == "5th"){
                                psup1_arr5[4] = psup1_arr5[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII5").html() == "6th"){
                                psup1_arr5[5] = psup1_arr5[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-psup1CII4").html() == "1st"){
                                psup1_arr4[0] = psup1_arr4[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII4").html() == "2nd"){
                                psup1_arr4[1] = psup1_arr4[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII4").html() == "3rd"){
                                psup1_arr4[2] = psup1_arr4[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII4").html() == "4th"){
                                psup1_arr4[3] = psup1_arr4[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII4").html() == "5th"){
                                psup1_arr4[4] = psup1_arr4[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII4").html() == "6th"){
                                psup1_arr4[5] = psup1_arr4[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-psup1CII3").html() == "1st"){
                                psup1_arr3[0] = psup1_arr3[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII3").html() == "2nd"){
                                psup1_arr3[1] = psup1_arr3[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII3").html() == "3rd"){
                                psup1_arr3[2] = psup1_arr3[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII3").html() == "4th"){
                                psup1_arr3[3] = psup1_arr3[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII3").html() == "5th"){
                                psup1_arr3[4] = psup1_arr3[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII3").html() == "6th"){
                                psup1_arr3[5] = psup1_arr3[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-psup1CII2").html() == "1st"){
                                psup1_arr2[0] = psup1_arr2[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII2").html() == "2nd"){
                                psup1_arr2[1] = psup1_arr2[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII2").html() == "3rd"){
                                psup1_arr2[2] = psup1_arr2[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII2").html() == "4th"){
                                psup1_arr2[3] = psup1_arr2[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII2").html() == "5th"){
                                psup1_arr2[4] = psup1_arr2[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII2").html() == "6th"){
                                psup1_arr2[5] = psup1_arr2[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-psup1CII1").html() == "1st"){
                                psup1_arr1[0] = psup1_arr1[0]+'1'
                                psup1_arrForMaxMin[0] = psup1_arrForMaxMin[0] = 1
                                psup1_arrCounter[0] = psup1_arrCounter[0]+'1'
                              }
                              if($(".competency-psup1CII1").html() == "2nd"){
                                psup1_arr1[1] = psup1_arr1[1]+'2'
                                psup1_arrForMaxMin[1] = psup1_arrForMaxMin[1] = 2
                                psup1_arrCounter[1] = psup1_arrCounter[1]+'2'
                              }
                              if($(".competency-psup1CII1").html() == "3rd"){
                                psup1_arr1[2] = psup1_arr1[2]+'3'
                                psup1_arrForMaxMin[2] = psup1_arrForMaxMin[2] = 3
                                psup1_arrCounter[2] = psup1_arrCounter[2]+'3'
                              }
                              if($(".competency-psup1CII1").html() == "4th"){
                                psup1_arr1[3] = psup1_arr1[3]+'4'
                                psup1_arrForMaxMin[3] = psup1_arrForMaxMin[3] = 4
                                psup1_arrCounter[3] = psup1_arrCounter[3]+'4'
                              }
                              if($(".competency-psup1CII1").html() == "5th"){
                                psup1_arr1[4] = psup1_arr1[4]+'5'
                                psup1_arrForMaxMin[4] = psup1_arrForMaxMin[4] = 5
                                psup1_arrCounter[4] = psup1_arrCounter[4]+'5'
                              }
                              if($(".competency-psup1CII1").html() == "6th"){
                                psup1_arr1[5] = psup1_arr1[5]+'6'
                                psup1_arrForMaxMin[5] = psup1_arrForMaxMin[5] = 6
                                psup1_arrCounter[5] = psup1_arrCounter[5]+'6'
                              }
             
                            //condition here
                            psup1_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  psup1_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue0 = Math.min(...psup1_arrForMaxMinCleaned)
                              let maxValue0 = Math.max(...psup1_arrForMaxMinCleaned)
                              let firstCount0 = 0
                              let secondCount0 = 0
                              let thirdCount0 = 0
                              let fourthCount0 = 0
                              let fifthCount0 = 0
                              let sixthCount0 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(psup1_arr6[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII6").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII6").html("=1st")
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII5").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII5").html("=1st")
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII4").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII4").html("=1st")
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII3").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII3").html("=1st")
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII2").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII2").html("=1st")
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0 && psup1_arrCounter[x].includes(minValue0)){
                                  $(".competency-psup1CII1").html("1st")
                                  firstCount0 = psup1_arrCounter[x].length
                                  if(firstCount0 > 1){
                                    $(".competency-psup1CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(psup1_arr6[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+1 && psup1_arrCounter[x].includes(minValue0+1)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+1)))
                                  secondCount0 = psup1_arrCounter[x].length
                                  if(secondCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(psup1_arr6[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+2 && psup1_arrCounter[x].includes(minValue0+2)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+1)))
                                  thirdCount0 = psup1_arrCounter[x].length
                                  if(thirdCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(psup1_arr6[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+3 && psup1_arrCounter[x].includes(minValue0+3)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  fourthCount0 = psup1_arrCounter[x].length
                                  if(fourthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(psup1_arr6[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+4 && psup1_arrCounter[x].includes(minValue0+4)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  fifthCount0 = psup1_arrCounter[x].length
                                  if(fifthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(psup1_arr6[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII6").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII6").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                 
                                if(psup1_arr5[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII5").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII5").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                       
                                if(psup1_arr4[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII4").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII4").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                          
                                if(psup1_arr3[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII3").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII3").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                        
                                if(psup1_arr2[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII2").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII2").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                           
                                if(psup1_arr1[x] == minValue0+5 && psup1_arrCounter[x].includes(minValue0+5)){
                                  $(".competency-psup1CII1").html(ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  sixthCount0 = psup1_arrCounter[x].length
                                  if(sixthCount0 > 1){
                                    $(".competency-psup1CII1").html("=" + ordinal(Math.round(firstCount0+secondCount0+thirdCount0+fourthCount0+fifthCount0+1)))
                                  }
                                }
                              //end of loop
                          }
                          //End of PSup Ranking
                        }
                        // end of PSup if statement
    
                          //start cohort ranking
                              let cohortCII_arrCounter = ['','','','','','']
                              let cohortCII_arr6 = ['','','','','','']
                              let cohortCII_arr5 = ['','','','','','']
                              let cohortCII_arr4 = ['','','','','','']
                              let cohortCII_arr3 = ['','','','','','']
                              let cohortCII_arr2 = ['','','','','','']
                              let cohortCII_arr1 = ['','','','','','']
                              let cohortCII_arrForMaxMin = ['','','','','','']
                              let cohortCII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-cohort_all_1000_CII6").html() == "1st"){
                                cohortCII_arr6[0] = cohortCII_arr6[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "2nd"){
                                cohortCII_arr6[1] = cohortCII_arr6[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "3rd"){
                                cohortCII_arr6[2] = cohortCII_arr6[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "4th"){
                                cohortCII_arr6[3] = cohortCII_arr6[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "5th"){
                                cohortCII_arr6[4] = cohortCII_arr6[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII6").html() == "6th"){
                                cohortCII_arr6[5] = cohortCII_arr6[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-cohort_all_1000_CII5").html() == "1st"){
                                cohortCII_arr5[0] = cohortCII_arr5[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "2nd"){
                                cohortCII_arr5[1] = cohortCII_arr5[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "3rd"){
                                cohortCII_arr5[2] = cohortCII_arr5[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "4th"){
                                cohortCII_arr5[3] = cohortCII_arr5[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "5th"){
                                cohortCII_arr5[4] = cohortCII_arr5[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII5").html() == "6th"){
                                cohortCII_arr5[5] = cohortCII_arr5[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-cohort_all_1000_CII4").html() == "1st"){
                                cohortCII_arr4[0] = cohortCII_arr4[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "2nd"){
                                cohortCII_arr4[1] = cohortCII_arr4[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "3rd"){
                                cohortCII_arr4[2] = cohortCII_arr4[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "4th"){
                                cohortCII_arr4[3] = cohortCII_arr4[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "5th"){
                                cohortCII_arr4[4] = cohortCII_arr4[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII4").html() == "6th"){
                                cohortCII_arr4[5] = cohortCII_arr4[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-cohort_all_1000_CII3").html() == "1st"){
                                cohortCII_arr3[0] = cohortCII_arr3[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "2nd"){
                                cohortCII_arr3[1] = cohortCII_arr3[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "3rd"){
                                cohortCII_arr3[2] = cohortCII_arr3[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "4th"){
                                cohortCII_arr3[3] = cohortCII_arr3[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "5th"){
                                cohortCII_arr3[4] = cohortCII_arr3[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII3").html() == "6th"){
                                cohortCII_arr3[5] = cohortCII_arr3[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-cohort_all_1000_CII2").html() == "1st"){
                                cohortCII_arr2[0] = cohortCII_arr2[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "2nd"){
                                cohortCII_arr2[1] = cohortCII_arr2[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "3rd"){
                                cohortCII_arr2[2] = cohortCII_arr2[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "4th"){
                                cohortCII_arr2[3] = cohortCII_arr2[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "5th"){
                                cohortCII_arr2[4] = cohortCII_arr2[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII2").html() == "6th"){
                                cohortCII_arr2[5] = cohortCII_arr2[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-cohort_all_1000_CII1").html() == "1st"){
                                cohortCII_arr1[0] = cohortCII_arr1[0]+'1'
                                cohortCII_arrForMaxMin[0] = cohortCII_arrForMaxMin[0] = 1
                                cohortCII_arrCounter[0] = cohortCII_arrCounter[0]+'1'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "2nd"){
                                cohortCII_arr1[1] = cohortCII_arr1[1]+'2'
                                cohortCII_arrForMaxMin[1] = cohortCII_arrForMaxMin[1] = 2
                                cohortCII_arrCounter[1] = cohortCII_arrCounter[1]+'2'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "3rd"){
                                cohortCII_arr1[2] = cohortCII_arr1[2]+'3'
                                cohortCII_arrForMaxMin[2] = cohortCII_arrForMaxMin[2] = 3
                                cohortCII_arrCounter[2] = cohortCII_arrCounter[2]+'3'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "4th"){
                                cohortCII_arr1[3] = cohortCII_arr1[3]+'4'
                                cohortCII_arrForMaxMin[3] = cohortCII_arrForMaxMin[3] = 4
                                cohortCII_arrCounter[3] = cohortCII_arrCounter[3]+'4'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "5th"){
                                cohortCII_arr1[4] = cohortCII_arr1[4]+'5'
                                cohortCII_arrForMaxMin[4] = cohortCII_arrForMaxMin[4] = 5
                                cohortCII_arrCounter[4] = cohortCII_arrCounter[4]+'5'
                              }
                              if($(".competency-cohort_all_1000_CII1").html() == "6th"){
                                cohortCII_arr1[5] = cohortCII_arr1[5]+'6'
                                cohortCII_arrForMaxMin[5] = cohortCII_arrForMaxMin[5] = 6
                                cohortCII_arrCounter[5] = cohortCII_arrCounter[5]+'6'
                              }
             
                            //condition here
                            cohortCII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  cohortCII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue = Math.min(...cohortCII_arrForMaxMinCleaned)
                              let maxValue = Math.max(...cohortCII_arrForMaxMinCleaned)
                              let firstCount = 0
                              let secondCount = 0
                              let thirdCount = 0
                              let fourthCount = 0
                              let fifthCount = 0
                              let sixthCount = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(cohortCII_arr6[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII6").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=1st")
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII5").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=1st")
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII4").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=1st")
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII3").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=1st")
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII2").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=1st")
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue && cohortCII_arrCounter[x].includes(minValue)){
                                  $(".competency-cohort_all_1000_CII1").html("1st")
                                  firstCount = cohortCII_arrCounter[x].length
                                  if(firstCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(cohortCII_arr6[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+1 && cohortCII_arrCounter[x].includes(minValue+1)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+1)))
                                  secondCount = cohortCII_arrCounter[x].length
                                  if(secondCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(cohortCII_arr6[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+2 && cohortCII_arrCounter[x].includes(minValue+2)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+1)))
                                  thirdCount = cohortCII_arrCounter[x].length
                                  if(thirdCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(cohortCII_arr6[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+3 && cohortCII_arrCounter[x].includes(minValue+3)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  fourthCount = cohortCII_arrCounter[x].length
                                  if(fourthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(cohortCII_arr6[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+4 && cohortCII_arrCounter[x].includes(minValue+4)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  fifthCount = cohortCII_arrCounter[x].length
                                  if(fifthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(cohortCII_arr6[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII6").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII6").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                 
                                if(cohortCII_arr5[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII5").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII5").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                       
                                if(cohortCII_arr4[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII4").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII4").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                          
                                if(cohortCII_arr3[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII3").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII3").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                        
                                if(cohortCII_arr2[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII2").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII2").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                           
                                if(cohortCII_arr1[x] == minValue+5 && cohortCII_arrCounter[x].includes(minValue+5)){
                                  $(".competency-cohort_all_1000_CII1").html(ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  sixthCount = cohortCII_arrCounter[x].length
                                  if(sixthCount > 1){
                                    $(".competency-cohort_all_1000_CII1").html("=" + ordinal(Math.round(firstCount+secondCount+thirdCount+fourthCount+fifthCount+1)))
                                  }
                                }
                              //end of loop
                              }
                            //End of Cohort Ranking
    
                          //start my ranking
                              let all_nomCII_arrCounter = ['','','','','','']
                              let all_nomCII_arr6 = ['','','','','','']
                              let all_nomCII_arr5 = ['','','','','','']
                              let all_nomCII_arr4 = ['','','','','','']
                              let all_nomCII_arr3 = ['','','','','','']
                              let all_nomCII_arr2 = ['','','','','','']
                              let all_nomCII_arr1 = ['','','','','','']
                              let all_nomCII_arrForMaxMin = ['','','','','','']
                              let all_nomCII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-all_nom999_CII6").html() == "1st"){
                                all_nomCII_arr6[0] = all_nomCII_arr6[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII6").html() == "2nd"){
                                all_nomCII_arr6[1] = all_nomCII_arr6[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII6").html() == "3rd"){
                                all_nomCII_arr6[2] = all_nomCII_arr6[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII6").html() == "4th"){
                                all_nomCII_arr6[3] = all_nomCII_arr6[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII6").html() == "5th"){
                                all_nomCII_arr6[4] = all_nomCII_arr6[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII6").html() == "6th"){
                                all_nomCII_arr6[5] = all_nomCII_arr6[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-all_nom999_CII5").html() == "1st"){
                                all_nomCII_arr5[0] = all_nomCII_arr5[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII5").html() == "2nd"){
                                all_nomCII_arr5[1] = all_nomCII_arr5[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII5").html() == "3rd"){
                                all_nomCII_arr5[2] = all_nomCII_arr5[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII5").html() == "4th"){
                                all_nomCII_arr5[3] = all_nomCII_arr5[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII5").html() == "5th"){
                                all_nomCII_arr5[4] = all_nomCII_arr5[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII5").html() == "6th"){
                                all_nomCII_arr5[5] = all_nomCII_arr5[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-all_nom999_CII4").html() == "1st"){
                                all_nomCII_arr4[0] = all_nomCII_arr4[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII4").html() == "2nd"){
                                all_nomCII_arr4[1] = all_nomCII_arr4[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII4").html() == "3rd"){
                                all_nomCII_arr4[2] = all_nomCII_arr4[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII4").html() == "4th"){
                                all_nomCII_arr4[3] = all_nomCII_arr4[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII4").html() == "5th"){
                                all_nomCII_arr4[4] = all_nomCII_arr4[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII4").html() == "6th"){
                                all_nomCII_arr4[5] = all_nomCII_arr4[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-all_nom999_CII3").html() == "1st"){
                                all_nomCII_arr3[0] = all_nomCII_arr3[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII3").html() == "2nd"){
                                all_nomCII_arr3[1] = all_nomCII_arr3[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII3").html() == "3rd"){
                                all_nomCII_arr3[2] = all_nomCII_arr3[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII3").html() == "4th"){
                                all_nomCII_arr3[3] = all_nomCII_arr3[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII3").html() == "5th"){
                                all_nomCII_arr3[4] = all_nomCII_arr3[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII3").html() == "6th"){
                                all_nomCII_arr3[5] = all_nomCII_arr3[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-all_nom999_CII2").html() == "1st"){
                                all_nomCII_arr2[0] = all_nomCII_arr2[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII2").html() == "2nd"){
                                all_nomCII_arr2[1] = all_nomCII_arr2[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII2").html() == "3rd"){
                                all_nomCII_arr2[2] = all_nomCII_arr2[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII2").html() == "4th"){
                                all_nomCII_arr2[3] = all_nomCII_arr2[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII2").html() == "5th"){
                                all_nomCII_arr2[4] = all_nomCII_arr2[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII2").html() == "6th"){
                                all_nomCII_arr2[5] = all_nomCII_arr2[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-all_nom999_CII1").html() == "1st"){
                                all_nomCII_arr1[0] = all_nomCII_arr1[0]+'1'
                                all_nomCII_arrForMaxMin[0] = all_nomCII_arrForMaxMin[0] = 1
                                all_nomCII_arrCounter[0] = all_nomCII_arrCounter[0]+'1'
                              }
                              if($(".competency-all_nom999_CII1").html() == "2nd"){
                                all_nomCII_arr1[1] = all_nomCII_arr1[1]+'2'
                                all_nomCII_arrForMaxMin[1] = all_nomCII_arrForMaxMin[1] = 2
                                all_nomCII_arrCounter[1] = all_nomCII_arrCounter[1]+'2'
                              }
                              if($(".competency-all_nom999_CII1").html() == "3rd"){
                                all_nomCII_arr1[2] = all_nomCII_arr1[2]+'3'
                                all_nomCII_arrForMaxMin[2] = all_nomCII_arrForMaxMin[2] = 3
                                all_nomCII_arrCounter[2] = all_nomCII_arrCounter[2]+'3'
                              }
                              if($(".competency-all_nom999_CII1").html() == "4th"){
                                all_nomCII_arr1[3] = all_nomCII_arr1[3]+'4'
                                all_nomCII_arrForMaxMin[3] = all_nomCII_arrForMaxMin[3] = 4
                                all_nomCII_arrCounter[3] = all_nomCII_arrCounter[3]+'4'
                              }
                              if($(".competency-all_nom999_CII1").html() == "5th"){
                                all_nomCII_arr1[4] = all_nomCII_arr1[4]+'5'
                                all_nomCII_arrForMaxMin[4] = all_nomCII_arrForMaxMin[4] = 5
                                all_nomCII_arrCounter[4] = all_nomCII_arrCounter[4]+'5'
                              }
                              if($(".competency-all_nom999_CII1").html() == "6th"){
                                all_nomCII_arr1[5] = all_nomCII_arr1[5]+'6'
                                all_nomCII_arrForMaxMin[5] = all_nomCII_arrForMaxMin[5] = 6
                                all_nomCII_arrCounter[5] = all_nomCII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            all_nomCII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  all_nomCII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue2 = Math.min(...all_nomCII_arrForMaxMinCleaned)
                              let maxValue2 = Math.max(...all_nomCII_arrForMaxMinCleaned)
                              let firstCount2 = 0
                              let secondCount2 = 0
                              let thirdCount2 = 0
                              let fourthCount2 = 0
                              let fifthCount2 = 0
                              let sixthCount2 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(all_nomCII_arr6[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII6").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=1st")
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII5").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=1st")
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII4").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=1st")
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII3").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=1st")
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII2").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=1st")
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2 && all_nomCII_arrCounter[x].includes(minValue2)){
                                  $(".competency-all_nom999_CII1").html("1st")
                                  firstCount2 = all_nomCII_arrCounter[x].length
                                  if(firstCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(all_nomCII_arr6[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+1 && all_nomCII_arrCounter[x].includes(minValue2+1)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+1)))
                                  secondCount2 = all_nomCII_arrCounter[x].length
                                  if(secondCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(all_nomCII_arr6[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+2 && all_nomCII_arrCounter[x].includes(minValue2+2)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+1)))
                                  thirdCount2 = all_nomCII_arrCounter[x].length
                                  if(thirdCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(all_nomCII_arr6[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+3 && all_nomCII_arrCounter[x].includes(minValue2+3)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  fourthCount2 = all_nomCII_arrCounter[x].length
                                  if(fourthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(all_nomCII_arr6[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+4 && all_nomCII_arrCounter[x].includes(minValue2+4)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  fifthCount2 = all_nomCII_arrCounter[x].length
                                  if(fifthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(all_nomCII_arr6[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII6").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII6").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                 
                                if(all_nomCII_arr5[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII5").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII5").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                       
                                if(all_nomCII_arr4[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII4").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII4").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                          
                                if(all_nomCII_arr3[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII3").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII3").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                        
                                if(all_nomCII_arr2[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII2").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII2").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                           
                                if(all_nomCII_arr1[x] == minValue2+5 && all_nomCII_arrCounter[x].includes(minValue2+5)){
                                  $(".competency-all_nom999_CII1").html(ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  sixthCount2 = all_nomCII_arrCounter[x].length
                                  if(sixthCount2 > 1){
                                    $(".competency-all_nom999_CII1").html("=" + ordinal(Math.round(firstCount2+secondCount2+thirdCount2+fourthCount2+fifthCount2+1)))
                                  }
                                }
                              //end of loop
                              }
                            //end of my Ranking
    
                            //start Peers
                            if(res.data[0].peer2_N > 1){
                              let peer2_CII_arrCounter = ['','','','','','']
                              let peer2_CII_arr6 = ['','','','','','']
                              let peer2_CII_arr5 = ['','','','','','']
                              let peer2_CII_arr4 = ['','','','','','']
                              let peer2_CII_arr3 = ['','','','','','']
                              let peer2_CII_arr2 = ['','','','','','']
                              let peer2_CII_arr1 = ['','','','','','']
                              let peer2_CII_arrForMaxMin = ['','','','','','']
                              let peer2_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-peer2_CII6").html() == "1st"){
                                peer2_CII_arr6[0] = peer2_CII_arr6[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII6").html() == "2nd"){
                                peer2_CII_arr6[1] = peer2_CII_arr6[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII6").html() == "3rd"){
                                peer2_CII_arr6[2] = peer2_CII_arr6[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII6").html() == "4th"){
                                peer2_CII_arr6[3] = peer2_CII_arr6[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII6").html() == "5th"){
                                peer2_CII_arr6[4] = peer2_CII_arr6[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII6").html() == "6th"){
                                peer2_CII_arr6[5] = peer2_CII_arr6[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-peer2_CII5").html() == "1st"){
                                peer2_CII_arr5[0] = peer2_CII_arr5[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII5").html() == "2nd"){
                                peer2_CII_arr5[1] = peer2_CII_arr5[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII5").html() == "3rd"){
                                peer2_CII_arr5[2] = peer2_CII_arr5[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII5").html() == "4th"){
                                peer2_CII_arr5[3] = peer2_CII_arr5[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII5").html() == "5th"){
                                peer2_CII_arr5[4] = peer2_CII_arr5[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII5").html() == "6th"){
                                peer2_CII_arr5[5] = peer2_CII_arr5[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-peer2_CII4").html() == "1st"){
                                peer2_CII_arr4[0] = peer2_CII_arr4[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII4").html() == "2nd"){
                                peer2_CII_arr4[1] = peer2_CII_arr4[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII4").html() == "3rd"){
                                peer2_CII_arr4[2] = peer2_CII_arr4[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII4").html() == "4th"){
                                peer2_CII_arr4[3] = peer2_CII_arr4[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII4").html() == "5th"){
                                peer2_CII_arr4[4] = peer2_CII_arr4[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII4").html() == "6th"){
                                peer2_CII_arr4[5] = peer2_CII_arr4[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-peer2_CII3").html() == "1st"){
                                peer2_CII_arr3[0] = peer2_CII_arr3[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII3").html() == "2nd"){
                                peer2_CII_arr3[1] = peer2_CII_arr3[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII3").html() == "3rd"){
                                peer2_CII_arr3[2] = peer2_CII_arr3[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII3").html() == "4th"){
                                peer2_CII_arr3[3] = peer2_CII_arr3[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII3").html() == "5th"){
                                peer2_CII_arr3[4] = peer2_CII_arr3[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII3").html() == "6th"){
                                peer2_CII_arr3[5] = peer2_CII_arr3[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-peer2_CII2").html() == "1st"){
                                peer2_CII_arr2[0] = peer2_CII_arr2[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII2").html() == "2nd"){
                                peer2_CII_arr2[1] = peer2_CII_arr2[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII2").html() == "3rd"){
                                peer2_CII_arr2[2] = peer2_CII_arr2[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII2").html() == "4th"){
                                peer2_CII_arr2[3] = peer2_CII_arr2[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII2").html() == "5th"){
                                peer2_CII_arr2[4] = peer2_CII_arr2[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII2").html() == "6th"){
                                peer2_CII_arr2[5] = peer2_CII_arr2[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-peer2_CII1").html() == "1st"){
                                peer2_CII_arr1[0] = peer2_CII_arr1[0]+'1'
                                peer2_CII_arrForMaxMin[0] = peer2_CII_arrForMaxMin[0] = 1
                                peer2_CII_arrCounter[0] = peer2_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-peer2_CII1").html() == "2nd"){
                                peer2_CII_arr1[1] = peer2_CII_arr1[1]+'2'
                                peer2_CII_arrForMaxMin[1] = peer2_CII_arrForMaxMin[1] = 2
                                peer2_CII_arrCounter[1] = peer2_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-peer2_CII1").html() == "3rd"){
                                peer2_CII_arr1[2] = peer2_CII_arr1[2]+'3'
                                peer2_CII_arrForMaxMin[2] = peer2_CII_arrForMaxMin[2] = 3
                                peer2_CII_arrCounter[2] = peer2_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-peer2_CII1").html() == "4th"){
                                peer2_CII_arr1[3] = peer2_CII_arr1[3]+'4'
                                peer2_CII_arrForMaxMin[3] = peer2_CII_arrForMaxMin[3] = 4
                                peer2_CII_arrCounter[3] = peer2_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-peer2_CII1").html() == "5th"){
                                peer2_CII_arr1[4] = peer2_CII_arr1[4]+'5'
                                peer2_CII_arrForMaxMin[4] = peer2_CII_arrForMaxMin[4] = 5
                                peer2_CII_arrCounter[4] = peer2_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-peer2_CII1").html() == "6th"){
                                peer2_CII_arr1[5] = peer2_CII_arr1[5]+'6'
                                peer2_CII_arrForMaxMin[5] = peer2_CII_arrForMaxMin[5] = 6
                                peer2_CII_arrCounter[5] = peer2_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            peer2_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  peer2_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue10 = Math.min(...peer2_CII_arrForMaxMinCleaned)
                              let maxValue10 = Math.max(...peer2_CII_arrForMaxMinCleaned)
                              let firstCount10 = 0
                              let secondCount10 = 0
                              let thirdCount10 = 0
                              let fourthCount10 = 0
                              let fifthCount10 = 0
                              let sixthCount10 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(peer2_CII_arr6[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII6").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII6").html("=1st")
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII5").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII5").html("=1st")
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII4").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII4").html("=1st")
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII3").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII3").html("=1st")
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII2").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII2").html("=1st")
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10 && peer2_CII_arrCounter[x].includes(minValue10)){
                                  $(".competency-peer2_CII1").html("1st")
                                  firstCount10 = peer2_CII_arrCounter[x].length
                                  if(firstCount10 > 1){
                                    $(".competency-peer2_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(peer2_CII_arr6[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+1 && peer2_CII_arrCounter[x].includes(minValue10+1)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+1)))
                                  secondCount10 = peer2_CII_arrCounter[x].length
                                  if(secondCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(peer2_CII_arr6[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+2 && peer2_CII_arrCounter[x].includes(minValue10+2)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+1)))
                                  thirdCount10 = peer2_CII_arrCounter[x].length
                                  if(thirdCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(peer2_CII_arr6[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+3 && peer2_CII_arrCounter[x].includes(minValue10+3)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  fourthCount10 = peer2_CII_arrCounter[x].length
                                  if(fourthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(peer2_CII_arr6[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+4 && peer2_CII_arrCounter[x].includes(minValue10+4)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  fifthCount10 = peer2_CII_arrCounter[x].length
                                  if(fifthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(peer2_CII_arr6[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII6").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII6").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                 
                                if(peer2_CII_arr5[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII5").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII5").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                       
                                if(peer2_CII_arr4[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII4").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII4").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                          
                                if(peer2_CII_arr3[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII3").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII3").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                        
                                if(peer2_CII_arr2[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII2").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII2").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                           
                                if(peer2_CII_arr1[x] == minValue10+5 && peer2_CII_arrCounter[x].includes(minValue10+5)){
                                  $(".competency-peer2_CII1").html(ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  sixthCount10 = peer2_CII_arrCounter[x].length
                                  if(sixthCount10 > 1){
                                    $(".competency-peer2_CII1").html("=" + ordinal(Math.round(firstCount10+secondCount10+thirdCount10+fourthCount10+fifthCount10+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of peers if
                            // end of Peers
    
                            //start Direct Reports
                            if(res.data[0].direct3_N > 1){
                              let direct3_CII_arrCounter = ['','','','','','']
                              let direct3_CII_arr6 = ['','','','','','']
                              let direct3_CII_arr5 = ['','','','','','']
                              let direct3_CII_arr4 = ['','','','','','']
                              let direct3_CII_arr3 = ['','','','','','']
                              let direct3_CII_arr2 = ['','','','','','']
                              let direct3_CII_arr1 = ['','','','','','']
                              let direct3_CII_arrForMaxMin = ['','','','','','']
                              let direct3_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-direct3_CII6").html() == "1st"){
                                direct3_CII_arr6[0] = direct3_CII_arr6[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII6").html() == "2nd"){
                                direct3_CII_arr6[1] = direct3_CII_arr6[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII6").html() == "3rd"){
                                direct3_CII_arr6[2] = direct3_CII_arr6[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII6").html() == "4th"){
                                direct3_CII_arr6[3] = direct3_CII_arr6[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII6").html() == "5th"){
                                direct3_CII_arr6[4] = direct3_CII_arr6[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII6").html() == "6th"){
                                direct3_CII_arr6[5] = direct3_CII_arr6[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-direct3_CII5").html() == "1st"){
                                direct3_CII_arr5[0] = direct3_CII_arr5[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII5").html() == "2nd"){
                                direct3_CII_arr5[1] = direct3_CII_arr5[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII5").html() == "3rd"){
                                direct3_CII_arr5[2] = direct3_CII_arr5[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII5").html() == "4th"){
                                direct3_CII_arr5[3] = direct3_CII_arr5[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII5").html() == "5th"){
                                direct3_CII_arr5[4] = direct3_CII_arr5[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII5").html() == "6th"){
                                direct3_CII_arr5[5] = direct3_CII_arr5[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-direct3_CII4").html() == "1st"){
                                direct3_CII_arr4[0] = direct3_CII_arr4[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII4").html() == "2nd"){
                                direct3_CII_arr4[1] = direct3_CII_arr4[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII4").html() == "3rd"){
                                direct3_CII_arr4[2] = direct3_CII_arr4[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII4").html() == "4th"){
                                direct3_CII_arr4[3] = direct3_CII_arr4[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII4").html() == "5th"){
                                direct3_CII_arr4[4] = direct3_CII_arr4[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII4").html() == "6th"){
                                direct3_CII_arr4[5] = direct3_CII_arr4[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-direct3_CII3").html() == "1st"){
                                direct3_CII_arr3[0] = direct3_CII_arr3[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII3").html() == "2nd"){
                                direct3_CII_arr3[1] = direct3_CII_arr3[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII3").html() == "3rd"){
                                direct3_CII_arr3[2] = direct3_CII_arr3[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII3").html() == "4th"){
                                direct3_CII_arr3[3] = direct3_CII_arr3[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII3").html() == "5th"){
                                direct3_CII_arr3[4] = direct3_CII_arr3[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII3").html() == "6th"){
                                direct3_CII_arr3[5] = direct3_CII_arr3[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-direct3_CII2").html() == "1st"){
                                direct3_CII_arr2[0] = direct3_CII_arr2[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII2").html() == "2nd"){
                                direct3_CII_arr2[1] = direct3_CII_arr2[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII2").html() == "3rd"){
                                direct3_CII_arr2[2] = direct3_CII_arr2[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII2").html() == "4th"){
                                direct3_CII_arr2[3] = direct3_CII_arr2[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII2").html() == "5th"){
                                direct3_CII_arr2[4] = direct3_CII_arr2[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII2").html() == "6th"){
                                direct3_CII_arr2[5] = direct3_CII_arr2[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-direct3_CII1").html() == "1st"){
                                direct3_CII_arr1[0] = direct3_CII_arr1[0]+'1'
                                direct3_CII_arrForMaxMin[0] = direct3_CII_arrForMaxMin[0] = 1
                                direct3_CII_arrCounter[0] = direct3_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-direct3_CII1").html() == "2nd"){
                                direct3_CII_arr1[1] = direct3_CII_arr1[1]+'2'
                                direct3_CII_arrForMaxMin[1] = direct3_CII_arrForMaxMin[1] = 2
                                direct3_CII_arrCounter[1] = direct3_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-direct3_CII1").html() == "3rd"){
                                direct3_CII_arr1[2] = direct3_CII_arr1[2]+'3'
                                direct3_CII_arrForMaxMin[2] = direct3_CII_arrForMaxMin[2] = 3
                                direct3_CII_arrCounter[2] = direct3_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-direct3_CII1").html() == "4th"){
                                direct3_CII_arr1[3] = direct3_CII_arr1[3]+'4'
                                direct3_CII_arrForMaxMin[3] = direct3_CII_arrForMaxMin[3] = 4
                                direct3_CII_arrCounter[3] = direct3_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-direct3_CII1").html() == "5th"){
                                direct3_CII_arr1[4] = direct3_CII_arr1[4]+'5'
                                direct3_CII_arrForMaxMin[4] = direct3_CII_arrForMaxMin[4] = 5
                                direct3_CII_arrCounter[4] = direct3_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-direct3_CII1").html() == "6th"){
                                direct3_CII_arr1[5] = direct3_CII_arr1[5]+'6'
                                direct3_CII_arrForMaxMin[5] = direct3_CII_arrForMaxMin[5] = 6
                                direct3_CII_arrCounter[5] = direct3_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            direct3_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  direct3_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue11 = Math.min(...direct3_CII_arrForMaxMinCleaned)
                              let maxValue11 = Math.max(...direct3_CII_arrForMaxMinCleaned)
                              let firstCount11 = 0
                              let secondCount11 = 0
                              let thirdCount11 = 0
                              let fourthCount11 = 0
                              let fifthCount11 = 0
                              let sixthCount11 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(direct3_CII_arr6[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII6").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII6").html("=1st")
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII5").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII5").html("=1st")
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII4").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII4").html("=1st")
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII3").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII3").html("=1st")
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII2").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII2").html("=1st")
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11 && direct3_CII_arrCounter[x].includes(minValue11)){
                                  $(".competency-direct3_CII1").html("1st")
                                  firstCount11 = direct3_CII_arrCounter[x].length
                                  if(firstCount11 > 1){
                                    $(".competency-direct3_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(direct3_CII_arr6[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+1 && direct3_CII_arrCounter[x].includes(minValue11+1)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+1)))
                                  secondCount11 = direct3_CII_arrCounter[x].length
                                  if(secondCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(direct3_CII_arr6[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+2 && direct3_CII_arrCounter[x].includes(minValue11+2)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+1)))
                                  thirdCount11 = direct3_CII_arrCounter[x].length
                                  if(thirdCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(direct3_CII_arr6[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+3 && direct3_CII_arrCounter[x].includes(minValue11+3)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  fourthCount11 = direct3_CII_arrCounter[x].length
                                  if(fourthCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(direct3_CII_arr6[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+4 && direct3_CII_arrCounter[x].includes(minValue11+4)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  fifthCount11 = direct3_CII_arrCounter[x].length
                                  if(fifthCount11 > 1){
                                    $(".competency-direct3_CII1").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(direct3_CII_arr6[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII6").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                 
                                if(direct3_CII_arr5[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII5").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII5").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                       
                                if(direct3_CII_arr4[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII4").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII4").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                          
                                if(direct3_CII_arr3[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII3").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII3").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                        
                                if(direct3_CII_arr2[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII2").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII2").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                           
                                if(direct3_CII_arr1[x] == minValue11+5 && direct3_CII_arrCounter[x].includes(minValue11+5)){
                                  $(".competency-direct3_CII1").html(ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  sixthCount11 = direct3_CII_arrCounter[x].length
                                  if(sixthCount11 > 1){
                                    $(".competency-direct3_CII6").html("=" + ordinal(Math.round(firstCount11+secondCount11+thirdCount11+fourthCount11+fifthCount11+1)))
                                  }
                                }
                                //end of loop
                              }
                            }
                            // end of direct reports if
                            // end of direct reports
    
                          //start Peers/Direct
                          if(res.data[0].PD996_N > 1){
                              let PD996_CII_arrCounter = ['','','','','','']
                              let PD996_CII_arr6 = ['','','','','','']
                              let PD996_CII_arr5 = ['','','','','','']
                              let PD996_CII_arr4 = ['','','','','','']
                              let PD996_CII_arr3 = ['','','','','','']
                              let PD996_CII_arr2 = ['','','','','','']
                              let PD996_CII_arr1 = ['','','','','','']
                              let PD996_CII_arrForMaxMin = ['','','','','','']
                              let PD996_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-PD996_CII6").html() == "1st"){
                                PD996_CII_arr6[0] = PD996_CII_arr6[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII6").html() == "2nd"){
                                PD996_CII_arr6[1] = PD996_CII_arr6[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII6").html() == "3rd"){
                                PD996_CII_arr6[2] = PD996_CII_arr6[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII6").html() == "4th"){
                                PD996_CII_arr6[3] = PD996_CII_arr6[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII6").html() == "5th"){
                                PD996_CII_arr6[4] = PD996_CII_arr6[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII6").html() == "6th"){
                                PD996_CII_arr6[5] = PD996_CII_arr6[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-PD996_CII5").html() == "1st"){
                                PD996_CII_arr5[0] = PD996_CII_arr5[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII5").html() == "2nd"){
                                PD996_CII_arr5[1] = PD996_CII_arr5[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII5").html() == "3rd"){
                                PD996_CII_arr5[2] = PD996_CII_arr5[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII5").html() == "4th"){
                                PD996_CII_arr5[3] = PD996_CII_arr5[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII5").html() == "5th"){
                                PD996_CII_arr5[4] = PD996_CII_arr5[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII5").html() == "6th"){
                                PD996_CII_arr5[5] = PD996_CII_arr5[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-PD996_CII4").html() == "1st"){
                                PD996_CII_arr4[0] = PD996_CII_arr4[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII4").html() == "2nd"){
                                PD996_CII_arr4[1] = PD996_CII_arr4[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII4").html() == "3rd"){
                                PD996_CII_arr4[2] = PD996_CII_arr4[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII4").html() == "4th"){
                                PD996_CII_arr4[3] = PD996_CII_arr4[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII4").html() == "5th"){
                                PD996_CII_arr4[4] = PD996_CII_arr4[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII4").html() == "6th"){
                                PD996_CII_arr4[5] = PD996_CII_arr4[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-PD996_CII3").html() == "1st"){
                                PD996_CII_arr3[0] = PD996_CII_arr3[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII3").html() == "2nd"){
                                PD996_CII_arr3[1] = PD996_CII_arr3[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII3").html() == "3rd"){
                                PD996_CII_arr3[2] = PD996_CII_arr3[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII3").html() == "4th"){
                                PD996_CII_arr3[3] = PD996_CII_arr3[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII3").html() == "5th"){
                                PD996_CII_arr3[4] = PD996_CII_arr3[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII3").html() == "6th"){
                                PD996_CII_arr3[5] = PD996_CII_arr3[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-PD996_CII2").html() == "1st"){
                                PD996_CII_arr2[0] = PD996_CII_arr2[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII2").html() == "2nd"){
                                PD996_CII_arr2[1] = PD996_CII_arr2[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII2").html() == "3rd"){
                                PD996_CII_arr2[2] = PD996_CII_arr2[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII2").html() == "4th"){
                                PD996_CII_arr2[3] = PD996_CII_arr2[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII2").html() == "5th"){
                                PD996_CII_arr2[4] = PD996_CII_arr2[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII2").html() == "6th"){
                                PD996_CII_arr2[5] = PD996_CII_arr2[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-PD996_CII1").html() == "1st"){
                                PD996_CII_arr1[0] = PD996_CII_arr1[0]+'1'
                                PD996_CII_arrForMaxMin[0] = PD996_CII_arrForMaxMin[0] = 1
                                PD996_CII_arrCounter[0] = PD996_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-PD996_CII1").html() == "2nd"){
                                PD996_CII_arr1[1] = PD996_CII_arr1[1]+'2'
                                PD996_CII_arrForMaxMin[1] = PD996_CII_arrForMaxMin[1] = 2
                                PD996_CII_arrCounter[1] = PD996_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-PD996_CII1").html() == "3rd"){
                                PD996_CII_arr1[2] = PD996_CII_arr1[2]+'3'
                                PD996_CII_arrForMaxMin[2] = PD996_CII_arrForMaxMin[2] = 3
                                PD996_CII_arrCounter[2] = PD996_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-PD996_CII1").html() == "4th"){
                                PD996_CII_arr1[3] = PD996_CII_arr1[3]+'4'
                                PD996_CII_arrForMaxMin[3] = PD996_CII_arrForMaxMin[3] = 4
                                PD996_CII_arrCounter[3] = PD996_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-PD996_CII1").html() == "5th"){
                                PD996_CII_arr1[4] = PD996_CII_arr1[4]+'5'
                                PD996_CII_arrForMaxMin[4] = PD996_CII_arrForMaxMin[4] = 5
                                PD996_CII_arrCounter[4] = PD996_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-PD996_CII1").html() == "6th"){
                                PD996_CII_arr1[5] = PD996_CII_arr1[5]+'6'
                                PD996_CII_arrForMaxMin[5] = PD996_CII_arrForMaxMin[5] = 6
                                PD996_CII_arrCounter[5] = PD996_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            PD996_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  PD996_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue12 = Math.min(...PD996_CII_arrForMaxMinCleaned)
                              let maxValue12 = Math.max(...PD996_CII_arrForMaxMinCleaned)
                              let firstCount12 = 0
                              let secondCount12 = 0
                              let thirdCount12 = 0
                              let fourthCount12 = 0
                              let fifthCount12 = 0
                              let sixthCount12 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(PD996_CII_arr6[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII6").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII6").html("=1st")
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII5").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII5").html("=1st")
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII4").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII4").html("=1st")
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII3").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII3").html("=1st")
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII2").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII2").html("=1st")
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12 && PD996_CII_arrCounter[x].includes(minValue12)){
                                  $(".competency-PD996_CII1").html("1st")
                                  firstCount12 = PD996_CII_arrCounter[x].length
                                  if(firstCount12 > 1){
                                    $(".competency-PD996_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(PD996_CII_arr6[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+1 && PD996_CII_arrCounter[x].includes(minValue12+1)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+1)))
                                  secondCount12 = PD996_CII_arrCounter[x].length
                                  if(secondCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(PD996_CII_arr6[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+2 && PD996_CII_arrCounter[x].includes(minValue12+2)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+1)))
                                  thirdCount12 = PD996_CII_arrCounter[x].length
                                  if(thirdCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(PD996_CII_arr6[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+3 && PD996_CII_arrCounter[x].includes(minValue12+3)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  fourthCount12 = PD996_CII_arrCounter[x].length
                                  if(fourthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(PD996_CII_arr6[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+4 && PD996_CII_arrCounter[x].includes(minValue12+4)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  fifthCount12 = PD996_CII_arrCounter[x].length
                                  if(fifthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(PD996_CII_arr6[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII6").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII6").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                 
                                if(PD996_CII_arr5[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII5").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII5").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                       
                                if(PD996_CII_arr4[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII4").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII4").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                          
                                if(PD996_CII_arr3[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII3").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII3").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                        
                                if(PD996_CII_arr2[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII2").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII2").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                           
                                if(PD996_CII_arr1[x] == minValue12+5 && PD996_CII_arrCounter[x].includes(minValue12+5)){
                                  $(".competency-PD996_CII1").html(ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  sixthCount12 = PD996_CII_arrCounter[x].length
                                  if(sixthCount12 > 1){
                                    $(".competency-PD996_CII1").html("=" + ordinal(Math.round(firstCount12+secondCount12+thirdCount12+fourthCount12+fifthCount12+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of peers/direct reports if
                            // end of peers/direct reports
    
                            //start Internal
                          if(res.data[0].internal4_N > 1){
                              let internal4_CII_arrCounter = ['','','','','','']
                              let internal4_CII_arr6 = ['','','','','','']
                              let internal4_CII_arr5 = ['','','','','','']
                              let internal4_CII_arr4 = ['','','','','','']
                              let internal4_CII_arr3 = ['','','','','','']
                              let internal4_CII_arr2 = ['','','','','','']
                              let internal4_CII_arr1 = ['','','','','','']
                              let internal4_CII_arrForMaxMin = ['','','','','','']
                              let internal4_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-internal4_CII6").html() == "1st"){
                                internal4_CII_arr6[0] = internal4_CII_arr6[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII6").html() == "2nd"){
                                internal4_CII_arr6[1] = internal4_CII_arr6[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII6").html() == "3rd"){
                                internal4_CII_arr6[2] = internal4_CII_arr6[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII6").html() == "4th"){
                                internal4_CII_arr6[3] = internal4_CII_arr6[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII6").html() == "5th"){
                                internal4_CII_arr6[4] = internal4_CII_arr6[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII6").html() == "6th"){
                                internal4_CII_arr6[5] = internal4_CII_arr6[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-internal4_CII5").html() == "1st"){
                                internal4_CII_arr5[0] = internal4_CII_arr5[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII5").html() == "2nd"){
                                internal4_CII_arr5[1] = internal4_CII_arr5[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII5").html() == "3rd"){
                                internal4_CII_arr5[2] = internal4_CII_arr5[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII5").html() == "4th"){
                                internal4_CII_arr5[3] = internal4_CII_arr5[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII5").html() == "5th"){
                                internal4_CII_arr5[4] = internal4_CII_arr5[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII5").html() == "6th"){
                                internal4_CII_arr5[5] = internal4_CII_arr5[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-internal4_CII4").html() == "1st"){
                                internal4_CII_arr4[0] = internal4_CII_arr4[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII4").html() == "2nd"){
                                internal4_CII_arr4[1] = internal4_CII_arr4[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII4").html() == "3rd"){
                                internal4_CII_arr4[2] = internal4_CII_arr4[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII4").html() == "4th"){
                                internal4_CII_arr4[3] = internal4_CII_arr4[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII4").html() == "5th"){
                                internal4_CII_arr4[4] = internal4_CII_arr4[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII4").html() == "6th"){
                                internal4_CII_arr4[5] = internal4_CII_arr4[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-internal4_CII3").html() == "1st"){
                                internal4_CII_arr3[0] = internal4_CII_arr3[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII3").html() == "2nd"){
                                internal4_CII_arr3[1] = internal4_CII_arr3[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII3").html() == "3rd"){
                                internal4_CII_arr3[2] = internal4_CII_arr3[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII3").html() == "4th"){
                                internal4_CII_arr3[3] = internal4_CII_arr3[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII3").html() == "5th"){
                                internal4_CII_arr3[4] = internal4_CII_arr3[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII3").html() == "6th"){
                                internal4_CII_arr3[5] = internal4_CII_arr3[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-internal4_CII2").html() == "1st"){
                                internal4_CII_arr2[0] = internal4_CII_arr2[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII2").html() == "2nd"){
                                internal4_CII_arr2[1] = internal4_CII_arr2[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII2").html() == "3rd"){
                                internal4_CII_arr2[2] = internal4_CII_arr2[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII2").html() == "4th"){
                                internal4_CII_arr2[3] = internal4_CII_arr2[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII2").html() == "5th"){
                                internal4_CII_arr2[4] = internal4_CII_arr2[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII2").html() == "6th"){
                                internal4_CII_arr2[5] = internal4_CII_arr2[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-internal4_CII1").html() == "1st"){
                                internal4_CII_arr1[0] = internal4_CII_arr1[0]+'1'
                                internal4_CII_arrForMaxMin[0] = internal4_CII_arrForMaxMin[0] = 1
                                internal4_CII_arrCounter[0] = internal4_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-internal4_CII1").html() == "2nd"){
                                internal4_CII_arr1[1] = internal4_CII_arr1[1]+'2'
                                internal4_CII_arrForMaxMin[1] = internal4_CII_arrForMaxMin[1] = 2
                                internal4_CII_arrCounter[1] = internal4_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-internal4_CII1").html() == "3rd"){
                                internal4_CII_arr1[2] = internal4_CII_arr1[2]+'3'
                                internal4_CII_arrForMaxMin[2] = internal4_CII_arrForMaxMin[2] = 3
                                internal4_CII_arrCounter[2] = internal4_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-internal4_CII1").html() == "4th"){
                                internal4_CII_arr1[3] = internal4_CII_arr1[3]+'4'
                                internal4_CII_arrForMaxMin[3] = internal4_CII_arrForMaxMin[3] = 4
                                internal4_CII_arrCounter[3] = internal4_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-internal4_CII1").html() == "5th"){
                                internal4_CII_arr1[4] = internal4_CII_arr1[4]+'5'
                                internal4_CII_arrForMaxMin[4] = internal4_CII_arrForMaxMin[4] = 5
                                internal4_CII_arrCounter[4] = internal4_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-internal4_CII1").html() == "6th"){
                                internal4_CII_arr1[5] = internal4_CII_arr1[5]+'6'
                                internal4_CII_arrForMaxMin[5] = internal4_CII_arrForMaxMin[5] = 6
                                internal4_CII_arrCounter[5] = internal4_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            internal4_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  internal4_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue13 = Math.min(...internal4_CII_arrForMaxMinCleaned)
                              let maxValue13 = Math.max(...internal4_CII_arrForMaxMinCleaned)
                              let firstCount13 = 0
                              let secondCount13 = 0
                              let thirdCount13 = 0
                              let fourthCount13 = 0
                              let fifthCount13 = 0
                              let sixthCount13 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(internal4_CII_arr6[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII6").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII6").html("=1st")
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII5").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII5").html("=1st")
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII4").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII4").html("=1st")
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII3").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII3").html("=1st")
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII2").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII2").html("=1st")
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13 && internal4_CII_arrCounter[x].includes(minValue13)){
                                  $(".competency-internal4_CII1").html("1st")
                                  firstCount13 = internal4_CII_arrCounter[x].length
                                  if(firstCount13 > 1){
                                    $(".competency-internal4_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(internal4_CII_arr6[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+1 && internal4_CII_arrCounter[x].includes(minValue13+1)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+1)))
                                  secondCount13 = internal4_CII_arrCounter[x].length
                                  if(secondCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(internal4_CII_arr6[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+2 && internal4_CII_arrCounter[x].includes(minValue13+2)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+1)))
                                  thirdCount13 = internal4_CII_arrCounter[x].length
                                  if(thirdCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(internal4_CII_arr6[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+3 && internal4_CII_arrCounter[x].includes(minValue13+3)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  fourthCount13 = internal4_CII_arrCounter[x].length
                                  if(fourthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(internal4_CII_arr6[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+4 && internal4_CII_arrCounter[x].includes(minValue13+4)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  fifthCount13 = internal4_CII_arrCounter[x].length
                                  if(fifthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(internal4_CII_arr6[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII6").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII6").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                 
                                if(internal4_CII_arr5[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII5").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII5").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                       
                                if(internal4_CII_arr4[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII4").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII4").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                          
                                if(internal4_CII_arr3[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII3").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII3").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                        
                                if(internal4_CII_arr2[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII2").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII2").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                           
                                if(internal4_CII_arr1[x] == minValue13+5 && internal4_CII_arrCounter[x].includes(minValue13+5)){
                                  $(".competency-internal4_CII1").html(ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  sixthCount13 = internal4_CII_arrCounter[x].length
                                  if(sixthCount13 > 1){
                                    $(".competency-internal4_CII1").html("=" + ordinal(Math.round(firstCount13+secondCount13+thirdCount13+fourthCount13+fifthCount13+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of internal if
                            // end of internal
    
                            //start External
                          if(res.data[0].external5_N > 1){
                              let external5_CII_arrCounter = ['','','','','','']
                              let external5_CII_arr6 = ['','','','','','']
                              let external5_CII_arr5 = ['','','','','','']
                              let external5_CII_arr4 = ['','','','','','']
                              let external5_CII_arr3 = ['','','','','','']
                              let external5_CII_arr2 = ['','','','','','']
                              let external5_CII_arr1 = ['','','','','','']
                              let external5_CII_arrForMaxMin = ['','','','','','']
                              let external5_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-external5_CII6").html() == "1st"){
                                external5_CII_arr6[0] = external5_CII_arr6[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII6").html() == "2nd"){
                                external5_CII_arr6[1] = external5_CII_arr6[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII6").html() == "3rd"){
                                external5_CII_arr6[2] = external5_CII_arr6[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII6").html() == "4th"){
                                external5_CII_arr6[3] = external5_CII_arr6[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII6").html() == "5th"){
                                external5_CII_arr6[4] = external5_CII_arr6[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII6").html() == "6th"){
                                external5_CII_arr6[5] = external5_CII_arr6[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-external5_CII5").html() == "1st"){
                                external5_CII_arr5[0] = external5_CII_arr5[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII5").html() == "2nd"){
                                external5_CII_arr5[1] = external5_CII_arr5[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII5").html() == "3rd"){
                                external5_CII_arr5[2] = external5_CII_arr5[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII5").html() == "4th"){
                                external5_CII_arr5[3] = external5_CII_arr5[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII5").html() == "5th"){
                                external5_CII_arr5[4] = external5_CII_arr5[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII5").html() == "6th"){
                                external5_CII_arr5[5] = external5_CII_arr5[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-external5_CII4").html() == "1st"){
                                external5_CII_arr4[0] = external5_CII_arr4[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII4").html() == "2nd"){
                                external5_CII_arr4[1] = external5_CII_arr4[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII4").html() == "3rd"){
                                external5_CII_arr4[2] = external5_CII_arr4[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII4").html() == "4th"){
                                external5_CII_arr4[3] = external5_CII_arr4[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII4").html() == "5th"){
                                external5_CII_arr4[4] = external5_CII_arr4[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII4").html() == "6th"){
                                external5_CII_arr4[5] = external5_CII_arr4[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-external5_CII3").html() == "1st"){
                                external5_CII_arr3[0] = external5_CII_arr3[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII3").html() == "2nd"){
                                external5_CII_arr3[1] = external5_CII_arr3[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII3").html() == "3rd"){
                                external5_CII_arr3[2] = external5_CII_arr3[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII3").html() == "4th"){
                                external5_CII_arr3[3] = external5_CII_arr3[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII3").html() == "5th"){
                                external5_CII_arr3[4] = external5_CII_arr3[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII3").html() == "6th"){
                                external5_CII_arr3[5] = external5_CII_arr3[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-external5_CII2").html() == "1st"){
                                external5_CII_arr2[0] = external5_CII_arr2[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII2").html() == "2nd"){
                                external5_CII_arr2[1] = external5_CII_arr2[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII2").html() == "3rd"){
                                external5_CII_arr2[2] = external5_CII_arr2[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII2").html() == "4th"){
                                external5_CII_arr2[3] = external5_CII_arr2[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII2").html() == "5th"){
                                external5_CII_arr2[4] = external5_CII_arr2[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII2").html() == "6th"){
                                external5_CII_arr2[5] = external5_CII_arr2[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-external5_CII1").html() == "1st"){
                                external5_CII_arr1[0] = external5_CII_arr1[0]+'1'
                                external5_CII_arrForMaxMin[0] = external5_CII_arrForMaxMin[0] = 1
                                external5_CII_arrCounter[0] = external5_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-external5_CII1").html() == "2nd"){
                                external5_CII_arr1[1] = external5_CII_arr1[1]+'2'
                                external5_CII_arrForMaxMin[1] = external5_CII_arrForMaxMin[1] = 2
                                external5_CII_arrCounter[1] = external5_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-external5_CII1").html() == "3rd"){
                                external5_CII_arr1[2] = external5_CII_arr1[2]+'3'
                                external5_CII_arrForMaxMin[2] = external5_CII_arrForMaxMin[2] = 3
                                external5_CII_arrCounter[2] = external5_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-external5_CII1").html() == "4th"){
                                external5_CII_arr1[3] = external5_CII_arr1[3]+'4'
                                external5_CII_arrForMaxMin[3] = external5_CII_arrForMaxMin[3] = 4
                                external5_CII_arrCounter[3] = external5_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-external5_CII1").html() == "5th"){
                                external5_CII_arr1[4] = external5_CII_arr1[4]+'5'
                                external5_CII_arrForMaxMin[4] = external5_CII_arrForMaxMin[4] = 5
                                external5_CII_arrCounter[4] = external5_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-external5_CII1").html() == "6th"){
                                external5_CII_arr1[5] = external5_CII_arr1[5]+'6'
                                external5_CII_arrForMaxMin[5] = external5_CII_arrForMaxMin[5] = 6
                                external5_CII_arrCounter[5] = external5_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            external5_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  external5_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue14 = Math.min(...external5_CII_arrForMaxMinCleaned)
                              let maxValue14 = Math.max(...external5_CII_arrForMaxMinCleaned)
                              let firstCount14 = 0
                              let secondCount14 = 0
                              let thirdCount14 = 0
                              let fourthCount14 = 0
                              let fifthCount14 = 0
                              let sixthCount14 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(external5_CII_arr6[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII6").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII6").html("=1st")
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII5").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII5").html("=1st")
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII4").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII4").html("=1st")
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII3").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII3").html("=1st")
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII2").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII2").html("=1st")
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14 && external5_CII_arrCounter[x].includes(minValue14)){
                                  $(".competency-external5_CII1").html("1st")
                                  firstCount14 = external5_CII_arrCounter[x].length
                                  if(firstCount14 > 1){
                                    $(".competency-external5_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(external5_CII_arr6[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+1 && external5_CII_arrCounter[x].includes(minValue14+1)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+1)))
                                  secondCount14 = external5_CII_arrCounter[x].length
                                  if(secondCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(external5_CII_arr6[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+2 && external5_CII_arrCounter[x].includes(minValue14+2)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+1)))
                                  thirdCount14 = external5_CII_arrCounter[x].length
                                  if(thirdCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(external5_CII_arr6[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+3 && external5_CII_arrCounter[x].includes(minValue14+3)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  fourthCount14 = external5_CII_arrCounter[x].length
                                  if(fourthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(external5_CII_arr6[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+4 && external5_CII_arrCounter[x].includes(minValue14+4)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  fifthCount14 = external5_CII_arrCounter[x].length
                                  if(fifthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(external5_CII_arr6[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII6").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII6").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                 
                                if(external5_CII_arr5[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII5").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII5").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                       
                                if(external5_CII_arr4[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII4").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII4").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                          
                                if(external5_CII_arr3[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII3").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII3").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                        
                                if(external5_CII_arr2[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII2").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII2").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                           
                                if(external5_CII_arr1[x] == minValue14+5 && external5_CII_arrCounter[x].includes(minValue14+5)){
                                  $(".competency-external5_CII1").html(ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  sixthCount14 = external5_CII_arrCounter[x].length
                                  if(sixthCount14 > 1){
                                    $(".competency-external5_CII1").html("=" + ordinal(Math.round(firstCount14+secondCount14+thirdCount14+fourthCount14+fifthCount14+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of external if
                            // end of external
    
                            //start internal/external
                          if(res.data[0].IE997_N > 1){
                              let IE997_CII_arrCounter = ['','','','','','']
                              let IE997_CII_arr6 = ['','','','','','']
                              let IE997_CII_arr5 = ['','','','','','']
                              let IE997_CII_arr4 = ['','','','','','']
                              let IE997_CII_arr3 = ['','','','','','']
                              let IE997_CII_arr2 = ['','','','','','']
                              let IE997_CII_arr1 = ['','','','','','']
                              let IE997_CII_arrForMaxMin = ['','','','','','']
                              let IE997_CII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-IE997_CII6").html() == "1st"){
                                IE997_CII_arr6[0] = IE997_CII_arr6[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII6").html() == "2nd"){
                                IE997_CII_arr6[1] = IE997_CII_arr6[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII6").html() == "3rd"){
                                IE997_CII_arr6[2] = IE997_CII_arr6[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII6").html() == "4th"){
                                IE997_CII_arr6[3] = IE997_CII_arr6[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII6").html() == "5th"){
                                IE997_CII_arr6[4] = IE997_CII_arr6[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII6").html() == "6th"){
                                IE997_CII_arr6[5] = IE997_CII_arr6[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-IE997_CII5").html() == "1st"){
                                IE997_CII_arr5[0] = IE997_CII_arr5[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII5").html() == "2nd"){
                                IE997_CII_arr5[1] = IE997_CII_arr5[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII5").html() == "3rd"){
                                IE997_CII_arr5[2] = IE997_CII_arr5[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII5").html() == "4th"){
                                IE997_CII_arr5[3] = IE997_CII_arr5[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII5").html() == "5th"){
                                IE997_CII_arr5[4] = IE997_CII_arr5[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII5").html() == "6th"){
                                IE997_CII_arr5[5] = IE997_CII_arr5[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-IE997_CII4").html() == "1st"){
                                IE997_CII_arr4[0] = IE997_CII_arr4[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII4").html() == "2nd"){
                                IE997_CII_arr4[1] = IE997_CII_arr4[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII4").html() == "3rd"){
                                IE997_CII_arr4[2] = IE997_CII_arr4[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII4").html() == "4th"){
                                IE997_CII_arr4[3] = IE997_CII_arr4[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII4").html() == "5th"){
                                IE997_CII_arr4[4] = IE997_CII_arr4[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII4").html() == "6th"){
                                IE997_CII_arr4[5] = IE997_CII_arr4[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-IE997_CII3").html() == "1st"){
                                IE997_CII_arr3[0] = IE997_CII_arr3[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII3").html() == "2nd"){
                                IE997_CII_arr3[1] = IE997_CII_arr3[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII3").html() == "3rd"){
                                IE997_CII_arr3[2] = IE997_CII_arr3[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII3").html() == "4th"){
                                IE997_CII_arr3[3] = IE997_CII_arr3[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII3").html() == "5th"){
                                IE997_CII_arr3[4] = IE997_CII_arr3[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII3").html() == "6th"){
                                IE997_CII_arr3[5] = IE997_CII_arr3[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-IE997_CII2").html() == "1st"){
                                IE997_CII_arr2[0] = IE997_CII_arr2[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII2").html() == "2nd"){
                                IE997_CII_arr2[1] = IE997_CII_arr2[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII2").html() == "3rd"){
                                IE997_CII_arr2[2] = IE997_CII_arr2[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII2").html() == "4th"){
                                IE997_CII_arr2[3] = IE997_CII_arr2[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII2").html() == "5th"){
                                IE997_CII_arr2[4] = IE997_CII_arr2[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII2").html() == "6th"){
                                IE997_CII_arr2[5] = IE997_CII_arr2[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-IE997_CII1").html() == "1st"){
                                IE997_CII_arr1[0] = IE997_CII_arr1[0]+'1'
                                IE997_CII_arrForMaxMin[0] = IE997_CII_arrForMaxMin[0] = 1
                                IE997_CII_arrCounter[0] = IE997_CII_arrCounter[0]+'1'
                              }
                              if($(".competency-IE997_CII1").html() == "2nd"){
                                IE997_CII_arr1[1] = IE997_CII_arr1[1]+'2'
                                IE997_CII_arrForMaxMin[1] = IE997_CII_arrForMaxMin[1] = 2
                                IE997_CII_arrCounter[1] = IE997_CII_arrCounter[1]+'2'
                              }
                              if($(".competency-IE997_CII1").html() == "3rd"){
                                IE997_CII_arr1[2] = IE997_CII_arr1[2]+'3'
                                IE997_CII_arrForMaxMin[2] = IE997_CII_arrForMaxMin[2] = 3
                                IE997_CII_arrCounter[2] = IE997_CII_arrCounter[2]+'3'
                              }
                              if($(".competency-IE997_CII1").html() == "4th"){
                                IE997_CII_arr1[3] = IE997_CII_arr1[3]+'4'
                                IE997_CII_arrForMaxMin[3] = IE997_CII_arrForMaxMin[3] = 4
                                IE997_CII_arrCounter[3] = IE997_CII_arrCounter[3]+'4'
                              }
                              if($(".competency-IE997_CII1").html() == "5th"){
                                IE997_CII_arr1[4] = IE997_CII_arr1[4]+'5'
                                IE997_CII_arrForMaxMin[4] = IE997_CII_arrForMaxMin[4] = 5
                                IE997_CII_arrCounter[4] = IE997_CII_arrCounter[4]+'5'
                              }
                              if($(".competency-IE997_CII1").html() == "6th"){
                                IE997_CII_arr1[5] = IE997_CII_arr1[5]+'6'
                                IE997_CII_arrForMaxMin[5] = IE997_CII_arrForMaxMin[5] = 6
                                IE997_CII_arrCounter[5] = IE997_CII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            IE997_CII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  IE997_CII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue15 = Math.min(...IE997_CII_arrForMaxMinCleaned)
                              let maxValue15 = Math.max(...IE997_CII_arrForMaxMinCleaned)
                              let firstCount15 = 0
                              let secondCount15 = 0
                              let thirdCount15 = 0
                              let fourthCount15 = 0
                              let fifthCount15 = 0
                              let sixthCount15 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(IE997_CII_arr6[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII6").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII6").html("=1st")
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII5").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII5").html("=1st")
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII4").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII4").html("=1st")
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII3").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII3").html("=1st")
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII2").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII2").html("=1st")
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15 && IE997_CII_arrCounter[x].includes(minValue15)){
                                  $(".competency-IE997_CII1").html("1st")
                                  firstCount15 = IE997_CII_arrCounter[x].length
                                  if(firstCount15 > 1){
                                    $(".competency-IE997_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(IE997_CII_arr6[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+1 && IE997_CII_arrCounter[x].includes(minValue15+1)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+1)))
                                  secondCount15 = IE997_CII_arrCounter[x].length
                                  if(secondCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(IE997_CII_arr6[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+2 && IE997_CII_arrCounter[x].includes(minValue15+2)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+1)))
                                  thirdCount15 = IE997_CII_arrCounter[x].length
                                  if(thirdCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(IE997_CII_arr6[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+3 && IE997_CII_arrCounter[x].includes(minValue15+3)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  fourthCount15 = IE997_CII_arrCounter[x].length
                                  if(fourthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(IE997_CII_arr6[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+4 && IE997_CII_arrCounter[x].includes(minValue15+4)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  fifthCount15 = IE997_CII_arrCounter[x].length
                                  if(fifthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(IE997_CII_arr6[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII6").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII6").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                 
                                if(IE997_CII_arr5[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII5").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII5").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                       
                                if(IE997_CII_arr4[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII4").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII4").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                          
                                if(IE997_CII_arr3[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII3").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII3").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                        
                                if(IE997_CII_arr2[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII2").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII2").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                           
                                if(IE997_CII_arr1[x] == minValue15+5 && IE997_CII_arrCounter[x].includes(minValue15+5)){
                                  $(".competency-IE997_CII1").html(ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  sixthCount15 = IE997_CII_arrCounter[x].length
                                  if(sixthCount15 > 1){
                                    $(".competency-IE997_CII1").html("=" + ordinal(Math.round(firstCount15+secondCount15+thirdCount15+fourthCount15+fifthCount15+1)))
                                  }
                                }
                              //end of loop
                              }
                            }
                            // end of internal/external if
                            // end of internal/external
    
    
                          //start my PDIE
                              let PDIECII_arrCounter = ['','','','','','']
                              let PDIECII_arr6 = ['','','','','','']
                              let PDIECII_arr5 = ['','','','','','']
                              let PDIECII_arr4 = ['','','','','','']
                              let PDIECII_arr3 = ['','','','','','']
                              let PDIECII_arr2 = ['','','','','','']
                              let PDIECII_arr1 = ['','','','','','']
                              let PDIECII_arrForMaxMin = ['','','','','','']
                              let PDIECII_arrForMaxMinCleaned = []
    
                              //CII6
                              if($(".competency-PDIE998_CII6").html() == "1st"){
                                PDIECII_arr6[0] = PDIECII_arr6[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII6").html() == "2nd"){
                                PDIECII_arr6[1] = PDIECII_arr6[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII6").html() == "3rd"){
                                PDIECII_arr6[2] = PDIECII_arr6[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII6").html() == "4th"){
                                PDIECII_arr6[3] = PDIECII_arr6[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII6").html() == "5th"){
                                PDIECII_arr6[4] = PDIECII_arr6[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII6").html() == "6th"){
                                PDIECII_arr6[5] = PDIECII_arr6[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII5
                              if($(".competency-PDIE998_CII5").html() == "1st"){
                                PDIECII_arr5[0] = PDIECII_arr5[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII5").html() == "2nd"){
                                PDIECII_arr5[1] = PDIECII_arr5[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII5").html() == "3rd"){
                                PDIECII_arr5[2] = PDIECII_arr5[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII5").html() == "4th"){
                                PDIECII_arr5[3] = PDIECII_arr5[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII5").html() == "5th"){
                                PDIECII_arr5[4] = PDIECII_arr5[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII5").html() == "6th"){
                                PDIECII_arr5[5] = PDIECII_arr5[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII4
                              if($(".competency-PDIE998_CII4").html() == "1st"){
                                PDIECII_arr4[0] = PDIECII_arr4[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII4").html() == "2nd"){
                                PDIECII_arr4[1] = PDIECII_arr4[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII4").html() == "3rd"){
                                PDIECII_arr4[2] = PDIECII_arr4[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII4").html() == "4th"){
                                PDIECII_arr4[3] = PDIECII_arr4[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII4").html() == "5th"){
                                PDIECII_arr4[4] = PDIECII_arr4[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII4").html() == "6th"){
                                PDIECII_arr4[5] = PDIECII_arr4[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII3
                              if($(".competency-PDIE998_CII3").html() == "1st"){
                                PDIECII_arr3[0] = PDIECII_arr3[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII3").html() == "2nd"){
                                PDIECII_arr3[1] = PDIECII_arr3[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII3").html() == "3rd"){
                                PDIECII_arr3[2] = PDIECII_arr3[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII3").html() == "4th"){
                                PDIECII_arr3[3] = PDIECII_arr3[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII3").html() == "5th"){
                                PDIECII_arr3[4] = PDIECII_arr3[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII3").html() == "6th"){
                                PDIECII_arr3[5] = PDIECII_arr3[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII2
                              if($(".competency-PDIE998_CII2").html() == "1st"){
                                PDIECII_arr2[0] = PDIECII_arr2[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII2").html() == "2nd"){
                                PDIECII_arr2[1] = PDIECII_arr2[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII2").html() == "3rd"){
                                PDIECII_arr2[2] = PDIECII_arr2[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII2").html() == "4th"){
                                PDIECII_arr2[3] = PDIECII_arr2[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII2").html() == "5th"){
                                PDIECII_arr2[4] = PDIECII_arr2[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII2").html() == "6th"){
                                PDIECII_arr2[5] = PDIECII_arr2[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                              //CII1
                              if($(".competency-PDIE998_CII1").html() == "1st"){
                                PDIECII_arr1[0] = PDIECII_arr1[0]+'1'
                                PDIECII_arrForMaxMin[0] = PDIECII_arrForMaxMin[0] = 1
                                PDIECII_arrCounter[0] = PDIECII_arrCounter[0]+'1'
                              }
                              if($(".competency-PDIE998_CII1").html() == "2nd"){
                                PDIECII_arr1[1] = PDIECII_arr1[1]+'2'
                                PDIECII_arrForMaxMin[1] = PDIECII_arrForMaxMin[1] = 2
                                PDIECII_arrCounter[1] = PDIECII_arrCounter[1]+'2'
                              }
                              if($(".competency-PDIE998_CII1").html() == "3rd"){
                                PDIECII_arr1[2] = PDIECII_arr1[2]+'3'
                                PDIECII_arrForMaxMin[2] = PDIECII_arrForMaxMin[2] = 3
                                PDIECII_arrCounter[2] = PDIECII_arrCounter[2]+'3'
                              }
                              if($(".competency-PDIE998_CII1").html() == "4th"){
                                PDIECII_arr1[3] = PDIECII_arr1[3]+'4'
                                PDIECII_arrForMaxMin[3] = PDIECII_arrForMaxMin[3] = 4
                                PDIECII_arrCounter[3] = PDIECII_arrCounter[3]+'4'
                              }
                              if($(".competency-PDIE998_CII1").html() == "5th"){
                                PDIECII_arr1[4] = PDIECII_arr1[4]+'5'
                                PDIECII_arrForMaxMin[4] = PDIECII_arrForMaxMin[4] = 5
                                PDIECII_arrCounter[4] = PDIECII_arrCounter[4]+'5'
                              }
                              if($(".competency-PDIE998_CII1").html() == "6th"){
                                PDIECII_arr1[5] = PDIECII_arr1[5]+'6'
                                PDIECII_arrForMaxMin[5] = PDIECII_arrForMaxMin[5] = 6
                                PDIECII_arrCounter[5] = PDIECII_arrCounter[5]+'6'
                              }
    
                            //condition here
                            PDIECII_arrForMaxMin.forEach(function(val,index) { 
                                if(val == ''){
                                  //do nothing
                                }else{
                                  PDIECII_arrForMaxMinCleaned.push(val)
                                }
                              }) 
                              let minValue3 = Math.min(...PDIECII_arrForMaxMinCleaned)
                              let maxValue3 = Math.max(...PDIECII_arrForMaxMinCleaned)
                              let firstCount3 = 0
                              let secondCount3 = 0
                              let thirdCount3 = 0
                              let fourthCount3 = 0
                              let fifthCount3 = 0
                              let sixthCount3 = 0
                              for(let x=0; x<6; x++){
                                // find the lowest
                                if(PDIECII_arr6[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII6").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=1st")
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII5").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=1st")
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII4").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=1st")
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII3").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=1st")
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII2").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=1st")
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3 && PDIECII_arrCounter[x].includes(minValue3)){
                                  $(".competency-PDIE998_CII1").html("1st")
                                  firstCount3 = PDIECII_arrCounter[x].length
                                  if(firstCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=1st")
                                  }
                                }
                                //find the 2nd lowest
                                if(PDIECII_arr6[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+1 && PDIECII_arrCounter[x].includes(minValue3+1)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+1)))
                                  secondCount3 = PDIECII_arrCounter[x].length
                                  if(secondCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+1)))
                                  }
                                }
    
                                //find the 3rd lowest
                                if(PDIECII_arr6[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+2 && PDIECII_arrCounter[x].includes(minValue3+2)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+1)))
                                  thirdCount3 = PDIECII_arrCounter[x].length
                                  if(thirdCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+1)))
                                  }
                                }
    
                                //find the 4th lowest
                                if(PDIECII_arr6[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+3 && PDIECII_arrCounter[x].includes(minValue3+3)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  fourthCount3 = PDIECII_arrCounter[x].length
                                  if(fourthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+1)))
                                  }
                                }
    
                                //find the 5th lowest
                                if(PDIECII_arr6[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+4 && PDIECII_arrCounter[x].includes(minValue3+4)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  fifthCount3 = PDIECII_arrCounter[x].length
                                  if(fifthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+1)))
                                  }
                                }
    
                                //find the 6th lowest
                                if(PDIECII_arr6[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII6").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII6").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                 
                                if(PDIECII_arr5[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII5").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII5").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                       
                                if(PDIECII_arr4[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII4").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII4").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                          
                                if(PDIECII_arr3[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII3").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII3").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                        
                                if(PDIECII_arr2[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII2").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII2").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                           
                                if(PDIECII_arr1[x] == minValue3+5 && PDIECII_arrCounter[x].includes(minValue3+5)){
                                  $(".competency-PDIE998_CII1").html(ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  sixthCount3 = PDIECII_arrCounter[x].length
                                  if(sixthCount3 > 1){
                                    $(".competency-PDIE998_CII1").html("=" + ordinal(Math.round(firstCount3+secondCount3+thirdCount3+fourthCount3+fifthCount3+1)))
                                  }
                                }
                              //end of loop
                              }
                            //end of PDIE
    
                              $('.table360-competency').removeClass('d-none')
                              $('.competency_spinner').addClass('d-none')
                              `
                          }
                        }
                        
                        // Radar Chart for section 2
                        if (data.Template === 'Radar-Chart-New-360-Head-Self') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                            function getDataSet(){
                              var dataset = [];
    
                            let Self = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let Psup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(139, 233, 153, 0.2)',
                                      borderColor: '#8be999',
                                      pointBackgroundColor: '#8be999',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let Peers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let DirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(164, 99, 216, 0.2)',
                                      borderColor: 'rgb(164, 99, 216)',
                                      pointBackgroundColor: 'rgb(164, 99, 216)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let PeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113,0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PeersDirectReport
    
                              let Internal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let External = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(220, 55, 56,0.2)',
                                      borderColor: 'rgb(220, 55, 56)',
                                      pointBackgroundColor: 'rgb(220, 55, 56)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let InternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of InternalExternal
                              
                              let PDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,0,PeersDirectReport);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,0,Peers);
                                    dataset.splice(3,0,DirectReport);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,0,InternalExternal);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,0,Internal);
                                    dataset.splice(5,0,External);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    dataset = []
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,0,PDIE);
                                  } else {
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                  }
                                    $('.radar_head').removeClass('d-none')
                                    $('.radarchart_head_spinner').addClass('d-none')  
                                return dataset;
                              }
    
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getDataSet (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Radar-Chart-New-360-Head-Cohort') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            
                            output += `
                            function getcohortDataSet(){
                              var cohortdataset = [];
    
                            let cohortSelf = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(229, 151, 247, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let cohortPsup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'rgb(140,233,154)',
                                      pointBackgroundColor: 'rgb(140,233,154)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let cohortPeers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let cohortDirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(164, 99, 216, 0.2)',
                                      borderColor: 'rgb(164, 99, 216)',
                                      pointBackgroundColor: 'rgb(164, 99, 216)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let cohortPeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113,0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortPeersDirectReport
    
                              let cohortInternal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let cohortExternal = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(220, 55, 56,0.2)',
                                      borderColor: 'rgb(220, 55, 56)',
                                      pointBackgroundColor: 'rgb(220, 55, 56)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let cohortInternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortInternalExternal
                              
                              let cohortPDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,0,cohortPeersDirectReport);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,0,cohortPeers);
                                    cohortdataset.splice(3,0,cohortDirectReport);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,0,cohortInternalExternal);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,0,cohortInternal);
                                    cohortdataset.splice(5,0,cohortExternal);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    cohortdataset = []
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,0,cohortPDIE);
                                  } else {
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                  }
                                  $('.radar_cohort').removeClass('d-none')
                                  $('.radarchart_cohort_spinner').addClass('d-none')
                                return cohortdataset;
                              }
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getcohortDataSet (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        // Organizational Climate Breakdown - TL
                        if (data.Template === 'Radar-Chart-New-360-Self') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            output += `
                            function getClimateDataset(){
                              var dataset = [];
    
                            let Self = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(184, 113, 26, 0.2)',
                                      borderColor: 'rgb(184, 113, 26)',
                                      pointBackgroundColor: 'rgb(184, 113, 26)',
                                      pointBorderColor: 'rgb(184, 113, 26)',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(184, 113, 26)',
                              }
                              //end of self
                              
                            let Psup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'rgb(140,233,154)',
                                      pointBackgroundColor: 'rgb(140,233,154)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let Peers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let DirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(164, 99, 216, 0.2)',
                                      borderColor: 'rgb(164, 99, 216)',
                                      pointBackgroundColor: 'rgb(164, 99, 216)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let PeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113,0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PeersDirectReport
    
                              let Internal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let External = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(220, 55, 56,0.2)',
                                      borderColor: 'rgb(220, 55, 56)',
                                      pointBackgroundColor: 'rgb(220, 55, 56)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let InternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of InternalExternal
                              
                              let PDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,0,PeersDirectReport);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,0,Peers);
                                    dataset.splice(3,0,DirectReport);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,0,InternalExternal);
                                    dataset.splice(8,1);
                                  } else {
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,0,Internal);
                                    dataset.splice(5,0,External);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    dataset = []
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                    dataset.splice(2,1);
                                    dataset.splice(3,1);
                                    dataset.splice(4,1);
                                    dataset.splice(5,1);
                                    dataset.splice(6,1);
                                    dataset.splice(7,1);
                                    dataset.splice(8,0,PDIE);
                                  } else {
                                    dataset.splice(0,0,Self);
                                    dataset.splice(1,0,Psup);
                                  }
                                    $('.radar_head').removeClass('d-none')
                                    $('.radarchart_head_spinner').addClass('d-none')  
                                return dataset;
                              }
    
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getClimateDataset (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }
                        if (data.Template === 'Radar-Chart-New-360-Cohort') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                             
                            output += `
                            function getClimatecohortDataset(){
                              var cohortdataset = [];
    
                            let cohortSelf = 
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                      Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(184, 113, 26, 0.2)',
                                      borderColor: 'rgb(184, 113, 26)',
                                      pointBackgroundColor: 'rgb(184, 113, 26)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of self
                              
                            let cohortPsup = 
                                    {
                                      label: '`+ res.label2 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(140,233,154, 0.2)',
                                      borderColor: 'rgb(140,233,154)',
                                      pointBackgroundColor: 'rgb(140,233,154)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                              }
                              //end of Psup
    
                              let cohortPeers = 
                                    {
                                      label: '`+ res.label3 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Peers
    
                              let cohortDirectReport = 
                                    {
                                      label: '`+ res.label4 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(164, 99, 216, 0.2)',
                                      borderColor: 'rgb(164, 99, 216)',
                                      pointBackgroundColor: 'rgb(164, 99, 216)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of DirectReport
    
                              let cohortPeersDirectReport = 
                                    {
                                      label: '`+ res.label7 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113,0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortPeersDirectReport
    
                              let cohortInternal = 
                                    {
                                      label: '`+ res.label5 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of Internal
    
                              let cohortExternal = 
                                    {
                                      label: '`+ res.label6 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(220, 55, 56,0.2)',
                                      borderColor: 'rgb(220, 55, 56)',
                                      pointBackgroundColor: 'rgb(220, 55, 56)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of External
                              
                              let cohortInternalExternal = 
                                    {
                                      label: '`+ res.label8 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(64, 164, 217,0.2)',
                                      borderColor: 'rgb(64, 164, 217)',
                                      pointBackgroundColor: 'rgb(64, 164, 217)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of cohortInternalExternal
                              
                              let cohortPDIE = 
                                    {
                                      label: '`+ res.label9 +`',
                                      data: [
                            `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1),
                                      `
                                    })
                            output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(14, 80, 113, 0.2)',
                                      borderColor: 'rgb(14, 80, 113)',
                                      pointBackgroundColor: 'rgb(14, 80, 113)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                              }
                              //end of PDIE
    
                              // condition starts here 
    
                                if(res.data[0].peer2_N === null){
                                  res.data[0].peer2_N = 0
                                }
                                if(res.data[0].direct3_N === null){
                                  res.data[0].direct3_N = 0
                                }
                                if(res.data[0].PD996_N === null){
                                  res.data[0].PD996_N = 0
                                }
                                if(res.data[0].internal4_N === null){
                                  res.data[0].internal4_N = 0
                                }
                                if(res.data[0].external5_N === null){
                                  res.data[0].external5_N = 0
                                }
                                if(res.data[0].IE997_N === null){
                                  res.data[0].IE997_N = 0
                                }
                                if(res.data[0].PDIE998_N === null){
                                  res.data[0].PDIE998_N = 0
                                }
    
                                if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,0,cohortPeersDirectReport);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,0,cohortPeers);
                                    cohortdataset.splice(3,0,cohortDirectReport);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,0,cohortInternalExternal);
                                    cohortdataset.splice(8,1);
                                  } else {
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,0,cohortInternal);
                                    cohortdataset.splice(5,0,cohortExternal);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,1);
                                  }
    
                                if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                    cohortdataset = []
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                    cohortdataset.splice(2,1);
                                    cohortdataset.splice(3,1);
                                    cohortdataset.splice(4,1);
                                    cohortdataset.splice(5,1);
                                    cohortdataset.splice(6,1);
                                    cohortdataset.splice(7,1);
                                    cohortdataset.splice(8,0,cohortPDIE);
                                  } else {
                                    cohortdataset.splice(0,0,cohortSelf);
                                    cohortdataset.splice(1,0,cohortPsup);
                                  }
                                  $('.radar_cohort').removeClass('d-none')
                                  $('.radarchart_cohort_spinner').addClass('d-none')
                                return cohortdataset;
                              }
                              let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                              ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                                    `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                    `
                                  })
                            output += `
                                          ],
                                  datasets: getClimatecohortDataset (),
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                            `
                          }
                        }

                        //Highest and Lowest
                        if (data.Template === 'Bar-Chart-New-360-Highest') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                $(".highest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".highest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".highest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                                $(".highest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                              `
                            })
                            output +=`
                              $('.highest-sortme').sort(function(a, b) {
                                if (a.textContent > b.textContent) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                              }).appendTo($('.highest-wrapper'));
    
                              const highestStartLength = 5
                              const highestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                              $('.highest-sortme').slice(highestStartLength, highestMaxLength).hide();
                              $('.highest-averageScore').hide()
    
                              $('.highest_table').removeClass('d-none')
                              $('.highest_spinner').addClass('d-none')
                            `
                          }
                        }
                        if (data.Template === 'Bar-Chart-New-360-Lowest') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                $(".lowest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".lowest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".lowest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                                $(".lowest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                              `
                            })
                            output +=`
                              $('.lowest-sortme').sort(function(a, b) {
                                if (a.textContent < b.textContent) {
                                    return -1;
                                } else {
                                    return 1;
                                }
                              }).appendTo($('.lowest-wrapper'));
    
                              const lowestStartLength = 5
                              const lowestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                              $('.lowest-sortme').slice(lowestStartLength, lowestMaxLength).hide();
                              $('.lowest-averageScore').hide()
    
                              $('.lowest_table').removeClass('d-none')
                              $('.lowest_spinner').addClass('d-none')
                            `
                          }
                        }

                        //Blind spot
                        if (data.Template === 'Table-360-New-Overestimated') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".overestimated-row` + response.Trait + `").css("display","none")
                                }
                                  $(".overestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".overestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  $(".overestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                                if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) > -1.5 ){
                                  $(".overestimated-row` + response.Trait + `").css("display","none")
                                }
                              `
                            })
                            output +=`
                            overestimateChild = $('#overestimate-wrapper>tr:not([style*="display: none"]')
                            if(overestimateChild.length === 0){
                              $(".overestimated-none").removeClass('d-none')
                              $(".overestimated-row-head").addClass('d-none')
                            } else {
                              $(".overestimated-none").addClass('d-none')
                              $(".overestimated-row-head").removeClass('d-none')
                            }
                            overestimateChild.sort(function(a, b) {
                                return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                            }).appendTo($("#overestimate-wrapper"));
                            `
                          }
                        }
                        if (data.Template === 'Table-360-New-Underestimated') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += 
                              `
                                if(res.data[0].ind_` + response.Trait + ` == 0){
                                  $(".underestimated-row` + response.Trait + `").css("display","none")
                                }
                                $(".underestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                $(".underestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".underestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                                if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) < 1.5 ){
                                  $(".underestimated-row` + response.Trait + `").css("display","none")
                                  }
                              `
                            })
                            output +=`
                            underestimateChild = $('#underestimate-wrapper>tr:not([style*="display: none"]')
                            if(underestimateChild.length === 0){
                              $(".underestimated-none").removeClass('d-none')
                              $(".underestimated-row-head").addClass('d-none')
                            } else {
                              $(".underestimated-none").addClass('d-none')
                              $(".underestimated-row-head").removeClass('d-none')
                            }
                            underestimateChild.sort(function(a, b) {
                                return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                            }).appendTo($("#underestimate-wrapper"));
                            `
                          }
                        }
                        
                        


                      }

                      output += `
                        $(".f5-spinner").addClass("d-none")
                        $(".f5-table").removeClass("d-none")
                    }) 
                  `
                }
              }

                
              // restore radio button answers
              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                
                if (data.Template === 'LMC-Accuracy-5-N' || data.Template === 'Scale-0-10' || data.Template === 'Scale-1-10' || data.Template === 'Yes-No-Maybe'  || data.Template === 'Yes-No' || data.Template === 'LMC-Effectiveness-7' || data.Template === 'LMC-Effectiveness-7+1' || data.Template === 'LMC-Effectiveness-6+1'|| data.Template === 'LMC-Effectiveness-10+1' || data.Template === 'LMC-Characteristic-5') { 
                  output += `
                    axios
                      .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}' + '`, config)' + `
                      .then((res) => {
                        let surveyResultList = res.data
                        for (const data of surveyResultList) {
                                        
                          let surveyStr = '[name = "'
                          surveyStr += data.statement_num
                          surveyStr += '"]'
                          surveyStr += '[value = "'
                          surveyStr += data.score
                          surveyStr += '"]'
      
                          $(surveyStr).prop('checked', true)  
                          // $('input[name="' + data.statement_num + '"]').val(data.answer);
                        }
                      })     
                  `
                  break
                }
              }
              // Script for reorder
              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                if (data.Template === 'Re-order') {
                  output += `
                  reqReOrder = false
                  let surveyData = []
                  let reOrderQuestions = []
      
                  axios
                    .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}' + '`, config)' + `
                    .then((res) => {
                      for (const data of res.data) {
                        if (data.record_type === "Ranking" && data.answer !== "") {
                          let question = data.answer
                          let rank = data.score
                          reOrderQuestions.push({question, rank})
                        }
                      }
                      reOrderQuestions.sort((a, b) => (a.rank < b.rank) ? -1 : ((b.rank > a.rank) ? 1 : 0))
                      for (const data of reOrderQuestions) {
                        surveyData.push(data.question)
                      }
                      if (surveyData.length === 0) {
                        reqReOrder = false
                        `
                      for (let i = 0; i < input.length; i++) {
                        let data = input[i];
                        if (data.Template == 'Re-order') {
                          output += `
                           surveyData.push("` + data.Text + `")
                          `
                        }
                      }
                      output += `
                      } else {
                        reqReOrder = true
                      }
      
                      const rank_list = document.getElementById('rank')
                      const draggable_list = document.getElementById('box')
      
                      // Store listitems
                      const listItems = []
                      const listInput = []
                      const result = []
                      let obj = {}
                      createList()
                      createRank()
      
                      function createList() {
                        [...surveyData].forEach((question, index) => {
                          // Div
                          const listItem = document.createElement('div')
                          listItem.setAttribute('data-index', index)
                          listItem.setAttribute('id', 'answer')
                          listItem.classList.add('item')
                          listItem.draggable = true
                          listItem.textContent = question
                          listItems.push(listItem)
                          draggable_list.appendChild(listItem)
      
                          if (index + 1 == surveyData.length) {
                            const lastItem = document.createElement('div')
                            lastItem.classList.add('item')
                            listItems.push(lastItem)
                            draggable_list.appendChild(lastItem)
                          }
                        })
                      }
      
                      function createRank() {
                        [...surveyData].forEach((item, index) => {
                          // Div
                          const rankItem = document.createElement('div')
                          rankItem.classList.add('rank-no')
                          rankItem.textContent = index + 1
                          rank_list.appendChild(rankItem)
                        })
                      }
      
                      // === Define Variables and Elements ===
                      let elements = document.querySelectorAll('.box .item')
                      let wrapper = document.getElementById('box')
                      let itemClip = document.getElementById('itemClip')
      
                      let targetEl
                      let scopeObj
      
                      // === Event Binding ===
                      for (let i = 0, max = elements.length; i < max; i++) {
                        elements[i].addEventListener('dragstart', handleDrag)
                        elements[i].addEventListener('dragend', handleDragEnd)
                        elements[i].addEventListener('dragenter', handleDragEnter)
                        if (i + 1 == elements.length) {
                          elements[i - 1].addEventListener('touchstart', handleTouch)
                          elements[i - 1].addEventListener('touchend', handleTouchEnd)
                          elements[i - 1].addEventListener('touchmove', handleTouchMove)
                        } else {
                          elements[i].addEventListener('touchstart', handleTouch)
                          elements[i].addEventListener('touchend', handleTouchEnd)
                          elements[i].addEventListener('touchmove', handleTouchMove)   
                        }
                      }
      
                      // === Function Kits ===
                      function handleDrag(event) {
                        targetEl = event.target
                        targetEl.classList.add('onDrag')
                        reqReOrder = true
                      }
      
                      function handleDragEnd(event) {
                        targetEl = event.target
                        targetEl.classList.remove('onDrag')
                        const Qitems = wrapper.querySelectorAll('#answer')
                        let result = []
                        Qitems.forEach((listItem, index) => {
                          let rank = index + 1
                          let text = listItem.innerText
                          result.push({rank,text})
                        })
                        `
                        input.forEach(data => {
                          if (data.Template == 'Re-order') {
                            output += `
                              for (const key of result) {
                                if (key.text === "` + data.Text + `") {
                                  axios
                                    .put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                                      {
                                        answer:  "` + data.Text + `",
                                        score: key.rank,
                                        modified_by: ind_id,
                                      }, config)
                                    .then(function (res) {
                                      console.log(res)
                                    })
                                    .catch(function (error) {
                                      console.log(error)
                                    })
                                }
                              }      
                            `
                          }
                        })
                      output += `
                      }
      
                      function handleDragEnter(event) {
                        wrapper.insertBefore(targetEl, event.target)
                      }
      
                      function handleTouch(event) {
                        defineScope(elements)
                        targetEl = event.target
                        itemClip.style.top = event.changedTouches[0].pageY + 'px'
                        itemClip.style.left = event.changedTouches[0].pageX + 'px'
                        itemClip.innerText = event.target.innerText
                        itemClip.classList.remove('hide')
                        targetEl.classList.add('onDrag')
                        reqReOrder = true
                      }
      
                      function handleTouchEnd(event) {
                        itemClip.classList.add('hide')
                        targetEl.classList.remove('onDrag')
                        const Qitems = wrapper.querySelectorAll('#answer')
                        reqReOrder = true
                        let result = []
                        Qitems.forEach((listItem, index) => {
                          let rank = index + 1
                          let text = listItem.innerText
                          result.push({rank,text})
                        })
                        `
                        input.forEach(data => {
                          if (data.Template == 'Re-order') {
                            output += `
                              for (const key of result) {
                                if (key.text === "` + data.Text + `") {
                                  axios
                                    .put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                                      {
                                        answer:  "` + data.Text + `",
                                        score: key.rank,
                                        modified_by: ind_id,
                                      }, config)
                                    .then(function (res) {
                                      console.log(res)
                                    })
                                    .catch(function (error) {
                                      console.log(error)
                                    })
                                }
                              }
                            `
                          }
                        });
                      output += `
                      }
      
                      function handleTouchMove(event) {
                        itemClip.style.top = event.changedTouches[0].pageY + 'px'
                        itemClip.style.left = event.changedTouches[0].pageX  + 'px'
                        hitTest(event.changedTouches[0].pageX, event.changedTouches[0].pageY)
                      }
      
                      function hitTest(thisX, thisY) {
                        for (let i = 0, max = scopeObj.length; i < max; i++) {
                          if (thisX > scopeObj[i].startX && thisX < scopeObj[i].endX) {
                            if (thisY > scopeObj[i].startY && thisY < scopeObj[i].endY) {        
                              wrapper.insertBefore(targetEl, scopeObj[i].target)
                              return
                            }
                          }
                        }
                      }
      
                      function defineScope(elementArray) {
                        scopeObj = []
                        for (let i = 0, max = elementArray.length; i < max; i++) {
                          let newObj = {}
                          newObj.target = elementArray[i]
                          newObj.startX = elementArray[i].offsetLeft
                          newObj.endX = elementArray[i].offsetLeft + elementArray[i].offsetWidth
                          newObj.startY = elementArray[i].offsetTop
                          newObj.endY = elementArray[i].offsetTop + elementArray[i].offsetHeight
                          scopeObj.push(newObj)
                        }
                      }
      
                    }) // end of axios call
                `
                  break
                }
              }
    
              // Script for Gender_culture individual
              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                if (data.Template === 'Gender-Culture-Individual') { 
                  output += `
                    axios.get('${url}/b5-get-countries', config)
                    .then((res) => {
                        countryList = res.data

                        let outputHTML = '<select id="norms_country" class="form-select" aria-label="Select nationality">'
                        outputHTML += '<option disabled>Select nationality</option>'
      
                        countryList.map((result, index) => {
                            outputHTML += ` +
                            '`' +
                              '<option value="${result.country}">${result.country}</option>' +     
                              '`' +    
                            `
                        })
                        outputHTML += '</select>'
  
                        $('.country_list').html(outputHTML)
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                    $.getJSON('calculation.json', (jsonData) => {
                      axios
                        .post(
                          '${url}/b5-report-data2',
                          {
                            survey_assignment_id : survey_assignment_id,
                            sex : sex,
                            country : country,
                            source : "IPIP120"
                          },
                          config                                            
                        )
                        .then((res) => {
                          console.log(res.data)
                          $('#gender_label').text(sex)
                          $('#culture_label').text(country)
                          $('#norms_gender').val(sex)
                          $('#norms_country').val(country)
                          $(".country-flag").attr("src",` + '`' + 'flags/4x3/${country}.svg' + '`)' + `
                          $(".gender-icon").attr("src",` + '`' + 'gender/${sex}.svg' + '`)' + `

                          // catch error if no sex and country in b5_norm_sum ex. if the sex is trans 
                          if(res.data.status == "noData") {
                            $('.isRadarChartHasData').text('noNormingData')
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                              customClass: 'norm-wording'
                            })
                          }
                          // catch error if there are sex and country in b5_norm_sum but there is no data in other column like er1_mean column
                          if(res.data.status == "noNormingData") {
                            $('.isRadarChartHasData').text('noNormingData')
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          // catch error if no data with this survey_assignment_id or something went wrong
                          // need to check it by developer
                          if(res.data.status == "noSurveyData") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>That didn’t go to plan. Please email your own name and email address to help@talentsage.com. We’ll review it and let you know when it’s fine.</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          if(expandAll === "true") {
                            if(res.data[0].n <= 30){
                              console.log('print pdf only')

                              setTimeout(function () {
                                $('#adjustNormBtnSubmit').click();
                              }, 1000);

                              $('.isRadarChartHasData').text('noNormingData')

                              printPDFonlyAndNoData = true

                            }

                          } else {

                            if(res.data[0].n <= 30){
                              $('.isRadarChartHasData').text('noNormingData')
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                                customClass: 'norm-wording'
                              })
                            }
                            
                          }

                          $('.isRadarChartHasData').text('hasData')
    
                          `
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i]
                              if (data.Template === 'Comparison-Chart-Task-vs-Relationship') { 
                                output += `
                                  let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                                  let meanTvsR = totalTvsR / 3
                                  if(meanTvsR <= 25) {
                                    $('.task-vs-relationship-dynamic-title').text("Tasks much more than Relationships")
                                    $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/20.jpg")
                                  }
                                  else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                    $('.task-vs-relationship-dynamic-title').text("Tasks more than Relationships")
                                    $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/40.jpg")
                                  }
                                  else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                    $('.task-vs-relationship-dynamic-title').text("Tasks and Relationships equally")
                                    $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/60.jpg")
                                  }
                                  else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                    $('.task-vs-relationship-dynamic-title').text("Relationships more than Tasks")
                                    $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/80.jpg")
                                  }
                                  else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                    $('.task-vs-relationship-dynamic-title').text("Relationships much more than Tasks")
                                    $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/100.jpg")
                                  }
                                `
                                break
                              }
                            }
                            
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i]
                              if (data.Template === 'Comparison-Chart-Team-vs-Individual') { 
                                output += `
                                  let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                                  let meanTeamvsIndividual = totalTeamvsIndividual / 3
                                  if(meanTeamvsIndividual <= 25) {
                                    $('.team-vs-individual-dynamic-title').text("Autonomy much more than Structure")
                                    $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/100.jpg")
                                  }
                                  else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                    $('.team-vs-individual-dynamic-title').text("Autonomy more than Structure")
                                    $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/80.jpg")
                                  }
                                  else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                    $('.team-vs-individual-dynamic-title').text("Structure and Autonomy equally")
                                    $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/60.jpg")
                                  }
                                  else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                    $('.team-vs-individual-dynamic-title').text("Structure more than Autonomy")
                                    $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/40.jpg")
                                  }
                                  else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                    $('.team-vs-individual-dynamic-title').text("Structure much more than Autonomy")
                                    $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/20.jpg")
                                  }
                                `
                                break
                              }
                            }
                            
                            
                            output += `
    
                              const ordinal = (number) => {
                              const ordinalRules = new Intl.PluralRules("en", {
                                type: "ordinal"
                              });
                              const suffixes = {
                                one: "st",
                                two: "nd",
                                few: "rd",
                                other: "th"
                              };
                              const suffix = suffixes[ordinalRules.select(number)];
                                return (number + suffix);
                              }
                              function getRanking (rank) {
                                if (rank <= 5){
                                  return 5
                                } else if (rank >= 95) {
                                  return 95
                                } else {
                                  return rank
                                }
                              }

                              for (const data of jsonData) {      
                                // ER
                                if (res.data[0].z_score_desc_ER === "VERY HIGH" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very high.' + ' ' + '`' + `))
                                  $("#replace-emotional-reactiveness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER === "HIGH" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is high.' + ' ' + '`' + `))
                                  $("#replace-emotional-reactiveness").append(data.High)
                                } else if (res.data[0].z_score_desc_ER === "TYPICAL" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is typical.' + ' ' + '`' + `))
                                  $("#replace-emotional-reactiveness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_ER === "LOW" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is low.' + ' ' + '`' + `))
                                  $("#replace-emotional-reactiveness").append(data.Low)
                                } else if (res.data[0].z_score_desc_ER === "VERY LOW" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very low.' + ' ' + '`' + `))
                                  $("#replace-emotional-reactiveness").append(data.Very_low)
                                }
            
                                if (res.data[0].z_score_desc_ER1 === "VERY HIGH" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very high' + '`' + `)
                                  $("#anxiety").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER1 === "HIGH" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is high' + '`' + `)
                                  $("#anxiety").text(data.High)
                                } else if (res.data[0].z_score_desc_ER1 === "TYPICAL" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is typical' + '`' + `)
                                  $("#anxiety").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER1 === "LOW" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is low' + '`' + `)
                                  $("#anxiety").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER1 === "VERY LOW" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very low' + '`' + `)
                                  $("#anxiety").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER2 === "VERY HIGH" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very high' + '`' + `)
                                  $("#anger").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER2 === "HIGH" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is high' + '`' + `)
                                  $("#anger").text(data.High)
                                } else if (res.data[0].z_score_desc_ER2 === "TYPICAL" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is typical' + '`' + `)
                                  $("#anger").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER2 === "LOW" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is low' + '`' + `)
                                  $("#anger").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER2 === "VERY LOW" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very low' + '`' + `)
                                  $("#anger").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_ER3 === "VERY HIGH" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very high' + '`' + `)
                                  $("#sadness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER3 === "HIGH" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is high' + '`' + `)
                                  $("#sadness").text(data.High)
                                } else if (res.data[0].z_score_desc_ER3 === "TYPICAL" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is typical' + '`' + `)
                                  $("#sadness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER3 === "LOW" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is low' + '`' + `)
                                  $("#sadness").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER3 === "VERY LOW" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very low' + '`' + `)
                                  $("#sadness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER4 === "VERY HIGH" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very high' + '`' + `)
                                  $("#self-consciousness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER4 === "HIGH" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is high' + '`' + `)
                                  $("#self-consciousness").text(data.High)
                                } else if (res.data[0].z_score_desc_ER4 === "TYPICAL" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is typical' + '`' + `)
                                  $("#self-consciousness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER4 === "LOW" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is low' + '`' + `)
                                  $("#self-consciousness").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER4 === "VERY LOW" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very low' + '`' + `)
                                  $("#self-consciousness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER5 === "VERY HIGH" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very high' + '`' + `)
                                  $("#self-indulgence").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER5 === "HIGH" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is high' + '`' + `)
                                  $("#self-indulgence").text(data.High)
                                } else if (res.data[0].z_score_desc_ER5 === "TYPICAL" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is typical' + '`' + `)
                                  $("#self-indulgence").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER5 === "LOW" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is low' + '`' + `)
                                  $("#self-indulgence").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER5 === "VERY LOW" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very low' + '`' + `)
                                  $("#self-indulgence").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER6 === "VERY HIGH" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very high' + '`' + `))
                                  $("#vulnerability").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER6 === "HIGH" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is high' + '`' + `))
                                  $("#vulnerability").text(data.High)
                                } else if (res.data[0].z_score_desc_ER6 === "TYPICAL" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is typical' + '`' + `))
                                  $("#vulnerability").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER6 === "LOW" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is low' + '`' + `))
                                  $("#vulnerability").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER6 === "VERY LOW" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very low' + '`' + `))
                                  $("#vulnerability").text(data.Very_Low)
                                }
            
                                // E
                                if (res.data[0].z_score_desc_E === "VERY HIGH" && data.Reference === "E") { 
                                  $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very high.' + ' ' + '`' + `))
                                  $("#replace-extraversion").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_E === "HIGH" && data.Reference === "E") {
                                  $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is high.' + ' ' + '`' + `))
                                  $("#replace-extraversion").append(data.High)
                                } else if (res.data[0].z_score_desc_E === "TYPICAL" && data.Reference === "E") {
                                  $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is typical.' + ' ' + '`' + `))
                                  $("#replace-extraversion").append(data.Typical)
                                } else if (res.data[0].z_score_desc_E === "LOW" && data.Reference === "E") {
                                  $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is low.' + ' ' + '`' + `))
                                  $("#replace-extraversion").append(data.Low)
                                } else if (res.data[0].z_score_desc_E === "VERY LOW" && data.Reference === "E") {
                                  $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very low.' + ' ' + '`' + `))
                                  $("#replace-extraversion").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E1 === "VERY HIGH" && data.Reference === "E1") { 
                                  $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very high' + '`' + `))
                                  $("#friendliness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E1 === "HIGH" && data.Reference === "E1") {
                                  $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is high' + '`' + `))
                                  $("#friendliness").text(data.High)
                                } else if (res.data[0].z_score_desc_E1 === "TYPICAL" && data.Reference === "E1") {
                                  $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is typical' + '`' + `))
                                  $("#friendliness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E1 === "LOW" && data.Reference === "E1") {
                                  $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is low' + '`' + `))
                                  $("#friendliness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E1 === "VERY LOW" && data.Reference === "E1") {
                                  $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very low' + '`' + `))
                                  $("#friendliness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E2 === "VERY HIGH" && data.Reference === "E2") {
                                  $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very high' + '`' + `))
                                  $("#sociability").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E2 === "HIGH" && data.Reference === "E2") {
                                  $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is high' + '`' + `))
                                  $("#sociability").text(data.High)
                                } else if (res.data[0].z_score_desc_E2 === "TYPICAL" && data.Reference === "E2") {
                                  $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is typical' + '`' + `))
                                  $("#sociability").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E2 === "LOW" && data.Reference === "E2") {
                                  $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is low' + '`' + `))
                                  $("#sociability").text(data.Low)
                                } else if (res.data[0].z_score_desc_E2 === "VERY LOW" && data.Reference === "E2") {
                                  $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very low' + '`' + `))
                                  $("#sociability").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_E3 === "VERY HIGH" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very high' + '`' + `))
                                  $("#assertiveness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E3 === "HIGH" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is high' + '`' + `))
                                  $("#assertiveness").text(data.High)
                                } else if (res.data[0].z_score_desc_E3 === "TYPICAL" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is typical' + '`' + `))
                                  $("#assertiveness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E3 === "LOW" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is low' + '`' + `))
                                  $("#assertiveness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E3 === "VERY LOW" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very low' + '`' + `))
                                  $("#assertiveness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E4 === "VERY HIGH" && data.Reference === "E4") {
                                  $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very high' + '`' + `))
                                  $("#activity-level").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E4 === "HIGH" && data.Reference === "E4") {
                                  $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is high' + '`' + `))
                                  $("#activity-level").text(data.High)
                                } else if (res.data[0].z_score_desc_E4 === "TYPICAL" && data.Reference === "E4") {
                                  $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is typical' + '`' + `))
                                  $("#activity-level").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E4 === "LOW" && data.Reference === "E4") {
                                  $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is low' + '`' + `))
                                  $("#activity-level").text(data.Low)
                                } else if (res.data[0].z_score_desc_E4 === "VERY LOW" && data.Reference === "E4") {
                                  $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very low' + '`' + `))
                                  $("#activity-level").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E5 === "VERY HIGH" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very high' + '`' + `))
                                  $("#excitement-seeking").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E5 === "HIGH" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is high' + '`' + `))
                                  $("#excitement-seeking").text(data.High)
                                } else if (res.data[0].z_score_desc_E5 === "TYPICAL" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is typical' + '`' + `))
                                  $("#excitement-seeking").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E5 === "LOW" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is low' + '`' + `))
                                  $("#excitement-seeking").text(data.Low)
                                } else if (res.data[0].z_score_desc_E5 === "VERY LOW" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very low' + '`' + `))
                                  $("#excitement-seeking").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E6 === "VERY HIGH" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very high' + '`' + `))
                                  $("#cheerfulness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E6 === "HIGH" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is high' + '`' + `))
                                  $("#cheerfulness").text(data.High)
                                } else if (res.data[0].z_score_desc_E6 === "TYPICAL" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is typical' + '`' + `))
                                  $("#cheerfulness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E6 === "LOW" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is low' + '`' + `))
                                  $("#cheerfulness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E6 === "VERY LOW" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very low' + '`' + `))
                                  $("#cheerfulness").text(data.Very_Low)
                                }
            
                                // O
                                if (res.data[0].z_score_desc_O === "VERY HIGH" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very high.' + ' ' + '`' + `))
                                  $("#replace-openness-to-experience").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_O === "HIGH" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is high.' + ' ' + '`' + `))
                                  $("#replace-openness-to-experience").append(data.High)
                                } else if (res.data[0].z_score_desc_O === "TYPICAL" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is typical.' + ' ' + '`' + `))
                                  $("#replace-openness-to-experience").append(data.Typical)
                                } else if (res.data[0].z_score_desc_O === "LOW" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is low.' + ' ' + '`' + `))
                                  $("#replace-openness-to-experience").append(data.Low)
                                } else if (res.data[0].z_score_desc_O === "VERY LOW" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very low.' + ' ' + '`' + `))
                                  $("#replace-openness-to-experience").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O1 === "VERY HIGH" && data.Reference === "O1") {
                                  $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very high' + '`' + `))
                                  $("#imagination").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O1 === "HIGH" && data.Reference === "O1") {
                                  $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is high' + '`' + `))
                                  $("#imagination").text(data.High)
                                } else if (res.data[0].z_score_desc_O1 === "TYPICAL" && data.Reference === "O1") {
                                  $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is typical' + '`' + `))
                                  $("#imagination").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O1 === "LOW" && data.Reference === "O1") {
                                  $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is low' + '`' + `))
                                  $("#imagination").text(data.Low)
                                } else if (res.data[0].z_score_desc_O1 === "VERY LOW" && data.Reference === "O1") {
                                  $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very low' + '`' + `))
                                  $("#imagination").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O2 === "VERY HIGH" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very high' + '`' + `))
                                  $("#artistic-Interests").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O2 === "HIGH" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is high' + '`' + `))
                                  $("#artistic-Interests").text(data.High)
                                } else if (res.data[0].z_score_desc_O2 === "TYPICAL" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is typical' + '`' + `))
                                  $("#artistic-Interests").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O2 === "LOW" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is low' + '`' + `))
                                  $("#artistic-Interests").text(data.Low)
                                } else if (res.data[0].z_score_desc_O2 === "VERY LOW" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very low' + '`' + `))
                                  $("#artistic-Interests").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_O3 === "VERY HIGH" && data.Reference === "O3") {
                                  $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very high' + '`' + `))
                                  $("#emotionality").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O3 === "HIGH" && data.Reference === "O3") {
                                  $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is high' + '`' + `))
                                  $("#emotionality").text(data.High)
                                } else if (res.data[0].z_score_desc_O3 === "TYPICAL" && data.Reference === "O3") {
                                  $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is typical' + '`' + `))
                                  $("#emotionality").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O3 === "LOW" && data.Reference === "O3") {
                                  $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is low' + '`' + `))
                                  $("#emotionality").text(data.Low)
                                } else if (res.data[0].z_score_desc_O3 === "VERY LOW" && data.Reference === "O3") {
                                  $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very low' + '`' + `))
                                  $("#emotionality").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O4 === "VERY HIGH" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very high' + '`' + `))
                                  $("#adventurousness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O4 === "HIGH" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is high' + '`' + `))
                                  $("#adventurousness").text(data.High)
                                } else if (res.data[0].z_score_desc_O4 === "TYPICAL" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is typical' + '`' + `))
                                  $("#adventurousness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O4 === "LOW" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is low' + '`' + `))
                                  $("#adventurousness").text(data.Low)
                                } else if (res.data[0].z_score_desc_O4 === "VERY LOW" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very low' + '`' + `))
                                  $("#adventurousness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O5 === "VERY HIGH" && data.Reference === "O5") {
                                  $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very high' + '`' + `))
                                  $("#cognition").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O5 === "HIGH" && data.Reference === "O5") {
                                  $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is high' + '`' + `))
                                  $("#cognition").text(data.High)
                                } else if (res.data[0].z_score_desc_O5 === "TYPICAL" && data.Reference === "O5") {
                                  $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is typical' + '`' + `))
                                  $("#cognition").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O5 === "LOW" && data.Reference === "O5") {
                                  $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is low' + '`' + `))
                                  $("#cognition").text(data.Low)
                                } else if (res.data[0].z_score_desc_O5 === "VERY LOW" && data.Reference === "O5") {
                                  $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very low' + '`' + `))
                                  $("#cognition").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O6 === "VERY HIGH" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very high' + '`' + `))
                                  $("#broad-mindedness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O6 === "HIGH" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is high' + '`' + `))
                                  $("#broad-mindedness").text(data.High)
                                } else if (res.data[0].z_score_desc_O6 === "TYPICAL" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is typical' + '`' + `))
                                  $("#broad-mindedness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O6 === "LOW" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is low' + '`' + `))
                                  $("#broad-mindedness").text(data.Low)
                                } else if (res.data[0].z_score_desc_O6 === "VERY LOW" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very low' + '`' + `))
                                  $("#broad-mindedness").text(data.Very_Low)
                                }
            
                                // A
                                if (res.data[0].z_score_desc_A === "VERY HIGH" && data.Reference === "A") { 
                                  $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very high.' + ' ' + '`' + `))
                                  $("#replace-agreeableness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_A === "HIGH" && data.Reference === "A") {
                                  $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is high.' + ' ' + '`' + `))
                                  $("#replace-agreeableness").append(data.High)
                                } else if (res.data[0].z_score_desc_A === "TYPICAL" && data.Reference === "A") {
                                  $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is typical.' + ' ' + '`' + `))
                                  $("#replace-agreeableness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_A === "LOW" && data.Reference === "A") {
                                  $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is low.' + ' ' + '`' + `))
                                  $("#replace-agreeableness").append(data.Low)
                                } else if (res.data[0].z_score_desc_A === "VERY LOW" && data.Reference === "A") {
                                  $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very low.' + ' ' + '`' + `))
                                  $("#replace-agreeableness").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A1 === "VERY HIGH" && data.Reference === "A1") { 
                                  $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very high' + '`' + `))
                                  $("#trust").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A1 === "HIGH" && data.Reference === "A1") {
                                  $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is high' + '`' + `))
                                  $("#trust").text(data.High)
                                } else if (res.data[0].z_score_desc_A1 === "TYPICAL" && data.Reference === "A1") {
                                  $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is typical' + '`' + `))
                                  $("#trust").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A1 === "LOW" && data.Reference === "A1") {
                                  $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is low' + '`' + `))
                                  $("#trust").text(data.Low)
                                } else if (res.data[0].z_score_desc_A1 === "VERY LOW" && data.Reference === "A1") {
                                  $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very low' + '`' + `))
                                  $("#trust").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A2 === "VERY HIGH" && data.Reference === "A2") {
                                  $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very high' + '`' + `))
                                  $("#candor").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A2 === "HIGH" && data.Reference === "A2") {
                                  $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is high' + '`' + `))
                                  $("#candor").text(data.High)
                                } else if (res.data[0].z_score_desc_A2 === "TYPICAL" && data.Reference === "A2") {
                                  $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is typical' + '`' + `))
                                  $("#candor").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A2 === "LOW" && data.Reference === "A2") {
                                  $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is low' + '`' + `))
                                  $("#candor").text(data.Low)
                                } else if (res.data[0].z_score_desc_A2 === "VERY LOW" && data.Reference === "A2") {
                                  $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very low' + '`' + `))
                                  $("#candor").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_A3 === "VERY HIGH" && data.Reference === "A3") {
                                  $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very high' + '`' + `))
                                  $("#altruism").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A3 === "HIGH" && data.Reference === "A3") {
                                  $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is high' + '`' + `))
                                  $("#altruism").text(data.High)
                                } else if (res.data[0].z_score_desc_A3 === "TYPICAL" && data.Reference === "A3") {
                                  $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is typical' + '`' + `))
                                  $("#altruism").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A3 === "LOW" && data.Reference === "A3") {
                                  $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is low' + '`' + `))
                                  $("#altruism").text(data.Low)
                                } else if (res.data[0].z_score_desc_A3 === "VERY LOW" && data.Reference === "A3") {
                                  $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very low' + '`' + `))
                                  $("#altruism").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A4 === "VERY HIGH" && data.Reference === "A4") {
                                  $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very high' + '`' + `))
                                  $("#cooperation").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A4 === "HIGH" && data.Reference === "A4") {
                                  $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is high' + '`' + `))
                                  $("#cooperation").text(data.High)
                                } else if (res.data[0].z_score_desc_A4 === "TYPICAL" && data.Reference === "A4") {
                                  $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is typical' + '`' + `))
                                  $("#cooperation").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A4 === "LOW" && data.Reference === "A4") {
                                  $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is low' + '`' + `))
                                  $("#cooperation").text(data.Low)
                                } else if (res.data[0].z_score_desc_A4 === "VERY LOW" && data.Reference === "A4") {
                                  $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very low' + '`' + `))
                                  $("#cooperation").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A5 === "VERY HIGH" && data.Reference === "A5") {
                                  $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very high' + '`' + `))
                                  $("#modesty").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A5 === "HIGH" && data.Reference === "A5") {
                                  $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is high' + '`' + `))
                                  $("#modesty").text(data.High)
                                } else if (res.data[0].z_score_desc_A5 === "TYPICAL" && data.Reference === "A5") {
                                  $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is typical' + '`' + `))
                                  $("#modesty").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A5 === "LOW" && data.Reference === "A5") {
                                  $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is low' + '`' + `))
                                  $("#modesty").text(data.Low)
                                } else if (res.data[0].z_score_desc_A5 === "VERY LOW" && data.Reference === "A5") {
                                  $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very low' + '`' + `))
                                  $("#modesty").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A6 === "VERY HIGH" && data.Reference === "A6") {
                                  $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very high' + '`' + `))
                                  $("#sympathy").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A6 === "HIGH" && data.Reference === "A6") {
                                  $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is high' + '`' + `))
                                  $("#sympathy").text(data.High)
                                } else if (res.data[0].z_score_desc_A6 === "TYPICAL" && data.Reference === "A6") {
                                  $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is typical' + '`' + `))
                                  $("#sympathy").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A6 === "LOW" && data.Reference === "A6") {
                                  $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is low' + '`' + `))
                                  $("#sympathy").text(data.Low)
                                } else if (res.data[0].z_score_desc_A6 === "VERY LOW" && data.Reference === "A6") {
                                  $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very low' + '`' + `))
                                  $("#sympathy").text(data.Very_Low)
                                }
            
                                // C
                                if (res.data[0].z_score_desc_C === "VERY HIGH" && data.Reference === "C") { 
                                  $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very high.' + ' ' + '`' + `))
                                  $("#replace-conscientiousness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_C === "HIGH" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is high.' + ' ' + '`' + `))
                                  $("#replace-conscientiousness").append(data.High)
                                } else if (res.data[0].z_score_desc_C === "TYPICAL" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is typical.' + ' ' + '`' + `))
                                  $("#replace-conscientiousness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_C === "LOW" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is low.' + ' ' + '`' + `))
                                  $("#replace-conscientiousness").append(data.Low)
                                } else if (res.data[0].z_score_desc_C === "VERY LOW" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very low.' + ' ' + '`' + `))
                                  $("#replace-conscientiousness").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C1 === "VERY HIGH" && data.Reference === "C1") { 
                                  $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very high' + '`' + `))
                                  $("#self-belief").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C1 === "HIGH" && data.Reference === "C1") {
                                  $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is high' + '`' + `))
                                  $("#self-belief").text(data.High)
                                } else if (res.data[0].z_score_desc_C1 === "TYPICAL" && data.Reference === "C1") {
                                  $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is typical' + '`' + `))
                                  $("#self-belief").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C1 === "LOW" && data.Reference === "C1") {
                                  $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is low' + '`' + `))
                                  $("#self-belief").text(data.Low)
                                } else if (res.data[0].z_score_desc_C1 === "VERY LOW" && data.Reference === "C1") {
                                  $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very low' + '`' + `))
                                  $("#self-belief").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C2 === "VERY HIGH" && data.Reference === "C2") {
                                  $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very high' + '`' + `))
                                  $("#orderliness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C2 === "HIGH" && data.Reference === "C2") {
                                  $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is high' + '`' + `))
                                  $("#orderliness").text(data.High)
                                } else if (res.data[0].z_score_desc_C2 === "TYPICAL" && data.Reference === "C2") {
                                  $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is typical' + '`' + `))
                                  $("#orderliness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C2 === "LOW" && data.Reference === "C2") {
                                  $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is low' + '`' + `))
                                  $("#orderliness").text(data.Low)
                                } else if (res.data[0].z_score_desc_C2 === "VERY LOW" && data.Reference === "C2") {
                                  $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very low' + '`' + `))
                                  $("#orderliness").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_C3 === "VERY HIGH" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very high' + '`' + `))
                                  $("#dutifulness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C3 === "HIGH" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is high' + '`' + `))
                                  $("#dutifulness").text(data.High)
                                } else if (res.data[0].z_score_desc_C3 === "TYPICAL" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is typical' + '`' + `))
                                  $("#dutifulness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C3 === "LOW" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is low' + '`' + `))
                                  $("#dutifulness").text(data.Low)
                                } else if (res.data[0].z_score_desc_C3 === "VERY LOW" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very low' + '`' + `))
                                  $("#dutifulness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C4 === "VERY HIGH" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very high' + '`' + `))
                                  $("#achievement-striving").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C4 === "HIGH" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is high' + '`' + `))
                                  $("#achievement-striving").text(data.High)
                                } else if (res.data[0].z_score_desc_C4 === "TYPICAL" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is typical' + '`' + `))
                                  $("#achievement-striving").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C4 === "LOW" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is low' + '`' + `))
                                  $("#achievement-striving").text(data.Low)
                                } else if (res.data[0].z_score_desc_C4 === "VERY LOW" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very low' + '`' + `))
                                  $("#achievement-striving").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C5 === "VERY HIGH" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very high' + '`' + `))
                                  $("#self-discipline").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C5 === "HIGH" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is high' + '`' + `))
                                  $("#self-discipline").text(data.High)
                                } else if (res.data[0].z_score_desc_C5 === "TYPICAL" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is typical' + '`' + `))
                                  $("#self-discipline").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C5 === "LOW" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is low' + '`' + `))
                                  $("#self-discipline").text(data.Low)
                                } else if (res.data[0].z_score_desc_C5 === "VERY LOW" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very low' + '`' + `))
                                  $("#self-discipline").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C6 === "VERY HIGH" && data.Reference === "C6") {
                                  $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very high' + '`' + `))
                                  $("#planning").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C6 === "HIGH" && data.Reference === "C6") {
                                  $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is high' + '`' + `))
                                  $("#planning").text(data.High)
                                } else if (res.data[0].z_score_desc_C6 === "TYPICAL" && data.Reference === "C6") {
                                  $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is typical' + '`' + `))
                                  $("#planning").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C6 === "LOW" && data.Reference === "C6") {
                                  $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is low' + '`' + `))
                                  $("#planning").text(data.Low)
                                } else if (res.data[0].z_score_desc_C6 === "VERY LOW" && data.Reference === "C6") {
                                  $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very low' + '`' + `))
                                  $("#planning").text(data.Very_Low)
                                }
                              }


                            `
    
                            // Script for radar-chart
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i];
                              if (data.Template === 'Radar-Chart') { 
                                let obj = JSON.parse(data.Text)
                                for (const res of obj) {
                                  
                                  output += `
                                  let `+ res.SuperTrait + `Select = document.querySelectorAll('.` + res.SuperTrait + `')
                                  `+ res.SuperTrait + `_Chart = new Chart(`+res.SuperTrait+`Select, {
                                    type: 'radar',
                                    data: {
                                      labels: [
                                  `
                                      res.Elements.forEach((response) => {
                                        output += `
                                          "`+response.Description+`",
                                        `
                                      })
                                  output += `
              
                                      ],
                                      datasets: [
                                        {
                                          label: '`+ res.label1 +`',
                                          data: [
                                        `
                                        res.Elements.forEach((response) => {
                                          output += `
                                          Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1),
                                          `
                                        })
                                        output+=`  
                                          ],
                                          fill: true,
                                          backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                          borderColor: 'rgb(255, 99, 132)',
                                          pointBackgroundColor: 'rgb(255, 99, 132)',
                                          pointBorderColor: '#fff',
                                          pointHoverBackgroundColor: '#fff',
                                          pointHoverBorderColor: 'rgb(255, 99, 132)',
                                        },
                                        {
                                          label: '`+res.label2+`',
                                          data: [
                                          `
                                          res.Elements.forEach((response) => {
                                          output += `
                                          Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                                          `
                                          })
                                          output +=`
                                          ],
                                          fill: true,
                                          backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                          borderColor: 'rgb(54, 162, 235)',
                                          pointBackgroundColor: 'rgb(54, 162, 235)',
                                          pointBorderColor: '#fff',
                                          pointHoverBackgroundColor: '#fff',
                                          pointHoverBorderColor: 'rgb(54, 162, 235)',
                                        },
                                      ],
                                    },
                                    options: {
                                      scales: {
                                        r: {
                                          beginAtZero: true,
                                          min: 0,
                                          max: 5,
                                          ticks: {
                                          stepSize: 1,
                                          }
                                        },
                                      }
                                    }
                                  })
                                  `
                                }
                              }
                            }
    
                            // Script for Bar-Chart
                            for (let i = 0; i < input.length; i++) {
                              const res = input[i]
                              if (res.Template === 'Bar-Chart') { 
                                let obj = JSON.parse(res.Text)
                                for (const res of obj) {
                                  res.Elements.forEach((response) => {
                                    output += `
                                      $(".percentile_` + response.Trait + `").html(ordinal(Math.round(getRanking(res.data[0].percentile_` + response.Trait + `))))
                                      $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                      $(".cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1))
                                      $(".percentile-` + response.Trait + `").css({ "flex-grow": ` + '`' + '${res.data[0].percentile_' + ``+response.Trait+``+ '}' + '`' + `, "background-color": "#`+ response.Color +`"})
                                      $(".percentile-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - ${res.data[0].percentile_' + ``+response.Trait+``+ '})' + '`' + `})
                                    `
                                  })
                                }
                              }
                            } 
    
    
                            output += `
    
                          
                        })
                        .catch((error) => {
                          console.log(error)
                          if(error.code == "ERR_NETWORK") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>Network Error, Please try again later!</p>',
                              customClass: 'norm-wording'
                            })
                          }
                        })
                    })
    
                    $('#adjustNormBtn').click(function() {
                      $("#showAdjustForm").removeClass('d-none')
                      $("#showAdjustBtn").addClass('d-none')
                    })
                    $(document).on('click', '#adjustNormBtnCancel', function() {
                      $("#showAdjustBtn").removeClass('d-none')
                      $("#showAdjustForm").addClass('d-none')
                    })
    
                    $('#adjustNormBtnSubmit').click(function (){
                      let isChecked = $('#compare_results').is(':checked')
                      let selectedGender = $('#norms_gender').val()
                      let selectedCountry = $('#norms_country').val()
                      let source = "IPIP120"
      
                      if(isChecked) {
                        selectedCountry = ""
                        source = "GLOBAL"
                      }
                      else {
                        selectedCountry = $('#norms_country').val()
                      }

                      if(printPDFonlyAndNoData) {
                        console.log('global dapat')
                        selectedCountry = ""
                        source = "GLOBAL"
                      }
                      else {
                        selectedCountry = $('#norms_country').val()
                      }
      
                      $("#showAdjustBtn").removeClass('d-none')
                      $("#showAdjustForm").addClass('d-none')
    
                      $.getJSON('calculation.json', (jsonData) => {
                        axios
                          .post(
                            '${url}/b5-report-data2',
                            {
                              survey_assignment_id: survey_assignment_id,
                              sex: selectedGender,
                              country: selectedCountry,
                              source: source
                            },
                            config
                          )
                          .then((res) => {
                            console.log(res.data)
                            // catch error if no sex and country in b5_norm_sum ex. if the sex is trans 
                            if(res.data.status == "noData") {
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                                customClass: 'norm-wording'
                              })
                            }
                            // catch error if there are sex and country in b5_norm_sum but there is no data in other column like er1_mean column
                            if(res.data.status == "noNormingData") {
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                                customClass: 'norm-wording'
                              })
                            }

                            // catch error if no data with this survey_assignment_id or something went wrong
                            if(res.data.status == "noSurveyData") {
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>That didn’t go to plan. Please email your own name and email address to help@talentsage.com. We’ll review it and let you know when it’s fine.</p>',
                                customClass: 'norm-wording'
                              })
                            }

                            if(res.data[0].n <= 30){
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                                customClass: 'norm-wording'
                              })
                            }


                            
                            if(printPDFonlyAndNoData) {
                              country = 'Global'
                              selectedCountry = 'Global'
                              console.log('country', country)
                            }

                            $('#gender_label').text(selectedGender)
                            $('#culture_label').text(selectedCountry)
                            $(".gender-icon").attr("src",` + '`' + 'gender/${selectedGender}.svg' + '`)' + `
                            $(".country-flag").attr("src",` + '`' + 'flags/4x3/${selectedCountry}.svg' + '`)' + `
                            
                            `
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i]
                              if (data.Template === 'Comparison-Chart-Task-vs-Relationship') { 
                                output += `
                                let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                                let meanTvsR = totalTvsR / 3
                                if(meanTvsR <= 25) {
                                  $('.task-vs-relationship-dynamic-title').text("Tasks much more than Relationships")
                                  $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/20.jpg")
                                }
                                else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                  $('.task-vs-relationship-dynamic-title').text("Tasks more than Relationships")
                                  $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/40.jpg")
                                }
                                else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                  $('.task-vs-relationship-dynamic-title').text("Tasks and Relationships equally")
                                  $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/60.jpg")
                                }
                                else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                  $('.task-vs-relationship-dynamic-title').text("Relationships more than Tasks")
                                  $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/80.jpg")
                                }
                                else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                  $('.task-vs-relationship-dynamic-title').text("Relationships much more than Tasks")
                                  $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/100.jpg")
                                }
                                `
                                break
                              }
                            }
    
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i]
                              if (data.Template === 'Comparison-Chart-Team-vs-Individual') { 
                                output += `
                                let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                                let meanTeamvsIndividual = totalTeamvsIndividual / 3
                                if(meanTeamvsIndividual <= 25) {
                                  $('.team-vs-individual-dynamic-title').text("Autonomy much more than Structure")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/100.jpg")
                                }
                                else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                  $('.team-vs-individual-dynamic-title').text("Autonomy more than Structure")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/80.jpg")
                                }
                                else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                  $('.team-vs-individual-dynamic-title').text("Structure and Autonomy equally")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/60.jpg")
                                }
                                else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                  $('.team-vs-individual-dynamic-title').text("Structure more than Autonomy")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/40.jpg")
                                }
                                else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                  $('.team-vs-individual-dynamic-title').text("Structure much more than Autonomy")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/20.jpg")
                                }
                                `
                                break
                              }
                            }
    
                            output +=`
                              const ordinal = (number) => {
                                const ordinalRules = new Intl.PluralRules("en", {
                                  type: "ordinal"
                                });
                                const suffixes = {
                                  one: "st",
                                  two: "nd",
                                  few: "rd",
                                  other: "th"
                                };
                                const suffix = suffixes[ordinalRules.select(number)];
                                return (number + suffix);
                              }
          
                              function getRanking (rank) {
                                if (rank <= 5){
                                  return 5
                                } else if (rank >= 95) {
                                  return 95
                                } else {
                                  return rank
                                }
                              }

                              for (const data of jsonData) {      
                                // ER
                                if (res.data[0].z_score_desc_ER === "VERY HIGH" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very high.' + ' ' + '`' + `)
                                  $("#replace-emotional-reactiveness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER === "HIGH" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is high.' + ' ' + '`' + `)
                                  $("#replace-emotional-reactiveness").append(data.High)
                                } else if (res.data[0].z_score_desc_ER === "TYPICAL" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is typical.' + ' ' + '`' + `)
                                  $("#replace-emotional-reactiveness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_ER === "LOW" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is low.' + ' ' + '`' + `)
                                  $("#replace-emotional-reactiveness").append(data.Low)
                                } else if (res.data[0].z_score_desc_ER === "VERY LOW" && data.Reference === "ER") {
                                  $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very low.' + ' ' + '`' + `)
                                  $("#replace-emotional-reactiveness").append(data.Very_low)
                                } 
            
                                if (res.data[0].z_score_desc_ER1 === "VERY HIGH" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very high' + '`' + `)
                                  $("#anxiety").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER1 === "HIGH" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is high' + '`' + `)
                                  $("#anxiety").text(data.High)
                                } else if (res.data[0].z_score_desc_ER1 === "TYPICAL" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is typical' + '`' + `)
                                  $("#anxiety").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER1 === "LOW" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is low' + '`' + `)
                                  $("#anxiety").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER1 === "VERY LOW" && data.Reference === "ER1") {
                                  $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very low' + '`' + `)
                                  $("#anxiety").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER2 === "VERY HIGH" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very high' + '`' + `)
                                  $("#anger").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER2 === "HIGH" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is high' + '`' + `)
                                  $("#anger").text(data.High)
                                } else if (res.data[0].z_score_desc_ER2 === "TYPICAL" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is typical' + '`' + `)
                                  $("#anger").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER2 === "LOW" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is low' + '`' + `)
                                  $("#anger").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER2 === "VERY LOW" && data.Reference === "ER2") {
                                  $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very low' + '`' + `)
                                  $("#anger").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_ER3 === "VERY HIGH" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very high' + '`' + `)
                                  $("#sadness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER3 === "HIGH" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is high' + '`' + `)
                                  $("#sadness").text(data.High)
                                } else if (res.data[0].z_score_desc_ER3 === "TYPICAL" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is typical' + '`' + `)
                                  $("#sadness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER3 === "LOW" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is low' + '`' + `)
                                  $("#sadness").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER3 === "VERY LOW" && data.Reference === "ER3") {
                                  $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very low' + '`' + `)
                                  $("#sadness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER4 === "VERY HIGH" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very high' + '`' + `)
                                  $("#self-consciousness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER4 === "HIGH" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is high' + '`' + `)
                                  $("#self-consciousness").text(data.High)
                                } else if (res.data[0].z_score_desc_ER4 === "TYPICAL" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is typical' + '`' + `)
                                  $("#self-consciousness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER4 === "LOW" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is low' + '`' + `)
                                  $("#self-consciousness").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER4 === "VERY LOW" && data.Reference === "ER4") {
                                  $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very low' + '`' + `)
                                  $("#self-consciousness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER5 === "VERY HIGH" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very high' + '`' + `)
                                  $("#self-indulgence").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER5 === "HIGH" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is high' + '`' + `)
                                  $("#self-indulgence").text(data.High)
                                } else if (res.data[0].z_score_desc_ER5 === "TYPICAL" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is typical' + '`' + `)
                                  $("#self-indulgence").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER5 === "LOW" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is low' + '`' + `)
                                  $("#self-indulgence").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER5 === "VERY LOW" && data.Reference === "ER5") {
                                  $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very low' + '`' + `)
                                  $("#self-indulgence").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_ER6 === "VERY HIGH" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very high' + '`' + `)
                                  $("#vulnerability").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_ER6 === "HIGH" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is high' + '`' + `)
                                  $("#vulnerability").text(data.High)
                                } else if (res.data[0].z_score_desc_ER6 === "TYPICAL" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is typical' + '`' + `)
                                  $("#vulnerability").text(data.Typical)
                                } else if (res.data[0].z_score_desc_ER6 === "LOW" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is low' + '`' + `)
                                  $("#vulnerability").text(data.Low)
                                } else if (res.data[0].z_score_desc_ER6 === "VERY LOW" && data.Reference === "ER6") {
                                  $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very low' + '`' + `)
                                  $("#vulnerability").text(data.Very_Low)
                                }
            
                                // E
                                if (res.data[0].z_score_desc_E === "VERY HIGH" && data.Reference === "E") { 
                                  $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very high.' + ' ' + '`' + `)
                                  $("#replace-extraversion").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_E === "HIGH" && data.Reference === "E") {
                                  $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is high.' + ' ' + '`' + `)
                                  $("#replace-extraversion").append(data.High)
                                } else if (res.data[0].z_score_desc_E === "TYPICAL" && data.Reference === "E") {
                                  $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is typical.' + ' ' + '`' + `)
                                  $("#replace-extraversion").append(data.Typical)
                                } else if (res.data[0].z_score_desc_E === "LOW" && data.Reference === "E") {
                                  $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is low.' + ' ' + '`' + `)
                                  $("#replace-extraversion").append(data.Low)
                                } else if (res.data[0].z_score_desc_E === "VERY LOW" && data.Reference === "E") {
                                  $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very low.' + ' ' + '`' + `)
                                  $("#replace-extraversion").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E1 === "VERY HIGH" && data.Reference === "E1") { 
                                  $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very high' + '`' + `)
                                  $("#friendliness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E1 === "HIGH" && data.Reference === "E1") {
                                  $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is high' + '`' + `)
                                  $("#friendliness").text(data.High)
                                } else if (res.data[0].z_score_desc_E1 === "TYPICAL" && data.Reference === "E1") {
                                  $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is typical' + '`' + `)
                                  $("#friendliness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E1 === "LOW" && data.Reference === "E1") {
                                  $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is low' + '`' + `)
                                  $("#friendliness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E1 === "VERY LOW" && data.Reference === "E1") {
                                  $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very low' + '`' + `)
                                  $("#friendliness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E2 === "VERY HIGH" && data.Reference === "E2") {
                                  $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very high' + '`' + `)
                                  $("#sociability").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E2 === "HIGH" && data.Reference === "E2") {
                                  $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is high' + '`' + `)
                                  $("#sociability").text(data.High)
                                } else if (res.data[0].z_score_desc_E2 === "TYPICAL" && data.Reference === "E2") {
                                  $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is typical' + '`' + `)
                                  $("#sociability").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E2 === "LOW" && data.Reference === "E2") {
                                  $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is low' + '`' + `)
                                  $("#sociability").text(data.Low)
                                } else if (res.data[0].z_score_desc_E2 === "VERY LOW" && data.Reference === "E2") {
                                  $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very low' + '`' + `)
                                  $("#sociability").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_E3 === "VERY HIGH" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very high' + '`' + `)
                                  $("#assertiveness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E3 === "HIGH" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is high' + '`' + `)
                                  $("#assertiveness").text(data.High)
                                } else if (res.data[0].z_score_desc_E3 === "TYPICAL" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is typical' + '`' + `)
                                  $("#assertiveness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E3 === "LOW" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is low' + '`' + `)
                                  $("#assertiveness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E3 === "VERY LOW" && data.Reference === "E3") {
                                  $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very low' + '`' + `)
                                  $("#assertiveness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E4 === "VERY HIGH" && data.Reference === "E4") {
                                  $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very high' + '`' + `)
                                  $("#activity-level").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E4 === "HIGH" && data.Reference === "E4") {
                                  $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is high' + '`' + `)
                                  $("#activity-level").text(data.High)
                                } else if (res.data[0].z_score_desc_E4 === "TYPICAL" && data.Reference === "E4") {
                                  $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is typical' + '`' + `)
                                  $("#activity-level").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E4 === "LOW" && data.Reference === "E4") {
                                  $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is low' + '`' + `)
                                  $("#activity-level").text(data.Low)
                                } else if (res.data[0].z_score_desc_E4 === "VERY LOW" && data.Reference === "E4") {
                                  $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very low' + '`' + `)
                                  $("#activity-level").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E5 === "VERY HIGH" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very high' + '`' + `)
                                  $("#excitement-seeking").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E5 === "HIGH" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is high' + '`' + `)
                                  $("#excitement-seeking").text(data.High)
                                } else if (res.data[0].z_score_desc_E5 === "TYPICAL" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is typical' + '`' + `)
                                  $("#excitement-seeking").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E5 === "LOW" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is low' + '`' + `)
                                  $("#excitement-seeking").text(data.Low)
                                } else if (res.data[0].z_score_desc_E5 === "VERY LOW" && data.Reference === "E5") {
                                  $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very low' + '`' + `)
                                  $("#excitement-seeking").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_E6 === "VERY HIGH" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very high' + '`' + `)
                                  $("#cheerfulness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_E6 === "HIGH" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is high' + '`' + `)
                                  $("#cheerfulness").text(data.High)
                                } else if (res.data[0].z_score_desc_E6 === "TYPICAL" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is typical' + '`' + `)
                                  $("#cheerfulness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_E6 === "LOW" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is low' + '`' + `)
                                  $("#cheerfulness").text(data.Low)
                                } else if (res.data[0].z_score_desc_E6 === "VERY LOW" && data.Reference === "E6") {
                                  $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very low' + '`' + `)
                                  $("#cheerfulness").text(data.Very_Low)
                                }
            
                                // O
                                if (res.data[0].z_score_desc_O === "VERY HIGH" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very high.' + ' ' + '`' + `)
                                  $("#replace-openness-to-experience").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_O === "HIGH" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is high.' + ' ' + '`' + `)
                                  $("#replace-openness-to-experience").append(data.High)
                                } else if (res.data[0].z_score_desc_O === "TYPICAL" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is typical.' + ' ' + '`' + `)
                                  $("#replace-openness-to-experience").append(data.Typical)
                                } else if (res.data[0].z_score_desc_O === "LOW" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is low.' + ' ' + '`' + `)
                                  $("#replace-openness-to-experience").append(data.Low)
                                } else if (res.data[0].z_score_desc_O === "VERY LOW" && data.Reference === "O") {
                                  $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very low.' + ' ' + '`' + `)
                                  $("#replace-openness-to-experience").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O1 === "VERY HIGH" && data.Reference === "O1") {
                                  $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very high' + '`' + `)
                                  $("#imagination").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O1 === "HIGH" && data.Reference === "O1") {
                                  $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is high' + '`' + `)
                                  $("#imagination").text(data.High)
                                } else if (res.data[0].z_score_desc_O1 === "TYPICAL" && data.Reference === "O1") {
                                  $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is typical' + '`' + `)
                                  $("#imagination").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O1 === "LOW" && data.Reference === "O1") {
                                  $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is low' + '`' + `)
                                  $("#imagination").text(data.Low)
                                } else if (res.data[0].z_score_desc_O1 === "VERY LOW" && data.Reference === "O1") {
                                  $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very low' + '`' + `)
                                  $("#imagination").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O2 === "VERY HIGH" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very high' + '`' + `)
                                  $("#artistic-Interests").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O2 === "HIGH" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is high' + '`' + `)
                                  $("#artistic-Interests").text(data.High)
                                } else if (res.data[0].z_score_desc_O2 === "TYPICAL" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is typical' + '`' + `)
                                  $("#artistic-Interests").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O2 === "LOW" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is low' + '`' + `)
                                  $("#artistic-Interests").text(data.Low)
                                } else if (res.data[0].z_score_desc_O2 === "VERY LOW" && data.Reference === "O2") {
                                  $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very low' + '`' + `)
                                  $("#artistic-Interests").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_O3 === "VERY HIGH" && data.Reference === "O3") {
                                  $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very high' + '`' + `)
                                  $("#emotionality").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O3 === "HIGH" && data.Reference === "O3") {
                                  $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is high' + '`' + `)
                                  $("#emotionality").text(data.High)
                                } else if (res.data[0].z_score_desc_O3 === "TYPICAL" && data.Reference === "O3") {
                                  $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is typical' + '`' + `)
                                  $("#emotionality").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O3 === "LOW" && data.Reference === "O3") {
                                  $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is low' + '`' + `)
                                  $("#emotionality").text(data.Low)
                                } else if (res.data[0].z_score_desc_O3 === "VERY LOW" && data.Reference === "O3") {
                                  $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very low' + '`' + `)
                                  $("#emotionality").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O4 === "VERY HIGH" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very high' + '`' + `)
                                  $("#adventurousness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O4 === "HIGH" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is high' + '`' + `)
                                  $("#adventurousness").text(data.High)
                                } else if (res.data[0].z_score_desc_O4 === "TYPICAL" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is typical' + '`' + `)
                                  $("#adventurousness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O4 === "LOW" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is low' + '`' + `)
                                  $("#adventurousness").text(data.Low)
                                } else if (res.data[0].z_score_desc_O4 === "VERY LOW" && data.Reference === "O4") {
                                  $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very low' + '`' + `)
                                  $("#adventurousness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O5 === "VERY HIGH" && data.Reference === "O5") {
                                  $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very high' + '`' + `)
                                  $("#cognition").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O5 === "HIGH" && data.Reference === "O5") {
                                  $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is high' + '`' + `)
                                  $("#cognition").text(data.High)
                                } else if (res.data[0].z_score_desc_O5 === "TYPICAL" && data.Reference === "O5") {
                                  $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is typical' + '`' + `)
                                  $("#cognition").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O5 === "LOW" && data.Reference === "O5") {
                                  $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is low' + '`' + `)
                                  $("#cognition").text(data.Low)
                                } else if (res.data[0].z_score_desc_O5 === "VERY LOW" && data.Reference === "O5") {
                                  $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very low' + '`' + `)
                                  $("#cognition").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_O6 === "VERY HIGH" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very high' + '`' + `)
                                  $("#broad-mindedness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_O6 === "HIGH" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is high' + '`' + `)
                                  $("#broad-mindedness").text(data.High)
                                } else if (res.data[0].z_score_desc_O6 === "TYPICAL" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is typical' + '`' + `)
                                  $("#broad-mindedness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_O6 === "LOW" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is low' + '`' + `)
                                  $("#broad-mindedness").text(data.Low)
                                } else if (res.data[0].z_score_desc_O6 === "VERY LOW" && data.Reference === "O6") {
                                  $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very low' + '`' + `)
                                  $("#broad-mindedness").text(data.Very_Low)
                                }
            
                                // A
                                if (res.data[0].z_score_desc_A === "VERY HIGH" && data.Reference === "A") { 
                                  $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very high.' + ' ' + '`' + `)
                                  $("#replace-agreeableness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_A === "HIGH" && data.Reference === "A") {
                                  $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is high.' + ' ' + '`' + `)
                                  $("#replace-agreeableness").append(data.High)
                                } else if (res.data[0].z_score_desc_A === "TYPICAL" && data.Reference === "A") {
                                  $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is typical.' + ' ' + '`' + `)
                                  $("#replace-agreeableness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_A === "LOW" && data.Reference === "A") {
                                  $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is low.' + ' ' + '`' + `)
                                  $("#replace-agreeableness").append(data.Low)
                                } else if (res.data[0].z_score_desc_A === "VERY LOW" && data.Reference === "A") {
                                  $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very low.' + ' ' + '`' + `)
                                  $("#replace-agreeableness").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A1 === "VERY HIGH" && data.Reference === "A1") { 
                                  $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very high' + '`' + `)
                                  $("#trust").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A1 === "HIGH" && data.Reference === "A1") {
                                  $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is high' + '`' + `)
                                  $("#trust").text(data.High)
                                } else if (res.data[0].z_score_desc_A1 === "TYPICAL" && data.Reference === "A1") {
                                  $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is typical' + '`' + `)
                                  $("#trust").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A1 === "LOW" && data.Reference === "A1") {
                                  $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is low' + '`' + `)
                                  $("#trust").text(data.Low)
                                } else if (res.data[0].z_score_desc_A1 === "VERY LOW" && data.Reference === "A1") {
                                  $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very low' + '`' + `)
                                  $("#trust").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A2 === "VERY HIGH" && data.Reference === "A2") {
                                  $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very high' + '`' + `)
                                  $("#candor").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A2 === "HIGH" && data.Reference === "A2") {
                                  $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is high' + '`' + `)
                                  $("#candor").text(data.High)
                                } else if (res.data[0].z_score_desc_A2 === "TYPICAL" && data.Reference === "A2") {
                                  $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is typical' + '`' + `)
                                  $("#candor").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A2 === "LOW" && data.Reference === "A2") {
                                  $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is low' + '`' + `)
                                  $("#candor").text(data.Low)
                                } else if (res.data[0].z_score_desc_A2 === "VERY LOW" && data.Reference === "A2") {
                                  $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very low' + '`' + `)
                                  $("#candor").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_A3 === "VERY HIGH" && data.Reference === "A3") {
                                  $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very high' + '`' + `)
                                  $("#altruism").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A3 === "HIGH" && data.Reference === "A3") {
                                  $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is high' + '`' + `)
                                  $("#altruism").text(data.High)
                                } else if (res.data[0].z_score_desc_A3 === "TYPICAL" && data.Reference === "A3") {
                                  $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is typical' + '`' + `)
                                  $("#altruism").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A3 === "LOW" && data.Reference === "A3") {
                                  $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is low' + '`' + `)
                                  $("#altruism").text(data.Low)
                                } else if (res.data[0].z_score_desc_A3 === "VERY LOW" && data.Reference === "A3") {
                                  $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very low' + '`' + `)
                                  $("#altruism").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A4 === "VERY HIGH" && data.Reference === "A4") {
                                  $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very high' + '`' + `)
                                  $("#cooperation").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A4 === "HIGH" && data.Reference === "A4") {
                                  $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is high' + '`' + `)
                                  $("#cooperation").text(data.High)
                                } else if (res.data[0].z_score_desc_A4 === "TYPICAL" && data.Reference === "A4") {
                                  $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is typical' + '`' + `)
                                  $("#cooperation").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A4 === "LOW" && data.Reference === "A4") {
                                  $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is low' + '`' + `)
                                  $("#cooperation").text(data.Low)
                                } else if (res.data[0].z_score_desc_A4 === "VERY LOW" && data.Reference === "A4") {
                                  $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very low' + '`' + `)
                                  $("#cooperation").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A5 === "VERY HIGH" && data.Reference === "A5") {
                                  $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very high' + '`' + `)
                                  $("#modesty").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A5 === "HIGH" && data.Reference === "A5") {
                                  $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is high' + '`' + `)
                                  $("#modesty").text(data.High)
                                } else if (res.data[0].z_score_desc_A5 === "TYPICAL" && data.Reference === "A5") {
                                  $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is typical' + '`' + `)
                                  $("#modesty").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A5 === "LOW" && data.Reference === "A5") {
                                  $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is low' + '`' + `)
                                  $("#modesty").text(data.Low)
                                } else if (res.data[0].z_score_desc_A5 === "VERY LOW" && data.Reference === "A5") {
                                  $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very low' + '`' + `)
                                  $("#modesty").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_A6 === "VERY HIGH" && data.Reference === "A6") {
                                  $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very high' + '`' + `)
                                  $("#sympathy").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_A6 === "HIGH" && data.Reference === "A6") {
                                  $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is high' + '`' + `)
                                  $("#sympathy").text(data.High)
                                } else if (res.data[0].z_score_desc_A6 === "TYPICAL" && data.Reference === "A6") {
                                  $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is typical' + '`' + `)
                                  $("#sympathy").text(data.Typical)
                                } else if (res.data[0].z_score_desc_A6 === "LOW" && data.Reference === "A6") {
                                  $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is low' + '`' + `)
                                  $("#sympathy").text(data.Low)
                                } else if (res.data[0].z_score_desc_A6 === "VERY LOW" && data.Reference === "A6") {
                                  $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very low' + '`' + `)
                                  $("#sympathy").text(data.Very_Low)
                                }
            
                                // C
                                if (res.data[0].z_score_desc_C === "VERY HIGH" && data.Reference === "C") { 
                                  $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very high.' + ' ' + '`' + `)
                                  $("#replace-conscientiousness").append(data.Very_High)
                                } else if (res.data[0].z_score_desc_C === "HIGH" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is high.' + ' ' + '`' + `)
                                  $("#replace-conscientiousness").append(data.High)
                                } else if (res.data[0].z_score_desc_C === "TYPICAL" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is typical.' + ' ' + '`' + `)
                                  $("#replace-conscientiousness").append(data.Typical)
                                } else if (res.data[0].z_score_desc_C === "LOW" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is low.' + ' ' + '`' + `)
                                  $("#replace-conscientiousness").append(data.Low)
                                } else if (res.data[0].z_score_desc_C === "VERY LOW" && data.Reference === "C") {
                                  $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very low.' + ' ' + '`' + `)
                                  $("#replace-conscientiousness").append(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C1 === "VERY HIGH" && data.Reference === "C1") { 
                                  $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very high' + '`' + `)
                                  $("#self-belief").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C1 === "HIGH" && data.Reference === "C1") {
                                  $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is high' + '`' + `)
                                  $("#self-belief").text(data.High)
                                } else if (res.data[0].z_score_desc_C1 === "TYPICAL" && data.Reference === "C1") {
                                  $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is typical' + '`' + `)
                                  $("#self-belief").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C1 === "LOW" && data.Reference === "C1") {
                                  $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is low' + '`' + `)
                                  $("#self-belief").text(data.Low)
                                } else if (res.data[0].z_score_desc_C1 === "VERY LOW" && data.Reference === "C1") {
                                  $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very low' + '`' + `)
                                  $("#self-belief").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C2 === "VERY HIGH" && data.Reference === "C2") {
                                  $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very high' + '`' + `)
                                  $("#orderliness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C2 === "HIGH" && data.Reference === "C2") {
                                  $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is high' + '`' + `)
                                  $("#orderliness").text(data.High)
                                } else if (res.data[0].z_score_desc_C2 === "TYPICAL" && data.Reference === "C2") {
                                  $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is typical' + '`' + `)
                                  $("#orderliness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C2 === "LOW" && data.Reference === "C2") {
                                  $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is low' + '`' + `)
                                  $("#orderliness").text(data.Low)
                                } else if (res.data[0].z_score_desc_C2 === "VERY LOW" && data.Reference === "C2") {
                                  $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very low' + '`' + `)
                                  $("#orderliness").text(data.Very_Low)
                                }
                                
                                if (res.data[0].z_score_desc_C3 === "VERY HIGH" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very high' + '`' + `)
                                  $("#dutifulness").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C3 === "HIGH" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is high' + '`' + `)
                                  $("#dutifulness").text(data.High)
                                } else if (res.data[0].z_score_desc_C3 === "TYPICAL" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is typical' + '`' + `)
                                  $("#dutifulness").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C3 === "LOW" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is low' + '`' + `)
                                  $("#dutifulness").text(data.Low)
                                } else if (res.data[0].z_score_desc_C3 === "VERY LOW" && data.Reference === "C3") {
                                  $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very low' + '`' + `)
                                  $("#dutifulness").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C4 === "VERY HIGH" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very high' + '`' + `)
                                  $("#achievement-striving").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C4 === "HIGH" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is high' + '`' + `)
                                  $("#achievement-striving").text(data.High)
                                } else if (res.data[0].z_score_desc_C4 === "TYPICAL" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is typical' + '`' + `)
                                  $("#achievement-striving").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C4 === "LOW" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is low' + '`' + `)
                                  $("#achievement-striving").text(data.Low)
                                } else if (res.data[0].z_score_desc_C4 === "VERY LOW" && data.Reference === "C4") {
                                  $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very low' + '`' + `)
                                  $("#achievement-striving").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C5 === "VERY HIGH" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very high' + '`' + `)
                                  $("#self-discipline").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C5 === "HIGH" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is high' + '`' + `)
                                  $("#self-discipline").text(data.High)
                                } else if (res.data[0].z_score_desc_C5 === "TYPICAL" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is typical' + '`' + `)
                                  $("#self-discipline").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C5 === "LOW" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is low' + '`' + `)
                                  $("#self-discipline").text(data.Low)
                                } else if (res.data[0].z_score_desc_C5 === "VERY LOW" && data.Reference === "C5") {
                                  $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very low' + '`' + `)
                                  $("#self-discipline").text(data.Very_Low)
                                }
            
                                if (res.data[0].z_score_desc_C6 === "VERY HIGH" && data.Reference === "C6") {
                                  $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very high' + '`' + `)
                                  $("#planning").text(data.Very_High)
                                } else if (res.data[0].z_score_desc_C6 === "HIGH" && data.Reference === "C6") {
                                  $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is high' + '`' + `)
                                  $("#planning").text(data.High)
                                } else if (res.data[0].z_score_desc_C6 === "TYPICAL" && data.Reference === "C6") {
                                  $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is typical' + '`' + `)
                                  $("#planning").text(data.Typical)
                                } else if (res.data[0].z_score_desc_C6 === "LOW" && data.Reference === "C6") {
                                  $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is low' + '`' + `)
                                  $("#planning").text(data.Low)
                                } else if (res.data[0].z_score_desc_C6 === "VERY LOW" && data.Reference === "C6") {
                                  $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very low' + '`' + `)
                                  $("#planning").text(data.Very_Low)
                                }
            
                              }

                            `
    
                            // Script removing old radar-chart 
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i];
                              if (data.Template === 'Radar-Chart') { 
                                let obj = JSON.parse(data.Text)
                                for (const res of obj) {
                                  output += `
                                    if($('.isRadarChartHasData').text() == "hasData") {
                                      `+ res.SuperTrait + `_Chart.destroy()
                                    }
                                  `
                                }
                              }
                            }
    
                            // Script creating new radar-chart
                            for (let i = 0; i < input.length; i++) {
                              const data = input[i];
                              if (data.Template === 'Radar-Chart') { 
                                let obj = JSON.parse(data.Text)
                                for (const res of obj) {
                                  
                                  output += `
                                    let `+ res.SuperTrait + `Select = document.querySelectorAll('.` + res.SuperTrait + `')
                                    `+ res.SuperTrait + `_Chart = new Chart(`+res.SuperTrait+`Select, {
                                      type: 'radar',
                                      data: {
                                        labels: [
                                  `
                                        res.Elements.forEach((response) => {
                                          output += `
                                            "`+response.Description+`",
                                          `
                                        })
                                  output += `
                                        ],
                                        datasets: [
                                          {
                                            label: '`+ res.label1 +`',
                                            data: [
                                  `
                                          res.Elements.forEach((response) => {
                                            output += `
                                            Math.round(res.data[0].cohort_` + response.Trait + ` * 10) / 10),
                                            `
                                          })
                                  output+=`  
                                            ],
                                            fill: true,
                                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                            borderColor: 'rgb(255, 99, 132)',
                                            pointBackgroundColor: 'rgb(255, 99, 132)',
                                            pointBorderColor: '#fff',
                                            pointHoverBackgroundColor: '#fff',
                                            pointHoverBorderColor: 'rgb(255, 99, 132)',
                                          },
                                          {
                                            label: '`+res.label2+`',
                                            data: [
                                  `
                                            res.Elements.forEach((response) => {
                                              output += `
                                              Math.round(res.data[0].ind_` + response.Trait + ` * 10) / 10),
                                              `
                                            })
                                  output +=`
                                            ],
                                            fill: true,
                                            backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                            borderColor: 'rgb(54, 162, 235)',
                                            pointBackgroundColor: 'rgb(54, 162, 235)',
                                            pointBorderColor: '#fff',
                                            pointHoverBackgroundColor: '#fff',
                                            pointHoverBorderColor: 'rgb(54, 162, 235)',
                                          },
                                        ],
                                      },
                                      options: {
                                        scales: {
                                          r: {
                                            beginAtZero: true,
                                            min: 0,
                                            max: 5,
                                            ticks: {
                                            stepSize: 1,
                                            }
                                          },
                                        }
                                      }
                                    })
                                  `
                                }
                              }
                            }
    
                            output += `
                              const getBarChartData = async () => {
                                try {
                                  `
                                    for (let i = 0; i < input.length; i++) {
                                        const res = input[i]
                                        if (res.Template === 'Bar-Chart') { 
                                            let obj = JSON.parse(res.Text)
                                            for (const res of obj) {
                                                res.Elements.forEach((response) => {
                                                    output += `
                                                        $(".percentile_` + response.Trait + `").html(ordinal(Math.round(getRanking(res.data[0].percentile_` + response.Trait + `))))
                                                        $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                                        $(".cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1))
                                                        $(".percentile-` + response.Trait + `").css({ "flex-grow": ` + '`' + '${res.data[0].percentile_' + ``+response.Trait+``+ '}' + '`' + `, "background-color": "#`+ response.Color +`"})
                                                        $(".percentile-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - ${res.data[0].percentile_' + ``+response.Trait+``+ '})' + '`' + `})
                                                    `
                                                })
                                            }
                                        }
                                    } 
                                  output +=`
                                } catch (err) {
                                    console.log(err)
                                }
                              }
                              getBarChartData()
    
                          })
                          .catch((error) => {
                            console.log(error)
                            if(error.code == "ERR_NETWORK") {
                              return Swal.fire({
                                icon: 'info',
                                title: 'Oops...',
                                html: '<p>Network Error, Please try again later!</p>',
                                customClass: 'norm-wording'
                              })
                            }
                          })
                      }) //$.getJSON
                    }) //button click adjustNormBtnSubmit
                  `
                }
              }
    
              // Script for Gender_culture for Tipping and Pressure Point
              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                if (data.Template === 'Gender-Culture') { 
                  output += `
                    axios.get('${url}/b5-get-countries', config)
                    .then((res) => {
                        countryList = res.data

                        let outputHTML = '<select id="norms_country" class="form-select" aria-label="Select nationality">'
                        outputHTML += '<option disabled>Select nationality</option>'
      
                        countryList.map((result, index) => {
                            outputHTML += ` +
                            '`' +
                              '<option value="${result.country}">${result.country}</option>' +     
                              '`' +    
                            `
                        })
                        outputHTML += '</select>'
  
                        $('.country_list').html(outputHTML)
                    })
                    .catch((error) => {
                      console.log(error)
                    })

                    $.getJSON('PressureCalc.json', (jsonDataPressure) => {
                    $.getJSON('TIPpingCalc.json', (jsonDataTIPping) => {
                    $.getJSON('calculation.json', (jsonData) => {
                    axios
                      .post(
                        '${url}/b5-report-data2',
                        {
                          survey_assignment_id : survey_assignment_id,
                          sex : sex,
                          country : country,
                          source : "IPIP120"
                        },
                        config                                            
                      )
                      .then((res) => {
                        console.log(res.data)
                        $('#gender_label').text(sex)
                        $('#culture_label').text(country)
                        $('#norms_gender').val(sex)
                        $('#norms_country').val(country)
                        $(".country-flag").attr("src",` + '`' + 'flags/4x3/${country}.svg' + '`)' + `
                        $(".gender-icon").attr("src",` + '`' + 'gender/${sex}.svg' + '`)' + `

                        // catch error if no sex and country in b5_norm_sum ex. if the sex is trans 
                        if(res.data.status == "noData") {
                          $('.isRadarChartHasData').text('noNormingData')
                          return Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                            customClass: 'norm-wording'
                          })
                        }
                        // catch error if there are sex and country in b5_norm_sum but there is no data in other column like er1_mean column
                        if(res.data.status == "noNormingData") {
                          $('.isRadarChartHasData').text('noNormingData')
                          return Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                            customClass: 'norm-wording'
                          })
                        }

                        // catch error if no data with this survey_assignment_id or something went wrong
                        if(res.data.status == "noSurveyData") {
                          return Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            html: '<p>That didn’t go to plan. Please email your own name and email address to help@talentsage.com. We’ll review it and let you know when it’s fine.</p>',
                            customClass: 'norm-wording'
                          })
                        }

                        if(res.data[0].n <= 30){
                          $('.isRadarChartHasData').text('noNormingData')
                          return Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                            customClass: 'norm-wording'
                          })
                        }

                        $('.isRadarChartHasData').text('hasData')
                        
                      `
                      for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Comparison-Chart-Task-vs-Relationship') { 
                          output += `
                          let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                          let meanTvsR = totalTvsR / 3
                          if(meanTvsR <= 25) {
                            $('.task-vs-relationship-dynamic-title').text("Tasks much more than Relationships")
                            $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/20.jpg")
                          }
                          else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                            $('.task-vs-relationship-dynamic-title').text("Tasks more than Relationships")
                            $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/40.jpg")
                          }
                          else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                            $('.task-vs-relationship-dynamic-title').text("Tasks and Relationships equally")
                            $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/60.jpg")
                          }
                          else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                            $('.task-vs-relationship-dynamic-title').text("Relationships more than Tasks")
                            $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/80.jpg")
                          }
                          else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                            $('.task-vs-relationship-dynamic-title').text("Relationships much more than Tasks")
                            $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/100.jpg")
                          }
                          `
                          break
                        }
                    }
      
                    for (let i = 0; i < input.length; i++) {
                        const data = input[i]
                        if (data.Template === 'Comparison-Chart-Team-vs-Individual') { 
                          output += `
                          let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                          let meanTeamvsIndividual = totalTeamvsIndividual / 3
                          if(meanTeamvsIndividual <= 25) {
                            $('.team-vs-individual-dynamic-title').text("Autonomy much more than Structure")
                            $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/100.jpg")
                          }
                          else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                            $('.team-vs-individual-dynamic-title').text("Autonomy more than Structure")
                            $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/80.jpg")
                          }
                          else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                            $('.team-vs-individual-dynamic-title').text("Structure and Autonomy equally")
                            $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/60.jpg")
                          }
                          else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                            $('.team-vs-individual-dynamic-title').text("Structure more than Autonomy")
                            $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/40.jpg")
                          }
                          else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                            $('.team-vs-individual-dynamic-title').text("Structure much more than Autonomy")
                            $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/20.jpg")
                          }
                          `
                          break
                        }
                    }
      
                    // for TIPping Point
                    for (let i = 0; i < input.length; i++) {
                      const data = input[i]
                      if (data.Template === 'Speedometer-All-Scale') { 
                        output += `
                          let arrData = []
                          let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                          let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                          let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                          let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                          
                          // Speedometer-Scale-Agilator
                          if(res.data[0].percentile_C <= 25) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-agilator').text("Very High")
                            num3 = 1
                            score3 = "Very High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
                          else if(res.data[0].percentile_C == 25.1 || res.data[0].percentile_C <= 35) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-agilator').text("High")
                            num3 = 2
                            score3 = "High"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(res.data[0].percentile_C == 35.1 || res.data[0].percentile_C <= 45) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-agilator').text("Moderate")
                            num3 = 3
                            score3 = "Moderate"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(res.data[0].percentile_C == 45.1 || res.data[0].percentile_C <= 55) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-agilator').text("Low")
                            num3 = 4
                            score3 = "Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(res.data[0].percentile_C == 55.1 || res.data[0].percentile_C <= 100) {
                            $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-agilator').text("Very Low")
                            num3 = 5
                            score3 = "Very Low"
                            role3 = "Agilator"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
      
                          // Speedometer-Scale-Boss-being
                          if(res.data[0].percentile_E <= 45) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-boss-being').text("Very Low")
                            num8 = 5
                            score8 = "Very Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
                          else if(res.data[0].percentile_E == 45.1 || res.data[0].percentile_E <= 55) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-boss-being').text("Low")
                            num8 = 4
                            score8 = "Low"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(res.data[0].percentile_E == 55.1 || res.data[0].percentile_E <= 65) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-boss-being').text("Moderate")
                            num8 = 3
                            score8 = "Moderate"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(res.data[0].percentile_E == 65.1 || res.data[0].percentile_E <= 75) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-boss-being').text("High")
                            num8 = 2
                            score8 = "High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(res.data[0].percentile_E == 75.1 || res.data[0].percentile_E <= 100) {
                            $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-boss-being').text("Very High")
                            num8 = 1
                            score8 = "Very High"
                            role8 = "Boss-being"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
      
                          // Speedometer-Scale-Empathor
                          if(res.data[0].percentile_ER <= 45) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-empathor').text("Very Low")
                            num6 = 5
                            score6 = "Very Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
                          else if(res.data[0].percentile_ER == 45.1 || res.data[0].percentile_ER <= 55) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-empathor').text("Low")
                            num6 = 4
                            score6 = "Low"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(res.data[0].percentile_ER == 55.1 || res.data[0].percentile_ER <= 65) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-empathor').text("Moderate")
                            num6 = 3
                            score6 = "Moderate"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(res.data[0].percentile_ER == 65.1 || res.data[0].percentile_ER <= 75) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-empathor').text("High")
                            num6 = 2
                            score6 = "High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(res.data[0].percentile_ER == 75.1 || res.data[0].percentile_ER <= 100) {
                            $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-empathor').text("Very High")
                            num6 = 1
                            score6 = "Very High"
                            role6 = "Empathor"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
      
                          // Speedometer-Scale-Imagineer
                          if(res.data[0].percentile_O <= 45) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-imagineer').text("Very Low")
                            num4 = 5
                            score4 = "Very Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
                          else if(res.data[0].percentile_O == 45.1 || res.data[0].percentile_O <= 55.0) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-imagineer').text("Low")
                            num4 = 4
                            score4 = "Low"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(res.data[0].percentile_O == 55.1 || res.data[0].percentile_O <= 65) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-imagineer').text("Moderate")
                            num4 = 3
                            score4 = "Moderate"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(res.data[0].percentile_O == 65.1 || res.data[0].percentile_O <= 75) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-imagineer').text("High")
                            num4 = 2
                            score4 = "High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(res.data[0].percentile_O == 75.1 || res.data[0].percentile_O <= 100) {
                            $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-imagineer').text("Very High")
                            num4 = 1
                            score4 = "Very High"
                            role4 = "Imagineer"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
      
                          // Speedometer-Scale-Rationalist
                          if(res.data[0].percentile_A <= 25) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-rationalist').text("Very High")
                            num1 = 1
                            score1 = "Very High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
                          else if(res.data[0].percentile_A == 25.1 || res.data[0].percentile_A <= 35) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-rationalist').text("High")
                            num1 = 2
                            score1 = "High"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(res.data[0].percentile_A == 35.1 || res.data[0].percentile_A <= 45) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-rationalist').text("Moderate")
                            num1 = 3
                            score1 = "Moderate"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(res.data[0].percentile_A == 45.1 || res.data[0].percentile_A <= 55) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-rationalist').text("Low")
                            num1 = 4
                            score1 = "Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(res.data[0].percentile_A == 55.1 || res.data[0].percentile_A <= 100) {
                            $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-rationalist').text("Very Low")
                            num1 = 5
                            score1 = "Very Low"
                            role1 = "Rationalist"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
      
                          // Speedometer-Scale-Realist
                          if(res.data[0].percentile_O <= 25) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            $('.score-speedometer-scale-realist').text("Very High")
                            num10 = 1
                            score10 = "Very High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
                          else if(res.data[0].percentile_O == 25.1 || res.data[0].percentile_O <= 35) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            $('.score-speedometer-scale-realist').text("High")
                            num10 = 2
                            score10 = "High"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(res.data[0].percentile_O == 35.1 || res.data[0].percentile_O <= 45) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            $('.score-speedometer-scale-realist').text("Moderate")
                            num10 = 3
                            score10 = "Moderate"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(res.data[0].percentile_O == 45.1 || res.data[0].percentile_O <= 55) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            $('.score-speedometer-scale-realist').text("Low")
                            num10 = 4
                            score10 = "Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(res.data[0].percentile_O == 55.1 || res.data[0].percentile_O <= 100) {
                            $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            $('.score-speedometer-scale-realist').text("Very Low")
                            num10 = 5
                            score10 = "Very Low"
                            role10 = "Realist"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
      
                          // Speedometer-Scale-Solo-Operator
                          if(res.data[0].percentile_E <= 25) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            $('.score-speedometer-scale-solo-operator').text("Very High")
                            num2 = 1
                            score2 = "Very High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
                          else if(res.data[0].percentile_E == 25.1 || res.data[0].percentile_E <= 35) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            $('.score-speedometer-scale-solo-operator').text("High")
                            num2 = 2
                            score2 = "High"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(res.data[0].percentile_E == 35.1 || res.data[0].percentile_E <= 45) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            $('.score-speedometer-scale-solo-operator').text("Moderate")
                            num2 = 3
                            score2 = "Moderate"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(res.data[0].percentile_E == 45.1 || res.data[0].percentile_E <= 55) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            $('.score-speedometer-scale-solo-operator').text("Low")
                            num2 = 4
                            score2 = "Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(res.data[0].percentile_E == 55.1 || res.data[0].percentile_E <= 100) {
                            $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            $('.score-speedometer-scale-solo-operator').text("Very Low")
                            num2 = 5
                            score2 = "Very Low"
                            role2 = "Solo Operator"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
      
                          // Speedometer-Scale-Standard-Bearer
                          if(res.data[0].percentile_C <= 45) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very Low")
                            num9 = 5
                            score9 = "Very Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
                          else if(res.data[0].percentile_C == 45.1 || res.data[0].percentile_C <= 55.0) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            $('.score-speedometer-scale-standard-bearer').text("Low")
                            num9 = 4
                            score9 = "Low"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(res.data[0].percentile_C == 55.1 || res.data[0].percentile_C <= 65) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            $('.score-speedometer-scale-standard-bearer').text("Moderate")
                            num9 = 3
                            score9 = "Moderate"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(res.data[0].percentile_C == 65.1 || res.data[0].percentile_C <= 75) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            $('.score-speedometer-scale-standard-bearer').text("High")
                            num9 = 2
                            score9 = "High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(res.data[0].percentile_C == 75.1 || res.data[0].percentile_C <= 100) {
                            $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            $('.score-speedometer-scale-standard-bearer').text("Very High")
                            num9 = 1
                            score9 = "Very High"
                            role9 = "Standard Bearer"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
      
                          // Speedometer-Scale-Strong-and-Stable
                          if(res.data[0].percentile_ER <= 25) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very High")
                            num5 = 1
                            score5 = "Very High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
                          else if(res.data[0].percentile_ER == 25.1 || res.data[0].percentile_ER <= 35) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            $('.score-speedometer-scale-strong-and-stable').text("High")
                            num5 = 2
                            score5 = "High"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(res.data[0].percentile_ER == 35.1 || res.data[0].percentile_ER <= 45) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Moderate")
                            num5 = 3
                            score5 = "Moderate"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(res.data[0].percentile_ER == 45.1 || res.data[0].percentile_ER <= 55) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Low")
                            num5 = 4
                            score5 = "Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(res.data[0].percentile_ER == 55.1 || res.data[0].percentile_ER <= 100) {
                            $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            $('.score-speedometer-scale-strong-and-stable').text("Very Low")
                            num5 = 5
                            score5 = "Very Low"
                            role5 = "Strong and Steady"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
      
                          // Speedometer-Scale-Team-Builder
                          if(res.data[0].percentile_A <= 45) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            $('.score-speedometer-scale-team-builder').text("Very Low")
                            num7 = 5
                            score7 = "Very Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
                          else if(res.data[0].percentile_A == 45.1 || res.data[0].percentile_A <= 55) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            $('.score-speedometer-scale-team-builder').text("Low")
                            num7 = 4
                            score7 = "Low"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(res.data[0].percentile_A == 55.1 || res.data[0].percentile_A <= 65) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            $('.score-speedometer-scale-team-builder').text("Moderate")
                            num7 = 3
                            score7 = "Moderate"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(res.data[0].percentile_A == 65.1 || res.data[0].percentile_A <= 75) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            $('.score-speedometer-scale-team-builder').text("High")
                            num7 = 2
                            score7 = "High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(res.data[0].percentile_A == 75.1 || res.data[0].percentile_A <= 100) {
                            $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            $('.score-speedometer-scale-team-builder').text("Very High")
                            num7 = 1
                            score7 = "Very High"
                            role7 = "Team Builder"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
      
                          let objSpeedometerAllScale = [
                            { key: num3, png: png3, score: score3, role: role3 },
                            { key: num8, png: png8, score: score8, role: role8 },
                            { key: num6, png: png6, score: score6, role: role6 },
                            { key: num4, png: png4, score: score4, role: role4 },
                            { key: num1, png: png1, score: score1, role: role1 },
                            { key: num10, png: png10, score: score10, role: role10 },
                            { key: num2, png: png2, score: score2, role: role2 },
                            { key: num9, png: png9, score: score9, role: role9 },
                            { key: num5, png: png5, score: score5, role: role5 },
                            { key: num7, png: png7, score: score7, role: role7 }
                          ]
      
                          let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                          for(let i=0; i<10; i++) {
                            $('.all-scale-role'+(i+1)).text(sortedData[i].role)
                            $('.all-scale-img'+(i+1)).attr("src", sortedData[i].png)
                            $('.all-scale-score'+(i+1)).text(sortedData[i].score)
                          }
                        `
                      }
                    }
      
                    // for Pressure Point
                    for (let i = 0; i < input.length; i++) {
                      const data = input[i]
                      if (data.Template === 'Speedometer-All-Scale-Pressure-Point') { 
                        output += `
                          let arrData = []
                          let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                          let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                          let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                          let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                          let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
      
                          // Speedometer-Scale-Amenable
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_A = res.data[0].percentile_A * 0.8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.0
                          }
                          else if(res.data[0].percentile_ER== 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.2
                          }
      
                          if(high_percentile_score_A <= 20) {
                            $('.score-speedometer-amenable').text("Very Low")
                            $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            num7 = 5
                            score7 = "Very Low"
                            role7 = "Amenable"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
                          else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                            $('.score-speedometer-amenable').text("Low")
                            $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            num7 = 4
                            score7 = "Low"
                            role7 = "Amenable"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                            $('.score-speedometer-amenable').text("Moderate")
                            $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            num7 = 3
                            score7 = "Moderate"
                            role7 = "Amenable"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                            $('.score-speedometer-amenable').text("High")
                            $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            num7 = 2
                            score7 = "High"
                            role7 = "Amenable"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                            $('.score-speedometer-amenable').text("Very High")
                            $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            num7 = 1
                            score7 = "Very High"
                            role7 = "Amenable"
                            png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
      
                          // Speedometer-Scale-Coldly-Logical
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_A = res.data[0].percentile_A * 0.8
                          }
      
                          if(low_percentile_score_A <= 20) {
                            $('.score-speedometer-coldly-logical').text("Very High")
                            $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                            num10 = 1
                            score10 = "Very High"
                            role10 = "Coldly Logical"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                          }
                          else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                            $('.score-speedometer-coldly-logical').text("High")
                            $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                            num10 = 2
                            score10 = "High"
                            role10 = "Coldly Logical"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                          }
                          else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                            $('.score-speedometer-coldly-logical').text("Moderate")
                            $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                            num10 = 3
                            score10 = "Moderate"
                            role10 = "Coldly Logical"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                          }
                          else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                            $('.score-speedometer-coldly-logical').text("Low")
                            $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                            num10 = 4
                            score10 = "Low"
                            role10 = "Coldly Logical"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                          }
                          else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                            $('.score-speedometer-coldly-logical').text("Very Low")
                            $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                            num10 = 5
                            score10 = "Very Low"
                            role10 = "Coldly Logical"
                            png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                          }
      
                          // Speedometer-Scale-Conforming
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_C = res.data[0].percentile_C * 0.8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.2
                          }
      
                          if(high_percentile_score_C <= 20) {
                            $('.score-speedometer-conforming').text("Very Low")
                            $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            num9 = 5
                            score9 = "Very Low"
                            role9 = "Conforming"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
                          else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                            $('.score-speedometer-conforming').text("Low")
                            $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            num9 = 4
                            score9 = "Low"
                            role9 = "Conforming"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                            $('.score-speedometer-conforming').text("Moderate")
                            $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            num9 = 3
                            score9 = "Moderate"
                            role9 = "Conforming"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                            $('.score-speedometer-conforming').text("High")
                            $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            num9 = 2
                            score9 = "High"
                            role9 = "Conforming"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                            $('.score-speedometer-conforming').text("Very High")
                            $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            num9 = 1
                            score9 = "Very High"
                            role9 = "Conforming"
                            png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
      
                          // Speedometer-Scale-Disappearing
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_E = res.data[0].percentile_E * 0.8
                          }
      
                          if(low_percentile_score_E <= 20) {
                            $('.score-speedometer-disappearing').text("Very High")
                            $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            num2 = 1
                            score2 = "Very High"
                            role2 = "Disappearing"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
                          else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                            $('.score-speedometer-disappearing').text("High")
                            $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            num2 = 2
                            score2 = "High"
                            role2 = "Disappearing"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                            $('.score-speedometer-disappearing').text("Moderate")
                            $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            num2 = 3
                            score2 = "Moderate"
                            role2 = "Disappearing"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                            $('.score-speedometer-disappearing').text("Low")
                            $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            num2 = 4
                            score2 = "Low"
                            role2 = "Disappearing"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                            $('.score-speedometer-disappearing').text("Very Low")
                            $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            num2 = 5
                            score2 = "Very Low"
                            role2 = "Disappearing"
                            png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
      
                          // Speedometer-Scale-Dominating
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_E = res.data[0].percentile_E * 0.8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.2
                          }
      
                          if(high_percentile_score_E <= 20) {
                            $('.score-speedometer-dominating').text("Very Low")
                            $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                            num8 = 5
                            score8 = "Very Low"
                            role8 = "Dominating"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                          }
                          else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                            $('.score-speedometer-dominating').text("Low")
                            $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                            num8 = 4
                            score8 = "Low"
                            role8 = "Dominating"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                          }
                          else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                            $('.score-speedometer-dominating').text("Moderate")
                            $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                            num8 = 3
                            score8 = "Moderate"
                            role8 = "Dominating"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                          }
                          else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                            $('.score-speedometer-dominating').text("High")
                            $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                            num8 = 2
                            score8 = "High"
                            role8 = "Dominating"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                          }
                          else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                            $('.score-speedometer-dominating').text("Very High")
                            $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                            num8 = 1
                            score8 = "Very High"
                            role8 = "Dominating"
                            png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                          }
      
                          // Speedometer-Scale-Dreaming
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_O = res.data[0].percentile_O * 0.8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.2
                          }
      
                          if(high_percentile_score_O <= 20) {
                            $('.score-speedometer-dreaming').text("Very Low")
                            $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            num4 = 5
                            score4 = "Very Low"
                            role4 = "Dreaming"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
                          else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                            $('.score-speedometer-dreaming').text("Low")
                            $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            num4 = 4
                            score4 = "Low"
                            role4 = "Dreaming"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                            $('.score-speedometer-dreaming').text("Moderate")
                            $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            num4 = 3
                            score4 = "Moderate"
                            role4 = "Dreaming"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                            $('.score-speedometer-dreaming').text("High")
                            $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            num4 = 2
                            score4 = "High"
                            role4 = "Dreaming"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                            $('.score-speedometer-dreaming').text("Very High")
                            $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            num4 = 1
                            score4 = "Very High"
                            role4 = "Dreaming"
                            png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
      
                          // Speedometer-Scale-Emotionally-Numb
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 0.8
                          }
      
                          if(low_percentile_score_ER <= 20) {
                            $('.score-speedometer-emotionally-numb').text("Very High")
                            $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            num5 = 1
                            score5 = "Very High"
                            role5 = "Emotionally Numb"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
                          else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                            $('.score-speedometer-emotionally-numb').text("High")
                            $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            num5 = 2
                            score5 = "High"
                            role5 = "Emotionally Numb"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                            $('.score-speedometer-emotionally-numb').text("Moderate")
                            $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            num5 = 3
                            score5 = "Moderate"
                            role5 = "Emotionally Numb"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                            $('.score-speedometer-emotionally-numb').text("Low")
                            $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            num5 = 4
                            score5 = "Low"
                            role5 = "Emotionally Numb"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                            $('.score-speedometer-emotionally-numb').text("Very Low")
                            $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            num5 = 5
                            score5 = "Very Low"
                            role5 = "Emotionally Numb"
                            png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
      
                          // Speedometer-Scale-Emotive
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 0.8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.2
                          }
      
                          if(high_percentile_score_ER <= 20) {
                            $('.score-speedometer-emotive').text("Very Low")
                            $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                            num6 = 5
                            score6 = "Very Low"
                            role6 = "Emotive"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                          }
                          else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                            $('.score-speedometer-emotive').text("Low")
                            $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                            num6 = 4
                            score6 = "Low"
                            role6 = "Emotive"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                          }
                          else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                            $('.score-speedometer-emotive').text("Moderate")
                            $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                            num6 = 3
                            score6 = "Moderate"
                            role6 = "Emotive"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                          }
                          else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                            $('.score-speedometer-emotive').text("High")
                            $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                            num6 = 2
                            score6 = "High"
                            role6 = "Emotive"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                          }
                          else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                            $('.score-speedometer-emotive').text("Very High")
                            $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                            num6 = 1
                            score6 = "Very High"
                            role6 = "Emotive"
                            png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                          }
      
                          // Speedometer-Scale-Pliable
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_C = res.data[0].percentile_C * 0.8
                          }
      
                          if(low_percentile_score_C <= 20) {
                            $('.score-speedometer-pliable').text("Very High")
                            $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                            num3 = 1
                            score3 = "Very High"
                            role3 = "Pliable"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                          }
                          else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                            $('.score-speedometer-pliable').text("High")
                            $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                            num3 = 2
                            score3 = "High"
                            role3 = "Pliable"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                          }
                          else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                            $('.score-speedometer-pliable').text("Moderate")
                            $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                            num3 = 3
                            score3 = "Moderate"
                            role3 = "Pliable"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                          }
                          else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                            $('.score-speedometer-pliable').text("Low")
                            $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                            num3 = 4
                            score3 = "Low"
                            role3 = "Pliable"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                          }
                          else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                            $('.score-speedometer-pliable').text("Very Low")
                            $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                            num3 = 5
                            score3 = "Very Low"
                            role3 = "Pliable"
                            png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                          }
      
                          // Speedometer-Scale-Strictly-Literal
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_O = res.data[0].percentile_O * 0.8
                          }
      
                          if(low_percentile_score_O <= 20) {
                            $('.score-speedometer-strictly-literal').text("Very High")
                            $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                            num1 = 1
                            score1 = "Very High"
                            role1 = "Strictly Literal"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                          }
                          else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                            $('.score-speedometer-strictly-literal').text("High")
                            $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                            num1 = 2
                            score1 = "High"
                            role1 = "Strictly Literal"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                          }
                          else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                            $('.score-speedometer-strictly-literal').text("Moderate")
                            $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                            num1 = 3
                            score1 = "Moderate"
                            role1 = "Strictly Literal"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                          }
                          else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                            $('.score-speedometer-strictly-literal').text("Low")
                            $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                            num1 = 4
                            score1 = "Low"
                            role1 = "Strictly Literal"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                          }
                          else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                            $('.score-speedometer-strictly-literal').text("Very Low")
                            $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                            num1 = 5
                            score1 = "Very Low"
                            role1 = "Strictly Literal"
                            png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                          }
      
                          let objSpeedometerAllScale = [
                            { key: num7, png: png7, score: score7, role: role7 },
                            { key: num10, png: png10, score: score10, role: role10 },
                            { key: num9, png: png9, score: score9, role: role9 },
                            { key: num2, png: png2, score: score2, role: role2 },
                            { key: num8, png: png8, score: score8, role: role8 },
                            { key: num4, png: png4, score: score4, role: role4 },
                            { key: num5, png: png5, score: score5, role: role5 },
                            { key: num6, png: png6, score: score6, role: role6 },
                            { key: num3, png: png3, score: score3, role: role3 },
                            { key: num1, png: png1, score: score1, role: role1 }
                          ]
      
                          let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                          for(let i=0; i<10; i++) {
                            $('.all-scale-role'+(i+1)).text(sortedData[i].role)
                            $('.all-scale-img'+(i+1)).attr("src", sortedData[i].png)
                            $('.all-scale-score'+(i+1)).text(sortedData[i].score)
                          }
                        `
                      }
                    }
                    
                      output += `
      
                      
                        const ordinal = (number) => {
                          const ordinalRules = new Intl.PluralRules("en", {
                            type: "ordinal"
                          });
                          const suffixes = {
                            one: "st",
                            two: "nd",
                            few: "rd",
                            other: "th"
                          };
                          const suffix = suffixes[ordinalRules.select(number)];
                          return (number + suffix);
                        }
                        function getRanking (rank) {
                          if (rank <= 5){
                            return 5
                          } else if (rank >= 95) {
                            return 95
                          } else {
                            return rank
                          }
                        }
                        for (const data of jsonDataTIPping) { 
                          //Strong & Steady Scale
                            // Strong & Steady Subscale1
                            let totalSubscale_StrongAndSteady1 = res.data[0].percentile_ER4 + res.data[0].percentile_ER1 + res.data[0].percentile_ER6
                            let meanSubscale_StrongAndSteady1 = totalSubscale_StrongAndSteady1 / 3
                            // Very High
                            if(meanSubscale_StrongAndSteady1 <= 25 && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_StrongAndSteady1 == 25.1 || meanSubscale_StrongAndSteady1 <= 35) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_StrongAndSteady1 == 35.1 || meanSubscale_StrongAndSteady1 <= 45) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_StrongAndSteady1 == 45.1 || meanSubscale_StrongAndSteady1 <= 55) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_StrongAndSteady1 == 55.1 || meanSubscale_StrongAndSteady1 <= 100) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Very_Low)
                            }
      
                            // Strong & Steady Subscale2
                            let totalSubscale_StrongAndSteady2 = res.data[0].percentile_ER2 + res.data[0].percentile_ER3 + res.data[0].percentile_ER5
                            let meanSubscale_StrongAndSteady2 = totalSubscale_StrongAndSteady2 / 3
                            // Very High
                            if(meanSubscale_StrongAndSteady2 <= 25 && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_StrongAndSteady2 == 25.1 || meanSubscale_StrongAndSteady2 <= 35) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_StrongAndSteady2 == 35.1 || meanSubscale_StrongAndSteady2 <= 45) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_StrongAndSteady2 == 45.1 || meanSubscale_StrongAndSteady2 <= 55) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_StrongAndSteady2 == 55.1 || meanSubscale_StrongAndSteady2 <= 100) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Very_Low)
                            }
      
                          //Solo Operator Scale
                            // Solo Operator Subscale1
                            let totalSubscale_SoloOperator1 = res.data[0].percentile_E3 + res.data[0].percentile_E4 + res.data[0].percentile_E5
                            let meanSubscale_SoloOperator1 = totalSubscale_SoloOperator1 / 3
                            // Very High
                            if(meanSubscale_SoloOperator1 <= 25 && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_SoloOperator1 == 25.1 || meanSubscale_SoloOperator1 <= 35) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_SoloOperator1 == 35.1 || meanSubscale_SoloOperator1 <= 45) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_SoloOperator1 == 45.1 || meanSubscale_SoloOperator1 <= 55) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_SoloOperator1 == 55.1 || meanSubscale_SoloOperator1 <= 100) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_SoloOperator2 = res.data[0].percentile_E1 + res.data[0].percentile_E2 + res.data[0].percentile_E6
                            let meanSubscale_SoloOperator2 = totalSubscale_SoloOperator2 / 3
                            // Very High
                            if(meanSubscale_SoloOperator2 <= 25 && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_SoloOperator2 == 25.1 || meanSubscale_SoloOperator2 <= 35) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_SoloOperator2 == 35.1 || meanSubscale_SoloOperator2 <= 45) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_SoloOperator2 == 45.1 || meanSubscale_SoloOperator2 <= 55) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_SoloOperator2 == 55.1 || meanSubscale_SoloOperator2 <= 100) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Very_Low)
                            }
      
                          //Realist Scale
                            // Realist Subscale1
                            let totalSubscale_Realist1 = res.data[0].percentile_O1 + res.data[0].percentile_O2 + res.data[0].percentile_O5
                            let meanSubscale_Realist1 = totalSubscale_Realist1 / 3
                            // Very High
                            if(meanSubscale_Realist1 <= 25 && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Realist1 == 25.1 || meanSubscale_Realist1 <= 35) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Realist1 == 35.1 || meanSubscale_Realist1 <= 45) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Realist1 == 45.1 || meanSubscale_Realist1 <= 55) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Realist1 == 55.1 || meanSubscale_Realist1 <= 100) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Realist2 = res.data[0].percentile_O3 + res.data[0].percentile_O4 + res.data[0].percentile_O6
                            let meanSubscale_Realist2 = totalSubscale_Realist2 / 3
                            // Very High
                            if(meanSubscale_Realist2 <= 25 && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Realist2 == 25.1 || meanSubscale_Realist2 <= 35) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Realist2 == 35.1 || meanSubscale_Realist2 <= 45) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Realist2 == 45.1 || meanSubscale_Realist2 <= 55) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Realist2 == 55.1 || meanSubscale_Realist2 <= 100) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Very_Low)
                            }
      
                          //Rationalist Scale
                            // Rationalist Subscale1
                            let totalSubscale_Rationalist1 = res.data[0].percentile_A1 + res.data[0].percentile_A2 + res.data[0].percentile_A5
                            let meanSubscale_Rationalist1 = totalSubscale_Rationalist1 / 3
                            // Very High
                            if(meanSubscale_Rationalist1 <= 25 && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Rationalist1 == 25.1 || meanSubscale_Rationalist1 <= 35) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Rationalist1 == 35.1 || meanSubscale_Rationalist1 <= 45) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Rationalist1 == 45.1 || meanSubscale_Rationalist1 <= 55) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Rationalist1 == 55.1 || meanSubscale_Rationalist1 <= 100) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Rationalist2 = res.data[0].percentile_A3 + res.data[0].percentile_A4 + res.data[0].percentile_A6
                            let meanSubscale_Rationalist2 = totalSubscale_Rationalist2 / 3
                            // Very High
                            if(meanSubscale_Rationalist2 <= 25 && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Rationalist2 == 25.1 || meanSubscale_Rationalist2 <= 35) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Rationalist2 == 35.1 || meanSubscale_Rationalist2 <= 45) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Rationalist2 == 45.1 || meanSubscale_Rationalist2 <= 55) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Rationalist2 == 55.1 || meanSubscale_Rationalist2 <= 100) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Very_Low)
                            }
      
                        //Agilator Scale
                            // Agilator Subscale1
                            let totalSubscale_Agilator1 = res.data[0].percentile_C3 + res.data[0].percentile_C4 + res.data[0].percentile_C5
                            let meanSubscale_Agilator1 = totalSubscale_Agilator1 / 3
                            // Very High
                            if(meanSubscale_Agilator1 <= 25 && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Agilator1 == 25.1 || meanSubscale_Agilator1 <= 35) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Agilator1 == 35.1 || meanSubscale_Agilator1 <= 45) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Agilator1 == 45.1 || meanSubscale_Agilator1 <= 55) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Agilator1 == 55.1 || meanSubscale_Agilator1 <= 100) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Agilator2 = res.data[0].percentile_C2 + res.data[0].percentile_C1 + res.data[0].percentile_C6
                            let meanSubscale_Agilator2 = totalSubscale_Agilator2 / 3
                            // Very High
                            if(meanSubscale_Agilator2 <= 25 && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Agilator2 == 25.1 || meanSubscale_Agilator2 <= 35) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Agilator2 == 35.1 || meanSubscale_Agilator2 <= 45) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Agilator2 == 45.1 || meanSubscale_Agilator2 <= 55) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Agilator2 == 55.1 || meanSubscale_Agilator2 <= 100) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Very_Low)
                            }
                        
                        // Empathor
                           // Empathor Subscale1
                           let totalSubscale_Empathor1 = res.data[0].percentile_ER4 + res.data[0].percentile_ER1 + res.data[0].percentile_ER6
                           let meanSubscale_Empathor1 = totalSubscale_Empathor1 / 3
                           // Very Low
                           if(meanSubscale_Empathor1 <= 45 && data.Reference === "Empathor-1st-subscale") {
                            $('#Empathor_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Empathor1 == 45.1 || meanSubscale_Empathor1 <= 55.0) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Empathor1 == 55.1 || meanSubscale_Empathor1 <= 65) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Empathor1 == 65.1 || meanSubscale_Empathor1 <= 75) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Empathor1 == 75.1 || meanSubscale_Empathor1 <= 100) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Very_High)
                            }
      
                           // Empathor Subscale2
                           let totalSubscale_Empathor2 = res.data[0].percentile_ER2 + res.data[0].percentile_ER3 + res.data[0].percentile_ER5
                           let meanSubscale_Empathor2 = totalSubscale_Empathor2 / 3
                           // Very Low
                           if(meanSubscale_Empathor2 <= 45 && data.Reference === "Empathor-2nd-subscale") {
                            $('#Empathor_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Empathor2 == 45.1 || meanSubscale_Empathor2 <= 55.0) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Empathor2 == 55.1 || meanSubscale_Empathor2 <= 65) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Empathor2 == 65.1 || meanSubscale_Empathor2 <= 75) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Empathor2 == 75.1 || meanSubscale_Empathor2 <= 100) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Very_High)
                            }
      
                          // Imagineer
                           // Imagineer Subscale1
                           let totalSubscale_Imagineer1 = res.data[0].percentile_O1 + res.data[0].percentile_O2 + res.data[0].percentile_O5
                           let meanSubscale_Imagineer1 = totalSubscale_Imagineer1 / 3
                           // Very Low
                           if(meanSubscale_Imagineer1 <= 45 && data.Reference === "Imagineer-1st-subscale") {
                            $('#Imagineer_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Imagineer1 == 45.1 || meanSubscale_Imagineer1 <= 55.0) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Imagineer1 == 55.1 || meanSubscale_Imagineer1 <= 65) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Imagineer1 == 65.1 || meanSubscale_Imagineer1 <= 75) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Imagineer1 == 75.1 || meanSubscale_Imagineer1 <= 100) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Very_High)
                            }
      
                           // Imagineer Subscale2
                           let totalSubscale_Imagineer2 = res.data[0].percentile_O3 + res.data[0].percentile_O4 + res.data[0].percentile_O6
                           let meanSubscale_Imagineer2 = totalSubscale_Imagineer2 / 3
                           // Very Low
                           if(meanSubscale_Imagineer2 <= 45 && data.Reference === "Imagineer-2nd-subscale") {
                            $('#Imagineer_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Imagineer2 == 45.1 || meanSubscale_Imagineer2 <= 55.0) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Imagineer2 == 55.1 || meanSubscale_Imagineer2 <= 65) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Imagineer2 == 65.1 || meanSubscale_Imagineer2 <= 75) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Imagineer2 == 75.1 || meanSubscale_Imagineer2 <= 100) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Very_High)
                            }
      
                          // TeamBuilder
                           // TeamBuilder Subscale1
                           let totalSubscale_TeamBuilder1 = res.data[0].percentile_A1 + res.data[0].percentile_A2 + res.data[0].percentile_A5
                           let meanSubscale_TeamBuilder1 = totalSubscale_TeamBuilder1 / 3
                           // Very Low
                           if(meanSubscale_TeamBuilder1 <= 45 && data.Reference === "TeamBuilder-1st-subscale") {
                            $('#TeamBuilder_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_TeamBuilder1 == 45.1 || meanSubscale_TeamBuilder1 <= 55.0) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_TeamBuilder1 == 55.1 || meanSubscale_TeamBuilder1 <= 65) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_TeamBuilder1 == 65.1 || meanSubscale_TeamBuilder1 <= 75) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_TeamBuilder1 == 75.1 || meanSubscale_TeamBuilder1 <= 100) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Very_High)
                            }
      
                           // TeamBuilder Subscale2
                           let totalSubscale_TeamBuilder2 = res.data[0].percentile_A3 + res.data[0].percentile_A4 + res.data[0].percentile_A6
                           let meanSubscale_TeamBuilder2 = totalSubscale_TeamBuilder2 / 3
                           // Very Low
                           if(meanSubscale_TeamBuilder2 <= 45 && data.Reference === "TeamBuilder-2nd-subscale") {
                            $('#TeamBuilder_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_TeamBuilder2 == 45.1 || meanSubscale_TeamBuilder2 <= 55.0) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_TeamBuilder2 == 55.1 || meanSubscale_TeamBuilder2 <= 65) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_TeamBuilder2 == 65.1 || meanSubscale_TeamBuilder2 <= 75) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_TeamBuilder2 == 75.1 || meanSubscale_TeamBuilder2 <= 100) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Very_High)
                            }
      
                          // StandardBearer
                           // StandardBearer Subscale1
                           let totalSubscale_StandardBearer1 = res.data[0].percentile_C3 + res.data[0].percentile_C4 + res.data[0].percentile_C5
                           let meanSubscale_StandardBearer1 = totalSubscale_StandardBearer1 / 3
                           // Very Low
                           if(meanSubscale_StandardBearer1 <= 45 && data.Reference === "StandardBearer-1st-subscale") {
                            $('#StandardBearer_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_StandardBearer1 == 45.1 || meanSubscale_StandardBearer1 <= 55.0) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_StandardBearer1 == 55.1 || meanSubscale_StandardBearer1 <= 65) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_StandardBearer1 == 65.1 || meanSubscale_StandardBearer1 <= 75) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_StandardBearer1 == 75.1 || meanSubscale_StandardBearer1 <= 100) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Very_High)
                            }
      
                           // StandardBearer Subscale2
                           let totalSubscale_StandardBearer2 = res.data[0].percentile_C2 + res.data[0].percentile_C1 + res.data[0].percentile_C6
                           let meanSubscale_StandardBearer2 = totalSubscale_StandardBearer2 / 3
                           // Very Low
                           if(meanSubscale_StandardBearer2 <= 45 && data.Reference === "StandardBearer-2nd-subscale") {
                            $('#StandardBearer_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_StandardBearer2 == 45.1 || meanSubscale_StandardBearer2 <= 55.0) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_StandardBearer2 == 55.1 || meanSubscale_StandardBearer2 <= 65) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_StandardBearer2 == 65.1 || meanSubscale_StandardBearer2 <= 75) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_StandardBearer2 == 75.1 || meanSubscale_StandardBearer2 <= 100) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Very_High)
                            }
      
                          // Boss-being
                           // Boss-being Subscale1
                           let totalSubscale_Bossbeing1 = res.data[0].percentile_E3 + res.data[0].percentile_E4 + res.data[0].percentile_E5
                           let meanSubscale_Bossbeing1 = totalSubscale_Bossbeing1 / 3
                           // Very Low
                           if(meanSubscale_Bossbeing1 <= 45 && data.Reference === "Boss-being-1st-subscale") {
                            $('#Bossbeing_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Bossbeing1 == 45.1 || meanSubscale_Bossbeing1 <= 55.0) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Bossbeing1 == 55.1 || meanSubscale_Bossbeing1 <= 65) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Bossbeing1 == 65.1 || meanSubscale_Bossbeing1 <= 75) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Bossbeing1 == 75.1 || meanSubscale_Bossbeing1 <= 100) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Very_High)
                            }
      
                           // Boss-being Subscale2
                           let totalSubscale_Bossbeing2 = res.data[0].percentile_E1 + res.data[0].percentile_E2 + res.data[0].percentile_E6
                           let meanSubscale_Bossbeing2 = totalSubscale_Bossbeing2 / 3
                           // Very Low
                           if(meanSubscale_Bossbeing2 <= 45 && data.Reference === "Boss-being-2nd-subscale") {
                            $('#Bossbeing_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Bossbeing2 == 45.1 || meanSubscale_Bossbeing2 <= 55.0) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Bossbeing2 == 55.1 || meanSubscale_Bossbeing2 <= 65) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Bossbeing2 == 65.1 || meanSubscale_Bossbeing2 <= 75) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Bossbeing2 == 75.1 || meanSubscale_Bossbeing2 <= 100) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Very_High)
                            }
                          // Task vs Relationship
                            let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                            let meanTvsR = totalTvsR / 3
                            if(meanTvsR <= 25 && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Very_High_Task_Focus)
                            }
                            else if((meanTvsR >= 25.1 && meanTvsR <= 45) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.High_Balance_1)
                            }
                            else if((meanTvsR >= 45.1 && meanTvsR <= 55) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Moderate_Task_vs_Relationship)
                            }
                            else if((meanTvsR >= 55.1 && meanTvsR <= 75) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.High_Balance_2)
                            }
                            else if((meanTvsR >= 75.1 && meanTvsR <= 100) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Very_High_Relationship)
                            }
      
                          // Team vs Individual Main Scale
                            
                            let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                            let meanTeamvsIndividual = totalTeamvsIndividual / 3
                            if(meanTeamvsIndividual <= 25 && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Very_High_Individual)
                            }
                            else if((meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.High_Balance_2)
                            }
                            else if((meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Moderate_Team_vs_Individual)
                            }
                            else if((meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.High_Balance_1)
                            }
                            else if((meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Very_High_Team_Structure)
                            }
                          }
                          for (const data of jsonDataPressure) {
                        //Start of Pressure Point
                        // LOW
                        // Emotionally Numb
                          let low_percentile_score_ER = 0 
                          let low_percentile_score_ER1 = 0
                          let low_percentile_score_ER2 = 0
                          let low_percentile_score_ER3 = 0
                          let low_percentile_score_ER4 = 0
                          let low_percentile_score_ER5 = 0
                          let low_percentile_score_ER6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.2
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.2
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.2
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.2
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.2
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.2
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.0
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.0
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.0
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.0
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.0
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.0
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 0.8
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 0.8
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 0.8
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 0.8
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 0.8
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 0.8
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 0.8
                          }
                        //End of 1st computation
      
                        //Emotionally Numb subscale 1
                          let Total_EmotionallyNumb_Subscale1 = low_percentile_score_ER4 + low_percentile_score_ER1 + low_percentile_score_ER6
                          let Mean_EmotionallyNumb_Subscale1 = Total_EmotionallyNumb_Subscale1 / 3
                          if(Mean_EmotionallyNumb_Subscale1 <= 20 && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Very_High)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/100.png")
                            $(".score-Belief-in-myself").text("Very High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 20.1 || Mean_EmotionallyNumb_Subscale1 <= 40) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.High)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/80.png")
                            $(".score-Belief-in-myself").text("High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 40.1 || Mean_EmotionallyNumb_Subscale1 <= 60) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Moderate)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/60.png")
                            $(".score-Belief-in-myself").text("Moderate")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 60.1 || Mean_EmotionallyNumb_Subscale1 <= 80) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Low)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/40.png")
                            $(".score-Belief-in-myself").text("Low")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 80.1 || Mean_EmotionallyNumb_Subscale1 >= 80) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Very_Low)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/20.png")
                            $(".score-Belief-in-myself").text("Very Low")
                          }
      
                        //Emotionally Numb subscale 2
                          let Total_EmotionallyNumb_Subscale2 = low_percentile_score_ER2 + low_percentile_score_ER3 + low_percentile_score_ER5
                          let Mean_EmotionallyNumb_Subscale2 = Total_EmotionallyNumb_Subscale2 / 3
                          if(Mean_EmotionallyNumb_Subscale2 <= 20 && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Very_High)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/100.png")
                            $(".score-Confidence-in-myself").text("Very High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 20.1 || Mean_EmotionallyNumb_Subscale2 <= 40) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.High)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/80.png")
                            $(".score-Confidence-in-myself").text("High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 40.1 || Mean_EmotionallyNumb_Subscale2 <= 60) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Moderate)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/60.png")
                            $(".score-Confidence-in-myself").text("Moderate")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 60.1 || Mean_EmotionallyNumb_Subscale2 <= 80) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Low)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/40.png")
                            $(".score-Confidence-in-myself").text("Low")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 80.1 || Mean_EmotionallyNumb_Subscale2 >= 80) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Very_Low)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/20.png")
                            $(".score-Confidence-in-myself").text("Very Low")
                          }
                        
                        // Disappearing
                          let low_percentile_score_E = 0 
                          let low_percentile_score_E1 = 0
                          let low_percentile_score_E2 = 0
                          let low_percentile_score_E3 = 0
                          let low_percentile_score_E4 = 0
                          let low_percentile_score_E5 = 0
                          let low_percentile_score_E6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.2
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 1.2
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 1.2
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 1.2
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 1.2
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 1.2
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.0
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 1.0
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 1.0
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 1.0
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 1.0
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 1.0
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_E = res.data[0].percentile_E * 0.8
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 0.8
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 0.8
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 0.8
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 0.8
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 0.8
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 0.8
                          }
                        //End of 1st computation
      
                        //Disappearing subscale 1
                          let Total_Disappearing_Subscale1 = low_percentile_score_E3 + low_percentile_score_E4 + low_percentile_score_E5
                          let Mean_Disappearing_Subscale1 = Total_Disappearing_Subscale1 / 3
                          if(Mean_Disappearing_Subscale1 <= 20 && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Very_High)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/100.png")
                            $(".score-Avoiding-the-team").text("Very High")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 20.1 || Mean_Disappearing_Subscale1 <= 40) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.High)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/80.png")
                            $(".score-Avoiding-the-team").text("High")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 40.1 || Mean_Disappearing_Subscale1 <= 60) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Moderate)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/60.png")
                            $(".score-Avoiding-the-team").text("Moderate")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 60.1 || Mean_Disappearing_Subscale1 <= 80) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Low)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/40.png")
                            $(".score-Avoiding-the-team").text("Low")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 80.1 || Mean_Disappearing_Subscale1 >= 80) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Very_Low)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/20.png")
                            $(".score-Avoiding-the-team").text("Very Low")
                          }
      
                        //Disappearing subscale 2
                          let Total_Disappearing_Subscale2 = low_percentile_score_E1 + low_percentile_score_E2 + low_percentile_score_E6
                          let Mean_Disappearing_Subscale2 = Total_Disappearing_Subscale2 / 3
                          if(Mean_Disappearing_Subscale2 <= 20 && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Very_High)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/100.png")
                            $(".score-Disengaging-the-team").text("Very High")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 20.1 || Mean_Disappearing_Subscale2 <= 40) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.High)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/80.png")
                            $(".score-Disengaging-the-team").text("High")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 40.1 || Mean_Disappearing_Subscale2 <= 60) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Moderate)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/60.png")
                            $(".score-Disengaging-the-team").text("Moderate")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 60.1 || Mean_Disappearing_Subscale2 <= 80) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Low)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/40.png")
                            $(".score-Disengaging-the-team").text("Low")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 80.1 || Mean_Disappearing_Subscale2 >= 80) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Very_Low)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/20.png")
                            $(".score-Disengaging-the-team").text("Very Low")
                          }
      
                        // StrictlyLiteral
                          let low_percentile_score_O = 0 
                          let low_percentile_score_O1 = 0
                          let low_percentile_score_O2 = 0
                          let low_percentile_score_O3 = 0
                          let low_percentile_score_O4 = 0
                          let low_percentile_score_O5 = 0
                          let low_percentile_score_O6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.2
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 1.2
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 1.2
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 1.2
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 1.2
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 1.2
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.0
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 1.0
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 1.0
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 1.0
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 1.0
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 1.0
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_O = res.data[0].percentile_O * 0.8
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 0.8
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 0.8
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 0.8
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 0.8
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 0.8
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 0.8
                          }
                        //End of 1st computation
      
                        //StrictlyLiteral subscale 1
                          let Total_StrictlyLiteral_Subscale1 = low_percentile_score_O1 + low_percentile_score_O2 + low_percentile_score_O5
                          let Mean_StrictlyLiteral_Subscale1 = Total_StrictlyLiteral_Subscale1 / 3
                          if(Mean_StrictlyLiteral_Subscale1 <= 20 && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Very_High)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/100.png")
                            $(".score-Sticking-to-the-data").text("Very High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 20.1 || Mean_StrictlyLiteral_Subscale1 <= 40) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.High)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/80.png")
                            $(".score-Sticking-to-the-data").text("High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 40.1 || Mean_StrictlyLiteral_Subscale1 <= 60) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Moderate)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/60.png")
                            $(".score-Sticking-to-the-data").text("Moderate")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 60.1 || Mean_StrictlyLiteral_Subscale1 <= 80) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Low)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/40.png")
                            $(".score-Sticking-to-the-data").text("Low")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 80.1 || Mean_StrictlyLiteral_Subscale1 >= 80) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Very_Low)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/20.png")
                            $(".score-Sticking-to-the-data").text("Very Low")
                          }
      
                        //StrictlyLiteral subscale 2
                          let Total_StrictlyLiteral_Subscale2 = low_percentile_score_O3 + low_percentile_score_O4 + low_percentile_score_O6
                          let Mean_StrictlyLiteral_Subscale2 = Total_StrictlyLiteral_Subscale2 / 3
                          if(Mean_StrictlyLiteral_Subscale2 <= 20 && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Very_High)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/100.png")
                            $(".score-Proven-ideas").text("Very High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 20.1 || Mean_StrictlyLiteral_Subscale2 <= 40) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.High)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/80.png")
                            $(".score-Proven-ideas").text("High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 40.1 || Mean_StrictlyLiteral_Subscale2 <= 60) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Moderate)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/60.png")
                            $(".score-Proven-ideas").text("Moderate")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 60.1 || Mean_StrictlyLiteral_Subscale2 <= 80) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Low)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/40.png")
                            $(".score-Proven-ideas").text("Low")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 80.1 || Mean_StrictlyLiteral_Subscale2 >= 80) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Very_Low)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/20.png")
                            $(".score-Proven-ideas").text("Very Low")
                          }
      
                        // ColdlyLogical
                          let low_percentile_score_A = 0 
                          let low_percentile_score_A1 = 0
                          let low_percentile_score_A2 = 0
                          let low_percentile_score_A3 = 0
                          let low_percentile_score_A4 = 0
                          let low_percentile_score_A5 = 0
                          let low_percentile_score_A6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.2
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 1.2
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 1.2
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 1.2
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 1.2
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 1.2
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.0
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 1.0
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 1.0
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 1.0
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 1.0
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 1.0
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_A = res.data[0].percentile_A * 0.8
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 0.8
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 0.8
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 0.8
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 0.8
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 0.8
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 0.8
                          }
                        //End of 1st computation
      
                        //ColdlyLogical subscale 1
                          let Total_ColdlyLogical_Subscale1 = low_percentile_score_A1 + low_percentile_score_A2 + low_percentile_score_A5
                          let Mean_ColdlyLogical_Subscale1 = Total_ColdlyLogical_Subscale1 / 3
                          if(Mean_ColdlyLogical_Subscale1 <= 20 && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Very_High)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/100.png")
                            $(".score-Self-protection").text("Very High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 20.1 || Mean_ColdlyLogical_Subscale1 <= 40) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.High)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/80.png")
                            $(".score-Self-protection").text("High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 40.1 || Mean_ColdlyLogical_Subscale1 <= 60) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Moderate)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/60.png")
                            $(".score-Self-protection").text("Moderate")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 60.1 || Mean_ColdlyLogical_Subscale1 <= 80) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Low)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/40.png")
                            $(".score-Self-protection").text("Low")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 80.1 || Mean_ColdlyLogical_Subscale1 >= 80) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Very_Low)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/20.png")
                            $(".score-Self-protection").text("Very Low")
                          }
      
                        //ColdlyLogical subscale 2
                          let Total_ColdlyLogical_Subscale2 = low_percentile_score_A3 + low_percentile_score_A4 + low_percentile_score_A6
                          let Mean_ColdlyLogical_Subscale2 = Total_ColdlyLogical_Subscale2 / 3
                          if(Mean_ColdlyLogical_Subscale2 <= 20 && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Very_High)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/100.png")
                            $(".score-Self-preservation").text("Very High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 20.1 || Mean_ColdlyLogical_Subscale2 <= 40) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.High)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/80.png")
                            $(".score-Self-preservation").text("High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 40.1 || Mean_ColdlyLogical_Subscale2 <= 60) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Moderate)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/60.png")
                            $(".score-Self-preservation").text("Moderate")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 60.1 || Mean_ColdlyLogical_Subscale2 <= 80) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Low)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/40.png")
                            $(".score-Self-preservation").text("Low")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 80.1 || Mean_ColdlyLogical_Subscale2 >= 80) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Very_Low)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/20.png")
                            $(".score-Self-preservation").text("Very Low")
                          }
      
                        // Pliable
                          let low_percentile_score_C = 0 
                          let low_percentile_score_C1 = 0
                          let low_percentile_score_C2 = 0
                          let low_percentile_score_C3 = 0
                          let low_percentile_score_C4 = 0
                          let low_percentile_score_C5 = 0
                          let low_percentile_score_C6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.2
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 1.2
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 1.2
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 1.2
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 1.2
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 1.2
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.0
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 1.0
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 1.0
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 1.0
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 1.0
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 1.0
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_C = res.data[0].percentile_C * 0.8
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 0.8
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 0.8
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 0.8
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 0.8
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 0.8
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 0.8
                          }
                        //End of 1st computation
      
                        //Pliable subscale 1
                          let Total_Pliable_Subscale1 = low_percentile_score_C3 + low_percentile_score_C4 + low_percentile_score_C5
                          let Mean_Pliable_Subscale1 = Total_Pliable_Subscale1 / 3
                          if(Mean_Pliable_Subscale1 <= 20 && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Very_High)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/100.png")
                            $(".score-Changing-responsibility").text("Very High")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 20.1 || Mean_Pliable_Subscale1 <= 40) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.High)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/80.png")
                            $(".score-Changing-responsibility").text("High")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 40.1 || Mean_Pliable_Subscale1 <= 60) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Moderate)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/60.png")
                            $(".score-Changing-responsibility").text("Moderate")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 60.1 || Mean_Pliable_Subscale1 <= 80) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Low)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/40.png")
                            $(".score-Changing-responsibility").text("Low")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 80.1 || Mean_Pliable_Subscale1 >= 80) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Very_Low)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/20.png")
                            $(".score-Changing-responsibility").text("Very Low")
                          }
      
                        //Pliable subscale 2
                          let Total_Pliable_Subscale2 = low_percentile_score_C2 + low_percentile_score_C1 + low_percentile_score_C6
                          let Mean_Pliable_Subscale2 = Total_Pliable_Subscale2 / 3
                          if(Mean_Pliable_Subscale2 <= 20 && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Very_High)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/100.png")
                            $(".score-Changing-structure").text("Very High")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 20.1 || Mean_Pliable_Subscale2 <= 40) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.High)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/80.png")
                            $(".score-Changing-structure").text("High")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 40.1 || Mean_Pliable_Subscale2 <= 60) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Moderate)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/60.png")
                            $(".score-Changing-structure").text("Moderate")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 60.1 || Mean_Pliable_Subscale2 <= 80) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Low)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/40.png")
                            $(".score-Changing-structure").text("Low")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 80.1 || Mean_Pliable_Subscale2 >= 80) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Very_Low)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/20.png")
                            $(".score-Changing-structure").text("Very Low")
                          }
      
                        // HIGH
                        // Emotive
                          let high_percentile_score_ER = 0 
                          let high_percentile_score_ER1 = 0
                          let high_percentile_score_ER2 = 0
                          let high_percentile_score_ER3 = 0
                          let high_percentile_score_ER4 = 0
                          let high_percentile_score_ER5 = 0
                          let high_percentile_score_ER6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_ER = res.data[0].percentile_ER * .8
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * .8
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * .8
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * .8
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * .8
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * .8
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.0
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.0
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.0
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.0
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.0
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.0
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.2
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.2
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.2
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.2
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.2
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.2
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.2
                          }
                        //End of 1st computation
      
                        //Emotive subscale 1
                          let Total_Emotive_Subscale1 = high_percentile_score_ER4 + high_percentile_score_ER1 + high_percentile_score_ER6
                          let Mean_Emotive_Subscale1 = Total_Emotive_Subscale1 / 3
                          if(Mean_Emotive_Subscale1 <= 20 && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Very_Low)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/20.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Very Low")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 20.1 || Mean_Emotive_Subscale1 <= 40) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Low)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/40.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Low")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 40.1 || Mean_Emotive_Subscale1 <= 60) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Moderate)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/60.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Moderate")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 60.1 || Mean_Emotive_Subscale1 <= 80) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.High)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/80.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("High")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 80.1 || Mean_Emotive_Subscale1 >= 80) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Very_High)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/100.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Very High")
                          }
      
                        //Emotive subscale 2
                          let Total_Emotive_Subscale2 = high_percentile_score_ER2 + high_percentile_score_ER3 + high_percentile_score_ER5
                          let Mean_Emotive_Subscale2 = Total_Emotive_Subscale2 / 3
                          if(Mean_Emotive_Subscale2 <= 20 && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Very_Low)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/20.png")
                            $(".score-Feeling-Negative-Thoughts").text("Very Low")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 20.1 || Mean_Emotive_Subscale2 <= 40) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Low)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/40.png")
                            $(".score-Feeling-Negative-Thoughts").text("Low")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 40.1 || Mean_Emotive_Subscale2 <= 60) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Moderate)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/60.png")
                            $(".score-Feeling-Negative-Thoughts").text("Moderate")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 60.1 || Mean_Emotive_Subscale2 <= 80) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.High)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/80.png")
                            $(".score-Feeling-Negative-Thoughts").text("High")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 80.1 || Mean_Emotive_Subscale2 >= 80) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Very_High)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/100.png")
                            $(".score-Feeling-Negative-Thoughts").text("Very High")
                          }
      
                        // Dominating
                          let high_percentile_score_E = 0 
                          let high_percentile_score_E1 = 0
                          let high_percentile_score_E2 = 0
                          let high_percentile_score_E3 = 0
                          let high_percentile_score_E4 = 0
                          let high_percentile_score_E5 = 0
                          let high_percentile_score_E6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_E = res.data[0].percentile_E * .8
                            high_percentile_score_E1 = res.data[0].percentile_E1 * .8
                            high_percentile_score_E2 = res.data[0].percentile_E2 * .8
                            high_percentile_score_E3 = res.data[0].percentile_E3 * .8
                            high_percentile_score_E4 = res.data[0].percentile_E4 * .8
                            high_percentile_score_E5 = res.data[0].percentile_E5 * .8
                            high_percentile_score_E6 = res.data[0].percentile_E6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.0
                            high_percentile_score_E1 = res.data[0].percentile_E1 * 1.0
                            high_percentile_score_E2 = res.data[0].percentile_E2 * 1.0
                            high_percentile_score_E3 = res.data[0].percentile_E3 * 1.0
                            high_percentile_score_E4 = res.data[0].percentile_E4 * 1.0
                            high_percentile_score_E5 = res.data[0].percentile_E5 * 1.0
                            high_percentile_score_E6 = res.data[0].percentile_E6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.2
                            high_percentile_score_E1 = res.data[0].percentile_E1 * 1.2
                            high_percentile_score_E2 = res.data[0].percentile_E2 * 1.2
                            high_percentile_score_E3 = res.data[0].percentile_E3 * 1.2
                            high_percentile_score_E4 = res.data[0].percentile_E4 * 1.2
                            high_percentile_score_E5 = res.data[0].percentile_E5 * 1.2
                            high_percentile_score_E6 = res.data[0].percentile_E6 * 1.2
                          }
                        //End of 1st computation
      
                        //Dominating subscale 1
                          let Total_Dominating_Subscale1 = high_percentile_score_E3 + high_percentile_score_E4 + high_percentile_score_E5
                          let Mean_Dominating_Subscale1 = Total_Dominating_Subscale1 / 3
                          if(Mean_Dominating_Subscale1 <= 20 && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Very_Low)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/20.png")
                            $(".score-Controlling-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 20.1 || Mean_Dominating_Subscale1 <= 40) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Low)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/40.png")
                            $(".score-Controlling-the-Team").text("Low")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 40.1 || Mean_Dominating_Subscale1 <= 60) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Moderate)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/60.png")
                            $(".score-Controlling-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 60.1 || Mean_Dominating_Subscale1 <= 80) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.High)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/80.png")
                            $(".score-Controlling-the-Team").text("High")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 80.1 || Mean_Dominating_Subscale1 >= 80) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Very_High)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/100.png")
                            $(".score-Controlling-the-Team").text("Very High")
                          }
      
                        //Dominating subscale 2
                          let Total_Dominating_Subscale2 = high_percentile_score_E1 + high_percentile_score_E2 + high_percentile_score_E6
                          let Mean_Dominating_Subscale2 = Total_Dominating_Subscale2 / 3
                          if(Mean_Dominating_Subscale2 <= 20 && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Very_Low)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/20.png")
                            $(".score-Engaging-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 20.1 || Mean_Dominating_Subscale2 <= 40) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Low)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/40.png")
                            $(".score-Engaging-the-Team").text("Low")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 40.1 || Mean_Dominating_Subscale2 <= 60) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Moderate)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/60.png")
                            $(".score-Engaging-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 60.1 || Mean_Dominating_Subscale2 <= 80) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.High)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/80.png")
                            $(".score-Engaging-the-Team").text("High")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 80.1 || Mean_Dominating_Subscale2 >= 80) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Very_High)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/100.png")
                            $(".score-Engaging-the-Team").text("Very High")
                          }
      
                        // Dreaming
                          let high_percentile_score_O = 0 
                          let high_percentile_score_O1 = 0
                          let high_percentile_score_O2 = 0
                          let high_percentile_score_O3 = 0
                          let high_percentile_score_O4 = 0
                          let high_percentile_score_O5 = 0
                          let high_percentile_score_O6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_O = res.data[0].percentile_O * .8
                            high_percentile_score_O1 = res.data[0].percentile_O1 * .8
                            high_percentile_score_O2 = res.data[0].percentile_O2 * .8
                            high_percentile_score_O3 = res.data[0].percentile_O3 * .8
                            high_percentile_score_O4 = res.data[0].percentile_O4 * .8
                            high_percentile_score_O5 = res.data[0].percentile_O5 * .8
                            high_percentile_score_O6 = res.data[0].percentile_O6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.0
                            high_percentile_score_O1 = res.data[0].percentile_O1 * 1.0
                            high_percentile_score_O2 = res.data[0].percentile_O2 * 1.0
                            high_percentile_score_O3 = res.data[0].percentile_O3 * 1.0
                            high_percentile_score_O4 = res.data[0].percentile_O4 * 1.0
                            high_percentile_score_O5 = res.data[0].percentile_O5 * 1.0
                            high_percentile_score_O6 = res.data[0].percentile_O6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.2
                            high_percentile_score_O1 = res.data[0].percentile_O1 * 1.2
                            high_percentile_score_O2 = res.data[0].percentile_O2 * 1.2
                            high_percentile_score_O3 = res.data[0].percentile_O3 * 1.2
                            high_percentile_score_O4 = res.data[0].percentile_O4 * 1.2
                            high_percentile_score_O5 = res.data[0].percentile_O5 * 1.2
                            high_percentile_score_O6 = res.data[0].percentile_O6 * 1.2
                          }
                        //End of 1st computation
      
                        //Dreaming subscale 1
                          let Total_Dreaming_Subscale1 = high_percentile_score_O1 + high_percentile_score_O2 + high_percentile_score_O5
                          let Mean_Dreaming_Subscale1 = Total_Dreaming_Subscale1 / 3
                          if(Mean_Dreaming_Subscale1 <= 20 && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Very_Low)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/20.png")
                            $(".score-Lost-in-Ideas").text("Very Low")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 20.1 || Mean_Dreaming_Subscale1 <= 40) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Low)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/40.png")
                            $(".score-Lost-in-Ideas").text("Low")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 40.1 || Mean_Dreaming_Subscale1 <= 60) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Moderate)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/60.png")
                            $(".score-Lost-in-Ideas").text("Moderate")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 60.1 || Mean_Dreaming_Subscale1 <= 80) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.High)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/80.png")
                            $(".score-Lost-in-Ideas").text("High")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 80.1 || Mean_Dreaming_Subscale1 >= 80) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Very_High)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/100.png")
                            $(".score-Lost-in-Ideas").text("Very High")
                          }
      
                        //Dreaming subscale 2
                          let Total_Dreaming_Subscale2 = high_percentile_score_O3 + high_percentile_score_O4 + high_percentile_score_O6
                          let Mean_Dreaming_Subscale2 = Total_Dreaming_Subscale2 / 3
                          if(Mean_Dreaming_Subscale2 <= 20 && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Very_Low)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/20.png")
                            $(".score-Being-Dramatic").text("Very Low")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 20.1 || Mean_Dreaming_Subscale2 <= 40) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Low)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/40.png")
                            $(".score-Being-Dramatic").text("Low")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 40.1 || Mean_Dreaming_Subscale2 <= 60) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Moderate)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/60.png")
                            $(".score-Being-Dramatic").text("Moderate")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 60.1 || Mean_Dreaming_Subscale2 <= 80) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.High)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/80.png")
                            $(".score-Being-Dramatic").text("High")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 80.1 || Mean_Dreaming_Subscale2 >= 80) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Very_High)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/100.png")
                            $(".score-Being-Dramatic").text("Very High")
                          }
      
                        // Amenable
                          let high_percentile_score_A = 0 
                          let high_percentile_score_A1 = 0
                          let high_percentile_score_A2 = 0
                          let high_percentile_score_A3 = 0
                          let high_percentile_score_A4 = 0
                          let high_percentile_score_A5 = 0
                          let high_percentile_score_A6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_A = res.data[0].percentile_A * .8
                            high_percentile_score_A1 = res.data[0].percentile_A1 * .8
                            high_percentile_score_A2 = res.data[0].percentile_A2 * .8
                            high_percentile_score_A3 = res.data[0].percentile_A3 * .8
                            high_percentile_score_A4 = res.data[0].percentile_A4 * .8
                            high_percentile_score_A5 = res.data[0].percentile_A5 * .8
                            high_percentile_score_A6 = res.data[0].percentile_A6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.0
                            high_percentile_score_A1 = res.data[0].percentile_A1 * 1.0
                            high_percentile_score_A2 = res.data[0].percentile_A2 * 1.0
                            high_percentile_score_A3 = res.data[0].percentile_A3 * 1.0
                            high_percentile_score_A4 = res.data[0].percentile_A4 * 1.0
                            high_percentile_score_A5 = res.data[0].percentile_A5 * 1.0
                            high_percentile_score_A6 = res.data[0].percentile_A6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.2
                            high_percentile_score_A1 = res.data[0].percentile_A1 * 1.2
                            high_percentile_score_A2 = res.data[0].percentile_A2 * 1.2
                            high_percentile_score_A3 = res.data[0].percentile_A3 * 1.2
                            high_percentile_score_A4 = res.data[0].percentile_A4 * 1.2
                            high_percentile_score_A5 = res.data[0].percentile_A5 * 1.2
                            high_percentile_score_A6 = res.data[0].percentile_A6 * 1.2
                          }
                        //End of 1st computation
                        //Amenable subscale 1
                          let Total_Amenable_Subscale1 = high_percentile_score_A1 + high_percentile_score_A2 + high_percentile_score_A5
                          let Mean_Amenable_Subscale1 = Total_Amenable_Subscale1 / 3
                          if(Mean_Amenable_Subscale1 <= 20 && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Very_Low)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/20.png")
                            $(".score-Trust-in-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 20.1 || Mean_Amenable_Subscale1 <= 40) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Low)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/40.png")
                            $(".score-Trust-in-the-Team").text("Low")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 40.1 || Mean_Amenable_Subscale1 <= 60) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Moderate)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/60.png")
                            $(".score-Trust-in-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 60.1 || Mean_Amenable_Subscale1 <= 80) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.High)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/80.png")
                            $(".score-Trust-in-the-Team").text("High")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 80.1 || Mean_Amenable_Subscale1 >= 80) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Very_High)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/100.png")
                            $(".score-Trust-in-the-Team").text("Very High")
                          }
      
                        //Amenable subscale 2
                          let Total_Amenable_Subscale2 = high_percentile_score_A3 + high_percentile_score_A4 + high_percentile_score_A6
                          let Mean_Amenable_Subscale2 = Total_Amenable_Subscale2 / 3
                          if(Mean_Amenable_Subscale2 <= 20 && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Very_Low)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/20.png")
                            $(".score-Self-versus-others-interests").text("Very Low")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 20.1 || Mean_Amenable_Subscale2 <= 40) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Low)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/40.png")
                            $(".score-Self-versus-others-interests").text("Low")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 40.1 || Mean_Amenable_Subscale2 <= 60) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Moderate)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/60.png")
                            $(".score-Self-versus-others-interests").text("Moderate")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 60.1 || Mean_Amenable_Subscale2 <= 80) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.High)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/80.png")
                            $(".score-Self-versus-others-interests").text("High")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 80.1 || Mean_Amenable_Subscale2 >= 80) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Very_High)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/100.png")
                            $(".score-Self-versus-others-interests").text("Very High")
                          }
      
                        // Conforming
                          let high_percentile_score_C = 0 
                          let high_percentile_score_C1 = 0
                          let high_percentile_score_C2 = 0
                          let high_percentile_score_C3 = 0
                          let high_percentile_score_C4 = 0
                          let high_percentile_score_C5 = 0
                          let high_percentile_score_C6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_C = res.data[0].percentile_C * .8
                            high_percentile_score_C1 = res.data[0].percentile_C1 * .8
                            high_percentile_score_C2 = res.data[0].percentile_C2 * .8
                            high_percentile_score_C3 = res.data[0].percentile_C3 * .8
                            high_percentile_score_C4 = res.data[0].percentile_C4 * .8
                            high_percentile_score_C5 = res.data[0].percentile_C5 * .8
                            high_percentile_score_C6 = res.data[0].percentile_C6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.0
                            high_percentile_score_C1 = res.data[0].percentile_C1 * 1.0
                            high_percentile_score_C2 = res.data[0].percentile_C2 * 1.0
                            high_percentile_score_C3 = res.data[0].percentile_C3 * 1.0
                            high_percentile_score_C4 = res.data[0].percentile_C4 * 1.0
                            high_percentile_score_C5 = res.data[0].percentile_C5 * 1.0
                            high_percentile_score_C6 = res.data[0].percentile_C6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.2
                            high_percentile_score_C1 = res.data[0].percentile_C1 * 1.2
                            high_percentile_score_C2 = res.data[0].percentile_C2 * 1.2
                            high_percentile_score_C3 = res.data[0].percentile_C3 * 1.2
                            high_percentile_score_C4 = res.data[0].percentile_C4 * 1.2
                            high_percentile_score_C5 = res.data[0].percentile_C5 * 1.2
                            high_percentile_score_C6 = res.data[0].percentile_C6 * 1.2
                          }
                        //End of 1st computation
      
                        //Conforming subscale 1
                          let Total_Conforming_Subscale1 = high_percentile_score_C3 + high_percentile_score_C4 + high_percentile_score_C5
                          let Mean_Conforming_Subscale1 = Total_Conforming_Subscale1 / 3
                          if(Mean_Conforming_Subscale1 <= 20 && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Very_Low)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/20.png")
                            $(".score-Compliance").text("Very Low")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 20.1 || Mean_Conforming_Subscale1 <= 40) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Low)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/40.png")
                            $(".score-Compliance").text("Low")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 40.1 || Mean_Conforming_Subscale1 <= 60) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Moderate)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/60.png")
                            $(".score-Compliance").text("Moderate")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 60.1 || Mean_Conforming_Subscale1 <= 80) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.High)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/80.png")
                            $(".score-Compliance").text("High")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 80.1 || Mean_Conforming_Subscale1 >= 80) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Very_High)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/100.png")
                            $(".score-Compliance").text("Very High")
                          }
      
                        //Conforming subscale 2
                          let Total_Conforming_Subscale2 = high_percentile_score_C2 + high_percentile_score_C1 + high_percentile_score_C6
                          let Mean_Conforming_Subscale2 = Total_Conforming_Subscale2 / 3
                          if(Mean_Conforming_Subscale2 <= 20 && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Very_Low)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/20.png")
                            $(".score-Structure").text("Very Low")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 20.1 || Mean_Conforming_Subscale2 <= 40) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Low)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/40.png")
                            $(".score-Structure").text("Low")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 40.1 || Mean_Conforming_Subscale2 <= 60) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Moderate)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/60.png")
                            $(".score-Structure").text("Moderate")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 60.1 || Mean_Conforming_Subscale2 <= 80) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.High)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/80.png")
                            $(".score-Structure").text("High")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 80.1 || Mean_Conforming_Subscale2 >= 80) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Very_High)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/100.png")
                            $(".score-Structure").text("Very High")
                          }
      
                        //End of Pressure Point
                        }            
      
                           
                        for (const data of jsonData) {      
                          // ER
                          if (res.data[0].z_score_desc_ER === "VERY HIGH" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very high.' + ' ' + '`' + `))
                            $("#replace-emotional-reactiveness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER === "HIGH" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is high.' + ' ' + '`' + `))
                            $("#replace-emotional-reactiveness").append(data.High)
                          } else if (res.data[0].z_score_desc_ER === "TYPICAL" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is typical.' + ' ' + '`' + `))
                            $("#replace-emotional-reactiveness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_ER === "LOW" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is low.' + ' ' + '`' + `))
                            $("#replace-emotional-reactiveness").append(data.Low)
                          } else if (res.data[0].z_score_desc_ER === "VERY LOW" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text($("#replace-emotional-reactiveness").text().replace(/&Emotional Reactiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very low.' + ' ' + '`' + `))
                            $("#replace-emotional-reactiveness").append(data.Very_low)
                          }
      
                          if (res.data[0].z_score_desc_ER1 === "VERY HIGH" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very high' + '`' + `)
                            $("#anxiety").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER1 === "HIGH" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is high' + '`' + `)
                            $("#anxiety").text(data.High)
                          } else if (res.data[0].z_score_desc_ER1 === "TYPICAL" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is typical' + '`' + `)
                            $("#anxiety").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER1 === "LOW" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is low' + '`' + `)
                            $("#anxiety").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER1 === "VERY LOW" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very low' + '`' + `)
                            $("#anxiety").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER2 === "VERY HIGH" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very high' + '`' + `)
                            $("#anger").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER2 === "HIGH" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is high' + '`' + `)
                            $("#anger").text(data.High)
                          } else if (res.data[0].z_score_desc_ER2 === "TYPICAL" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is typical' + '`' + `)
                            $("#anger").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER2 === "LOW" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is low' + '`' + `)
                            $("#anger").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER2 === "VERY LOW" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very low' + '`' + `)
                            $("#anger").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_ER3 === "VERY HIGH" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very high' + '`' + `)
                            $("#sadness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER3 === "HIGH" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is high' + '`' + `)
                            $("#sadness").text(data.High)
                          } else if (res.data[0].z_score_desc_ER3 === "TYPICAL" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is typical' + '`' + `)
                            $("#sadness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER3 === "LOW" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is low' + '`' + `)
                            $("#sadness").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER3 === "VERY LOW" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very low' + '`' + `)
                            $("#sadness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER4 === "VERY HIGH" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very high' + '`' + `)
                            $("#self-consciousness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER4 === "HIGH" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is high' + '`' + `)
                            $("#self-consciousness").text(data.High)
                          } else if (res.data[0].z_score_desc_ER4 === "TYPICAL" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is typical' + '`' + `)
                            $("#self-consciousness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER4 === "LOW" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is low' + '`' + `)
                            $("#self-consciousness").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER4 === "VERY LOW" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very low' + '`' + `)
                            $("#self-consciousness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER5 === "VERY HIGH" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very high' + '`' + `)
                            $("#self-indulgence").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER5 === "HIGH" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is high' + '`' + `)
                            $("#self-indulgence").text(data.High)
                          } else if (res.data[0].z_score_desc_ER5 === "TYPICAL" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is typical' + '`' + `)
                            $("#self-indulgence").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER5 === "LOW" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is low' + '`' + `)
                            $("#self-indulgence").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER5 === "VERY LOW" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very low' + '`' + `)
                            $("#self-indulgence").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER6 === "VERY HIGH" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very high' + '`' + `))
                            $("#vulnerability").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER6 === "HIGH" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is high' + '`' + `))
                            $("#vulnerability").text(data.High)
                          } else if (res.data[0].z_score_desc_ER6 === "TYPICAL" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is typical' + '`' + `))
                            $("#vulnerability").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER6 === "LOW" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is low' + '`' + `))
                            $("#vulnerability").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER6 === "VERY LOW" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text($("#replace-vulnerability").text().replace(/&Vulnerability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very low' + '`' + `))
                            $("#vulnerability").text(data.Very_Low)
                          }
      
                          // E
                          if (res.data[0].z_score_desc_E === "VERY HIGH" && data.Reference === "E") { 
                            $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very high.' + ' ' + '`' + `))
                            $("#replace-extraversion").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_E === "HIGH" && data.Reference === "E") {
                            $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is high.' + ' ' + '`' + `))
                            $("#replace-extraversion").append(data.High)
                          } else if (res.data[0].z_score_desc_E === "TYPICAL" && data.Reference === "E") {
                            $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is typical.' + ' ' + '`' + `))
                            $("#replace-extraversion").append(data.Typical)
                          } else if (res.data[0].z_score_desc_E === "LOW" && data.Reference === "E") {
                            $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is low.' + ' ' + '`' + `))
                            $("#replace-extraversion").append(data.Low)
                          } else if (res.data[0].z_score_desc_E === "VERY LOW" && data.Reference === "E") {
                            $("#replace-extraversion").text($("#replace-extraversion").text().replace(/&Extraversion/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very low.' + ' ' + '`' + `))
                            $("#replace-extraversion").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E1 === "VERY HIGH" && data.Reference === "E1") { 
                            $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very high' + '`' + `))
                            $("#friendliness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E1 === "HIGH" && data.Reference === "E1") {
                            $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is high' + '`' + `))
                            $("#friendliness").text(data.High)
                          } else if (res.data[0].z_score_desc_E1 === "TYPICAL" && data.Reference === "E1") {
                            $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is typical' + '`' + `))
                            $("#friendliness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E1 === "LOW" && data.Reference === "E1") {
                            $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is low' + '`' + `))
                            $("#friendliness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E1 === "VERY LOW" && data.Reference === "E1") {
                            $("#replace-friendliness").text($("#replace-friendliness").text().replace(/&Friendliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very low' + '`' + `))
                            $("#friendliness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E2 === "VERY HIGH" && data.Reference === "E2") {
                            $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very high' + '`' + `))
                            $("#sociability").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E2 === "HIGH" && data.Reference === "E2") {
                            $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is high' + '`' + `))
                            $("#sociability").text(data.High)
                          } else if (res.data[0].z_score_desc_E2 === "TYPICAL" && data.Reference === "E2") {
                            $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is typical' + '`' + `))
                            $("#sociability").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E2 === "LOW" && data.Reference === "E2") {
                            $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is low' + '`' + `))
                            $("#sociability").text(data.Low)
                          } else if (res.data[0].z_score_desc_E2 === "VERY LOW" && data.Reference === "E2") {
                            $("#replace-sociability").text($("#replace-sociability").text().replace(/&Sociability/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very low' + '`' + `))
                            $("#sociability").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_E3 === "VERY HIGH" && data.Reference === "E3") {
                            $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very high' + '`' + `))
                            $("#assertiveness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E3 === "HIGH" && data.Reference === "E3") {
                            $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is high' + '`' + `))
                            $("#assertiveness").text(data.High)
                          } else if (res.data[0].z_score_desc_E3 === "TYPICAL" && data.Reference === "E3") {
                            $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is typical' + '`' + `))
                            $("#assertiveness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E3 === "LOW" && data.Reference === "E3") {
                            $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is low' + '`' + `))
                            $("#assertiveness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E3 === "VERY LOW" && data.Reference === "E3") {
                            $("#replace-assertiveness").text($("#replace-assertiveness").text().replace(/&Assertiveness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very low' + '`' + `))
                            $("#assertiveness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E4 === "VERY HIGH" && data.Reference === "E4") {
                            $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very high' + '`' + `))
                            $("#activity-level").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E4 === "HIGH" && data.Reference === "E4") {
                            $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is high' + '`' + `))
                            $("#activity-level").text(data.High)
                          } else if (res.data[0].z_score_desc_E4 === "TYPICAL" && data.Reference === "E4") {
                            $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is typical' + '`' + `))
                            $("#activity-level").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E4 === "LOW" && data.Reference === "E4") {
                            $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is low' + '`' + `))
                            $("#activity-level").text(data.Low)
                          } else if (res.data[0].z_score_desc_E4 === "VERY LOW" && data.Reference === "E4") {
                            $("#replace-activity-level").text($("#replace-activity-level").text().replace(/&Activity Level/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very low' + '`' + `))
                            $("#activity-level").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E5 === "VERY HIGH" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very high' + '`' + `))
                            $("#excitement-seeking").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E5 === "HIGH" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is high' + '`' + `))
                            $("#excitement-seeking").text(data.High)
                          } else if (res.data[0].z_score_desc_E5 === "TYPICAL" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is typical' + '`' + `))
                            $("#excitement-seeking").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E5 === "LOW" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is low' + '`' + `))
                            $("#excitement-seeking").text(data.Low)
                          } else if (res.data[0].z_score_desc_E5 === "VERY LOW" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text($("#replace-excitement-seeking").text().replace(/&Excitement-seeking/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very low' + '`' + `))
                            $("#excitement-seeking").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E6 === "VERY HIGH" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very high' + '`' + `))
                            $("#cheerfulness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E6 === "HIGH" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is high' + '`' + `))
                            $("#cheerfulness").text(data.High)
                          } else if (res.data[0].z_score_desc_E6 === "TYPICAL" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is typical' + '`' + `))
                            $("#cheerfulness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E6 === "LOW" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is low' + '`' + `))
                            $("#cheerfulness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E6 === "VERY LOW" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text($("#replace-cheerfulness").text().replace(/&Cheerfulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very low' + '`' + `))
                            $("#cheerfulness").text(data.Very_Low)
                          }
      
                          // O
                          if (res.data[0].z_score_desc_O === "VERY HIGH" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very high.' + ' ' + '`' + `))
                            $("#replace-openness-to-experience").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_O === "HIGH" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is high.' + ' ' + '`' + `))
                            $("#replace-openness-to-experience").append(data.High)
                          } else if (res.data[0].z_score_desc_O === "TYPICAL" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is typical.' + ' ' + '`' + `))
                            $("#replace-openness-to-experience").append(data.Typical)
                          } else if (res.data[0].z_score_desc_O === "LOW" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is low.' + ' ' + '`' + `))
                            $("#replace-openness-to-experience").append(data.Low)
                          } else if (res.data[0].z_score_desc_O === "VERY LOW" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text($("#replace-openness-to-experience").text().replace(/&Openness to Experience/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very low.' + ' ' + '`' + `))
                            $("#replace-openness-to-experience").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O1 === "VERY HIGH" && data.Reference === "O1") {
                            $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very high' + '`' + `))
                            $("#imagination").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O1 === "HIGH" && data.Reference === "O1") {
                            $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is high' + '`' + `))
                            $("#imagination").text(data.High)
                          } else if (res.data[0].z_score_desc_O1 === "TYPICAL" && data.Reference === "O1") {
                            $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is typical' + '`' + `))
                            $("#imagination").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O1 === "LOW" && data.Reference === "O1") {
                            $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is low' + '`' + `))
                            $("#imagination").text(data.Low)
                          } else if (res.data[0].z_score_desc_O1 === "VERY LOW" && data.Reference === "O1") {
                            $("#replace-imagination").text($("#replace-imagination").text().replace(/&Imagination/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very low' + '`' + `))
                            $("#imagination").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O2 === "VERY HIGH" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very high' + '`' + `))
                            $("#artistic-Interests").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O2 === "HIGH" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is high' + '`' + `))
                            $("#artistic-Interests").text(data.High)
                          } else if (res.data[0].z_score_desc_O2 === "TYPICAL" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is typical' + '`' + `))
                            $("#artistic-Interests").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O2 === "LOW" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is low' + '`' + `))
                            $("#artistic-Interests").text(data.Low)
                          } else if (res.data[0].z_score_desc_O2 === "VERY LOW" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text($("#replace-artistic-interests").text().replace(/&Artistic Interests/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very low' + '`' + `))
                            $("#artistic-Interests").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_O3 === "VERY HIGH" && data.Reference === "O3") {
                            $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very high' + '`' + `))
                            $("#emotionality").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O3 === "HIGH" && data.Reference === "O3") {
                            $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is high' + '`' + `))
                            $("#emotionality").text(data.High)
                          } else if (res.data[0].z_score_desc_O3 === "TYPICAL" && data.Reference === "O3") {
                            $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is typical' + '`' + `))
                            $("#emotionality").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O3 === "LOW" && data.Reference === "O3") {
                            $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is low' + '`' + `))
                            $("#emotionality").text(data.Low)
                          } else if (res.data[0].z_score_desc_O3 === "VERY LOW" && data.Reference === "O3") {
                            $("#replace-emotionality").text($("#replace-emotionality").text().replace(/&Emotionality/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very low' + '`' + `))
                            $("#emotionality").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O4 === "VERY HIGH" && data.Reference === "O4") {
                            $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very high' + '`' + `))
                            $("#adventurousness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O4 === "HIGH" && data.Reference === "O4") {
                            $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is high' + '`' + `))
                            $("#adventurousness").text(data.High)
                          } else if (res.data[0].z_score_desc_O4 === "TYPICAL" && data.Reference === "O4") {
                            $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is typical' + '`' + `))
                            $("#adventurousness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O4 === "LOW" && data.Reference === "O4") {
                            $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is low' + '`' + `))
                            $("#adventurousness").text(data.Low)
                          } else if (res.data[0].z_score_desc_O4 === "VERY LOW" && data.Reference === "O4") {
                            $("#replace-adventurousness").text($("#replace-adventurousness").text().replace(/&Adventurousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very low' + '`' + `))
                            $("#adventurousness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O5 === "VERY HIGH" && data.Reference === "O5") {
                            $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very high' + '`' + `))
                            $("#cognition").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O5 === "HIGH" && data.Reference === "O5") {
                            $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is high' + '`' + `))
                            $("#cognition").text(data.High)
                          } else if (res.data[0].z_score_desc_O5 === "TYPICAL" && data.Reference === "O5") {
                            $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is typical' + '`' + `))
                            $("#cognition").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O5 === "LOW" && data.Reference === "O5") {
                            $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is low' + '`' + `))
                            $("#cognition").text(data.Low)
                          } else if (res.data[0].z_score_desc_O5 === "VERY LOW" && data.Reference === "O5") {
                            $("#replace-cognition").text($("#replace-cognition").text().replace(/&Cognition/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very low' + '`' + `))
                            $("#cognition").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O6 === "VERY HIGH" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very high' + '`' + `))
                            $("#broad-mindedness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O6 === "HIGH" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is high' + '`' + `))
                            $("#broad-mindedness").text(data.High)
                          } else if (res.data[0].z_score_desc_O6 === "TYPICAL" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is typical' + '`' + `))
                            $("#broad-mindedness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O6 === "LOW" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is low' + '`' + `))
                            $("#broad-mindedness").text(data.Low)
                          } else if (res.data[0].z_score_desc_O6 === "VERY LOW" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text($("#replace-broad-mindedness").text().replace(/&Broad-mindedness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very low' + '`' + `))
                            $("#broad-mindedness").text(data.Very_Low)
                          }
      
                          // A
                          if (res.data[0].z_score_desc_A === "VERY HIGH" && data.Reference === "A") { 
                            $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very high.' + ' ' + '`' + `))
                            $("#replace-agreeableness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_A === "HIGH" && data.Reference === "A") {
                            $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is high.' + ' ' + '`' + `))
                            $("#replace-agreeableness").append(data.High)
                          } else if (res.data[0].z_score_desc_A === "TYPICAL" && data.Reference === "A") {
                            $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is typical.' + ' ' + '`' + `))
                            $("#replace-agreeableness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_A === "LOW" && data.Reference === "A") {
                            $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is low.' + ' ' + '`' + `))
                            $("#replace-agreeableness").append(data.Low)
                          } else if (res.data[0].z_score_desc_A === "VERY LOW" && data.Reference === "A") {
                            $("#replace-agreeableness").text($("#replace-agreeableness").text().replace(/&Agreeableness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very low.' + ' ' + '`' + `))
                            $("#replace-agreeableness").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A1 === "VERY HIGH" && data.Reference === "A1") { 
                            $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very high' + '`' + `))
                            $("#trust").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A1 === "HIGH" && data.Reference === "A1") {
                            $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is high' + '`' + `))
                            $("#trust").text(data.High)
                          } else if (res.data[0].z_score_desc_A1 === "TYPICAL" && data.Reference === "A1") {
                            $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is typical' + '`' + `))
                            $("#trust").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A1 === "LOW" && data.Reference === "A1") {
                            $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is low' + '`' + `))
                            $("#trust").text(data.Low)
                          } else if (res.data[0].z_score_desc_A1 === "VERY LOW" && data.Reference === "A1") {
                            $("#replace-trust").text($("#replace-trust").text().replace(/&Trust/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very low' + '`' + `))
                            $("#trust").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A2 === "VERY HIGH" && data.Reference === "A2") {
                            $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very high' + '`' + `))
                            $("#candor").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A2 === "HIGH" && data.Reference === "A2") {
                            $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is high' + '`' + `))
                            $("#candor").text(data.High)
                          } else if (res.data[0].z_score_desc_A2 === "TYPICAL" && data.Reference === "A2") {
                            $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is typical' + '`' + `))
                            $("#candor").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A2 === "LOW" && data.Reference === "A2") {
                            $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is low' + '`' + `))
                            $("#candor").text(data.Low)
                          } else if (res.data[0].z_score_desc_A2 === "VERY LOW" && data.Reference === "A2") {
                            $("#replace-candor").text($("#replace-candor").text().replace(/&Candor/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very low' + '`' + `))
                            $("#candor").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_A3 === "VERY HIGH" && data.Reference === "A3") {
                            $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very high' + '`' + `))
                            $("#altruism").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A3 === "HIGH" && data.Reference === "A3") {
                            $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is high' + '`' + `))
                            $("#altruism").text(data.High)
                          } else if (res.data[0].z_score_desc_A3 === "TYPICAL" && data.Reference === "A3") {
                            $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is typical' + '`' + `))
                            $("#altruism").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A3 === "LOW" && data.Reference === "A3") {
                            $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is low' + '`' + `))
                            $("#altruism").text(data.Low)
                          } else if (res.data[0].z_score_desc_A3 === "VERY LOW" && data.Reference === "A3") {
                            $("#replace-altruism").text($("#replace-altruism").text().replace(/&Altruism/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very low' + '`' + `))
                            $("#altruism").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A4 === "VERY HIGH" && data.Reference === "A4") {
                            $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very high' + '`' + `))
                            $("#cooperation").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A4 === "HIGH" && data.Reference === "A4") {
                            $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is high' + '`' + `))
                            $("#cooperation").text(data.High)
                          } else if (res.data[0].z_score_desc_A4 === "TYPICAL" && data.Reference === "A4") {
                            $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is typical' + '`' + `))
                            $("#cooperation").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A4 === "LOW" && data.Reference === "A4") {
                            $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is low' + '`' + `))
                            $("#cooperation").text(data.Low)
                          } else if (res.data[0].z_score_desc_A4 === "VERY LOW" && data.Reference === "A4") {
                            $("#replace-cooperation").text($("#replace-cooperation").text().replace(/&Cooperation/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very low' + '`' + `))
                            $("#cooperation").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A5 === "VERY HIGH" && data.Reference === "A5") {
                            $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very high' + '`' + `))
                            $("#modesty").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A5 === "HIGH" && data.Reference === "A5") {
                            $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is high' + '`' + `))
                            $("#modesty").text(data.High)
                          } else if (res.data[0].z_score_desc_A5 === "TYPICAL" && data.Reference === "A5") {
                            $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is typical' + '`' + `))
                            $("#modesty").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A5 === "LOW" && data.Reference === "A5") {
                            $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is low' + '`' + `))
                            $("#modesty").text(data.Low)
                          } else if (res.data[0].z_score_desc_A5 === "VERY LOW" && data.Reference === "A5") {
                            $("#replace-modesty").text($("#replace-modesty").text().replace(/&Modesty/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very low' + '`' + `))
                            $("#modesty").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A6 === "VERY HIGH" && data.Reference === "A6") {
                            $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very high' + '`' + `))
                            $("#sympathy").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A6 === "HIGH" && data.Reference === "A6") {
                            $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is high' + '`' + `))
                            $("#sympathy").text(data.High)
                          } else if (res.data[0].z_score_desc_A6 === "TYPICAL" && data.Reference === "A6") {
                            $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is typical' + '`' + `))
                            $("#sympathy").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A6 === "LOW" && data.Reference === "A6") {
                            $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is low' + '`' + `))
                            $("#sympathy").text(data.Low)
                          } else if (res.data[0].z_score_desc_A6 === "VERY LOW" && data.Reference === "A6") {
                            $("#replace-sympathy").text($("#replace-sympathy").text().replace(/&Sympathy/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very low' + '`' + `))
                            $("#sympathy").text(data.Very_Low)
                          }
      
                          // C
                          if (res.data[0].z_score_desc_C === "VERY HIGH" && data.Reference === "C") { 
                            $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very high.' + ' ' + '`' + `))
                            $("#replace-conscientiousness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_C === "HIGH" && data.Reference === "C") {
                            $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is high.' + ' ' + '`' + `))
                            $("#replace-conscientiousness").append(data.High)
                          } else if (res.data[0].z_score_desc_C === "TYPICAL" && data.Reference === "C") {
                            $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is typical.' + ' ' + '`' + `))
                            $("#replace-conscientiousness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_C === "LOW" && data.Reference === "C") {
                            $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is low.' + ' ' + '`' + `))
                            $("#replace-conscientiousness").append(data.Low)
                          } else if (res.data[0].z_score_desc_C === "VERY LOW" && data.Reference === "C") {
                            $("#replace-conscientiousness").text($("#replace-conscientiousness").text().replace(/&Conscientiousness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very low.' + ' ' + '`' + `))
                            $("#replace-conscientiousness").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C1 === "VERY HIGH" && data.Reference === "C1") { 
                            $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very high' + '`' + `))
                            $("#self-belief").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C1 === "HIGH" && data.Reference === "C1") {
                            $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is high' + '`' + `))
                            $("#self-belief").text(data.High)
                          } else if (res.data[0].z_score_desc_C1 === "TYPICAL" && data.Reference === "C1") {
                            $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is typical' + '`' + `))
                            $("#self-belief").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C1 === "LOW" && data.Reference === "C1") {
                            $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is low' + '`' + `))
                            $("#self-belief").text(data.Low)
                          } else if (res.data[0].z_score_desc_C1 === "VERY LOW" && data.Reference === "C1") {
                            $("#replace-self-belief").text($("#replace-self-belief").text().replace(/&Self-belief/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very low' + '`' + `))
                            $("#self-belief").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C2 === "VERY HIGH" && data.Reference === "C2") {
                            $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very high' + '`' + `))
                            $("#orderliness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C2 === "HIGH" && data.Reference === "C2") {
                            $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is high' + '`' + `))
                            $("#orderliness").text(data.High)
                          } else if (res.data[0].z_score_desc_C2 === "TYPICAL" && data.Reference === "C2") {
                            $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is typical' + '`' + `))
                            $("#orderliness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C2 === "LOW" && data.Reference === "C2") {
                            $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is low' + '`' + `))
                            $("#orderliness").text(data.Low)
                          } else if (res.data[0].z_score_desc_C2 === "VERY LOW" && data.Reference === "C2") {
                            $("#replace-orderliness").text($("#replace-orderliness").text().replace(/&Orderliness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very low' + '`' + `))
                            $("#orderliness").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_C3 === "VERY HIGH" && data.Reference === "C3") {
                            $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very high' + '`' + `))
                            $("#dutifulness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C3 === "HIGH" && data.Reference === "C3") {
                            $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is high' + '`' + `))
                            $("#dutifulness").text(data.High)
                          } else if (res.data[0].z_score_desc_C3 === "TYPICAL" && data.Reference === "C3") {
                            $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is typical' + '`' + `))
                            $("#dutifulness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C3 === "LOW" && data.Reference === "C3") {
                            $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is low' + '`' + `))
                            $("#dutifulness").text(data.Low)
                          } else if (res.data[0].z_score_desc_C3 === "VERY LOW" && data.Reference === "C3") {
                            $("#replace-dutifulness").text($("#replace-dutifulness").text().replace(/&Dutifulness/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very low' + '`' + `))
                            $("#dutifulness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C4 === "VERY HIGH" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very high' + '`' + `))
                            $("#achievement-striving").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C4 === "HIGH" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is high' + '`' + `))
                            $("#achievement-striving").text(data.High)
                          } else if (res.data[0].z_score_desc_C4 === "TYPICAL" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is typical' + '`' + `))
                            $("#achievement-striving").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C4 === "LOW" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is low' + '`' + `))
                            $("#achievement-striving").text(data.Low)
                          } else if (res.data[0].z_score_desc_C4 === "VERY LOW" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text($("#replace-achievement-striving").text().replace(/&Achievement-striving/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very low' + '`' + `))
                            $("#achievement-striving").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C5 === "VERY HIGH" && data.Reference === "C5") {
                            $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very high' + '`' + `))
                            $("#self-discipline").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C5 === "HIGH" && data.Reference === "C5") {
                            $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is high' + '`' + `))
                            $("#self-discipline").text(data.High)
                          } else if (res.data[0].z_score_desc_C5 === "TYPICAL" && data.Reference === "C5") {
                            $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is typical' + '`' + `))
                            $("#self-discipline").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C5 === "LOW" && data.Reference === "C5") {
                            $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is low' + '`' + `))
                            $("#self-discipline").text(data.Low)
                          } else if (res.data[0].z_score_desc_C5 === "VERY LOW" && data.Reference === "C5") {
                            $("#replace-self-discipline").text($("#replace-self-discipline").text().replace(/&Self-discipline/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very low' + '`' + `))
                            $("#self-discipline").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C6 === "VERY HIGH" && data.Reference === "C6") {
                            $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very high' + '`' + `))
                            $("#planning").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C6 === "HIGH" && data.Reference === "C6") {
                            $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is high' + '`' + `))
                            $("#planning").text(data.High)
                          } else if (res.data[0].z_score_desc_C6 === "TYPICAL" && data.Reference === "C6") {
                            $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is typical' + '`' + `))
                            $("#planning").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C6 === "LOW" && data.Reference === "C6") {
                            $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is low' + '`' + `))
                            $("#planning").text(data.Low)
                          } else if (res.data[0].z_score_desc_C6 === "VERY LOW" && data.Reference === "C6") {
                            $("#replace-planning").text($("#replace-planning").text().replace(/&Planning/g, ` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very low' + '`' + `))
                            $("#planning").text(data.Very_Low)
                          }
                        }
                        
                        `
                        // Script for radar-chart
                        for (let i = 0; i < input.length; i++) {
                          const data = input[i];
                          if (data.Template === 'Radar-Chart') { 
                            let obj = JSON.parse(data.Text)
                            for (const res of obj) {
                              
                              output += `
                              let `+ res.SuperTrait + `Select = document.querySelectorAll('.` + res.SuperTrait + `')
                              `+ res.SuperTrait + `_Chart = new Chart(`+res.SuperTrait+`Select, {
                                type: 'radar',
                                data: {
                                  labels: [
                              `
                                  res.Elements.forEach((response) => {
                                    output += `
                                      "`+response.Description+`",
                                    `
                                  })
                              output += `
          
                                  ],
                                  datasets: [
                                    {
                                      label: '`+ res.label1 +`',
                                      data: [
                                    `
                                    res.Elements.forEach((response) => {
                                      output += `
                                        res.data[0].cohort_` + response.Trait + `,
                                      `
                                    })
                                    output+=`  
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                      borderColor: 'rgb(255, 99, 132)',
                                      pointBackgroundColor: 'rgb(255, 99, 132)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(255, 99, 132)',
                                    },
                                    {
                                      label: '`+res.label2+`',
                                      data: [
                                      `
                                      res.Elements.forEach((response) => {
                                      output += `
                                        res.data[0].ind_` + response.Trait + `,
                                      `
                                      })
                                      output +=`
                                      ],
                                      fill: true,
                                      backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                      borderColor: 'rgb(54, 162, 235)',
                                      pointBackgroundColor: 'rgb(54, 162, 235)',
                                      pointBorderColor: '#fff',
                                      pointHoverBackgroundColor: '#fff',
                                      pointHoverBorderColor: 'rgb(54, 162, 235)',
                                    },
                                  ],
                                },
                                options: {
                                  scales: {
                                    r: {
                                      beginAtZero: true,
                                      min: 0,
                                      max: 5,
                                      ticks: {
                                      stepSize: 1,
                                      }
                                    },
                                  }
                                }
                              })
                              `
                            }
                          }
                        }
                        // Script for Bar-Chart
                        for (let i = 0; i < input.length; i++) {
                          const res = input[i]
                          if (res.Template === 'Bar-Chart') { 
                            let obj = JSON.parse(res.Text)
                            for (const res of obj) {
                              res.Elements.forEach((response) => {
                                output += `
                                  $(".percentile_` + response.Trait + `").html(ordinal(Math.round(getRanking(res.data[0].percentile_` + response.Trait + `))))
                                  $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  $(".cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1))
                                  $(".percentile-` + response.Trait + `").css({ "flex-grow": ` + '`' + '${res.data[0].percentile_' + ``+response.Trait+``+ '}' + '`' + `, "background-color": "#`+ response.Color +`"})
                                  $(".percentile-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - ${res.data[0].percentile_' + ``+response.Trait+``+ '})' + '`' + `})
                                `
                              })
                            }
                          }
                        } 
                  output += `
                      })
                      .catch((error) => {
                        console.log(error)
                        if(error.code == "ERR_NETWORK") {
                          return Swal.fire({
                            icon: 'info',
                            title: 'Oops...',
                            html: '<p>Network Error, Please try again later!</p>',
                            customClass: 'norm-wording'
                          })
                        }
                      })
                })
      
                    $('#adjustNormBtn').click(function() {
                      $("#showAdjustForm").removeClass('d-none')
                      $("#showAdjustBtn").addClass('d-none')
                    })
                    $(document).on('click', '#adjustNormBtnCancel', function() {
                      $("#showAdjustBtn").removeClass('d-none')
                      $("#showAdjustForm").addClass('d-none')
                    })
                    $('#adjustNormBtnSubmit').click(function (){
                      let isChecked = $('#compare_results').is(':checked')
                      let selectedGender = $('#norms_gender').val()
                      let selectedCountry = $('#norms_country').val()
                      let source = "IPIP120"
      
                      if(isChecked) {
                        selectedCountry = ""
                        source = "GLOBAL"
                      }
                      else {
                        selectedCountry = $('#norms_country').val()
                      }
      
                      if(printPDFonlyAndNoData) {
                        console.log('global dapat')
                        selectedCountry = ""
                        source = "GLOBAL"
                      }
                      else {
                        selectedCountry = $('#norms_country').val()
                      }

                      $("#showAdjustBtn").removeClass('d-none')
                      $("#showAdjustForm").addClass('d-none')
      
                      $.getJSON('calculation.json', (jsonData) => {
                      axios
                        .post(
                          '${url}/b5-report-data2',
                          {
                            survey_assignment_id: survey_assignment_id,
                            sex: selectedGender,
                            country: selectedCountry,
                            source: source
                          },
                          config
                        )
                        .then((res) => {
                          console.log(res.data)
                          // catch error if no sex and country in b5_norm_sum ex. if the sex is trans 
                          if(res.data.status == "noData") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                              customClass: 'norm-wording'
                            })
                          }
                          // catch error if there are sex and country in b5_norm_sum but there is no data in other column like er1_mean column
                          if(res.data.status == "noNormingData") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          // catch error if no data with this survey_assignment_id or something went wrong
                          if(res.data.status == "noSurveyData") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>That didn’t go to plan. Please email your own name and email address to help@talentsage.com. We’ll review it and let you know when it’s fine.</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          if(res.data[0].n <= 30){
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>“We are committed to ensuring personality profiles are inclusive. To this end, we collect and improve our data regularly to enhance the comparative datasets available to you. Despite our best efforts, the current country and gender combination does not yet have enough records in common to provide a valid result.</p><p>Please temporarily adjust your country and/or gender to a larger country or similar gender (most similar to your own) to increase the likelihood of a successful temporary norm comparison based on a valid sample size.</p><p>Feel free to return to your report periodically for updated results based on your original country and gender submission."</p>',
                              customClass: 'norm-wording'
                            })
                          }

                          $('#gender_label').text(selectedGender)
                          $('#culture_label').text(selectedCountry)
                          $(".gender-icon").attr("src",` + '`' + 'gender/${selectedGender}.svg' + '`)' + `
                          $(".country-flag").attr("src",` + '`' + 'flags/4x3/${selectedCountry}.svg' + '`)' + `
                          
                          `
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
                            if (data.Template === 'Comparison-Chart-Task-vs-Relationship') { 
                              output += `
                              let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                              let meanTvsR = totalTvsR / 3
                              if(meanTvsR <= 25) {
                                $('.task-vs-relationship-dynamic-title').text("Tasks much more than Relationships")
                                $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/20.jpg")
                              }
                              else if(meanTvsR >= 25.1 && meanTvsR <= 45) {
                                $('.task-vs-relationship-dynamic-title').text("Tasks more than Relationships")
                                $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/40.jpg")
                              }
                              else if(meanTvsR >= 45.1 && meanTvsR <= 55) {
                                $('.task-vs-relationship-dynamic-title').text("Tasks and Relationships equally")
                                $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/60.jpg")
                              }
                              else if(meanTvsR >= 55.1 && meanTvsR <= 75) {
                                $('.task-vs-relationship-dynamic-title').text("Relationships more than Tasks")
                                $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/80.jpg")
                              }
                              else if(meanTvsR >= 75.1 && meanTvsR <= 100) {
                                $('.task-vs-relationship-dynamic-title').text("Relationships much more than Tasks")
                                $(".img-task-vs-relationship").attr("src", "./Chart-Images/Bar/blue/100.jpg")
                              }
                              `
                              break
                            }
                        }
      
                          for (let i = 0; i < input.length; i++) {
                              const data = input[i]
                              if (data.Template === 'Comparison-Chart-Team-vs-Individual') { 
                                output += `
                                let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                                let meanTeamvsIndividual = totalTeamvsIndividual / 3
                                if(meanTeamvsIndividual <= 25) {
                                  $('.team-vs-individual-dynamic-title').text("Autonomy much more than Structure")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/100.jpg")
                                }
                                else if(meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) {
                                  $('.team-vs-individual-dynamic-title').text("Autonomy more than Structure")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/80.jpg")
                                }
                                else if(meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) {
                                  $('.team-vs-individual-dynamic-title').text("Structure and Autonomy equally")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/60.jpg")
                                }
                                else if(meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) {
                                  $('.team-vs-individual-dynamic-title').text("Structure more than Autonomy")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/40.jpg")
                                }
                                else if(meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) {
                                  $('.team-vs-individual-dynamic-title').text("Structure much more than Autonomy")
                                  $(".img-team-vs-individual").attr("src", "./Chart-Images/Bar/green/20.jpg")
                                }
                                `
                                break
                              }
                          }
                          
                          // for TIPping Point
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
                            if (data.Template === 'Speedometer-All-Scale') { 
                              output += `
                                let arrData = []
                                let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                                let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                                let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                                let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                                
                                // Speedometer-Scale-Rationalist
                                if(res.data[0].percentile_A <= 25) {
                                  $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                                  $('.score-rationalist').text("Very High")
                                  num1 = 1
                                  score1 = "Very High"
                                  role1 = "Rationalist"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                                }
                                else if(res.data[0].percentile_A == 25.1 || res.data[0].percentile_A <= 35) {
                                  $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                                  $('.score-rationalist').text("High")
                                  num1 = 2
                                  score1 = "High"
                                  role1 = "Rationalist"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                                }
                                else if(res.data[0].percentile_A == 35.1 || res.data[0].percentile_A <= 45) {
                                  $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                                  $('.score-rationalist').text("Moderate")
                                  num1 = 3
                                  score1 = "Moderate"
                                  role1 = "Rationalist"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                                }
                                else if(res.data[0].percentile_A == 45.1 || res.data[0].percentile_A <= 55) {
                                  $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                                  $('.score-rationalist').text("Low")
                                  num1 = 4
                                  score1 = "Low"
                                  role1 = "Rationalist"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                                }
                                else if(res.data[0].percentile_A == 55.1 || res.data[0].percentile_A <= 100) {
                                  $(".img-rationalist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                                  $('.score-rationalist').text("Very Low")
                                  num1 = 5
                                  score1 = "Very Low"
                                  role1 = "Rationalist"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                                }
      
                                // Speedometer-Scale-Solo-Operator
                                if(res.data[0].percentile_E <= 25) {
                                  $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                                  $('.score-speedometer-scale-solo-operator').text("Very High")
                                  num2 = 1
                                  score2 = "Very High"
                                  role2 = "Solo Operator"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                                }
                                else if(res.data[0].percentile_E == 25.1 || res.data[0].percentile_E <= 35) {
                                  $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                                  $('.score-speedometer-scale-solo-operator').text("High")
                                  num2 = 2
                                  score2 = "High"
                                  role2 = "Solo Operator"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                                }
                                else if(res.data[0].percentile_E == 35.1 || res.data[0].percentile_E <= 45) {
                                  $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                                  $('.score-speedometer-scale-solo-operator').text("Moderate")
                                  num2 = 3
                                  score2 = "Moderate"
                                  role2 = "Solo Operator"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                                }
                                else if(res.data[0].percentile_E == 45.1 || res.data[0].percentile_E <= 55) {
                                  $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                                  $('.score-speedometer-scale-solo-operator').text("Low")
                                  num2 = 4
                                  score2 = "Low"
                                  role2 = "Solo Operator"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                                }
                                else if(res.data[0].percentile_E == 55.1 || res.data[0].percentile_E <= 100) {
                                  $(".img-speedometer-scale-solo-operator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                                  $('.score-speedometer-scale-solo-operator').text("Very Low")
                                  num2 = 5
                                  score2 = "Very Low"
                                  role2 = "Solo Operator"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                                }
      
                                // Speedometer-Scale-Agilator
                                if(res.data[0].percentile_C <= 25) {
                                  $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                                  $('.score-speedometer-scale-agilator').text("Very High")
                                  num3 = 1
                                  score3 = "Very High"
                                  role3 = "Agilator"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                                }
                                else if(res.data[0].percentile_C == 25.1 || res.data[0].percentile_C <= 35) {
                                  $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                                  $('.score-speedometer-scale-agilator').text("High")
                                  num3 = 2
                                  score3 = "High"
                                  role3 = "Agilator"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                                }
                                else if(res.data[0].percentile_C == 35.1 || res.data[0].percentile_C <= 45) {
                                  $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                                  $('.score-speedometer-scale-agilator').text("Moderate")
                                  num3 = 3
                                  score3 = "Moderate"
                                  role3 = "Agilator"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                                }
                                else if(res.data[0].percentile_C == 45.1 || res.data[0].percentile_C <= 55) {
                                  $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                                  $('.score-speedometer-scale-agilator').text("Low")
                                  num3 = 4
                                  score3 = "Low"
                                  role3 = "Agilator"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                                }
                                else if(res.data[0].percentile_C == 55.1 || res.data[0].percentile_C <= 100) {
                                  $(".img-speedometer-scale-agilator").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                                  $('.score-speedometer-scale-agilator').text("Very Low")
                                  num3 = 5
                                  score3 = "Very Low"
                                  role3 = "Agilator"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                                }
      
                                // Speedometer-Scale-Imagineer
                                if(res.data[0].percentile_O <= 45) {
                                  $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                                  $('.score-speedometer-scale-imagineer').text("Very Low")
                                  num4 = 5
                                  score4 = "Very Low"
                                  role4 = "Imagineer"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                                }
                                else if(res.data[0].percentile_O == 45.1 || res.data[0].percentile_O <= 55.0) {
                                  $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                                  $('.score-speedometer-scale-imagineer').text("Low")
                                  num4 = 4
                                  score4 = "Low"
                                  role4 = "Imagineer"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                                }
                                else if(res.data[0].percentile_O == 55.1 || res.data[0].percentile_O <= 65) {
                                  $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                                  $('.score-speedometer-scale-imagineer').text("Moderate")
                                  num4 = 3
                                  score4 = "Moderate"
                                  role4 = "Imagineer"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                                }
                                else if(res.data[0].percentile_O == 65.1 || res.data[0].percentile_O <= 75) {
                                  $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                                  $('.score-speedometer-scale-imagineer').text("High")
                                  num4 = 2
                                  score4 = "High"
                                  role4 = "Imagineer"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                                }
                                else if(res.data[0].percentile_O == 75.1 || res.data[0].percentile_O <= 100) {
                                  $(".img-speedometer-scale-imagineer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                                  $('.score-speedometer-scale-imagineer').text("Very High")
                                  num4 = 1
                                  score4 = "Very High"
                                  role4 = "Imagineer"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                                }
      
                                // Speedometer-Scale-Strong-and-Stable
                                if(res.data[0].percentile_ER <= 25) {
                                  $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                                  $('.score-speedometer-scale-strong-and-stable').text("Very High")
                                  num5 = 1
                                  score5 = "Very High"
                                  role5 = "Strong and Steady"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                                }
                                else if(res.data[0].percentile_ER == 25.1 || res.data[0].percentile_ER <= 35) {
                                  $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                                  $('.score-speedometer-scale-strong-and-stable').text("High")
                                  num5 = 2
                                  score5 = "High"
                                  role5 = "Strong and Steady"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                                }
                                else if(res.data[0].percentile_ER == 35.1 || res.data[0].percentile_ER <= 45) {
                                  $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                                  $('.score-speedometer-scale-strong-and-stable').text("Moderate")
                                  num5 = 3
                                  score5 = "Moderate"
                                  role5 = "Strong and Steady"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                                }
                                else if(res.data[0].percentile_ER == 45.1 || res.data[0].percentile_ER <= 55) {
                                  $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                                  $('.score-speedometer-scale-strong-and-stable').text("Low")
                                  num5 = 4
                                  score5 = "Low"
                                  role5 = "Strong and Steady"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                                }
                                else if(res.data[0].percentile_ER == 55.1 || res.data[0].percentile_ER <= 100) {
                                  $(".img-speedometer-scale-strong-and-stable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                                  $('.score-speedometer-scale-strong-and-stable').text("Very Low")
                                  num5 = 5
                                  score5 = "Very Low"
                                  role5 = "Strong and Steady"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                                }
      
                                // Speedometer-Scale-Empathor
                                if(res.data[0].percentile_ER <= 45) {
                                  $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                                  $('.score-speedometer-scale-empathor').text("Very Low")
                                  num6 = 5
                                  score6 = "Very Low"
                                  role6 = "Empathor"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                                }
                                else if(res.data[0].percentile_ER == 45.1 || res.data[0].percentile_ER <= 55) {
                                  $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                                  $('.score-speedometer-scale-empathor').text("Low")
                                  num6 = 4
                                  score6 = "Low"
                                  role6 = "Empathor"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                                }
                                else if(res.data[0].percentile_ER == 55.1 || res.data[0].percentile_ER <= 65) {
                                  $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                                  $('.score-speedometer-scale-empathor').text("Moderate")
                                  num6 = 3
                                  score6 = "Moderate"
                                  role6 = "Empathor"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                                }
                                else if(res.data[0].percentile_ER == 65.1 || res.data[0].percentile_ER <= 75) {
                                  $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                                  $('.score-speedometer-scale-empathor').text("High")
                                  num6 = 2
                                  score6 = "High"
                                  role6 = "Empathor"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                                }
                                else if(res.data[0].percentile_ER == 75.1 || res.data[0].percentile_ER <= 100) {
                                  $(".img-speedometer-scale-empathor").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                                  $('.score-speedometer-scale-empathor').text("Very High")
                                  num6 = 1
                                  score6 = "Very High"
                                  role6 = "Empathor"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                                }
      
                                // Speedometer-Scale-Team-Builder
                                if(res.data[0].percentile_A <= 45) {
                                  $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                                  $('.score-speedometer-scale-team-builder').text("Very Low")
                                  num7 = 5
                                  score7 = "Very Low"
                                  role7 = "Team Builder"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                                }
                                else if(res.data[0].percentile_A == 45.1 || res.data[0].percentile_A <= 55) {
                                  $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                                  $('.score-speedometer-scale-team-builder').text("Low")
                                  num7 = 4
                                  score7 = "Low"
                                  role7 = "Team Builder"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                                }
                                else if(res.data[0].percentile_A == 55.1 || res.data[0].percentile_A <= 65) {
                                  $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                                  $('.score-speedometer-scale-team-builder').text("Moderate")
                                  num7 = 3
                                  score7 = "Moderate"
                                  role7 = "Team Builder"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                                }
                                else if(res.data[0].percentile_A == 65.1 || res.data[0].percentile_A <= 75) {
                                  $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                                  $('.score-speedometer-scale-team-builder').text("High")
                                  num7 = 2
                                  score7 = "High"
                                  role7 = "Team Builder"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                                }
                                else if(res.data[0].percentile_A == 75.1 || res.data[0].percentile_A <= 100) {
                                  $(".img-speedometer-scale-team-builder").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                                  $('.score-speedometer-scale-team-builder').text("Very High")
                                  num7 = 1
                                  score7 = "Very High"
                                  role7 = "Team Builder"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                                }
      
                                // Speedometer-Scale-Boss-being
                                if(res.data[0].percentile_E <= 45) {
                                  $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                                  $('.score-speedometer-scale-boss-being').text("Very Low")
                                  num8 = 5
                                  score8 = "Very Low"
                                  role8 = "Boss-being"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                                }
                                else if(res.data[0].percentile_E == 45.1 || res.data[0].percentile_E <= 55) {
                                  $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                                  $('.score-speedometer-scale-boss-being').text("Low")
                                  num8 = 4
                                  score8 = "Low"
                                  role8 = "Boss-being"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                                }
                                else if(res.data[0].percentile_E == 55.1 || res.data[0].percentile_E <= 65) {
                                  $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                                  $('.score-speedometer-scale-boss-being').text("Moderate")
                                  num8 = 3
                                  score8 = "Moderate"
                                  role8 = "Boss-being"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                                }
                                else if(res.data[0].percentile_E == 65.1 || res.data[0].percentile_E <= 75) {
                                  $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                                  $('.score-speedometer-scale-boss-being').text("High")
                                  num8 = 2
                                  score8 = "High"
                                  role8 = "Boss-being"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                                }
                                else if(res.data[0].percentile_E == 75.1 || res.data[0].percentile_E <= 100) {
                                  $(".img-speedometer-scale-boss-being").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                                  $('.score-speedometer-scale-boss-being').text("Very High")
                                  num8 = 1
                                  score8 = "Very High"
                                  role8 = "Boss-being"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                                }
      
                                // Speedometer-Scale-Standard-Bearer
                                if(res.data[0].percentile_C <= 45) {
                                  $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                                  $('.score-speedometer-scale-standard-bearer').text("Very Low")
                                  num9 = 5
                                  score9 = "Very Low"
                                  role9 = "Standard Bearer"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                                }
                                else if(res.data[0].percentile_C == 45.1 || res.data[0].percentile_C <= 55.0) {
                                  $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                                  $('.score-speedometer-scale-standard-bearer').text("Low")
                                  num9 = 4
                                  score9 = "Low"
                                  role9 = "Standard Bearer"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                                }
                                else if(res.data[0].percentile_C == 55.1 || res.data[0].percentile_C <= 65) {
                                  $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                                  $('.score-speedometer-scale-standard-bearer').text("Moderate")
                                  num9 = 3
                                  score9 = "Moderate"
                                  role9 = "Standard Bearer"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                                }
                                else if(res.data[0].percentile_C == 65.1 || res.data[0].percentile_C <= 75) {
                                  $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                                  $('.score-speedometer-scale-standard-bearer').text("High")
                                  num9 = 2
                                  score9 = "High"
                                  role9 = "Standard Bearer"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                                }
                                else if(res.data[0].percentile_C == 75.1 || res.data[0].percentile_C <= 100) {
                                  $(".img-speedometer-scale-standard-bearer").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                                  $('.score-speedometer-scale-standard-bearer').text("Very High")
                                  num9 = 1
                                  score9 = "Very High"
                                  role9 = "Standard Bearer"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                                }
      
                                // Speedometer-Scale-Realist
                                if(res.data[0].percentile_O <= 25) {
                                  $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                                  $('.score-speedometer-scale-realist').text("Very High")
                                  num10 = 1
                                  score10 = "Very High"
                                  role10 = "Realist"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                                }
                                else if(res.data[0].percentile_O == 25.1 || res.data[0].percentile_O <= 35) {
                                  $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                                  $('.score-speedometer-scale-realist').text("High")
                                  num10 = 2
                                  score10 = "High"
                                  role10 = "Realist"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                                }
                                else if(res.data[0].percentile_O == 35.1 || res.data[0].percentile_O <= 45) {
                                  $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                                  $('.score-speedometer-scale-realist').text("Moderate")
                                  num10 = 3
                                  score10 = "Moderate"
                                  role10 = "Realist"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                                }
                                else if(res.data[0].percentile_O == 45.1 || res.data[0].percentile_O <= 55) {
                                  $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                                  $('.score-speedometer-scale-realist').text("Low")
                                  num10 = 4
                                  score10 = "Low"
                                  role10 = "Realist"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                                }
                                else if(res.data[0].percentile_O == 55.1 || res.data[0].percentile_O <= 100) {
                                  $(".img-speedometer-scale-realist").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                                  $('.score-speedometer-scale-realist').text("Very Low")
                                  num10 = 5
                                  score10 = "Very Low"
                                  role10 = "Realist"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                                }
      
                                let objSpeedometerAllScale = [
                                  { key: num1, png: png1, score: score1, role: role1 },
                                  { key: num2, png: png2, score: score2, role: role2 },
                                  { key: num3, png: png3, score: score3, role: role3 },
                                  { key: num4, png: png4, score: score4, role: role4 },
                                  { key: num5, png: png5, score: score5, role: role5 },
                                  { key: num6, png: png6, score: score6, role: role6 },
                                  { key: num7, png: png7, score: score7, role: role7 },
                                  { key: num8, png: png8, score: score8, role: role8 },
                                  { key: num9, png: png9, score: score9, role: role9 },
                                  { key: num10, png: png10, score: score10, role: role10 }
                                ]
      
                                let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                                for(let i=0; i<10; i++) {
                                  $('.all-scale-role'+(i+1)).text(sortedData[i].role)
                                  $('.all-scale-img'+(i+1)).attr("src", sortedData[i].png)
                                  $('.all-scale-score'+(i+1)).text(sortedData[i].score)
                                }
                              `
                            }
                          }
      
                          // for Pressure Point
                          for (let i = 0; i < input.length; i++) {
                            const data = input[i]
                            if (data.Template === 'Speedometer-All-Scale-Pressure-Point') { 
                              output += `
                                let arrData = []
                                let num1 = 0, num2 = 0, num3 = 0, num4 = 0, num5 = 0, num6 = 0, num7 = 0, num8 = 0, num9 = 0, num10 = 0
                                let png1 = "", png2 = "", png3 = "", png4 = "", png5 = "", png6 = "", png7 = "", png8 = "", png9 = "", png10 = ""
                                let score1 = "", score2 = "", score3 = "", score4 = "", score5 = "", score6 = "", score7 = "", score8 = "", score9 = "", score10 = ""
                                let role1 = "", role2 = "", role3 = "", role4 = "", role5 = "", role6 = "", role7 = "", role8 = "", role9 = "", role10 = ""
                                let low_percentile_score_O = 0, low_percentile_score_E = 0, low_percentile_score_C = 0, high_percentile_score_O = 0, low_percentile_score_ER = 0, high_percentile_score_ER = 0, high_percentile_score_A = 0, high_percentile_score_E = 0, high_percentile_score_C = 0, low_percentile_score_A = 0
      
                                // Speedometer-Scale-Amenable
                                if(res.data[0].percentile_ER <= 20) {
                                  high_percentile_score_A = res.data[0].percentile_A * 0.8
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  high_percentile_score_A = res.data[0].percentile_A * 1.0
                                }
                                else if(res.data[0].percentile_ER== 80 || res.data[0].percentile_ER >= 80) {
                                  high_percentile_score_A = res.data[0].percentile_A * 1.2
                                }
      
                                if(high_percentile_score_A <= 20) {
                                  $('.score-speedometer-amenable').text("Very Low")
                                  $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                                  num7 = 5
                                  score7 = "Very Low"
                                  role7 = "Amenable"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                                }
                                else if(high_percentile_score_A == 20.1 || high_percentile_score_A <= 40) {
                                  $('.score-speedometer-amenable').text("Low")
                                  $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                                  num7 = 4
                                  score7 = "Low"
                                  role7 = "Amenable"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                                }
                                else if(high_percentile_score_A == 40.1 || high_percentile_score_A <= 60) {
                                  $('.score-speedometer-amenable').text("Moderate")
                                  $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                                  num7 = 3
                                  score7 = "Moderate"
                                  role7 = "Amenable"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                                }
                                else if(high_percentile_score_A == 60.1 || high_percentile_score_A <= 80) {
                                  $('.score-speedometer-amenable').text("High")
                                  $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                                  num7 = 2
                                  score7 = "High"
                                  role7 = "Amenable"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                                }
                                else if(high_percentile_score_A == 80.1 || high_percentile_score_A >= 80) {
                                  $('.score-speedometer-amenable').text("Very High")
                                  $(".img-speedometer-amenable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                                  num7 = 1
                                  score7 = "Very High"
                                  role7 = "Amenable"
                                  png7 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                                }
      
                                // Speedometer-Scale-Coldly-Logical
                                if(res.data[0].percentile_ER <= 20) {
                                  low_percentile_score_A = res.data[0].percentile_A * 1.2
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  low_percentile_score_A = res.data[0].percentile_A * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  low_percentile_score_A = res.data[0].percentile_A * 0.8
                                }
      
                                if(low_percentile_score_A <= 20) {
                                  $('.score-speedometer-coldly-logical').text("Very High")
                                  $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/100.png")
                                  num10 = 1
                                  score10 = "Very High"
                                  role10 = "Coldly Logical"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/100.png"
                                }
                                else if(low_percentile_score_A == 20.1 || low_percentile_score_A <= 40) {
                                  $('.score-speedometer-coldly-logical').text("High")
                                  $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/80.png")
                                  num10 = 2
                                  score10 = "High"
                                  role10 = "Coldly Logical"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/80.png"
                                }
                                else if(low_percentile_score_A == 40.1 || low_percentile_score_A <= 60) {
                                  $('.score-speedometer-coldly-logical').text("Moderate")
                                  $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/60.png")
                                  num10 = 3
                                  score10 = "Moderate"
                                  role10 = "Coldly Logical"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/60.png"
                                }
                                else if(low_percentile_score_A == 60.1 || low_percentile_score_A <= 80) {
                                  $('.score-speedometer-coldly-logical').text("Low")
                                  $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/40.png")
                                  num10 = 4
                                  score10 = "Low"
                                  role10 = "Coldly Logical"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/40.png"
                                }
                                else if(low_percentile_score_A == 80.1 || low_percentile_score_A >= 80) {
                                  $('.score-speedometer-coldly-logical').text("Very Low")
                                  $(".img-speedometer-coldly-logical").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/20.png")
                                  num10 = 5
                                  score10 = "Very Low"
                                  role10 = "Coldly Logical"
                                  png10 = "./Chart-Images/Speedometer/Pressure-Point/blue/20.png"
                                }
      
                                // Speedometer-Scale-Conforming
                                if(res.data[0].percentile_ER <= 20) {
                                  high_percentile_score_C = res.data[0].percentile_C * 0.8
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  high_percentile_score_C = res.data[0].percentile_C * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  high_percentile_score_C = res.data[0].percentile_C * 1.2
                                }
      
                                if(high_percentile_score_C <= 20) {
                                  $('.score-speedometer-conforming').text("Very Low")
                                  $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                                  num9 = 5
                                  score9 = "Very Low"
                                  role9 = "Conforming"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                                }
                                else if(high_percentile_score_C == 20.1 || high_percentile_score_C <= 40) {
                                  $('.score-speedometer-conforming').text("Low")
                                  $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                                  num9 = 4
                                  score9 = "Low"
                                  role9 = "Conforming"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                                }
                                else if(high_percentile_score_C == 40.1 || high_percentile_score_C <= 60) {
                                  $('.score-speedometer-conforming').text("Moderate")
                                  $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                                  num9 = 3
                                  score9 = "Moderate"
                                  role9 = "Conforming"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                                }
                                else if(high_percentile_score_C == 60.1 || high_percentile_score_C <= 80) {
                                  $('.score-speedometer-conforming').text("High")
                                  $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                                  num9 = 2
                                  score9 = "High"
                                  role9 = "Conforming"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                                }
                                else if(high_percentile_score_C == 80.1 || high_percentile_score_C >= 80) {
                                  $('.score-speedometer-conforming').text("Very High")
                                  $(".img-speedometer-conforming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                                  num9 = 1
                                  score9 = "Very High"
                                  role9 = "Conforming"
                                  png9 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                                }
      
                                // Speedometer-Scale-Disappearing
                                if(res.data[0].percentile_ER <= 20) {
                                  low_percentile_score_E = res.data[0].percentile_E * 1.2
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  low_percentile_score_E = res.data[0].percentile_E * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  low_percentile_score_E = res.data[0].percentile_E * 0.8
                                }
      
                                if(low_percentile_score_E <= 20) {
                                  $('.score-speedometer-disappearing').text("Very High")
                                  $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                                  num2 = 1
                                  score2 = "Very High"
                                  role2 = "Disappearing"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                                }
                                else if(low_percentile_score_E == 20.1 || low_percentile_score_E <= 40) {
                                  $('.score-speedometer-disappearing').text("High")
                                  $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                                  num2 = 2
                                  score2 = "High"
                                  role2 = "Disappearing"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                                }
                                else if(low_percentile_score_E == 40.1 || low_percentile_score_E <= 60) {
                                  $('.score-speedometer-disappearing').text("Moderate")
                                  $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                                  num2 = 3
                                  score2 = "Moderate"
                                  role2 = "Disappearing"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                                }
                                else if(low_percentile_score_E == 60.1 || low_percentile_score_E <= 80) {
                                  $('.score-speedometer-disappearing').text("Low")
                                  $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                                  num2 = 4
                                  score2 = "Low"
                                  role2 = "Disappearing"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                                }
                                else if(low_percentile_score_E == 80.1 || low_percentile_score_E >= 80) {
                                  $('.score-speedometer-disappearing').text("Very Low")
                                  $(".img-speedometer-disappearing").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                                  num2 = 5
                                  score2 = "Very Low"
                                  role2 = "Disappearing"
                                  png2 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                                }
      
                                // Speedometer-Scale-Dominating
                                if(res.data[0].percentile_ER <= 20) {
                                  high_percentile_score_E = res.data[0].percentile_E * 0.8
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  high_percentile_score_E = res.data[0].percentile_E * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  high_percentile_score_E = res.data[0].percentile_E * 1.2
                                }
      
                                if(high_percentile_score_E <= 20) {
                                  $('.score-speedometer-dominating').text("Very Low")
                                  $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/20.png")
                                  num8 = 5
                                  score8 = "Very Low"
                                  role8 = "Dominating"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/20.png"
                                }
                                else if(high_percentile_score_E == 20.1 || high_percentile_score_E <= 40) {
                                  $('.score-speedometer-dominating').text("Low")
                                  $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/40.png")
                                  num8 = 4
                                  score8 = "Low"
                                  role8 = "Dominating"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/40.png"
                                }
                                else if(high_percentile_score_E == 40.1 || high_percentile_score_E <= 60) {
                                  $('.score-speedometer-dominating').text("Moderate")
                                  $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/60.png")
                                  num8 = 3
                                  score8 = "Moderate"
                                  role8 = "Dominating"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/60.png"
                                }
                                else if(high_percentile_score_E == 60.1 || high_percentile_score_E <= 80) {
                                  $('.score-speedometer-dominating').text("High")
                                  $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/80.png")
                                  num8 = 2
                                  score8 = "High"
                                  role8 = "Dominating"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/80.png"
                                }
                                else if(high_percentile_score_E == 80.1 || high_percentile_score_E >= 80) {
                                  $('.score-speedometer-dominating').text("Very High")
                                  $(".img-speedometer-dominating").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/100.png")
                                  num8 = 1
                                  score8 = "Very High"
                                  role8 = "Dominating"
                                  png8 = "./Chart-Images/Speedometer/Pressure-Point/teal/100.png"
                                }
      
                                // Speedometer-Scale-Dreaming
                                if(res.data[0].percentile_ER <= 20) {
                                  high_percentile_score_O = res.data[0].percentile_O * 0.8
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  high_percentile_score_O = res.data[0].percentile_O * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  high_percentile_score_O = res.data[0].percentile_O * 1.2
                                }
      
                                if(high_percentile_score_O <= 20) {
                                  $('.score-speedometer-dreaming').text("Very Low")
                                  $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                                  num4 = 5
                                  score4 = "Very Low"
                                  role4 = "Dreaming"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                                }
                                else if(high_percentile_score_O == 20.1 || high_percentile_score_O <= 40) {
                                  $('.score-speedometer-dreaming').text("Low")
                                  $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                                  num4 = 4
                                  score4 = "Low"
                                  role4 = "Dreaming"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                                }
                                else if(high_percentile_score_O == 40.1 || high_percentile_score_O <= 60) {
                                  $('.score-speedometer-dreaming').text("Moderate")
                                  $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                                  num4 = 3
                                  score4 = "Moderate"
                                  role4 = "Dreaming"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                                }
                                else if(high_percentile_score_O == 60.1 || high_percentile_score_O <= 80) {
                                  $('.score-speedometer-dreaming').text("High")
                                  $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                                  num4 = 2
                                  score4 = "High"
                                  role4 = "Dreaming"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                                }
                                else if(high_percentile_score_O == 80.1 || high_percentile_score_O >= 80) {
                                  $('.score-speedometer-dreaming').text("Very High")
                                  $(".img-speedometer-dreaming").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                                  num4 = 1
                                  score4 = "Very High"
                                  role4 = "Dreaming"
                                  png4 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                                }
      
                                // Speedometer-Scale-Emotionally-Numb
                                if(res.data[0].percentile_ER <= 20) {
                                  low_percentile_score_ER = res.data[0].percentile_ER * 1.2
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  low_percentile_score_ER = res.data[0].percentile_ER * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  low_percentile_score_ER = res.data[0].percentile_ER * 0.8
                                }
      
                                if(low_percentile_score_ER <= 20) {
                                  $('.score-speedometer-emotionally-numb').text("Very High")
                                  $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                                  num5 = 1
                                  score5 = "Very High"
                                  role5 = "Emotionally Numb"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                                }
                                else if(low_percentile_score_ER == 20.1 || low_percentile_score_ER <= 40) {
                                  $('.score-speedometer-emotionally-numb').text("High")
                                  $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                                  num5 = 2
                                  score5 = "High"
                                  role5 = "Emotionally Numb"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                                }
                                else if(low_percentile_score_ER == 40.1 || low_percentile_score_ER <= 60) {
                                  $('.score-speedometer-emotionally-numb').text("Moderate")
                                  $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                                  num5 = 3
                                  score5 = "Moderate"
                                  role5 = "Emotionally Numb"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                                }
                                else if(low_percentile_score_ER == 60.1 || low_percentile_score_ER <= 80) {
                                  $('.score-speedometer-emotionally-numb').text("Low")
                                  $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                                  num5 = 4
                                  score5 = "Low"
                                  role5 = "Emotionally Numb"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                                }
                                else if(low_percentile_score_ER == 80.1 || low_percentile_score_ER >= 80) {
                                  $('.score-speedometer-emotionally-numb').text("Very Low")
                                  $(".img-speedometer-emotionally-numb").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                                  num5 = 5
                                  score5 = "Very Low"
                                  role5 = "Emotionally Numb"
                                  png5 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                                }
      
                                // Speedometer-Scale-Emotive
                                if(res.data[0].percentile_ER <= 20) {
                                  high_percentile_score_ER = res.data[0].percentile_ER * 0.8
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  high_percentile_score_ER = res.data[0].percentile_ER * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  high_percentile_score_ER = res.data[0].percentile_ER * 1.2
                                }
      
                                if(high_percentile_score_ER <= 20) {
                                  $('.score-speedometer-emotive').text("Very Low")
                                  $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/20.png")
                                  num6 = 5
                                  score6 = "Very Low"
                                  role6 = "Emotive"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/20.png"
                                }
                                else if(high_percentile_score_ER == 20.1 || high_percentile_score_ER <= 40) {
                                  $('.score-speedometer-emotive').text("Low")
                                  $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/40.png")
                                  num6 = 4
                                  score6 = "Low"
                                  role6 = "Emotive"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/40.png"
                                }
                                else if(high_percentile_score_ER == 40.1 || high_percentile_score_ER <= 60) {
                                  $('.score-speedometer-emotive').text("Moderate")
                                  $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/60.png")
                                  num6 = 3
                                  score6 = "Moderate"
                                  role6 = "Emotive"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/60.png"
                                }
                                else if(high_percentile_score_ER == 60.1 || high_percentile_score_ER <= 80) {
                                  $('.score-speedometer-emotive').text("High")
                                  $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/80.png")
                                  num6 = 2
                                  score6 = "High"
                                  role6 = "Emotive"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/80.png"
                                }
                                else if(high_percentile_score_ER == 80.1 || high_percentile_score_ER >= 80) {
                                  $('.score-speedometer-emotive').text("Very High")
                                  $(".img-speedometer-emotive").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/100.png")
                                  num6 = 1
                                  score6 = "Very High"
                                  role6 = "Emotive"
                                  png6 = "./Chart-Images/Speedometer/Pressure-Point/orange/100.png"
                                }
      
                                // Speedometer-Scale-Pliable
                                if(res.data[0].percentile_ER <= 20) {
                                  low_percentile_score_C = res.data[0].percentile_C * 1.2
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  low_percentile_score_C = res.data[0].percentile_C * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  low_percentile_score_C = res.data[0].percentile_C * 0.8
                                }
      
                                if(low_percentile_score_C <= 20) {
                                  $('.score-speedometer-pliable').text("Very High")
                                  $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png")
                                  num3 = 1
                                  score3 = "Very High"
                                  role3 = "Pliable"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/100.png"
                                }
                                else if(low_percentile_score_C == 20.1 || low_percentile_score_C <= 40) {
                                  $('.score-speedometer-pliable').text("High")
                                  $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png")
                                  num3 = 2
                                  score3 = "High"
                                  role3 = "Pliable"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/80.png"
                                }
                                else if(low_percentile_score_C == 40.1 || low_percentile_score_C <= 60) {
                                  $('.score-speedometer-pliable').text("Moderate")
                                  $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png")
                                  num3 = 3
                                  score3 = "Moderate"
                                  role3 = "Pliable"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/60.png"
                                }
                                else if(low_percentile_score_C == 60.1 || low_percentile_score_C <= 80) {
                                  $('.score-speedometer-pliable').text("Low")
                                  $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png")
                                  num3 = 4
                                  score3 = "Low"
                                  role3 = "Pliable"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/40.png"
                                }
                                else if(low_percentile_score_C == 80.1 || low_percentile_score_C >= 80) {
                                  $('.score-speedometer-pliable').text("Very Low")
                                  $(".img-speedometer-pliable").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png")
                                  num3 = 5
                                  score3 = "Very Low"
                                  role3 = "Pliable"
                                  png3 = "./Chart-Images/Speedometer/Pressure-Point/yellow/20.png"
                                }
      
                                // Speedometer-Scale-Strictly-Literal
                                if(res.data[0].percentile_ER <= 20) {
                                  low_percentile_score_O = res.data[0].percentile_O * 1.2
                                }
                                else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                                  low_percentile_score_O = res.data[0].percentile_O * 1.0
                                }
                                else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                                  low_percentile_score_O = res.data[0].percentile_O * 0.8
                                }
      
                                if(low_percentile_score_O <= 20) {
                                  $('.score-speedometer-strictly-literal').text("Very High")
                                  $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/100.png")
                                  num1 = 1
                                  score1 = "Very High"
                                  role1 = "Strictly Literal"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/100.png"
                                }
                                else if(low_percentile_score_O == 20.1 || low_percentile_score_O <= 40) {
                                  $('.score-speedometer-strictly-literal').text("High")
                                  $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/80.png")
                                  num1 = 2
                                  score1 = "High"
                                  role1 = "Strictly Literal"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/80.png"
                                }
                                else if(low_percentile_score_O == 40.1 || low_percentile_score_O <= 60) {
                                  $('.score-speedometer-strictly-literal').text("Moderate")
                                  $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/60.png")
                                  num1 = 3
                                  score1 = "Moderate"
                                  role1 = "Strictly Literal"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/60.png"
                                }
                                else if(low_percentile_score_O == 60.1 || low_percentile_score_O <= 80) {
                                  $('.score-speedometer-strictly-literal').text("Low")
                                  $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/40.png")
                                  num1 = 4
                                  score1 = "Low"
                                  role1 = "Strictly Literal"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/40.png"
                                }
                                else if(low_percentile_score_O == 80.1 || low_percentile_score_O >= 80) {
                                  $('.score-speedometer-strictly-literal').text("Very Low")
                                  $(".img-speedometer-strictly-literal").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/20.png")
                                  num1 = 5
                                  score1 = "Very Low"
                                  role1 = "Strictly Literal"
                                  png1 = "./Chart-Images/Speedometer/Pressure-Point/purple/20.png"
                                }
      
                                let objSpeedometerAllScale = [
                                  { key: num7, png: png7, score: score7, role: role7 },
                                  { key: num10, png: png10, score: score10, role: role10 },
                                  { key: num9, png: png9, score: score9, role: role9 },
                                  { key: num2, png: png2, score: score2, role: role2 },
                                  { key: num8, png: png8, score: score8, role: role8 },
                                  { key: num4, png: png4, score: score4, role: role4 },
                                  { key: num5, png: png5, score: score5, role: role5 },
                                  { key: num6, png: png6, score: score6, role: role6 },
                                  { key: num3, png: png3, score: score3, role: role3 },
                                  { key: num1, png: png1, score: score1, role: role1 }
                                ]
      
                                let sortedData = objSpeedometerAllScale.sort((a,b) => a.key - b.key).slice(0,10);
                                for(let i=0; i<10; i++) {
                                  $('.all-scale-role'+(i+1)).text(sortedData[i].role)
                                  $('.all-scale-img'+(i+1)).attr("src", sortedData[i].png)
                                  $('.all-scale-score'+(i+1)).text(sortedData[i].score)
                                }
                              `
                            }
                          }
      
      
                          output +=`
                          const ordinal = (number) => {
                            const ordinalRules = new Intl.PluralRules("en", {
                              type: "ordinal"
                            });
                            const suffixes = {
                              one: "st",
                              two: "nd",
                              few: "rd",
                              other: "th"
                            };
                            const suffix = suffixes[ordinalRules.select(number)];
                            return (number + suffix);
                          }
      
                          function getRanking (rank) {
                            if (rank <= 5){
                              return 5
                            } else if (rank >= 95) {
                              return 95
                            } else {
                              return rank
                            }
                          }
                          for (const data of jsonDataTIPping) { 
                          //Strong & Steady Scale
                            // Strong & Steady Subscale1
                            let totalSubscale_StrongAndSteady1 = res.data[0].percentile_ER4 + res.data[0].percentile_ER1 + res.data[0].percentile_ER6
                            let meanSubscale_StrongAndSteady1 = totalSubscale_StrongAndSteady1 / 3
                            // Very High
                            if(meanSubscale_StrongAndSteady1 <= 25 && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_StrongAndSteady1 == 25.1 || meanSubscale_StrongAndSteady1 <= 35) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_StrongAndSteady1 == 35.1 || meanSubscale_StrongAndSteady1 <= 45) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_StrongAndSteady1 == 45.1 || meanSubscale_StrongAndSteady1 <= 55) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_StrongAndSteady1 == 55.1 || meanSubscale_StrongAndSteady1 <= 100) && data.Reference === "StrongAndStable-1st-subscale") {
                              $('#StrongandStable_Subscale1').text(data.Very_Low)
                            }
      
                            // Strong & Steady Subscale2
                            let totalSubscale_StrongAndSteady2 = res.data[0].percentile_ER2 + res.data[0].percentile_ER3 + res.data[0].percentile_ER5
                            let meanSubscale_StrongAndSteady2 = totalSubscale_StrongAndSteady2 / 3
                            // Very High
                            if(meanSubscale_StrongAndSteady2 <= 25 && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_StrongAndSteady2 == 25.1 || meanSubscale_StrongAndSteady2 <= 35) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_StrongAndSteady2 == 35.1 || meanSubscale_StrongAndSteady2 <= 45) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_StrongAndSteady2 == 45.1 || meanSubscale_StrongAndSteady2 <= 55) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_StrongAndSteady2 == 55.1 || meanSubscale_StrongAndSteady2 <= 100) && data.Reference === "StrongAndStable-2nd-subscale") {
                              $('#StrongandStable_Subscale2').text(data.Very_Low)
                            }
      
                          //Solo Operator Scale
                            // Solo Operator Subscale1
                            let totalSubscale_SoloOperator1 = res.data[0].percentile_E3 + res.data[0].percentile_E4 + res.data[0].percentile_E5
                            let meanSubscale_SoloOperator1 = totalSubscale_SoloOperator1 / 3
                            // Very High
                            if(meanSubscale_SoloOperator1 <= 25 && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_SoloOperator1 == 25.1 || meanSubscale_SoloOperator1 <= 35) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_SoloOperator1 == 35.1 || meanSubscale_SoloOperator1 <= 45) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_SoloOperator1 == 45.1 || meanSubscale_SoloOperator1 <= 55) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_SoloOperator1 == 55.1 || meanSubscale_SoloOperator1 <= 100) && data.Reference === "SoloOperator-1st-subscale") {
                              $('#SoloOperator_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_SoloOperator2 = res.data[0].percentile_E1 + res.data[0].percentile_E2 + res.data[0].percentile_E6
                            let meanSubscale_SoloOperator2 = totalSubscale_SoloOperator2 / 3
                            // Very High
                            if(meanSubscale_SoloOperator2 <= 25 && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_SoloOperator2 == 25.1 || meanSubscale_SoloOperator2 <= 35) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_SoloOperator2 == 35.1 || meanSubscale_SoloOperator2 <= 45) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_SoloOperator2 == 45.1 || meanSubscale_SoloOperator2 <= 55) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_SoloOperator2 == 55.1 || meanSubscale_SoloOperator2 <= 100) && data.Reference === "SoloOperator-2nd-subscale") {
                              $('#SoloOperator_Subscale2').text(data.Very_Low)
                            }
      
                          //Realist Scale
                            // Realist Subscale1
                            let totalSubscale_Realist1 = res.data[0].percentile_O1 + res.data[0].percentile_O2 + res.data[0].percentile_O5
                            let meanSubscale_Realist1 = totalSubscale_Realist1 / 3
                            // Very High
                            if(meanSubscale_Realist1 <= 25 && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Realist1 == 25.1 || meanSubscale_Realist1 <= 35) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Realist1 == 35.1 || meanSubscale_Realist1 <= 45) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Realist1 == 45.1 || meanSubscale_Realist1 <= 55) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Realist1 == 55.1 || meanSubscale_Realist1 <= 100) && data.Reference === "Realist-1st-subscale") {
                              $('#Realist_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Realist2 = res.data[0].percentile_O3 + res.data[0].percentile_O4 + res.data[0].percentile_O6
                            let meanSubscale_Realist2 = totalSubscale_Realist2 / 3
                            // Very High
                            if(meanSubscale_Realist2 <= 25 && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Realist2 == 25.1 || meanSubscale_Realist2 <= 35) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Realist2 == 35.1 || meanSubscale_Realist2 <= 45) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Realist2 == 45.1 || meanSubscale_Realist2 <= 55) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Realist2 == 55.1 || meanSubscale_Realist2 <= 100) && data.Reference === "Realist-2nd-subscale") {
                              $('#Realist_Subscale2').text(data.Very_Low)
                            }
      
                          //Rationalist Scale
                            // Rationalist Subscale1
                            let totalSubscale_Rationalist1 = res.data[0].percentile_A1 + res.data[0].percentile_A2 + res.data[0].percentile_A5
                            let meanSubscale_Rationalist1 = totalSubscale_Rationalist1 / 3
                            // Very High
                            if(meanSubscale_Rationalist1 <= 25 && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Rationalist1 == 25.1 || meanSubscale_Rationalist1 <= 35) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Rationalist1 == 35.1 || meanSubscale_Rationalist1 <= 45) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Rationalist1 == 45.1 || meanSubscale_Rationalist1 <= 55) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Rationalist1 == 55.1 || meanSubscale_Rationalist1 <= 100) && data.Reference === "Rationalist-1st-subscale") {
                              $('#Rationalist_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Rationalist2 = res.data[0].percentile_A3 + res.data[0].percentile_A4 + res.data[0].percentile_A6
                            let meanSubscale_Rationalist2 = totalSubscale_Rationalist2 / 3
                            // Very High
                            if(meanSubscale_Rationalist2 <= 25 && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Rationalist2 == 25.1 || meanSubscale_Rationalist2 <= 35) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Rationalist2 == 35.1 || meanSubscale_Rationalist2 <= 45) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Rationalist2 == 45.1 || meanSubscale_Rationalist2 <= 55) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Rationalist2 == 55.1 || meanSubscale_Rationalist2 <= 100) && data.Reference === "Rationalist-2nd-subscale") {
                              $('#Rationalist_Subscale2').text(data.Very_Low)
                            }
      
                        //Agilator Scale
                            // Agilator Subscale1
                            let totalSubscale_Agilator1 = res.data[0].percentile_C3 + res.data[0].percentile_C4 + res.data[0].percentile_C5
                            let meanSubscale_Agilator1 = totalSubscale_Agilator1 / 3
                            // Very High
                            if(meanSubscale_Agilator1 <= 25 && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Agilator1 == 25.1 || meanSubscale_Agilator1 <= 35) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Agilator1 == 35.1 || meanSubscale_Agilator1 <= 45) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Agilator1 == 45.1 || meanSubscale_Agilator1 <= 55) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Agilator1 == 55.1 || meanSubscale_Agilator1 <= 100) && data.Reference === "Agilator-1st-subscale") {
                              $('#Agilator_Subscale1').text(data.Very_Low)
                            }
      
                            // Solo Operator Subscale2
                            let totalSubscale_Agilator2 = res.data[0].percentile_C2 + res.data[0].percentile_C1 + res.data[0].percentile_C6
                            let meanSubscale_Agilator2 = totalSubscale_Agilator2 / 3
                            // Very High
                            if(meanSubscale_Agilator2 <= 25 && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Very_High)
                            }
                            // High
                            else if((meanSubscale_Agilator2 == 25.1 || meanSubscale_Agilator2 <= 35) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.High)
                            }
                            // Moderate
                            else if((meanSubscale_Agilator2 == 35.1 || meanSubscale_Agilator2 <= 45) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Moderate)
                            }
                            // Low
                            else if((meanSubscale_Agilator2 == 45.1 || meanSubscale_Agilator2 <= 55) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Low)
                            }
                            // Very Low
                            else if((meanSubscale_Agilator2 == 55.1 || meanSubscale_Agilator2 <= 100) && data.Reference === "Agilator-2nd-subscale") {
                              $('#Agilator_Subscale2').text(data.Very_Low)
                            }
                        
                        // Empathor
                           // Empathor Subscale1
                           let totalSubscale_Empathor1 = res.data[0].percentile_ER4 + res.data[0].percentile_ER1 + res.data[0].percentile_ER6
                           let meanSubscale_Empathor1 = totalSubscale_Empathor1 / 3
                           // Very Low
                           if(meanSubscale_Empathor1 <= 45 && data.Reference === "Empathor-1st-subscale") {
                            $('#Empathor_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Empathor1 == 45.1 || meanSubscale_Empathor1 <= 55.0) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Empathor1 == 55.1 || meanSubscale_Empathor1 <= 65) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Empathor1 == 65.1 || meanSubscale_Empathor1 <= 75) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Empathor1 == 75.1 || meanSubscale_Empathor1 <= 100) && data.Reference === "Empathor-1st-subscale") {
                              $('#Empathor_Subscale1').text(data.Very_High)
                            }
      
                           // Empathor Subscale2
                           let totalSubscale_Empathor2 = res.data[0].percentile_ER2 + res.data[0].percentile_ER3 + res.data[0].percentile_ER5
                           let meanSubscale_Empathor2 = totalSubscale_Empathor2 / 3
                           // Very Low
                           if(meanSubscale_Empathor2 <= 45 && data.Reference === "Empathor-2nd-subscale") {
                            $('#Empathor_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Empathor2 == 45.1 || meanSubscale_Empathor2 <= 55.0) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Empathor2 == 55.1 || meanSubscale_Empathor2 <= 65) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Empathor2 == 65.1 || meanSubscale_Empathor2 <= 75) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Empathor2 == 75.1 || meanSubscale_Empathor2 <= 100) && data.Reference === "Empathor-2nd-subscale") {
                              $('#Empathor_Subscale2').text(data.Very_High)
                            }
      
                          // Imagineer
                           // Imagineer Subscale1
                           let totalSubscale_Imagineer1 = res.data[0].percentile_O1 + res.data[0].percentile_O2 + res.data[0].percentile_O5
                           let meanSubscale_Imagineer1 = totalSubscale_Imagineer1 / 3
                           // Very Low
                           if(meanSubscale_Imagineer1 <= 45 && data.Reference === "Imagineer-1st-subscale") {
                            $('#Imagineer_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Imagineer1 == 45.1 || meanSubscale_Imagineer1 <= 55.0) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Imagineer1 == 55.1 || meanSubscale_Imagineer1 <= 65) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Imagineer1 == 65.1 || meanSubscale_Imagineer1 <= 75) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Imagineer1 == 75.1 || meanSubscale_Imagineer1 <= 100) && data.Reference === "Imagineer-1st-subscale") {
                              $('#Imagineer_Subscale1').text(data.Very_High)
                            }
      
                           // Imagineer Subscale2
                           let totalSubscale_Imagineer2 = res.data[0].percentile_O3 + res.data[0].percentile_O4 + res.data[0].percentile_O6
                           let meanSubscale_Imagineer2 = totalSubscale_Imagineer2 / 3
                           // Very Low
                           if(meanSubscale_Imagineer2 <= 45 && data.Reference === "Imagineer-2nd-subscale") {
                            $('#Imagineer_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Imagineer2 == 45.1 || meanSubscale_Imagineer2 <= 55.0) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Imagineer2 == 55.1 || meanSubscale_Imagineer2 <= 65) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Imagineer2 == 65.1 || meanSubscale_Imagineer2 <= 75) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Imagineer2 == 75.1 || meanSubscale_Imagineer2 <= 100) && data.Reference === "Imagineer-2nd-subscale") {
                              $('#Imagineer_Subscale2').text(data.Very_High)
                            }
      
                          // TeamBuilder
                           // TeamBuilder Subscale1
                           let totalSubscale_TeamBuilder1 = res.data[0].percentile_A1 + res.data[0].percentile_A2 + res.data[0].percentile_A5
                           let meanSubscale_TeamBuilder1 = totalSubscale_TeamBuilder1 / 3
                           // Very Low
                           if(meanSubscale_TeamBuilder1 <= 45 && data.Reference === "TeamBuilder-1st-subscale") {
                            $('#TeamBuilder_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_TeamBuilder1 == 45.1 || meanSubscale_TeamBuilder1 <= 55.0) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_TeamBuilder1 == 55.1 || meanSubscale_TeamBuilder1 <= 65) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_TeamBuilder1 == 65.1 || meanSubscale_TeamBuilder1 <= 75) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_TeamBuilder1 == 75.1 || meanSubscale_TeamBuilder1 <= 100) && data.Reference === "TeamBuilder-1st-subscale") {
                              $('#TeamBuilder_Subscale1').text(data.Very_High)
                            }
      
                           // TeamBuilder Subscale2
                           let totalSubscale_TeamBuilder2 = res.data[0].percentile_A3 + res.data[0].percentile_A4 + res.data[0].percentile_A6
                           let meanSubscale_TeamBuilder2 = totalSubscale_TeamBuilder2 / 3
                           // Very Low
                           if(meanSubscale_TeamBuilder2 <= 45 && data.Reference === "TeamBuilder-2nd-subscale") {
                            $('#TeamBuilder_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_TeamBuilder2 == 45.1 || meanSubscale_TeamBuilder2 <= 55.0) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_TeamBuilder2 == 55.1 || meanSubscale_TeamBuilder2 <= 65) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_TeamBuilder2 == 65.1 || meanSubscale_TeamBuilder2 <= 75) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_TeamBuilder2 == 75.1 || meanSubscale_TeamBuilder2 <= 100) && data.Reference === "TeamBuilder-2nd-subscale") {
                              $('#TeamBuilder_Subscale2').text(data.Very_High)
                            }
      
                          // StandardBearer
                           // StandardBearer Subscale1
                           let totalSubscale_StandardBearer1 = res.data[0].percentile_C3 + res.data[0].percentile_C4 + res.data[0].percentile_C5
                           let meanSubscale_StandardBearer1 = totalSubscale_StandardBearer1 / 3
                           // Very Low
                           if(meanSubscale_StandardBearer1 <= 45 && data.Reference === "StandardBearer-1st-subscale") {
                            $('#StandardBearer_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_StandardBearer1 == 45.1 || meanSubscale_StandardBearer1 <= 55.0) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_StandardBearer1 == 55.1 || meanSubscale_StandardBearer1 <= 65) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_StandardBearer1 == 65.1 || meanSubscale_StandardBearer1 <= 75) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_StandardBearer1 == 75.1 || meanSubscale_StandardBearer1 <= 100) && data.Reference === "StandardBearer-1st-subscale") {
                              $('#StandardBearer_Subscale1').text(data.Very_High)
                            }
      
                           // StandardBearer Subscale2
                           let totalSubscale_StandardBearer2 = res.data[0].percentile_C2 + res.data[0].percentile_C1 + res.data[0].percentile_C6
                           let meanSubscale_StandardBearer2 = totalSubscale_StandardBearer2 / 3
                           // Very Low
                           if(meanSubscale_StandardBearer2 <= 45 && data.Reference === "StandardBearer-2nd-subscale") {
                            $('#StandardBearer_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_StandardBearer2 == 45.1 || meanSubscale_StandardBearer2 <= 55.0) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_StandardBearer2 == 55.1 || meanSubscale_StandardBearer2 <= 65) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_StandardBearer2 == 65.1 || meanSubscale_StandardBearer2 <= 75) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_StandardBearer2 == 75.1 || meanSubscale_StandardBearer2 <= 100) && data.Reference === "StandardBearer-2nd-subscale") {
                              $('#StandardBearer_Subscale2').text(data.Very_High)
                            }
      
                          // Boss-being
                           // Boss-being Subscale1
                           let totalSubscale_Bossbeing1 = res.data[0].percentile_E3 + res.data[0].percentile_E4 + res.data[0].percentile_E5
                           let meanSubscale_Bossbeing1 = totalSubscale_Bossbeing1 / 3
                           // Very Low
                           if(meanSubscale_Bossbeing1 <= 45 && data.Reference === "Boss-being-1st-subscale") {
                            $('#Bossbeing_Subscale1').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Bossbeing1 == 45.1 || meanSubscale_Bossbeing1 <= 55.0) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Bossbeing1 == 55.1 || meanSubscale_Bossbeing1 <= 65) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Bossbeing1 == 65.1 || meanSubscale_Bossbeing1 <= 75) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Bossbeing1 == 75.1 || meanSubscale_Bossbeing1 <= 100) && data.Reference === "Boss-being-1st-subscale") {
                              $('#Bossbeing_Subscale1').text(data.Very_High)
                            }
      
                           // Boss-being Subscale2
                           let totalSubscale_Bossbeing2 = res.data[0].percentile_E1 + res.data[0].percentile_E2 + res.data[0].percentile_E6
                           let meanSubscale_Bossbeing2 = totalSubscale_Bossbeing2 / 3
                           // Very Low
                           if(meanSubscale_Bossbeing2 <= 45 && data.Reference === "Boss-being-2nd-subscale") {
                            $('#Bossbeing_Subscale2').text(data.Very_Low)
                            }
                           // Low
                            else if((meanSubscale_Bossbeing2 == 45.1 || meanSubscale_Bossbeing2 <= 55.0) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Low)
                            }
                            // Moderate
                            else if((meanSubscale_Bossbeing2 == 55.1 || meanSubscale_Bossbeing2 <= 65) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Moderate)
                            }
                            // High
                            else if((meanSubscale_Bossbeing2 == 65.1 || meanSubscale_Bossbeing2 <= 75) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.High)
                            }
                            // Very High
                            else if((meanSubscale_Bossbeing2 == 75.1 || meanSubscale_Bossbeing2 <= 100) && data.Reference === "Boss-being-2nd-subscale") {
                              $('#Bossbeing_Subscale2').text(data.Very_High)
                            }
      
                          // Task vs Relationship
                            let totalTvsR = res.data[0].percentile_ER + res.data[0].percentile_A + res.data[0].percentile_E
                            let meanTvsR = totalTvsR / 3
                            if(meanTvsR <= 25 && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Very_High_Task_Focus)
                            }
                            else if((meanTvsR >= 25.1 && meanTvsR <= 45) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.High_Balance_1)
                            }
                            else if((meanTvsR >= 45.1 && meanTvsR <= 55) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Moderate_Task_vs_Relationship)
                            }
                            else if((meanTvsR >= 55.1 && meanTvsR <= 75) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.High_Balance_2)
                            }
                            else if((meanTvsR >= 75.1 && meanTvsR <= 100) && data.Reference === "TaskvsRelationship-mainscale") {
                              $('#TaskVSRelationship').text(data.Very_High_Relationship)
                            }
      
                          // Team vs Individual Main Scale
                            let totalTeamvsIndividual = (100 - res.data[0].percentile_O) + res.data[0].percentile_C + res.data[0].percentile_E
                            let meanTeamvsIndividual = totalTeamvsIndividual / 3
                            if(meanTeamvsIndividual <= 25 && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Very_High_Individual)
                            }
                            else if((meanTeamvsIndividual >= 25.1 && meanTeamvsIndividual <= 45) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.High_Balance_2)
                            }
                            else if((meanTeamvsIndividual >= 45.1 && meanTeamvsIndividual <= 55) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Moderate_Team_vs_Individual)
                            }
                            else if((meanTeamvsIndividual >= 55.1 && meanTeamvsIndividual <= 75) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.High_Balance_1)
                            }
                            else if((meanTeamvsIndividual >= 75.1 && meanTeamvsIndividual <= 100) && data.Reference === "TeamVSIndividual-mainscale") {
                              $('#TeamvsIndividual').text(data.Very_High_Team_Structure)
                            }
                           }
                           for (const data of jsonDataPressure) {
                        //Start of Pressure Point
                        // LOW
                        // Emotionally Numb
                          let low_percentile_score_ER = 0 
                          let low_percentile_score_ER1 = 0
                          let low_percentile_score_ER2 = 0
                          let low_percentile_score_ER3 = 0
                          let low_percentile_score_ER4 = 0
                          let low_percentile_score_ER5 = 0
                          let low_percentile_score_ER6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.2
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.2
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.2
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.2
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.2
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.2
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 1.0
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.0
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.0
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.0
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.0
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.0
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_ER = res.data[0].percentile_ER * 0.8
                            low_percentile_score_ER1 = res.data[0].percentile_ER1 * 0.8
                            low_percentile_score_ER2 = res.data[0].percentile_ER2 * 0.8
                            low_percentile_score_ER3 = res.data[0].percentile_ER3 * 0.8
                            low_percentile_score_ER4 = res.data[0].percentile_ER4 * 0.8
                            low_percentile_score_ER5 = res.data[0].percentile_ER5 * 0.8
                            low_percentile_score_ER6 = res.data[0].percentile_ER6 * 0.8
                          }
                        //End of 1st computation
      
                        //Emotionally Numb subscale 1
                          let Total_EmotionallyNumb_Subscale1 = low_percentile_score_ER4 + low_percentile_score_ER1 + low_percentile_score_ER6
                          let Mean_EmotionallyNumb_Subscale1 = Total_EmotionallyNumb_Subscale1 / 3
                          if(Mean_EmotionallyNumb_Subscale1 <= 20 && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Very_High)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/100.png")
                            $(".score-Belief-in-myself").text("Very High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 20.1 || Mean_EmotionallyNumb_Subscale1 <= 40) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.High)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/80.png")
                            $(".score-Belief-in-myself").text("High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 40.1 || Mean_EmotionallyNumb_Subscale1 <= 60) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Moderate)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/60.png")
                            $(".score-Belief-in-myself").text("Moderate")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 60.1 || Mean_EmotionallyNumb_Subscale1 <= 80) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Low)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/40.png")
                            $(".score-Belief-in-myself").text("Low")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale1 == 80.1 || Mean_EmotionallyNumb_Subscale1 >= 80) && data.Reference === "EmotionallyNumb-1st-subscale"){
                            $('#EmotionallyNumb_Subscale1').text(data.Very_Low)
                            $(".img-Belief-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/20.png")
                            $(".score-Belief-in-myself").text("Very Low")
                          }
      
                        //Emotionally Numb subscale 2
                          let Total_EmotionallyNumb_Subscale2 = low_percentile_score_ER2 + low_percentile_score_ER3 + low_percentile_score_ER5
                          let Mean_EmotionallyNumb_Subscale2 = Total_EmotionallyNumb_Subscale2 / 3
                          if(Mean_EmotionallyNumb_Subscale2 <= 20 && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Very_High)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/100.png")
                            $(".score-Confidence-in-myself").text("Very High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 20.1 || Mean_EmotionallyNumb_Subscale2 <= 40) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.High)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/80.png")
                            $(".score-Confidence-in-myself").text("High")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 40.1 || Mean_EmotionallyNumb_Subscale2 <= 60) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Moderate)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/60.png")
                            $(".score-Confidence-in-myself").text("Moderate")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 60.1 || Mean_EmotionallyNumb_Subscale2 <= 80) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Low)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/40.png")
                            $(".score-Confidence-in-myself").text("Low")
                          }
      
                          else if((Mean_EmotionallyNumb_Subscale2 == 80.1 || Mean_EmotionallyNumb_Subscale2 >= 80) && data.Reference === "EmotionallyNumb-2nd-subscale"){
                            $('#EmotionallyNumb_Subscale2').text(data.Very_Low)
                            $(".img-Confidence-in-myself").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/20.png")
                            $(".score-Confidence-in-myself").text("Very Low")
                          }
                        
                        // Disappearing
                          let low_percentile_score_E = 0 
                          let low_percentile_score_E1 = 0
                          let low_percentile_score_E2 = 0
                          let low_percentile_score_E3 = 0
                          let low_percentile_score_E4 = 0
                          let low_percentile_score_E5 = 0
                          let low_percentile_score_E6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.2
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 1.2
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 1.2
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 1.2
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 1.2
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 1.2
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_E = res.data[0].percentile_E * 1.0
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 1.0
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 1.0
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 1.0
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 1.0
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 1.0
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_E = res.data[0].percentile_E * 0.8
                            low_percentile_score_E1 = res.data[0].percentile_E1 * 0.8
                            low_percentile_score_E2 = res.data[0].percentile_E2 * 0.8
                            low_percentile_score_E3 = res.data[0].percentile_E3 * 0.8
                            low_percentile_score_E4 = res.data[0].percentile_E4 * 0.8
                            low_percentile_score_E5 = res.data[0].percentile_E5 * 0.8
                            low_percentile_score_E6 = res.data[0].percentile_E6 * 0.8
                          }
                        //End of 1st computation
      
                        //Disappearing subscale 1
                          let Total_Disappearing_Subscale1 = low_percentile_score_E3 + low_percentile_score_E4 + low_percentile_score_E5
                          let Mean_Disappearing_Subscale1 = Total_Disappearing_Subscale1 / 3
                          if(Mean_Disappearing_Subscale1 <= 20 && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Very_High)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/100.png")
                            $(".score-Avoiding-the-team").text("Very High")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 20.1 || Mean_Disappearing_Subscale1 <= 40) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.High)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/80.png")
                            $(".score-Avoiding-the-team").text("High")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 40.1 || Mean_Disappearing_Subscale1 <= 60) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Moderate)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/60.png")
                            $(".score-Avoiding-the-team").text("Moderate")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 60.1 || Mean_Disappearing_Subscale1 <= 80) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Low)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/40.png")
                            $(".score-Avoiding-the-team").text("Low")
                          }
      
                          else if((Mean_Disappearing_Subscale1 == 80.1 || Mean_Disappearing_Subscale1 >= 80) && data.Reference === "Disappearing-1st-subscale"){
                            $('#Disappearing_Subscale1').text(data.Very_Low)
                            $(".img-Avoiding-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/20.png")
                            $(".score-Avoiding-the-team").text("Very Low")
                          }
      
                        //Disappearing subscale 2
                          let Total_Disappearing_Subscale2 = low_percentile_score_E1 + low_percentile_score_E2 + low_percentile_score_E6
                          let Mean_Disappearing_Subscale2 = Total_Disappearing_Subscale2 / 3
                          if(Mean_Disappearing_Subscale2 <= 20 && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Very_High)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/100.png")
                            $(".score-Disengaging-the-team").text("Very High")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 20.1 || Mean_Disappearing_Subscale2 <= 40) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.High)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/80.png")
                            $(".score-Disengaging-the-team").text("High")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 40.1 || Mean_Disappearing_Subscale2 <= 60) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Moderate)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/60.png")
                            $(".score-Disengaging-the-team").text("Moderate")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 60.1 || Mean_Disappearing_Subscale2 <= 80) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Low)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/40.png")
                            $(".score-Disengaging-the-team").text("Low")
                          }
      
                          else if((Mean_Disappearing_Subscale2 == 80.1 || Mean_Disappearing_Subscale2 >= 80) && data.Reference === "Disappearing-2nd-subscale"){
                            $('#Disappearing_Subscale2').text(data.Very_Low)
                            $(".img-Disengaging-the-team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/20.png")
                            $(".score-Disengaging-the-team").text("Very Low")
                          }
      
                        // StrictlyLiteral
                          let low_percentile_score_O = 0 
                          let low_percentile_score_O1 = 0
                          let low_percentile_score_O2 = 0
                          let low_percentile_score_O3 = 0
                          let low_percentile_score_O4 = 0
                          let low_percentile_score_O5 = 0
                          let low_percentile_score_O6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.2
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 1.2
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 1.2
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 1.2
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 1.2
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 1.2
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_O = res.data[0].percentile_O * 1.0
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 1.0
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 1.0
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 1.0
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 1.0
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 1.0
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_O = res.data[0].percentile_O * 0.8
                            low_percentile_score_O1 = res.data[0].percentile_O1 * 0.8
                            low_percentile_score_O2 = res.data[0].percentile_O2 * 0.8
                            low_percentile_score_O3 = res.data[0].percentile_O3 * 0.8
                            low_percentile_score_O4 = res.data[0].percentile_O4 * 0.8
                            low_percentile_score_O5 = res.data[0].percentile_O5 * 0.8
                            low_percentile_score_O6 = res.data[0].percentile_O6 * 0.8
                          }
                        //End of 1st computation
      
                        //StrictlyLiteral subscale 1
                          let Total_StrictlyLiteral_Subscale1 = low_percentile_score_O1 + low_percentile_score_O2 + low_percentile_score_O5
                          let Mean_StrictlyLiteral_Subscale1 = Total_StrictlyLiteral_Subscale1 / 3
                          if(Mean_StrictlyLiteral_Subscale1 <= 20 && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Very_High)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/100.png")
                            $(".score-Sticking-to-the-data").text("Very High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 20.1 || Mean_StrictlyLiteral_Subscale1 <= 40) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.High)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/80.png")
                            $(".score-Sticking-to-the-data").text("High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 40.1 || Mean_StrictlyLiteral_Subscale1 <= 60) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Moderate)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/60.png")
                            $(".score-Sticking-to-the-data").text("Moderate")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 60.1 || Mean_StrictlyLiteral_Subscale1 <= 80) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Low)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/40.png")
                            $(".score-Sticking-to-the-data").text("Low")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale1 == 80.1 || Mean_StrictlyLiteral_Subscale1 >= 80) && data.Reference === "StrictlyLiteral-1st-subscale"){
                            $('#StrictlyLiteral_Subscale1').text(data.Very_Low)
                            $(".img-Sticking-to-the-data").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/20.png")
                            $(".score-Sticking-to-the-data").text("Very Low")
                          }
      
                        //StrictlyLiteral subscale 2
                          let Total_StrictlyLiteral_Subscale2 = low_percentile_score_O3 + low_percentile_score_O4 + low_percentile_score_O6
                          let Mean_StrictlyLiteral_Subscale2 = Total_StrictlyLiteral_Subscale2 / 3
                          if(Mean_StrictlyLiteral_Subscale2 <= 20 && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Very_High)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/100.png")
                            $(".score-Proven-ideas").text("Very High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 20.1 || Mean_StrictlyLiteral_Subscale2 <= 40) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.High)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/80.png")
                            $(".score-Proven-ideas").text("High")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 40.1 || Mean_StrictlyLiteral_Subscale2 <= 60) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Moderate)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/60.png")
                            $(".score-Proven-ideas").text("Moderate")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 60.1 || Mean_StrictlyLiteral_Subscale2 <= 80) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Low)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/40.png")
                            $(".score-Proven-ideas").text("Low")
                          }
      
                          else if((Mean_StrictlyLiteral_Subscale2 == 80.1 || Mean_StrictlyLiteral_Subscale2 >= 80) && data.Reference === "StrictlyLiteral-2nd-subscale"){
                            $('#StrictlyLiteral_Subscale2').text(data.Very_Low)
                            $(".img-Proven-ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/20.png")
                            $(".score-Proven-ideas").text("Very Low")
                          }
      
                        // ColdlyLogical
                          let low_percentile_score_A = 0 
                          let low_percentile_score_A1 = 0
                          let low_percentile_score_A2 = 0
                          let low_percentile_score_A3 = 0
                          let low_percentile_score_A4 = 0
                          let low_percentile_score_A5 = 0
                          let low_percentile_score_A6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.2
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 1.2
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 1.2
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 1.2
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 1.2
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 1.2
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_A = res.data[0].percentile_A * 1.0
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 1.0
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 1.0
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 1.0
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 1.0
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 1.0
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_A = res.data[0].percentile_A * 0.8
                            low_percentile_score_A1 = res.data[0].percentile_A1 * 0.8
                            low_percentile_score_A2 = res.data[0].percentile_A2 * 0.8
                            low_percentile_score_A3 = res.data[0].percentile_A3 * 0.8
                            low_percentile_score_A4 = res.data[0].percentile_A4 * 0.8
                            low_percentile_score_A5 = res.data[0].percentile_A5 * 0.8
                            low_percentile_score_A6 = res.data[0].percentile_A6 * 0.8
                          }
                        //End of 1st computation
      
                        //ColdlyLogical subscale 1
                          let Total_ColdlyLogical_Subscale1 = low_percentile_score_A1 + low_percentile_score_A2 + low_percentile_score_A5
                          let Mean_ColdlyLogical_Subscale1 = Total_ColdlyLogical_Subscale1 / 3
                          if(Mean_ColdlyLogical_Subscale1 <= 20 && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Very_High)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/100.png")
                            $(".score-Self-protection").text("Very High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 20.1 || Mean_ColdlyLogical_Subscale1 <= 40) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.High)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/80.png")
                            $(".score-Self-protection").text("High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 40.1 || Mean_ColdlyLogical_Subscale1 <= 60) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Moderate)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/60.png")
                            $(".score-Self-protection").text("Moderate")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 60.1 || Mean_ColdlyLogical_Subscale1 <= 80) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Low)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/40.png")
                            $(".score-Self-protection").text("Low")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale1 == 80.1 || Mean_ColdlyLogical_Subscale1 >= 80) && data.Reference === "ColdlyLogical-1st-subscale"){
                            $('#ColdlyLogical_Subscale1').text(data.Very_Low)
                            $(".img-Self-protection").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/20.png")
                            $(".score-Self-protection").text("Very Low")
                          }
      
                        //ColdlyLogical subscale 2
                          let Total_ColdlyLogical_Subscale2 = low_percentile_score_A3 + low_percentile_score_A4 + low_percentile_score_A6
                          let Mean_ColdlyLogical_Subscale2 = Total_ColdlyLogical_Subscale2 / 3
                          if(Mean_ColdlyLogical_Subscale2 <= 20 && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Very_High)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/100.png")
                            $(".score-Self-preservation").text("Very High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 20.1 || Mean_ColdlyLogical_Subscale2 <= 40) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.High)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/80.png")
                            $(".score-Self-preservation").text("High")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 40.1 || Mean_ColdlyLogical_Subscale2 <= 60) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Moderate)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/60.png")
                            $(".score-Self-preservation").text("Moderate")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 60.1 || Mean_ColdlyLogical_Subscale2 <= 80) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Low)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/40.png")
                            $(".score-Self-preservation").text("Low")
                          }
      
                          else if((Mean_ColdlyLogical_Subscale2 == 80.1 || Mean_ColdlyLogical_Subscale2 >= 80) && data.Reference === "ColdlyLogical-2nd-subscale"){
                            $('#ColdlyLogical_Subscale2').text(data.Very_Low)
                            $(".img-Self-preservation").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/20.png")
                            $(".score-Self-preservation").text("Very Low")
                          }
      
                        // Pliable
                          let low_percentile_score_C = 0 
                          let low_percentile_score_C1 = 0
                          let low_percentile_score_C2 = 0
                          let low_percentile_score_C3 = 0
                          let low_percentile_score_C4 = 0
                          let low_percentile_score_C5 = 0
                          let low_percentile_score_C6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.2
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 1.2
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 1.2
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 1.2
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 1.2
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 1.2
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 1.2
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            low_percentile_score_C = res.data[0].percentile_C * 1.0
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 1.0
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 1.0
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 1.0
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 1.0
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 1.0
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            low_percentile_score_C = res.data[0].percentile_C * 0.8
                            low_percentile_score_C1 = res.data[0].percentile_C1 * 0.8
                            low_percentile_score_C2 = res.data[0].percentile_C2 * 0.8
                            low_percentile_score_C3 = res.data[0].percentile_C3 * 0.8
                            low_percentile_score_C4 = res.data[0].percentile_C4 * 0.8
                            low_percentile_score_C5 = res.data[0].percentile_C5 * 0.8
                            low_percentile_score_C6 = res.data[0].percentile_C6 * 0.8
                          }
                        //End of 1st computation
      
                        //Pliable subscale 1
                          let Total_Pliable_Subscale1 = low_percentile_score_C3 + low_percentile_score_C4 + low_percentile_score_C5
                          let Mean_Pliable_Subscale1 = Total_Pliable_Subscale1 / 3
                          if(Mean_Pliable_Subscale1 <= 20 && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Very_High)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/100.png")
                            $(".score-Changing-responsibility").text("Very High")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 20.1 || Mean_Pliable_Subscale1 <= 40) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.High)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/80.png")
                            $(".score-Changing-responsibility").text("High")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 40.1 || Mean_Pliable_Subscale1 <= 60) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Moderate)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/60.png")
                            $(".score-Changing-responsibility").text("Moderate")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 60.1 || Mean_Pliable_Subscale1 <= 80) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Low)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/40.png")
                            $(".score-Changing-responsibility").text("Low")
                          }
      
                          else if((Mean_Pliable_Subscale1 == 80.1 || Mean_Pliable_Subscale1 >= 80) && data.Reference === "Pliable-1st-subscale"){
                            $('#Pliable_Subscale1').text(data.Very_Low)
                            $(".img-Changing-responsibility").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/20.png")
                            $(".score-Changing-responsibility").text("Very Low")
                          }
      
                        //Pliable subscale 2
                          let Total_Pliable_Subscale2 = low_percentile_score_C2 + low_percentile_score_C1 + low_percentile_score_C6
                          let Mean_Pliable_Subscale2 = Total_Pliable_Subscale2 / 3
                          if(Mean_Pliable_Subscale2 <= 20 && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Very_High)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/100.png")
                            $(".score-Changing-structure").text("Very High")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 20.1 || Mean_Pliable_Subscale2 <= 40) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.High)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/80.png")
                            $(".score-Changing-structure").text("High")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 40.1 || Mean_Pliable_Subscale2 <= 60) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Moderate)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/60.png")
                            $(".score-Changing-structure").text("Moderate")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 60.1 || Mean_Pliable_Subscale2 <= 80) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Low)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/40.png")
                            $(".score-Changing-structure").text("Low")
                          }
      
                          else if((Mean_Pliable_Subscale2 == 80.1 || Mean_Pliable_Subscale2 >= 80) && data.Reference === "Pliable-2nd-subscale"){
                            $('#Pliable_Subscale2').text(data.Very_Low)
                            $(".img-Changing-structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/20.png")
                            $(".score-Changing-structure").text("Very Low")
                          }
      
                        // HIGH
                        // Emotive
                          let high_percentile_score_ER = 0 
                          let high_percentile_score_ER1 = 0
                          let high_percentile_score_ER2 = 0
                          let high_percentile_score_ER3 = 0
                          let high_percentile_score_ER4 = 0
                          let high_percentile_score_ER5 = 0
                          let high_percentile_score_ER6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_ER = res.data[0].percentile_ER * .8
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * .8
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * .8
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * .8
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * .8
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * .8
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.0
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.0
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.0
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.0
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.0
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.0
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_ER = res.data[0].percentile_ER * 1.2
                            high_percentile_score_ER1 = res.data[0].percentile_ER1 * 1.2
                            high_percentile_score_ER2 = res.data[0].percentile_ER2 * 1.2
                            high_percentile_score_ER3 = res.data[0].percentile_ER3 * 1.2
                            high_percentile_score_ER4 = res.data[0].percentile_ER4 * 1.2
                            high_percentile_score_ER5 = res.data[0].percentile_ER5 * 1.2
                            high_percentile_score_ER6 = res.data[0].percentile_ER6 * 1.2
                          }
                        //End of 1st computation
      
                        //Emotive subscale 1
                          let Total_Emotive_Subscale1 = high_percentile_score_ER4 + high_percentile_score_ER1 + high_percentile_score_ER6
                          let Mean_Emotive_Subscale1 = Total_Emotive_Subscale1 / 3
                          if(Mean_Emotive_Subscale1 <= 20 && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Very_Low)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/20.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Very Low")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 20.1 || Mean_Emotive_Subscale1 <= 40) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Low)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/40.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Low")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 40.1 || Mean_Emotive_Subscale1 <= 60) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Moderate)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/60.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Moderate")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 60.1 || Mean_Emotive_Subscale1 <= 80) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.High)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/80.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("High")
                          }
      
                          else if((Mean_Emotive_Subscale1 == 80.1 || Mean_Emotive_Subscale1 >= 80) && data.Reference === "Emotive-1st-subscale"){
                            $('#Emotive_Subscale1').text(data.Very_High)
                            $(".img-What-My-Team-Thinks-of-Me").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale1/100.png")
                            $(".score-What-My-Team-Thinks-of-Me").text("Very High")
                          }
      
                        //Emotive subscale 2
                          let Total_Emotive_Subscale2 = high_percentile_score_ER2 + high_percentile_score_ER3 + high_percentile_score_ER5
                          let Mean_Emotive_Subscale2 = Total_Emotive_Subscale2 / 3
                          if(Mean_Emotive_Subscale2 <= 20 && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Very_Low)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/20.png")
                            $(".score-Feeling-Negative-Thoughts").text("Very Low")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 20.1 || Mean_Emotive_Subscale2 <= 40) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Low)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/40.png")
                            $(".score-Feeling-Negative-Thoughts").text("Low")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 40.1 || Mean_Emotive_Subscale2 <= 60) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Moderate)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/60.png")
                            $(".score-Feeling-Negative-Thoughts").text("Moderate")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 60.1 || Mean_Emotive_Subscale2 <= 80) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.High)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/80.png")
                            $(".score-Feeling-Negative-Thoughts").text("High")
                          }
      
                          else if((Mean_Emotive_Subscale2 == 80.1 || Mean_Emotive_Subscale2 >= 80) && data.Reference === "Emotive-2nd-subscale"){
                            $('#Emotive_Subscale2').text(data.Very_High)
                            $(".img-Feeling-Negative-Thoughts").attr("src", "./Chart-Images/Speedometer/Pressure-Point/orange/subscale2/100.png")
                            $(".score-Feeling-Negative-Thoughts").text("Very High")
                          }
      
                        // Dominating
                          let high_percentile_score_E = 0 
                          let high_percentile_score_E1 = 0
                          let high_percentile_score_E2 = 0
                          let high_percentile_score_E3 = 0
                          let high_percentile_score_E4 = 0
                          let high_percentile_score_E5 = 0
                          let high_percentile_score_E6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_E = res.data[0].percentile_E * .8
                            high_percentile_score_E1 = res.data[0].percentile_E1 * .8
                            high_percentile_score_E2 = res.data[0].percentile_E2 * .8
                            high_percentile_score_E3 = res.data[0].percentile_E3 * .8
                            high_percentile_score_E4 = res.data[0].percentile_E4 * .8
                            high_percentile_score_E5 = res.data[0].percentile_E5 * .8
                            high_percentile_score_E6 = res.data[0].percentile_E6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.0
                            high_percentile_score_E1 = res.data[0].percentile_E1 * 1.0
                            high_percentile_score_E2 = res.data[0].percentile_E2 * 1.0
                            high_percentile_score_E3 = res.data[0].percentile_E3 * 1.0
                            high_percentile_score_E4 = res.data[0].percentile_E4 * 1.0
                            high_percentile_score_E5 = res.data[0].percentile_E5 * 1.0
                            high_percentile_score_E6 = res.data[0].percentile_E6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_E = res.data[0].percentile_E * 1.2
                            high_percentile_score_E1 = res.data[0].percentile_E1 * 1.2
                            high_percentile_score_E2 = res.data[0].percentile_E2 * 1.2
                            high_percentile_score_E3 = res.data[0].percentile_E3 * 1.2
                            high_percentile_score_E4 = res.data[0].percentile_E4 * 1.2
                            high_percentile_score_E5 = res.data[0].percentile_E5 * 1.2
                            high_percentile_score_E6 = res.data[0].percentile_E6 * 1.2
                          }
                        //End of 1st computation
      
                        //Dominating subscale 1
                          let Total_Dominating_Subscale1 = high_percentile_score_E3 + high_percentile_score_E4 + high_percentile_score_E5
                          let Mean_Dominating_Subscale1 = Total_Dominating_Subscale1 / 3
                          if(Mean_Dominating_Subscale1 <= 20 && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Very_Low)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/20.png")
                            $(".score-Controlling-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 20.1 || Mean_Dominating_Subscale1 <= 40) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Low)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/40.png")
                            $(".score-Controlling-the-Team").text("Low")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 40.1 || Mean_Dominating_Subscale1 <= 60) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Moderate)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/60.png")
                            $(".score-Controlling-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 60.1 || Mean_Dominating_Subscale1 <= 80) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.High)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/80.png")
                            $(".score-Controlling-the-Team").text("High")
                          }
      
                          else if((Mean_Dominating_Subscale1 == 80.1 || Mean_Dominating_Subscale1 >= 80) && data.Reference === "Dominating-1st-subscale"){
                            $('#Dominating_Subscale1').text(data.Very_High)
                            $(".img-Controlling-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale1/100.png")
                            $(".score-Controlling-the-Team").text("Very High")
                          }
      
                        //Dominating subscale 2
                          let Total_Dominating_Subscale2 = high_percentile_score_E1 + high_percentile_score_E2 + high_percentile_score_E6
                          let Mean_Dominating_Subscale2 = Total_Dominating_Subscale2 / 3
                          if(Mean_Dominating_Subscale2 <= 20 && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Very_Low)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/20.png")
                            $(".score-Engaging-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 20.1 || Mean_Dominating_Subscale2 <= 40) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Low)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/40.png")
                            $(".score-Engaging-the-Team").text("Low")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 40.1 || Mean_Dominating_Subscale2 <= 60) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Moderate)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/60.png")
                            $(".score-Engaging-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 60.1 || Mean_Dominating_Subscale2 <= 80) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.High)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/80.png")
                            $(".score-Engaging-the-Team").text("High")
                          }
      
                          else if((Mean_Dominating_Subscale2 == 80.1 || Mean_Dominating_Subscale2 >= 80) && data.Reference === "Dominating-2nd-subscale"){
                            $('#Dominating_Subscale2').text(data.Very_High)
                            $(".img-Engaging-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/teal/subscale2/100.png")
                            $(".score-Engaging-the-Team").text("Very High")
                          }
      
                        // Dreaming
                          let high_percentile_score_O = 0 
                          let high_percentile_score_O1 = 0
                          let high_percentile_score_O2 = 0
                          let high_percentile_score_O3 = 0
                          let high_percentile_score_O4 = 0
                          let high_percentile_score_O5 = 0
                          let high_percentile_score_O6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_O = res.data[0].percentile_O * .8
                            high_percentile_score_O1 = res.data[0].percentile_O1 * .8
                            high_percentile_score_O2 = res.data[0].percentile_O2 * .8
                            high_percentile_score_O3 = res.data[0].percentile_O3 * .8
                            high_percentile_score_O4 = res.data[0].percentile_O4 * .8
                            high_percentile_score_O5 = res.data[0].percentile_O5 * .8
                            high_percentile_score_O6 = res.data[0].percentile_O6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.0
                            high_percentile_score_O1 = res.data[0].percentile_O1 * 1.0
                            high_percentile_score_O2 = res.data[0].percentile_O2 * 1.0
                            high_percentile_score_O3 = res.data[0].percentile_O3 * 1.0
                            high_percentile_score_O4 = res.data[0].percentile_O4 * 1.0
                            high_percentile_score_O5 = res.data[0].percentile_O5 * 1.0
                            high_percentile_score_O6 = res.data[0].percentile_O6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_O = res.data[0].percentile_O * 1.2
                            high_percentile_score_O1 = res.data[0].percentile_O1 * 1.2
                            high_percentile_score_O2 = res.data[0].percentile_O2 * 1.2
                            high_percentile_score_O3 = res.data[0].percentile_O3 * 1.2
                            high_percentile_score_O4 = res.data[0].percentile_O4 * 1.2
                            high_percentile_score_O5 = res.data[0].percentile_O5 * 1.2
                            high_percentile_score_O6 = res.data[0].percentile_O6 * 1.2
                          }
                        //End of 1st computation
      
                        //Dreaming subscale 1
                          let Total_Dreaming_Subscale1 = high_percentile_score_O1 + high_percentile_score_O2 + high_percentile_score_O5
                          let Mean_Dreaming_Subscale1 = Total_Dreaming_Subscale1 / 3
                          if(Mean_Dreaming_Subscale1 <= 20 && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Very_Low)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/20.png")
                            $(".score-Lost-in-Ideas").text("Very Low")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 20.1 || Mean_Dreaming_Subscale1 <= 40) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Low)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/40.png")
                            $(".score-Lost-in-Ideas").text("Low")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 40.1 || Mean_Dreaming_Subscale1 <= 60) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Moderate)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/60.png")
                            $(".score-Lost-in-Ideas").text("Moderate")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 60.1 || Mean_Dreaming_Subscale1 <= 80) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.High)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/80.png")
                            $(".score-Lost-in-Ideas").text("High")
                          }
      
                          else if((Mean_Dreaming_Subscale1 == 80.1 || Mean_Dreaming_Subscale1 >= 80) && data.Reference === "Dreaming-1st-subscale"){
                            $('#Dreaming_Subscale1').text(data.Very_High)
                            $(".img-Lost-in-Ideas").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale1/100.png")
                            $(".score-Lost-in-Ideas").text("Very High")
                          }
      
                        //Dreaming subscale 2
                          let Total_Dreaming_Subscale2 = high_percentile_score_O3 + high_percentile_score_O4 + high_percentile_score_O6
                          let Mean_Dreaming_Subscale2 = Total_Dreaming_Subscale2 / 3
                          if(Mean_Dreaming_Subscale2 <= 20 && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Very_Low)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/20.png")
                            $(".score-Being-Dramatic").text("Very Low")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 20.1 || Mean_Dreaming_Subscale2 <= 40) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Low)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/40.png")
                            $(".score-Being-Dramatic").text("Low")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 40.1 || Mean_Dreaming_Subscale2 <= 60) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Moderate)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/60.png")
                            $(".score-Being-Dramatic").text("Moderate")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 60.1 || Mean_Dreaming_Subscale2 <= 80) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.High)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/80.png")
                            $(".score-Being-Dramatic").text("High")
                          }
      
                          else if((Mean_Dreaming_Subscale2 == 80.1 || Mean_Dreaming_Subscale2 >= 80) && data.Reference === "Dreaming-2nd-subscale"){
                            $('#Dreaming_Subscale2').text(data.Very_High)
                            $(".img-Being-Dramatic").attr("src", "./Chart-Images/Speedometer/Pressure-Point/purple/subscale2/100.png")
                            $(".score-Being-Dramatic").text("Very High")
                          }
      
                        // Amenable
                          let high_percentile_score_A = 0 
                          let high_percentile_score_A1 = 0
                          let high_percentile_score_A2 = 0
                          let high_percentile_score_A3 = 0
                          let high_percentile_score_A4 = 0
                          let high_percentile_score_A5 = 0
                          let high_percentile_score_A6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_A = res.data[0].percentile_A * .8
                            high_percentile_score_A1 = res.data[0].percentile_A1 * .8
                            high_percentile_score_A2 = res.data[0].percentile_A2 * .8
                            high_percentile_score_A3 = res.data[0].percentile_A3 * .8
                            high_percentile_score_A4 = res.data[0].percentile_A4 * .8
                            high_percentile_score_A5 = res.data[0].percentile_A5 * .8
                            high_percentile_score_A6 = res.data[0].percentile_A6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.0
                            high_percentile_score_A1 = res.data[0].percentile_A1 * 1.0
                            high_percentile_score_A2 = res.data[0].percentile_A2 * 1.0
                            high_percentile_score_A3 = res.data[0].percentile_A3 * 1.0
                            high_percentile_score_A4 = res.data[0].percentile_A4 * 1.0
                            high_percentile_score_A5 = res.data[0].percentile_A5 * 1.0
                            high_percentile_score_A6 = res.data[0].percentile_A6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_A = res.data[0].percentile_A * 1.2
                            high_percentile_score_A1 = res.data[0].percentile_A1 * 1.2
                            high_percentile_score_A2 = res.data[0].percentile_A2 * 1.2
                            high_percentile_score_A3 = res.data[0].percentile_A3 * 1.2
                            high_percentile_score_A4 = res.data[0].percentile_A4 * 1.2
                            high_percentile_score_A5 = res.data[0].percentile_A5 * 1.2
                            high_percentile_score_A6 = res.data[0].percentile_A6 * 1.2
                          }
                        //End of 1st computation
                        console.log('test Amenable')
                        //Amenable subscale 1
                          let Total_Amenable_Subscale1 = high_percentile_score_A1 + high_percentile_score_A2 + high_percentile_score_A5
                          let Mean_Amenable_Subscale1 = Total_Amenable_Subscale1 / 3
                          if(Mean_Amenable_Subscale1 <= 20 && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Very_Low)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/20.png")
                            $(".score-Trust-in-the-Team").text("Very Low")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 20.1 || Mean_Amenable_Subscale1 <= 40) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Low)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/40.png")
                            $(".score-Trust-in-the-Team").text("Low")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 40.1 || Mean_Amenable_Subscale1 <= 60) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Moderate)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/60.png")
                            $(".score-Trust-in-the-Team").text("Moderate")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 60.1 || Mean_Amenable_Subscale1 <= 80) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.High)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/80.png")
                            $(".score-Trust-in-the-Team").text("High")
                          }
      
                          else if((Mean_Amenable_Subscale1 == 80.1 || Mean_Amenable_Subscale1 >= 80) && data.Reference === "Amenable-1st-subscale"){
                            $('#Amenable_Subscale1').text(data.Very_High)
                            $(".img-Trust-in-the-Team").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale1/100.png")
                            $(".score-Trust-in-the-Team").text("Very High")
                          }
      
                        //Amenable subscale 2
                          let Total_Amenable_Subscale2 = high_percentile_score_A3 + high_percentile_score_A4 + high_percentile_score_A6
                          let Mean_Amenable_Subscale2 = Total_Amenable_Subscale2 / 3
                          if(Mean_Amenable_Subscale2 <= 20 && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Very_Low)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/20.png")
                            $(".score-Self-versus-others-interests").text("Very Low")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 20.1 || Mean_Amenable_Subscale2 <= 40) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Low)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/40.png")
                            $(".score-Self-versus-others-interests").text("Low")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 40.1 || Mean_Amenable_Subscale2 <= 60) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Moderate)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/60.png")
                            $(".score-Self-versus-others-interests").text("Moderate")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 60.1 || Mean_Amenable_Subscale2 <= 80) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.High)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/80.png")
                            $(".score-Self-versus-others-interests").text("High")
                          }
      
                          else if((Mean_Amenable_Subscale2 == 80.1 || Mean_Amenable_Subscale2 >= 80) && data.Reference === "Amenable-2nd-subscale"){
                            $('#Amenable_Subscale2').text(data.Very_High)
                            $(".img-Self-versus-others-interests").attr("src", "./Chart-Images/Speedometer/Pressure-Point/blue/subscale2/100.png")
                            $(".score-Self-versus-others-interests").text("Very High")
                          }
      
                        // Conforming
                          let high_percentile_score_C = 0 
                          let high_percentile_score_C1 = 0
                          let high_percentile_score_C2 = 0
                          let high_percentile_score_C3 = 0
                          let high_percentile_score_C4 = 0
                          let high_percentile_score_C5 = 0
                          let high_percentile_score_C6 = 0
      
                          if(res.data[0].percentile_ER <= 20) {
                            high_percentile_score_C = res.data[0].percentile_C * .8
                            high_percentile_score_C1 = res.data[0].percentile_C1 * .8
                            high_percentile_score_C2 = res.data[0].percentile_C2 * .8
                            high_percentile_score_C3 = res.data[0].percentile_C3 * .8
                            high_percentile_score_C4 = res.data[0].percentile_C4 * .8
                            high_percentile_score_C5 = res.data[0].percentile_C5 * .8
                            high_percentile_score_C6 = res.data[0].percentile_C6 * .8
                          }
                          else if(res.data[0].percentile_ER == 20.1 || res.data[0].percentile_ER <= 79.9) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.0
                            high_percentile_score_C1 = res.data[0].percentile_C1 * 1.0
                            high_percentile_score_C2 = res.data[0].percentile_C2 * 1.0
                            high_percentile_score_C3 = res.data[0].percentile_C3 * 1.0
                            high_percentile_score_C4 = res.data[0].percentile_C4 * 1.0
                            high_percentile_score_C5 = res.data[0].percentile_C5 * 1.0
                            high_percentile_score_C6 = res.data[0].percentile_C6 * 1.0
                          }
                          else if(res.data[0].percentile_ER == 80 || res.data[0].percentile_ER >= 80) {
                            high_percentile_score_C = res.data[0].percentile_C * 1.2
                            high_percentile_score_C1 = res.data[0].percentile_C1 * 1.2
                            high_percentile_score_C2 = res.data[0].percentile_C2 * 1.2
                            high_percentile_score_C3 = res.data[0].percentile_C3 * 1.2
                            high_percentile_score_C4 = res.data[0].percentile_C4 * 1.2
                            high_percentile_score_C5 = res.data[0].percentile_C5 * 1.2
                            high_percentile_score_C6 = res.data[0].percentile_C6 * 1.2
                          }
                        //End of 1st computation
      
                        //Conforming subscale 1
                          let Total_Conforming_Subscale1 = high_percentile_score_C3 + high_percentile_score_C4 + high_percentile_score_C5
                          let Mean_Conforming_Subscale1 = Total_Conforming_Subscale1 / 3
                          if(Mean_Conforming_Subscale1 <= 20 && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Very_Low)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/20.png")
                            $(".score-Compliance").text("Very Low")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 20.1 || Mean_Conforming_Subscale1 <= 40) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Low)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/40.png")
                            $(".score-Compliance").text("Low")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 40.1 || Mean_Conforming_Subscale1 <= 60) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Moderate)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/60.png")
                            $(".score-Compliance").text("Moderate")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 60.1 || Mean_Conforming_Subscale1 <= 80) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.High)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/80.png")
                            $(".score-Compliance").text("High")
                          }
      
                          else if((Mean_Conforming_Subscale1 == 80.1 || Mean_Conforming_Subscale1 >= 80) && data.Reference === "Conforming-1st-subscale"){
                            $('#Conforming_Subscale1').text(data.Very_High)
                            $(".img-Compliance").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale1/100.png")
                            $(".score-Compliance").text("Very High")
                          }
      
                        //Conforming subscale 2
                          let Total_Conforming_Subscale2 = high_percentile_score_C2 + high_percentile_score_C1 + high_percentile_score_C6
                          let Mean_Conforming_Subscale2 = Total_Conforming_Subscale2 / 3
                          if(Mean_Conforming_Subscale2 <= 20 && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Very_Low)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/20.png")
                            $(".score-Structure").text("Very Low")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 20.1 || Mean_Conforming_Subscale2 <= 40) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Low)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/40.png")
                            $(".score-Structure").text("Low")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 40.1 || Mean_Conforming_Subscale2 <= 60) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Moderate)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/60.png")
                            $(".score-Structure").text("Moderate")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 60.1 || Mean_Conforming_Subscale2 <= 80) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.High)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/80.png")
                            $(".score-Structure").text("High")
                          }
      
                          else if((Mean_Conforming_Subscale2 == 80.1 || Mean_Conforming_Subscale2 >= 80) && data.Reference === "Conforming-2nd-subscale"){
                            $('#Conforming_Subscale2').text(data.Very_High)
                            $(".img-Structure").attr("src", "./Chart-Images/Speedometer/Pressure-Point/yellow/subscale2/100.png")
                            $(".score-Structure").text("Very High")
                          }
      
                        //End of Pressure Point
                        }      
      
      
                        for (const data of jsonData) {      
                          // ER
                          if (res.data[0].z_score_desc_ER === "VERY HIGH" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very high.' + ' ' + '`' + `)
                            $("#replace-emotional-reactiveness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER === "HIGH" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is high.' + ' ' + '`' + `)
                            $("#replace-emotional-reactiveness").append(data.High)
                          } else if (res.data[0].z_score_desc_ER === "TYPICAL" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is typical.' + ' ' + '`' + `)
                            $("#replace-emotional-reactiveness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_ER === "LOW" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is low.' + ' ' + '`' + `)
                            $("#replace-emotional-reactiveness").append(data.Low)
                          } else if (res.data[0].z_score_desc_ER === "VERY LOW" && data.Reference === "ER") {
                            $("#replace-emotional-reactiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER)))} percentile which is very low.' + ' ' + '`' + `)
                            $("#replace-emotional-reactiveness").append(data.Very_low)
                          } 
      
                          if (res.data[0].z_score_desc_ER1 === "VERY HIGH" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very high' + '`' + `)
                            $("#anxiety").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER1 === "HIGH" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is high' + '`' + `)
                            $("#anxiety").text(data.High)
                          } else if (res.data[0].z_score_desc_ER1 === "TYPICAL" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is typical' + '`' + `)
                            $("#anxiety").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER1 === "LOW" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is low' + '`' + `)
                            $("#anxiety").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER1 === "VERY LOW" && data.Reference === "ER1") {
                            $("#replace-anxiety").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER1)))} percentile which is very low' + '`' + `)
                            $("#anxiety").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER2 === "VERY HIGH" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very high' + '`' + `)
                            $("#anger").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER2 === "HIGH" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is high' + '`' + `)
                            $("#anger").text(data.High)
                          } else if (res.data[0].z_score_desc_ER2 === "TYPICAL" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is typical' + '`' + `)
                            $("#anger").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER2 === "LOW" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is low' + '`' + `)
                            $("#anger").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER2 === "VERY LOW" && data.Reference === "ER2") {
                            $("#replace-anger").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER2)))} percentile which is very low' + '`' + `)
                            $("#anger").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_ER3 === "VERY HIGH" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very high' + '`' + `)
                            $("#sadness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER3 === "HIGH" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is high' + '`' + `)
                            $("#sadness").text(data.High)
                          } else if (res.data[0].z_score_desc_ER3 === "TYPICAL" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is typical' + '`' + `)
                            $("#sadness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER3 === "LOW" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is low' + '`' + `)
                            $("#sadness").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER3 === "VERY LOW" && data.Reference === "ER3") {
                            $("#replace-sadness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER3)))} percentile which is very low' + '`' + `)
                            $("#sadness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER4 === "VERY HIGH" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very high' + '`' + `)
                            $("#self-consciousness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER4 === "HIGH" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is high' + '`' + `)
                            $("#self-consciousness").text(data.High)
                          } else if (res.data[0].z_score_desc_ER4 === "TYPICAL" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is typical' + '`' + `)
                            $("#self-consciousness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER4 === "LOW" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is low' + '`' + `)
                            $("#self-consciousness").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER4 === "VERY LOW" && data.Reference === "ER4") {
                            $("#replace-self-consciousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER4)))} percentile which is very low' + '`' + `)
                            $("#self-consciousness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER5 === "VERY HIGH" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very high' + '`' + `)
                            $("#self-indulgence").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER5 === "HIGH" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is high' + '`' + `)
                            $("#self-indulgence").text(data.High)
                          } else if (res.data[0].z_score_desc_ER5 === "TYPICAL" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is typical' + '`' + `)
                            $("#self-indulgence").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER5 === "LOW" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is low' + '`' + `)
                            $("#self-indulgence").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER5 === "VERY LOW" && data.Reference === "ER5") {
                            $("#replace-self-indulgence").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER5)))} percentile which is very low' + '`' + `)
                            $("#self-indulgence").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_ER6 === "VERY HIGH" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very high' + '`' + `)
                            $("#vulnerability").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_ER6 === "HIGH" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is high' + '`' + `)
                            $("#vulnerability").text(data.High)
                          } else if (res.data[0].z_score_desc_ER6 === "TYPICAL" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is typical' + '`' + `)
                            $("#vulnerability").text(data.Typical)
                          } else if (res.data[0].z_score_desc_ER6 === "LOW" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is low' + '`' + `)
                            $("#vulnerability").text(data.Low)
                          } else if (res.data[0].z_score_desc_ER6 === "VERY LOW" && data.Reference === "ER6") {
                            $("#replace-vulnerability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_ER6)))} percentile which is very low' + '`' + `)
                            $("#vulnerability").text(data.Very_Low)
                          }
      
                          // E
                          if (res.data[0].z_score_desc_E === "VERY HIGH" && data.Reference === "E") { 
                            $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very high.' + ' ' + '`' + `)
                            $("#replace-extraversion").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_E === "HIGH" && data.Reference === "E") {
                            $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is high.' + ' ' + '`' + `)
                            $("#replace-extraversion").append(data.High)
                          } else if (res.data[0].z_score_desc_E === "TYPICAL" && data.Reference === "E") {
                            $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is typical.' + ' ' + '`' + `)
                            $("#replace-extraversion").append(data.Typical)
                          } else if (res.data[0].z_score_desc_E === "LOW" && data.Reference === "E") {
                            $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is low.' + ' ' + '`' + `)
                            $("#replace-extraversion").append(data.Low)
                          } else if (res.data[0].z_score_desc_E === "VERY LOW" && data.Reference === "E") {
                            $("#replace-extraversion").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E)))} percentile which is very low.' + ' ' + '`' + `)
                            $("#replace-extraversion").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E1 === "VERY HIGH" && data.Reference === "E1") { 
                            $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very high' + '`' + `)
                            $("#friendliness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E1 === "HIGH" && data.Reference === "E1") {
                            $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is high' + '`' + `)
                            $("#friendliness").text(data.High)
                          } else if (res.data[0].z_score_desc_E1 === "TYPICAL" && data.Reference === "E1") {
                            $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is typical' + '`' + `)
                            $("#friendliness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E1 === "LOW" && data.Reference === "E1") {
                            $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is low' + '`' + `)
                            $("#friendliness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E1 === "VERY LOW" && data.Reference === "E1") {
                            $("#replace-friendliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E1)))} percentile which is very low' + '`' + `)
                            $("#friendliness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E2 === "VERY HIGH" && data.Reference === "E2") {
                            $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very high' + '`' + `)
                            $("#sociability").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E2 === "HIGH" && data.Reference === "E2") {
                            $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is high' + '`' + `)
                            $("#sociability").text(data.High)
                          } else if (res.data[0].z_score_desc_E2 === "TYPICAL" && data.Reference === "E2") {
                            $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is typical' + '`' + `)
                            $("#sociability").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E2 === "LOW" && data.Reference === "E2") {
                            $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is low' + '`' + `)
                            $("#sociability").text(data.Low)
                          } else if (res.data[0].z_score_desc_E2 === "VERY LOW" && data.Reference === "E2") {
                            $("#replace-sociability").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E2)))} percentile which is very low' + '`' + `)
                            $("#sociability").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_E3 === "VERY HIGH" && data.Reference === "E3") {
                            $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very high' + '`' + `)
                            $("#assertiveness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E3 === "HIGH" && data.Reference === "E3") {
                            $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is high' + '`' + `)
                            $("#assertiveness").text(data.High)
                          } else if (res.data[0].z_score_desc_E3 === "TYPICAL" && data.Reference === "E3") {
                            $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is typical' + '`' + `)
                            $("#assertiveness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E3 === "LOW" && data.Reference === "E3") {
                            $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is low' + '`' + `)
                            $("#assertiveness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E3 === "VERY LOW" && data.Reference === "E3") {
                            $("#replace-assertiveness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E3)))} percentile which is very low' + '`' + `)
                            $("#assertiveness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E4 === "VERY HIGH" && data.Reference === "E4") {
                            $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very high' + '`' + `)
                            $("#activity-level").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E4 === "HIGH" && data.Reference === "E4") {
                            $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is high' + '`' + `)
                            $("#activity-level").text(data.High)
                          } else if (res.data[0].z_score_desc_E4 === "TYPICAL" && data.Reference === "E4") {
                            $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is typical' + '`' + `)
                            $("#activity-level").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E4 === "LOW" && data.Reference === "E4") {
                            $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is low' + '`' + `)
                            $("#activity-level").text(data.Low)
                          } else if (res.data[0].z_score_desc_E4 === "VERY LOW" && data.Reference === "E4") {
                            $("#replace-activity-level").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E4)))} percentile which is very low' + '`' + `)
                            $("#activity-level").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E5 === "VERY HIGH" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very high' + '`' + `)
                            $("#excitement-seeking").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E5 === "HIGH" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is high' + '`' + `)
                            $("#excitement-seeking").text(data.High)
                          } else if (res.data[0].z_score_desc_E5 === "TYPICAL" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is typical' + '`' + `)
                            $("#excitement-seeking").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E5 === "LOW" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is low' + '`' + `)
                            $("#excitement-seeking").text(data.Low)
                          } else if (res.data[0].z_score_desc_E5 === "VERY LOW" && data.Reference === "E5") {
                            $("#replace-excitement-seeking").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E5)))} percentile which is very low' + '`' + `)
                            $("#excitement-seeking").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_E6 === "VERY HIGH" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very high' + '`' + `)
                            $("#cheerfulness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_E6 === "HIGH" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is high' + '`' + `)
                            $("#cheerfulness").text(data.High)
                          } else if (res.data[0].z_score_desc_E6 === "TYPICAL" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is typical' + '`' + `)
                            $("#cheerfulness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_E6 === "LOW" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is low' + '`' + `)
                            $("#cheerfulness").text(data.Low)
                          } else if (res.data[0].z_score_desc_E6 === "VERY LOW" && data.Reference === "E6") {
                            $("#replace-cheerfulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_E6)))} percentile which is very low' + '`' + `)
                            $("#cheerfulness").text(data.Very_Low)
                          }
      
                           // O
                          if (res.data[0].z_score_desc_O === "VERY HIGH" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very high.' + ' ' + '`' + `)
                            $("#replace-openness-to-experience").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_O === "HIGH" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is high.' + ' ' + '`' + `)
                            $("#replace-openness-to-experience").append(data.High)
                          } else if (res.data[0].z_score_desc_O === "TYPICAL" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is typical.' + ' ' + '`' + `)
                            $("#replace-openness-to-experience").append(data.Typical)
                          } else if (res.data[0].z_score_desc_O === "LOW" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is low.' + ' ' + '`' + `)
                            $("#replace-openness-to-experience").append(data.Low)
                          } else if (res.data[0].z_score_desc_O === "VERY LOW" && data.Reference === "O") {
                            $("#replace-openness-to-experience").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O)))} percentile which is very low.' + ' ' + '`' + `)
                            $("#replace-openness-to-experience").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O1 === "VERY HIGH" && data.Reference === "O1") {
                            $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very high' + '`' + `)
                            $("#imagination").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O1 === "HIGH" && data.Reference === "O1") {
                            $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is high' + '`' + `)
                            $("#imagination").text(data.High)
                          } else if (res.data[0].z_score_desc_O1 === "TYPICAL" && data.Reference === "O1") {
                            $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is typical' + '`' + `)
                            $("#imagination").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O1 === "LOW" && data.Reference === "O1") {
                            $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is low' + '`' + `)
                            $("#imagination").text(data.Low)
                          } else if (res.data[0].z_score_desc_O1 === "VERY LOW" && data.Reference === "O1") {
                            $("#replace-imagination").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O1)))} percentile which is very low' + '`' + `)
                            $("#imagination").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O2 === "VERY HIGH" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very high' + '`' + `)
                            $("#artistic-Interests").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O2 === "HIGH" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is high' + '`' + `)
                            $("#artistic-Interests").text(data.High)
                          } else if (res.data[0].z_score_desc_O2 === "TYPICAL" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is typical' + '`' + `)
                            $("#artistic-Interests").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O2 === "LOW" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is low' + '`' + `)
                            $("#artistic-Interests").text(data.Low)
                          } else if (res.data[0].z_score_desc_O2 === "VERY LOW" && data.Reference === "O2") {
                            $("#replace-artistic-interests").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O2)))} percentile which is very low' + '`' + `)
                            $("#artistic-Interests").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_O3 === "VERY HIGH" && data.Reference === "O3") {
                            $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very high' + '`' + `)
                            $("#emotionality").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O3 === "HIGH" && data.Reference === "O3") {
                            $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is high' + '`' + `)
                            $("#emotionality").text(data.High)
                          } else if (res.data[0].z_score_desc_O3 === "TYPICAL" && data.Reference === "O3") {
                            $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is typical' + '`' + `)
                            $("#emotionality").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O3 === "LOW" && data.Reference === "O3") {
                            $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is low' + '`' + `)
                            $("#emotionality").text(data.Low)
                          } else if (res.data[0].z_score_desc_O3 === "VERY LOW" && data.Reference === "O3") {
                            $("#replace-emotionality").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O3)))} percentile which is very low' + '`' + `)
                            $("#emotionality").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O4 === "VERY HIGH" && data.Reference === "O4") {
                            $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very high' + '`' + `)
                            $("#adventurousness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O4 === "HIGH" && data.Reference === "O4") {
                            $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is high' + '`' + `)
                            $("#adventurousness").text(data.High)
                          } else if (res.data[0].z_score_desc_O4 === "TYPICAL" && data.Reference === "O4") {
                            $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is typical' + '`' + `)
                            $("#adventurousness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O4 === "LOW" && data.Reference === "O4") {
                            $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is low' + '`' + `)
                            $("#adventurousness").text(data.Low)
                          } else if (res.data[0].z_score_desc_O4 === "VERY LOW" && data.Reference === "O4") {
                            $("#replace-adventurousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O4)))} percentile which is very low' + '`' + `)
                            $("#adventurousness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O5 === "VERY HIGH" && data.Reference === "O5") {
                            $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very high' + '`' + `)
                            $("#cognition").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O5 === "HIGH" && data.Reference === "O5") {
                            $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is high' + '`' + `)
                            $("#cognition").text(data.High)
                          } else if (res.data[0].z_score_desc_O5 === "TYPICAL" && data.Reference === "O5") {
                            $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is typical' + '`' + `)
                            $("#cognition").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O5 === "LOW" && data.Reference === "O5") {
                            $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is low' + '`' + `)
                            $("#cognition").text(data.Low)
                          } else if (res.data[0].z_score_desc_O5 === "VERY LOW" && data.Reference === "O5") {
                            $("#replace-cognition").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O5)))} percentile which is very low' + '`' + `)
                            $("#cognition").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_O6 === "VERY HIGH" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very high' + '`' + `)
                            $("#broad-mindedness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_O6 === "HIGH" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is high' + '`' + `)
                            $("#broad-mindedness").text(data.High)
                          } else if (res.data[0].z_score_desc_O6 === "TYPICAL" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is typical' + '`' + `)
                            $("#broad-mindedness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_O6 === "LOW" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is low' + '`' + `)
                            $("#broad-mindedness").text(data.Low)
                          } else if (res.data[0].z_score_desc_O6 === "VERY LOW" && data.Reference === "O6") {
                            $("#replace-broad-mindedness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_O6)))} percentile which is very low' + '`' + `)
                            $("#broad-mindedness").text(data.Very_Low)
                          }
      
                          // A
                          if (res.data[0].z_score_desc_A === "VERY HIGH" && data.Reference === "A") { 
                            $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very high.' + ' ' + '`' + `)
                            $("#replace-agreeableness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_A === "HIGH" && data.Reference === "A") {
                            $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is high.' + ' ' + '`' + `)
                            $("#replace-agreeableness").append(data.High)
                          } else if (res.data[0].z_score_desc_A === "TYPICAL" && data.Reference === "A") {
                            $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is typical.' + ' ' + '`' + `)
                            $("#replace-agreeableness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_A === "LOW" && data.Reference === "A") {
                            $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is low.' + ' ' + '`' + `)
                            $("#replace-agreeableness").append(data.Low)
                          } else if (res.data[0].z_score_desc_A === "VERY LOW" && data.Reference === "A") {
                            $("#replace-agreeableness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A)))} percentile which is very low.' + ' ' + '`' + `)
                            $("#replace-agreeableness").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A1 === "VERY HIGH" && data.Reference === "A1") { 
                            $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very high' + '`' + `)
                            $("#trust").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A1 === "HIGH" && data.Reference === "A1") {
                            $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is high' + '`' + `)
                            $("#trust").text(data.High)
                          } else if (res.data[0].z_score_desc_A1 === "TYPICAL" && data.Reference === "A1") {
                            $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is typical' + '`' + `)
                            $("#trust").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A1 === "LOW" && data.Reference === "A1") {
                            $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is low' + '`' + `)
                            $("#trust").text(data.Low)
                          } else if (res.data[0].z_score_desc_A1 === "VERY LOW" && data.Reference === "A1") {
                            $("#replace-trust").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A1)))} percentile which is very low' + '`' + `)
                            $("#trust").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A2 === "VERY HIGH" && data.Reference === "A2") {
                            $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very high' + '`' + `)
                            $("#candor").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A2 === "HIGH" && data.Reference === "A2") {
                            $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is high' + '`' + `)
                            $("#candor").text(data.High)
                          } else if (res.data[0].z_score_desc_A2 === "TYPICAL" && data.Reference === "A2") {
                            $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is typical' + '`' + `)
                            $("#candor").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A2 === "LOW" && data.Reference === "A2") {
                            $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is low' + '`' + `)
                            $("#candor").text(data.Low)
                          } else if (res.data[0].z_score_desc_A2 === "VERY LOW" && data.Reference === "A2") {
                            $("#replace-candor").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A2)))} percentile which is very low' + '`' + `)
                            $("#candor").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_A3 === "VERY HIGH" && data.Reference === "A3") {
                            $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very high' + '`' + `)
                            $("#altruism").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A3 === "HIGH" && data.Reference === "A3") {
                            $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is high' + '`' + `)
                            $("#altruism").text(data.High)
                          } else if (res.data[0].z_score_desc_A3 === "TYPICAL" && data.Reference === "A3") {
                            $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is typical' + '`' + `)
                            $("#altruism").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A3 === "LOW" && data.Reference === "A3") {
                            $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is low' + '`' + `)
                            $("#altruism").text(data.Low)
                          } else if (res.data[0].z_score_desc_A3 === "VERY LOW" && data.Reference === "A3") {
                            $("#replace-altruism").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A3)))} percentile which is very low' + '`' + `)
                            $("#altruism").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A4 === "VERY HIGH" && data.Reference === "A4") {
                            $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very high' + '`' + `)
                            $("#cooperation").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A4 === "HIGH" && data.Reference === "A4") {
                            $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is high' + '`' + `)
                            $("#cooperation").text(data.High)
                          } else if (res.data[0].z_score_desc_A4 === "TYPICAL" && data.Reference === "A4") {
                            $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is typical' + '`' + `)
                            $("#cooperation").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A4 === "LOW" && data.Reference === "A4") {
                            $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is low' + '`' + `)
                            $("#cooperation").text(data.Low)
                          } else if (res.data[0].z_score_desc_A4 === "VERY LOW" && data.Reference === "A4") {
                            $("#replace-cooperation").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A4)))} percentile which is very low' + '`' + `)
                            $("#cooperation").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A5 === "VERY HIGH" && data.Reference === "A5") {
                            $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very high' + '`' + `)
                            $("#modesty").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A5 === "HIGH" && data.Reference === "A5") {
                            $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is high' + '`' + `)
                            $("#modesty").text(data.High)
                          } else if (res.data[0].z_score_desc_A5 === "TYPICAL" && data.Reference === "A5") {
                            $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is typical' + '`' + `)
                            $("#modesty").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A5 === "LOW" && data.Reference === "A5") {
                            $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is low' + '`' + `)
                            $("#modesty").text(data.Low)
                          } else if (res.data[0].z_score_desc_A5 === "VERY LOW" && data.Reference === "A5") {
                            $("#replace-modesty").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A5)))} percentile which is very low' + '`' + `)
                            $("#modesty").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_A6 === "VERY HIGH" && data.Reference === "A6") {
                            $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very high' + '`' + `)
                            $("#sympathy").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_A6 === "HIGH" && data.Reference === "A6") {
                            $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is high' + '`' + `)
                            $("#sympathy").text(data.High)
                          } else if (res.data[0].z_score_desc_A6 === "TYPICAL" && data.Reference === "A6") {
                            $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is typical' + '`' + `)
                            $("#sympathy").text(data.Typical)
                          } else if (res.data[0].z_score_desc_A6 === "LOW" && data.Reference === "A6") {
                            $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is low' + '`' + `)
                            $("#sympathy").text(data.Low)
                          } else if (res.data[0].z_score_desc_A6 === "VERY LOW" && data.Reference === "A6") {
                            $("#replace-sympathy").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_A6)))} percentile which is very low' + '`' + `)
                            $("#sympathy").text(data.Very_Low)
                          }
      
                          // C
                          if (res.data[0].z_score_desc_C === "VERY HIGH" && data.Reference === "C") { 
                            $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very high.' + ' ' + '`' + `)
                            $("#replace-conscientiousness").append(data.Very_High)
                          } else if (res.data[0].z_score_desc_C === "HIGH" && data.Reference === "C") {
                            $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is high.' + ' ' + '`' + `)
                            $("#replace-conscientiousness").append(data.High)
                          } else if (res.data[0].z_score_desc_C === "TYPICAL" && data.Reference === "C") {
                            $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is typical.' + ' ' + '`' + `)
                            $("#replace-conscientiousness").append(data.Typical)
                          } else if (res.data[0].z_score_desc_C === "LOW" && data.Reference === "C") {
                            $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is low.' + ' ' + '`' + `)
                            $("#replace-conscientiousness").append(data.Low)
                          } else if (res.data[0].z_score_desc_C === "VERY LOW" && data.Reference === "C") {
                            $("#replace-conscientiousness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C)))} percentile which is very low.' + ' ' + '`' + `)
                            $("#replace-conscientiousness").append(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C1 === "VERY HIGH" && data.Reference === "C1") { 
                            $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very high' + '`' + `)
                            $("#self-belief").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C1 === "HIGH" && data.Reference === "C1") {
                            $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is high' + '`' + `)
                            $("#self-belief").text(data.High)
                          } else if (res.data[0].z_score_desc_C1 === "TYPICAL" && data.Reference === "C1") {
                            $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is typical' + '`' + `)
                            $("#self-belief").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C1 === "LOW" && data.Reference === "C1") {
                            $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is low' + '`' + `)
                            $("#self-belief").text(data.Low)
                          } else if (res.data[0].z_score_desc_C1 === "VERY LOW" && data.Reference === "C1") {
                            $("#replace-self-belief").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C1)))} percentile which is very low' + '`' + `)
                            $("#self-belief").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C2 === "VERY HIGH" && data.Reference === "C2") {
                            $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very high' + '`' + `)
                            $("#orderliness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C2 === "HIGH" && data.Reference === "C2") {
                            $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is high' + '`' + `)
                            $("#orderliness").text(data.High)
                          } else if (res.data[0].z_score_desc_C2 === "TYPICAL" && data.Reference === "C2") {
                            $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is typical' + '`' + `)
                            $("#orderliness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C2 === "LOW" && data.Reference === "C2") {
                            $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is low' + '`' + `)
                            $("#orderliness").text(data.Low)
                          } else if (res.data[0].z_score_desc_C2 === "VERY LOW" && data.Reference === "C2") {
                            $("#replace-orderliness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C2)))} percentile which is very low' + '`' + `)
                            $("#orderliness").text(data.Very_Low)
                          }
                          
                          if (res.data[0].z_score_desc_C3 === "VERY HIGH" && data.Reference === "C3") {
                            $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very high' + '`' + `)
                            $("#dutifulness").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C3 === "HIGH" && data.Reference === "C3") {
                            $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is high' + '`' + `)
                            $("#dutifulness").text(data.High)
                          } else if (res.data[0].z_score_desc_C3 === "TYPICAL" && data.Reference === "C3") {
                            $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is typical' + '`' + `)
                            $("#dutifulness").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C3 === "LOW" && data.Reference === "C3") {
                            $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is low' + '`' + `)
                            $("#dutifulness").text(data.Low)
                          } else if (res.data[0].z_score_desc_C3 === "VERY LOW" && data.Reference === "C3") {
                            $("#replace-dutifulness").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C3)))} percentile which is very low' + '`' + `)
                            $("#dutifulness").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C4 === "VERY HIGH" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very high' + '`' + `)
                            $("#achievement-striving").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C4 === "HIGH" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is high' + '`' + `)
                            $("#achievement-striving").text(data.High)
                          } else if (res.data[0].z_score_desc_C4 === "TYPICAL" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is typical' + '`' + `)
                            $("#achievement-striving").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C4 === "LOW" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is low' + '`' + `)
                            $("#achievement-striving").text(data.Low)
                          } else if (res.data[0].z_score_desc_C4 === "VERY LOW" && data.Reference === "C4") {
                            $("#replace-achievement-striving").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C4)))} percentile which is very low' + '`' + `)
                            $("#achievement-striving").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C5 === "VERY HIGH" && data.Reference === "C5") {
                            $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very high' + '`' + `)
                            $("#self-discipline").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C5 === "HIGH" && data.Reference === "C5") {
                            $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is high' + '`' + `)
                            $("#self-discipline").text(data.High)
                          } else if (res.data[0].z_score_desc_C5 === "TYPICAL" && data.Reference === "C5") {
                            $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is typical' + '`' + `)
                            $("#self-discipline").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C5 === "LOW" && data.Reference === "C5") {
                            $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is low' + '`' + `)
                            $("#self-discipline").text(data.Low)
                          } else if (res.data[0].z_score_desc_C5 === "VERY LOW" && data.Reference === "C5") {
                            $("#replace-self-discipline").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C5)))} percentile which is very low' + '`' + `)
                            $("#self-discipline").text(data.Very_Low)
                          }
      
                          if (res.data[0].z_score_desc_C6 === "VERY HIGH" && data.Reference === "C6") {
                            $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very high' + '`' + `)
                            $("#planning").text(data.Very_High)
                          } else if (res.data[0].z_score_desc_C6 === "HIGH" && data.Reference === "C6") {
                            $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is high' + '`' + `)
                            $("#planning").text(data.High)
                          } else if (res.data[0].z_score_desc_C6 === "TYPICAL" && data.Reference === "C6") {
                            $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is typical' + '`' + `)
                            $("#planning").text(data.Typical)
                          } else if (res.data[0].z_score_desc_C6 === "LOW" && data.Reference === "C6") {
                            $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is low' + '`' + `)
                            $("#planning").text(data.Low)
                          } else if (res.data[0].z_score_desc_C6 === "VERY LOW" && data.Reference === "C6") {
                            $("#replace-planning").text(` + '`' + '${ordinal(Math.round(getRanking(res.data[0].percentile_C6)))} percentile which is very low' + '`' + `)
                            $("#planning").text(data.Very_Low)
                          }
      
                        }
                  `
                  // Script removing old radar-chart
                  for (let i = 0; i < input.length; i++) {
                    const data = input[i];
                    if (data.Template === 'Radar-Chart') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          if($('.isRadarChartHasData').text() == "hasData") {
                            `+ res.SuperTrait + `_Chart.destroy()
                          }
                        `
                      }
                    }
                  }
      
                  // Script creating new radar-chart
                  for (let i = 0; i < input.length; i++) {
                    const data = input[i];
                    if (data.Template === 'Radar-Chart') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        
                        output += `
                          let `+ res.SuperTrait + `Select = document.querySelectorAll('.` + res.SuperTrait + `')
                          `+ res.SuperTrait + `_Chart = new Chart(`+res.SuperTrait+`Select, {
                            type: 'radar',
                            data: {
                              labels: [
                        `
                              res.Elements.forEach((response) => {
                                output += `
                                  "`+response.Description+`",
                                `
                              })
                        output += `
                              ],
                              datasets: [
                                {
                                  label: '`+ res.label1 +`',
                                  data: [
                        `
                                res.Elements.forEach((response) => {
                                  output += `
                                    res.data[0].cohort_` + response.Trait + `,
                                  `
                                })
                        output+=`  
                                  ],
                                  fill: true,
                                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                  borderColor: 'rgb(255, 99, 132)',
                                  pointBackgroundColor: 'rgb(255, 99, 132)',
                                  pointBorderColor: '#fff',
                                  pointHoverBackgroundColor: '#fff',
                                  pointHoverBorderColor: 'rgb(255, 99, 132)',
                                },
                                {
                                  label: '`+res.label2+`',
                                  data: [
                        `
                                  res.Elements.forEach((response) => {
                                    output += `
                                      res.data[0].ind_` + response.Trait + `,
                                    `
                                  })
                        output +=`
                                  ],
                                  fill: true,
                                  backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                  borderColor: 'rgb(54, 162, 235)',
                                  pointBackgroundColor: 'rgb(54, 162, 235)',
                                  pointBorderColor: '#fff',
                                  pointHoverBackgroundColor: '#fff',
                                  pointHoverBorderColor: 'rgb(54, 162, 235)',
                                },
                              ],
                            },
                            options: {
                              scales: {
                                r: {
                                  beginAtZero: true,
                                  min: 0,
                                  max: 5,
                                  ticks: {
                                  stepSize: 1,
                                  }
                                },
                              }
                            }
                          })
                        })
                        `
                      }
                    }
                  }
      
                        output +=`
                          const getBarChartData = async () => {
                            try {
                        `
                  for (let i = 0; i < input.length; i++) {
                    const res = input[i]
                    if (res.Template === 'Bar-Chart') { 
                      let obj = JSON.parse(res.Text)
                      for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                            $(".percentile_` + response.Trait + `").html(ordinal(Math.round(getRanking(res.data[0].percentile_` + response.Trait + `))))
                            $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            $(".cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_` + response.Trait + `).toFixed(1))
                            $(".percentile-` + response.Trait + `").css({ "flex-grow": ` + '`' + '${res.data[0].percentile_' + ``+response.Trait+``+ '}' + '`' + `, "background-color": "#`+ response.Color +`"})
                            $(".percentile-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - ${res.data[0].percentile_' + ``+response.Trait+``+ '})' + '`' + `})
                          `
                        })
                      }
                    }
                  } 
                  output +=`
                              } catch (err) {
                                console.log(err)
                              }
                            }
                            getBarChartData()
                          console.log(res.data)
                        })
                        .catch((error) => {
                          console.log(error)
                          if(error.code == "ERR_NETWORK") {
                            return Swal.fire({
                              icon: 'info',
                              title: 'Oops...',
                              html: '<p>Network Error, Please try again later!</p>',
                              customClass: 'norm-wording'
                            })
                          }
                        })
                      })
                    })
                  })
                })
                  `
                }
              }
              
              // api of updating note 
              for (let i = 0; i < input.length; i++) {
                const data = input[i]
                if (data.Template === 'Notes') { 
                  output += `
                    // textarea auto scroll height 
                    $(".note-box__textarea1").on('input', function() {
                      const scroll_height = $(".note-box__textarea1").get(0).scrollHeight;
                      $(".note-box__textarea1").css('height', scroll_height + 'px')
                    })
                    $(".note-box__textarea2").on('input', function() {
                      const scroll_height = $(".note-box__textarea2").get(0).scrollHeight;
                      $(".note-box__textarea2").css('height', scroll_height + 'px')
                    })
      
      
                    // api of getting all notes
                    function getNotes(section_num, resolved_status) {
                      axios.get(` + '`' + `${url}` + '/survey-result-notes/survey-assignment/${survey_assignment_id}/section/${section_num}/resolved-status/${resolved_status}' + '`, config)' + `
                        .then((res) => {
                          let result = res.data
                          let outputHTML = ''
      
                          outputHTML += '<p class="font-weight-bold mt-3">Notes</p>';
                          
                          if(res.data.length == 0) {
                              outputHTML += '<p>There are no notes on this section of the report.</p><hr>'
                          }
      
                          result.map((res, index) => {
                            let shareStatus = res.share_status == 1 ? 'This note is visible to users with access to this report.' : 'This note is only visible to you.'
                            outputHTML += ` +
                              '`' +
                              '<p>${(index+1)}' +
                              '. '+ 
                              '${res.note_content}</p>' +
                              '<p class="note-share-status">${shareStatus}</p>' +
                              '<div class="d-flex">' +
                              '<a data-bs-toggle="modal" data-bs-target="#editNoteModal" class="note-edit-mark__a editNote" data-share-status="${res.share_status}" data-note-id="${res.survey_result_note_id}"data-note-content="${res.note_content}" data-section-num="${section_num}">Edit Note</a>' +
                              '<a class="note-edit-mark__a markAsResolvedBtn ps-4" data-section-num="${section_num}" data-note-id="${res.survey_result_note_id}">Mark as resolved</a>' +
                              '</div>' +
                              '<hr>`' +
                            `
                          })
      
                          outputHTML += ` +
                            '`' +
                            '<div class="mt-2 mb-2">' +
                            '<input type="button" data-bs-toggle="modal" data-bs-target="#addNoteModal" data-section-num="${section_num}" value="Create a new Note" class="btn-submit addNote">' +
                            '</div>`' +  
                          `
      
                          // $(".displayNotes").addClass("lorem");
      
                          $('.displayNotes_'+section_num).html(outputHTML)
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    }
      
                    // api of getting all notes resolved status
                    function getResolvedNotes(section_num, resolved_status) {
                      axios.get(` + '`' + `${url}` + '/survey-result-notes/survey-assignment/${survey_assignment_id}/section/${section_num}/resolved-status/${resolved_status}' + '`, config)' + `
                        .then((res) => {
                            let result = res.data
                            let outputHTML = ''
      
                            result.map((res, index) => {
                                let shareStatus = res.share_status == 1 ? 'This note is visible to users with access to this report.' : 'This note is only visible to you.'
                                outputHTML += ` +
                                '`' +
                                  '<p>${(index+1)} ' +
                                  '. '+ 
                                  '${res.note_content}</p>' +
                                  '<p class="note-share-status">${shareStatus}</p>' +
                                  '<hr>`' +           
                                `
                            })
      
                            $('.displayResolvedNotes_' +section_num).html(outputHTML)
                        })
                        .catch((error) => {
                          console.log(error)
                        })
                    }
      
                    // open add note modal 
                    $(document).on('click','.addNote',function() {
                      $('.add_note_section_num').val($(this).data('section-num'))
                    })
      
                    // add note modal 
                    $(document).on('click', '.addNoteBtn', function() {
                      let isChecked = $('#create_share_with_others').is(':checked'); 
                      let section_num = $('.add_note_section_num').val()
                      const data = {
                        survey_assignment_id : survey_assignment_id,
                        section_num : section_num,
                        share_status : isChecked ? 1 : 0,
                        note_content : $('.create_note_content').val(),
                        org_id : org_id,
                        suborg_id : subOrg_id,
                        created_by : ind_id,
                        modified_by : ind_id,
                      }
                      saveNote(data, section_num);
                    })
      
                    // click mark as resolve link
                    $(document).on('click','.markAsResolvedBtn',function() {
                      const noteId = $(this).data('note-id');
                      const section_num = $(this).data('section-num')
                      const data = {
                          'modified_by' : ind_id
                      }
                      markAsResolved(noteId, data, section_num)
                    })
      
                    // click the checkbox of edit note 
                    $(document).on('click', '.edit_share_with_others', function() {
                      const noteId = $(this).data('note-id');
                      let isChecked = $(this).find('input:checkbox[name=edit_checkbox_share_with_others]');
                      if(isChecked.is(":checked")) {
                        $('.editVisibleToYouAndToOthers').text('This note will be visible to users with access to this report.');
                      }
                      else {
                        $('.editVisibleToYouAndToOthers').text('This note will only be visible to you.');
                      }
                    })
      
                    // click the checkbox of add note 
                    $(document).on('click', '.create_share_with_others', function() {
                      let isChecked = $(this).find('input:checkbox[name=create_checkbox_share_with_others]');
                      if(isChecked.is(":checked")) {
                        $('.addVisibleToYouAndToOthers').text('This note will be visible to users with access to this report.');
                      }
                      else {
                        $('.addVisibleToYouAndToOthers').text('This note will only be visible to you.');
                      }
                    })
      
      
                     // api of creating note 
                    function saveNote(data, section_num) {
                      axios.post(` + '`' + `${url}` + '/survey-result-notes' + '`, data, config)' + `
                        .then((res) => {
                            if(res.data.status == "success") {
                                $('.btn-close').click()
                                $('.create_note_content').val('')
                                $(".create_share_with_others").prop( "checked", false )
                                getNotes(section_num, 0)
                                getResolvedNotes(section_num, 1)
                                Swal.fire({
                                    text: res.data.message,
                                    icon: "success",
                                    confirmButtonText: "Ok",
                                    allowOutsideClick: false
                                })
                            }
                        })
                        .catch(function(error) {
                          console.log(error)
                        })
                    }
      
                    // api of mark as resolve
                    function markAsResolved(noteId, data, section_num) {
                      Swal.fire({
                        text: "Are you sure you want to mark this note as resolved?",
                        showCancelButton: true,
                        confirmButtonColor: '#6c757d',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Resolve',
                        allowOutsideClick: false,
                      }).then((result) => {
                          if (result.isConfirmed) {
                            axios.put(` + '`' + `${url}` + '/survey-result-notes-resolved-status/${noteId}' + '`, data, config)' + `
                            .then((res) => {
                                if(res.data.status == "success") {
                                    getNotes(section_num, 0)
                                    getResolvedNotes(section_num, 1)
                                    Swal.fire({
                                        text: res.data.message,
                                        icon: "success",
                                        confirmButtonText: "Ok",
                                        allowOutsideClick: false
                                    })
                                }
                            })
                            .catch(function(error) {
                              console.log(error)
                            })
                          }
                      })
                    }
      
      
                    // update note modal 
                          $(document).on('click', '.updateNoteBtn', function() {
                              const noteId = $('.edit_note_id').val();
                              const noteContent = $('.edit_note_content').val();
                              let section_num = $('.edit_note_section_num').val()
                              let isChecked = $('#edit_share_with_others').is(':checked'); 
                              const data = {
                                  share_status : isChecked ? 1 : 0,
                                  note_content : noteContent,
                                  modified_by : ind_id,
                              }
                              updateNote(noteId, data, section_num)
                          });
      
                    //  open edit modal - click edit note link
                          $(document).on('click','.editNote',function() {
                              let shareStatus = $(this).data('share-status');
                              if(shareStatus == 1)  $(".edit_share_with_others").prop( "checked", true )
                              let shareWithOthersStatus = shareStatus == 1 ? 'This note is visible to users with access to this report.' : 'This note is only visible to you.'
                              $('.edit_note_id').val($(this).data('note-id'))
                              $('.edit_note_content').val($(this).data('note-content'))
                              $('.edit_note_section_num').val($(this).data('section-num'))
                              $('.editVisibleToYouAndToOthers').text(shareWithOthersStatus)
                          });
                    
                    
                    function updateNote(noteId, data, section_num) {
                      axios.put(` + '`' + `${url}` + '/survey-result-notes/${noteId}' + '`, data, config)' + `
                      .then((res) => {
                          if(res.data.status == "success") {
                              $('.btn-close').click()
                              getNotes(section_num, 0)
                              getResolvedNotes(section_num, 1)
                              Swal.fire({
                                  text: res.data.message,
                                  icon: "success",
                                  confirmButtonText: "Ok",
                                  allowOutsideClick: false
                              })
                          }
                      }).catch(function(error) {
                          console.log(error)
                      })
                  }
                  ` 
    
                  // Script for Notes
                  for (let i = 0; i < input.length; i++) {
                    const data = input[i];
          
                    if (data.Template === 'Section') { 
                      output += `
                        getNotes(`+data.QuestionNumber+`, 0)
                        getResolvedNotes(`+data.QuestionNumber+`, 1)
                      `
                    }
                  }
                  break
                
                }
              }
              // Getting the value in database
              input.forEach(data => {
                if (data.Template === 'Free-text') {
                  output += `
                $('[name="Q` + data.QuestionNumber + `"]').each(countCharacters);
                $('[name="Q` + data.QuestionNumber + `"]').keyup(countCharacters);
                axios
                  .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                  .then((res) => {
                    for (const stored of res.data) {
                      $('[name="Q` + data.QuestionNumber + `"]').val(stored.answer)
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
                `
                }
                if (data.Template === 'TagDisplay') {
                  output += `
                axios
                  .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                  .then((res) => {
                    for (const stored of res.data) {
                      $('[name="Q` + data.QuestionNumber + `"]').val(stored.score)
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
                `
                  if (data.Tag === '20') {
                    output += `
                    axios
                    .get(` + '`' + `${url}` + '/survey_assignment_tags/sharing/${org_id}/${survey_assignment_id}' + '`, config)' + `
                      .then((res) => {
                        for (const data of res.data) {
                          if (data.do_not_show_coach_sharing === 1) {
                            $('#coach-access').attr("hidden", true);
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  `
                  }
                  if (data.Tag === '4') {
                    output += `
                    axios
                    .get(` + '`' + `${url}` + '/survey_assignment_tags/sharing/${org_id}/${survey_assignment_id}' + '`, config)' + `
                      .then((res) => {
                        for (const data of res.data) {
                          if (data.show_coach_group_report === 0) {
                            $('#coach-group-access').attr("hidden", true);
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  `
                  }
                  if (data.Tag === '19') {
                    output += `
                    axios
                    .get(` + '`' + `${url}` + '/survey_assignment_tags/sharing/${org_id}/${survey_assignment_id}' + '`, config)' + `
                      .then((res) => {
                        for (const data of res.data) {
                          if (data.show_hr_report === 0) {
                            $('#hr-access').attr("hidden", true);
                          }
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  `
                  }
                }
                if (data.Template === 'Select_Country') {
                  output += `
                  try {
                    const response = await axios.get('${url}/b5-get-countries', config);
                    const countryList = response.data;
                    
                    let outputHTML = '<select class="form-select" name="Q` + data.QuestionNumber + `" required>'
                    outputHTML += '<option value="" disabled selected>- Select -</option>'
                    
                    countryList.map((result, index) => {
                              outputHTML += ` +
                              '`' +
                                '<option value="${result.country}">${result.country}</option>' +     
                                '`' +    
                              `
                          })
                    outputHTML += '</select>'
                    
                    $('.country_list').html(outputHTML);
                  } catch (error) {
                    console.log(error);
                  }

                    axios
                      .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                      .then((res) => {
                        for (const stored of res.data) {
                          $('[name="Q` + data.QuestionNumber + `"]').val(stored.answer)
                        }
                      })
                      .catch((error) => {
                        console.log(error)
                      })
                  `
                }
                if (data.Template === 'Select_Gender') {
                  output += `
                  axios
                    .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                    .then((res) => {
                      for (const stored of res.data) {
                        $('[name="Q` + data.QuestionNumber + `"]').val(stored.answer)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                `
                }
                if (data.Template === 'Select_Age') {
                  output += `
                  axios
                    .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                    .then((res) => {
                      for (const stored of res.data) {
                        $('[name="Q` + data.QuestionNumber + `"]').val(stored.answer)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                `
                }
                if (data.Template === 'Select_YN') {
                  output += `
                  axios
                    .get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`, config)' + `          
                    .then((res) => {
                      for (const stored of res.data) {
                        $('[name="Q` + data.QuestionNumber + `"]').val(stored.answer)
                      }
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                `
                }
              })
              // Update answers in survey_result table
              for (let i = 0; i < ids.length; i++) {
                const id = ids[i]
                output += `
                  $('[name="Q${id}"]').change(async function () {
                    let value = $('[name="Q${id}"]:checked').val()
                    let label = $('[name="Q${id}"]:checked').next().text()
      
                    try {
                      const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${id}` + '`,' + `
                        {
                          answer: label,
                          score: value,
                          modified_by: ind_id,
                        }, config)
                      if (res.status === 200) {
                        console.log(res)
                      }
                    } catch (error) {
                      console.log(error)
                    }
                  })
                `
              }
              for (let i = 0; i < input.length; i++) {
                const data = input[i]

                if (data.Template == 'Input-Date') {
                  output += `
                    $('[name="Q${data.QuestionNumber}"]').focusout(async function () {
                      let inputValue = $('[name="Q${data.QuestionNumber}"]').val();

                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: inputValue,
                            score: 0,
                            modified_by: ind_id,
                          }, config);
                        if (res.status === 200) {
                          console.log(res);
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    });

                    document.addEventListener('DOMContentLoaded', function() {
                      var dateInput = document.getElementById('${data.QuestionNumber}-date');
                      var date = new Date();
                      var preferredLanguages = navigator.languages || [navigator.language || navigator.userLanguage]; // Get user's preferred languages
                      
                      // Initialize localeFormats object with default format
                      var localeFormats = {
                          default: { // Default format if user's locale is not supported
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                          }
                      };

                      // Add supported locales with their date formats
                      preferredLanguages.forEach(function(locale) {
                          localeFormats[locale] = {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                          };
                      });

                      // Determine the format based on user's preferred language, or fallback to default format
                      var options = localeFormats[preferredLanguages.find(function(locale) {
                          return localeFormats[locale];
                      })] || localeFormats.default;

                      dateInput.value = date.toLocaleDateString(undefined, options);
                  });

                  var date = new Date();
                      var locale = navigator.language || navigator.userLanguage; // Get user's locale
                      console.log('locale', locale)
                    
                  `;
                }

                if (data.Template == 'Free-text') {
                  output += `            
                    $('[name="Q` + data.QuestionNumber + `"]').focusout(async function () {
                      let inputValue = $('[name="Q` + data.QuestionNumber + `"]').val()
      
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: inputValue,
                            score: 0,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  `
                }
                if (data.Template == 'Select_Country') {
                  output += `
                    $(document).on('change', '[name="Q` + data.QuestionNumber + `"]', async function () {
                      let selected = $(this).children('option:selected').val()
      
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: selected,
                            score: 0,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })  
                  `
                }
                if (data.Template == 'Select_Gender') {
                  output += `
                    $('[name="Q` + data.QuestionNumber + `"]').change(async function () {
                      let selected = $(this).children('option:selected').val()
      
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: selected,
                            score: 0,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  `
                }
                if (data.Template == 'Select_Age') {
                  output += `
                    $('[name="Q` + data.QuestionNumber + `"]').change(async function () {
                      let selected = $(this).children('option:selected').val()
            
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: selected,
                            score: 0,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }     
                    })
                  `
                }
                if (data.Template == 'Select_YN') {
                  output += `
                    $('[name="Q` + data.QuestionNumber + `"]').change(async function () {
                      let selected = $(this).children('option:selected').val()
                      let selectedText = $(this).children('option:selected').text()
      
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: selectedText,
                            score: selected,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  `
                }
                if (data.Template === 'TagDisplay') {
                  output += `
                    $('[name="Q` + data.QuestionNumber + `"]').change(async function () {
                      let selected = $(this).children('option:selected').val()
                      let selectedText = $(this).children('option:selected').text()
                  `
                  if (data.Tag === '20') {
                    output += `
                      axios
                        .put(` + '`' + `${url}` + '/survey-assignment/coach-granted/${survey_assignment_id}' + '`,' + `
                        {
                          coach_access_granted: selected,
                          modified_by: ind_id,
                        }, config)
                    `
                  }
                  if (data.Tag === '4') {
                    output += `
                      axios
                        .put(` + '`' + `${url}` + '/survey-assignment/coach-group-granted/${survey_assignment_id}' + '`,' + `
                        {
                          coach_group_access_granted: selected,
                          modified_by: ind_id,
                        }, config)
                    `
                  }
                  if (data.Tag === '19') {
                    output += `
                      axios
                        .put(` + '`' + `${url}` + '/survey-assignment/hr-granted/${survey_assignment_id}' + '`,' + `
                        {
                          hr_access_granted: selected,
                          modified_by: ind_id,
                        }, config)
                    `
                  }
                  output += `
                      try {
                        const res = await axios.put(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}/Q' + `${data.QuestionNumber}` + '`,' + `
                          {
                            answer: selectedText,
                            score: selected,
                            modified_by: ind_id,
                          }, config)
                        if (res.status === 200) {
                          console.log(res)
                        }
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  `
                }
                if(data.Template === 'NumberOfQuestions') {
                  const questionCount = data.Text;
                  let missingAnswerUrl = '';
                  const questionsArray = [];
                  for (let i = 1; i <= questionCount; i++) {
                      questionsArray.push(`Q${i}`);
                  }
                  const questionsArrayString = JSON.stringify(questionsArray);
                  if(questionsArray.length > 0) {
                    missingAnswerUrl = 'dynamic-missing-answer'
                  } else {
                    missingAnswerUrl = 'missing-answer'
                  }
                  output += `
                    $('#submitSurvey').submit(async function (e) {
                      $('#submit_btn').prop('disabled', true)
                      e.preventDefault()
                      let duplicateList = []
                      let calculationEndpoint = ''
        
                      // get calculation endpoint
                      try {
                        const res = await axios.get(` + '`' + `${url}` + '/survey-templates/calculate/${survey_template_id}' + '`, config)' + `
                        if (res.status === 200) {
                          calculationEndpoint = res.data.calculate_survey_endpoint 
                        }
                        console.log(calculationEndpoint)
                      } catch (e) {
                        console.log(e)
                      }
        
                      // check if answer is complete
                      try {
                        const res = await axios.post(` + '`' + `${url}` + '/survey-results/' + `${missingAnswerUrl}` + '/' + '`,' + `
                          {
                            survey_assignment_id: survey_assignment_id,
                            statementNumbers: ${questionsArrayString},
                            modified_by: ind_id,
                          }, config)
                        if (res.data.empty_answer < 1) {
                          completeAnswer = true
                          try {
                            console.log("Complete answer")
                            axios.put(` + '`' + `${url}` + '/survey-assignment/survey-complete/${survey_assignment_id}' + '`,' + `
                            {
                              complete_survey: 1,
                              modified_by: ind_id,
                            }, config)
                          } catch (e) {
                            console.log(e)
                          }
                        } else {
                          completeAnswer = false
                          console.log("Answer Not Complete")
                        }
                      } catch (e) {
                        console.log(e)
                      }
        
                      // check for duplicates
                      try {
                        const res = await axios.post(` + '`' + `${url}` + '/survey-results/duplicate-answer' + '`,' + `
                          {
                            survey_assignment_id: survey_assignment_id,
                            modified_by: ind_id,
                          }, config)
                        if (res.data.length < 1) {
                          noDuplicates = true
                          console.log("No Duplicates")
                          try {
                            axios.put(` + '`' + `${url}` + '/survey-assignment/survey-duplicate/${survey_assignment_id}' + '`,' + `
                              {
                                no_duplicates: 1,
                                modified_by: ind_id,
                              }, config)
                          } catch (e) {
                            console.log(e)
                          }
                        } else {
                          noDuplicates = false
                          for (const data of res.data) {
                            duplicateList.push(data.has_duplicate_survey_result_id)
                          }
                          try {
                            const res = await axios.delete(` + '`' + `${url}` + '/survey-results/delete/duplicate' + '`,' + `
                              {
                                headers: {
                                  token: 'cCW7PW2CRotxuALrBuMob5lXgVhY4xo'
                                },
                                data: {
                                  survey_result_id: duplicateList
                                }
                              }
                            )
                            if (res.status === 200) {
                              console.log("Duplicates Deleted!")
                            }
                          } catch (e) {
                            console.log(e)
                          }
                        }
                      } catch (e) {
                        console.log(e)
                      }
        
                      if (reqReOrder === false) {
                        Swal.fire({
                          title: 'Incomplete!',
                          text: 'Please Re-order the Competency Importance Index!',
                          icon: 'warning',
                          confirmButtonText: 'Go Back',
                          confirmButtonColor: '#3085d6',
                          allowOutsideClick: false,
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            $('#submit_btn').prop('disabled', false)
                          }
                        })
                      }
        
                      if (completeAnswer === false) {
                        Swal.fire({
                          title: 'Incomplete!',
                          text: 'Please complete the survey!',
                          icon: 'warning',
                          confirmButtonText: 'Go Back',
                          confirmButtonColor: '#3085d6',
                          allowOutsideClick: false,
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            $('#submit_btn').prop('disabled', false)
                            document.location.reload()
                          }
                        })
                      }
        
                      if (noDuplicates === false) {
                        Swal.fire({
                          title: 'Deleting duplicates...',
                          text: 'Please resubmit again.',
                          icon: 'info',
                          confirmButtonText: 'Go Back',
                          confirmButtonColor: '#3085d6',
                          allowOutsideClick: false,
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            $('#submit_btn').prop('disabled', false)
                          }
                        })
                      }
        
                      if (calculationEndpoint === '' || calculationEndpoint === undefined) {
                        Swal.fire({
                          title: 'No calculation available',
                          text: 'Please contact customer support.',
                          icon: 'info',
                          confirmButtonText: 'Go Back',
                          confirmButtonColor: '#3085d6',
                          allowOutsideClick: false,
                        })
                        .then((result) => {
                          if (result.isConfirmed) {
                            $('#submit_btn').prop('disabled', false)
                          }
                        })
                      }
        
                      if(reqReOrder === true && completeAnswer === true && noDuplicates === true && calculationEndpoint !== '' && calculationEndpoint !== undefined){
                        Swal.fire({
                          title: 'Are you sure want to submit?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showDenyButton: true,
                          confirmButtonColor: '#6c757d',
                          confirmButtonText: 'Submit',
                          denyButtonText: 'Cancel',
                          allowOutsideClick: false,
                        })
                        .then(async (result) => {
                          if (result.isConfirmed) {
                            let survey_responses = [] 
                            try {
                              const res = await axios.get(` + '`' + `${url}` + '/survey-results/${survey_assignment_id}' + '`, config)' + `
                              if (res.status === 200) {
                                surveyResultList = res.data
                                surveyResultList.forEach((data) => {
                                  let q = data.statement_num
                                  let a = data.answer
                                  let r = data.score
                                  let jsonString = JSON.stringify({ q, a, r })
                                  survey_responses.push(jsonString)
                                })
                              }
                            } catch (e) {
                              console.log(e)
                            }
                            
                            // Calculate survey and insert calculations in b5_norm_raw table
                            try {
                              const res = await axios.post(` + '`' + `${url}` + '/calculate/${calculationEndpoint}' + '`,' + `
                                {
                                  survey_assignment_id: survey_assignment_id
                                }, config)
                              if (res.status === 200) {
                                console.log("Calculations done!")
                              }
                            } catch (e) {
                              console.log(e)
                            }
        
                            // if no duplicate and answer is complete, set submitted status = 1
                            try {
                              const res = await axios.put(` + '`' + `${url}` + '/survey-assignment/${survey_assignment_id}' + '`,' + `
                                {
                                  submitted_status: '1',
                                  stmt_answer: ` + '`[' + '${survey_responses}' + ']`,' + `
                                  modified_by: ind_id,
                                }, config)
                              if (res.status === 200) {
                                try {
                                  axios.get(` + '`' + `${url}` + '/submit-survey-email/${survey_assignment_id}' + '`, config)' + `
                                } catch (e) {
                                  console.log(e)
                                }
                              }
                            } catch (e) {
                              console.log(e)
                            }
        
                            // if nominee submitted, add 1 in parent number of respondents
                            try {
                              const res = await axios.get(` + '`' + `${url}` + '/survey-assignment/view-one/${survey_assignment_id}' + '`, config)' + `
                              if (res.status === 200) {
                                let assignedParentSurveyId = res.data.parent_survey_assignment_id
                                if (assignedParentSurveyId === null || assignedParentSurveyId === undefined) {
                                  console.log('Participant')
                                } else {
                                  axios
                                    .get(` + '`' + `${url}` + '/survey-assignment/view-one/${assignedParentSurveyId}' + '`, config)' + `
                                    .then((res) => {
                                      axios
                                        .put(` + '`' + `${url}` + '/survey-assignment/number-of-respondents/${assignedParentSurveyId}' + '`,' + `
                                        {
                                          number_of_respondents : res.data.number_of_respondents + 1,
                                          modified_by : ind_id
                                        },
                                        config
                                      )
                                    })
                                    .catch((e) => {
                                      console.log(e)
                                    })
                                }
                              }
                            } catch (e) {
                              console.log(e)
                            }
                            Swal.fire({
                              title: "Good job!",
                              text: "Survey submitted successfully!",
                              icon: "success",
                              confirmButtonText: "Done",
                              allowOutsideClick: false
                            }).then((result) => {
                              if (result.isConfirmed) {
                                window.history.back()
                                // console.log("COMPLETED")
                              }
                            })
                          }
                          if (result.isDenied) {
                            $('#submit_btn').prop('disabled', false)
                          }
                        })
                      } 
                    })
          
                  })`
                }
              }
              return output;
            }
          },
        };
      </script>
      <style scoped>
        .display {
          margin-left: 20px;
          margin-right: auto;
          width: 950px;
          height: 350px;
          margin-top: 5px;
          align-content: center;
          margin-bottom: 10px;
        }
      
        table {
          margin-top: 20px;
          margin-bottom: 10px;
          min-width: 1000px;
          max-width: 1000px;
          align-self: center;
        }
      
        #table th {
          border: 1px solid #ddd;
          padding: 8px;
          max-width: 20px;
        }
      
        #table input {
          max-height: 23px;
          overflow: hidden;
          word-wrap: break-word;
        }
      
        #table tr {
          background-color: #f2f2f2;
        }
      
        #table tr:hover #table td>input:hover {
          background-color: #ddd;
        }
      
        #table th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #0e5071;
          color: white;
        }
      
        .survey-creator-container {
          align-self: center;
          background: white;
          border-radius: 20px;
          padding: 20px;
          margin: 20px;
          box-shadow: 0px 2px 10px -4px #000000;
        }
      
        .template {
          outline: 0;
          width: 200px;
          margin-left: 10px;
          border-width: 0 0 0px;
          border-color: grey;
          background-color: #f2f2f2;
          font-family: Arial, Helvetica, sans-serif;
          resize: none;
        }
      
        .type {
          outline: 0;
          margin-left: 10px;
          width: 150px;
          border-width: 0 0 0px;
          border-color: grey;
          background-color: #f2f2f2;
          font-family: Arial, Helvetica, sans-serif;
          resize: none;
        }
      
        .qno {
          outline: 0;
          width: 50px;
          margin-left: 10px;
          border-width: 0 0 0px;
          border-color: grey;
          background-color: #f2f2f2;
          font-family: Arial, Helvetica, sans-serif;
          resize: none;
        }
      
        .text {
          outline: 0;
          border-width: 0 0 0px;
          border-color: grey;
          background-color: #f2f2f2;
          font-family: Arial, Helvetica, sans-serif;
          resize: none;
        }
      
        label {
          font-size: 16px;
          font-weight: 500;
          display: inline-block;
          color: #8c8c8c;
          margin-bottom: 0.5rem;
        }
      
        #displayJSON {
          margin-top: 20px;
          margin-bottom: 10px;
          min-width: 1000px;
          max-width: 1000px;
          align-self: center;
        }
      
        .btns {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      
        .tag-container {
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-weight: bold;
        }
        input, select, option, textarea {
          border: 1px solid grey;
        }
      </style>