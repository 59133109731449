/* #version=0.0.0-0#5 jb 2024-05-22T09:25:44 B3D184CCDE1A711E */
/* #version=0.0.0-0#4 jb 2024-05-22T09:17:28 F74A5D0B8E2C4966 */
export const getTimezoneAbbreviation = (timezone) => {
    const timezoneAbbreviations = {
        "Africa/Abidjan": "GMT",
        "Africa/Accra": "GMT",
        "Africa/Addis_Ababa": "EAT",
        "Africa/Algiers": "CET",
        "Africa/Asmara": "EAT",
        "Africa/Asmera": "EAT",
        "Africa/Bamako": "GMT",
        "Africa/Bangui": "WAT",
        "Africa/Banjul": "GMT",
        "Africa/Bissau": "GMT",
        "Africa/Blantyre": "CAT",
        "Africa/Brazzaville": "WAT",
        "Africa/Bujumbura": "CAT",
        "Africa/Cairo": "EET",
        "Africa/Casablanca": "WET",
        "Africa/Ceuta": "CET",
        "Africa/Conakry": "GMT",
        "Africa/Dakar": "GMT",
        "Africa/Dar_es_Salaam": "EAT",
        "Africa/Djibouti": "EAT",
        "Africa/Douala": "WAT",
        "Africa/El_Aaiun": "WET",
        "Africa/Freetown": "GMT",
        "Africa/Gaborone": "CAT",
        "Africa/Harare": "CAT",
        "Africa/Johannesburg": "SAST",
        "Africa/Juba": "CAT",
        "Africa/Kampala": "EAT",
        "Africa/Khartoum": "CAT",
        "Africa/Kigali": "CAT",
        "Africa/Kinshasa": "WAT",
        "Africa/Lagos": "WAT",
        "Africa/Libreville": "WAT",
        "Africa/Lome": "GMT",
        "Africa/Luanda": "WAT",
        "Africa/Lubumbashi": "CAT",
        "Africa/Lusaka": "CAT",
        "Africa/Malabo": "WAT",
        "Africa/Maputo": "CAT",
        "Africa/Maseru": "SAST",
        "Africa/Mbabane": "SAST",
        "Africa/Mogadishu": "EAT",
        "Africa/Monrovia": "GMT",
        "Africa/Nairobi": "EAT",
        "Africa/Ndjamena": "WAT",
        "Africa/Niamey": "WAT",
        "Africa/Nouakchott": "GMT",
        "Africa/Ouagadougou": "GMT",
        "Africa/Porto-Novo": "WAT",
        "Africa/Sao_Tome": "GMT",
        "Africa/Timbuktu": "GMT",
        "Africa/Tripoli": "EET",
        "Africa/Tunis": "CET",
        "Africa/Windhoek": "CAT",
        "America/Adak": "HST",
        "America/Anchorage": "AKST",
        "America/Anguilla": "AST",
        "America/Antigua": "AST",
        "America/Araguaina": "BRT",
        "America/Argentina/Buenos_Aires": "ART",
        "America/Argentina/Catamarca": "ART",
        "America/Argentina/ComodRivadavia": "ART",
        "America/Argentina/Cordoba": "ART",
        "America/Argentina/Jujuy": "ART",
        "America/Argentina/La_Rioja": "ART",
        "America/Argentina/Mendoza": "ART",
        "America/Argentina/Rio_Gallegos": "ART",
        "America/Argentina/Salta": "ART",
        "America/Argentina/San_Juan": "ART",
        "America/Argentina/San_Luis": "ART",
        "America/Argentina/Tucuman": "ART",
        "America/Argentina/Ushuaia": "ART",
        "America/Aruba": "AST",
        "America/Asuncion": "PYT",
        "America/Atikokan": "EST",
        "America/Atka": "HST",
        "America/Bahia": "BRT",
        "America/Bahia_Banderas": "CST",
        "America/Barbados": "AST",
        "America/Belem": "BRT",
        "America/Belize": "CST",
        "America/Blanc-Sablon": "AST",
        "America/Boa_Vista": "AMT",
        "America/Bogota": "COT",
        "America/Boise": "MST",
        "America/Buenos_Aires": "ART",
        "America/Cambridge_Bay": "MST",
        "America/Campo_Grande": "AMT",
        "America/Cancun": "EST",
        "America/Caracas": "VET",
        "America/Catamarca": "ART",
        "America/Cayenne": "GFT",
        "America/Cayman": "EST",
        "America/Chicago": "CST",
        "America/Chihuahua": "MST",
        "America/Coral_Harbour": "EST",
        "America/Cordoba": "ART",
        "America/Costa_Rica": "CST",
        "America/Creston": "MST",
        "America/Cuiaba": "AMT",
        "America/Curacao": "AST",
        "America/Danmarkshavn": "GMT",
        "America/Dawson": "MST",
        "America/Dawson_Creek": "MST",
        "America/Denver": "MST",
        "America/Detroit": "EST",
        "America/Dominica": "AST",
        "America/Edmonton": "MST",
        "America/Eirunepe": "ACT",
        "America/El_Salvador": "CST",
        "America/Ensenada": "PST",
        "America/Fort_Wayne": "EST",
        "America/Fortaleza": "BRT",
        "America/Glace_Bay": "AST",
        "America/Godthab": "WGT",
        "America/Goose_Bay": "AST",
        "America/Grand_Turk": "AST",
        "America/Grenada": "AST",
        "America/Guadeloupe": "AST",
        "America/Guatemala": "CST",
        "America/Guayaquil": "ECT",
        "America/Guyana": "GYT",
        "America/Halifax": "AST",
        "America/Havana": "CST",
        "America/Hermosillo": "MST",
        "America/Indiana/Indianapolis": "EST",
        "America/Indiana/Knox": "CST",
        "America/Indiana/Marengo": "EST",
        "America/Indiana/Petersburg": "EST",
        "America/Indiana/Tell_City": "CST",
        "America/Indiana/Vevay": "EST",
        "America/Indiana/Vincennes": "EST",
        "America/Indiana/Winamac": "EST",
        "America/Indianapolis": "EST",
        "America/Inuvik": "MST",
        "America/Iqaluit": "EST",
        "America/Jamaica": "EST",
        "America/Jujuy": "ART",
        "America/Juneau": "AKST",
        "America/Kentucky/Louisville": "EST",
        "America/Kentucky/Monticello": "EST",
        "America/Knox_IN": "CST",
        "America/Kralendijk": "AST",
        "America/La_Paz": "BOT",
        "America/Lima": "PET",
        "America/Los_Angeles": "PST",
        "America/Louisville": "EST",
        "America/Lower_Princes": "AST",
        "America/Maceio": "BRT",
        "America/Managua": "CST",
        "America/Manaus": "AMT",
        "America/Marigot": "AST",
        "America/Martinique": "AST",
        "America/Matamoros": "CST",
        "America/Mazatlan": "MST",
        "America/Mendoza": "ART",
        "America/Menominee": "CST",
        "America/Merida": "CST",
        "America/Metlakatla": "AKST",
        "America/Mexico_City": "CST",
        "America/Miquelon": "PMST",
        "America/Moncton": "AST",
        "America/Monterrey": "CST",
        "America/Montevideo": "UYT",
        "America/Montreal": "EST",
        "America/Montserrat": "AST",
        "America/Nassau": "EST",
        "America/New_York": "EST",
        "America/Nipigon": "EST",
        "America/Nome": "AKST",
        "America/Noronha": "FNT",
        "America/North_Dakota/Beulah": "CST",
        "America/North_Dakota/Center": "CST",
        "America/North_Dakota/New_Salem": "CST",
        "America/Ojinaga": "MST",
        "America/Panama": "EST",
        "America/Pangnirtung": "EST",
        "America/Paramaribo": "SRT",
        "America/Phoenix": "MST",
        "America/Port-au-Prince": "EST",
        "America/Port_of_Spain": "AST",
        "America/Porto_Acre": "ACT",
        "America/Porto_Velho": "AMT",
        "America/Puerto_Rico": "AST",
        "America/Punta_Arenas": "CLT",
        "America/Rainy_River": "CST",
        "America/Rankin_Inlet": "CST",
        "America/Recife": "BRT",
        "America/Regina": "CST",
        "America/Resolute": "CST",
        "America/Rio_Branco": "ACT",
        "America/Rosario": "ART",
        "America/Santa_Isabel": "PST",
        "America/Santarem": "BRT",
        "America/Santiago": "CLT",
        "America/Santo_Domingo": "AST",
        "America/Sao_Paulo": "BRT",
        "America/Scoresbysund": "EGT",
        "America/Shiprock": "MST",
        "America/Sitka": "AKST",
        "America/St_Barthelemy": "AST",
        "America/St_Johns": "NST",
        "America/St_Kitts": "AST",
        "America/St_Lucia": "AST",
        "America/St_Thomas": "AST",
        "America/St_Vincent": "AST",
        "America/Swift_Current": "CST",
        "America/Tegucigalpa": "CST",
        "America/Thule": "AST",
        "America/Thunder_Bay": "EST",
        "America/Tijuana": "PST",
        "America/Toronto": "EST",
        "America/Tortola": "AST",
        "America/Vancouver": "PST",
        "America/Whitehorse": "PST",
        "America/Winnipeg": "CST",
        "America/Yakutat": "AKST",
        "America/Yellowknife": "MST",

        "Antarctica/Casey": "CAST",
        "Antarctica/Davis": "DAVT",
        "Antarctica/DumontDUrville": "DDUT",
        "Antarctica/Macquarie": "MIST",
        "Antarctica/Mawson": "MAWT",
        "Antarctica/McMurdo": "NZST",
        "Antarctica/Palmer": "CLT",
        "Antarctica/Rothera": "ROTT",
        "Antarctica/Syowa": "SYOT",
        "Antarctica/Troll": "UTC",
        "Antarctica/Vostok": "VOST",
        "Arctic/Longyearbyen": "CET",
        "Asia/Aden": "AST",
        "Asia/Almaty": "ALMT",
        "Asia/Amman": "EET",
        "Asia/Anadyr": "ANAT",
        "Asia/Aqtau": "AQTT",
        "Asia/Aqtobe": "AQTT",
        "Asia/Ashgabat": "TMT",
        "Asia/Ashkhabad": "TMT",
        "Asia/Atyrau": "AQTT",
        "Asia/Baghdad": "AST",
        "Asia/Bahrain": "AST",
        "Asia/Baku": "AZT",
        "Asia/Bangkok": "ICT",
        "Asia/Barnaul": "KRAT",
        "Asia/Beirut": "EET",
        "Asia/Bishkek": "KGT",
        "Asia/Brunei": "BNT",
        "Asia/Calcutta": "IST",
        "Asia/Chita": "YAKT",
        "Asia/Choibalsan": "CHOT",
        "Asia/Chongqing": "CST",
        "Asia/Chungking": "CST",
        "Asia/Colombo": "IST",
        "Asia/Dacca": "BST",
        "Asia/Damascus": "EET",
        "Asia/Dhaka": "BST",
        "Asia/Dili": "TLT",
        "Asia/Dubai": "GST",
        "Asia/Dushanbe": "TJT",
        "Asia/Famagusta": "EET",
        "Asia/Gaza": "EET",
        "Asia/Harbin": "CST",
        "Asia/Hebron": "EET",
        "Asia/Ho_Chi_Minh": "ICT",
        "Asia/Hong_Kong": "HKT",
        "Asia/Hovd": "HOVT",
        "Asia/Irkutsk": "IRKT",
        "Asia/Istanbul": "TRT",
        "Asia/Jakarta": "WIB",
        "Asia/Jayapura": "WIT",
        "Asia/Jerusalem": "IST",
        "Asia/Kabul": "AFT",
        "Asia/Kamchatka": "PETT",
        "Asia/Karachi": "PKT",
        "Asia/Kashgar": "XJT",
        "Asia/Kathmandu": "NPT",
        "Asia/Katmandu": "NPT",
        "Asia/Khandyga": "YAKT",
        "Asia/Kolkata": "IST",
        "Asia/Krasnoyarsk": "KRAT",
        "Asia/Kuala_Lumpur": "MYT",
        "Asia/Kuching": "MYT",
        "Asia/Kuwait": "AST",
        "Asia/Macao": "CST",
        "Asia/Macau": "CST",
        "Asia/Magadan": "MAGT",
        "Asia/Makassar": "WITA",
        "Asia/Manila": "PHT",
        "Asia/Muscat": "GST",
        "Asia/Nicosia": "EET",
        "Asia/Novokuznetsk": "KRAT",
        "Asia/Novosibirsk": "NOVT",
        "Asia/Omsk": "OMST",
        "Asia/Oral": "ORAT",
        "Asia/Phnom_Penh": "ICT",
        "Asia/Pontianak": "WIB",
        "Asia/Pyongyang": "KST",
        "Asia/Qatar": "AST",
        "Asia/Qyzylorda": "QYZT",
        "Asia/Rangoon": "MMT",
        "Asia/Riyadh": "AST",
        "Asia/Saigon": "ICT",
        "Asia/Sakhalin": "SAKT",
        "Asia/Samarkand": "UZT",
        "Asia/Seoul": "KST",
        "Asia/Shanghai": "CST",
        "Asia/Singapore": "SGT",
        "Asia/Srednekolymsk": "SRET",
        "Asia/Taipei": "CST",
        "Asia/Tashkent": "UZT",
        "Asia/Tbilisi": "GET",
        "Asia/Tehran": "IRST",
        "Asia/Tel_Aviv": "IST",
        "Asia/Thimbu": "BST",
        "Asia/Thimphu": "BST",
        "Asia/Tokyo": "JST",
        "Asia/Tomsk": "KRAT",
        "Asia/Ujung_Pandang": "WITA",
        "Asia/Ulaanbaatar": "ULAT",
        "Asia/Ulan_Bator": "ULAT",
        "Asia/Urumqi": "XJT",
        "Asia/Ust-Nera": "VLAT",
        "Asia/Vientiane": "ICT",
        "Asia/Vladivostok": "VLAT",
        "Asia/Yakutsk": "YAKT",
        "Asia/Yangon": "MMT",
        "Asia/Yekaterinburg": "YEKT",
        "Asia/Yerevan": "AMT",
        "Atlantic/Azores": "AZOT",
        "Atlantic/Bermuda": "AST",
        "Atlantic/Canary": "WET",
        "Atlantic/Cape_Verde": "CVT",
        "Atlantic/Faeroe": "WET",
        "Atlantic/Faroe": "WET",
        "Atlantic/Jan_Mayen": "CET",
        "Atlantic/Madeira": "WET",
        "Atlantic/Reykjavik": "GMT",
        "Atlantic/South_Georgia": "GST",
        "Atlantic/St_Helena": "GMT",
        "Atlantic/Stanley": "FKT",
        "Australia/ACT": "AEST",
        "Australia/Adelaide": "ACST",
        "Australia/Brisbane": "AEST",
        "Australia/Broken_Hill": "ACST",
        "Australia/Canberra": "AEST",
        "Australia/Currie": "AEST",
        "Australia/Darwin": "ACST",
        "Australia/Eucla": "ACWST",
        "Australia/Hobart": "AEST",
        "Australia/LHI": "LHST",
        "Australia/Lindeman": "AEST",
        "Australia/Lord_Howe": "LHST",
        "Australia/Melbourne": "AEST",
        "Australia/NSW": "AEST",
        "Australia/North": "ACST",
        "Australia/Perth": "AWST",
        "Australia/Queensland": "AEST",
        "Australia/South": "ACST",
        "Australia/Sydney": "AEST",
        "Australia/Tasmania": "AEST",
        "Australia/Victoria": "AEST",
        "Australia/West": "AWST",
        "Australia/Yancowinna": "ACST",
        "Brazil/Acre": "ACT",
        "Brazil/DeNoronha": "FNT",
        "Brazil/East": "BRT",
        "Brazil/West": "AMT",
        "CET": "CET",
        "CST6CDT": "CST",
        "Canada/Atlantic": "AST",
        "Canada/Central": "CST",
        "Canada/Eastern": "EST",
        "Canada/Mountain": "MST",
        "Canada/Newfoundland": "NST",
        "Canada/Pacific": "PST",
        "Canada/Saskatchewan": "CST",
        "Canada/Yukon": "PST",
        "Chile/Continental": "CLT",
        "Chile/EasterIsland": "EAST",
        "Cuba": "CST",
        "EET": "EET",
        "EST5EDT": "EST",
        "Egypt": "EET",
        "Eire": "GMT",
        "Etc/GMT": "GMT",
        "Etc/GMT+0": "GMT",
        "Etc/GMT+1": "GMT-1",
        "Etc/GMT+10": "GMT-10",
        "Etc/GMT+11": "GMT-11",
        "Etc/GMT+12": "GMT-12",
        "Etc/GMT+2": "GMT-2",
        "Etc/GMT+3": "GMT-3",
        "Etc/GMT+4": "GMT-4",
        "Etc/GMT+5": "GMT-5",
        "Etc/GMT+6": "GMT-6",
        "Etc/GMT+7": "GMT-7",
        "Etc/GMT+8": "GMT-8",
        "Etc/GMT+9": "GMT-9",
        "Etc/GMT-0": "GMT",
        "Etc/GMT-1": "GMT+1",
        "Etc/GMT-10": "GMT+10",
        "Etc/GMT-11": "GMT+11",
        "Etc/GMT-12": "GMT+12",
        "Etc/GMT-13": "GMT+13",
        "Etc/GMT-14": "GMT+14",
        "Etc/GMT-2": "GMT+2",
        "Etc/GMT-3": "GMT+3",
        "Etc/GMT-4": "GMT+4",
        "Etc/GMT-5": "GMT+5",
        "Etc/GMT-6": "GMT+6",
        "Etc/GMT-7": "GMT+7",
        "Etc/GMT-8": "GMT+8",
        "Etc/GMT-9": "GMT+9",
        "Etc/GMT0": "GMT",
        "Etc/Greenwich": "GMT",
        "Etc/UCT": "UTC",
        "Etc/UTC": "UTC",
        "Etc/Universal": "UTC",
        "Etc/Zulu": "UTC",
        "Europe/Amsterdam": "CET",
        "Europe/Andorra": "CET",
        "Europe/Astrakhan": "MSK",
        "Europe/Athens": "EET",
        "Europe/Belfast": "GMT",
        "Europe/Belgrade": "CET",
        "Europe/Berlin": "CET",
        "Europe/Bratislava": "CET",
        "Europe/Brussels": "CET",
        "Europe/Bucharest": "EET",
        "Europe/Budapest": "CET",
        "Europe/Busingen": "CET",
        "Europe/Chisinau": "EET",
        "Europe/Copenhagen": "CET",
        "Europe/Dublin": "GMT",
        "Europe/Gibraltar": "CET",
        "Europe/Guernsey": "GMT",
        "Europe/Helsinki": "EET",
        "Europe/Isle_of_Man": "GMT",
        "Europe/Istanbul": "TRT",
        "Europe/Jersey": "GMT",
        "Europe/Kaliningrad": "EET",
        "Europe/Kiev": "EET",
        "Europe/Kirov": "MSK",
        "Europe/Lisbon": "WET",
        "Europe/Ljubljana": "CET",
        "Europe/London": "GMT",
        "Europe/Luxembourg": "CET",
        "Europe/Madrid": "CET",
        "Europe/Malta": "CET",
        "Europe/Mariehamn": "EET",
        "Europe/Minsk": "MSK",
        "Europe/Monaco": "CET",
        "Europe/Moscow": "MSK",
        "Europe/Oslo": "CET",
        "Europe/Paris": "CET",
        "Europe/Podgorica": "CET",
        "Europe/Prague": "CET",
        "Europe/Riga": "EET",
        "Europe/Rome": "CET",
        "Europe/Samara": "SAMT",
        "Europe/San_Marino": "CET",
        "Europe/Sarajevo": "CET",
        "Europe/Saratov": "MSK",
        "Europe/Simferopol": "MSK",
        "Europe/Skopje": "CET",
        "Europe/Sofia": "EET",
        "Europe/Stockholm": "CET",
        "Europe/Tallinn": "EET",
        "Europe/Tirane": "CET",
        "Europe/Tiraspol": "EET",
        "Europe/Ulyanovsk": "MSK",
        "Europe/Uzhgorod": "EET",
        "Europe/Vaduz": "CET",
        "Europe/Vatican": "CET",
        "Europe/Vienna": "CET",
        "Europe/Vilnius": "EET",
        "Europe/Volgograd": "MSK",
        "Europe/Warsaw": "CET",
        "Europe/Zagreb": "CET",
        "Europe/Zaporozhye": "EET",
        "Europe/Zurich": "CET",
        "GB": "GMT",
        "GB-Eire": "GMT",
        "GMT": "GMT",
        "GMT+0": "GMT",
        "GMT-0": "GMT",
        "GMT0": "GMT",
        "Greenwich": "GMT",
        "HST": "HST",
        "Hongkong": "HKT",
        "Iceland": "GMT",
        "Indian/Antananarivo": "EAT",
        "Indian/Chagos": "IOT",
        "Indian/Christmas": "CXT",
        "Indian/Cocos": "CCT",
        "Indian/Comoro": "EAT",
        "Indian/Kerguelen": "TFT",
        "Indian/Mahe": "SCT",
        "Indian/Maldives": "MVT",
        "Indian/Mauritius": "MUT",
        "Indian/Mayotte": "EAT",
        "Indian/Reunion": "RET",
        "Iran": "IRST",
        "Israel": "IST",
        "Jamaica": "EST",
        "Japan": "JST",
        "Kwajalein": "MHT",
        "Libya": "EET",
        "MET": "MET",
        "MST": "MST",
        "MST7MDT": "MST",
        "Mexico/BajaNorte": "PST",
        "Mexico/BajaSur": "MST",
        "Mexico/General": "CST",
        "NZ": "NZST",
        "NZ-CHAT": "CHAST",
        "Navajo": "MST",
        "PRC": "CST",
        "PST8PDT": "PST",
        "Pacific/Apia": "WSST",
        "Pacific/Auckland": "NZST",
        "Pacific/Bougainville": "BST",
        "Pacific/Chatham": "CHAST",
        "Pacific/Chuuk": "CHUT",
        "Pacific/Easter": "EASST",
        "Pacific/Efate": "VUT",
        "Pacific/Enderbury": "PHOT",
        "Pacific/Fakaofo": "TKT",
        "Pacific/Fiji": "FJT",
        "Pacific/Funafuti": "TVT",
        "Pacific/Galapagos": "GALT",
        "Pacific/Gambier": "GAMT",
        "Pacific/Guadalcanal": "SBT",
        "Pacific/Guam": "ChST",
        "Pacific/Honolulu": "HST",
        "Pacific/Johnston": "HST",
        "Pacific/Kiritimati": "LINT",
        "Pacific/Kosrae": "KOST",
        "Pacific/Kwajalein": "MHT",
        "Pacific/Majuro": "MHT",
        "Pacific/Marquesas": "MART",
        "Pacific/Midway": "SST",
        "Pacific/Nauru": "NRT",
        "Pacific/Niue": "NUT",
        "Pacific/Norfolk": "NFT",
        "Pacific/Noumea": "NCT",
        "Pacific/Pago_Pago": "SST",
        "Pacific/Palau": "PWT",
        "Pacific/Pitcairn": "PST",
        "Pacific/Pohnpei": "PONT",
        "Pacific/Ponape": "PONT",
        "Pacific/Port_Moresby": "PGT",
        "Pacific/Rarotonga": "CKT",
        "Pacific/Saipan": "ChST",
        "Pacific/Samoa": "SST",
        "Pacific/Tahiti": "TAHT",
        "Pacific/Tarawa": "GILT",
        "Pacific/Tongatapu": "TOT",
        "Pacific/Truk": "CHUT",
        "Pacific/Wake": "WAKT",
        "Pacific/Wallis": "WFT",
        "Pacific/Yap": "CHUT",
        "Poland": "CET",
        "Portugal": "WET",
        "ROC": "CST",
        "ROK": "KST",
        "Singapore": "SGT",
        "Turkey": "TRT",
        "UCT": "UTC",
        "US/Alaska": "AKST",
        "US/Aleutian": "HST",
        "US/Arizona": "MST",
        "US/Central": "CST",
        "US/East-Indiana": "EST",
        "US/Eastern": "EST",
        "US/Hawaii": "HST",
        "US/Indiana-Starke": "CST",
        "US/Michigan": "EST",
        "US/Mountain": "MST",
        "US/Pacific": "PST",
        "US/Samoa": "SST",
        "UTC": "UTC",
        "Universal": "UTC",
        "W-SU": "MSK",
        "WET": "WET",
        "Zulu": "UTC"
      };

    return timezoneAbbreviations[timezone] || ''; // Default to empty string if not found
};

export const replaceTokens = (data, tokenObj, password_link) => {
    //replace the tokens to original word
    let mapObjTokens = {
        RECIPIENT_EMAIL: tokenObj.recipient_email ? tokenObj.recipient_email : '',
        WEBSITE_URL: tokenObj.website_url ? tokenObj.website_url : '',
        WEBSITE_CONTACT_EMAIL: tokenObj.website_contact_email ? tokenObj.website_contact_email : '',
        WEBSITE_SENDER_EMAIL: tokenObj.website_sender_email ? tokenObj.website_sender_email : '',
        WEBSITE_TERMS_URL: tokenObj.website_terms_url ? tokenObj.website_terms_url : '',
        WEBSITE_PRIVACY_URL: tokenObj.website_privacy_url ? tokenObj.website_privacy_url : '',
        DAYS_UNTIL_SURVEY_CLOSE_DATE: tokenObj.days_until_survey_close_date ? tokenObj.days_until_survey_close_date : '',
        SURVEY_INITIAL_CLOSE_DATE: new Date(tokenObj.survey_close_date).toString().slice(4, 21),
        SURVEY_DESCRIPTION: tokenObj.survey_description ? tokenObj.survey_description : '',
        SURVEY_SUBJECT_FULL_NAME: tokenObj.user_full_name ? tokenObj.user_full_name : '',
        SURVEY_SUBJECT_FIRST_NAME: tokenObj.first_name ? tokenObj.first_name : '',
        SURVEY_SUB_ORGANIZATION_NAME: tokenObj.suborg_name ? tokenObj.suborg_name : '',
        USER_SUB_ORGANIZATION_NAME: tokenObj.suborg_name ? tokenObj.suborg_name : '',
        SURVEY_PROGRAM_NAME: tokenObj.program_name ? tokenObj.program_name : '',
        SURVEY_ITERATION_NAME: tokenObj.iteration_name ? tokenObj.iteration_name : '',
        SURVEY_TEMPLATE_NAME: tokenObj.survey_template_name ? tokenObj.survey_template_name : '',
        SURVEY_ACTIVE_REMINDERS: tokenObj.survey_active_reminders ? tokenObj.survey_active_reminders : '',
        NOMINEE_SALUTATION: tokenObj.nominee_salutation ? tokenObj.nominee_salutation : '',
        NOMINEE_MESSAGE: tokenObj.nominee_message ? tokenObj.nominee_message : '',
        USER_FULL_NAME: tokenObj.user_full_name ? tokenObj.user_full_name : tokenObj.nominee_salutation,
        USER_EMAIL: tokenObj.email ? tokenObj.email : '',
        CHOOSE_PASSWORD_LINK: password_link ? password_link : '',
        // LOGO: `<div style="background: ${tokenObj.header_bg_color ? tokenObj.header_bg_color : '#ffffff'}; display:flex; padding: 5px; height: 60px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);">
        //         <img src="${tokenObj.website_url + '' + tokenObj.brand_path}/logo.png" alt="Brand Logo"/>
        //       </div>`,
        LOGO: `<div style="background: ${tokenObj.header_bg_color ? tokenObj.header_bg_color : '#ffffff'}; display:flex; padding: 5px; height: 60px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);">
                <img src="${tokenObj.website_url + '' + tokenObj.brand_path}/logo.png" alt="Brand Logo"/>
              </div>`,
        PARTICIPANT_REPORT_RELEASE_DATE: tokenObj.participant_report_release_date ? tokenObj.participant_report_release_date : '',
        PDF_DATE_CREATED: tokenObj.pdf_date_created ? tokenObj.pdf_date_created : '',
        PDF_TIME_CREATED: tokenObj.pdf_time_created ? tokenObj.pdf_time_created : '',
        REPORT_TITLE: tokenObj.report_title ? tokenObj.report_title : '',
    };

    // remove the dollar sign
    const replaceDollar = data.replaceAll('$', '');
    const replaceLogo = replaceDollar.replaceAll('<strong>LOGO</strong>', 'LOGO');


    // display the sentence with converted tokens
    const convertedData = replaceLogo.replace(/LOGO|USER_EMAIL|RECIPIENT_EMAIL|WEBSITE_URL|NOMINEE_SALUTATION|NOMINEE_MESSAGE|SURVEY_PROGRAM_NAME|SURVEY_SUB_ORGANIZATION_NAME|USER_SUB_ORGANIZATION_NAME|WEBSITE_SENDER_EMAIL|WEBSITE_TERMS_URL|WEBSITE_PRIVACY_URL|WEBSITE_CONTACT_EMAIL|SURVEY_INITIAL_CLOSE_DATE|DAYS_UNTIL_SURVEY_CLOSE_DATE|SURVEY_TEMPLATE_NAME|SURVEY_ACTIVE_REMINDERS|SURVEY_DESCRIPTION|USER_FULL_NAME|SURVEY_SUBJECT_FIRST_NAME|SURVEY_SUBJECT_FULL_NAME|SURVEY_ITERATION_NAME|REPORT_TITLE|PARTICIPANT_REPORT_RELEASE_DATE|DATE_AND_TIME_PDF_CREATED|DOWNLOADED_BY|DOWNLOADED_TIME|DOWNLOADED_DATE|PDF_DATE_CREATED|PDF_TIME_CREATED|REPORT_TITLE|ORG_LOGO|TS_LOGO|CHOOSE_PASSWORD_LINK/g, function (matched) {

        return mapObjTokens[matched];
    });
    return convertedData
}

// this is only for making a nomination screen 
export const replaceTokensForNomination = (data, tokenObj, password_link, nominee_salutation, nominee_message, recipient_email) => {
    // let tokenObj = tokens

    //replace the tokens to original word
    let mapObjTokens = {
        WEBSITE_URL: tokenObj.website_url,
        WEBSITE_CONTACT_EMAIL: tokenObj.website_contact_email,
        WEBSITE_SENDER_EMAIL: tokenObj.website_sender_email,
        WEBSITE_TERMS_URL: tokenObj.website_terms_url,
        WEBSITE_PRIVACY_URL: tokenObj.website_privacy_url,
        DAYS_UNTIL_SURVEY_CLOSE_DATE: tokenObj.days_until_survey_close_date,
        SURVEY_INITIAL_CLOSE_DATE: new Date(tokenObj.survey_close_date).toString().slice(4, 21),
        SURVEY_DESCRIPTION: tokenObj.survey_description,
        SURVEY_SUB_ORGANIZATION_NAME: tokenObj.suborg_name,
        USER_SUB_ORGANIZATION_NAME: tokenObj.suborg_name,
        SURVEY_PROGRAM_NAME: tokenObj.program_name,
        SURVEY_ITERATION_NAME: tokenObj.iteration_name,
        SURVEY_TEMPLATE_NAME: tokenObj.survey_template_name,
        SURVEY_ACTIVE_REMINDERS: tokenObj.survey_active_reminders,
        NOMINEE_MESSAGE: nominee_message,
        USER_FULL_NAME: tokenObj.user_full_name,
        USER_EMAIL: tokenObj.email,
        CHOOSE_PASSWORD_LINK: password_link,
        NOMINEE_SALUTATION: nominee_salutation,
        SURVEY_SUBJECT_FULL_NAME: tokenObj.user_full_name,
        SURVEY_SUBJECT_FIRST_NAME: tokenObj.first_name,
        RECIPIENT_EMAIL: recipient_email,
        LOGO: `<div style="background: ${tokenObj.header_bg_color ? tokenObj.header_bg_color : '#ffffff'}; display:flex; padding: 5px; height: 60px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);">
                <img src="${tokenObj.website_url + '' + tokenObj.brand_path}/logo.png" alt="Brand Logo"/>
              </div>`,
    };

    // remove the dollar sign
    const replaceDollar = data.replaceAll('$', '');

    // display the sentence with converted tokens
    const convertedData = replaceDollar.replace(/LOGO|USER_EMAIL|RECIPIENT_EMAIL|WEBSITE_URL|NOMINEE_SALUTATION|NOMINEE_MESSAGE|SURVEY_PROGRAM_NAME|SURVEY_SUB_ORGANIZATION_NAME|USER_SUB_ORGANIZATION_NAME|WEBSITE_SENDER_EMAIL|WEBSITE_TERMS_URL|WEBSITE_PRIVACY_URL|WEBSITE_CONTACT_EMAIL|SURVEY_INITIAL_CLOSE_DATE|DAYS_UNTIL_SURVEY_CLOSE_DATE|SURVEY_TEMPLATE_NAME|SURVEY_ACTIVE_REMINDERS|SURVEY_DESCRIPTION|USER_FULL_NAME|SURVEY_SUBJECT_FIRST_NAME|SURVEY_SUBJECT_FULL_NAME|SURVEY_ITERATION_NAME|REPORT_TITLE|PARTICIPANT_REPORT_RELEASE_DATE|DATE_AND_TIME_PDF_CREATED|DOWNLOADED_BY|DOWNLOADED_TIME|DOWNLOADED_DATE|ORG_LOGO|TS_LOGO|CHOOSE_PASSWORD_LINK/gi, function (matched) {
        return mapObjTokens[matched];
    });

    return convertedData
}

export const replaceTokensForUpdateEmail = (data, tokenObj, password_link, recipient_email) => {
    // let tokenObj = tokens

    //replace the tokens to original word
    let mapObjTokens = {
        RECIPIENT_EMAIL: recipient_email,
        WEBSITE_URL: tokenObj.website_url,
        WEBSITE_CONTACT_EMAIL: tokenObj.website_contact_email,
        WEBSITE_SENDER_EMAIL: tokenObj.website_sender_email,
        WEBSITE_TERMS_URL: tokenObj.website_terms_url,
        WEBSITE_PRIVACY_URL: tokenObj.website_privacy_url,
        DAYS_UNTIL_SURVEY_CLOSE_DATE: tokenObj.days_until_survey_close_date,
        SURVEY_INITIAL_CLOSE_DATE: new Date(tokenObj.survey_close_date).toString().slice(4, 21),
        SURVEY_DESCRIPTION: tokenObj.survey_description,
        SURVEY_SUBJECT_FULL_NAME: tokenObj.survey_subject_full_name,
        SURVEY_SUBJECT_FIRST_NAME: tokenObj.survey_subject_first_name,
        SURVEY_SUB_ORGANIZATION_NAME: tokenObj.suborg_name,
        USER_SUB_ORGANIZATION_NAME: tokenObj.suborg_name,
        SURVEY_PROGRAM_NAME: tokenObj.program_name,
        SURVEY_ITERATION_NAME: tokenObj.iteration_name,
        SURVEY_TEMPLATE_NAME: tokenObj.survey_template_name,
        SURVEY_ACTIVE_REMINDERS: tokenObj.survey_active_reminders,
        NOMINEE_SALUTATION: tokenObj.nominee_salutation,
        NOMINEE_MESSAGE: tokenObj.nominee_message,
        USER_FULL_NAME: tokenObj.user_full_name,
        USER_EMAIL: tokenObj.email,
        CHOOSE_PASSWORD_LINK: password_link,
        LOGO: `<div style="background: ${tokenObj.header_bg_color ? tokenObj.header_bg_color : '#ffffff'}; display:flex; padding: 5px; height: 60px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);">
                <img src="${tokenObj.website_url + '' + tokenObj.brand_path}/logo.png" alt="Brand Logo"/>
              </div>`,
    };

    // remove the dollar sign
    const replaceDollar = data.replaceAll('$', '');

    // display the sentence with converted tokens
    const convertedData = replaceDollar.replace(/LOGO|USER_EMAIL|RECIPIENT_EMAIL|WEBSITE_URL|NOMINEE_SALUTATION|NOMINEE_MESSAGE|SURVEY_PROGRAM_NAME|SURVEY_SUB_ORGANIZATION_NAME|USER_SUB_ORGANIZATION_NAME|WEBSITE_SENDER_EMAIL|WEBSITE_TERMS_URL|WEBSITE_PRIVACY_URL|WEBSITE_CONTACT_EMAIL|SURVEY_INITIAL_CLOSE_DATE|DAYS_UNTIL_SURVEY_CLOSE_DATE|SURVEY_TEMPLATE_NAME|SURVEY_ACTIVE_REMINDERS|SURVEY_DESCRIPTION|USER_FULL_NAME|SURVEY_SUBJECT_FIRST_NAME|SURVEY_SUBJECT_FULL_NAME|SURVEY_ITERATION_NAMEREPORT_TITLE|PARTICIPANT_REPORT_RELEASE_DATE|DATE_AND_TIME_PDF_CREATED|DOWNLOADED_BY|DOWNLOADED_TIME|DOWNLOADED_DATE|REPORT_TITLE|ORG_LOGO|TS_LOGO|CHOOSE_PASSWORD_LINK/gi, function (matched) {
        return mapObjTokens[matched];
    });

    return convertedData
}

export const flashMessage = (flash, accent_color, text_color, message) => {
    flash.show({
        html: `<div style="background-color: ${accent_color}; width:100%;">
          <p style="margin-left:1rem; color: ${text_color};">${message}</p>
        </div>`,
        clickable: true,
    });
}

export const mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/ // eslint-disable-line
