
<template>
    <div></div>
</template>

<script>
export default {
  mounted() {
    this.test()
  },
  data: () => ({
    finalHTML: "",
  }),
  methods: {
    
    individual360Funct(surveyData, filename, orgID) {
      let input = JSON.parse(surveyData)

      console.log("orgID")
      console.log(orgID)

      let output = `
        <html lang="en">
          <head>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title></title>
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap');

              .main-container {
                display: flex;
                justify-content: center;
                margin: 20px 100px;
                font-size: 14px;
                font-family: 'Open Sans', sans-serif;
              }

              .sub-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }

              .card-box {
                border-top: 4px solid rgb(0, 67, 94);
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                margin-bottom: 30px;
                padding: 10px 20px;
              }

              .question-box {
                border-top: 4px solid rgb(0, 67, 94);
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                margin-bottom: 30px;
                padding: 10px 20px;
              }

              .reorder-box {
                border-top: 4px solid rgb(0, 67, 94);
                box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                margin-bottom: 30px;
                padding: 10px 20px;
              }

              .reorder-box:nth-of-type(54),
              .reorder-box:nth-of-type(55),
              .reorder-box:nth-of-type(56),
              .reorder-box:nth-of-type(57),
              .reorder-box:nth-of-type(58) {
                display: none;
              }

              .question-field {
                font-weight: bold;
              }

              .free-text-question {
                font-weight: bold;
              }

              .demographic-quesiton {
                font-weight: bold;
              }

              .sharing-option {
                font-weight: bold;
                margin-bottom: 16px
              }

              .sharing-option p {
                display: inline;
              }

              .button-section {
                display: flex;
                justify-content: space-around;
                text-align: center;
              }

              .form-select,
              .form-control {
                box-shadow: none !important;
              }

              .btn.btn-outline-secondary {
                font-size: 14px;
                font-weight: 600;
                border: 2px solid #999;
                border-radius: 10px;
                box-shadow: none !important;
              }

              .btn.btn-outline-secondary:hover {
                color: rgb(25, 25, 25);
                background-color: rgb(210, 210, 210);
              }

              .btn-check:checked+.btn-outline-secondary {
                color: rgb(25, 25, 25);
                background-color: rgb(168, 168, 168);
              }

              @media (max-width: 875px) {
                .button-section {
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                }

                .btn.btn-outline-secondary {
                  width: 100%;
                }
              }

              .reOrder-mainContainer {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
              }

              .item {
                border: 1px solid #ddd;
                color: #34444f;
                cursor: pointer;
                position: relative;
                user-select: none;
                padding: 10px;
                background-color: #0036e711;
                touch-action: none;
              }

              .item.onDrag {
                opacity: 1;
                background-color: #f5f5f5;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                background: transparent;
                color: transparent;
                border: 1px solid #4ca1af;
              }

              .item:last-child {
                display: hidden;
                line-height: 0;
                height: 0;
                overflow: hidden;
                touch-action: none;
                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
                background-color: white;
                border: none;
              }

              .item:last-child::before {
                border: none;
                height: 0;
              }

              .itemClip {
                position: absolute;
                background-color: white;
                opacity: 1;
                top: 0;
                left: 0;
                transform: translate(-50%, -50%);
                transition: none;
                background-color: white;
              }

              .hide {
                display: none;
              }

              .textarea {
                width: 350px;
                height: 200px;
                margin-top: 10px;
                margin-bottom: 20px;
                display: relative;
              }

              .reOrder-subContainer {
                display: flex;
                height: auto;
                padding: 15px;
                gap: 5px;
              }

              .rank-no {
                border: 1px solid #ddd;
                text-align: center;
                color: #333;
                position: relative;
                transition: all 0.3s;
                user-select: none;
                padding: 10px;
              }

              .unfocus {
                color: #6c757d !important;
                background-color: #ffff !important;
                border-color: #999 !important;
              }

              .focus {
                color: #ffff;
                background-color: #6c757d;
              }

              .invalid-question {
                color: #dc3545;
              }

              .valid-question {
                color: #212529
              }

              .accordion {
                margin: 20px 0;
              }

              .accordion-item {
                border: none !important;
              }

              .accordion-button.collapsed {
                background-color: aliceblue;
                border-radius: 0 !important;
              }

              .accordion-button:not(.collapsed) {
                color: black !important;
                box-shadow: none !important;
                outline: none !important;
                border-color: rgba(0, 0, 0, .125);
              }

              .accordion-button:focus {
                box-shadow: none !important;
                outline: none !important;
                border-color: rgba(0, 0, 0, .125);
              }

              .section-number {
                background-color: orange;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 56px;
                font-size: 20px;
                color: white;
              }

              .accordion-header {
                display: flex;
                align-items: center;
                height: 56px;
              }

              .report-for-name {
                margin-left: auto;
                margin-right: 10px
              }

              .accordion-button::after {
                margin: 0 !important;
              }

              /* Charts styles  */
              .chart-container {
                display: flex;
                margin: auto;
                margin-bottom: 40px;
                margin-top: 40px;
                font-weight: bold;
                width: 40%;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              }

              .line-chart-container {
                margin-left: auto;
                margin-right: auto;
                width: 60%;
              }

              .new-line-chart-container {
                width: 100%;
              }
              .new-line-chart-container-ocs {
                width:80%; 
                margin-left:auto; 
                margin-right:auto;
              }

              .subtrait-chart-container {
                margin-left: auto;
                margin-right: auto;
                width: 60%;
              }

              table {
                display: table;
                border-collapse: separate;
                box-sizing: border-box;
                text-indent: initial;
                border-spacing: 2px;
                border-color: grey;
                margin-bottom: 20px;
              }

              table>tr {
                vertical-align: middle;
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
              }

              td {
                display: table-cell;
                vertical-align: inherit;
              }

              table,
              table.main-table {
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                border-top: 0 solid #e0e0e0;
              }

              td._data-heading,
              th.heading-label {
                border-top: 1px solid #e0e0e0;
                padding: 8px 16px;
              }

              .heading-label div {
                display: flex;
                align-items: center;
              }

              table,
              table.main-table {
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                border-top: 0 solid #e0e0e0;
              }

              table,
              table.main-table-faculty {
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                border-top: 0 solid #e0e0e0;
              }

              table,
              table.main-table-faculty {
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                border-top: 0 solid #e0e0e0;
              }

              div.bar-container {
                display: flex;
                justify-content: space-between;
                min-width: 200px;
              }

              div.bar-container>div.bar-difference {
                height: 0.7em;
                margin: 1px 0;
                min-width: 1px;
                flex-grow: 57;
                background-color: rgb(177, 151, 252);
              }

              .subtrait-box {
                padding: 10px;
                margin: 20px 0;
                border-radius: 5px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
              }

              .subtrait-box-vfp {
                padding: 10px;
                border-radius: 5px;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
              }

              .chart-container-vfp {
                margin: auto;
                margin-bottom: 40px;
                margin-top: 40px;
                font-weight: bold;
                width: 50%;
              }

              .input {
                outline: 0;
                border-width: 0 0 1px;
                border-color: grey;
                padding: 2px 5px;
                margin: 10px 0px;
                background: none;
              }

              .formBox {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 1em 0 10px;
                width: 370px;
              }

              .btn-submit {
                background-color: #e67829 !important;
                color: #fff !important;
                appearance: none;
                border: 1px solid rgba(27, 31, 35, 0.15);
                border-radius: 5px;
                cursor: pointer;
                padding: 6px 16px;
              }

              .btn-cancel {
                background-color: #dbdbdb !important;
                color: #000 !important;
                appearance: none;
                border: 1px solid rgba(27, 31, 35, 0.15);
                border-radius: 5px;
                cursor: pointer;
                padding: 6px 16px;
              }

              .note-edit-mark__a {
                color: #4594a8;
                cursor: pointer;
              }

              /* adjust norm  */
              .adjust-norm-container {
                display: flex;
                flex-direction: column;
              }

              .adjust-norm-box {
                background: white;
                margin: auto;
                padding: 1px 30px 30px 30px;
                align-self: center;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
                border-radius: 5px;
              }

              .adjust-norm-box__gender {
                margin-bottom: 2px;
              }

              .adjust-norm-box__culture {
                margin-top: 0;
              }

              .adjust-norm-box__gender--span,
              .adjust-norm-box__culture--span {
                text-transform: capitalize;
                font-weight: bold;
                color: rgb(111, 140, 31);
              }

              /* end of adjust norm  */

              /* Note  */
              .note-box {
                background: white;
                margin: auto;
                padding: 1px 30px 30px 30px;
                align-self: center;
                box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
                border-radius: 5px;
              }

              .note-box__textarea1,
              .note-box__textarea2 {
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 3px;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                resize: none;
                box-sizing: border-box;
                min-height: 50px;
              }

              .note-share-status {
                color: rgb(102, 102, 102);
              }

              /* End of Note  */
              .norm-wording {
                text-align: left;
              }

              /*360 report*/
              .table360 {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                font-family: sans-serif;
                margin: auto;
                margin-bottom: 10px;
                width: 80%;
              }

              .table-coach-TIPpingPoint-role-results {
                display: table;
                font-family: sans-serif;
                border: solid black 3px;
                margin: auto;
                margin-bottom: 10px;
                width: 100%;
              }

              .table-coach-TIPpingpoint-overall-results {
                display: table;
                font-family: sans-serif;
                border: solid black 3px;
                margin: auto;
                margin-bottom: 10px;
                width: 100%;
              }

              .coach-TIP-heading>th {
                text-align: center;
                border: solid black 3px;
                padding: 10px;
              }

              .coach-TIP-items>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
                width: 120px;
              }

              .coach-TIP-person0>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-TIP-person1>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-TIP-person2>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-TIP-person3>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-TIP-person4>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-TIP-person5>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .table-coach-pressurepoint-link-chart {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 3px solid black;
                border-collapse: collapse;
                font-family: sans-serif;
                margin: auto;
                margin-bottom: 10px;
                width: 80%;
              }

              .pressurepoint-link-chart-title>th {
                width: 20px;
                border: 3px solid black;
                text-align: center;
                padding: 10px;
                color: white;
                background-color: #F47820;
              }

              .pressurepoint-link-chart-items>td {
                width: 20px;
                height: 50px;
                border: 3px solid black;
                text-align: center;
                padding: 10px;
              }

              .table-coach-pressurepoint-role-results {
                display: table;
                font-family: sans-serif;
                border: solid black 3px;
                margin: auto;
                margin-bottom: 10px;
                width: 100%;
              }

              .coach-PPP-heading>th {
                text-align: center;
                border: solid black 3px;
                padding: 10px;
              }

              .coach-PPP-person0>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-PPP-person1>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-PPP-person2>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-PPP-person3>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-PPP-person4>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .coach-PPP-person5>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
              }

              .table-faculty-TIPpingpoint-overall-results {
                display: table;
                font-family: sans-serif;
                border: solid black 3px;
                margin: auto;
                margin-bottom: 10px;
                width: 100%;
              }

              .table-faculty-pressurepoint-link-chart {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 3px solid black;
                border-collapse: collapse;
                font-family: sans-serif;
                margin: auto;
                margin-bottom: 10px;
                width: 80%;
              }

              .faculty-TIP-items>td {
                text-align: center;
                border: solid black 1px;
                padding: 10px;
                width: 120px;
              }

              .faculty-TIP-heading>th {
                text-align: center;
                border: solid black 3px;
                padding: 10px;
              }

              .table360-competency {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                font-family: sans-serif;
                margin: auto;
                margin-bottom: 10px;
                margin-top: 10px;
                width: 80%;
              }

              .table360>tr {
                vertical-align: middle;
                display: table-row;
                vertical-align: inherit;
              }

              .table360>td {
                display: table-cell;
                vertical-align: inherit;
              }

              .break-column {
                flex-basis: 100%;
                width: 0;
              }

              .heading-text-barchart1 {
                font-weight: bold;
                background-color: #F8F8F8;
                border-top: 1px solid black;
                border-bottom: 1px solid #e0e0e0;
                min-width: 340px;
                padding-left: 10px;
              }

              .heading-score-barchart1 {
                border-top: 1px solid black;
                font-weight: bold;
                text-align: center;
              }

              .heading-Histogram-barchart1-barchart1 {
                border-top: 1px solid black;
                font-weight: bold;
                text-align: center;
              }

              .heading-bar-barchart1 {
                background-color: #F8F8F8;
                border-top: 1px solid black;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-bar-barchart1 {
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
                background-color: #F8F8F8;
              }

              .sub-heading-text-barchart1 {
                padding-right: 0;
                font-size: small;
                background-color: #F8F8F8;
                padding-left: 15px;
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-score-barchart1 {
                border-top: 1px solid #e0e0e0;
                text-align: center;
              }

              .sub-heading-Histogram-barchart1-barchart1 {
                border-top: 1px solid #e0e0e0;
                text-align: center;
              }

              /* Bar chart */
              .bar-container-barchart1 {
                display: flex;
                min-width: 200px;
                flex-flow: row wrap;
                justify-content: space-around;
              }

              .bar-container-barchart1>div.bar-difference {
                height: 0.7em;
                margin: 1px 0;
                min-width: 1px;
              }

              @keyframes bar-container-barchart1-animation {
                0% {
                  flex-grow: 0;
                  opacity: 1;
                }

                100% {
                  opacity: 1;
                }
              }

              .bar-container-barchart1>div.bar-difference {
                animation-delay: 0.8s;
              }

              .bar-container-barchart1>div {
                animation-delay: 0.9s;
              }

              /* Histogram-barchart1 */
              .Histogram-barchart1 {
                padding: 0;
                margin: 0;
                height: 25px;
                display: flex;
                list-style: none;
                align-items: flex-end;
                justify-content: center;
                border-bottom: 1px solid white;
              }

              @keyframes Histogram-barchart1-animation {
                0% {
                  height: 0;
                  opacity: 1;
                }

                100% {
                  opacity: 1;
                }
              }

              .Histogram-barchart1>li {
                display: inline-block;
                background-color: currentColor;
                width: 20px;
                margin-left: 3px;
                padding: 0;
                opacity: 0;
                animation: Histogram-barchart1-animation 0.2s ease forwards;
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.4);
              }

              .Histogram-barchart1>li:nth-child(1) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart1>li:nth-child(2) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart1>li:nth-child(3) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart1>li:nth-child(4) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart1>li:nth-child(5) {
                animation-delay: 0.2s;
              }

              .heading-label-barchart2 {
                border-top: 1px solid white;
                border-left: 1px solid white;
                border-right: 1px solid white;
                border-bottom: 1px solid black;
              }

              .heading-text-barchart2 {
                border-top: 1px solid black;
                padding-left: 10px;
                font-size: 15px;
              }
              .heading-text-barchart2-cii {
                border-top: 1px solid black;
                min-width: 150px;
                padding-left: 10px;
                font-size: 15px;
              }

              .heading-text-barchart2-under {
                font-size: 15px;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
                padding-left: 12px;
              }

              .heading-score-barchart2 {
                border-top: 1px solid black;
                font-weight: bold;
                text-align: center;
              }

              .heading-Histogram-barchart2-barchart2 {
                border-top: 1px solid black;
                font-weight: bold;
                text-align: center;
              }

              .heading-bar-barchart2 {
                border-top: 1px solid black;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-bar-barchart2 {
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-text-barchart2 {
                padding-right: 0;
                font-size: small;
                ;
                padding-left: 15px;
                width: 80px;
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-text-barchart2-top {
                border-top: 1px solid black;
                padding-right: 15px;
                font-size: small;
                ;
                padding-left: 15px;
                width: 80px;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
              }

              .sub-heading-score-barchart2 {
                border-top: 1px solid #e0e0e0;
                text-align: center;
              }

              .sub-heading-Histogram-barchart2-barchart2 {
                border-top: 1px solid #e0e0e0;
                text-align: center;
              }

              /* Bar chart */
              .bar-container-barchart2 {
                display: flex;
                min-width: 200px;
                flex-flow: row wrap;
                justify-content: space-around;
              }

              .bar-container-barchart2>div.bar-difference {
                height: 0.7em;
                margin: 1px 0;
                min-width: 1px;
              }

              @keyframes bar-container-barchart2-animation {
                0% {
                  flex-grow: 0;
                  opacity: 1;
                }

                100% {
                  opacity: 1;
                }
              }

              .bar-container-barchart2>div.bar-difference {
                animation-delay: 0.8s;
              }

              .bar-container-barchart2>div {
                animation-delay: 0.9s;
              }

              /* Histogram-barchart2 */
              .Histogram-barchart2 {
                padding: 0;
                margin: 0;
                height: 25px;
                display: flex;
                list-style: none;
                align-items: flex-end;
                justify-content: center;
                border-bottom: 1px solid white;
              }

              @keyframes Histogram-barchart2-animation {
                0% {
                  height: 0;
                  opacity: 1;
                }

                100% {
                  opacity: 1;
                }
              }

              .Histogram-barchart2>li {
                display: inline-block;
                background-color: currentColor;
                width: 20px;
                margin-left: 3px;
                padding: 0;
                opacity: 0;
                animation: Histogram-barchart2-animation 0.2s ease forwards;
                box-sizing: border-box;
                border: 1px solid rgba(0, 0, 0, 0.4);
              }

              .Histogram-barchart2>li:nth-child(1) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart2>li:nth-child(2) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart2>li:nth-child(3) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart2>li:nth-child(4) {
                animation-delay: 0.2s;
              }

              .Histogram-barchart2>li:nth-child(5) {
                animation-delay: 0.2s;
              }

              .heading-label-CII {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-peers {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-direct {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-pd {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-internal {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-external {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-ie {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII-pdie {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-text-CII {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                font-size: 15px;
                padding: 10px;
                ;
                max-width: 400px;
              }

              .heading-text-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-score-CII {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                font-weight: bold;
                padding: 10px;
              }

              .heading-score-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-text-CII {
                padding-right: 0;
                font-size: small;
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-text-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-text-CII-top {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding-right: 0;
                font-size: small;
                padding: 10px;
              }

              .sub-heading-text-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-score-CII {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-peers {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-direct {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-pd {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-internal {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-external {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-ie {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII-pdie {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-CII:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-label-blindspots {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .heading-label-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-text-blindspots {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                font-size: 15px;
                padding: 10px;
                ;
                max-width: 400px;
              }

              .heading-text-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-score-blindspots {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                font-weight: bold;
                padding: 10px;
              }

              .heading-score-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-text-blindspots {
                padding-right: 0;
                font-size: small;
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-text-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-text-blindspots-top {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding-right: 0;
                font-size: small;
                padding: 10px;
              }

              .sub-heading-text-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .sub-heading-score-blindspots {
                border: 1px solid #e0e0e0;
                border-right: 1px solid white;
                padding: 10px;
              }

              .sub-heading-score-blindspots:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
                padding: 10px;
              }

              .heading-label-OpenEndedQuestion {
                border: 1px solid #e0e0e0;
                padding: 10px;
                min-width: 800px;
                background-color: #F8F8F8;
              }

              .open-ended-container {
                border-left: 1px solid white;
                border-right: 1px solid white;
                font-size: 13px;
              }

              .open-ended-container>ul>li {
                margin: 5px;
              }

              .heading-label-rawscores {
                border: none;
                border-bottom: 1px solid black;
                font-size: 13px;
                padding-left: 15px;
                padding-top: 30px;
                padding-bottom: 30px;
              }

              .heading-num {
                border: none;
                border-bottom: 1px solid black;
                min-width: 20px;
                text-align: center;
                font-size: 13px;
              }

              .heading-desc {
                border: none;
                border-bottom: 1px solid black;
                min-width: 20px;
                max-width: 40px;
                text-align: center;
                font-size: 13px;
              }

              .heading-text-rawscores {
                border-top: 1px solid black;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid white;
                max-width: 250px;
                padding-left: 10px;
                font-size: 13px;
              }

              .score {
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid white;
                border-right: 1px solid white;
                text-align: center;
                min-width: 20px;
                font-size: 12px;
              }

              .score:nth-last-child(1) {
                border-right: 1px solid #e0e0e0;
              }

              .heading-score-rawscores {
                border-top: 1px solid black;
                text-align: center;
                min-width: 20px;
                font-size: 12px;
              }

              .sub-heading-text-rawscores {
                padding: 5px;
                font-size: 12px;
                padding-left: 15px;
                max-width: 100px;
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid white;
                border-right: 1px solid white;
              }

              .sub-heading-text-rawscores:nth-child(2) {
                border-top: 1px solid black;
              }

              .main-table tbody tr:nth-child(2) td:nth-child(1) {
                font-weight: bold;
              }

              .main-table-faculty tbody tr:nth-child(1) td:nth-child(1) {
                font-weight: bold;
              }

              #section2 div .main-table tbody tr:nth-child(2) td {
                font-weight: normal;
              }

              .table-border {
                border: 1px solid #e0e0e0;
                padding: 0.5rem;
              }

              /* 360 coach report */
              .table360-summary-coach-report {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                font-family: sans-serif;
                margin: auto;
                margin-bottom: 10px;
                width: 100%;
              }

              table.with-flag {
                border: none;
              }

              .flag-row div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
              }

              th.with-flag {
                border: none;
                padding-top: 0;
              }

              .country-flag-span-table img {
                width: 20px;
                margin-bottom: 5px;
                margin-right: 2px;
              }

              .gender-span-table img {
                width: 20px;
                margin-bottom: 9px;
              }

              .front-page-client-logo-img {
                height: 70px !important;
              }

              .front-page-client-logo,
              .front-page-client-logo-static {
                height: 100% !important;
                width: 30vw !important;
                object-fit: contain !important;
                object-position: 0 0 !important;
              }

              @media print {
                @page {
                  size: A4;
                }

                .pagebreak {
                  clear: both;
                  page-break-after: always;
                }

                .front-back-page {
                  display: block !important;
                }

                .print-pdf {
                  display: none !important;
                }

                .expand-all {
                  display: none !important;
                }

                .print-page {
                  width: 9.3in !important;
                  height: 11in !important;
                }

                .subtrait-box-vfp {
                  box-shadow: none !important;
                }
                .cii-table-column {
                  font-size: 6px !important;
                }
                .new-line-chart-container-ocs {
                  margin: 0 !important;
                }
              }

              /*QSORT Dynamic text */
              .cohort_scores_IA,
              .cohort_scores_CE,
              .cohort_scores_LC,
              .cohort_scores_GC,
              .cohort_scores_GNF,
              .cohort_scores_PC,
              .cohort_scores_RA {
                color: #4694B1;
              }

              .custom-blue {
                color: #4694B1;
              }

              .custom-orange {
                color: #CF6C24;
              }

              .table360-Qsort {
                display: table;
                border-spacing: 2px;
                border-color: grey;
                border: 1px solid #e0e0e0;
                border-collapse: collapse;
                font-family: sans-serif;
              }

              .vfp-bg-chart {
                display: flex;
                justify-content: center;
              }

              .vfp-chart {
                width: 720px;
                height: 100px;
                background-color: #ddd;
                position: relative;
              }

              .vfp-bar {
                height: 100%;
                position: absolute;
                background-color: #2196F3;
              }

              .line {
                position: absolute;
                height: 100%;
                border-left: 3px solid #b5a6a6;
              }

              .line1 {
                left: 120px;
              }

              .line2 {
                left: 240px;
              }

              .line3 {
                left: 360px;
              }

              .line4 {
                left: 480px;
              }

              .line5 {
                left: 600px;
              }

              .custom-bar-graph {
                height: 10px;
                margin: 20px 0;
                position: relative;
                width: 100%;
              }

              .custom-bar {
                height: 100%;
                float: left;
                width: 25%;
                position: relative;
              }

              .custom-bar-color::before {
                content: "";
                position: absolute;
                top: -20px;
                left: 25%;
                width: 100%;
                height: 20px;
                z-index: -1;
              }

              .custom-bar-text {
                position: absolute;
                top: -20px;
                font-size: 12px;
              }

              .vertical-line {
                position: absolute;
                top: -10px;
                bottom: -10px;
                width: 2px;
                background-color: #CCCCCC;
              }

              .vertical-line:nth-of-type(2) {
                left: 25%;
              }

              .vertical-line:nth-of-type(4) {
                left: 50%;
              }

              .vertical-line:nth-of-type(6) {
                left: 75%;
              }

              .speedbox {
                height: 175px;
                width: 150px;
                display: flex;
                display: -webkit-flex;
                flex-direction: column;
                -webkit-flex-direction: column;
                align-items: center;
                -webkit-align-items: center;
                position: relative;
              }

              .speedbox__groove {
                height: 75px;
                width: 150px;
                background: transparent;
                border-top-left-radius: 100px;
                border-top-right-radius: 100px;
                border: 20px solid #eee;
                border-bottom: 0;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: 0;
              }

              .speedbox__score {
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(-45deg);
                -webkit-transform: rotate(0deg);
                height: 150px;
                width: 150px;
                background: transparent;
                border-radius: 50%;
                box-sizing: border-box;
                cursor: pointer;
                z-index: 1;
                transition: transform 0.3s ease;
              }

              .speedbox__base {
                width: 240px;
                height: 100px;
                background: white;
                position: relative;
                top: 74px;
                z-index: 20;
              }

              .speedbox__odo {
                text-align: center;
                position: absolute;
                color: #5c6f7b;
                bottom: 100px;
                left: 50%;
                transform: translateX(-50%);
              }

              .speedbox__odo i {
                font-size: 13px;
                opacity: 0.6;
              }

              .speedbox__odo>div {
                margin-bottom: 0;
              }

              .speedbox__odo span {
                font-size: 0.7em;
              }

              .speedbox__down {
                text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
                line-height: 1.2em;
              }
              .breakdown-keys{
                  padding: 12px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                }
                
                .breakdown-keys p{
                  font-size: 12px;
                  margin: 2px 1px;
                  text-align: right;
                }
                .cb-marker-o{
                  position: relative
                }
                .cb-marker-o::before, span[class^="trait-"]::before{
                  content: '';
                  position: absolute;
                  width: 5px;
                  height: 12px;
                  left: 0;
                  bottom: -8px;
                  background-color: rgb(84,130,53);
                  border: 2px solid white;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                  z-index: 1;
                }
               
                .cb-marker-s{
                  position: relative
                }
                .cb-marker-s::before{
                  content: '';
                  position: absolute;
                  width: 5px;
                  height: 12px;
                  left: 0;
                  top: -8px;
                  background-color: rgb(197,90,17);
                  border: 2px solid white;
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                }

                .hidden-content {
                    display: none;
                }

            </style>
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet">
          </head>

          <body class="print-page">
          <div class="hidden-content">
          <!-- Your content to be hidden initially -->
            <div class="main-container">
              <div class="sub-container">
                <form>
      `
                  for (let i = 0; i < input.length; i++) {
                    let data = input[i];
                    //check coach here
                    if (data.Template === 'Print-PDF-Button') { 
                      output += `
                        <div style="display:flex; justify-content: center;" class="print-pdf">
                          <div class="btn btn-primary" style="border: 1px; box-shadow: 2px 4px #ccc;" onclick="printPDF();">Download Report PDF</div>
                        </div>
                      `
                    }
                    if (data.Template === 'Heading-text-nobox') {
                      output += `
                        ` + data.Text + `
                      `
                    }
                    if (data.Template === 'text-important-note') {
                      output += `
                        ` + data.Text + `
                      `
                    }
                    if (data.Template === 'Description-text-nobox') {
                      output += `
                        ` + data.Text + `
                      `
                    }
                    if (data.Template === 'Heading-text') {
                      output += `=
                        <div class="heading-text">
                          <h3>` + data.Text + `</h3>
                        </div>
                      `;
                    }
                    if (data.Template === 'Description-text') {
                      output += `
                        <div class="card-box">
                          <p id="desc-text">` + data.Text + `</p>
                        </div>
                      `;
                    }
                    if (data.Template === 'Expand-All-Accordions') {
                      output += `
                        <div class="expand-all">
                          <div class="mt-5 d-flex justify-content-end">
                            <button 
                              class="btn btn-success me-2" 
                              type="button"
                              id="btnMakePdfAvailable"
                              >
                              <span>Make PDF Available</span>
                            </button> 
                            <button 
                              class="btn btn-primary" 
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target=".multi-collapse" 
                              aria-expanded="false" 
                              aria-controls=""
                              id="expandAllBtn">
                              Expand All
                            </button>
                          </div>
                        </div>
                      `
                    }
                    if (data.Template === 'Expand-All-with-Toggle-Bar-Chart') {
                      output += `
                        <div class="expand-all">
                          <div class="mt-5 d-flex justify-content-end">
                            <button 
                              class="btn btn-secondary toggle-bar-chart" 
                              type="button">
                              <span class="toggle-bar-chart-text">Show Bar Chart</span>
                            </button> 
                            <button 
                              class="btn btn-success mx-2" 
                              type="button"
                              id="btnMakePdfAvailable"
                            >
                              <span>Make PDF Available</span>
                            </button> 
                            <button 
                              class="btn btn-primary" 
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target=".multi-collapse" 
                              aria-expanded="false" 
                              aria-controls=""
                              id="expandAllBtn">
                              Expand All
                            </button>
                          </div>
                        </div>
                      `
                    }
                    if (data.Template === 'Section') {
                      output += `
                        <div class="accordion" id="accordionPanelsStayOpenExample">
                          <div class="accordion-item">
                            <div class="accordion-header" id="panelsStayOpen-headingOne">
                              <div class="section-number">` + data.QuestionNumber + `</div>
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#section` + data.QuestionNumber + `"
                                aria-expanded="false"
                                aria-controls="section` + data.QuestionNumber + `"
                              >
                              ` + data.Text + ` <span class="report-for-name">&report_for_fullname</span>
                              </button>
                            </div>
                            <div
                              id="section` + data.QuestionNumber + `"
                              class="accordion-collapse collapse multi-collapse"
                              aria-labelledby="panelsStayOpen-headingOne"
                            >
                            
                            <div class="accordion-body">
                      `
                    }
                    if (data.Template === 'Section-End') { 
                      output += `
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pagebreak"> </div>
                      `
                    }
                    if (data.Template === 'Notes') { 
                      output += `
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pagebreak"> </div>
                      `
                    }
                    if (data.Template === 'Dynamic-Message-Head') { 
                      output += `
                        <span class="Head-Dynamic-Message"></span>
                      `
                    }
                    if (data.Template === 'Dynamic-Message-Sub') { 
                      output += `
                        <span class="Sub-Dynamic-Message"></span>
                      `
                    }
                    if (data.Template === 'Bar-Chart') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {  
                        output += `
                          <table class="main-table with-flag">
                            <tr>
                              <td class="flag-row"></td>
                              <td class="flag-row"></td>
                              <td class="flag-row">
                                <div>
                                  <span class="country-flag-span-table"><img class="country-flag" alt="flag"></span>
                                  <span class="gender-span-table"><img class="gender-icon" alt="gender"></span>
                                </div>
                              </td>
                              <td class="flag-row"></td>
                              <td class="flag-row"></td>
                            </tr>
        
                            <tr>
                              <th class="heading-label with-flag">
                                <div></div>
                              </th>
                              <th class="heading-label with-flag">
                                <div></div>
                              </th>
                              <th class="heading-label with-flag">
                                <div>
                                  <div>
                                    <div>` + res.heading1 + `</div>
                                  </div>
                                </div>
                              </th>
                              <th class="heading-label with-flag">
                                <div>` + res.heading2 + `</div>
                              </th>
                              <th class="heading-label with-flag">
                                <div>` + res.heading3 + `</div>
                              </th>
                            </tr>
                        `
                        res.Elements.forEach((response) => {
                          output += `
                            <tr>
                              <td class="_data-heading" style="padding-right:0;">` + response.Description + `</td>
                              <td class="_data-heading" style="padding-right:0;">
                                <div class="bar-container">
                                  <div class="bar-difference percentile-` + response.Trait + `"></div>
                                  <div class="percentile-` + response.Trait + `-white-space"></div>
                                </div>
                              </td>
                              <td class="_data-heading">
                                <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="percentile_` + response.Trait + `"></span></div>
                              </td>
                              <td class="_data-heading">
                                <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="_data-heading">
                                <div style="color:#` + response.Color + `; text-align: center;"><span style="font-weight: bold;" class="cohort_` + response.Trait + `"></span></div>
                              </td>
                            </tr>
                          `
                        })
                        output += `
                          </table>
                        `
                      }
                      
                    }
                    if (data.Template === 'Radar-Chart') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <div class="chart-container">
                              <canvas class="` + res.SuperTrait + `"></canvas>
                          </div>
                        `
                      }
                    }
                    if (data.Template === 'Bar-Chart-360-Head') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                          <div class="text-center 360_head_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                            <table class="table360 360_head d-none">
                              <tr>
                                <th class="heading-label">
                                  <div style="display: flex; align-items: center;"></div>
                                </th>
                                <th class="heading-label">
                                  <div style="display: flex; align-items: center;"></div>
                                </th>
                                <th class="heading-label">
                                  <div style="display: flex; align-items: center; font-weight: bold;">
                                    <div>
                                      <div>`+ res.heading1 +`</div>
                                    </div>
                                  </div>
                                </th>
                                <th class="heading-label">
                                  <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading2 +`</div>
                                </th> 
                                <th class="heading-label">
                                  <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading3 +`</div>
                                </th> 
                              </tr>
                          `
                          res.Elements.forEach(response => {
                            output += 
                            `
                              <tr class="head-bar-chart-`+response.Trait+`">
                                <td class="heading-text-barchart1" style="font-weight: bold;">`+ response.Description +`</td>
                                <td class="heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainOverall-` + response.Trait + `" style="background-color: #66D9E8; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainOverall-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference mainCohort-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainCohort-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="heading-score-barchart1">
                                  <div style="color:#66D9E8"><span style="font-weight: bold;" class="all_1000_` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781);"><span style="font-weight: bold;" class="cohort_all_1000_` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-head" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-head" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-head" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-head" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-head" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              
                              <tr class="head-bar-chart-`+response.Trait+`-Psup">
                                <td class="sub-heading-text-barchart1">Primary supervisors</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainPsup-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainPsup-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortPsup-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortPsup-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="psup1_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_psup1_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-Psup" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              
                              <tr class="head-bar-chart-`+response.Trait+`-P">
                                <td class="sub-heading-text-barchart1">Peers</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainP-` + response.Trait + `" style="background-color: #B197FC; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainP-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortP-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortP-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#B197FC; font-size: small;"><span style="font-weight: bold;" class="peer2_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_peer2_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-P" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-P" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-P" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-P" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-P" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              
                              <tr class="head-bar-chart-`+response.Trait+`-D">
                                <td class="sub-heading-text-barchart1">Direct report</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainD-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainD-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortD-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortD-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="direct3_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_direct3_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-D" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-D" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-D" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-D" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-D" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
        
                              <tr class="head-bar-chart-`+response.Trait+`-PD">
                                <td class="sub-heading-text-barchart1">Peers/Direct report</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainPD-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainPD-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortPD-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortPD-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="PD996_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_PD996_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-PD" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-PD" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-PD" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-PD" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-PD" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
                              
                              
                              <tr  class="head-bar-chart-`+response.Trait+`-I">
                                <td class="sub-heading-text-barchart1">Internal stakeholders</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainI-` + response.Trait + `" style="background-color: #63E6BE; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainI-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortI-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortI-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#63E6BE; font-size: small;"><span style="font-weight: bold;" class="internal4_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_internal4_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-I" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-I" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-I" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-I" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-I" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              
                              <tr class="head-bar-chart-`+response.Trait+`-E">
                                <td class="sub-heading-text-barchart1">External stakeholders</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainE-` + response.Trait + `" style="background-color: #63E6BE; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainE-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortE-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#63E6BE; font-size: small;"><span style="font-weight: bold;" class="external5_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_external5_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-E" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-E" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-E" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-E" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-E" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              <tr class="head-bar-chart-`+response.Trait+`-IE">
                                <td class="sub-heading-text-barchart1">Internal/External stakeholders</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainIE-` + response.Trait + `" style="background-color: #8CE99A; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainIE-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortIE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortIE-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#8CE99A; font-size: small;"><span style="font-weight: bold;" class="IE997_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_IE997_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-IE" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-IE" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-IE" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-IE" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-IE" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
                              <tr class="head-bar-chart-`+response.Trait+`-PDIE">
                                <td class="sub-heading-text-barchart1" style="padding-right:0; font-size: small;">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainPDIE-` + response.Trait + `" style="background-color: #B197FC; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainPDIE-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortPDIE-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortPDIE-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#B197FC; font-size: small;"><span style="font-weight: bold;" class="PDIE998_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_PDIE998_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-PDIE" style="color: grey;"></li>
                                  </ul>
                                </td>

                              </tr>
          
                              
                              <tr class="head-bar-chart-`+response.Trait+`-Self">
                                <td class="sub-heading-text-barchart1">Self</td>
                                <td class="sub-heading-bar-barchart1">
                                  <div class="bar-container-barchart1">
                                    <div class="bar-difference mainSelf-` + response.Trait + `" style="background-color: #E599F7; animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="mainSelf-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohortSelf-` + response.Trait + `" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart1-animation 0.9s ease forwards"></div>
                                    <div class="cohortSelf-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart1">
                                  <div style="color:#E599F7; font-size: small;"><span style="font-weight: bold;" class="ind_` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart1">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-size: small;"><span style="font-weight: bold;" class="cohort_self0_` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-Self" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-Self" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-Self" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-Self" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-Self" style="color: grey;"></li>
                                  </ul>
                                </td>

                              </tr>
          
                            `
                          });
                          output += 
                            `
                          </table>
                            `
                        }
                    }
                    if (data.Template === 'Bar-Chart-360-Sub') {
                      let obj = JSON.parse(data.Text)
          
                      for (const res of obj) {
                        output += `
                        <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <table class="table360 360_sub d-none">
                            <tr>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center;"></div>
                              </th>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center;"></div>
                              </th>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center;"></div>
                              </th>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center; font-weight: bold;">
                                  <div>
                                    <div>`+ res.heading1 +`</div>
                                  </div>
                                </div>
                              </th>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-barchart2">
                                <div style="display: flex; align-items: center; font-weight: bold;">`+ res.heading3 +`</div>
                              </th>
                            </tr>
                          `
                          res.Elements.forEach(response => {
                            output += 
                            `
                              <tr class="MAIN">
                                <td class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                              </tr>
                              <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:`+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              <tr class="sub-bar-chart-P-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Peers</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: #B197FC; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:#B197FC; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                  </ul>
                                </td>
                              </tr>
          
                              <tr class="sub-bar-chart-D-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Direct report</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
        
                              <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
                              <tr class="sub-bar-chart-I-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: #63E6BE; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: #63E6BE; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                  </ul>
                                </td>
                              </tr>
          
                              <tr class="sub-bar-chart-E-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: #63E6BE; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:#63E6BE; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                  </ul>
                                </td>

                              </tr>                  
          
                              <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                <td class="heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: #8CE99A; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color:#8CE99A; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                </td>
                                <td class="heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                  </ul>
                                </td>
                              </tr>
        
                              <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                <td class="sub-heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: #B197FC; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: #B197FC; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>
          
          
                              <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Total</td>
                                <td class="sub-heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: #66D9E8; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: #66D9E8; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                </td>

                                <td class="sub-heading-Histogram-barchart1-barchart1">
                                  <ul class="Histogram-barchart1">
                                    <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                  </ul>
                                </td>

                              </tr>
          
          
                              <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                <td class="sub-heading-bar-barchart2">
                                  <div class="bar-container-barchart2">
                                    <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: #E599F7; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                    <div class="break-column"></div>
                                    <div class="bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                    <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                  </div>
                                </td>
          
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: #E599F7; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                </td>
                                <td class="sub-heading-score-barchart2">
                                  <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                </td>

                              <td class="sub-heading-Histogram-barchart1-barchart1">
                                <ul class="Histogram-barchart1">
                                  <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                  <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                  <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                  <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                  <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                </ul>
                              </td>

                              </tr>

                          `
                        });
                          output += 
                            `
                              </table>
                            `
                      }
                    }
                    if (data.Template === 'Radar-Chart-360-Head-Self') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                          <div class="text-center radarchart_head_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                            <div class="chart-container radar_head d-none">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Radar-Chart-360-Head-Cohort') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                          <div class="text-center radarchart_cohort_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                            <div class="chart-container radar_cohort d-none">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Radar-Chart-360-Self') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                            <div class="chart-container">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Radar-Chart-360-Cohort') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                            <div class="chart-container section9-cohort-data">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Table-360-Competency-old') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                          <div class="text-center competency_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <table class="table360-competency d-none">
                            <tr>
                              <th class="heading-label-CII">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">
                                  <div>
                                    <div>`+ res.heading2 +`</div>
                                  </div>
                                </div>
                              </th>
                              <th class="heading-label-CII-peers text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-CII-direct text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-CII-pd text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                              </th>
                              <th class="heading-label-CII-internal text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading6 +`</div>
                              </th>
                              <th class="heading-label-CII-external text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading7 +`</div>
                              </th>
                              <th class="heading-label-CII-ie text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading8 +`</div>
                              </th>
                              <th class="heading-label-CII-pdie text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading9 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading10 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading11 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading12 +`</div>
                              </th>
                            </tr>
                        <!-- 1 line here -->
                        `
                          res.Elements.forEach(response => {
                            output +=
                            `
                            <tr>
                              <td class="heading-text-CII">`+ response.Description +`</td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-psup1` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-peers">
                                <div class="text-center" style="font-weight: bold"><span class="competency-peer2_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-direct">
                                <div class="text-center" style="font-weight: bold"><span class="competency-direct3_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-pd">
                                <div class="text-center" style="font-weight: bold"><span class="competency-PD996_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-internal">
                                <div class="text-center" style="font-weight: bold"><span class="competency-internal4_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-external">
                                <div class="text-center" style="font-weight: bold"><span class="competency-external5_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-ie">
                                <div class="text-center" style="font-weight: bold"><span class="competency-IE997_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-pdie">
                                <div class="text-center" style="font-weight: bold"><span class="competency-PDIE998_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center" style="font-weight: bold"><span class="competency-cohort_all_1000_` + response.Trait + `"></span></div>
                              </td>
                          </tr>
                            `
                          });
                        output +=
                          `
                            </table>
                          `
                    }
                    }
                    if (data.Template === 'Bar-Chart-360-Highest') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                            `
                            <div class="text-center highest_spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <table class="table360 highest_table d-none">
                              <thead>
                                <tr class="highest-row-head">
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div class="section11-cohort-data" style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div class="section11-cohort-data" style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                                  </th>
                                </tr>
                              </thead>
        
                              <tbody class="highest-wrapper">
                              `
                              res.Elements.forEach(response => {
                                output +=
                                `
                                  <tr class="highest-row` + response.Trait + ` highest-sortme">
                                    <td class="sub-heading-score-blindspots highest-averageScore">
                                      <div><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
        
                                    <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-ind_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center section11-cohort-data"><span class="highest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center section11-cohort-data"><span class="highest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                    </td>
                                  </tr>
                                `
                              });
                              output +=
                            `
                          </tbody>
                        </table>
                          `
                      }
                    }
                    if (data.Template === 'Bar-Chart-360-Lowest') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                            `
                            <div class="text-center lowest_spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <table class="table360 lowest_table d-none">
                              <thead>
                                <tr class="lowest-row-head">
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div class="section11-cohort-data" style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div class="section11-cohort-data" style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                                  </th>
                                </tr>
                              </thead>
        
                              <tbody class="lowest-wrapper">
                              `
                              res.Elements.forEach(response => {
                                output +=
                                `
                                  <tr class="lowest-row` + response.Trait + ` lowest-sortme">
                                    <td class="sub-heading-score-blindspots lowest-averageScore">
                                      <div><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
        
                                    <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-ind_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="section11-cohort-data text-center"><span class="lowest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="section11-cohort-data text-center"><span class="lowest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                    </td>
                                  </tr>
                                `
                              });
                              output +=
                            `
                          </tbody>
                        </table>
                          `
                      }
                    }
                    if (data.Template === 'Table-360-Overestimated'){ 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360"> 
                          <p class ="d-none overestimated-none" style="font-weight: bold;">None</p>
                          <thead>
                            <tr class="overestimated-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody id="overestimate-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                          <!-- 1 line here -->
                            <tr class="overestimated-row` + response.Trait + `">
                              <td class="heading-text-blindspots">`+ response.Description +`</td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="overestimated-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center" style="font-weight: bold"><span class="overestimated-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="overestimated-difference` + response.Trait + `"></span></div>
                              </td>
                            </tr>
                          `
                          });
                          output +=
                          `
                          </tbody>
                        </table>
                          `
                    }
                    }
                    if (data.Template === 'Table-360-Underestimated') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360">
                          <p class ="d-none underestimated-none" style="font-weight: bold;">None</p>
                          <thead>
                            <tr class="underestimated-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody id="underestimate-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                          <!-- 1 line here -->
                            <tr class="underestimated-row` + response.Trait + `">
                              <td class="heading-text-blindspots">`+ response.Description +`</td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="underestimated-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center" style="font-weight: bold"><span class="underestimated-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="underestimated-difference` + response.Trait + `"></span></div>
                              </td>
                            </tr>
                          `
                          });
                          output +=
                          `
                          </tbody>
                        </table>
                          `
                    }
                    }
                    if (data.Template === 'Table-360-Open-ended') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360">
                        <!-- start of item  -->
                              `
                              res.Elements.forEach(response => {
                              output +=
                              `
                              <tr>
                              <td class="heading-label-OpenEndedQuestion">
                                `+ response.Description +`
                              </td>
                              <tr>
                              <td class="open-ended-container">
                                <ul id="sort-open-ended">
                                  <li class="open-ended-1` + response.Trait + `"></li>
                                  <li class="open-ended-2` + response.Trait + `"></li>
                                  <li class="open-ended-3` + response.Trait + `"></li>
                                  <li class="open-ended-4` + response.Trait + `"></li>
                                  <li class="open-ended-5` + response.Trait + `"></li>
                                  <li class="open-ended-6` + response.Trait + `"></li>
                                  <li class="open-ended-7` + response.Trait + `"></li>
                                  <li class="open-ended-8` + response.Trait + `"></li>
                                  <li class="open-ended-9` + response.Trait + `"></li>
                                  <li class="open-ended-10` + response.Trait + `"></li>
                                  <li class="open-ended-11` + response.Trait + `"></li>
                                  <li class="open-ended-12` + response.Trait + `"></li>
                                  <li class="open-ended-13` + response.Trait + `"></li>
                                  <li class="open-ended-14` + response.Trait + `"></li>
                                  <li class="open-ended-15` + response.Trait + `"></li>
                                  <li class="open-ended-16` + response.Trait + `"></li>
                                  <li class="open-ended-17` + response.Trait + `"></li>
                                  <li class="open-ended-18` + response.Trait + `"></li>
                                  <li class="open-ended-19` + response.Trait + `"></li>
                                  <li class="open-ended-20` + response.Trait + `"></li>
                                </ul>
                              </td>
                            </tr>
                          </tr>
                          `
                          });
                          output +=
                          `
                            </table>
                          `
                      }
                    }
                    // section 2 with Competency Importance Index and Organizational Climate Snapshot
                    // for New 360 Reports - April 24 2023 - started work 
                    if (data.Template === 'Dynamic-Message-Head-New') { 
                        output += `
                        <span class="Head-Dynamic-Message">
                        </span>
                        `
                    }
                    // Radar chart for section 2
                    if (data.Template === 'Radar-Chart-New-360-Head-Self') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                          <div class="text-center radarchart_head_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                            <div class="chart-container radar_head d-none">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Radar-Chart-New-360-Head-Cohort') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                          <div class="text-center radarchart_cohort_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                            <div class="chart-container radar_cohort d-none">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    //Highest and Lowest
                    if (data.Template === 'Bar-Chart-New-360-Highest') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                            `
                            <div class="text-center highest_spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <table class="table360 highest_table d-none">
                              <thead>
                                <tr class="highest-row-head">
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                                  </th>
                                </tr>
                              </thead>
        
                              <tbody class="highest-wrapper">
                              `
                              res.Elements.forEach(response => {
                                output +=
                                `
                                  <tr class="highest-row` + response.Trait + ` highest-sortme">
                                    <td class="sub-heading-score-blindspots highest-averageScore">
                                      <div><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
        
                                    <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-ind_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="highest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                    </td>
                                  </tr>
                                `
                              });
                              output +=
                            `
                          </tbody>
                        </table>
                          `
                      }
                    }
                    if (data.Template === 'Bar-Chart-New-360-Lowest') {
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                            `
                            <div class="text-center lowest_spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <table class="table360 lowest_table d-none">
                              <thead>
                                <tr class="lowest-row-head">
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                                  </th>
                                  <th class="heading-label-blindspots">
                                    <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading5 +`</div>
                                  </th>
                                </tr>
                              </thead>
        
                              <tbody class="lowest-wrapper">
                              `
                              res.Elements.forEach(response => {
                                output +=
                                `
                                  <tr class="lowest-row` + response.Trait + ` lowest-sortme">
                                    <td class="sub-heading-score-blindspots lowest-averageScore">
                                      <div><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
        
                                    <td class="heading-text-blindspots">`+ response.TraitBold +` `+ response.Description +`</td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-all_nom999_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-ind_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-all_nom999_cohort_` + response.Trait + `"></span></div>
                                    </td>
                                    <td class="sub-heading-score-blindspots">
                                      <div class="text-center"><span class="lowest-all_nom999_cohort_self_` + response.Trait + `"></span></div>
                                    </td>
                                  </tr>
                                `
                              });
                              output +=
                            `
                          </tbody>
                        </table>
                          `
                      }
                    }
                    // Organizational Climate Breakdown - TL
                    if (data.Template === 'Radar-Chart-New-360-Self') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                            <div class="chart-container">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    if (data.Template === 'Radar-Chart-New-360-Cohort') {
                        let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += `
                            <div class="chart-container">
                            `+res.Title+`
                                <canvas class="` + res.SuperTrait + `` + res.Title + `"></canvas>
                            </div>
                          `
                      }
                    }
                    //Blind spot
                    if (data.Template === 'Table-360-New-Overestimated'){ 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360"> 
                          <p class ="d-none overestimated-none" style="font-weight: bold;">None</p>
                          <thead>
                            <tr class="overestimated-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody id="overestimate-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                          <!-- 1 line here -->
                            <tr class="overestimated-row` + response.Trait + `">
                              <td class="heading-text-blindspots">`+ response.Description +`</td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="overestimated-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center" style="font-weight: bold"><span class="overestimated-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="overestimated-difference` + response.Trait + `"></span></div>
                              </td>
                            </tr>
                          `
                          });
                          output +=
                          `
                          </tbody>
                        </table>
                          `
                    }
                    }
                    if (data.Template === 'Table-360-New-Underestimated') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360">
                          <p class ="d-none underestimated-none" style="font-weight: bold;">None</p>
                          <thead>
                            <tr class="underestimated-row-head">
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading2 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-blindspots">
                                <div style="display: flex; align-items: center; justify-content: center;">`+ res.heading4 +`</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody id="underestimate-wrapper">
                          `
                          res.Elements.forEach(response => {
                            output +=
                            `
                          <!-- 1 line here -->
                            <tr class="underestimated-row` + response.Trait + `">
                              <td class="heading-text-blindspots">`+ response.Description +`</td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="underestimated-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center" style="font-weight: bold"><span class="underestimated-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-blindspots">
                                <div class="text-center"><span class="underestimated-difference` + response.Trait + `"></span></div>
                              </td>
                            </tr>
                          `
                          });
                          output +=
                          `
                          </tbody>
                        </table>
                          `
                    }
                    }
                    // Competency Climate - TL
                    if (data.Template === 'Table-360-Competency') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                          <div class="text-center competency_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          <table class="table360-competency d-none">
                            <tr>
                              <th class="heading-label-CII">
                                <div style="display: flex; align-items: center;">`+ res.heading1 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">
                                  <div>
                                    <div>`+ res.heading2 +`</div>
                                  </div>
                                </div>
                              </th>
                              <th class="heading-label-CII-peers text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading3 +`</div>
                              </th>
                              <th class="heading-label-CII-direct text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading4 +`</div>
                              </th>
                              <th class="heading-label-CII-pd text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading5 +`</div>
                              </th>
                              <th class="heading-label-CII-internal text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading6 +`</div>
                              </th>
                              <th class="heading-label-CII-external text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading7 +`</div>
                              </th>
                              <th class="heading-label-CII-ie text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading8 +`</div>
                              </th>
                              <th class="heading-label-CII-pdie text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading9 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading10 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div style="display: flex; align-items: center;">`+ res.heading11 +`</div>
                              </th>
                              <th class="heading-label-CII text-center">
                                <div class="section10-cohort-data" style="display: flex; align-items: center;">`+ res.heading12 +`</div>
                              </th>
                            </tr>
                        <!-- 1 line here -->
                        `
                          res.Elements.forEach(response => {
                            output +=
                            `
                            <tr>
                              <td class="heading-text-CII">`+ response.Description +`</td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-psup1` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-peers">
                                <div class="text-center" style="font-weight: bold"><span class="competency-peer2_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-direct">
                                <div class="text-center" style="font-weight: bold"><span class="competency-direct3_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-pd">
                                <div class="text-center" style="font-weight: bold"><span class="competency-PD996_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-internal">
                                <div class="text-center" style="font-weight: bold"><span class="competency-internal4_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-external">
                                <div class="text-center" style="font-weight: bold"><span class="competency-external5_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-ie">
                                <div class="text-center" style="font-weight: bold"><span class="competency-IE997_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII-pdie">
                                <div class="text-center" style="font-weight: bold"><span class="competency-PDIE998_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-all_nom999_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center"><span class="competency-ind_` + response.Trait + `"></span></div>
                              </td>
                              <td class="sub-heading-score-CII">
                                <div class="text-center section10-cohort-data" style="font-weight: bold"><span class="competency-cohort_all_1000_` + response.Trait + `"></span></div>
                              </td>
                          </tr>
                            `
                          });
                        output +=
                          `
                            </table>
                          `
                    }
                    }
                    // section 2 with Competency Importance Index and Organizational Climate Snapshot
                    if (data.Template === 'Bar-Chart-New-360-Head') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `

                          <table class="npsData mt-4 mb-4" style="width: 100%; border:none;">
                            <tr>
                              <th colspan="3">`+res.NPS.description+`</th>
                            </tr>
                          
                            <tr>
                              <td style="width: 30%; vertical-align:middle;">
                                <div>
                                  <p>`+res.NPS.name+`</p>
                                  <!-- <p>Click to expand by role</p> -->
                                </div>
                              </td>

                              <td style="vertical-align:middle;">
                                <div style="position:relative; bottom: 5">
                                  <div class="custom-bar-Others-NPS" style="position:absolute; color: `+res.TextColors[1]+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-NPS-allOthers-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                </div>

                                <div style="position:relative; bottom: 23">
                                  <div class="custom-bar-Self-KDY" style="position:absolute; color: `+res.TextColors[0]+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-NPS-self-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </td>

                              <td style="width: 30%; vertical-align:middle; text-align: right;">
                                O = Others, S = Self 
                              </td>
                            </tr>
                          </table>

                          <div class="row">
                            <div class="col-7">
                              <h5 class="mb-3">`+ res.heading3+`</h5>

                              <div class="text-center f5-spinner">
                                <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              </div>

                              <table class="table d-none f5-table" style="border-top: 1px solid #ccc;">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col"><span style="color: `+res.Colors.color_s+` ">`+res.heading1a+`</span> <span>`+res.heading1b+`</span></th>
                                    <th class="text-center" scope="col">`+ res.heading2 +`</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  `
                                    res.Competencies.forEach((resCompetency) => {
                                      output += `
                                        <tr>
                                          <td style="width: 33%">`+ resCompetency.Name+`</td>
                                          <td style="padding-right: 35px;">
                                            
                                            <div style="position:relative; bottom: 5">
                                              <div class="custom-bar-Others-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                                <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                            <div class="custom-bar-graph">
                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                            </div>

                                            <div style="position:relative; bottom: 23">
                                              <div class="custom-bar-Self-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                                <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                          </td>
                                        </tr>
                                      `
                                    })
                                  output += `
                                </tbody>
                              </table>
                            </div>

                            <div class="col-5">
                              <!-- Top Behaviours   -->
                              <div>
                                <h5 class="mb-3">`+ res.heading4+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table top-behaviours-wrapper">
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-behaviours-sortme">
                                            <li class="top_behaviours_` + response.Trait + ` top-behaviours-average-score"></li>
                                            <li>`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="total_avg_score` + response.Trait + `"></span>)</span></li>
                                          </div>
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                              <!-- Underappreciated strengths -->
                              <div>
                                <h5 class="mb-3">`+ res.heading5+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table undiscovered-strength-wrapper">
                                  <p class="d-none undiscovered-strength-message"><i>Your understanding of your strengths aligns with that of your nominees</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-undiscovered-strength-sortme">
                                            <li class="top-undiscovered-strength_` + response.Trait + ` top-undiscovered-strength-average-score"></li>
                                            <li class="uslistZeroItem d-none undiscovered-strength-all-rows undiscovered-strength-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>
                              

                              <!-- Potential Blindspots -->
                              <div>
                                <h5 class="mb-3">`+ res.heading6+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table potential-blindspot-wrapper">
                                  <p class="d-none potential-blindspot-message"><i>You are already aware of your strengths and use them effectively</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-potential-blindspot-sortme">
                                            <li class="top-potential-blindspot_` + response.Trait + ` top-potential-blindspot-average-score"></li>
                                            <li class="pblistZeroItem d-none potential-blindspot-all-rows potential-blindspot-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                            </div>
                          </div>

                          <!-- Competency Importance Index - Section 2 -->
                          <div class="row mt-2" style="margin-left: 2px; margin-right: 2px;">
                          <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                              <table class="table360-competency" style="width: 100%;">
                                  <thead>
                                    <div style="display: flex; align-items: center; font-size: 12px; text-align: center;">
                                      <tr id="trait-row">
                                          <!-- Table columns will be populated using JavaScript -->
                                      </tr>
                                    </div>
                                  </thead>
                                  <tbody>
                                      <tr id="self-row">
                                        <td class="sub-heading-score-CII text-center">
                                          <div><span class="competency-ind_undefined" style="text-align: center;"></span></div>
                                        </td>
                                          <!-- "Self" data will be populated using JavaScript -->
                                      </tr>
                                      <tr id="others-row">
                                        <td class="sub-heading-score-CII text-center">
                                          <div><span class="competency-all_nom999_undefined" style="text-align: center;"></span></div>
                                        </td>
                                          <!-- "Others" data will be populated using JavaScript -->
                                      </tr>
                                  </tbody>
                              </table>
                            </div>

                              <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                                <h5 class="mb-3">`+ res.ocsTitle+`</h5>
                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>
                                <div class="d-none f5-table new-line-chart-container">
                                    <canvas class="` + res.ocsSuperTrait + `"></canvas>
                                </div>
                             </div>

                          </div>



                    `
                      }
                    }
                    // section 2 without Competency Importance Index and Organizational Climate Snapshot
                    if (data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `

                        <table class="npsData mt-4 mb-4" style="width: 100%; border:none;">
                            <tr>
                              <th colspan="3">`+res.NPS.description+`</th>
                            </tr>
                          
                            <tr>
                              <td style="width: 30%; vertical-align:middle;">
                                <div>
                                  <p>`+res.NPS.name+`</p>
                                  <!-- <p>Click to expand by role</p> -->
                                </div>
                              </td>

                              <td style="vertical-align:middle;">
                                <div style="position:relative; bottom: 5">
                                  <div class="custom-bar-Others-NPS" style="position:absolute; color: `+res.TextColors[1]+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-NPS-allOthers-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                </div>

                                <div style="position:relative; bottom: 23">
                                  <div class="custom-bar-Self-KDY" style="position:absolute; color: `+res.TextColors[0]+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-NPS-self-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </td>

                              <td style="width: 30%; vertical-align:middle; text-align: right;">
                                O = Others, S = Self 
                              </td>
                            </tr>
                          </table>

                          <div class="row">
                            <div class="col-7">
                              <h5 class="mb-3">`+ res.heading3+`</h5>

                              <div class="text-center f5-spinner">
                                <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              </div>

                              <table class="table d-none f5-table" style="border-top: 1px solid #ccc;">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col"><span style="color: `+res.Colors.color_s+` ">`+res.heading1a+`</span> <span>`+res.heading1b+`</span></th>
                                    <th class="text-center" scope="col">`+ res.heading2 +`</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  `
                                    res.Competencies.forEach((resCompetency) => {
                                      output += `
                                        <tr>
                                          <td style="width: 33%">`+ resCompetency.Name+`</td>
                                          <td style="padding-right: 35px;">
                                            
                                            <div style="position:relative; bottom: 5">
                                              <div class="custom-bar-Others-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                                <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                            <div class="custom-bar-graph">
                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                            </div>

                                            <div style="position:relative; bottom: 23">
                                              <div class="custom-bar-Self-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                                <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                          </td>
                                        </tr>
                                      `
                                    })
                                  output += `
                                </tbody>
                              </table>
                            </div>

                            <div class="col-5">
                              <!-- Top Behaviours   -->
                              <div>
                                <h5 class="mb-3">`+ res.heading4+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table top-behaviours-wrapper">
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-behaviours-sortme">
                                            <li class="top_behaviours_` + response.Trait + ` top-behaviours-average-score"></li>
                                            <li>`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="total_avg_score` + response.Trait + `"></span>)</span></li>
                                          </div>
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                              <!-- Underappreciated strengths -->
                              <div>
                                <h5 class="mb-3">`+ res.heading5+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table undiscovered-strength-wrapper">
                                  <p class="d-none undiscovered-strength-message"><i>Your understanding of your strengths aligns with that of your nominees</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-undiscovered-strength-sortme">
                                            <li class="top-undiscovered-strength_` + response.Trait + ` top-undiscovered-strength-average-score"></li>
                                            <li class="uslistZeroItem d-none undiscovered-strength-all-rows undiscovered-strength-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>
                              

                              <!-- Potential Blindspots -->
                              <div>
                                <h5 class="mb-3">`+ res.heading6+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table potential-blindspot-wrapper">
                                  <p class="d-none potential-blindspot-message"><i>You are already aware of your strengths and use them effectively</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-potential-blindspot-sortme">
                                            <li class="top-potential-blindspot_` + response.Trait + ` top-potential-blindspot-average-score"></li>
                                            <li class="pblistZeroItem d-none potential-blindspot-all-rows potential-blindspot-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                            </div>
                          </div>
                        `
                      }
                    } 
                    // section 2 with Competency Importance Index and `NO` Organizational Climate Snapshot
                    if (data.Template === 'Bar-Chart-New-360-Head-CII') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `

                        <table class="npsData mt-4 mb-4" style="width: 100%; border:none;">
                            <tr>
                              <th colspan="3">`+res.NPS.description+`</th>
                            </tr>
                          
                            <tr>
                              <td style="width: 30%; vertical-align:middle;">
                                <div>
                                  <p>`+res.NPS.name+`</p>
                                  <!-- <p>Click to expand by role</p> -->
                                </div>
                              </td>

                              <td style="vertical-align:middle;">
                                <div style="position:relative; bottom: 5">
                                  <div class="custom-bar-Others-NPS" style="position:absolute; color: `+res.TextColors[1]+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-NPS-allOthers-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                </div>

                                <div style="position:relative; bottom: 23">
                                  <div class="custom-bar-Self-KDY" style="position:absolute; color: `+res.TextColors[0]+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-NPS-self-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </td>

                              <td style="width: 30%; vertical-align:middle; text-align: right;">
                                O = Others, S = Self 
                              </td>
                            </tr>
                          </table>

                          <div class="row">
                            <div class="col-7">
                              <h5 class="mb-3">`+ res.heading3+`</h5>

                              <div class="text-center f5-spinner">
                                <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              </div>

                              <table class="table d-none f5-table" style="border-top: 1px solid #ccc;">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col"><span style="color: `+res.Colors.color_s+` ">`+res.heading1a+`</span> <span>`+res.heading1b+`</span></th>
                                    <th class="text-center" scope="col">`+ res.heading2 +`</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  `
                                    res.Competencies.forEach((resCompetency) => {
                                      output += `
                                        <tr>
                                          <td style="width: 33%">`+ resCompetency.Name+`</td>
                                          <td style="padding-right: 35px;">
                                            
                                            <div style="position:relative; bottom: 5">
                                              <div class="custom-bar-Others-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                                <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                            <div class="custom-bar-graph">
                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                            </div>

                                            <div style="position:relative; bottom: 23">
                                              <div class="custom-bar-Self-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                                <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                          </td>
                                        </tr>
                                      `
                                    })
                                  output += `
                                </tbody>
                              </table>
                            </div>

                            <div class="col-5">
                              <!-- Top Behaviours   -->
                              <div>
                                <h5 class="mb-3">`+ res.heading4+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table top-behaviours-wrapper">
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-behaviours-sortme">
                                            <li class="top_behaviours_` + response.Trait + ` top-behaviours-average-score"></li>
                                            <li>`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="total_avg_score` + response.Trait + `"></span>)</span></li>
                                          </div>
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                              <!-- Underappreciated strengths -->
                              <div>
                                <h5 class="mb-3">`+ res.heading5+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table undiscovered-strength-wrapper">
                                  <p class="d-none undiscovered-strength-message"><i>Your understanding of your strengths aligns with that of your nominees</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-undiscovered-strength-sortme">
                                            <li class="top-undiscovered-strength_` + response.Trait + ` top-undiscovered-strength-average-score"></li>
                                            <li class="uslistZeroItem d-none undiscovered-strength-all-rows undiscovered-strength-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>
                              

                              <!-- Potential Blindspots -->
                              <div>
                                <h5 class="mb-3">`+ res.heading6+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table potential-blindspot-wrapper">
                                  <p class="d-none potential-blindspot-message"><i>You are already aware of your strengths and use them effectively</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-potential-blindspot-sortme">
                                            <li class="top-potential-blindspot_` + response.Trait + ` top-potential-blindspot-average-score"></li>
                                            <li class="pblistZeroItem d-none potential-blindspot-all-rows potential-blindspot-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                            </div>
                          </div>

                          <!-- Competency Importance Index - Section 2 -->
                          <div class="row mt-2" style="margin-left: 2px; margin-right: 2px;">
                          <div class="col-xl-6 col-lg-12 mt-2" style="border: 1px solid #C0C0C0; border-radius: 2px;">
                              <table class="table360-competency" style="width: 100%;">
                                  <thead>
                                    <div style="display: flex; align-items: center; font-size: 12px; text-align: center;">
                                      <tr id="trait-row">
                                          <!-- Table columns will be populated using JavaScript -->
                                      </tr>
                                    </div>
                                  </thead>
                                  <tbody>
                                      <tr id="self-row">
                                        <td class="sub-heading-score-CII text-center">
                                          <div><span class="competency-ind_undefined" style="text-align: center;"></span></div>
                                        </td>
                                          <!-- "Self" data will be populated using JavaScript -->
                                      </tr>
                                      <tr id="others-row">
                                        <td class="sub-heading-score-CII text-center">
                                          <div><span class="competency-all_nom999_undefined" style="text-align: center;"></span></div>
                                        </td>
                                          <!-- "Others" data will be populated using JavaScript -->
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                          </div>
                        `
                      }
                    } 
                    // section 2 with Organizational Climate Snapshot and `NO` Competency Importance Index
                    if (data.Template === 'Bar-Chart-New-360-Head-OCS') { 
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `

                        <table class="npsData mt-4 mb-4" style="width: 100%; border:none;">
                            <tr>
                              <th colspan="3">`+res.NPS.description+`</th>
                            </tr>
                          
                            <tr>
                              <td style="width: 30%; vertical-align:middle;">
                                <div>
                                  <p>`+res.NPS.name+`</p>
                                  <!-- <p>Click to expand by role</p> -->
                                </div>
                              </td>

                              <td style="vertical-align:middle;">
                                <div style="position:relative; bottom: 5">
                                  <div class="custom-bar-Others-NPS" style="position:absolute; color: `+res.TextColors[1]+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-NPS-allOthers-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar-graph">
                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                  <div class="vertical-line"></div>

                                  <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                </div>

                                <div style="position:relative; bottom: 23">
                                  <div class="custom-bar-Self-KDY" style="position:absolute; color: `+res.TextColors[0]+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-NPS-self-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </td>

                              <td style="width: 30%; vertical-align:middle; text-align: right;">
                                O = Others, S = Self
                              </td>
                            </tr>
                          </table>

                          <div class="row">
                            <div class="col-7">
                              <h5 class="mb-3">`+ res.heading3+`</h5>

                              <div class="text-center f5-spinner">
                                <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                  <span class="sr-only"></span>
                                </div>
                              </div>

                              <table class="table d-none f5-table" style="border-top: 1px solid #ccc;">
                                <thead>
                                  <tr>
                                    <th class="text-center" scope="col"><span style="color: `+res.Colors.color_s+` ">`+res.heading1a+`</span> <span>`+res.heading1b+`</span></th>
                                    <th class="text-center" scope="col">`+ res.heading2 +`</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  `
                                    res.Competencies.forEach((resCompetency) => {
                                      output += `
                                        <tr>
                                          <td style="width: 33%">`+ resCompetency.Name+`</td>
                                          <td style="padding-right: 35px;">
                                            
                                            <div style="position:relative; bottom: 5">
                                              <div class="custom-bar-Others-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                                <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                            <div class="custom-bar-graph">
                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[0]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[1]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[2]+`;"></div>

                                              <div class="vertical-line"></div>

                                              <div class="custom-bar custom-bar-color" style="background-color: `+res.BarColors[3]+`;"></div>
                                            </div>

                                            <div style="position:relative; bottom: 23">
                                              <div class="custom-bar-Self-`+resCompetency.Acronym+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                                <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+resCompetency.Acronym+`-value" style="font-size: 12px;"></span>
                                              </div>
                                            </div>

                                          </td>
                                        </tr>
                                      `
                                    })
                                  output += `
                                </tbody>
                              </table>
                            </div>

                            <div class="col-5">
                              <!-- Top Behaviours   -->
                              <div>
                                <h5 class="mb-3">`+ res.heading4+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table top-behaviours-wrapper">
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-behaviours-sortme">
                                            <li class="top_behaviours_` + response.Trait + ` top-behaviours-average-score"></li>
                                            <li>`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="total_avg_score` + response.Trait + `"></span>)</span></li>
                                          </div>
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                              <!-- Underappreciated strengths -->
                              <div>
                                <h5 class="mb-3">`+ res.heading5+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table undiscovered-strength-wrapper">
                                  <p class="d-none undiscovered-strength-message"><i>Your understanding of your strengths aligns with that of your nominees</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-undiscovered-strength-sortme">
                                            <li class="top-undiscovered-strength_` + response.Trait + ` top-undiscovered-strength-average-score"></li>
                                            <li class="uslistZeroItem d-none undiscovered-strength-all-rows undiscovered-strength-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>
                              

                              <!-- Potential Blindspots -->
                              <div>
                                <h5 class="mb-3">`+ res.heading6+`</h5>

                                <div class="text-center f5-spinner">
                                  <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                </div>

                                <ul class="d-none f5-table potential-blindspot-wrapper">
                                  <p class="d-none potential-blindspot-message"><i>You are already aware of your strengths and use them effectively</i></p>
                                  `
                                    for (const res of obj) {
                                      res.Elements.forEach((response) => {
                                        output += `
                                          <div class="top-potential-blindspot-sortme">
                                            <li class="top-potential-blindspot_` + response.Trait + ` top-potential-blindspot-average-score"></li>
                                            <li class="pblistZeroItem d-none potential-blindspot-all-rows potential-blindspot-row-` + response.Trait + `">`+response.Behaviour+` (`+response.Competency+`) <span class="d-none isSmartCollab">(<span class="top-undiscovered-strength-others_` + response.Trait + `"></span>/<span class="top-undiscovered-strength-self_` + response.Trait + `"></span>)</span></li>
                                          </div>  
                                        `
                                      })
                                    }
                                  output += `
                                </ul>
                              </div>

                            </div>
                          </div>

                          <!-- Organizational Climate Snapshot -->
                          <div class="mt-2">
                            <h5 class="mb-3">`+ res.ocsTitle+`</h5>

                            <div class="text-center f5-spinner">
                              <div class="spinner-border text-danger mb-5 mt-5" role="status">
                                <span class="sr-only"></span>
                              </div>
                            </div>
                            <div class="d-none f5-table new-line-chart-container-ocs">
                                <canvas class="` + res.ocsSuperTrait + `"></canvas>
                            </div>
                          </div>

                        `
                      }
                    } 
                    
                    // section 3-8 with cohort data and average all others 
                    if (data.Template === 'Bar-Chart-New-360-Sub') {
                  let obj = JSON.parse(data.Text)
      
                  for (const res of obj) {
                    output += `
                      <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                      <div class="text-center 360_sub_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      
                      <div class="360_sub d-none">
                        <table class="showSliders table behavior_table_`+ res.Elements[0].Trait +`" style="border-top: 1px solid #ccc;">
                          <thead>
                            <tr>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">`+res.Elements[0].Description+`</th>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">
                                <div class="custom-bar-graph ">
                                  <div style="position:relative; top: -28px">
                                    <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                      <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                    </div>
                                  </div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                  <div class="vertical-line" style="left: 25%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                  <div class="vertical-line" style="left: 50%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                  <div class="vertical-line" style="left: 75%"></div>

                                  <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                  <div style="position:relative; bottom: -8px">
                                    <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                      <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                    </div>
                                  </div>
                                </div>
                              </th>
                              <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                <!-- Breakdown keys -->
                                <div class="breakdown-keys">
                                  <h6 style="text-align: right">My Respondents</h6>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                  <p class="bd-key-`+ res.Elements[0].Trait +`-O" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                </div>  
                              </th>
                            </tr>

                            <tr>
                              <th scope="col" class="text-center"></th>
                              <th scope="col"></th>
                              <th scope="col" style="padding-left:50px; text-align: center; font-size: 12px!important;">My Response Distribution</th>

                              <th scope="col" style="font-size: 12px!important;" class="text-center">Cohort Respondents' Score</th>
                            </tr>
                          </thead>

                          <tbody>
                            `
                              res.Elements.map(behavior=>{
                                output +=`
                                    <tr>
                                      <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+behavior.Description+`</td>  
                                      <td style="width: 40%;">

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PS">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-PS" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PS"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                          
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-P ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-P" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-P"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-D ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-D" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-D"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  


                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PD">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-PD" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PD"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-I">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-I" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-I"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-E ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-E" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-E"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-IE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-IE" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-IE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-PDIE" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PDIE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-Others">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+behavior.Trait+`-Others" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-Others"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                            <div class="vertical-line" style="left: 25%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                            <div class="vertical-line" style="left: 50%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                            <div class="vertical-line" style="left: 75%"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-S ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+behavior.Trait+`-S" style="position: absolute; font-weight: bold;"><span class="trait-`+behavior.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-S"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  
                                      </td>  
                                      
                                      <td style="padding-left:50px; padding-top: 0px;">
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PS" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-P" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-D" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PD" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-I" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-E" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-IE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div class="custom-bar-graph-`+ behavior.Trait +`-Others" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div></div> <!-- No Histogram for Self -->
                                      </td>


                                      <td style="width: 10%; vertical-align: middle; text-align: center;">
                                        <span class="cohortScore-`+behavior.Trait+`"></span>  
                                      </td>
                                    </tr>`
                                  })
                            output += `
                          </tbody>
                        </table>
                      </div>
                      <!-- end - EMil -->

                      <div class="360_sub d-none">
                        <table class="table360 showBarChart" style="width:100%;">
                          <tr>
                            <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements[0].Description+`</th>
                            <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;">
                              <div class="custom-bar-graph ">
                                <div style="position:relative; top: -28px">
                                  <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                    <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                <div class="vertical-line" style="left: 25%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                <div class="vertical-line" style="left: 50%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                <div class="vertical-line" style="left: 75%"></div>

                                <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                <div style="position:relative; bottom: -8px">
                                  <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                    <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                              <!-- Breakdown keys -->
                              <div class="breakdown-keys">
                                <h6 style="text-align: right">My Respondents</h6>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                <p class="bd-key-`+ res.Elements[0].Trait +`-O" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                              </div>  
                            </th>
                          </tr>

                          <tr>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=" text-center">My Respondents' Score</th>
                            <th class=" text-center">My Respondents' Distribution</th>
                            <th class=" text-center">Cohort Respondents' Score</th>
                          <!--<th class=" text-center d-none">Cohort Score</th>-->
                          </tr>
                            `
                            res.Elements.forEach(response => {
                              output += 
                              `
                                <tr class="MAIN">
                                  <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                                </tr>
                                <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                  <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="heading-score-barchart2">
                                    <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="heading-score-barchart2" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-P-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-D-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                  </td>

                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-I-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-E-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>                  
            
                                <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->

                                </tr>
            
                                <!-- Change total to Average all others -->
                                <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1 " style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1 d-none">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
                              `
                            });
                          output += `
                        </table>
                      </div>
                    `
                  }
                     }
                    // section 3-8 without cohort data and average all others 
                    if (data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others') {
                      let obj = JSON.parse(data.Text)
          
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements[0].Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 30%; vertical-align: middle; padding-bottom: 5rem;">`+res.Elements[0].Description+`</th>
                                  <th scope="col" style="width: 40%; vertical-align: middle; padding-bottom: 5rem;">
                                    <div class="custom-bar-graph ">
                                      <div style="position:relative; top: -28px">
                                        <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                          <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                      <div class="vertical-line" style="left: 25%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                      <div class="vertical-line" style="left: 50%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                      <div class="vertical-line" style="left: 75%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                      <div style="position:relative; bottom: -8px">
                                        <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                          <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col" class="th-slider-colspan-without-cohort-others" style="text-align: center; font-size: 12px!important;">My Response Distribution</th>
                                  <th scope="col" style="padding-left:50px;" class="histo-hide-show"></th>
                                </tr>
                              </thead>

                              <tbody>
                                `
                                  res.Elements.map(behavior=>{
                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+behavior.Description+`</td>  
                                          <td style="width: 40%;" class="td-slider-colspan-without-cohort-others">

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PS" style="position: absolute;"><span class="trait-`+behavior.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-P" style="position: absolute; "><span class="trait-`+behavior.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-D" style="position: absolute; "><span class="trait-`+behavior.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PD" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-I" style="position: absolute; "><span class="trait-`+behavior.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+behavior.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-E" style="position: absolute; "><span class="trait-`+behavior.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+behavior.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-IE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+behavior.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-Others" style="position: absolute;"><span class="trait-`+behavior.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-S" style="position: absolute;"><span class="trait-`+behavior.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>
                                        </tr>`
                                      })
                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements[0].Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;" class="th-barchart-colspan-without-cohort-others">
                                  <div class="custom-bar-graph ">
                                    <div style="position:relative; top: -28px">
                                      <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                        <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                    <div class="vertical-line" style="left: 25%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                    <div class="vertical-line" style="left: 50%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                    <div class="vertical-line" style="left: 75%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                    <div style="position:relative; bottom: -8px">
                                      <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                        <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                              </tr>
                                `
                                res.Elements.forEach(response => {
                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                                  `
                                });
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }
                    // section 3-8 with cohort data and `NO` average all others 
                    if (data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others') {
                      let obj = JSON.parse(data.Text)
          
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements[0].Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 30%; vertical-align: middle; padding-bottom: 5rem;">`+res.Elements[0].Description+`</th>
                                  <th scope="col" style="width: 40%; vertical-align: middle; padding-bottom: 5rem;">
                                    <div class="custom-bar-graph ">
                                      <div style="position:relative; top: -28px">
                                        <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                          <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                      <div class="vertical-line" style="left: 25%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                      <div class="vertical-line" style="left: 50%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                      <div class="vertical-line" style="left: 75%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                      <div style="position:relative; bottom: -8px">
                                        <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                          <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col" class="th-slider-colspan-with-cohort-no-others" style="text-align: center; font-size: 12px!important;">My Response Distribution</th>
                                  <th scope="col" style="padding-left:50px;" class="histo-hide-show"></th>
                                </tr>
                              </thead>

                              <tbody>
                                `
                                  res.Elements.map(behavior=>{
                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+behavior.Description+`</td>  
                                          <td style="width: 40%;" class="td-slider-colspan-with-cohort-no-others">

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PS" style="position: absolute;"><span class="trait-`+behavior.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-P" style="position: absolute; "><span class="trait-`+behavior.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-D" style="position: absolute; "><span class="trait-`+behavior.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PD" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-I" style="position: absolute; "><span class="trait-`+behavior.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+behavior.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-E" style="position: absolute; "><span class="trait-`+behavior.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+behavior.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-IE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+behavior.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-Others" style="position: absolute;"><span class="trait-`+behavior.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-S" style="position: absolute;"><span class="trait-`+behavior.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>
                                        </tr>`
                                      })
                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements[0].Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;">
                                  <div class="custom-bar-graph ">
                                    <div style="position:relative; top: -28px">
                                      <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                        <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                    <div class="vertical-line" style="left: 25%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                    <div class="vertical-line" style="left: 50%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                    <div class="vertical-line" style="left: 75%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                    <div style="position:relative; bottom: -8px">
                                      <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                        <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                                <th class=" text-center">Cohort Respondents' Score</th>
                              <!--<th class=" text-center d-none">Cohort Score</th>-->
                              </tr>
                                `
                                res.Elements.forEach(response => {
                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="heading-score-barchart2" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>-->

                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 " style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1 d-none">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                                  `
                                });
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }
                    // section 3-8 with average all others and `NO` cohort data
                    if (data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort') {
                      let obj = JSON.parse(data.Text)
          
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements[0].Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">`+res.Elements[0].Description+`</th>
                                  <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">
                                    <div class="custom-bar-graph ">
                                      <div style="position:relative; top: -28px">
                                        <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                          <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                      <div class="vertical-line" style="left: 25%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                      <div class="vertical-line" style="left: 50%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                      <div class="vertical-line" style="left: 75%"></div>

                                      <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                      <div style="position:relative; bottom: -8px">
                                        <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                          <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col"></th>
                                  <th scope="col" style="padding-left:50px; text-align: center; font-size: 12px!important;" class="histo-hide-show">My Response Distribution</th>
                                  <th scope="col" style="font-size: 12px!important;" class="text-center">Average All Others</th>
                                </tr>
                              </thead>

                              <tbody>
                                `
                                  res.Elements.map(behavior=>{
                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+behavior.Description+`</td>  
                                          <td style="width: 40%;">

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PS" style="position: absolute;"><span class="trait-`+behavior.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-P" style="position: absolute; "><span class="trait-`+behavior.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-D" style="position: absolute; "><span class="trait-`+behavior.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PD" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-I" style="position: absolute; "><span class="trait-`+behavior.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+behavior.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-E" style="position: absolute; "><span class="trait-`+behavior.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+behavior.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-IE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+behavior.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+behavior.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-Others" style="position: absolute;"><span class="trait-`+behavior.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+behavior.Trait+`-S" style="position: absolute;"><span class="trait-`+behavior.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+behavior.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+behavior.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ behavior.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ behavior.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ behavior.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>

                                          <td style="width: 10%; vertical-align: middle; text-align: center;" class="td-slider-width-with-others-no-cohort">
                                            <span class="averageOthers-`+behavior.Trait+`"></span>  
                                          </td>
                                        </tr>`
                                      })
                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements[0].Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;" class="th-barchart-colspan-with-others-no-cohort">
                                  <div class="custom-bar-graph ">
                                    <div style="position:relative; top: -28px">
                                      <div class="custom-bar-Others-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_o+`">
                                        <span class="cb-marker-o" style="font-size: 20px;">O</span><span class="custom-bar-Others-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[0]+`;"></div>
                                    <div class="vertical-line" style="left: 25%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[1]+`;"></div>

                                    <div class="vertical-line" style="left: 50%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[2]+`;"></div>

                                    <div class="vertical-line" style="left: 75%"></div>

                                    <div class="custom-bar custom-bar-color-`+res.Elements[0].Trait+`" style="background-color: `+res.Colors.BarColors[3]+`;"></div>

                                    <div style="position:relative; bottom: -8px">
                                      <div class="custom-bar-Self-`+res.Elements[0].Trait+`" style="position:absolute; color: `+res.Colors.color_s+`">
                                        <span class="cb-marker-s" style="font-size: 20px;">S</span><span class="custom-bar-Self-`+res.Elements[0].Trait+`-value" style="font-size: 12px;"></span>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements[0].Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements[0].Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements[0].Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements[0].Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements[0].Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements[0].Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements[0].Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                              </tr>
                                `
                                res.Elements.forEach(response => {
                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ response.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-Psup-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-P-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-D-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PD-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + response.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-I-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-E-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + response.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-IE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + response.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+response.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-SELF-` + response.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + response.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ response.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                                  `
                                });
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }

                    // section for multiple nps html
                    if (data.Template === 'Bar-Chart-New-360-Sub-NPS') {
                  let obj = JSON.parse(data.Text)
      
                  for (const res of obj) {
                    output += `
                      <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                      <div class="text-center 360_sub_spinner">
                        <div class="spinner-border text-danger mb-5 mt-5" role="status">
                          <span class="sr-only"></span>
                        </div>
                      </div>
                      
                      <div class="360_sub d-none">
                        <table class="showSliders table behavior_table_`+ res.Elements.Trait +`" style="border-top: 1px solid #ccc;">
                          <thead>
                            <tr>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">`+res.Elements.Description+`</th>
                              <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;"></th>
                              <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                <!-- Breakdown keys -->
                                <div class="breakdown-keys">
                                  <h6 style="text-align: right">My Respondents</h6>
                                  <p class="bd-key-`+ res.Elements.Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                  <p class="bd-key-`+ res.Elements.Trait +`-O" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                </div>  
                              </th>
                            </tr>

                            <tr>
                              <th scope="col" class="text-center"></th>
                              <th scope="col"></th>
                              <th scope="col" style="padding-left:50px; text-align: center; font-size: 12px!important;">My Response Distribution</th>
                              <th scope="col" style="font-size: 12px!important;" class="text-center">Average All Others</th>
                            </tr>
                          </thead>

                          <tbody>
                            `

                                output +=`
                                    <tr>
                                      <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+res.Elements.Description+`</td>  
                                      <td style="width: 40%;">

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+res.Elements.Trait+`-PS" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PS"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                          
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-P ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-P" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-P"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-D ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-D" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-D"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  


                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-PD" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PD"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-I">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-I" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-I"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-E ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-E" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-E"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-IE" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-IE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+res.Elements.Trait+`-PDIE" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PDIE"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p class="position-`+res.Elements.Trait+`-Others" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-Others"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                            <div class="vertical-line" style="left: 25%"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                            <div class="vertical-line" style="left: 50%"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                            <div class="vertical-line" style="left: 75%"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                          </div>
                                        </div>  

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-S ">
                                          <div class="custom-bar-graph">

                                            <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                              <p  class="position-`+res.Elements.Trait+`-S" style="position: absolute; font-weight: bold;"><span class="trait-`+res.Elements.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-S"></span></p>
                                            </div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                            <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                            <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                            <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                          </div>
                                        </div>  
                                      </td>  
                                      
                                      <td style="padding-left:50px; padding-top: 0px;">
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-P" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-D" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-I" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-E" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          </ul>
                                        </div>
                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others" style="margin-top: 3px">
                                          <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                            <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                            <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                          </ul>
                                        </div>

                                        <div></div> <!-- No Histogram for Self -->
                                      </td>

                                      <td style="width: 10%; vertical-align: middle; text-align: center;">
                                        <span class="averageOthers-`+res.Elements.Trait+`"></span>  
                                      </td>
                                    </tr>`

                            output += `
                          </tbody>
                        </table>
                      </div>
                      <!-- end - EMil -->

                      <div class="360_sub d-none">
                        <table class="table360 showBarChart" style="width:100%;">
                          <tr>
                            <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements.Description+`</th>
                            <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;"></th>
                            <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                              <!-- Breakdown keys -->
                              <div class="breakdown-keys">
                                <h6 style="text-align: right">My Respondents</h6>
                                <p class="bd-key-`+ res.Elements.Trait +`-PS " style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-P " style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-D " style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-PD " style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-I " style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-E " style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-IE " style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-PDIE " style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                <p class="bd-key-`+ res.Elements.Trait +`-O" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                              </div>  
                            </th>
                          </tr>

                          <tr>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=""></th>
                            <th class=" text-center">My Respondents' Score</th>
                            <th class=" text-center">My Respondents' Distribution</th>
                            <th class=" text-center">Cohort Respondents' Score</th>
                          <!--<th class=" text-center d-none">Cohort Score</th>-->
                          </tr>
                            `

                              output += 
                              `
                                <tr class="MAIN">
                                  <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ res.Elements.Description +`</td>
                                </tr>
                                <tr class="sub-bar-chart-Psup-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                  <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="heading-score-barchart2">
                                    <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="heading-score-barchart2" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-P-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-D-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PD-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-I-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-E-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>                  
            
                                <tr class="sub-bar-chart-IE-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>

                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
          
                                <tr class="sub-bar-chart-PDIE-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space" style="flex-grow: 10;"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                    </ul>
                                  </td>-->

                                </tr>
            
                                <!-- Change total to Average all others -->
                                <tr class="sub-bar-chart-TOTAL-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
            
                                <tr class="sub-bar-chart-SELF-`+res.Elements.Trait+`">
                                  <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div class="bar-container-barchart2">
                                      <div class="bar-difference my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                      <div class="break-column"></div>
                                      <div class="bar-difference cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                      <div class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                    </div>
                                  </td>
            
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>
                                  
                                  <td class="sub-heading-Histogram-barchart1-barchart1 " style="padding-left: 10px;">
                                    <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                  </td>

                                  <!--<td class="sub-heading-Histogram-barchart1-barchart1 d-none">
                                    <ul class="Histogram-barchart1">
                                      <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                      <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                    </ul>
                                  </td>-->
                                </tr>
                              `
                          output += `
                        </table>
                      </div>
                    `
                  }
                     }

                     if (data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others-NPS') {
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements.Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 30%; vertical-align: middle; padding-bottom: 5rem;">`+res.Elements.Description+`</th>
                                  <th scope="col" style="width: 40%; vertical-align: middle; padding-bottom: 5rem;"></th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col" class="th-slider-colspan-without-cohort-others" style="text-align: center; font-size: 12px!important;">My Response Distribution</th>
                                  <th scope="col" style="padding-left:50px;" class="histo-hide-show"></th>
                                </tr>
                              </thead>

                              <tbody>
                                `

                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+res.Elements.Description+`</td>  
                                          <td style="width: 40%;" class="td-slider-colspan-without-cohort-others">

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PS" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-P" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-D" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PD" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-I" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+res.Elements.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-E" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+res.Elements.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-IE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+res.Elements.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-Others" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-S" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>
                                        </tr>`
                                      
                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements.Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;" class="th-barchart-colspan-without-cohort-others"></th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                              </tr>
                                `
                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ res.Elements.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                                  `
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }

                    if (data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others-NPS') {
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements.Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="width: 30%; vertical-align: middle; padding-bottom: 5rem;">`+res.Elements.Description+`</th>
                                  <th scope="col" style="width: 40%; vertical-align: middle; padding-bottom: 5rem;"></th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col" class="th-slider-colspan-with-cohort-no-others" style="text-align: center; font-size: 12px!important;">My Response Distribution</th>
                                  <th scope="col" style="padding-left:50px;" class="histo-hide-show"></th>
                                </tr>
                              </thead>

                              <tbody>
                                `
                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+res.Elements.Description+`</td>  
                                          <td style="width: 40%;" class="td-slider-colspan-with-cohort-no-others">

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PS" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-P" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-D" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PD" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-I" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+res.Elements.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-E" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+res.Elements.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-IE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+res.Elements.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-Others" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-S" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>
                                        </tr>`
                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements.Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;"></th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                                <th class=" text-center">Cohort Respondents' Score</th>
                              <!--<th class=" text-center d-none">Cohort Score</th>-->
                              </tr>
                                `

                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ res.Elements.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="heading-score-barchart2" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>-->

                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1" style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="bar-difference cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 " style="padding-left: 10px;">
                                        <div style="color: rgba(0, 0, 0, 0.781); font-weight: bold;"><span style="font-weight: bold;" class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <!--<td class="sub-heading-Histogram-barchart1-barchart1 d-none">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>-->
                                    </tr>
                                  `
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }

                    if (data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort-NPS') {
                      let obj = JSON.parse(data.Text)
                      for (const res of obj) {
                        output += `
                          <!-- Behaviors for each of Competencies in section 3 to 8 - Emil -->

                          <div class="text-center 360_sub_spinner">
                            <div class="spinner-border text-danger mb-5 mt-5" role="status">
                              <span class="sr-only"></span>
                            </div>
                          </div>
                          
                          <div class="360_sub d-none">
                            <table class="showSliders table behavior_table_`+ res.Elements.Trait +`" style="border-top: 1px solid #ccc;">
                              <thead>
                                <tr>
                                  <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;">`+res.Elements.Description+`</th>
                                  <th scope="col" style="vertical-align: middle; padding-bottom: 5rem;"></th>
                                  <th scope="col" colspan="2" style="padding-left: 50px; padding-bottom: 5rem;">
                                    <!-- Breakdown keys -->
                                    <div class="breakdown-keys">
                                      <h6 style="text-align: right">My Respondents</h6>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                      <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                    </div>  
                                  </th>
                                </tr>

                                <tr>
                                  <th scope="col" class="text-center"></th>
                                  <th scope="col"></th>
                                  <th scope="col" style="padding-left:50px; text-align: center; font-size: 12px!important;" class="histo-hide-show">My Response Distribution</th>
                                  <th scope="col" style="font-size: 12px!important;" class="text-center">Average All Others</th>
                                </tr>
                              </thead>

                              <tbody>
                                `

                                    output +=`
                                        <tr>
                                          <td style="width: 30%; vertical-align: middle; padding-left: 10px" >`+res.Elements.Description+`</td>  
                                          <td style="width: 40%;">

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PS" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-PS" style="color: `+res.Colors.color_ps+`">PS</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PS"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                              
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-P" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-P" style="color: `+res.Colors.color_p+`">P</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-P"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-D" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-D" style="color: `+res.Colors.color_d+`">D</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-D"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  


                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PD" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PD" style="color: `+res.Colors.color_pd+`">PD</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-PD"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-I" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-I" style="color: `+res.Colors.color_i+`">I</span><span class="span-`+res.Elements.Trait+`-value-I"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-E" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-E" style="color: `+res.Colors.color_e+`">E</span><span class="span-`+res.Elements.Trait+`-value-E"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-IE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-IE" style="color: `+res.Colors.color_ie+`">IE</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-IE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-PDIE" style="position: absolute; "><span class="trait-`+res.Elements.Trait+`-PDIE" style="color: `+res.Colors.color_pdie+`">PDIE</span><span class="span-`+res.Elements.Trait+`-value-PDIE"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-Others" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-Others" style="color: `+res.Colors.color_o+`">O</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-Others"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[0]+`; "></div>
                                                  <div class="vertical-line" style="left: 25%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[1]+`; "></div>

                                                  <div class="vertical-line" style="left: 50%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[2]+`; "></div>

                                                  <div class="vertical-line" style="left: 75%"></div>

                                                  <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: `+res.Colors.BarColors[3]+`; "></div>
                                                </div>
                                            </div>  

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-S d-none">
                                              <div class="custom-bar-graph">

                                                <div style="display: flex;position: absolute; top: -20px;width: 100%;">
                                                  <p class="position-`+res.Elements.Trait+`-S" style="position: absolute;"><span class="trait-`+res.Elements.Trait+`-S" style="color: `+res.Colors.color_s+`">S</span><span style="font-size: 12px" class="span-`+res.Elements.Trait+`-value-S"></span></p>
                                                </div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                                <div class="vertical-line" style="left: 25%;z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 50%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>

                                                <div class="vertical-line" style="left: 75%; z-index: 2;"></div>

                                                <div class="custom-bar custom-bar-color-`+res.Elements.Trait+`" style="background-color: gray; height: 1px; "></div>
                                              </div>
                                            </div>  
                                          </td>  
                                          
                                          <td style="padding-left:50px; padding-top: 0px;" class="histo-hide-show">
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PS d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-P d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-D d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PD d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-I d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-E d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-IE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                              </ul>
                                            </div>
                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-PDIE d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div class="custom-bar-graph-`+ res.Elements.Trait +`-Others d-none" style="margin-top: 3px">
                                              <ul class="Histogram-barchart1" style="margin-bottom: 6px">
                                                <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                                <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Others" style="color: grey;"></li>
                                              </ul>
                                            </div>

                                            <div></div> <!-- No Histogram for Self -->
                                          </td>

                                          <td style="width: 10%; vertical-align: middle; text-align: center;" class="td-slider-width-with-others-no-cohort">
                                            <span class="averageOthers-`+res.Elements.Trait+`"></span>  
                                          </td>
                                        </tr>
                                        `

                                output += `
                              </tbody>
                            </table>
                          </div>
                          <!-- end - EMil -->

                          <div class="360_sub d-none">
                            <table class="table360 showBarChart" style="width:100%;">
                              <tr>
                                <th scope="col" style="vertical-align: middle; padding-bottom: 5rem; padding-left: 10px; padding-top: 10px;">`+res.Elements.Description+`</th>
                                <th scope="col" colspan="3" style="vertical-align: middle; padding-bottom: 5rem; padding-top: 10px;" class="th-barchart-colspan-with-others-no-cohort"></th>
                                <th scope="col" colspan="2" style="padding-left: 50px; padding-right: 10px; padding-bottom: 5rem; padding-top: 10px;">
                                  <!-- Breakdown keys -->
                                  <div class="breakdown-keys">
                                    <h6 style="text-align: right">My Respondents</h6>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PS d-none" style="color: `+res.Colors.color_ps+`">PS = Primary Supervisor (<span class="bd-`+res.Elements.Trait+`-value-PS"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-P d-none" style="color: `+res.Colors.color_p+`">P = Peers (<span class="bd-`+res.Elements.Trait+`-value-P"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-D d-none" style="color: `+res.Colors.color_d+`">D = Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-D"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PD d-none" style="color: `+res.Colors.color_pd+`">PD = Peers and Direct Reports (<span class="bd-`+res.Elements.Trait+`-value-PD"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-I d-none" style="color: `+res.Colors.color_i+`">I = Internal Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-I"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-E d-none" style="color: `+res.Colors.color_e+`">E = External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-E"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-IE d-none" style="color: `+res.Colors.color_ie+`">IE =Internal and External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-IE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-PDIE d-none" style="color: `+res.Colors.color_pdie+`">PDIE = Peers, Direct Report, Internal Stakeholders, External Stakeholders (<span class="bd-`+res.Elements.Trait+`-value-PDIE"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-S d-none" style="color: `+res.Colors.color_s+`">S = Self (<span class="bd-`+res.Elements.Trait+`-value-S"></span>)</p>
                                    <p class="bd-key-`+ res.Elements.Trait +`-O d-none" style="color: `+res.Colors.color_o+`">O = Average All Others (<span class="bd-`+res.Elements.Trait+`-value-O"></span>)</p>
                                  </div>  
                                </th>
                              </tr>

                              <tr>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=""></th>
                                <th class=" text-center">My Respondents' Score</th>
                                <th class=" text-center histo-hide-show">My Respondents' Distribution</th>
                              </tr>
                                `

                                  output += 
                                  `
                                    <tr class="MAIN">
                                      <td style="width: 30%;" class="heading-text-barchart2 sub-chart-rowspan"  rowspan="9">`+ res.Elements.Description +`</td>
                                    </tr>
                                    <tr class="sub-bar-chart-Psup-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Primary Supervisors</td>
                                      <td class="heading-bar-barchart2" style="border-right: 0 !important;">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ps+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="heading-score-barchart2">
                                        <div style="color: `+res.Colors.color_ps+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-Psup-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show" style="border-top: 1px solid black;">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Psup" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-P-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_p+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_p+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-P-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-P" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-D-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_d+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_d+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-D-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-D" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PD-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct report</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pd+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pd+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PD-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PD" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-I-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_i+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_i+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-I-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-I" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-E-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_e+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_e+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-E-` + res.Elements.Trait + `"></span></div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-E" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>                  
                
                                    <tr class="sub-bar-chart-IE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Internal/External stakeholder</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_ie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>

                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_ie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-IE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-IE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
              
                                    <tr class="sub-bar-chart-PDIE-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top">Peers/Direct reports/Internal stakeholders/External stakeholders</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_pdie+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space" style="flex-grow: 10;"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_pdie+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-PDIE-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-PDIE" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <!-- Change total to Average all others -->
                                    <tr class="sub-bar-chart-TOTAL-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Average All Others</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_o+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_o+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Total" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                
                                    <tr class="sub-bar-chart-SELF-`+res.Elements.Trait+`">
                                      <td class="sub-heading-text-barchart2-top" style="padding-right:0;">Self</td>
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div class="bar-container-barchart2">
                                          <div class="bar-difference my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: `+res.Colors.color_s+`; animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                          <div class="break-column"></div>
                                          <div class="section3-8-cohort-data bar-difference cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar" style="background-color: rgb(0, 0, 0); animation: bar-container-barchart2-animation 0.9s ease forwards"></div>
                                          <div class="cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space"></div>
                                        </div>
                                      </td>
                
                                      <td class="sub-heading-Histogram-barchart1-barchart1">
                                        <div style="color: `+res.Colors.color_s+`; font-weight: bold;"><span style="font-weight: bold;" class="my-sub-bar-chart-SELF-` + res.Elements.Trait + `"></span></div>
                                      </td>
                                      
                                      <td class="sub-heading-Histogram-barchart1-barchart1 histo-hide-show">
                                        <ul class="Histogram-barchart1">
                                          <li class="histogram-score1-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score2-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score3-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score4-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                          <li class="histogram-score5-`+ res.Elements.Trait +`-sub-Self" style="color: grey;"></li>
                                        </ul>
                                      </td>
                                    </tr>
                                  `
                              output += `
                            </table>
                          </div>
                        `
                      }
                    }

                    if (data.Template === 'Table-360-Open-ended-New') { 
                      let obj = JSON.parse(data.Text)
                        for (const res of obj) {
                          output += 
                          `
                        <table class="table360">
                        <!-- start of item  -->
                              `
                              res.Elements.forEach(response => {
                              output +=
                              `
                              <tr>
                              <td class="heading-label-OpenEndedQuestion">
                                `+ response.Description +`
                              </td>
                              <tr>
                              <td class="open-ended-container">
                                <ul id="sort-open-ended">
                                  <li class="open-ended-1` + response.Trait + `"></li>
                                  <li class="open-ended-2` + response.Trait + `"></li>
                                  <li class="open-ended-3` + response.Trait + `"></li>
                                  <li class="open-ended-4` + response.Trait + `"></li>
                                  <li class="open-ended-5` + response.Trait + `"></li>
                                  <li class="open-ended-6` + response.Trait + `"></li>
                                  <li class="open-ended-7` + response.Trait + `"></li>
                                  <li class="open-ended-8` + response.Trait + `"></li>
                                  <li class="open-ended-9` + response.Trait + `"></li>
                                  <li class="open-ended-10` + response.Trait + `"></li>
                                  <li class="open-ended-11` + response.Trait + `"></li>
                                  <li class="open-ended-12` + response.Trait + `"></li>
                                  <li class="open-ended-13` + response.Trait + `"></li>
                                  <li class="open-ended-14` + response.Trait + `"></li>
                                  <li class="open-ended-15` + response.Trait + `"></li>
                                  <li class="open-ended-16` + response.Trait + `"></li>
                                  <li class="open-ended-17` + response.Trait + `"></li>
                                  <li class="open-ended-18` + response.Trait + `"></li>
                                  <li class="open-ended-19` + response.Trait + `"></li>
                                  <li class="open-ended-20` + response.Trait + `"></li>
                                </ul>
                              </td>
                            </tr>
                          </tr>
                          `
                          });
                          output +=
                          `
                            </table>
                          `
                      }
                    }   
                  }
      output += `
                </form>
              </div>
            </div>
            </div>
            <scriptSuffixToRemove src="https://code.jquery.com/jquery-3.6.0.min.js"
              integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></scriptSuffixToRemove>
            <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/js/bootstrap.bundle.min.js"
              integrity="sha384-A3rJD856KowSb7dwlZdYEkO39Gagi7vIsF0jrRAoQmDKKtQBHUuLZ9AsSv4jD4Xa" crossorigin="anonymous">
            </scriptSuffixToRemove>
            <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></scriptSuffixToRemove>
            <scriptSuffixToRemove src="https://cdn.jsdelivr.net/npm/sweetalert2@11"></scriptSuffixToRemove>
            <scriptSuffixToRemove src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.8.2/chart.min.js">
            </scriptSuffixToRemove>
            <scriptSuffixToRemove>` + this.addJS(surveyData, orgID) + `</scriptSuffixToRemove>
          </body>
        </html>
      `

      output = output.replaceAll("SuffixToRemove", "");
      // this.finalHTML = output;

      // Create a Blob with the HTML content
      const blob = new Blob([output], { type: 'text/html' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.download = filename; // Set the filename for the downloaded file
      link.click();

      // Clean up the URL object after the file is downloaded
      URL.revokeObjectURL(url);
    }, // end of individual360Funct
    addJS(surveyData, orgID) {
      let url = 'https://be.talentsage.com'
      let baseUrl = window.location.protocol + '//' + window.location.host;
      if(baseUrl == "https://tstest.gcm3.com") {
        url = 'https://betest.gcm3.com'
      }
      else if(baseUrl == "https://uat-ts.talentsage.com/") {
        url = 'https://uatbe.talentsage.com'
      }
      else {
        url = 'https://be.talentsage.com'
      }
      
      let ORGID = orgID

      let input = JSON.parse(surveyData)

      let output = `

        // function getCookie(name) {
        //   let cookieName = name + "=";
        //   let cookies = document.cookie.split(';');
        //   for (let i = 0; i < cookies.length; i++) {
        //     let cookie = cookies[i].trim();
        //     if (cookie.indexOf(cookieName) === 0) {
        //       return cookie.substring(cookieName.length, cookie.length);
        //     }
        //   }
        //   return null;
        // }

        function printPDF(){
          window.print()
        }
        
        $(document).ready(function(){

          console.log('ORGID')
          console.log(`+ ORGID +`)
          
          if(`+ ORGID +` == 427) {
            $(".section-number").css("background-color", "rgb(2,8,64)")
            console.log('if ORGID')
          }
          else {
            $(".section-number").css("background-color", "orange")
            console.log('else ORGID')
          }

          // const cookieExists = getCookie("a3b3dd4702b847b440c49c1198d77f85") !== null;
          // if (cookieExists) {
          //   console.log('there is a cookie')
          // } else {
          //   console.log('there is no cookie')
          //   return Swal.fire({
          //     text: 'You must be logged in to access the report!',
          //     icon: "warning",
          //     showConfirmButton: false,
          //     allowOutsideClick: false
          //   })
          // }

          let config = {
            headers: {
              token: 'cCW7PW2CRotxuALrBuMob5lXgVhY4xo'
            }
          }
          let survey_assignment_id = new URL(location.href).searchParams.get('survey_assignment_id')
          let ind_id = new URL(location.href).searchParams.get('ind_id')
          let org_id = new URL(location.href).searchParams.get('org_id')
          let subOrg_id = new URL(location.href).searchParams.get('subOrg_id')
          let viewer_fullname = new URL(location.href).searchParams.get('viewer_fullname')
          let report_for_fullname = new URL(location.href).searchParams.get('report_for_fullname')
          let sex = new URL(location.href).searchParams.get('sex')
          let country = new URL(location.href).searchParams.get('country')
          let program_id = new URL(location.href).searchParams.get('program_id')
          let iteration_id = new URL(location.href).searchParams.get('iteration_id')
          let coach_id = new URL(location.href).searchParams.get('coach_id')
          let survey_template_id = new URL(location.href).searchParams.get('survey_template_id')
          let qsort_type = new URL(location.href).searchParams.get('qsort_type')
          let reqReOrder = true
          let noDuplicates = true
          let completeAnswer = true
          let run_mode = new URL(location.href).searchParams.get('run_mode')
          let org_acronym = new URL(location.href).searchParams.get('org_acronym')
          let org_name = new URL(location.href).searchParams.get('org_name')
          let suborg_name = new URL(location.href).searchParams.get('suborg_name')
          let program_name = new URL(location.href).searchParams.get('program_name')
          let iteration_name = new URL(location.href).searchParams.get('iteration_name')
          let report_template_name = new URL(location.href).searchParams.get('report_template_name')
          let participant_report_release_date = new URL(location.href).searchParams.get('participant_report_release_date')
          let client_logo = new URL(location.href).searchParams.get('client_logo')
          let brand_bgcolor = new URL(location.href).searchParams.get('brand_bgcolor')
          let expandAll = new URL(location.href).searchParams.get('expand_all')
          let isPdfAvailable = new URL(location.href).searchParams.get('is_pdf_available')

          if (expandAll === "true") {
            $('.hidden-content').css('display', 'block');
            setTimeout(function () {
              $('#expandAllBtn').click();
            }, 1000);
          }

          // click the make pdf availble button
          $(document).on('click', '#btnMakePdfAvailable', function(e) {
            if(isPdfAvailable == 1) {
              return Swal.fire({
                text: 'Your PDF is already available.',
                icon: "success",
                confirmButtonColor: '#3085d6',
              })
            }
            else if(isPdfAvailable == 0) {
              //update the survey assignment, is_pdf_available to 1
              axios
                .put(` + '`' + `${url}` + '/update-survey-pdf-available/${survey_assignment_id}' + '`, {}, config)' + `
                .then((res) => {
                  return Swal.fire({
                    text: 'Your PDF is now available.',
                    icon: "success",
                    confirmButtonColor: '#3085d6',
                  })
                })
                .catch((err) => {
                  console.log(err)
                })
            }
            else {
              return Swal.fire({
                text: 'Something went wrong with your PDF, Please try again later.',
                icon: "error",
                confirmButtonColor: '#3085d6',
              })
            }
          })

          if (run_mode === "ReadOnly") {
            $('input').attr('disabled','disabled')
            $('select').attr('disabled','disabled')
            $('button').attr('disabled','disabled')
          }

          $(".front-page-client-logo-img").css("background-color", brand_bgcolor);
          $(".front-page-client-logo").attr("src", client_logo)
          $('.front-page-program-name').text(program_name)
          $('.front-page-survey-title').text(report_template_name)
          $('.front-page-survey-subject-name').text(report_for_fullname)
          $('.front-page-participant-report-release-date').text(participant_report_release_date)
          $('.front-page-downloaded-by').text(viewer_fullname)

          let today = new Date()
          let dd = today.getDate()
          let mm = today.getMonth() + 1
          const yyyy = today.getFullYear()

          if(mm < 10) mm = '0' +mm 
          if(dd < 10) dd = '0' +dd

          $('.front-page-date-today').text(dd+ '/' +mm+ '/' +yyyy)

          document.body.innerHTML = document.body.innerHTML.replace(/&amp;output_name/g, viewer_fullname)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;report_for_fullname/g, report_for_fullname)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;org_acronym/g, org_acronym)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;org_name/g, org_name)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;suborg_name/g, suborg_name)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;program_name/g, program_name)
          document.body.innerHTML = document.body.innerHTML.replace(/&amp;iteration_name/g, iteration_name)

          function getLogoHtml(id, pacifierText) {
            let logoUrl;
            switch (id) {
                case 1:
                    logoUrl = 'https://ts.talentsage.com/1ccaa508-f5eb-11ec-bb65-06c5d6b8da7c/logo.png';
                    break;
                case 425:
                    logoUrl = 'https://ts.talentsage.com/ea0afcc3-066c-11ed-a84d-0aab292fc3a4/logo.png';
                    break;
                case 426:
                    logoUrl = 'https://ts.talentsage.com/e1aeb3c6-fc0b-11ec-bb65-06c5d6b8da7c/logo.png';
                    break;
                case 427:
                    logoUrl = 'https://ts.talentsage.com/1d52d28f-0665-11ed-a84d-0aab292fc3a4/logo.png';
                    break;
                case 428:
                    logoUrl = 'https://ts.talentsage.com/1c1e8da7-027a-11ed-a337-062734f9244f/logo.png';
                    break;
                default:
                    // Default logo URL or handle other cases as needed
                    logoUrl = 'https://ts.talentsage.com/1ccaa508-f5eb-11ec-bb65-06c5d6b8da7c/logo.png';
            }
            let htmlString = 
            '<div>' +
                '<img src="' + logoUrl + '" alt="Organization Logo" style="height: 70px; object-fit: cover;">' +
                '<br>' +
                '<br>' +
              '<div>' +
                pacifierText + 
                '<div class="loading-spinner">' +
                  '<img src="https://ts.talentsage.com/loading-talentsage.gif" alt="Loading..." style="width: 50px; height: 50px;" />' +
                '</div>' +
              '</div>' +
            '</div>';
            return htmlString;
        }
          axios
          .get(
            'https://be.talentsage.com/brandswithsuborg/`+ ORGID +`',
            config                                            
          )
          .then((res) => {
            const filteredBrandObjects = res.data.filter(obj => obj.suborg_id === null || obj.suborg_id === 0);
            let pacifierText = filteredBrandObjects[0].pacifier_text
            console.log('filteredBrandObjects',filteredBrandObjects[0])
            
              if (expandAll != "true") {
                Swal.fire({
                  html: getLogoHtml(`+ ORGID +`, pacifierText), // Replace with the desired org_id
                  timer: 5000,  // Set the countdown time in milliseconds (5 seconds in this example)
                  allowOutsideClick: false,  // Prevent users from closing the modal by clicking outside
                  showConfirmButton: false,  // Hide the confirmation button
                  width: '50%', // Set the width of the modal (adjust as needed)
                  padding: '3em', // Add padding to the modal content
                  backdrop:
                      'rgba(0, 0, 0, 0.5)' +
                      'center left' +
                      'no-repeat',
                  onBeforeOpen: () => {
                      // Show the hidden content initially
                      $('.hidden-content').css('display', 'block');

                      // Initialize the countdown
                      const timerElement = $('.swal2-html-container b')[0];

                      const countdownTimer = setInterval(() => {
                          const timerValue = Math.ceil(Swal.getTimerLeft() / 1000);
                          timerElement.textContent = timerValue;

                          // If the countdown reaches 0, close the modal
                          if (timerValue <= 0) {
                              clearInterval(countdownTimer);
                              Swal.close();
                          }
                      }, 1000);
                  },
                  willClose: () => {
                      // Cleanup or additional actions just before the modal closes
                      console.log('Modal about to close!');

                      // Show the hidden content again before the modal closes
                      $('.hidden-content').css('display', 'block');
                  }
                });
              }

            })
            .catch((err) => {
              console.log(err)
            })

          // by default button label is Show Bar Chart
          localStorage.setItem("hasCohort", "yes");

          // toggle button for F5 New 360 Report
          let buttonState = localStorage.getItem("button-state"); // retrieve button state from localStorage
          if (buttonState === "on") {
            $(".toggle-bar-chart").addClass("active");
            // console.log("Show Bar Chart");
            $('.showBarChart').show()
            $('.showSliders').hide()
            $(".toggle-bar-chart-text").text('Show Sliders')
          } else {
            // console.log("hide bar chart");
            $('.showBarChart').hide()
            $('.showSliders').show()
            
            const hasCohort = localStorage.getItem("hasCohort")
            if(hasCohort == "no") {
              $(".toggle-bar-chart-text").text('Show Bar Chart')
            }
            else {
              $(".toggle-bar-chart-text").text('Show Bar Chart')
            }
          }

          $(".toggle-bar-chart").click(function(e){
            e.preventDefault()
            if ($(this).hasClass("active")) {
              $(this).removeClass("active");
              localStorage.setItem("button-state", "off"); // store button state in localStorage
              // console.log("hide bar chart");
              $('.showBarChart').hide()
              $('.showSliders').show()

              const hasCohort = localStorage.getItem("hasCohort")
              if(hasCohort == "no") {
                $(".toggle-bar-chart-text").text('Show Bar Chart')
              }
              else {
                $(".toggle-bar-chart-text").text('Show Bar Chart')
              }
            } else {
              $(this).addClass("active");
              localStorage.setItem("button-state", "on"); // store button state in localStorage
              // console.log("Show Bar Chart");
              $('.showBarChart').show()
              $('.showSliders').hide()
              $(".toggle-bar-chart-text").text('Show Sliders')
            }
          });
      `

      // for New 360 Individual Report
      for (let i = 0; i < input.length; i++) {
        const data = input[i];

        if (data.Template === 'New-360-Report') {
          output += `
          
            axios
            .get(` + '`' + `${url}` + '/360NetPromoterScore/survey_assignment/${survey_assignment_id}' + '`, config)' + `
            .then((res) => {
              $('.custom-bar-NPS-allOthers-value').text(parseFloat(res.data.AverageAllNom).toFixed(1));
              $('.custom-bar-NPS-self-value').text(parseFloat(res.data.selfScore).toFixed(1)); 
              let AverageAllNom = parseFloat(res.data.AverageAllNom);
              let adjustedOthersValue = AverageAllNom * 10;
                $('.custom-bar-Others-NPS').css('left', adjustedOthersValue + '%');
              let selfScore = parseFloat(res.data.selfScore);
              let adjustedSelfValue = selfScore * 10;
                $('.custom-bar-Self-KDY').css('left', adjustedSelfValue + '%');
            })
            .catch((err) => {
              console.log(err)
            })

            axios
            .post(
              '${url}/r360-report-data2a',
              {
                survey_assignment_id: survey_assignment_id
              },
              config                                            
            )
            .then((res) => {
              console.log(res)
            `
              for (let i = 0; i < input.length; i++) {
                const data = input[i]
                
                if (data.Template === 'Table-360-Open-ended') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output +=`
                    function checkonlySpaces(str) {
                          return str.trim().length === 0;
                        }
                    `
                    res.Elements.forEach((response) => {
                      output += 
                      `
                        if(res.data.length < 1){
                          $(".open-ended-1` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[0] != 'undefined'){
                          if(res.data[0].font === "bold"){
                            $(".open-ended-1` + response.Trait + `").css("font-weight", "bold")
                          } else if(res.data[0].font === "italic") {
                            $(".open-ended-1` + response.Trait + `").css("font-style", "italic")
                          }
                          $(".open-ended-1` + response.Trait + `").html(res.data[0].` + response.Trait + `)
                        }

                        if(res.data.length < 2){
                          $(".open-ended-2` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[1] != 'undefined'){
                          if(res.data[1].font === "bold"){
                            $(".open-ended-2` + response.Trait + `").css("font-weight", "bold")
                          } else if(res.data[1].font === "italic") {
                            $(".open-ended-2` + response.Trait + `").css("font-style", "italic")
                          }
                          $(".open-ended-2` + response.Trait + `").html(res.data[1].` + response.Trait + `)
                        }

                        if(res.data.length < 3){
                          $(".open-ended-3` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[2] != 'undefined'){
                          if(res.data[2].font === "bold"){
                            $(".open-ended-3` + response.Trait + `").css("font-weight", "bold")
                          } else if(res.data[2].font === "italic") {
                            $(".open-ended-3` + response.Trait + `").css("font-style", "italic")
                          }
                          $(".open-ended-3` + response.Trait + `").html(res.data[2].` + response.Trait + `)
                        }

                        if(res.data.length < 4){
                          $(".open-ended-4` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[3] != 'undefined'){
                          if(res.data[3].font === "bold"){
                            $(".open-ended-4` + response.Trait + `").css("font-weight", "bold")
                          } else if(res.data[3].font === "italic") {
                            $(".open-ended-4` + response.Trait + `").css("font-style", "italic")
                          }
                          $(".open-ended-4` + response.Trait + `").html(res.data[3].` + response.Trait + `)
                        }

                        if(res.data.length < 5){
                          $(".open-ended-5` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[4] != 'undefined'){
                          if(res.data[4].font === "bold"){
                            $(".open-ended-5` + response.Trait + `").css("font-weight", "bold")
                          } else if(res.data[4].font === "italic") {
                            $(".open-ended-5` + response.Trait + `").css("font-style", "italic")
                          }
                          $(".open-ended-5` + response.Trait + `").html(res.data[4].` + response.Trait + `)
                        }
                        
                        if(res.data.length < 6){
                          $(".open-ended-6` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[5] != 'undefined'){
                          $(".open-ended-6` + response.Trait + `").html(res.data[5].` + response.Trait + `)
                        }

                        if(res.data.length < 7){
                          $(".open-ended-7` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[6] != 'undefined'){
                          $(".open-ended-7` + response.Trait + `").html(res.data[6].` + response.Trait + `)
                        }

                        if(res.data.length < 8){
                          $(".open-ended-8` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[7] != 'undefined'){
                          $(".open-ended-8` + response.Trait + `").html(res.data[7].` + response.Trait + `)
                        }
                            
                        if(res.data.length < 9){
                          $(".open-ended-9` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[8] != 'undefined'){
                          $(".open-ended-9` + response.Trait + `").html(res.data[8].` + response.Trait + `)
                        }

                        if(res.data.length < 10){
                          $(".open-ended-10` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[9] != 'undefined'){
                          $(".open-ended-10` + response.Trait + `").html(res.data[9].` + response.Trait + `)
                        }

                        if(res.data.length < 11){
                          $(".open-ended-11` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[10] != 'undefined'){
                          $(".open-ended-11` + response.Trait + `").html(res.data[10].` + response.Trait + `)
                        }

                        if(res.data.length < 12){
                          $(".open-ended-12` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[11] != 'undefined'){
                          $(".open-ended-12` + response.Trait + `").html(res.data[11].` + response.Trait + `)
                        }

                        if(res.data.length < 13){
                          $(".open-ended-13` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[12] != 'undefined'){
                          $(".open-ended-13` + response.Trait + `").html(res.data[12].` + response.Trait + `)
                        }

                        if(res.data.length < 14){
                          $(".open-ended-14` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[13] != 'undefined'){
                          $(".open-ended-14` + response.Trait + `").html(res.data[13].` + response.Trait + `)
                        }

                        if(res.data.length < 15){
                          $(".open-ended-15` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[14] != 'undefined'){
                          $(".open-ended-15` + response.Trait + `").html(res.data[14].` + response.Trait + `)
                        }
                        if(res.data.length < 16){
                          $(".open-ended-16` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[15] != 'undefined'){
                          $(".open-ended-16` + response.Trait + `").html(res.data[15].` + response.Trait + `)
                        }
                        if(res.data.length < 17){
                          $(".open-ended-17` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[16] != 'undefined'){
                          $(".open-ended-17` + response.Trait + `").html(res.data[16].` + response.Trait + `)
                        }
                        if(res.data.length < 18){
                          $(".open-ended-18` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[17] != 'undefined'){
                          $(".open-ended-18` + response.Trait + `").html(res.data[17].` + response.Trait + `)
                        }
                        if(res.data.length < 19){
                          $(".open-ended-19` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[18] != 'undefined'){
                          $(".open-ended-19` + response.Trait + `").html(res.data[18].` + response.Trait + `)
                        }
                        if(res.data.length < 20){
                          $(".open-ended-20` + response.Trait + `").css("display", "none")
                        }
                        if(typeof res.data[19] != 'undefined'){
                          $(".open-ended-20` + response.Trait + `").html(res.data[19].` + response.Trait + `)
                        }
                        
                        if(checkonlySpaces($(".open-ended-1` + response.Trait + `").html()) == true){
                          $(".open-ended-1` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-2` + response.Trait + `").html()) == true){
                          $(".open-ended-2` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-3` + response.Trait + `").html()) == true){
                          $(".open-ended-3` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-4` + response.Trait + `").html()) == true){
                          $(".open-ended-4` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-5` + response.Trait + `").html()) == true){
                          $(".open-ended-5` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-6` + response.Trait + `").html()) == true){
                          $(".open-ended-6` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-7` + response.Trait + `").html()) == true){
                          $(".open-ended-7` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-8` + response.Trait + `").html()) == true){
                          $(".open-ended-8` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-9` + response.Trait + `").html()) == true){
                          $(".open-ended-9` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-10` + response.Trait + `").html()) == true){
                          $(".open-ended-10` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-11` + response.Trait + `").html()) == true){
                          $(".open-ended-11` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-12` + response.Trait + `").html()) == true){
                          $(".open-ended-12` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-13` + response.Trait + `").html()) == true){
                          $(".open-ended-13` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-14` + response.Trait + `").html()) == true){
                          $(".open-ended-14` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-15` + response.Trait + `").html()) == true){
                          $(".open-ended-15` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-16` + response.Trait + `").html()) == true){
                          $(".open-ended-16` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-17` + response.Trait + `").html()) == true){
                          $(".open-ended-17` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-18` + response.Trait + `").html()) == true){
                          $(".open-ended-18` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-19` + response.Trait + `").html()) == true){
                          $(".open-ended-19` + response.Trait + `").html('No response provided')
                        }
                        if(checkonlySpaces($(".open-ended-20` + response.Trait + `").html()) == true){
                          $(".open-ended-20` + response.Trait + `").html('No response provided')
                        }
                      `
                    })
                  }
                }
              }
            output += `
            })
            .catch((err) => {
              console.log(err)
            })

            axios
            .post(
              '${url}/r360-report-data2',
              {
                "survey_assignment_id": survey_assignment_id,
                "org_id": org_id,
                "suborg_id": subOrg_id,
                "program_id": program_id,
                "iteration_id": iteration_id
              },
              config                                            
            )
            .then((res) => {
              console.log(res.data)

            //   if(res.data.length == 0) {
            //     // sometimes it will show if the iteration didn't run
            //     return Swal.fire({
            //       text: 'No records found!',
            //       icon: "error",
            //       showConfirmButton: false,
            //       allowOutsideClick: false
            //     })
            //   }
              
              const ordinal = (number) => {
                const ordinalRules = new Intl.PluralRules("en", {
                  type: "ordinal"
                });
                const suffixes = {
                  one: "st",
                  two: "nd",
                  few: "rd",
                  other: "th"
                };
                const suffix = suffixes[ordinalRules.select(number)];
                return (number + suffix);
              }

              function arrayRemoveZero(arr, value) { 
                return arr.filter(function(ele){ 
                    return ele != value; 
                });
              }
                function checkGroups(A, B, C, D) {
                  const group1 = [A, B];
                  const group2 = [C, D];

                  const group1HasZeroAndOne = group1.includes(0) && group1.includes(1);
                  const group2HasZeroAndOne = group2.includes(0) && group2.includes(1);
                  const group1HasTwoZeros = group1.every(val => val === 0);
                  const group2HasTwoZeros = group2.every(val => val === 0);

                  return group1HasZeroAndOne || group2HasZeroAndOne || (group1HasTwoZeros && group2HasTwoZeros);
                }
              `

              for (let i = 0; i < input.length; i++) {
                const data = input[i];
                console.log('data ito', data)
                // section 2 with Competency Importance Index and Organizational Climate Snapshot
                if (data.Template === 'Bar-Chart-New-360-Head') {
                  if (data.Template === 'Bar-Chart-New-360-Head') { 
                    output += `
                      let avgCount = 1
                      let avgCohortCount = 1
                      let isDisplay_P = 0
                      let isDisplay_D = 0
                      let isDisplay_PD = 0
                      let isDisplay_I = 0
                      let isDisplay_E = 0
                      let isDisplay_IE = 0
                      let isDisplay_PDIE = 0

                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_PD = 1
                      } else {
                        isDisplay_P = 1
                        isDisplay_D = 1
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                      }
                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_IE = 1
                      } else {
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                        isDisplay_I = 1
                        isDisplay_E = 1
                      }
                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          avgCount = 2
                          avgCohortCount = 2
                          isDisplay_PDIE = 1
                        }
                      `
                    let obj1 = JSON.parse(data.Text)
                    for (const res of obj1) {
                      res.Competencies.forEach((response) => {
                        output += `

                          let avgHeadRollup` + response.Acronym + ` = 0
                          let cohortHeadavgRollup` + response.Acronym + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                          $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                          $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                            $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                            $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                          }
                        `
                      })
                    }
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                          let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                          let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                          let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                          let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                          // MAIN 

                          let avgHeadRollup` + response.Trait + ` = 0
                          let cohortHeadavgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                          $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                          $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                            $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                            $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }
                          
                          // PRIMARY SUPERVISOR
                          $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct report
                          $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder|External stakeholders
                          $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Self
                          $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                          $('.front-page-for-individual-report').removeClass('d-none')
                          $('.front-page-psup-value').text(res.data[0].psup1_N)
                          $('.front-page-P-value').text(res.data[0].peer2_N)
                          $('.front-page-D-value').text(res.data[0].direct3_N)
                          $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                          $('.front-page-I-value').text(res.data[0].internal4_N)
                          $('.front-page-E-value').text(res.data[0].external5_N)
                          $('.front-page-IE-value').text(res.data[0].IE997_N)
                          $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)

                          if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          
                          if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }

                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                            $(".front-page-PDIE").css("display", "none")
                          }
                        `
                      })
                    }
                    output += `
                      $('.360_head').removeClass('d-none')
                      $('.360_head_spinner').addClass('d-none')
                    `
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head') { 
                          let obj = JSON.parse(data.Text)
                          for (const res of obj) {
                            res.Elements.forEach((response) => {
                              output += `
      
                                // PRIMARY SUPERVISOR
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Peers
                                $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // Direct report
                                $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Peers|Direct reports
                                $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Internal stakeholder
                                $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                // External stakeholders
                                $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                //Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                // Peers|Direct reports|Internal stakeholders|External stakeholders
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                let avgRollup` + response.Trait + ` = 0
                                let cohortavgRollup` + response.Trait + ` = 0
                                let cohortSelfAvgRollup` + response.Trait + ` = 0
    
                                if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                                  }
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
    
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
    
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                  } 
                                else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  
                                  
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PD996_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_IE997_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                                  }

                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
    
                                if (isDisplay_PDIE === 1) {
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                } 
                                else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                  let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                  let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
    
                                  let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                                  let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
    
                                                                  
                                  let zeroCount` + response.Trait + ` = 0
                                  if (roundOff_psup1_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_internal4_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_external5_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_direct3_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_peer2_` + response.Trait + ` === 0) {
                                    zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                                  }

                                  let zeroCohortCount` + response.Trait + ` = 0

                                  if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }
                                  if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                                    zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                                  }

                                  if (avgCount === zeroCount` + response.Trait + `) {
                                    avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                                    cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                                  } else {
                                    cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                                  }
                                  
                                  
                                  // Total
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
        
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
        
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                                  $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                                }
                                
      
                                // Self
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
      
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})
      
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                                $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                              
                                if(res.data[0].peer2_N === null){
                                res.data[0].peer2_N = 0
                              }
                              if(res.data[0].direct3_N === null){
                                res.data[0].direct3_N = 0
                              }
                              if(res.data[0].PD996_N === null){
                                res.data[0].PD996_N = 0
                              }
                              if(res.data[0].internal4_N === null){
                                res.data[0].internal4_N = 0
                              }
                              if(res.data[0].external5_N === null){
                                res.data[0].external5_N = 0
                              }
                              if(res.data[0].IE997_N === null){
                                res.data[0].IE997_N = 0
                              }
                              if(res.data[0].PDIE998_N === null){
                                res.data[0].PDIE998_N = 0
                              }
                              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                }
    
                              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                }

                                if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                }
                                if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                }
    
                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                                  $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                                  $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                                } else {
                                  $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                                }

                                
                                if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                               
                              `
                              
                            })
                          }
                          output += `
                            $('.360_sub').removeClass('d-none')
                            $('.360_sub_spinner').addClass('d-none')
                          `
                  }    
                  // slider section 2
                  if (data.Template === 'Bar-Chart-New-360-Head') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Competencies.forEach((resCompetency) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].all_nom999_`+resCompetency.Acronym+`).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));                                

                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        } else {
                          
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(avgHeadRollup` + resCompetency.Acronym + `).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));         
                                                 
                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        }
                        `
                      })
                    }     
                  }
                  // Top-Behaviour section 2
                  if(data.Template === 'Bar-Chart-New-360-Head'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                          // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top_behaviours_` + response.Trait + `").text( ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 )
                            let avgOthersInd_` + response.Trait + ` = ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 
                            $(".total_avg_score` + response.Trait + `").text( parseFloat( avgOthersInd_` + response.Trait + ` ).toFixed(1) )
                            $(".isSmartCollab").removeClass("d-none")
                          }
                          else {
                            $(".top_behaviours_` + response.Trait + `").text(avgRollup` + response.Trait + `)
                          }
                        `
                      })
                      output += `
                        $('.top-behaviours-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.top-behaviours-wrapper'));

                        const topBehavioursStartLength = 3
                        const topBehavioursMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-behaviours-sortme').slice(topBehavioursStartLength, topBehavioursMaxLength).hide();
                        $('.top-behaviours-average-score').hide()
                      `
                    }
                  }
                  // for Undiscovered-Strengths section 2
                  if(data.Template === 'Bar-Chart-New-360-Head'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-undiscovered-strength-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-undiscovered-strength-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                              console.log('negative')
                            }else{
                              if( (Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1)) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                                $(".top-undiscovered-strength_` + response.Trait + `").html( (Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1)) - (res.data[0].ind_` + response.Trait + `) )
                                $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                                console.log('remove d-none undiscovered-strength')
                              }
                            }

                          }else {
                            $(".top-undiscovered-strength_` + response.Trait + `").html( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) )
                            if( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                              $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                              console.log('remove d-none undiscovered-strength')
                            }
                          }
                        `
                      })

                      output +=`
                        const isUndiscoveredStrengthAllHidden = $(".undiscovered-strength-wrapper .uslistZeroItem").not(".d-none").length === 0;

                        if (isUndiscoveredStrengthAllHidden) {
                          $('.undiscovered-strength-all-rows').addClass('d-none')
                          $('.undiscovered-strength-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                        $('.top-undiscovered-strength-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.undiscovered-strength-wrapper'));

                        const topUndiscoveredStrengthStartLength = 3
                        const topUndiscoveredStrengthMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-undiscovered-strength-sortme').slice(topUndiscoveredStrengthStartLength, topUndiscoveredStrengthMaxLength).hide();
                        $('.top-undiscovered-strength-average-score').hide()
                      `
                    }
                  }
                  // for Potential-Blind-Spots section 2
                  if(data.Template === 'Bar-Chart-New-360-Head'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-potential-blindspot-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-potential-blindspot-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                                console.log('negative')
                              }else{
                                if( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) >= 2 ){
                                  $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) )
                                  $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                                }
                              }

                          }else {
                            $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) )
                            if( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) >= 2 ){
                                $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                              }
                          }
                        `
                      })

                      output +=`
                        const isPotentialBlindSpotsAllHidden = $(".potential-blindspot-wrapper .pblistZeroItem").not(".d-none").length === 0;

                        console.log('this is potential blind spot',isPotentialBlindSpotsAllHidden)
                        if (isPotentialBlindSpotsAllHidden) {
                          $('.potential-blindspot-all-rows').addClass('d-none')
                          $('.potential-blindspot-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                      $('.top-potential-blindspot-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.potential-blindspot-wrapper'));

                        const potentialBlindSpotStartLength = 3
                        const potentialBlindSpotMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-potential-blindspot-sortme').slice(potentialBlindSpotStartLength, potentialBlindSpotMaxLength).hide();
                        $('.top-potential-blindspot-average-score').hide()
                      `
                    }
                  }
                  // for Organizational Climate Snaphot section 2
                  if (data.Template === 'Bar-Chart-New-360-Head') { 
                    let obj = JSON.parse(data.Text)
                    
                    for (const res of obj) {
                      console.log("Obj:",res)
                      output += `
                          let ` + res.ocsSuperTrait + `chart = document.querySelectorAll('.` + res.ocsSuperTrait + `')
                          new Chart(`+ res.ocsSuperTrait +`chart,{
                            type: "line",
                            data:{
                              labels: [
                                  ['ER','Emotional', 'Reactivity'],
                                  ['E','Extraversion'],
                                  ['O','Openness', 'to Chage'],
                                  ['A','Agreeableness'], 
                                  ['C','Conscientiousness']
                              ],
                              datasets:[{
                                  label: "S",
                                  data: [
                                    res.data[0].ind_ERX ? res.data[0].ind_ERX : 0, 
                                    res.data[0].ind_EX ? res.data[0].ind_EX : 0, 
                                    res.data[0].ind_OX ? res.data[0].ind_OX : 0, 
                                    res.data[0].ind_AX ? res.data[0].ind_AX : 0, 
                                    res.data[0].ind_CX ? res.data[0].ind_CX : 0
                                  ],
                                  borderColor: '`+ res.Colors.color_s +`'
                                },
                                {
                                  label: "O",
                                  data: [
                                    res.data[0].all_nom999_ERX ? res.data[0].all_nom999_ERX : 0,
                                    res.data[0].all_nom999_EX ? res.data[0].all_nom999_EX : 0,
                                    res.data[0].all_nom999_OX ? res.data[0].all_nom999_OX : 0,
                                    res.data[0].all_nom999_AX ? res.data[0].all_nom999_AX : 0,
                                    res.data[0].all_nom999_CX ? res.data[0].all_nom999_CX : 0
                                  ],
                                  borderColor: '`+ res.Colors.color_o +`'
                                }]
                            },
                            options: {
                              scales: {
                                xAxis: {position: 'top'},
                                y: {
                                  beginAtZero: true,
                                  min: 0,
                                  max: 5,
                                  ticks: {
                                    stepSize: 1,
                                  }
                                }
                              },
                              plugins: {
                                legend: {
                                  position: "left"
                                }
                              },
                              responsive: true,
                              maintainAspectRatio: false
                            }
                          })
                        ` 
                    }
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head') { 
                    let obj = JSON.parse(data.Text)
                    output += `
                    if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $('.heading-label-CII-peers').addClass('d-none')
                            $('.sub-heading-score-CII-peers').addClass('d-none')
                            $('.heading-label-CII-direct').addClass('d-none')
                            $('.sub-heading-score-CII-direct').addClass('d-none')
                          } else {
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $('.heading-label-CII-internal').addClass('d-none')
                            $('.sub-heading-score-CII-internal').addClass('d-none')
                            $('.heading-label-CII-external').addClass('d-none')
                            $('.sub-heading-score-CII-external').addClass('d-none')
                          } else {
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          }

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          }

                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $('.heading-label-CII-peers').addClass('d-none')
                            $('.sub-heading-score-CII-peers').addClass('d-none')
                            $('.heading-label-CII-direct').addClass('d-none')
                            $('.sub-heading-score-CII-direct').addClass('d-none')
                            $('.heading-label-CII-internal').addClass('d-none')
                            $('.sub-heading-score-CII-internal').addClass('d-none')
                            $('.heading-label-CII-external').addClass('d-none')
                            $('.sub-heading-score-CII-external').addClass('d-none')
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          } else {
                            $('.heading-label-CII-pdie').addClass('d-none')
                            $('.sub-heading-score-CII-pdie').addClass('d-none')
                          } 

                    function processOrdinalLevelsHead(dataArray) {
                        // Check if the array contains only null values
                        if (dataArray.every((value) => value === null)) {
                          return Array(dataArray.length).fill('N/A');
                        }

                        // Count the occurrences of each number in the array
                        const numberCounts = {};
                        for (const num of dataArray) {
                          if (num in numberCounts) {
                            numberCounts[num]++;
                          } else {
                            numberCounts[num] = 1;
                          }
                        }

                        // Create a sorted array of unique numbers in ascending order
                        const uniqueSortedNumbers = Array.from(new Set(dataArray)).sort((a, b) => a - b);

                        // Create a mapping of numbers to their levels based on their order
                        const numberToLevel = {};
                        let currentLevel = 1;

                        for (const num of uniqueSortedNumbers) {
                          numberToLevel[num] = currentLevel;
                          currentLevel += numberCounts[num];
                        }

                        // Function to add the appropriate ordinal suffix or replace with "N/A" for null values
                        function addOrdinalSuffix(num) {
                          if (num === null) return 'N/A'; // Replace null with "N/A"
                          if (num === 1) return '1st';
                          if (num === 2) return '2nd';
                          if (num === 3) return '3rd';
                          return num + 'th';
                        }

                        // Assign levels with correct ordinal suffix or "N/A" for null values to the original array
                        const levelsAsOrdinals = dataArray.map((num) => addOrdinalSuffix(numberToLevel[num]));

                        // Count the occurrences of each ordinal level
                        const levelCounts = {};

                        for (const level of levelsAsOrdinals) {
                          if (levelCounts[level]) {
                            levelCounts[level]++;
                          } else {
                            levelCounts[level] = 1;
                          }
                        }

                        // Replace all "1st" with "=1st" if there are more than one "1st"
                        const firstCount = levelCounts['1st'];
                        if (firstCount > 1) {
                          for (let j = 0; j < levelsAsOrdinals.length; j++) {
                            if (levelsAsOrdinals[j] === '1st') {
                              levelsAsOrdinals[j] = '=1st';
                            }
                          }
                        }

                        // Replace ordinal levels if they occur more than once up to 20th
                        for (let i = 1; i <= 20; i++) {
                          const ordinal = i + 'th';
                          if (levelCounts[ordinal] > 1) {
                            for (let j = 0; j < levelsAsOrdinals.length; j++) {
                              if (levelsAsOrdinals[j] === ordinal) {
                                levelsAsOrdinals[j] = '=' + ordinal;
                              }
                            }
                          }
                        }

                        return levelsAsOrdinals;
                      }

                      function convertArrayToObjects(inputArray) {
                        if (!Array.isArray(inputArray)) {
                          return "Input is not an array.";
                        }

                        const result = [];

                        for (let i = 0; i < inputArray.length; i++) {
                          const code = "CII" + (i + 1);
                          const num = inputArray[i];

                          const obj = {
                            code: code,
                            num: num
                          };

                          result.push(obj);
                        }

                        return result;
                      }

                      function rankObjects(arrayOfObjects) {
                        if (!Array.isArray(arrayOfObjects)) {
                          return "Input is not an array.";
                        }

                        // Sort the objects by the "num" property in ascending order
                        const sortedObjects = arrayOfObjects.slice().sort((a, b) => a.num - b.num);

                        // Get the top 6 objects or all objects if there are fewer than 6
                        const limitedRanking = sortedObjects.slice(0, Math.min(6, sortedObjects.length));

                        return limitedRanking;
                      }

                      let indHeadArray = [];
                      let allnomHeadArray = [];
                      let competencyName = [];

                    `
                    console.log('d2d2', obj)
                    for (const res of obj) {
                      res.ciiCompetencies.forEach((response) => {
                        output += 
                        `
                        indHeadArray.push(res.data[0].ind_` + response.Trait + `)
                        allnomHeadArray.push(res.data[0].all_nom999_` + response.Trait + `)
                        competencyName.push("` + response.Description + `")

                          if(res.data[0].ind_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                              $(".competency-ind_` + response.Trait + `").html("N/A")
                              $(".sub-heading-text-CII_new_`+response.Trait+`_s-new").html("N/A")
                          }
                          else{
                              $(".competency-ind_` + response.Trait + `").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                              $(".sub-heading-text-CII_new_`+response.Trait+`_s_new").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                          }

                          if(res.data[0].all_nom999_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                            $(".competency-all_nom999_` + response.Trait + `").html("N/A")
                          }
                          else{
                            $(".competency-all_nom999_` + response.Trait + `").html(ordinal(Math.round(res.data[0].all_nom999_` + response.Trait + `)))
                          }
                        `
                    })
                    output += `
                      let newIndHeadArr = processOrdinalLevelsHead(indHeadArray)
                      let newAllNomHeadArr = processOrdinalLevelsHead(allnomHeadArray)
                      let newAllNomHeadArrtoObject = convertArrayToObjects(newAllNomHeadArr)
                      
                      function generateOutput(array1, array2, array3, competencyArr) {
                        const output = [];

                        for (let i = 0; i < array1.length; i++) {
                          const trait = competencyArr[i];
                          const self = array1[i];
                          const ranked = array3[i];
                          const others = array2[i];

                          output.push({ Trait: trait, Self: self, Ranked: ranked, Others: others });
                        }

                        return output;
                      }

                      const result = generateOutput(newIndHeadArr, newAllNomHeadArr, allnomHeadArray, competencyName);
                      let data = result

                      const traitRow = document.getElementById('trait-row');
                      const selfRow = document.getElementById('self-row');
                      const othersRow = document.getElementById('others-row');
                      const sortButton = document.getElementById('sort-button');
                      let sortAscending = true;

                      // Function to populate the table
                      const populateTable = (dataToShow) => {
                          // Clear the table rows
                          traitRow.innerHTML = '';
                          selfRow.innerHTML = '';
                          othersRow.innerHTML = '';
                        //generate blank th
                        const blankCell = document.createElement('th');
                        traitRow.appendChild(blankCell);
                          // Populate headers
                          dataToShow.forEach(item => {
                            const traitCell = document.createElement('th');

                            // Add a class to the th element
                            traitCell.classList.add('heading-label-CII');
                            traitCell.style.backgroundColor = '#f2f2f2';
                            // Create the div element with the specified styles
                            const traitDiv = document.createElement('div');
                            traitDiv.textContent = item.Trait;
                            traitDiv.style.display = 'flex';
                            traitDiv.style.alignItems = 'center';
                            traitDiv.style.fontSize = '12px';
                            traitDiv.style.textAlign = 'center';

                            // Append the div element to the traitCell
                            traitCell.appendChild(traitDiv);

                            traitRow.appendChild(traitCell);
                          });

                        //generate Self row static
                        const blankRowSelf = document.createElement('td');
                        blankRowSelf.style.border = '1px solid #e0e0e0'
                        blankRowSelf.style.padding = '5px';
                        blankRowSelf.textContent = 'S';
                        blankRowSelf.style.color = 'rgb(184, 113, 26)'
                        blankRowSelf.style['border-right']  = '1px solid #e0e0e0';
                        selfRow.appendChild(blankRowSelf);

                        //generate Self row static
                        const blankRowOthers = document.createElement('td');
                        blankRowOthers.style.border = '1px solid #e0e0e0'
                        blankRowOthers.style.padding = '5px';
                        blankRowOthers.textContent = 'O';
                        blankRowOthers.style['border-right']  = '1px solid #e0e0e0';
                        blankRowOthers.style['border-top']  = '1px solid #e0e0e0';
                        othersRow.appendChild(blankRowOthers);

                          // Populate "Self" and "Others" rows
                          dataToShow.forEach(item => {
                            const selfCell = document.createElement('td');
                            const othersCell = document.createElement('td');
                            selfCell.style.border = '1px solid #e0e0e0'
                            othersCell.style.border = '1px solid #e0e0e0'

                            // Create the div element with the specified styles
                            const traitDivSelf = document.createElement('div');
                            traitDivSelf.textContent = item.Self;
                            traitDivSelf.style.alignItems = 'center';
                            traitDivSelf.style.fontSize = '12px';
                            traitDivSelf.style.textAlign = 'center';
                            const traitDivOthers = document.createElement('div');
                            traitDivOthers.textContent = item.Others;
                            traitDivOthers.style.alignItems = 'center';
                            traitDivOthers.style.fontSize = '12px';
                            traitDivOthers.style.textAlign = 'center';

                            // Append the div element to the traitCell
                            selfCell.appendChild(traitDivSelf);
                            othersCell.appendChild(traitDivOthers);

                            selfRow.appendChild(selfCell);
                            othersRow.appendChild(othersCell);
                          });
                      };

                      // Function to sort the data by "Ranked" column
                      const sortTableByRanked = () => {
                          const order = sortAscending ? 1 : -1;
                          data.sort((a, b) => order * (a.Ranked - b.Ranked));
                          sortAscending = !sortAscending;
                          populateTable(data.slice(0, 6)); // Display the first 6 rows visually
                      };

                      // Populate the table initially with the first 6 rows
                      populateTable(data.slice(0, 6));

                      // Sort the table upon load
                      sortTableByRanked()

                      `
                    for (const res1 of obj) {
                      res1.ciiCompetencies.forEach((response, index) => {
                          output += 
                          `
                          $(".sub-heading-text-CII_new_`+response.Trait+`_s-new").html(newIndHeadArr[${index}])
                          $(".competency-all_nom999_` + response.Trait + `").html(newAllNomHeadArr[${index}])
                          `
                      })
                    }
                    }
                  }
                }
                // section 2 without Competency Importance Index and Organizational Climate Snapshot
                if (data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS') {
                  if (data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS') { 
                    output += `
                      let avgCount = 1
                      let avgCohortCount = 1
                      let isDisplay_P = 0
                      let isDisplay_D = 0
                      let isDisplay_PD = 0
                      let isDisplay_I = 0
                      let isDisplay_E = 0
                      let isDisplay_IE = 0
                      let isDisplay_PDIE = 0

                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_PD = 1
                      } else {
                        isDisplay_P = 1
                        isDisplay_D = 1
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                      }
                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_IE = 1
                      } else {
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                        isDisplay_I = 1
                        isDisplay_E = 1
                      }
                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          avgCount = 2
                          avgCohortCount = 2
                          isDisplay_PDIE = 1
                        }
                      `

                    let obj1 = JSON.parse(data.Text)
                    console.log('obj1 without cii and ocs', obj1)
                    for (const res of obj1) {
                      res.Competencies.forEach((response) => {
                        output += `

                          let avgHeadRollup` + response.Acronym + ` = 0
                          let cohortHeadavgRollup` + response.Acronym + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                          $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                          $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                            $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                            $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                          }
                        `
                      })
                    }

                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                          let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                          let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                          let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                          let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                          // MAIN 

                          let avgHeadRollup` + response.Trait + ` = 0
                          let cohortHeadavgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                          $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                          $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                            $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                            $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }
                          
                          // PRIMARY SUPERVISOR
                          $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct report
                          $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder|External stakeholders
                          $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Self
                          $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                          $('.front-page-for-individual-report').removeClass('d-none')
                          $('.front-page-psup-value').text(res.data[0].psup1_N)
                          $('.front-page-P-value').text(res.data[0].peer2_N)
                          $('.front-page-D-value').text(res.data[0].direct3_N)
                          $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                          $('.front-page-I-value').text(res.data[0].internal4_N)
                          $('.front-page-E-value').text(res.data[0].external5_N)
                          $('.front-page-IE-value').text(res.data[0].IE997_N)
                          $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)

                          if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          
                          if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }
                          
                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                            $(".front-page-PDIE").css("display", "none")
                          }
                        `
                      })
                    }
                    output += `
                      $('.360_head').removeClass('d-none')
                      $('.360_head_spinner').addClass('d-none')
                    `
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          // PRIMARY SUPERVISOR
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Peers|Direct reports
                          $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          let avgRollup` + response.Trait + ` = 0
                          let cohortavgRollup` + response.Trait + ` = 0
                          let cohortSelfAvgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                            } 
                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }

                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          if (isDisplay_PDIE === 1) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                          else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                            
                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }
                          

                          // Self
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                          
                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }
                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }
                        if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                          }

                          
                          if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                        `
                      })
                    }
                    output += `
                      $('.360_sub').removeClass('d-none')
                      $('.360_sub_spinner').addClass('d-none')
                    `
                  }
                  // slider section 2
                  if (data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Competencies.forEach((resCompetency) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].all_nom999_`+resCompetency.Acronym+`).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));                                

                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        } else {
                          
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(avgHeadRollup` + resCompetency.Acronym + `).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));         
                                                 
                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        }`
                      })
                    }
                  }
                  // Top-Behaviours section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                          // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top_behaviours_` + response.Trait + `").text( ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 )
                            let avgOthersInd_` + response.Trait + ` = ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 
                            $(".total_avg_score` + response.Trait + `").text( parseFloat( avgOthersInd_` + response.Trait + ` ).toFixed(1) )
                            $(".isSmartCollab").removeClass("d-none")
                          }
                          else {
                            $(".top_behaviours_` + response.Trait + `").text(avgRollup` + response.Trait + `)
                          }
                        `
                      })
                      output += `
                        $('.top-behaviours-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.top-behaviours-wrapper'));

                        const topBehavioursStartLength = 3
                        const topBehavioursMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-behaviours-sortme').slice(topBehavioursStartLength, topBehavioursMaxLength).hide();
                        $('.top-behaviours-average-score').hide()
                      `
                    }
                  }
                  // for Undiscovered-Strengths section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-undiscovered-strength-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-undiscovered-strength-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                              console.log('negative')
                            }else{
                              if( (Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1)) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                                $(".top-undiscovered-strength_` + response.Trait + `").html( (Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1)) - (res.data[0].ind_` + response.Trait + `) )
                                $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                                console.log('remove d-none undiscovered-strength')
                              }
                            }
                          }else {
                            $(".top-undiscovered-strength_` + response.Trait + `").html( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) )
                            if( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                              $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                              console.log('remove d-none undiscovered-strength')
                            }
                          }
                        `
                      })

                      output +=`
                        const isUndiscoveredStrengthAllHidden = $(".undiscovered-strength-wrapper .uslistZeroItem").not(".d-none").length === 0;

                        if (isUndiscoveredStrengthAllHidden) {
                          $('.undiscovered-strength-all-rows').addClass('d-none')
                          $('.undiscovered-strength-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                        $('.top-undiscovered-strength-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.undiscovered-strength-wrapper'));

                        const topUndiscoveredStrengthStartLength = 3
                        const topUndiscoveredStrengthMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-undiscovered-strength-sortme').slice(topUndiscoveredStrengthStartLength, topUndiscoveredStrengthMaxLength).hide();
                        $('.top-undiscovered-strength-average-score').hide()
                      `
                    }
                  }
                  // for Potential-Blind-Spots section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-Without-CII-And-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-potential-blindspot-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-potential-blindspot-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                                console.log('negative')
                              }else{
                                if( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) >= 2 ){
                                  $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) )
                                  $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                                }
                              }
                          }else {
                            $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) )
                            if( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) >= 2 ){
                                $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                              }
                          }
                        `
                      })

                      output +=`
                        const isPotentialBlindSpotsAllHidden = $(".potential-blindspot-wrapper .pblistZeroItem").not(".d-none").length === 0;

                        console.log('this is potential blind spot',isPotentialBlindSpotsAllHidden)
                        console.log('this is potential blind spot length', $(".potential-blindspot-wrapper .pblistZeroItem").not(".d-none").length)

                        if (isPotentialBlindSpotsAllHidden) {
                          $('.potential-blindspot-all-rows').addClass('d-none')
                          $('.potential-blindspot-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                      $('.top-potential-blindspot-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.potential-blindspot-wrapper'));

                        const potentialBlindSpotStartLength = 3
                        const potentialBlindSpotMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-potential-blindspot-sortme').slice(potentialBlindSpotStartLength, potentialBlindSpotMaxLength).hide();
                        $('.top-potential-blindspot-average-score').hide()
                      `
                    }
                  }
                }
                // section 2 with Competency Importance Index and `NO` Organizational Climate Snapshot
                if (data.Template === 'Bar-Chart-New-360-Head-CII') {
                  if (data.Template === 'Bar-Chart-New-360-Head-CII') { 
                    output += `
                      let avgCount = 1
                      let avgCohortCount = 1
                      let isDisplay_P = 0
                      let isDisplay_D = 0
                      let isDisplay_PD = 0
                      let isDisplay_I = 0
                      let isDisplay_E = 0
                      let isDisplay_IE = 0
                      let isDisplay_PDIE = 0

                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_PD = 1
                      } else {
                        isDisplay_P = 1
                        isDisplay_D = 1
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                      }
                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_IE = 1
                      } else {
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                        isDisplay_I = 1
                        isDisplay_E = 1
                      }
                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          avgCount = 2
                          avgCohortCount = 2
                          isDisplay_PDIE = 1
                        }
                      `
                    let obj1 = JSON.parse(data.Text)
                    console.log('obj1 Head-CII', obj1)
                    for (const res of obj1) {
                      res.Competencies.forEach((response) => {
                        output += `

                          let avgHeadRollup` + response.Acronym + ` = 0
                          let cohortHeadavgRollup` + response.Acronym + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                          $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                          $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                            $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                            $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                          }
                        `
                      })
                    }

                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                          let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                          let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                          let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                          let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                          // MAIN 

                          let avgHeadRollup` + response.Trait + ` = 0
                          let cohortHeadavgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                          $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                          $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                            $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                            $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }
                          
                          // PRIMARY SUPERVISOR
                          $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct report
                          $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder|External stakeholders
                          $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Self
                          $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                          $('.front-page-for-individual-report').removeClass('d-none')
                          $('.front-page-psup-value').text(res.data[0].psup1_N)
                          $('.front-page-P-value').text(res.data[0].peer2_N)
                          $('.front-page-D-value').text(res.data[0].direct3_N)
                          $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                          $('.front-page-I-value').text(res.data[0].internal4_N)
                          $('.front-page-E-value').text(res.data[0].external5_N)
                          $('.front-page-IE-value').text(res.data[0].IE997_N)
                          $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)

                          if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          
                          if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }
                          
                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                            $(".front-page-PDIE").css("display", "none")
                          }
                        `
                      })
                    }
                    output += `
                      $('.360_head').removeClass('d-none')
                      $('.360_head_spinner').addClass('d-none')
                    `
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head-CII') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          // PRIMARY SUPERVISOR
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Peers|Direct reports
                          $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          let avgRollup` + response.Trait + ` = 0
                          let cohortavgRollup` + response.Trait + ` = 0
                          let cohortSelfAvgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                            } 
                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }

                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          if (isDisplay_PDIE === 1) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                          else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                            
                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }
                          

                          // Self
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                          
                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }
                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }
                        if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                          }

                          
                          if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                        `
                      })
                    }
                    output += `
                      $('.360_sub').removeClass('d-none')
                      $('.360_sub_spinner').addClass('d-none')
                    `
                  }
                  // slider section 2
                  if (data.Template === 'Bar-Chart-New-360-Head-CII') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Competencies.forEach((resCompetency) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].all_nom999_`+resCompetency.Acronym+`).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));                                

                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        } else {
                          
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(avgHeadRollup` + resCompetency.Acronym + `).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));         
                                                 
                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        }`
                      })
                    }
                  }
                  // Top-Behaviours section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-CII'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                          // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top_behaviours_` + response.Trait + `").text( ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 )
                            let avgOthersInd_` + response.Trait + ` = ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 
                            $(".total_avg_score` + response.Trait + `").text( parseFloat( avgOthersInd_` + response.Trait + ` ).toFixed(1) )
                            $(".isSmartCollab").removeClass("d-none")
                          }
                          else {
                            $(".top_behaviours_` + response.Trait + `").text(avgRollup` + response.Trait + `)
                          }
                        `
                      })
                      output += `
                        $('.top-behaviours-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.top-behaviours-wrapper'));

                        const topBehavioursStartLength = 3
                        const topBehavioursMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-behaviours-sortme').slice(topBehavioursStartLength, topBehavioursMaxLength).hide();
                        $('.top-behaviours-average-score').hide()
                      `
                    }
                  }
                  // for Undiscovered-Strengths section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-CII'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-undiscovered-strength-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-undiscovered-strength-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                            
                            if(res.data[0].ind_` + response.Trait + ` == 0){
                              console.log('negative')
                            }else{
                              if( Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                                $(".top-undiscovered-strength_` + response.Trait + `").html( Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) - (res.data[0].ind_` + response.Trait + `) )
                                $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                                console.log('remove d-none undiscovered-strength')
                              }
                            }

                          }else {
                            $(".top-undiscovered-strength_` + response.Trait + `").html( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) )
                            if( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                              $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                              console.log('remove d-none undiscovered-strength')
                            }
                          }
                        `
                      })

                      output +=`
                        const isUndiscoveredStrengthAllHidden = $(".undiscovered-strength-wrapper .uslistZeroItem").not(".d-none").length === 0;

                        if (isUndiscoveredStrengthAllHidden) {
                          $('.undiscovered-strength-all-rows').addClass('d-none')
                          $('.undiscovered-strength-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                        $('.top-undiscovered-strength-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.undiscovered-strength-wrapper'));

                        const topUndiscoveredStrengthStartLength = 3
                        const topUndiscoveredStrengthMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-undiscovered-strength-sortme').slice(topUndiscoveredStrengthStartLength, topUndiscoveredStrengthMaxLength).hide();
                        $('.top-undiscovered-strength-average-score').hide()
                      `
                    }
                  }
                  // for Potential-Blind-Spots section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-CII'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-potential-blindspot-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-potential-blindspot-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                                console.log('negative')
                              }else{
                                if( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) >= 2 ){
                                  $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) )
                                  $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                                }
                              }
                          }else {
                            $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) )
                            if( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) >= 2 ){
                                $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                              }
                          }
                        `
                      })

                      output +=`
                        const isPotentialBlindSpotsAllHidden = $(".potential-blindspot-wrapper .pblistZeroItem").not(".d-none").length === 0;

                        console.log('this is potential blind spot',isPotentialBlindSpotsAllHidden)

                        if (isPotentialBlindSpotsAllHidden) {
                          $('.potential-blindspot-all-rows').addClass('d-none')
                          $('.potential-blindspot-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                      $('.top-potential-blindspot-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.potential-blindspot-wrapper'));

                        const potentialBlindSpotStartLength = 3
                        const potentialBlindSpotMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-potential-blindspot-sortme').slice(potentialBlindSpotStartLength, potentialBlindSpotMaxLength).hide();
                        $('.top-potential-blindspot-average-score').hide()
                      `
                    }
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head-CII') { 
                    let obj = JSON.parse(data.Text)
                    console.log('obj ng cii',obj)
                    output += `
                    if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $('.heading-label-CII-peers').addClass('d-none')
                            $('.sub-heading-score-CII-peers').addClass('d-none')
                            $('.heading-label-CII-direct').addClass('d-none')
                            $('.sub-heading-score-CII-direct').addClass('d-none')
                          } else {
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $('.heading-label-CII-internal').addClass('d-none')
                            $('.sub-heading-score-CII-internal').addClass('d-none')
                            $('.heading-label-CII-external').addClass('d-none')
                            $('.sub-heading-score-CII-external').addClass('d-none')
                          } else {
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          }

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          }

                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $('.heading-label-CII-peers').addClass('d-none')
                            $('.sub-heading-score-CII-peers').addClass('d-none')
                            $('.heading-label-CII-direct').addClass('d-none')
                            $('.sub-heading-score-CII-direct').addClass('d-none')
                            $('.heading-label-CII-internal').addClass('d-none')
                            $('.sub-heading-score-CII-internal').addClass('d-none')
                            $('.heading-label-CII-external').addClass('d-none')
                            $('.sub-heading-score-CII-external').addClass('d-none')
                            $('.heading-label-CII-pd').addClass('d-none')
                            $('.sub-heading-score-CII-pd').addClass('d-none')
                            $('.heading-label-CII-ie').addClass('d-none')
                            $('.sub-heading-score-CII-ie').addClass('d-none')
                          } else {
                            $('.heading-label-CII-pdie').addClass('d-none')
                            $('.sub-heading-score-CII-pdie').addClass('d-none')
                          } 

                    function processOrdinalLevelsHead(dataArray) {
                        // Check if the array contains only null values
                        if (dataArray.every((value) => value === null)) {
                          return Array(dataArray.length).fill('N/A');
                        }

                        // Count the occurrences of each number in the array
                        const numberCounts = {};
                        for (const num of dataArray) {
                          if (num in numberCounts) {
                            numberCounts[num]++;
                          } else {
                            numberCounts[num] = 1;
                          }
                        }

                        // Create a sorted array of unique numbers in ascending order
                        const uniqueSortedNumbers = Array.from(new Set(dataArray)).sort((a, b) => a - b);

                        // Create a mapping of numbers to their levels based on their order
                        const numberToLevel = {};
                        let currentLevel = 1;

                        for (const num of uniqueSortedNumbers) {
                          numberToLevel[num] = currentLevel;
                          currentLevel += numberCounts[num];
                        }

                        // Function to add the appropriate ordinal suffix or replace with "N/A" for null values
                        function addOrdinalSuffix(num) {
                          if (num === null) return 'N/A'; // Replace null with "N/A"
                          if (num === 1) return '1st';
                          if (num === 2) return '2nd';
                          if (num === 3) return '3rd';
                          return num + 'th';
                        }

                        // Assign levels with correct ordinal suffix or "N/A" for null values to the original array
                        const levelsAsOrdinals = dataArray.map((num) => addOrdinalSuffix(numberToLevel[num]));

                        // Count the occurrences of each ordinal level
                        const levelCounts = {};

                        for (const level of levelsAsOrdinals) {
                          if (levelCounts[level]) {
                            levelCounts[level]++;
                          } else {
                            levelCounts[level] = 1;
                          }
                        }

                        // Replace all "1st" with "=1st" if there are more than one "1st"
                        const firstCount = levelCounts['1st'];
                        if (firstCount > 1) {
                          for (let j = 0; j < levelsAsOrdinals.length; j++) {
                            if (levelsAsOrdinals[j] === '1st') {
                              levelsAsOrdinals[j] = '=1st';
                            }
                          }
                        }

                        // Replace ordinal levels if they occur more than once up to 20th
                        for (let i = 1; i <= 20; i++) {
                          const ordinal = i + 'th';
                          if (levelCounts[ordinal] > 1) {
                            for (let j = 0; j < levelsAsOrdinals.length; j++) {
                              if (levelsAsOrdinals[j] === ordinal) {
                                levelsAsOrdinals[j] = '=' + ordinal;
                              }
                            }
                          }
                        }

                        return levelsAsOrdinals;
                      }

                      function convertArrayToObjects(inputArray) {
                        if (!Array.isArray(inputArray)) {
                          return "Input is not an array.";
                        }

                        const result = [];

                        for (let i = 0; i < inputArray.length; i++) {
                          const code = "CII" + (i + 1);
                          const num = inputArray[i];

                          const obj = {
                            code: code,
                            num: num
                          };

                          result.push(obj);
                        }

                        return result;
                      }

                      function rankObjects(arrayOfObjects) {
                        if (!Array.isArray(arrayOfObjects)) {
                          return "Input is not an array.";
                        }

                        // Sort the objects by the "num" property in ascending order
                        const sortedObjects = arrayOfObjects.slice().sort((a, b) => a.num - b.num);

                        // Get the top 6 objects or all objects if there are fewer than 6
                        const limitedRanking = sortedObjects.slice(0, Math.min(6, sortedObjects.length));

                        return limitedRanking;
                      }

                      let indHeadArray = [];
                      let allnomHeadArray = [];
                      let competencyName = [];

                    `
                    for (const res of obj) {
                      res.ciiCompetencies.forEach((response) => {
                        output += 
                        `
                        indHeadArray.push(res.data[0].ind_` + response.Trait + `)
                        allnomHeadArray.push(res.data[0].all_nom999_` + response.Trait + `)
                        competencyName.push("` + response.Description + `")

                          if(res.data[0].ind_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                              $(".competency-ind_` + response.Trait + `").html("N/A")
                              $(".sub-heading-text-CII_new_`+response.Trait+`_s-new").html("N/A")
                          }
                          else{
                              $(".competency-ind_` + response.Trait + `").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                              $(".sub-heading-text-CII_new_`+response.Trait+`_s_new").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                          }

                          if(res.data[0].all_nom999_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                            $(".competency-all_nom999_` + response.Trait + `").html("N/A")
                          }
                          else{
                            $(".competency-all_nom999_` + response.Trait + `").html(ordinal(Math.round(res.data[0].all_nom999_` + response.Trait + `)))
                          }
                        `
                    })
                    output += `
                      let newIndHeadArr = processOrdinalLevelsHead(indHeadArray)
                      let newAllNomHeadArr = processOrdinalLevelsHead(allnomHeadArray)
                      let newAllNomHeadArrtoObject = convertArrayToObjects(newAllNomHeadArr)
                      
                      function generateOutput(array1, array2, array3, competencyArr) {
                        const output = [];

                        for (let i = 0; i < array1.length; i++) {
                          const trait = competencyArr[i];
                          const self = array1[i];
                          const ranked = array3[i];
                          const others = array2[i];

                          output.push({ Trait: trait, Self: self, Ranked: ranked, Others: others });
                        }

                        return output;
                      }

                      const result = generateOutput(newIndHeadArr, newAllNomHeadArr, allnomHeadArray, competencyName);
                      let data = result

                      const traitRow = document.getElementById('trait-row');
                      const selfRow = document.getElementById('self-row');
                      const othersRow = document.getElementById('others-row');
                      const sortButton = document.getElementById('sort-button');
                      let sortAscending = true;

                      // Function to populate the table
                      const populateTable = (dataToShow) => {
                          // Clear the table rows
                          traitRow.innerHTML = '';
                          selfRow.innerHTML = '';
                          othersRow.innerHTML = '';
                        //generate blank th
                        const blankCell = document.createElement('th');
                        traitRow.appendChild(blankCell);
                          // Populate headers
                          dataToShow.forEach(item => {
                            const traitCell = document.createElement('th');

                            // Add a class to the th element
                            traitCell.classList.add('heading-label-CII');
                            traitCell.style.backgroundColor = '#f2f2f2';
                            // Create the div element with the specified styles
                            const traitDiv = document.createElement('div');
                            traitDiv.textContent = item.Trait;
                            traitDiv.style.display = 'flex';
                            traitDiv.style.alignItems = 'center';
                            traitDiv.style.fontSize = '12px';
                            traitDiv.style.textAlign = 'center';

                            // Append the div element to the traitCell
                            traitCell.appendChild(traitDiv);

                            traitRow.appendChild(traitCell);
                          });

                        //generate Self row static
                        const blankRowSelf = document.createElement('td');
                        blankRowSelf.style.border = '1px solid #e0e0e0'
                        blankRowSelf.style.padding = '5px';
                        blankRowSelf.textContent = 'S';
                        blankRowSelf.style.color = 'rgb(184, 113, 26)'
                        blankRowSelf.style['border-right']  = '1px solid #e0e0e0';
                        selfRow.appendChild(blankRowSelf);

                        //generate Self row static
                        const blankRowOthers = document.createElement('td');
                        blankRowOthers.style.border = '1px solid #e0e0e0'
                        blankRowOthers.style.padding = '5px';
                        blankRowOthers.textContent = 'O';
                        blankRowOthers.style['border-right']  = '1px solid #e0e0e0';
                        blankRowOthers.style['border-top']  = '1px solid #e0e0e0';
                        othersRow.appendChild(blankRowOthers);

                          // Populate "Self" and "Others" rows
                          dataToShow.forEach(item => {
                            const selfCell = document.createElement('td');
                            const othersCell = document.createElement('td');
                            selfCell.style.border = '1px solid #e0e0e0'
                            othersCell.style.border = '1px solid #e0e0e0'

                            // Create the div element with the specified styles
                            const traitDivSelf = document.createElement('div');
                            traitDivSelf.textContent = item.Self;
                            traitDivSelf.style.alignItems = 'center';
                            traitDivSelf.style.fontSize = '12px';
                            traitDivSelf.style.textAlign = 'center';
                            const traitDivOthers = document.createElement('div');
                            traitDivOthers.textContent = item.Others;
                            traitDivOthers.style.alignItems = 'center';
                            traitDivOthers.style.fontSize = '12px';
                            traitDivOthers.style.textAlign = 'center';

                            // Append the div element to the traitCell
                            selfCell.appendChild(traitDivSelf);
                            othersCell.appendChild(traitDivOthers);

                            selfRow.appendChild(selfCell);
                            othersRow.appendChild(othersCell);
                          });
                      };

                      // Function to sort the data by "Ranked" column
                      const sortTableByRanked = () => {
                          const order = sortAscending ? 1 : -1;
                          data.sort((a, b) => order * (a.Ranked - b.Ranked));
                          sortAscending = !sortAscending;
                          populateTable(data.slice(0, 6)); // Display the first 6 rows visually
                      };

                      // Populate the table initially with the first 6 rows
                      populateTable(data.slice(0, 6));

                      // Sort the table upon load
                      sortTableByRanked()

                      `
                    for (const res1 of obj) {
                      res1.ciiCompetencies.forEach((response, index) => {
                          output += 
                          `
                          $(".sub-heading-text-CII_new_`+response.Trait+`_s-new").html(newIndHeadArr[${index}])
                          $(".competency-all_nom999_` + response.Trait + `").html(newAllNomHeadArr[${index}])
                          `
                      })
                    }
                    }
                  }
                }
                // section 2 with Organizational Climate Snapshot and `NO` Competency Importance Index
                if (data.Template === 'Bar-Chart-New-360-Head-OCS') {
                  if (data.Template === 'Bar-Chart-New-360-Head-OCS') { 
                    output += `
                      let avgCount = 1
                      let avgCohortCount = 1
                      let isDisplay_P = 0
                      let isDisplay_D = 0
                      let isDisplay_PD = 0
                      let isDisplay_I = 0
                      let isDisplay_E = 0
                      let isDisplay_IE = 0
                      let isDisplay_PDIE = 0

                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_PD = 1
                      } else {
                        isDisplay_P = 1
                        isDisplay_D = 1
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                      }
                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                        avgCount = avgCount + 1
                        avgCohortCount = avgCohortCount + 1
                        isDisplay_IE = 1
                      } else {
                        avgCount = avgCount + 2
                        avgCohortCount = avgCohortCount + 2
                        isDisplay_I = 1
                        isDisplay_E = 1
                      }
                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          avgCount = 2
                          avgCohortCount = 2
                          isDisplay_PDIE = 1
                        }
                      `

                    let obj1 = JSON.parse(data.Text)
                    console.log('obj1 New-360-Head-OCS', obj1)
                    for (const res of obj1) {
                      res.Competencies.forEach((response) => {
                        output += `

                          let avgHeadRollup` + response.Acronym + ` = 0
                          let cohortHeadavgRollup` + response.Acronym + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                          $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                          $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if(avgCount === HeadzeroCount` + response.Acronym + `){
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `){
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                            $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                            $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_IE997_` + response.Acronym + ` + headRoundOff_PD996_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_IE997_` + response.Acronym + ` + headRoundOff_cohort_PD996_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_PDIE998_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_PDIE998_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Acronym + `").html(avgHeadRollup` + response.Acronym + `)
                              $(".cohort_all_1000_` + response.Acronym + `").html(cohortavgHeadRollup` + response.Acronym + `)

                              $(".mainOverall-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Acronym + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Acronym + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Acronym + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Acronym + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Acronym + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Acronym + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Acronym + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Acronym + ` = 0
                            if (headRoundOff_ind_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCount` + response.Acronym + ` = HeadzeroCount` + response.Acronym + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Acronym + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Acronym + ` === 0) {
                              HeadzeroCohortCount` + response.Acronym + ` = HeadzeroCohortCount` + response.Acronym + ` + 1
                            }

                            if (avgCount === HeadzeroCount` + response.Acronym + `) {
                              avgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_psup1_` + response.Acronym + ` + headRoundOff_peer2_` + response.Acronym + ` + headRoundOff_direct3_` + response.Acronym + ` + headRoundOff_internal4_` + response.Acronym + ` + headRoundOff_external5_` + response.Acronym + `) / (avgCount - HeadzeroCount` + response.Acronym + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Acronym + `) {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs(headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Acronym + ` = Math.abs((headRoundOff_cohort_self0_` + response.Acronym + ` + headRoundOff_cohort_psup1_` + response.Acronym + ` + headRoundOff_cohort_peer2_` + response.Acronym + ` + headRoundOff_cohort_direct3_` + response.Acronym + ` + headRoundOff_cohort_internal4_` + response.Acronym + ` + headRoundOff_cohort_external5_` + response.Acronym + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Acronym + `)).toFixed(1)
                            }
                          }
                        `
                      })
                    }

                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          let `+ response.Trait + `Dataset = [res.data[0].PDIE998_` + response.Trait + `, res.data[0].psup1_` + response.Trait + `, res.data[0].ind_` + response.Trait + `]
                          let `+ response.Trait + `finalSet = arrayRemoveZero(`+ response.Trait + `Dataset, null)
                          let `+ response.Trait + `Sum = `+ response.Trait + `finalSet.reduce((a, b) => a + b, 0)
                          let `+ response.Trait + `Result = ` + response.Trait + `Sum / ` + response.Trait + `finalSet.length
                          let `+ response.Trait + ` = ` + response.Trait + `Result.toFixed(1)
                          // MAIN 

                          let avgHeadRollup` + response.Trait + ` = 0
                          let cohortHeadavgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                            cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }

                          // Total
                          $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                          $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                          $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {

                            let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                            let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if((avgCount + 1) === HeadzeroCount` + response.Trait + `){
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `).toFixed(1)
                            }  else {
                                avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `){
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else{
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }  
                            
                            // Total
                            $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                            $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                            $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }

                            else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `).toFixed(1)    
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_IE997_` + response.Trait + ` + headRoundOff_PD996_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_IE997_` + response.Trait + ` + headRoundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            }


                            if (isDisplay_PDIE === 1) {

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_PDIE998_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }

                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                              let headRoundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                              let headRoundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                              let headRoundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                              let headRoundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            
                              let HeadzeroCount` + response.Trait + ` = 0
                            if (headRoundOff_ind_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_external5_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCount` + response.Trait + ` = HeadzeroCount` + response.Trait + ` + 1
                            }

                            let HeadzeroCohortCount` + response.Trait + ` = 0

                            if (headRoundOff_cohort_self0_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_external5_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }
                            if (headRoundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              HeadzeroCohortCount` + response.Trait + ` = HeadzeroCohortCount` + response.Trait + ` + 1
                            }

                            if ((avgCount + 1) === HeadzeroCount` + response.Trait + `) {
                              avgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_ind_` + response.Trait + ` + headRoundOff_psup1_` + response.Trait + ` + headRoundOff_peer2_` + response.Trait + ` + headRoundOff_direct3_` + response.Trait + ` + headRoundOff_internal4_` + response.Trait + ` + headRoundOff_external5_` + response.Trait + `) / ((avgCount + 1) - HeadzeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount + 1) === HeadzeroCohortCount` + response.Trait + `) {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs(headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgHeadRollup` + response.Trait + ` = Math.abs((headRoundOff_cohort_self0_` + response.Trait + ` + headRoundOff_cohort_psup1_` + response.Trait + ` + headRoundOff_cohort_peer2_` + response.Trait + ` + headRoundOff_cohort_direct3_` + response.Trait + ` + headRoundOff_cohort_internal4_` + response.Trait + ` + headRoundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount + 1) - HeadzeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                              
                              
                              // Total
                              $(".all_1000_` + response.Trait + `").html(avgHeadRollup` + response.Trait + `)
                              $(".cohort_all_1000_` + response.Trait + `").html(cohortavgHeadRollup` + response.Trait + `)

                              $(".mainOverall-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainOverall-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              $(".mainCohort-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '})' + '`' + `})
                              $(".mainCohort-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgHeadRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                              }
                          
                          // PRIMARY SUPERVISOR
                          $(".psup1_` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort_psup1_` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".mainPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPsup-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPsup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct report
                          $(".PD996_` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort_PD996_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".mainPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".peer2_` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort_peer2_` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".mainP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortP-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortP-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".direct3_` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort_direct3_` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".mainD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortD-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder|External stakeholders
                          $(".IE997_` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort_IE997_` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".mainIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".internal4_` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort_internal4_` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".mainI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortI-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortI-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".external5_` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort_external5_` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".mainE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort_PDIE998_` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".mainPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortPDIE-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortPDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Self
                          $(".ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort_self0_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".mainSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".mainSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohortSelf-` + response.Trait + `").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohortSelf-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                          $('.front-page-for-individual-report').removeClass('d-none')
                          $('.front-page-psup-value').text(res.data[0].psup1_N)
                          $('.front-page-P-value').text(res.data[0].peer2_N)
                          $('.front-page-D-value').text(res.data[0].direct3_N)
                          $('.front-page-PD-value').text(res.data[0].PDIE998_N)
                          $('.front-page-I-value').text(res.data[0].internal4_N)
                          $('.front-page-E-value').text(res.data[0].external5_N)
                          $('.front-page-IE-value').text(res.data[0].IE997_N)
                          $('.front-page-PDIE-value').text(res.data[0].PDIE998_N)

                          if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          
                          if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")

                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          }
                          
                          if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".head-bar-chart-`+response.Trait+`-P").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-D").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-I").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-E").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-PD").css("display", "none")
                            $(".head-bar-chart-`+response.Trait+`-IE").css("display", "none")

                            $(".front-page-P").css("display", "none")
                            $(".front-page-D").css("display", "none")
                            $(".front-page-I").css("display", "none")
                            $(".front-page-E").css("display", "none")
                            $(".front-page-PD").css("display", "none")
                            $(".front-page-IE").css("display", "none")
                          } else {
                            $(".head-bar-chart-`+response.Trait+`-PDIE").css("display", "none")

                            $(".front-page-PDIE").css("display", "none")
                          }
                        `
                      })
                    }
                    output += `
                      $('.360_head').removeClass('d-none')
                      $('.360_head_spinner').addClass('d-none')
                    `
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head-OCS') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `

                          // PRIMARY SUPERVISOR
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Peers|Direct reports
                          $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                          //Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                          let avgRollup` + response.Trait + ` = 0
                          let cohortavgRollup` + response.Trait + ` = 0
                          let cohortSelfAvgRollup` + response.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                            }
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                            } 
                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                            }

                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }

                          if (isDisplay_PDIE === 1) {
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                          else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                            let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                            let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                            let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + response.Trait + ` = 0
                            if (roundOff_psup1_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_external5_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + response.Trait + ` === 0) {
                              zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                            }

                            let zeroCohortCount` + response.Trait + ` = 0

                            if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                              zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + response.Trait + `) {
                              avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                              cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                            }
                            
                            
                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          }
                          

                          // Self
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                          
                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }
                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          }

                        if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }
                        if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                            $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                          }

                          
                          if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                        `
                      })
                    }
                    output += `
                      $('.360_sub').removeClass('d-none')
                      $('.360_sub_spinner').addClass('d-none')
                    `
                  }
                  // slider section 2
                  if (data.Template === 'Bar-Chart-New-360-Head-OCS') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Competencies.forEach((resCompetency) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].all_nom999_`+resCompetency.Acronym+`).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));                                

                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        } else {
                          
                          $(".custom-bar-Others-`+resCompetency.Acronym+`-value").text(parseFloat(avgHeadRollup` + resCompetency.Acronym + `).toFixed(1));
                          $(".custom-bar-Self-`+resCompetency.Acronym+`-value").text(parseFloat(res.data[0].ind_`+resCompetency.Acronym+`).toFixed(1));         
                                                 
                          $(".custom-bar-Others-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].all_nom999_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                          $(".custom-bar-Self-`+resCompetency.Acronym+`").css('left', +(parseFloat( (res.data[0].ind_`+resCompetency.Acronym+`)?.toFixed(1) - 1) * 25)+'%');
                        }`
                      })
                    }
                  }
                  // Top-Behaviours section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                          // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top_behaviours_` + response.Trait + `").text( ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 )
                            let avgOthersInd_` + response.Trait + ` = ( parseFloat(res.data[0].all_nom999_` + response.Trait + `) + res.data[0].ind_` + response.Trait + `) / 2 
                            $(".total_avg_score` + response.Trait + `").text( parseFloat( avgOthersInd_` + response.Trait + ` ).toFixed(1) )
                            $(".isSmartCollab").removeClass("d-none")
                          }
                          else {
                            $(".top_behaviours_` + response.Trait + `").text(avgRollup` + response.Trait + `)
                          }
                        `
                      })
                      output += `
                        $('.top-behaviours-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.top-behaviours-wrapper'));

                        const topBehavioursStartLength = 3
                        const topBehavioursMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-behaviours-sortme').slice(topBehavioursStartLength, topBehavioursMaxLength).hide();
                        $('.top-behaviours-average-score').hide()
                      `
                    }
                  }
                  // for Undiscovered-Strengths section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        // smart collab = 427 in db
                          if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-undiscovered-strength-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-undiscovered-strength-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                              console.log('negative')
                            }else{
                              if( Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                                $(".top-undiscovered-strength_` + response.Trait + `").html( Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) - (res.data[0].ind_` + response.Trait + `) )
                                $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                                console.log('remove d-none undiscovered-strength')
                              }
                            }
                          }else {
                            $(".top-undiscovered-strength_` + response.Trait + `").html( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) )
                            if( (avgRollup` + response.Trait + `) - (res.data[0].ind_` + response.Trait + `) >= 2 ){
                              $(".undiscovered-strength-row-` + response.Trait + `").removeClass("d-none")
                              console.log('remove d-none undiscovered-strength')
                            }
                          }
                        `
                      })

                      output +=`
                        const isUndiscoveredStrengthAllHidden = $(".undiscovered-strength-wrapper .uslistZeroItem").not(".d-none").length === 0;

                        if (isUndiscoveredStrengthAllHidden) {
                          $('.undiscovered-strength-all-rows').addClass('d-none')
                          $('.undiscovered-strength-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                        $('.top-undiscovered-strength-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.undiscovered-strength-wrapper'));

                        const topUndiscoveredStrengthStartLength = 3
                        const topUndiscoveredStrengthMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-undiscovered-strength-sortme').slice(topUndiscoveredStrengthStartLength, topUndiscoveredStrengthMaxLength).hide();
                        $('.top-undiscovered-strength-average-score').hide()
                      `
                    }
                  }
                  // for Potential-Blind-Spots section 2
                  if(data.Template === 'Bar-Chart-New-360-Head-OCS'){
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      res.Elements.forEach((response) => {
                        output += `
                        if(`+ ORGID +` == 427) {
                            // for smart collab computation
                            $(".top-potential-blindspot-self_` + response.Trait + `").html((res.data[0].ind_` + response.Trait + `))
                            $(".top-potential-blindspot-others_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))

                            if(res.data[0].ind_` + response.Trait + ` == 0){
                                console.log('negative')
                              }else{
                                if( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) >= 2 ){
                                  $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1) )
                                  $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                                }
                              }
                          }else {
                            $(".top-potential-blindspot_` + response.Trait + `").html( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) )
                            if( (res.data[0].ind_` + response.Trait + `) - (avgRollup` + response.Trait + `) >= 2 ){
                                $(".potential-blindspot-row-` + response.Trait + `").removeClass("d-none")
                              }
                          }
                        `
                      })

                      output +=`
                        const isPotentialBlindSpotsAllHidden = $(".potential-blindspot-wrapper .pblistZeroItem").not(".d-none").length === 0;

                        console.log('this is potential blind spot',isPotentialBlindSpotsAllHidden)


                        if (isPotentialBlindSpotsAllHidden) {
                          $('.potential-blindspot-all-rows').addClass('d-none')
                          $('.potential-blindspot-message').removeClass('d-none')
                          console.log("All <li> elements have class 'd-none'.");
                        } else {
                          console.log("Not all <li> elements have class 'd-none'.");
                        }
                      `

                      output += `
                      $('.top-potential-blindspot-sortme').sort(function(a, b) {
                          if (a.textContent > b.textContent) {
                            return -1;
                          } else {
                            return 1;
                          }
                        }).appendTo($('.potential-blindspot-wrapper'));

                        const potentialBlindSpotStartLength = 3
                        const potentialBlindSpotMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                        $('.top-potential-blindspot-sortme').slice(potentialBlindSpotStartLength, potentialBlindSpotMaxLength).hide();
                        $('.top-potential-blindspot-average-score').hide()
                      `
                    }
                  }
                  if (data.Template === 'Bar-Chart-New-360-Head-OCS') { 
                    let obj = JSON.parse(data.Text)
                    
                    for (const res of obj) {
                      output += `
                          let ` + res.ocsSuperTrait + `chart = document.querySelectorAll('.` + res.ocsSuperTrait + `')
                          new Chart(`+ res.ocsSuperTrait +`chart,{
                            type: "line",
                            data:{
                              labels: [
                                  ['ER','Emotional', 'Reactivity'],
                                  ['E','Extraversion'],
                                  ['O','Openness', 'to Chage'],
                                  ['A','Agreeableness'], 
                                  ['C','Conscientiousness']
                              ],
                              datasets:[{
                                  label: "S",
                                  data: [
                                    res.data[0].ind_ERX ? res.data[0].ind_ERX : 0, 
                                    res.data[0].ind_EX ? res.data[0].ind_EX : 0, 
                                    res.data[0].ind_OX ? res.data[0].ind_OX : 0, 
                                    res.data[0].ind_AX ? res.data[0].ind_AX : 0, 
                                    res.data[0].ind_CX ? res.data[0].ind_CX : 0
                                  ],
                                  borderColor: '`+ res.Colors.color_s +`'
                                },
                                {
                                  label: "O",
                                  data: [
                                    res.data[0].all_nom999_ERX ? res.data[0].all_nom999_ERX : 0,
                                    res.data[0].all_nom999_EX ? res.data[0].all_nom999_EX : 0,
                                    res.data[0].all_nom999_OX ? res.data[0].all_nom999_OX : 0,
                                    res.data[0].all_nom999_AX ? res.data[0].all_nom999_AX : 0,
                                    res.data[0].all_nom999_CX ? res.data[0].all_nom999_CX : 0
                                  ],
                                  borderColor: '`+ res.Colors.color_o +`'
                                }]
                            },
                            options: {
                              scales: {
                                xAxis: {position: 'top'},
                                y: {
                                  beginAtZero: true,
                                  min: 0,
                                  max: 5,
                                  ticks: {
                                    stepSize: 1,
                                  }
                                }
                              },
                              plugins: {
                                legend: {
                                  position: "left"
                                }
                              },
                              responsive: true,
                              maintainAspectRatio: false
                            }
                          })
                        ` 
                    }
                  }
                }



                /*********** NOTE in section 3-8 ****************
                  * 
                  * Bar-Chart-New-360-Sub
                  * Bar-Chart-New-360-Sub-Without-Cohort-And-Others
                  * Bar-Chart-New-360-Sub-With-Cohort-And-No-Others, 
                  * Bar-Chart-New-360-Sub-With-Others-And-No-Cohort
                  * 
                  * the 4 templates have the same codes inside
                  * 
                  * if we change one template, just copy it in other templates
                  * 
                  */

                if (data.Template === 'Bar-Chart-New-360-Sub') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + response.Trait + ` = 0
                        // let cohortavgRollup` + response.Trait + ` = 0
                        // let cohortSelfAvgRollup` + response.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          console.log('PD + I + E')
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            // $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")
                            // $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                            // $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                            // $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                            // $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                            // $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                            // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          // $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")

                          // $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          
                          // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          console.log('IE + PD')
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          // $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")

                          // $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")

                          // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          console.log('PDIE')
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          // $(".custom-bar-graph-`+response.Trait+`-PDIE").removeClass("d-none")

                          // $(".bd-key-`+res.Elements[0].Trait+`-PDIE").removeClass("d-none")

                          // $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())

                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          console.log('P + D + I + E')
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          // $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                          // $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                          // $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                          // $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                          // $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }
                        // dito ilalagay code
                        //$(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                        // $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())

                        $(".bd-`+res.Elements[0].Trait+`-value-P").text((index,currentText)=>{
                          return res.data[0].peer2_N=== null ? 0 : +parseFloat(res.data[0].peer2_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-D").text((index,currentText)=>{
                          return res.data[0].direct3_N=== null ? 0 : +parseFloat(res.data[0].direct3_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-I").text((index,currentText)=>{
                          return res.data[0].internal4_N=== null ? 0 : +parseFloat(res.data[0].internal4_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-E").text((index,currentText)=>{
                          return res.data[0].external5_N=== null ? 0 : +parseFloat(res.data[0].external5_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text((index,currentText)=>{
                          return res.data[0].PDIE998_N === null ? 0 : +parseFloat(res.data[0].PDIE998_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-IE").text((index,currentText)=>{
                          return res.data[0].IE997_N=== null ? 0 : +parseFloat(res.data[0].IE997_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-PD").text((index,currentText)=>{
                          return res.data[0].PD996_N=== null ? 0 : +parseFloat(res.data[0].PD996_N).toFixed()     
                        })
                        $(".bd-`+res.Elements[0].Trait+`-value-O").text((index,currentText)=>{
                          return res.data[0].all_nom999_N=== null ? 0 : +parseFloat(res.data[0].all_nom999_N).toFixed()     
                        })
                          

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+response.Trait+`-PS").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements[0].Trait +`-PS").removeClass("d-none")
                        //$(".bd-`+res.Elements[0].Trait+`-value-PS").text(+parseFloat(res.data[0].psup1_N).toFixed())   
                        $(".bd-`+ res.Elements[0].Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })
                        
                

                        if(parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+response.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+response.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+response.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+response.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+response.Trait+`-S").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".bd-`+res.Elements[0].Trait+`-value-S").text((index,currentText)=>{
                          return res.data[0].ind_N=== null ? 0 : +parseFloat(res.data[0].ind_N).toFixed()     
                        })


                        if(parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers
                        $(".span-`+response.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+response.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+response.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //All Nominees
                        $(".span-`+response.Trait+`-value-Others").text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+response.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                        $(".custom-bar-graph-`+response.Trait+`-P").addClass("d-none")
                        $(".bd-key-`+res.Elements[0].Trait+`-P").addClass("d-none")
                        
                        $(".custom-bar-graph-`+response.Trait+`-D").addClass("d-none")
                        $(".bd-key-`+res.Elements[0].Trait+`-D").addClass("d-none")

                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")

                          $(".custom-bar-graph-`+response.Trait+`-I").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-E").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-I").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-E").addClass("d-none")

                        } else {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")

                          $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                        $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                        $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")

                          $(".custom-bar-graph-`+response.Trait+`-P").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-I").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-E").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-P").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-I").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-E").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")

                        } else {
                          $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")

                          $(".custom-bar-graph-`+response.Trait+`-PDIE").addClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-PDIE").addClass("d-none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                      } 
                      if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                      }
                      if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                      }
                      if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                      }

                      if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                      }
                      if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                        $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                      $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                      }
                      `
                    })
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                          let AllNomAvg` + response.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                          $(".span-`+response.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          `
                        })
                      }
                  output += `
                    }
                    `
                    for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                          let cohortScore` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_all_nom999_` + response.Trait + `).toFixed(1))
                          $('.cohortScore-`+response.Trait+`').text(Math.abs(cohortScore`+ response.Trait +`).toFixed(1))
                    `
                        })
                      }
                          output += `
                            $('.360_sub').removeClass('d-none')
                            $('.360_sub_spinner').addClass('d-none')
                          `
                }

                // section 3-8 without cohort data and average all others 
                if (data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + response.Trait + ` = 0
                        // let cohortavgRollup` + response.Trait + ` = 0
                        // let cohortSelfAvgRollup` + response.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                            }

                            $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                          }

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }

                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+response.Trait+`-PS").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements[0].Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements[0].Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })

                        if(parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+response.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+response.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+response.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+response.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+response.Trait+`-S").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers
                        $(".span-`+response.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+response.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+response.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //All Nominees
                        $(".span-`+response.Trait+`-value-Others").text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+response.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                    })
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                          let AllNomAvg` + response.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                          $(".span-`+response.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          `
                        })
                      }
                  output += `
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }
                // section 3-8 with cohort data and `NO` average all others 
                if (data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + response.Trait + ` = 0
                        // let cohortavgRollup` + response.Trait + ` = 0
                        // let cohortSelfAvgRollup` + response.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                            $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                            }


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())

                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+response.Trait+`-PS").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements[0].Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements[0].Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })
                

                        if(parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+response.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+response.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+response.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+response.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+response.Trait+`-S").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers
                        $(".span-`+response.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+response.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+response.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //All Nominees
                        $(".span-`+response.Trait+`-value-Others").text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+response.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                    })
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                          let AllNomAvg` + response.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                          $(".span-`+response.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          `
                        })
                      }
                  output += `
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }
                // section 3-8 with average all others and `NO` cohort data
                if (data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_psup1_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_direct3_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PD996_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_internal4_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_external5_' + `` + response.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].peer2_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_IE997_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_PDIE998_' + `` + response.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + response.Trait + ` = 0
                        // let cohortavgRollup` + response.Trait + ` = 0
                        // let cohortSelfAvgRollup` + response.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                        
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PD996_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")

                            $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                            }


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_peer2_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_IE997_` + response.Trait + ` + roundOff_PD996_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_IE997_` + response.Trait + ` + roundOff_cohort_PD996_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements[0].Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements[0].Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+response.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_PDIE998_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_PDIE998_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements[0].Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          let roundOff_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))
                          let roundOff_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + response.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + response.Trait + ` = 0
                          if (roundOff_psup1_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_external5_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + response.Trait + ` === 0) {
                            zeroCount` + response.Trait + ` = zeroCount` + response.Trait + ` + 1
                          }

                          let zeroCohortCount` + response.Trait + ` = 0

                          if (roundOff_cohort_self0_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + response.Trait + ` === 0) {
                            zeroCohortCount` + response.Trait + ` = zeroCohortCount` + response.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + response.Trait + `) {
                            avgRollup` + response.Trait + ` = Math.abs(roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + response.Trait + ` = Math.abs((roundOff_psup1_` + response.Trait + ` + roundOff_peer2_` + response.Trait + ` + roundOff_direct3_` + response.Trait + ` + roundOff_internal4_` + response.Trait + ` + roundOff_external5_` + response.Trait + `) / (avgCount - zeroCount` + response.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + response.Trait + `) {
                            cohortavgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / (avgCohortCount - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + response.Trait + `) {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs(roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + response.Trait + ` = Math.abs((roundOff_cohort_self0_` + response.Trait + ` + roundOff_cohort_psup1_` + response.Trait + ` + roundOff_cohort_peer2_` + response.Trait + ` + roundOff_cohort_direct3_` + response.Trait + ` + roundOff_cohort_internal4_` + response.Trait + ` + roundOff_cohort_external5_` + response.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + response.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+response.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+response.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements[0].Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements[0].Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements[0].Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements[0].Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${avgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${avgRollup' + `` + response.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${cohortavgRollup' + `` + response.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+response.Trait+`-PS").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements[0].Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements[0].Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })
                

                        if(parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+response.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+response.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+response.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+response.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+response.Trait+`-S").removeClass("d-none")
                        $(".span-`+response.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers
                        $(".span-`+response.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+response.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+response.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1) - 1) * 25 + '%')
                        }

                        //All Nominees
                        $(".span-`+response.Trait+`-value-Others").text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+response.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+response.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ response.Trait +`).toFixed(1) - 1) * 25 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+response.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(avgRollup`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].ind_' + `` + response.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + response.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 20 * ${res.data[0].cohort_self0_' + `` + response.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+response.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+response.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+response.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                    })
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                        res.Elements.forEach((response) => {
                          output += `
                          let AllNomAvg` + response.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                          $(".span-`+response.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          $('.averageOthers-`+response.Trait+`').text(Math.abs(AllNomAvg`+ response.Trait +`).toFixed(1))
                          `
                        })
                      }
                  output += `
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }
                
                // Section for multiple NPS javascript
                if (data.Template === 'Bar-Chart-New-360-Sub-NPS') { 
                    let obj = JSON.parse(data.Text)
                    for (const res of obj) {
                      console.log(res)
                        output += `

                          // PRIMARY SUPERVISOR
                          $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // Peers
                          $(".my-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // Direct report
                          $(".my-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          //Peers|Direct reports
                          $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // Internal stakeholder
                          $(".my-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // External stakeholders
                          $(".my-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          //Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // Peers|Direct reports|Internal stakeholders|External stakeholders
                          $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          // let avgRollup` + res.Elements.Trait + ` = 0
                          // let cohortavgRollup` + res.Elements.Trait + ` = 0
                          // let cohortSelfAvgRollup` + res.Elements.Trait + ` = 0

                          if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            console.log('PD + I + E')
                            let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          
                            let zeroCount` + res.Elements.Trait + ` = 0
                            if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }

                            let zeroCohortCount` + res.Elements.Trait + ` = 0

                            if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + res.Elements.Trait + `) {
                              avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)  
                            }
                            
                            //display - Emil
                              // $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")
                              // $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                              // $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                              // $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                              // $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                              // $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")

                              // $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                              // $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                              // $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                              // $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                              


                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                            } 
                          else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                            let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            
                            let zeroCount` + res.Elements.Trait + ` = 0
                            if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }

                            let zeroCohortCount` + res.Elements.Trait + ` = 0

                            if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + res.Elements.Trait + `) {
                              avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                            }
                            
                            //display - Emil
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")

                            // $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                            
                            // $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                            
                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          }

                          else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                            console.log('IE + PD')
                            let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + res.Elements.Trait + ` = 0
                            if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }

                            let zeroCohortCount` + res.Elements.Trait + ` = 0

                            if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + res.Elements.Trait + `) {
                              avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                            }

                            //display - Emil
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")

                            // $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")

                            // $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            

                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          }

                          if (isDisplay_PDIE === 1) {
                            console.log('PDIE')
                            let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + res.Elements.Trait + ` = 0
                            if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_PDIE998_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }

                            let zeroCohortCount` + res.Elements.Trait + ` = 0

                            if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_PDIE998_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + res.Elements.Trait + `) {
                              avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                            }

                            //display - Emil
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-PDIE").removeClass("d-none")

                            // $(".bd-key-`+res.Elements.Trait+`-PDIE").removeClass("d-none")

                            // $(".bd-`+res.Elements.Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())

                            
                          // Total
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          } 
                          else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                            console.log('P + D + I + E')
                            let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                            let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                            let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                            
                            let zeroCount` + res.Elements.Trait + ` = 0
                            if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                              zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                            }

                            let zeroCohortCount` + res.Elements.Trait + ` = 0

                            if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }
                            if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                              zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                            }

                            if (avgCount === zeroCount` + res.Elements.Trait + `) {
                              avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                            } else {
                              cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                            }
                            
                            //display - Emil
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                            // $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                            // $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                            // $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")

                            // $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            // $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())


                            // Total
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                            $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          }
                          // dito ilalagay code
                          //$(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          // $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())

                          $(".bd-`+res.Elements.Trait+`-value-P").text((index,currentText)=>{
                            return res.data[0].peer2_N=== null ? 0 : +parseFloat(res.data[0].peer2_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-D").text((index,currentText)=>{
                            return res.data[0].direct3_N=== null ? 0 : +parseFloat(res.data[0].direct3_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-I").text((index,currentText)=>{
                            return res.data[0].internal4_N=== null ? 0 : +parseFloat(res.data[0].internal4_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-E").text((index,currentText)=>{
                            return res.data[0].external5_N=== null ? 0 : +parseFloat(res.data[0].external5_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-PDIE").text((index,currentText)=>{
                            return res.data[0].PDIE998_N === null ? 0 : +parseFloat(res.data[0].PDIE998_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-IE").text((index,currentText)=>{
                            return res.data[0].IE997_N=== null ? 0 : +parseFloat(res.data[0].IE997_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-PD").text((index,currentText)=>{
                            return res.data[0].PD996_N=== null ? 0 : +parseFloat(res.data[0].PD996_N).toFixed()     
                          })
                          $(".bd-`+res.Elements.Trait+`-value-O").text((index,currentText)=>{
                            return res.data[0].all_nom999_N=== null ? 0 : +parseFloat(res.data[0].all_nom999_N).toFixed()     
                          })
                            

                          //Display - Emil
                          
                          // Primary Supervisor
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PS").removeClass("d-none")
                          $(".span-`+res.Elements.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          
                          $(".bd-key-`+ res.Elements.Trait +`-PS").removeClass("d-none")
                          //$(".bd-`+res.Elements.Trait+`-value-PS").text(+parseFloat(res.data[0].psup1_N).toFixed())   
                          $(".bd-`+ res.Elements.Trait +`-value-PS").text((index,currentText)=>{
                            return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                          })
                          
                  

                          if(parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-PS").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                            
                            
                          //Direct Reports
                          $(".span-`+res.Elements.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))

                          if(parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-D").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          //Internal and External Stakeholders
                          $(".span-`+res.Elements.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          if(parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-IE").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          //Internal Stakeholders
                          $(".span-`+res.Elements.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))

                          if(parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-I").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          //External Stakeholders
                          $(".span-`+res.Elements.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))

                          if(parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-E").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                        

                          // Self
                          $(".custom-bar-graph-`+res.Elements.Trait+`-S").removeClass("d-none")
                          $(".span-`+res.Elements.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          $(".bd-`+res.Elements.Trait+`-value-S").text((index,currentText)=>{
                            return res.data[0].ind_N=== null ? 0 : +parseFloat(res.data[0].ind_N).toFixed()     
                          })


                          if(parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-S").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          //Peers
                          $(".span-`+res.Elements.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))

                          if(parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-P").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          //Peers and Direct Reports
                          $(".span-`+res.Elements.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          if(parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-PD").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }

                          //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                          $(".span-`+res.Elements.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                          if(parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-PDIE").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                          }

                          //All Nominees
                          $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                          if(parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1)) < 1) {
                            $(".position-`+res.Elements.Trait+`-Others").css('left', '-1%')
                          }
                          else {
                            $(".position-`+res.Elements.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1) - 2) * 12.5 + '%')
                          }
                          

                          // Average Others
                          $(".custom-bar-graph-`+res.Elements.Trait+`-Others").removeClass("d-none")
                            $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                            $('.averageOthers-`+res.Elements.Trait+`').css('color','rgb(84,130,53)')
                            // End of Display - Emil



                          // Self
                          $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))

                          $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          
                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }
                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").addClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-P").addClass("d-none")
                          
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").addClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").addClass("d-none")

                            $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                          } else {
                            $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")

                            $(".custom-bar-graph-`+res.Elements.Trait+`-I").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-E").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-I").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-E").addClass("d-none")

                          } else {
                            $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")

                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }

                        if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                        }
                        if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                        }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                            $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")

                            $(".custom-bar-graph-`+res.Elements.Trait+`-P").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-D").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-I").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-E").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")

                            $(".bd-key-`+res.Elements.Trait+`-P").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-D").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-I").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-E").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")

                          } else {
                            $(".sub-bar-chart-PDIE-`+res.Elements.Trait+`").css("display", "none")

                            $(".custom-bar-graph-`+res.Elements.Trait+`-PDIE").addClass("d-none")

                            $(".bd-key-`+res.Elements.Trait+`-PDIE").addClass("d-none")
                          }

                          
                          if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                        `

                    }
                    output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                          output += `
                          let AllNomAvg` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + res.Elements.Trait + `).toFixed(1))
                          $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          `
                    }
                  output += `
                    console.log('AllNomCount : ', res.data[0].all_nom999_N)
                    }
                  `
                    output += `
                      $('.360_sub').removeClass('d-none')
                      $('.360_sub_spinner').addClass('d-none')
                    `
                }

                if (data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others-NPS') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {

                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + res.Elements.Trait + ` = 0
                        // let cohortavgRollup` + res.Elements.Trait + ` = 0
                        // let cohortSelfAvgRollup` + res.Elements.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                            }

                            $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                          }

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }

                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements.Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+res.Elements.Trait+`-PS").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements.Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements.Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })

                        if(parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+res.Elements.Trait+`-S").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers
                        $(".span-`+res.Elements.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //All Nominees
                        $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+res.Elements.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+res.Elements.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                          output += `
                          let AllNomAvg` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + res.Elements.Trait + `).toFixed(1))
                          $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          `
                    }
                  output += `
                    console.log('AllNomCount : ', res.data[0].all_nom999_N)
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }

                if (data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others-NPS') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + res.Elements.Trait + ` = 0
                        // let cohortavgRollup` + res.Elements.Trait + ` = 0
                        // let cohortSelfAvgRollup` + res.Elements.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")

                            $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                            }


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())

                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements.Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())

                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+res.Elements.Trait+`-PS").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements.Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements.Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })
                

                        if(parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+res.Elements.Trait+`-S").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers
                        $(".span-`+res.Elements.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //All Nominees
                        $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+res.Elements.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+res.Elements.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                          output += `
                          let AllNomAvg` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + res.Elements.Trait + `).toFixed(1))
                          $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          `
                    }
                  output += `
                    console.log('AllNomCount : ', res.data[0].all_nom999_N)
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }

                if (data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort-NPS') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                      output += `

                        // PRIMARY SUPERVISOR
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-Psup-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_psup1_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-P-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Direct report
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-D-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_direct3_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Peers|Direct reports
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PD-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PD996_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Internal stakeholder
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-I-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_internal4_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // External stakeholders
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-E-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_external5_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        //Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].peer2_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-IE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_IE997_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // Peers|Direct reports|Internal stakeholders|External stakeholders
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-PDIE-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_PDIE998_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        // let avgRollup` + res.Elements.Trait + ` = 0
                        // let cohortavgRollup` + res.Elements.Trait + ` = 0
                        // let cohortSelfAvgRollup` + res.Elements.Trait + ` = 0

                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                        
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)  
                          }
                          
                          //display - Emil
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                            $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                            $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")

                            $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                            $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())

                            if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                            }
                            if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                              $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                              $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                            }


                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                          } 
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          
                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PD996_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_IE997_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_IE997_` + res.Elements.Trait + ` + roundOff_PD996_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_IE997_` + res.Elements.Trait + ` + roundOff_cohort_PD996_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1) 
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PD").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-IE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-PD").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements.Trait+`-value-IE").text(parseFloat(res.data[0].IE997_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-PD").text(parseFloat(res.data[0].PD996_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                          if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-PD").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-PD").addClass("d-none")
                          }
                          if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                            $(".bd-key-`+res.Elements.Trait+`-IE").addClass("d-none")
                            $(".custom-bar-graph-`+res.Elements.Trait+`-IE").addClass("d-none")
                          }

                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }

                        if (isDisplay_PDIE === 1) {
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_PDIE998_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_PDIE998_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_PDIE998_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }

                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-PDIE").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-PDIE").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")
                          $(".bd-`+res.Elements.Trait+`-value-PDIE").text(parseFloat(res.data[0].PDIE998_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())
                          
                        // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        } 
                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          let roundOff_ind_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))

                          let roundOff_cohort_self0_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_psup1_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_psup1_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PD996_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PD996_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_internal4_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_internal4_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_external5_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_external5_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_direct3_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_direct3_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_peer2_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_peer2_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_IE997_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_IE997_` + res.Elements.Trait + `).toFixed(1))
                          let roundOff_cohort_PDIE998_` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].cohort_PDIE998_` + res.Elements.Trait + `).toFixed(1))

                                                          
                          let zeroCount` + res.Elements.Trait + ` = 0
                          if (roundOff_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCount` + res.Elements.Trait + ` = zeroCount` + res.Elements.Trait + ` + 1
                          }

                          let zeroCohortCount` + res.Elements.Trait + ` = 0

                          if (roundOff_cohort_self0_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_psup1_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_internal4_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_external5_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_direct3_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }
                          if (roundOff_cohort_peer2_` + res.Elements.Trait + ` === 0) {
                            zeroCohortCount` + res.Elements.Trait + ` = zeroCohortCount` + res.Elements.Trait + ` + 1
                          }

                          if (avgCount === zeroCount` + res.Elements.Trait + `) {
                            avgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            avgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_psup1_` + res.Elements.Trait + ` + roundOff_peer2_` + res.Elements.Trait + ` + roundOff_direct3_` + res.Elements.Trait + ` + roundOff_internal4_` + res.Elements.Trait + ` + roundOff_external5_` + res.Elements.Trait + `) / (avgCount - zeroCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if (avgCohortCount === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortavgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / (avgCohortCount - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          if ((avgCohortCount+1) === zeroCohortCount` + res.Elements.Trait + `) {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs(roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `).toFixed(1)
                          } else {
                            cohortSelfAvgRollup` + res.Elements.Trait + ` = Math.abs((roundOff_cohort_self0_` + res.Elements.Trait + ` + roundOff_cohort_psup1_` + res.Elements.Trait + ` + roundOff_cohort_peer2_` + res.Elements.Trait + ` + roundOff_cohort_direct3_` + res.Elements.Trait + ` + roundOff_cohort_internal4_` + res.Elements.Trait + ` + roundOff_cohort_external5_` + res.Elements.Trait + `) / ((avgCohortCount+1) - zeroCohortCount` + res.Elements.Trait + `)).toFixed(1)
                          }
                          
                          //display - Emil
                          $(".custom-bar-graph-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".custom-bar-graph-`+res.Elements.Trait+`-E").removeClass("d-none")

                          $(".bd-key-`+res.Elements.Trait+`-P").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-D").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-I").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-E").removeClass("d-none")
                          $(".bd-key-`+res.Elements.Trait+`-O").removeClass("d-none")

                          $(".bd-`+res.Elements.Trait+`-value-P").text(parseFloat(res.data[0].peer2_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-D").text(parseFloat(res.data[0].direct3_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-I").text(parseFloat(res.data[0].internal4_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-E").text(parseFloat(res.data[0].external5_N).toFixed())
                          $(".bd-`+res.Elements.Trait+`-value-O").text(parseFloat(res.data[0].all_nom999_N).toFixed())


                          // Total
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(avgRollup` + res.Elements.Trait + `)
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `").html(cohortavgRollup` + res.Elements.Trait + `)

                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".my-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${avgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                          $(".cohort-sub-bar-chart-TOTAL-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${cohortavgRollup' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        }
                        

                        //Display - Emil
                        
                        // Primary Supervisor
                        $(".custom-bar-graph-`+res.Elements.Trait+`-PS").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-PS").text(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1))
                        
                        $(".bd-key-`+ res.Elements.Trait +`-PS").removeClass("d-none")
                        $(".bd-`+ res.Elements.Trait +`-value-PS").text((index,currentText)=>{
                          return res.data[0].psup1_N=== null ? 0 : +parseFloat(res.data[0].psup1_N).toFixed()     
                        })
                

                        if(parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PS").css('left', parseFloat(Math.abs(res.data[0].psup1_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                          
                          
                        //Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-D").text(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-D").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-D").css('left', parseFloat(Math.abs(res.data[0].direct3_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal and External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-IE").text(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-IE").css('left', parseFloat(Math.abs(res.data[0].IE997_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Internal Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-I").text(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-I").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-I").css('left', parseFloat(Math.abs(res.data[0].internal4_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-E").text(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-E").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-E").css('left', parseFloat(Math.abs(res.data[0].external5_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                      

                        // Self
                        $(".custom-bar-graph-`+res.Elements.Trait+`-S").removeClass("d-none")
                        $(".span-`+res.Elements.Trait+`-value-S").text(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))


                        if(parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-S").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-S").css('left', parseFloat(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers
                        $(".span-`+res.Elements.Trait+`-value-P").text(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1))

                        if(parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-P").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-P").css('left', parseFloat(Math.abs(res.data[0].peer2_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        //Peers and Direct Reports
                        $(".span-`+res.Elements.Trait+`-value-PD").text(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PD").css('left', parseFloat(Math.abs(res.data[0].PD996_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //Peers, Direct Reports, Internal Stakeholders, External Stakeholders
                        $(".span-`+res.Elements.Trait+`-value-PDIE").text(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1))
                        if(parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-PDIE").css('left', parseFloat(Math.abs(res.data[0].PDIE998_` + res.Elements.Trait + `).toFixed(1) - 2) * 12.5 + '%')
                        }

                        //All Nominees
                        $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                        if(parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1)) < 1) {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', '-1%')
                        }
                        else {
                          $(".position-`+res.Elements.Trait+`-Others").css('left', parseFloat(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1) - 2) * 12.5 + '%')
                        }
                        

                        // Average Others
                        $(".custom-bar-graph-`+res.Elements.Trait+`-Others").removeClass("d-none")
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(avgRollup`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').css('color','rgb(84,130,53)')
                          // End of Display - Emil



                        // Self
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].ind_` + res.Elements.Trait + `).toFixed(1))
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + res.Elements.Trait + `).toFixed(1))

                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".my-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].ind_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})

                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-bar").css({ "flex-grow": ` + '`' + 'calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '})' + '`' + `})
                        $(".cohort-sub-bar-chart-SELF-` + res.Elements.Trait + `-white-space").css({ "flex-grow": ` + '`' + 'calc( 100 - calc( 10 * ${res.data[0].cohort_self0_' + `` + res.Elements.Trait + `` + '}))' + '`' + `})
                        
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        }

                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                      }

                      if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $(".sub-bar-chart-P-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-D-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-I-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-E-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-PD-`+res.Elements.Trait+`").css("display", "none")
                          $(".sub-bar-chart-IE-`+res.Elements.Trait+`").css("display", "none")
                        } else {
                          $(".sub-bar-chart-PDIE-`+res.Elements.Trait+`").css("display", "none")
                        }

                        
                        if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '8')
                              } 
                              if((res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) && (res.data[0].internal4_N > 1 && res.data[0].external5_N > 1)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].peer2_N > 1 && res.data[0].direct3_N > 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '7')
                              }
                              if((res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)){
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '6')
                              }

                              if ((res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) && (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                              if ((res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) && (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2)) {
                                $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }

                              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                              $(".sub-chart-rowspan[rowspan]").attr('rowspan', '5')
                              }
                      `
                  }
                  output += `
                    if(`+ ORGID +` == 427) {
                      console.log('if ORGID')
                    `
                    for (const res of obj) {
                          output += `
                          let AllNomAvg` + res.Elements.Trait + ` = parseFloat(Math.abs(res.data[0].all_nom999_` + res.Elements.Trait + `).toFixed(1))
                          $(".span-`+res.Elements.Trait+`-value-Others").text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          $('.averageOthers-`+res.Elements.Trait+`').text(Math.abs(AllNomAvg`+ res.Elements.Trait +`).toFixed(1))
                          `
                    }
                  output += `
                    console.log('AllNomCount : ', res.data[0].all_nom999_N)
                    }
                  `
                  output += `
                    $('.360_sub').removeClass('d-none')
                    $('.360_sub_spinner').addClass('d-none')
                  `
                }

                if (data.Template === 'Radar-Chart-360-Self') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    output += `
                    function getClimateDataset(){
                      var dataset = [];

                    let Self = 
                            {
                              label: '`+ res.label1 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(184, 113, 26, 0.2)',
                              borderColor: 'rgb(184, 113, 26)',
                              pointBackgroundColor: 'rgb(184, 113, 26)',
                              pointBorderColor: 'rgb(184, 113, 26)',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(184, 113, 26)',
                      }
                      //end of self
                      
                    let Psup = 
                            {
                              label: '`+ res.label2 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].psup1_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(140,233,154, 0.2)',
                              borderColor: 'rgb(140,233,154)',
                              pointBackgroundColor: 'rgb(140,233,154)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(54, 162, 235)',
                      }
                      //end of Psup

                      let Peers = 
                            {
                              label: '`+ res.label3 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].peer2_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113, 0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of Peers

                      let DirectReport = 
                            {
                              label: '`+ res.label4 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].direct3_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(164, 99, 216, 0.2)',
                              borderColor: 'rgb(164, 99, 216)',
                              pointBackgroundColor: 'rgb(164, 99, 216)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of DirectReport

                      let PeersDirectReport = 
                            {
                              label: '`+ res.label7 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].PD996_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113,0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of PeersDirectReport

                      let Internal = 
                            {
                              label: '`+ res.label5 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].internal4_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(64, 164, 217,0.2)',
                              borderColor: 'rgb(64, 164, 217)',
                              pointBackgroundColor: 'rgb(64, 164, 217)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of Internal

                      let External = 
                            {
                              label: '`+ res.label6 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].external5_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(220, 55, 56,0.2)',
                              borderColor: 'rgb(220, 55, 56)',
                              pointBackgroundColor: 'rgb(220, 55, 56)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of External
                      
                      let InternalExternal = 
                            {
                              label: '`+ res.label8 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].IE997_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(64, 164, 217,0.2)',
                              borderColor: 'rgb(64, 164, 217)',
                              pointBackgroundColor: 'rgb(64, 164, 217)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of InternalExternal
                      
                      let PDIE = 
                            {
                              label: '`+ res.label9 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].PDIE998_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113, 0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of PDIE

                      // condition starts here 

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            dataset.splice(2,1);
                            dataset.splice(3,1);
                            dataset.splice(4,1);
                            dataset.splice(5,1);
                            dataset.splice(6,0,PeersDirectReport);
                            dataset.splice(7,1);
                            dataset.splice(8,1);
                          } else {
                            dataset.splice(2,0,Peers);
                            dataset.splice(3,0,DirectReport);
                            dataset.splice(4,1);
                            dataset.splice(5,1);
                            dataset.splice(6,1);
                            dataset.splice(7,1);
                            dataset.splice(8,1);
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            dataset.splice(2,1);
                            dataset.splice(3,1);
                            dataset.splice(4,1);
                            dataset.splice(5,1);
                            dataset.splice(6,1);
                            dataset.splice(7,0,InternalExternal);
                            dataset.splice(8,1);
                          } else {
                            dataset.splice(2,1);
                            dataset.splice(3,1);
                            dataset.splice(4,0,Internal);
                            dataset.splice(5,0,External);
                            dataset.splice(6,1);
                            dataset.splice(7,1);
                            dataset.splice(8,1);
                          }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            dataset = []
                            dataset.splice(0,0,Self);
                            dataset.splice(1,0,Psup);
                            dataset.splice(2,1);
                            dataset.splice(3,1);
                            dataset.splice(4,1);
                            dataset.splice(5,1);
                            dataset.splice(6,1);
                            dataset.splice(7,1);
                            dataset.splice(8,0,PDIE);
                          } else {
                            dataset.splice(0,0,Self);
                            dataset.splice(1,0,Psup);
                          }
                            $('.radar_head').removeClass('d-none')
                            $('.radarchart_head_spinner').addClass('d-none')  
                        return dataset;
                      }

                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                        type: 'radar',
                        data: {
                          labels: [
                            `
                              res.Elements.forEach((response) => {
                                output += `
                                  "`+response.Description+`",
                            `
                          })
                    output += `
                                  ],
                          datasets: getClimateDataset (),
                        },
                        options: {
                          scales: {
                            r: {
                              beginAtZero: true,
                              min: 0,
                              max: 5,
                              ticks: {
                              stepSize: 1,
                              }
                            },
                          }
                        }
                      })
                    `
                  }
                }
                if (data.Template === 'Radar-Chart-360-Cohort') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                      
                    output += `
                    function getClimatecohortDataset(){
                      var cohortdataset = [];

                    let cohortSelf = 
                            {
                              label: '`+ res.label1 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                              Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(184, 113, 26, 0.2)',
                              borderColor: 'rgb(184, 113, 26)',
                              pointBackgroundColor: 'rgb(184, 113, 26)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of self
                      
                    let cohortPsup = 
                            {
                              label: '`+ res.label2 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_psup1_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(140,233,154, 0.2)',
                              borderColor: 'rgb(140,233,154)',
                              pointBackgroundColor: 'rgb(140,233,154)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(54, 162, 235)',
                      }
                      //end of Psup

                      let cohortPeers = 
                            {
                              label: '`+ res.label3 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_peer2_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113, 0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of Peers

                      let cohortDirectReport = 
                            {
                              label: '`+ res.label4 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_direct3_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(164, 99, 216, 0.2)',
                              borderColor: 'rgb(164, 99, 216)',
                              pointBackgroundColor: 'rgb(164, 99, 216)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of DirectReport

                      let cohortPeersDirectReport = 
                            {
                              label: '`+ res.label7 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_PD996_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113,0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of cohortPeersDirectReport

                      let cohortInternal = 
                            {
                              label: '`+ res.label5 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_internal4_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(64, 164, 217,0.2)',
                              borderColor: 'rgb(64, 164, 217)',
                              pointBackgroundColor: 'rgb(64, 164, 217)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of Internal

                      let cohortExternal = 
                            {
                              label: '`+ res.label6 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_external5_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(220, 55, 56,0.2)',
                              borderColor: 'rgb(220, 55, 56)',
                              pointBackgroundColor: 'rgb(220, 55, 56)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of External
                      
                      let cohortInternalExternal = 
                            {
                              label: '`+ res.label8 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_IE997_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(64, 164, 217,0.2)',
                              borderColor: 'rgb(64, 164, 217)',
                              pointBackgroundColor: 'rgb(64, 164, 217)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
              }
                      //end of cohortInternalExternal
                      
                      let cohortPDIE = 
                            {
                              label: '`+ res.label9 +`',
                              data: [
                    `
                            res.Elements.forEach((response) => {
                              output += `
                                Math.abs(res.data[0].cohort_PDIE998_` + response.Trait + `).toFixed(1),
                              `
                            })
                    output+=`  
                              ],
                              fill: true,
                              backgroundColor: 'rgba(14, 80, 113, 0.2)',
                              borderColor: 'rgb(14, 80, 113)',
                              pointBackgroundColor: 'rgb(14, 80, 113)',
                              pointBorderColor: '#fff',
                              pointHoverBackgroundColor: '#fff',
                              pointHoverBorderColor: 'rgb(255, 99, 132)',
                      }
                      //end of PDIE

                      // condition starts here 

                        if(res.data[0].peer2_N === null){
                          res.data[0].peer2_N = 0
                        }
                        if(res.data[0].direct3_N === null){
                          res.data[0].direct3_N = 0
                        }
                        if(res.data[0].PD996_N === null){
                          res.data[0].PD996_N = 0
                        }
                        if(res.data[0].internal4_N === null){
                          res.data[0].internal4_N = 0
                        }
                        if(res.data[0].external5_N === null){
                          res.data[0].external5_N = 0
                        }
                        if(res.data[0].IE997_N === null){
                          res.data[0].IE997_N = 0
                        }
                        if(res.data[0].PDIE998_N === null){
                          res.data[0].PDIE998_N = 0
                        }

                        if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                            cohortdataset.splice(2,1);
                            cohortdataset.splice(3,1);
                            cohortdataset.splice(4,1);
                            cohortdataset.splice(5,1);
                            cohortdataset.splice(6,0,cohortPeersDirectReport);
                            cohortdataset.splice(7,1);
                            cohortdataset.splice(8,1);
                          } else {
                            cohortdataset.splice(2,0,cohortPeers);
                            cohortdataset.splice(3,0,cohortDirectReport);
                            cohortdataset.splice(4,1);
                            cohortdataset.splice(5,1);
                            cohortdataset.splice(6,1);
                            cohortdataset.splice(7,1);
                            cohortdataset.splice(8,1);
                          }

                        if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                            cohortdataset.splice(2,1);
                            cohortdataset.splice(3,1);
                            cohortdataset.splice(4,1);
                            cohortdataset.splice(5,1);
                            cohortdataset.splice(6,1);
                            cohortdataset.splice(7,0,cohortInternalExternal);
                            cohortdataset.splice(8,1);
                          } else {
                            cohortdataset.splice(2,1);
                            cohortdataset.splice(3,1);
                            cohortdataset.splice(4,0,cohortInternal);
                            cohortdataset.splice(5,0,cohortExternal);
                            cohortdataset.splice(6,1);
                            cohortdataset.splice(7,1);
                            cohortdataset.splice(8,1);
                          }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                            cohortdataset = []
                            cohortdataset.splice(0,0,cohortSelf);
                            cohortdataset.splice(1,0,cohortPsup);
                            cohortdataset.splice(2,1);
                            cohortdataset.splice(3,1);
                            cohortdataset.splice(4,1);
                            cohortdataset.splice(5,1);
                            cohortdataset.splice(6,1);
                            cohortdataset.splice(7,1);
                            cohortdataset.splice(8,0,cohortPDIE);
                          } else {
                            cohortdataset.splice(0,0,cohortSelf);
                            cohortdataset.splice(1,0,cohortPsup);
                          }
                          $('.radar_cohort').removeClass('d-none')
                          $('.radarchart_cohort_spinner').addClass('d-none')
                        return cohortdataset;
                      }
                      let ` + res.SuperTrait + `` + res.Title + `Select = document.querySelectorAll('.` + res.SuperTrait + `` + res.Title + `')
                      ` + res.SuperTrait + `` + res.Title + `_Chart = new Chart(` + res.SuperTrait + `` + res.Title + `Select, {
                        type: 'radar',
                        data: {
                          labels: [
                            `
                              res.Elements.forEach((response) => {
                                output += `
                                  "`+response.Description+`",
                            `
                          })
                    output += `
                                  ],
                          datasets: getClimatecohortDataset (),
                        },
                        options: {
                          scales: {
                            r: {
                              beginAtZero: true,
                              min: 0,
                              max: 5,
                              ticks: {
                              stepSize: 1,
                              }
                            },
                          }
                        }
                      })
                    `
                  }
                }
                if (data.Template === 'Table-360-Competency') { 
                  let obj = JSON.parse(data.Text)
                  output += `
                    if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }

                      if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                          $('.heading-label-CII-peers').addClass('d-none')
                          $('.sub-heading-score-CII-peers').addClass('d-none')
                          $('.heading-label-CII-direct').addClass('d-none')
                          $('.sub-heading-score-CII-direct').addClass('d-none')
                        } else {
                          $('.heading-label-CII-pd').addClass('d-none')
                          $('.sub-heading-score-CII-pd').addClass('d-none')
                        }

                      if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                          $('.heading-label-CII-internal').addClass('d-none')
                          $('.sub-heading-score-CII-internal').addClass('d-none')
                          $('.heading-label-CII-external').addClass('d-none')
                          $('.sub-heading-score-CII-external').addClass('d-none')
                        } else {
                          $('.heading-label-CII-ie').addClass('d-none')
                          $('.sub-heading-score-CII-ie').addClass('d-none')
                        }

                        
                      if (res.data[0].peer2_N < 1 && res.data[0].direct3_N < 1) {
                        $('.heading-label-CII-pd').addClass('d-none')
                        $('.sub-heading-score-CII-pd').addClass('d-none')
                      }
                      if (res.data[0].internal4_N < 1 && res.data[0].external5_N < 1) {
                        $('.heading-label-CII-ie').addClass('d-none')
                        $('.sub-heading-score-CII-ie').addClass('d-none')
                      }

                        if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                          $('.heading-label-CII-peers').addClass('d-none')
                          $('.sub-heading-score-CII-peers').addClass('d-none')
                          $('.heading-label-CII-direct').addClass('d-none')
                          $('.sub-heading-score-CII-direct').addClass('d-none')
                          $('.heading-label-CII-internal').addClass('d-none')
                          $('.sub-heading-score-CII-internal').addClass('d-none')
                          $('.heading-label-CII-external').addClass('d-none')
                          $('.sub-heading-score-CII-external').addClass('d-none')
                          $('.heading-label-CII-pd').addClass('d-none')
                          $('.sub-heading-score-CII-pd').addClass('d-none')
                          $('.heading-label-CII-ie').addClass('d-none')
                          $('.sub-heading-score-CII-ie').addClass('d-none')
                        } else {
                          $('.heading-label-CII-pdie').addClass('d-none')
                          $('.sub-heading-score-CII-pdie').addClass('d-none')
                        } 
                        
                      //VARIABLES
                      function processOrdinalLevels(dataArray) {
                        // Check if the array contains only null values
                        if (dataArray.every((value) => value === null)) {
                          return Array(dataArray.length).fill('N/A');
                        }

                        // Count the occurrences of each number in the array
                        const numberCounts = {};
                        for (const num of dataArray) {
                          if (num in numberCounts) {
                            numberCounts[num]++;
                          } else {
                            numberCounts[num] = 1;
                          }
                        }

                        // Create a sorted array of unique numbers in ascending order
                        const uniqueSortedNumbers = Array.from(new Set(dataArray)).sort((a, b) => a - b);

                        // Create a mapping of numbers to their levels based on their order
                        const numberToLevel = {};
                        let currentLevel = 1;

                        for (const num of uniqueSortedNumbers) {
                          numberToLevel[num] = currentLevel;
                          currentLevel += numberCounts[num];
                        }

                        // Function to add the appropriate ordinal suffix or replace with "N/A" for null values
                        function addOrdinalSuffix(num) {
                          if (num === null) return 'N/A'; // Replace null with "N/A"
                          if (num === 1) return '1st';
                          if (num === 2) return '2nd';
                          if (num === 3) return '3rd';
                          return num + 'th';
                        }

                        // Assign levels with correct ordinal suffix or "N/A" for null values to the original array
                        const levelsAsOrdinals = dataArray.map((num) => addOrdinalSuffix(numberToLevel[num]));

                        // Count the occurrences of each ordinal level
                        const levelCounts = {};

                        for (const level of levelsAsOrdinals) {
                          if (levelCounts[level]) {
                            levelCounts[level]++;
                          } else {
                            levelCounts[level] = 1;
                          }
                        }

                        // Replace all "1st" with "=1st" if there are more than one "1st"
                        const firstCount = levelCounts['1st'];
                        if (firstCount > 1) {
                          for (let j = 0; j < levelsAsOrdinals.length; j++) {
                            if (levelsAsOrdinals[j] === '1st') {
                              levelsAsOrdinals[j] = '=1st';
                            }
                          }
                        }

                        // Replace ordinal levels if they occur more than once up to 20th
                        for (let i = 1; i <= 20; i++) {
                          const ordinal = i + 'th';
                          if (levelCounts[ordinal] > 1) {
                            for (let j = 0; j < levelsAsOrdinals.length; j++) {
                              if (levelsAsOrdinals[j] === ordinal) {
                                levelsAsOrdinals[j] = '=' + ordinal;
                              }
                            }
                          }
                        }

                        return levelsAsOrdinals;
                      }

                    let psupArray = []
                    let indArray = []
                    let peerArray = []
                    let directArray = []
                    let internalArray = []
                    let externalArray = []
                    let pdArray = []
                    let ieArray = []
                    let pdieArray = []
                    let allnomArray = []
                    let cohortallArray = []
                    `
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += 
                      `
                      psupArray.push(res.data[0].psup1_` + response.Trait + `)
                      indArray.push(res.data[0].ind_` + response.Trait + `)
                      peerArray.push(res.data[0].peer2_` + response.Trait + `)
                      directArray.push(res.data[0].direct3_` + response.Trait + `)
                      internalArray.push(res.data[0].internal4_` + response.Trait + `)
                      externalArray.push(res.data[0].external5_` + response.Trait + `)
                      pdArray.push(res.data[0].PD996_` + response.Trait + `)
                      ieArray.push(res.data[0].IE997_` + response.Trait + `)
                      pdieArray.push(res.data[0].PDIE998_` + response.Trait + `)
                      allnomArray.push(res.data[0].all_nom999_` + response.Trait + `)
                      cohortallArray.push(res.data[0].cohort_all_1000_` + response.Trait + `)

                        if(res.data[0].ind_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                            $(".competency-ind_` + response.Trait + `").html("N/A")
                        }
                        else{
                            $(".competency-ind_` + response.Trait + `").html(ordinal(Math.round(res.data[0].ind_` + response.Trait + `)))
                        }
                        if(res.data[0].psup1_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-psup1` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-psup1` + response.Trait + `").html(ordinal(Math.round(res.data[0].psup1_` + response.Trait + `)))
                        }
                        if(res.data[0].peer2_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-peer2_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-peer2_` + response.Trait + `").html(ordinal(Math.round(res.data[0].peer2_` + response.Trait + `)))
                        }
                        if(res.data[0].direct3_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-direct3_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-direct3_` + response.Trait + `").html(ordinal(Math.round(res.data[0].direct3_` + response.Trait + `)))
                        }
                        if(res.data[0].PD996_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-PD996_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-PD996_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PD996_` + response.Trait + `)))
                        }
                        if(res.data[0].internal4_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-internal4_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-internal4_` + response.Trait + `").html(ordinal(Math.round(res.data[0].internal4_` + response.Trait + `)))
                        }
                        if(res.data[0].external5_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-external5_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-external5_` + response.Trait + `").html(ordinal(Math.round(res.data[0].external5_` + response.Trait + `)))
                        }
                        if(res.data[0].IE997_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-IE997_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-IE997_` + response.Trait + `").html(ordinal(Math.round(res.data[0].IE997_` + response.Trait + `)))
                        }
                        if(res.data[0].PDIE998_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-PDIE998_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-PDIE998_` + response.Trait + `").html(ordinal(Math.round(res.data[0].PDIE998_` + response.Trait + `)))
                        }
                        if(res.data[0].all_nom999_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-all_nom999_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-all_nom999_` + response.Trait + `").html(ordinal(Math.round(res.data[0].all_nom999_` + response.Trait + `)))
                        }
                        if(res.data[0].cohort_all_1000_` + response.Trait + ` === null || res.data[0].ind_` + response.Trait + ` == 0){
                          $(".competency-cohort_all_1000_` + response.Trait + `").html("N/A")
                        }
                        else{
                          $(".competency-cohort_all_1000_` + response.Trait + `").html(ordinal(Math.round(res.data[0].cohort_all_1000_` + response.Trait + `)))
                        }
                      `
                    }) 
                  }
                  output += `
                      let newPsupArr = processOrdinalLevels(psupArray)
                      let newIndArr = processOrdinalLevels(indArray)
                      let newPeerArr = processOrdinalLevels(peerArray)
                      let newDirectArr = processOrdinalLevels(directArray)
                      let newInternalArr = processOrdinalLevels(internalArray)
                      let newExternalArr = processOrdinalLevels(externalArray)
                      let newPDArr = processOrdinalLevels(pdArray)
                      let newIEArr = processOrdinalLevels(ieArray)
                      let newPDIEArr = processOrdinalLevels(pdieArray)
                      let newAllNomArr = processOrdinalLevels(allnomArray)
                      let newCohortAllArr = processOrdinalLevels(cohortallArray)
                      `
                      for (const res1 of obj) {
                    res1.Elements.forEach((response, index) => {
                      output += 
                      `
                       $(".competency-ind_` + response.Trait + `").html(newIndArr[${index}])
                       $(".competency-psup1` + response.Trait + `").html(newPsupArr[${index}])
                       $(".competency-peer2_` + response.Trait + `").html(newPeerArr[${index}])
                       $(".competency-direct3_` + response.Trait + `").html(newDirectArr[${index}])
                       $(".competency-internal4_` + response.Trait + `").html(newInternalArr[${index}])
                       $(".competency-external5_` + response.Trait + `").html(newExternalArr[${index}])
                       $(".competency-PD996_` + response.Trait + `").html(newPDArr[${index}])
                       $(".competency-IE997_` + response.Trait + `").html(newIEArr[${index}])
                       $(".competency-PDIE998_` + response.Trait + `").html(newPDIEArr[${index}])
                       $(".competency-all_nom999_` + response.Trait + `").html(newAllNomArr[${index}])
                       $(".competency-cohort_all_1000_` + response.Trait + `").html(newCohortAllArr[${index}])
                       `
                    })
                  }
                  output += `
                    $('.table360-competency').removeClass('d-none')
                    $('.competency_spinner').addClass('d-none')
                  `
                }
                if (data.Template === 'Bar-Chart-360-Highest') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += 
                      `
                      if(`+ ORGID +` == 427) {
                        $(".highest-all_nom999_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                        $(".highest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".highest-all_nom999_cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_all_nom999_` + response.Trait + `).toFixed(1))
                        $(".highest-all_nom999_cohort_self_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                      }
                      else {
                        $(".highest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                        $(".highest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".highest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                        $(".highest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                      }
                      `
                    })
                    output +=`
                      $('.highest-sortme').sort(function(a, b) {
                        if (a.textContent > b.textContent) {
                            return -1;
                        } else {
                            return 1;
                        }
                      }).appendTo($('.highest-wrapper'));

                      const highestStartLength = 5
                      const highestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                      $('.highest-sortme').slice(highestStartLength, highestMaxLength).hide();
                      $('.highest-averageScore').hide()

                      $('.highest_table').removeClass('d-none')
                      $('.highest_spinner').addClass('d-none')
                    `
                  }
                }
                if (data.Template === 'Bar-Chart-360-Lowest') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += 
                      `
                      if(`+ ORGID +` == 427) {
                        $(".lowest-all_nom999_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                        $(".lowest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".lowest-all_nom999_cohort_` + response.Trait + `").html(Math.abs(res.data[0].cohort_all_nom999_` + response.Trait + `).toFixed(1))
                        $(".lowest-all_nom999_cohort_self_` + response.Trait + `").html(Math.abs(res.data[0].cohort_self0_` + response.Trait + `).toFixed(1))
                      }
                      else {
                        $(".lowest-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                        $(".lowest-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".lowest-all_nom999_cohort_` + response.Trait + `").html(cohortavgRollup` + response.Trait + `)
                        $(".lowest-all_nom999_cohort_self_` + response.Trait + `").html(cohortSelfAvgRollup` + response.Trait + `)
                      }
                      `
                    })
                    output +=`
                      $('.lowest-sortme').sort(function(a, b) {
                        if (a.textContent < b.textContent) {
                            return -1;
                        } else {
                            return 1;
                        }
                      }).appendTo($('.lowest-wrapper'));

                      const lowestStartLength = 5
                      const lowestMaxLength = `+res.Elements.length+` //res.Elements.length eg. 36
                      $('.lowest-sortme').slice(lowestStartLength, lowestMaxLength).hide();
                      $('.lowest-averageScore').hide()

                      $('.lowest_table').removeClass('d-none')
                      $('.lowest_spinner').addClass('d-none')
                    `
                  }
                }
                // hide the cohort in section 9, 10 and section 11 if cohort data is not selected in report builder
                if (data.Template === 'Bar-Chart-360-Highest-Lowest-NoCohort') {
                  output += `
                    $('.section9-cohort-data').addClass('d-none')
                    $('.section10-cohort-data').addClass('d-none')
                    $('.section11-cohort-data').addClass('d-none')

                    $(".section3-8-cohort-data").css("background-color", "#fff")

                    const showBarChart = localStorage.getItem("button-state")
                    if(showBarChart == "off") {
                      $(".toggle-bar-chart-text").text('Show Bar Chart')
                    }
                    localStorage.setItem("hasCohort", "no");
                  `
                }
                if (data.Template === 'Table-360-Overestimated') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += 
                      `
                      if(`+ ORGID +` == 427) {
                        if(res.data[0].ind_` + response.Trait + ` == 0){
                          $(".overestimated-row` + response.Trait + `").css("display","none")
                        }
                          $(".overestimated-all_nom999_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                          $(".overestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".overestimated-difference` + response.Trait + `").html(Math.abs((res.data[0].all_nom999_` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                        if((res.data[0].all_nom999_` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) > -1.5 ){
                          $(".overestimated-row` + response.Trait + `").css("display","none")
                        }
                      }
                      else {
                        if(res.data[0].ind_` + response.Trait + ` == 0){
                          $(".overestimated-row` + response.Trait + `").css("display","none")
                        }
                          $(".overestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                          $(".overestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                          $(".overestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                        if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) > -1.5 ){
                          $(".overestimated-row` + response.Trait + `").css("display","none")
                        }
                      }
                      `
                    })
                    output +=`
                    overestimateChild = $('#overestimate-wrapper>tr:not([style*="display: none"]')
                    if(overestimateChild.length === 0){
                      $(".overestimated-none").removeClass('d-none')
                      $(".overestimated-row-head").addClass('d-none')
                    } else {
                      $(".overestimated-none").addClass('d-none')
                      $(".overestimated-row-head").removeClass('d-none')
                    }
                    overestimateChild.sort(function(a, b) {
                        return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                    }).appendTo($("#overestimate-wrapper"));
                    `
                  }
                }
                if (data.Template === 'Table-360-Underestimated') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += 
                      `
                      if(`+ ORGID +` == 427) {
                        if(res.data[0].ind_` + response.Trait + ` == 0){
                          $(".underestimated-row` + response.Trait + `").css("display","none")
                        }
                        $(".underestimated-all_nom999_` + response.Trait + `").html(Math.abs(res.data[0].all_nom999_` + response.Trait + `).toFixed(1))
                        $(".underestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".underestimated-difference` + response.Trait + `").html(Math.abs((res.data[0].all_nom999_` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                        if((res.data[0].all_nom999_` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) < 1.5 ){
                          $(".underestimated-row` + response.Trait + `").css("display","none")
                          }
                      } else {
                        if(res.data[0].ind_` + response.Trait + ` == 0){
                          $(".underestimated-row` + response.Trait + `").css("display","none")
                        }
                        $(".underestimated-all_nom999_` + response.Trait + `").html(avgRollup` + response.Trait + `)
                        $(".underestimated-ind_` + response.Trait + `").html(Math.abs(res.data[0].ind_` + response.Trait + `).toFixed(1))
                        $(".underestimated-difference` + response.Trait + `").html(Math.abs((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `)).toFixed(1))
                        if((avgRollup` + response.Trait + `)-(res.data[0].ind_` + response.Trait + `) < 1.5 ){
                          $(".underestimated-row` + response.Trait + `").css("display","none")
                          }
                      }
                      `
                    })
                    output +=`
                    underestimateChild = $('#underestimate-wrapper>tr:not([style*="display: none"]')
                    if(underestimateChild.length === 0){
                      $(".underestimated-none").removeClass('d-none')
                      $(".underestimated-row-head").addClass('d-none')
                    } else {
                      $(".underestimated-none").addClass('d-none')
                      $(".underestimated-row-head").removeClass('d-none')
                    }
                    underestimateChild.sort(function(a, b) {
                        return $('td:last', b).children().last().text().localeCompare($('td:last', a).children().last().text());
                    }).appendTo($("#underestimate-wrapper"));
                    `
                  }
                }
                if (data.Template === 'Dynamic-Message-Head') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                      output += 
                      `
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }

                      if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                      } else {
                        $(".Head-Dynamic-Message").html("` + res.Message + `")
                      }
                      `
                  }
                }
                if (data.Template === 'Dynamic-Message-Sub') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                      output += 
                      `
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }

                      if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                      } else {
                        $(".Sub-Dynamic-Message").html("` + res.Message + `")
                      }
                      `
                  }
                }
                if (data.Template === 'text-important-note') { 
                  // let obj = JSON.parse(data.Text)
                  // for (const res of obj) {
                      output += 
                      `
                      if(res.data[0].peer2_N === null){
                        res.data[0].peer2_N = 0
                      }
                      if(res.data[0].direct3_N === null){
                        res.data[0].direct3_N = 0
                      }
                      if(res.data[0].PD996_N === null){
                        res.data[0].PD996_N = 0
                      }
                      if(res.data[0].IE997_N === null){
                        res.data[0].IE997_N = 0
                      }
                      if(res.data[0].PDIE998_N === null){
                        res.data[0].PDIE998_N = 0
                      }
                      if(res.data[0].internal4_N === null){
                        res.data[0].internal4_N = 0
                      }
                      if(res.data[0].external5_N === null){
                        res.data[0].external5_N = 0
                      }

                      if(res.data[0].peer2_N > 1 && res.data[0].direct3_N> 1 && res.data[0].internal4_N > 1 && res.data[0].external5_N > 1){
                      } else {
                        $(".Head-Dynamic-Message").html("` + data.Text + `")
                      }
                      `
                  // }
                }

                
              }

              output += `
                $(".f5-spinner").addClass("d-none")
                $(".f5-table").removeClass("d-none")
            }) 
          `
        }
      }

      // add histogram for 360 individual
      for(let i = 0; i < input.length; i++){
        const data = input[i];
        // part 1 of Histogram
        if(data.Template === 'Add-Histogram'){
          output += `
          axios
          .post(
            '${url}/r360-report-data2',
            {
              "survey_assignment_id": survey_assignment_id,
              "org_id": org_id,
              "suborg_id": subOrg_id,
              "program_id": program_id,
              "iteration_id": iteration_id
          },
            config                                            
          )
          .then((res) => {
          `
          // part 2 of Histogram
          if (data.Template === 'Add-Histogram') { 
            output += `
              function checkGroups(A, B, C, D) {
                const group1 = [A, B];
                const group2 = [C, D];

                const group1HasZeroAndOne = group1.includes(0) && group1.includes(1);
                const group2HasZeroAndOne = group2.includes(0) && group2.includes(1);
                const group1HasTwoZeros = group1.every(val => val === 0);
                const group2HasTwoZeros = group2.every(val => val === 0);

                return group1HasZeroAndOne || group2HasZeroAndOne || (group1HasTwoZeros && group2HasTwoZeros);
              }


              let avgCount = 1
              let avgCohortCount = 1
              let isDisplay_P = 0
              let isDisplay_D = 0
              let isDisplay_PD = 0
              let isDisplay_I = 0
              let isDisplay_E = 0
              let isDisplay_IE = 0
              let isDisplay_PDIE = 0

              if (res.data[0].peer2_N < 2 || res.data[0].direct3_N < 2) {
                avgCount = avgCount + 1
                avgCohortCount = avgCohortCount + 1
                isDisplay_PD = 1
              } else {
                isDisplay_P = 1
                isDisplay_D = 1
                avgCount = avgCount + 2
                avgCohortCount = avgCohortCount + 2
              }
              if (res.data[0].internal4_N < 2 || res.data[0].external5_N < 2) {
                avgCount = avgCount + 1
                avgCohortCount = avgCohortCount + 1
                isDisplay_IE = 1
              } else {
                avgCount = avgCount + 2
                avgCohortCount = avgCohortCount + 2
                isDisplay_I = 1
                isDisplay_E = 1
              }
              if(checkGroups(res.data[0].peer2_N, res.data[0].direct3_N, res.data[0].internal4_N, res.data[0].external5_N) == true){
                avgCount = 2
                avgCohortCount = 2
                isDisplay_PDIE = 1
              }

          axios
            .post(` + '`' + `${url}` + '/r360-histogram-report-endpoint/${survey_assignment_id}' + '`, {}, config)' + `
            .then((resHistogram) => {
              console.log(resHistogram.data)
            `
              for (let i = 0; i < input.length; i++) {
                const data = input[i]
                // part 3 of Histogram
                if (data.Template === 'Add-Histogram') { 
                  output += `
                    function removeElementsWithValue(arr, val) {
                        var i = arr.length;
                        while (i--) {
                            if (arr[i] === val) {
                                arr.splice(i, 1);
                            }
                        }
                        return arr;
                    }
                    function rankRange(num, arr) {
                      // remove duplicate values
                      let newArr = [...new Set(removeElementsWithValue(arr, 0))];
                      // sort array
                      newArr.sort((a, b) => a - b);
                      // find the index of the input number in the sorted array
                      let index = newArr.indexOf(num);
                      // calculate the rank as the index + 1
                      let rank = index + 1;
                      // calculate the range as a percentage of the rank divided by the length of the array
                      let range = (rank / newArr.length) * 100;
                      // return the range capped at 100
                      return Math.min(range, 100);
                    }

                    console.log('checkgroups')
                    console.log(checkGroups(1,2,3,4))
                    
                    console.log('removeElementsWithValue')
                    console.log(removeElementsWithValue([1,2,3,4,5], 1))
                    
                    console.log('rankRange')
                    console.log(rankRange(0, [1,2,3,4,5]))
                    






                  `
                }
                // part 4 of Histogram
                if (data.Template === 'Add-Histogram') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `
                      //individual array
                          let array`+ response.Trait +`_Self = []
                          let array`+ response.Trait +`_PSup = []
                          let array`+ response.Trait +`_P = []
                          let array`+ response.Trait +`_D = []
                          let array`+ response.Trait +`_I = []
                          let array`+ response.Trait +`_E = []
                          let array`+ response.Trait +`_PD = []
                          let array`+ response.Trait +`_IE = []
                          let array`+ response.Trait +`_PDIE = []
                          let array`+ response.Trait +`_Others = []
                          //cohort array
                          let arrayCohort`+ response.Trait +`_Self = []
                          let arrayCohort`+ response.Trait +`_PSup = []
                          let arrayCohort`+ response.Trait +`_P = []
                          let arrayCohort`+ response.Trait +`_D = []
                          let arrayCohort`+ response.Trait +`_I = []
                          let arrayCohort`+ response.Trait +`_E = []
                          let arrayCohort`+ response.Trait +`_PD = []
                          let arrayCohort`+ response.Trait +`_IE = []
                          let arrayCohort`+ response.Trait +`_PDIE = []
                          let arrayCohort`+ response.Trait +`_Others = []

                          // score 1
                          let score1`+ response.Trait +`_Self = 0
                          let score1`+ response.Trait +`_PSup = 0
                          let score1`+ response.Trait +`_P = 0
                          let score1`+ response.Trait +`_D = 0
                          let score1`+ response.Trait +`_I = 0
                          let score1`+ response.Trait +`_E = 0
                          let score1`+ response.Trait +`_PD = 0
                          let score1`+ response.Trait +`_IE = 0
                          let score1`+ response.Trait +`_PDIE = 0
                          let score1`+ response.Trait +`_Others = 0
                          // score 2
                          let score2`+ response.Trait +`_Self = 0
                          let score2`+ response.Trait +`_PSup = 0
                          let score2`+ response.Trait +`_P = 0
                          let score2`+ response.Trait +`_D = 0
                          let score2`+ response.Trait +`_I = 0
                          let score2`+ response.Trait +`_E = 0
                          let score2`+ response.Trait +`_PD = 0
                          let score2`+ response.Trait +`_IE = 0
                          let score2`+ response.Trait +`_PDIE = 0
                          let score2`+ response.Trait +`_Others = 0
                          // score 3
                          let score3`+ response.Trait +`_Self = 0
                          let score3`+ response.Trait +`_PSup = 0
                          let score3`+ response.Trait +`_P = 0
                          let score3`+ response.Trait +`_D = 0
                          let score3`+ response.Trait +`_I = 0
                          let score3`+ response.Trait +`_E = 0
                          let score3`+ response.Trait +`_PD = 0
                          let score3`+ response.Trait +`_IE = 0
                          let score3`+ response.Trait +`_PDIE = 0
                          let score3`+ response.Trait +`_Others = 0
                          // score 4
                          let score4`+ response.Trait +`_Self = 0
                          let score4`+ response.Trait +`_PSup = 0
                          let score4`+ response.Trait +`_P = 0
                          let score4`+ response.Trait +`_D = 0
                          let score4`+ response.Trait +`_I = 0
                          let score4`+ response.Trait +`_E = 0
                          let score4`+ response.Trait +`_PD = 0
                          let score4`+ response.Trait +`_IE = 0
                          let score4`+ response.Trait +`_PDIE = 0
                          let score4`+ response.Trait +`_Others = 0
                          // score 5
                          let score5`+ response.Trait +`_Self = 0
                          let score5`+ response.Trait +`_PSup = 0
                          let score5`+ response.Trait +`_P = 0
                          let score5`+ response.Trait +`_D = 0
                          let score5`+ response.Trait +`_I = 0
                          let score5`+ response.Trait +`_E = 0
                          let score5`+ response.Trait +`_PD = 0
                          let score5`+ response.Trait +`_IE = 0
                          let score5`+ response.Trait +`_PDIE = 0
                          let score5`+ response.Trait +`_Others = 0

                          // cohort score 1
                          let cohortScore1`+ response.Trait +`_Self = 0
                          let cohortScore1`+ response.Trait +`_PSup = 0
                          let cohortScore1`+ response.Trait +`_P = 0
                          let cohortScore1`+ response.Trait +`_D = 0
                          let cohortScore1`+ response.Trait +`_I = 0
                          let cohortScore1`+ response.Trait +`_E = 0
                          let cohortScore1`+ response.Trait +`_PD = 0
                          let cohortScore1`+ response.Trait +`_IE = 0
                          let cohortScore1`+ response.Trait +`_PDIE = 0
                          let cohortScore1`+ response.Trait +`_Others = 0
                          // cohort score 2
                          let cohortScore2`+ response.Trait +`_Self = 0
                          let cohortScore2`+ response.Trait +`_PSup = 0
                          let cohortScore2`+ response.Trait +`_P = 0
                          let cohortScore2`+ response.Trait +`_D = 0
                          let cohortScore2`+ response.Trait +`_I = 0
                          let cohortScore2`+ response.Trait +`_E = 0
                          let cohortScore2`+ response.Trait +`_PD = 0
                          let cohortScore2`+ response.Trait +`_IE = 0
                          let cohortScore2`+ response.Trait +`_PDIE = 0
                          let cohortScore2`+ response.Trait +`_Others = 0
                          // cohort score 3
                          let cohortScore3`+ response.Trait +`_Self = 0
                          let cohortScore3`+ response.Trait +`_PSup = 0
                          let cohortScore3`+ response.Trait +`_P = 0
                          let cohortScore3`+ response.Trait +`_D = 0
                          let cohortScore3`+ response.Trait +`_I = 0
                          let cohortScore3`+ response.Trait +`_E = 0
                          let cohortScore3`+ response.Trait +`_PD = 0
                          let cohortScore3`+ response.Trait +`_IE = 0
                          let cohortScore3`+ response.Trait +`_PDIE = 0
                          let cohortScore3`+ response.Trait +`_Others = 0
                          // cohort score 4
                          let cohortScore4`+ response.Trait +`_Self = 0
                          let cohortScore4`+ response.Trait +`_PSup = 0
                          let cohortScore4`+ response.Trait +`_P = 0
                          let cohortScore4`+ response.Trait +`_D = 0
                          let cohortScore4`+ response.Trait +`_I = 0
                          let cohortScore4`+ response.Trait +`_E = 0
                          let cohortScore4`+ response.Trait +`_PD = 0
                          let cohortScore4`+ response.Trait +`_IE = 0
                          let cohortScore4`+ response.Trait +`_PDIE = 0
                          let cohortScore4`+ response.Trait +`_Others = 0
                          // cohort score 5
                          let cohortScore5`+ response.Trait +`_Self = 0
                          let cohortScore5`+ response.Trait +`_PSup = 0
                          let cohortScore5`+ response.Trait +`_P = 0
                          let cohortScore5`+ response.Trait +`_D = 0
                          let cohortScore5`+ response.Trait +`_I = 0
                          let cohortScore5`+ response.Trait +`_E = 0
                          let cohortScore5`+ response.Trait +`_PD = 0
                          let cohortScore5`+ response.Trait +`_IE = 0
                          let cohortScore5`+ response.Trait +`_PDIE = 0
                          let cohortScore5`+ response.Trait +`_Others = 0

                          for (let i=0; i<resHistogram.data.length; i++){
                            // get the score 1
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_P = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_P = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_D = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_D = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_I = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_I = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_E = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_E = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score1`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 1) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore1`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }

                            // get the score 2
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_P = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_P = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_D = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_D = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_I = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_I = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_E = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_E = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score2`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 2) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore2`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }

                            // get the score 3
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_P = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_P = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_D = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_D = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_I = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_I = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_E = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_E = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score3`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 3) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore3`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }

                            // get the score 4
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_P = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_P = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_D = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_D = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_I = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_I = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_E = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_E = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score4`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 4) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore4`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }

                            // get the score 5
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_Self = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            } if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_PSup = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_P = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_P = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_D = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_D = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_I = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_I = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_E = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_E = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_PD = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_IE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_PDIE = resHistogram.data[i].qty
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              score5`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }if((resHistogram.data[i].element === '`+ response.Trait +`' && resHistogram.data[i].score == 5) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              cohortScore5`+ response.Trait +`_Others = resHistogram.data[i].qty
                            }

                          //put data into array
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_Self.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_Self.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_PSup.push(resHistogram.data[i].qty)
                            } if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_PSup.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_P.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_P.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_D.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_D.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_I.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_I.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_E.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_E.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_PD.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_PD.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_IE.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_IE.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_PDIE.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_PDIE.push(resHistogram.data[i].qty)
                            }
                            if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                              array`+ response.Trait +`_Others.push(resHistogram.data[i].qty)
                            }if((resHistogram.data[i].element === '`+ response.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                              arrayCohort`+ response.Trait +`_Others.push(resHistogram.data[i].qty)
                            }
                          }
                          // add numbers in 1 row for ranking 0 to 100%
                          array`+ response.Trait +`_Self.push(score1`+ response.Trait +`_Self+score2`+ response.Trait +`_Self+score3`+ response.Trait +`_Self+score4`+ response.Trait +`_Self+score5`+ response.Trait +`_Self)
                          array`+ response.Trait +`_PSup.push(score1`+ response.Trait +`_PSup+score2`+ response.Trait +`_PSup+score3`+ response.Trait +`_PSup+score4`+ response.Trait +`_PSup+score5`+ response.Trait +`_PSup)
                          array`+ response.Trait +`_P.push(score1`+ response.Trait +`_P+score2`+ response.Trait +`_P+score3`+ response.Trait +`_P+score4`+ response.Trait +`_P+score5`+ response.Trait +`_P)
                          array`+ response.Trait +`_D.push(score1`+ response.Trait +`_D+score2`+ response.Trait +`_D+score3`+ response.Trait +`_D+score4`+ response.Trait +`_D+score5`+ response.Trait +`_D)
                          array`+ response.Trait +`_I.push(score1`+ response.Trait +`_I+score2`+ response.Trait +`_I+score3`+ response.Trait +`_I+score4`+ response.Trait +`_I+score5`+ response.Trait +`_I)
                          array`+ response.Trait +`_E.push(score1`+ response.Trait +`_E+score2`+ response.Trait +`_E+score3`+ response.Trait +`_E+score4`+ response.Trait +`_E+score5`+ response.Trait +`_E)
                          array`+ response.Trait +`_PD.push(score1`+ response.Trait +`_PD+score2`+ response.Trait +`_PD+score3`+ response.Trait +`_PD+score4`+ response.Trait +`_PD+score5`+ response.Trait +`_PD)
                          array`+ response.Trait +`_IE.push(score1`+ response.Trait +`_IE+score2`+ response.Trait +`_IE+score3`+ response.Trait +`_IE+score4`+ response.Trait +`_IE+score5`+ response.Trait +`_IE)
                          array`+ response.Trait +`_PDIE.push(score1`+ response.Trait +`_PDIE+score2`+ response.Trait +`_PDIE+score3`+ response.Trait +`_PDIE+score4`+ response.Trait +`_PDIE+score5`+ response.Trait +`_PDIE)
                          array`+ response.Trait +`_Others.push(score1`+ response.Trait +`_Others+score2`+ response.Trait +`_Others+score3`+ response.Trait +`_Others+score4`+ response.Trait +`_Others+score5`+ response.Trait +`_Others)
                          //cohort
                          arrayCohort`+ response.Trait +`_Self.push(cohortScore1`+ response.Trait +`_Self+cohortScore2`+ response.Trait +`_Self+cohortScore3`+ response.Trait +`_Self+cohortScore4`+ response.Trait +`_Self+cohortScore5`+ response.Trait +`_Self)
                          arrayCohort`+ response.Trait +`_PSup.push(cohortScore1`+ response.Trait +`_PSup+cohortScore2`+ response.Trait +`_PSup+cohortScore3`+ response.Trait +`_PSup+cohortScore4`+ response.Trait +`_PSup+cohortScore5`+ response.Trait +`_PSup)
                          arrayCohort`+ response.Trait +`_P.push(cohortScore1`+ response.Trait +`_P+cohortScore2`+ response.Trait +`_P+cohortScore3`+ response.Trait +`_P+cohortScore4`+ response.Trait +`_P+cohortScore5`+ response.Trait +`_P)
                          arrayCohort`+ response.Trait +`_D.push(cohortScore1`+ response.Trait +`_D+cohortScore2`+ response.Trait +`_D+cohortScore3`+ response.Trait +`_D+cohortScore4`+ response.Trait +`_D+cohortScore5`+ response.Trait +`_D)
                          arrayCohort`+ response.Trait +`_I.push(cohortScore1`+ response.Trait +`_I+cohortScore2`+ response.Trait +`_I+cohortScore3`+ response.Trait +`_I+cohortScore4`+ response.Trait +`_I+cohortScore5`+ response.Trait +`_I)
                          arrayCohort`+ response.Trait +`_E.push(cohortScore1`+ response.Trait +`_E+cohortScore2`+ response.Trait +`_E+cohortScore3`+ response.Trait +`_E+cohortScore4`+ response.Trait +`_E+cohortScore5`+ response.Trait +`_E)
                          arrayCohort`+ response.Trait +`_PD.push(cohortScore1`+ response.Trait +`_PD+cohortScore2`+ response.Trait +`_PD+cohortScore3`+ response.Trait +`_PD+cohortScore4`+ response.Trait +`_PD+cohortScore5`+ response.Trait +`_PD)
                          arrayCohort`+ response.Trait +`_IE.push(cohortScore1`+ response.Trait +`_IE+cohortScore2`+ response.Trait +`_IE+cohortScore3`+ response.Trait +`_IE+cohortScore4`+ response.Trait +`_IE+cohortScore5`+ response.Trait +`_IE)
                          arrayCohort`+ response.Trait +`_PDIE.push(cohortScore1`+ response.Trait +`_PDIE+cohortScore2`+ response.Trait +`_PDIE+cohortScore3`+ response.Trait +`_PDIE+cohortScore4`+ response.Trait +`_PDIE+cohortScore5`+ response.Trait +`_PDIE)
                          arrayCohort`+ response.Trait +`_Others.push(cohortScore1`+ response.Trait +`_Others+cohortScore2`+ response.Trait +`_Others+cohortScore3`+ response.Trait +`_Others+cohortScore4`+ response.Trait +`_Others+cohortScore5`+ response.Trait +`_Others)
                      `
                    })
                  }
                }
                // part 5 of Histogram
                if (data.Template === 'Add-Histogram') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                          // for Rolled-up Total
                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                      
                        //section 2 compute average per score
                          let arrayMain_`+ response.Trait +` = []

                            let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PD + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                          
                            let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PD + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                          
                            let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PD + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                          
                            let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PD + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                          
                            let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PD + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)

                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)
                          
                        //section 3-8 compute average per score
                          arraySub_`+ response.Trait +` = []

                            let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_PD + score1`+ response.Trait +`_I + score1`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                          
                            let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_PD + score2`+ response.Trait +`_I + score2`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                          
                            let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_PD + score3`+ response.Trait +`_I + score3`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                          
                            let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_PD + score4`+ response.Trait +`_I + score4`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                          
                            let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_PD + score5`+ response.Trait +`_I + score5`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                          
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)
                        //rank computed here section 2
                          let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                        //section 2
                          $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                          $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                          $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                          $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                          $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                        //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                        //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                          
                        }
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          
                        //section 2 compute average per score
                          let arrayMain_`+ response.Trait +` = []

                            let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D)
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                          
                            let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D)
                            arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                          
                            let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D)
                            arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                          
                            let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D)
                            arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                          
                            let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D)
                            arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                          
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                        //section 3-8 compute average per score
                          arraySub_`+ response.Trait +` = []

                            let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_IE + score1`+ response.Trait +`_P + score1`+ response.Trait +`_D)
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                          
                            let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_IE + score2`+ response.Trait +`_P + score2`+ response.Trait +`_D)
                            arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                          
                            let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_IE + score3`+ response.Trait +`_P + score3`+ response.Trait +`_D)
                            arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                          
                            let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_IE + score4`+ response.Trait +`_P + score4`+ response.Trait +`_D)
                            arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                          
                            let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_IE + score5`+ response.Trait +`_P + score5`+ response.Trait +`_D)
                            arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                          
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                        //rank computed here section 2
                          let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                        //section 2
                          $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                          $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                          $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                          $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                          $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                        //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                        //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');

                        }
                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          //section 2 compute average per score
                          let arrayMain_`+ response.Trait +` = []

                            let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_PD)
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                          
                            let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_PD)
                            arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                          
                            let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_PD)
                            arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                          
                            let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_PD)
                            arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                          
                            let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_PD)
                            arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                          
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                        //section 3-8 compute average per score
                          arraySub_`+ response.Trait +` = []

                            let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_IE + score1`+ response.Trait +`_PD)
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                          
                            let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_IE + score2`+ response.Trait +`_PD)
                            arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                          
                            let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_IE + score3`+ response.Trait +`_PD)
                            arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                          
                            let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_IE + score4`+ response.Trait +`_PD)
                            arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                          
                            let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_IE + score5`+ response.Trait +`_PD)
                            arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                          
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                        //rank computed here section 2
                          let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                        //section 2
                          $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                          $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                          $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                          $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                          $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                        //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                        //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                          
                          }
                        
                        if (isDisplay_PDIE === 1) {
                          let arrayMain_`+ response.Trait +` = []

                            let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PDIE)
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                          
                            let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PDIE)
                            arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                          
                            let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PDIE)
                            arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                          
                            let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PDIE)
                            arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                          
                            let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PDIE)
                            arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                          
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                        //section 3-8 compute average per score
                          arraySub_`+ response.Trait +` = []

                            let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_PDIE)
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                          
                            let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_PDIE)
                            arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                          
                            let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_PDIE)
                            arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                          
                            let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_PDIE)
                            arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                          
                            let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_PDIE)
                            arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                          
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                        //rank computed here section 2
                          let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                        //section 2
                          $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                          $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                          $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                          $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                          $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                        //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                        //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');


                          
                          }

                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                          let arrayMain_`+ response.Trait +` = []

                            let numMainscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_Self + cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`)
                          
                            let numMainscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_Self + cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore2_`+ response.Trait +`)
                          
                            let numMainscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_Self + cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore3_`+ response.Trait +`)
                          
                            let numMainscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_Self + cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore4_`+ response.Trait +`)
                          
                            let numMainscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_Self + cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                            arrayMain_`+ response.Trait +`.push(numMainscore5_`+ response.Trait +`)
                          
                            arrayMain_`+ response.Trait +`.push(numMainscore1_`+ response.Trait +`+ numMainscore2_`+ response.Trait +`+ numMainscore3_`+ response.Trait +`+ numMainscore4_`+ response.Trait +`+ numMainscore5_`+ response.Trait +`)

                        //section 3-8 compute average per score
                          arraySub_`+ response.Trait +` = []

                            let numSubscore1_`+ response.Trait +` = (score1`+ response.Trait +`_PSup + score1`+ response.Trait +`_P + score1`+ response.Trait +`_D + score1`+ response.Trait +`_I + score1`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`)
                          
                            let numSubscore2_`+ response.Trait +` = (score2`+ response.Trait +`_PSup + score2`+ response.Trait +`_P + score2`+ response.Trait +`_D + score2`+ response.Trait +`_I + score2`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore2_`+ response.Trait +`)
                          
                            let numSubscore3_`+ response.Trait +` = (score3`+ response.Trait +`_PSup + score3`+ response.Trait +`_P + score3`+ response.Trait +`_D + score3`+ response.Trait +`_I + score3`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore3_`+ response.Trait +`)
                          
                            let numSubscore4_`+ response.Trait +` = (score4`+ response.Trait +`_PSup + score4`+ response.Trait +`_P + score4`+ response.Trait +`_D + score4`+ response.Trait +`_I + score4`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore4_`+ response.Trait +`)
                          
                            let numSubscore5_`+ response.Trait +` = (score5`+ response.Trait +`_PSup + score5`+ response.Trait +`_P + score5`+ response.Trait +`_D + score5`+ response.Trait +`_I + score5`+ response.Trait +`_E)
                            arraySub_`+ response.Trait +`.push(numSubscore5_`+ response.Trait +`)
                          
                            arraySub_`+ response.Trait +`.push(numSubscore1_`+ response.Trait +`+ numSubscore2_`+ response.Trait +`+ numSubscore3_`+ response.Trait +`+ numSubscore4_`+ response.Trait +`+ numSubscore5_`+ response.Trait +`)

                        //rank computed here section 2
                          let histogramScore1_`+ response.Trait +`_Head = rankRange(numMainscore1_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Head = rankRange(numMainscore2_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Head = rankRange(numMainscore3_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Head = rankRange(numMainscore4_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Head = rankRange(numMainscore5_`+ response.Trait +`, arrayMain_`+ response.Trait +`)
                        //section 2
                          $(".histogram-score1-`+ response.Trait +`-head").css('height', histogramScore1_`+ response.Trait +`_Head +'%');
                          $(".histogram-score2-`+ response.Trait +`-head").css('height', histogramScore2_`+ response.Trait +`_Head +'%');
                          $(".histogram-score3-`+ response.Trait +`-head").css('height', histogramScore3_`+ response.Trait +`_Head +'%');
                          $(".histogram-score4-`+ response.Trait +`-head").css('height', histogramScore4_`+ response.Trait +`_Head +'%');
                          $(".histogram-score5-`+ response.Trait +`-head").css('height', histogramScore5_`+ response.Trait +`_Head +'%');
                        //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_Sub = rankRange(numSubscore1_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_Sub = rankRange(numSubscore2_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_Sub = rankRange(numSubscore3_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_Sub = rankRange(numSubscore4_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_Sub = rankRange(numSubscore5_`+ response.Trait +`, arraySub_`+ response.Trait +`)
                        //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Total").css('height', histogramScore1_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Total").css('height', histogramScore2_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Total").css('height', histogramScore3_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Total").css('height', histogramScore4_`+ response.Trait +`_Sub +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Total").css('height', histogramScore5_`+ response.Trait +`_Sub +'%');
                          
                        }
                      `
                    })
                  }
                }
                // part 5.1 of Histogram
                if (data.Template === 'Add-Histogram') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        //self
                          let histogramScore1_`+ response.Trait +`_Self = rankRange(cohortScore1`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                          let histogramScore2_`+ response.Trait +`_Self = rankRange(cohortScore2`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                          let histogramScore3_`+ response.Trait +`_Self = rankRange(cohortScore3`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                          let histogramScore4_`+ response.Trait +`_Self = rankRange(cohortScore4`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                          let histogramScore5_`+ response.Trait +`_Self = rankRange(cohortScore5`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-Self").css('height', histogramScore1_`+ response.Trait +`_Self +'%');
                          $(".histogram-score2-`+ response.Trait +`-Self").css('height', histogramScore2_`+ response.Trait +`_Self +'%');
                          $(".histogram-score3-`+ response.Trait +`-Self").css('height', histogramScore3_`+ response.Trait +`_Self +'%');
                          $(".histogram-score4-`+ response.Trait +`-Self").css('height', histogramScore4_`+ response.Trait +`_Self +'%');
                          $(".histogram-score5-`+ response.Trait +`-Self").css('height', histogramScore5_`+ response.Trait +`_Self +'%');
                          //self section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_Self = rankRange(score1`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                          let histogramScore2_`+ response.Trait +`_sub_Self = rankRange(score2`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                          let histogramScore3_`+ response.Trait +`_sub_Self = rankRange(score3`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                          let histogramScore4_`+ response.Trait +`_sub_Self = rankRange(score4`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                          let histogramScore5_`+ response.Trait +`_sub_Self = rankRange(score5`+ response.Trait +`_Self, array`+ response.Trait +`_Self)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Self").css('height', histogramScore1_`+ response.Trait +`_sub_Self +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Self").css('height', histogramScore2_`+ response.Trait +`_sub_Self +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Self").css('height', histogramScore3_`+ response.Trait +`_sub_Self +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Self").css('height', histogramScore4_`+ response.Trait +`_sub_Self +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Self").css('height', histogramScore5_`+ response.Trait +`_sub_Self +'%');

                          //psup section 2
                          let histogramScore1_`+ response.Trait +`_PSup = rankRange(cohortScore1`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                          let histogramScore2_`+ response.Trait +`_PSup = rankRange(cohortScore2`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                          let histogramScore3_`+ response.Trait +`_PSup = rankRange(cohortScore3`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                          let histogramScore4_`+ response.Trait +`_PSup = rankRange(cohortScore4`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                          let histogramScore5_`+ response.Trait +`_PSup = rankRange(cohortScore5`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-Psup").css('height', histogramScore1_`+ response.Trait +`_PSup +'%');
                          $(".histogram-score2-`+ response.Trait +`-Psup").css('height', histogramScore2_`+ response.Trait +`_PSup +'%');
                          $(".histogram-score3-`+ response.Trait +`-Psup").css('height', histogramScore3_`+ response.Trait +`_PSup +'%');
                          $(".histogram-score4-`+ response.Trait +`-Psup").css('height', histogramScore4_`+ response.Trait +`_PSup +'%');
                          $(".histogram-score5-`+ response.Trait +`-Psup").css('height', histogramScore5_`+ response.Trait +`_PSup +'%');
                          //psup section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_PSup = rankRange(score1`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                          let histogramScore2_`+ response.Trait +`_sub_PSup = rankRange(score2`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                          let histogramScore3_`+ response.Trait +`_sub_PSup = rankRange(score3`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                          let histogramScore4_`+ response.Trait +`_sub_PSup = rankRange(score4`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                          let histogramScore5_`+ response.Trait +`_sub_PSup = rankRange(score5`+ response.Trait +`_PSup, array`+ response.Trait +`_PSup)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Psup").css('height', histogramScore1_`+ response.Trait +`_sub_PSup +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Psup").css('height', histogramScore2_`+ response.Trait +`_sub_PSup +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Psup").css('height', histogramScore3_`+ response.Trait +`_sub_PSup +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Psup").css('height', histogramScore4_`+ response.Trait +`_sub_PSup +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Psup").css('height', histogramScore5_`+ response.Trait +`_sub_PSup +'%');

                          //peers section 2
                          let histogramScore1_`+ response.Trait +`_P = rankRange(cohortScore1`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                          let histogramScore2_`+ response.Trait +`_P = rankRange(cohortScore2`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                          let histogramScore3_`+ response.Trait +`_P = rankRange(cohortScore3`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                          let histogramScore4_`+ response.Trait +`_P = rankRange(cohortScore4`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                          let histogramScore5_`+ response.Trait +`_P = rankRange(cohortScore5`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-P").css('height', histogramScore1_`+ response.Trait +`_P +'%');
                          $(".histogram-score2-`+ response.Trait +`-P").css('height', histogramScore2_`+ response.Trait +`_P +'%');
                          $(".histogram-score3-`+ response.Trait +`-P").css('height', histogramScore3_`+ response.Trait +`_P +'%');
                          $(".histogram-score4-`+ response.Trait +`-P").css('height', histogramScore4_`+ response.Trait +`_P +'%');
                          $(".histogram-score5-`+ response.Trait +`-P").css('height', histogramScore5_`+ response.Trait +`_P +'%');
                          //peers section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_P = rankRange(score1`+ response.Trait +`_P, array`+ response.Trait +`_P)
                          let histogramScore2_`+ response.Trait +`_sub_P = rankRange(score2`+ response.Trait +`_P, array`+ response.Trait +`_P)
                          let histogramScore3_`+ response.Trait +`_sub_P = rankRange(score3`+ response.Trait +`_P, array`+ response.Trait +`_P)
                          let histogramScore4_`+ response.Trait +`_sub_P = rankRange(score4`+ response.Trait +`_P, array`+ response.Trait +`_P)
                          let histogramScore5_`+ response.Trait +`_sub_P = rankRange(score5`+ response.Trait +`_P, array`+ response.Trait +`_P)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-P").css('height', histogramScore1_`+ response.Trait +`_sub_P +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-P").css('height', histogramScore2_`+ response.Trait +`_sub_P +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-P").css('height', histogramScore3_`+ response.Trait +`_sub_P +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-P").css('height', histogramScore4_`+ response.Trait +`_sub_P +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-P").css('height', histogramScore5_`+ response.Trait +`_sub_P +'%');

                          //direct reports section 2
                          let histogramScore1_`+ response.Trait +`_D = rankRange(cohortScore1`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                          let histogramScore2_`+ response.Trait +`_D = rankRange(cohortScore2`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                          let histogramScore3_`+ response.Trait +`_D = rankRange(cohortScore3`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                          let histogramScore4_`+ response.Trait +`_D = rankRange(cohortScore4`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                          let histogramScore5_`+ response.Trait +`_D = rankRange(cohortScore5`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-D").css('height', histogramScore1_`+ response.Trait +`_D +'%');
                          $(".histogram-score2-`+ response.Trait +`-D").css('height', histogramScore2_`+ response.Trait +`_D +'%');
                          $(".histogram-score3-`+ response.Trait +`-D").css('height', histogramScore3_`+ response.Trait +`_D +'%');
                          $(".histogram-score4-`+ response.Trait +`-D").css('height', histogramScore4_`+ response.Trait +`_D +'%');
                          $(".histogram-score5-`+ response.Trait +`-D").css('height', histogramScore5_`+ response.Trait +`_D +'%');
                          //direct reports section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_D = rankRange(score1`+ response.Trait +`_D, array`+ response.Trait +`_D)
                          let histogramScore2_`+ response.Trait +`_sub_D = rankRange(score2`+ response.Trait +`_D, array`+ response.Trait +`_D)
                          let histogramScore3_`+ response.Trait +`_sub_D = rankRange(score3`+ response.Trait +`_D, array`+ response.Trait +`_D)
                          let histogramScore4_`+ response.Trait +`_sub_D = rankRange(score4`+ response.Trait +`_D, array`+ response.Trait +`_D)
                          let histogramScore5_`+ response.Trait +`_sub_D = rankRange(score5`+ response.Trait +`_D, array`+ response.Trait +`_D)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-D").css('height', histogramScore1_`+ response.Trait +`_sub_D +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-D").css('height', histogramScore2_`+ response.Trait +`_sub_D +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-D").css('height', histogramScore3_`+ response.Trait +`_sub_D +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-D").css('height', histogramScore4_`+ response.Trait +`_sub_D +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-D").css('height', histogramScore5_`+ response.Trait +`_sub_D +'%');
                          
                          //internal stakeholders section 2
                          let histogramScore1_`+ response.Trait +`_I = rankRange(cohortScore1`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                          let histogramScore2_`+ response.Trait +`_I = rankRange(cohortScore2`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                          let histogramScore3_`+ response.Trait +`_I = rankRange(cohortScore3`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                          let histogramScore4_`+ response.Trait +`_I = rankRange(cohortScore4`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                          let histogramScore5_`+ response.Trait +`_I = rankRange(cohortScore5`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-I").css('height', histogramScore1_`+ response.Trait +`_I +'%');
                          $(".histogram-score2-`+ response.Trait +`-I").css('height', histogramScore2_`+ response.Trait +`_I +'%');
                          $(".histogram-score3-`+ response.Trait +`-I").css('height', histogramScore3_`+ response.Trait +`_I +'%');
                          $(".histogram-score4-`+ response.Trait +`-I").css('height', histogramScore4_`+ response.Trait +`_I +'%');
                          $(".histogram-score5-`+ response.Trait +`-I").css('height', histogramScore5_`+ response.Trait +`_I +'%');
                          //internal stakeholders section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_I = rankRange(score1`+ response.Trait +`_I, array`+ response.Trait +`_I)
                          let histogramScore2_`+ response.Trait +`_sub_I = rankRange(score2`+ response.Trait +`_I, array`+ response.Trait +`_I)
                          let histogramScore3_`+ response.Trait +`_sub_I = rankRange(score3`+ response.Trait +`_I, array`+ response.Trait +`_I)
                          let histogramScore4_`+ response.Trait +`_sub_I = rankRange(score4`+ response.Trait +`_I, array`+ response.Trait +`_I)
                          let histogramScore5_`+ response.Trait +`_sub_I = rankRange(score5`+ response.Trait +`_I, array`+ response.Trait +`_I)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-I").css('height', histogramScore1_`+ response.Trait +`_sub_I +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-I").css('height', histogramScore2_`+ response.Trait +`_sub_I +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-I").css('height', histogramScore3_`+ response.Trait +`_sub_I +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-I").css('height', histogramScore4_`+ response.Trait +`_sub_I +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-I").css('height', histogramScore5_`+ response.Trait +`_sub_I +'%');

                          //external stakeholders section 2
                          let histogramScore1_`+ response.Trait +`_E = rankRange(cohortScore1`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                          let histogramScore2_`+ response.Trait +`_E = rankRange(cohortScore2`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                          let histogramScore3_`+ response.Trait +`_E = rankRange(cohortScore3`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                          let histogramScore4_`+ response.Trait +`_E = rankRange(cohortScore4`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                          let histogramScore5_`+ response.Trait +`_E = rankRange(cohortScore5`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-E").css('height', histogramScore1_`+ response.Trait +`_E +'%');
                          $(".histogram-score2-`+ response.Trait +`-E").css('height', histogramScore2_`+ response.Trait +`_E +'%');
                          $(".histogram-score3-`+ response.Trait +`-E").css('height', histogramScore3_`+ response.Trait +`_E +'%');
                          $(".histogram-score4-`+ response.Trait +`-E").css('height', histogramScore4_`+ response.Trait +`_E +'%');
                          $(".histogram-score5-`+ response.Trait +`-E").css('height', histogramScore5_`+ response.Trait +`_E +'%');
                          //external stakeholders section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_E = rankRange(score1`+ response.Trait +`_E, array`+ response.Trait +`_E)
                          let histogramScore2_`+ response.Trait +`_sub_E = rankRange(score2`+ response.Trait +`_E, array`+ response.Trait +`_E)
                          let histogramScore3_`+ response.Trait +`_sub_E = rankRange(score3`+ response.Trait +`_E, array`+ response.Trait +`_E)
                          let histogramScore4_`+ response.Trait +`_sub_E = rankRange(score4`+ response.Trait +`_E, array`+ response.Trait +`_E)
                          let histogramScore5_`+ response.Trait +`_sub_E = rankRange(score5`+ response.Trait +`_E, array`+ response.Trait +`_E)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-E").css('height', histogramScore1_`+ response.Trait +`_sub_E +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-E").css('height', histogramScore2_`+ response.Trait +`_sub_E +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-E").css('height', histogramScore3_`+ response.Trait +`_sub_E +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-E").css('height', histogramScore4_`+ response.Trait +`_sub_E +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-E").css('height', histogramScore5_`+ response.Trait +`_sub_E +'%');

                          
                          //peers/direct section 2
                          let histogramScore1_`+ response.Trait +`_PD = rankRange(cohortScore1`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                          let histogramScore2_`+ response.Trait +`_PD = rankRange(cohortScore2`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                          let histogramScore3_`+ response.Trait +`_PD = rankRange(cohortScore3`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                          let histogramScore4_`+ response.Trait +`_PD = rankRange(cohortScore4`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                          let histogramScore5_`+ response.Trait +`_PD = rankRange(cohortScore5`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-PD").css('height', histogramScore1_`+ response.Trait +`_PD +'%');
                          $(".histogram-score2-`+ response.Trait +`-PD").css('height', histogramScore2_`+ response.Trait +`_PD +'%');
                          $(".histogram-score3-`+ response.Trait +`-PD").css('height', histogramScore3_`+ response.Trait +`_PD +'%');
                          $(".histogram-score4-`+ response.Trait +`-PD").css('height', histogramScore4_`+ response.Trait +`_PD +'%');
                          $(".histogram-score5-`+ response.Trait +`-PD").css('height', histogramScore5_`+ response.Trait +`_PD +'%');
                          //peers/direct section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_PD = rankRange(score1`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                          let histogramScore2_`+ response.Trait +`_sub_PD = rankRange(score2`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                          let histogramScore3_`+ response.Trait +`_sub_PD = rankRange(score3`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                          let histogramScore4_`+ response.Trait +`_sub_PD = rankRange(score4`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                          let histogramScore5_`+ response.Trait +`_sub_PD = rankRange(score5`+ response.Trait +`_PD, array`+ response.Trait +`_PD)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-PD").css('height', histogramScore1_`+ response.Trait +`_sub_PD +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-PD").css('height', histogramScore2_`+ response.Trait +`_sub_PD +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-PD").css('height', histogramScore3_`+ response.Trait +`_sub_PD +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-PD").css('height', histogramScore4_`+ response.Trait +`_sub_PD +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-PD").css('height', histogramScore5_`+ response.Trait +`_sub_PD +'%');

                          //internal/external section 2
                          let histogramScore1_`+ response.Trait +`_IE = rankRange(cohortScore1`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                          let histogramScore2_`+ response.Trait +`_IE = rankRange(cohortScore2`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                          let histogramScore3_`+ response.Trait +`_IE = rankRange(cohortScore3`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                          let histogramScore4_`+ response.Trait +`_IE = rankRange(cohortScore4`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                          let histogramScore5_`+ response.Trait +`_IE = rankRange(cohortScore5`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-IE").css('height', histogramScore1_`+ response.Trait +`_IE +'%');
                          $(".histogram-score2-`+ response.Trait +`-IE").css('height', histogramScore2_`+ response.Trait +`_IE +'%');
                          $(".histogram-score3-`+ response.Trait +`-IE").css('height', histogramScore3_`+ response.Trait +`_IE +'%');
                          $(".histogram-score4-`+ response.Trait +`-IE").css('height', histogramScore4_`+ response.Trait +`_IE +'%');
                          $(".histogram-score5-`+ response.Trait +`-IE").css('height', histogramScore5_`+ response.Trait +`_IE +'%');
                          //internal/external section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_IE = rankRange(score1`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                          let histogramScore2_`+ response.Trait +`_sub_IE = rankRange(score2`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                          let histogramScore3_`+ response.Trait +`_sub_IE = rankRange(score3`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                          let histogramScore4_`+ response.Trait +`_sub_IE = rankRange(score4`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                          let histogramScore5_`+ response.Trait +`_sub_IE = rankRange(score5`+ response.Trait +`_IE, array`+ response.Trait +`_IE)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-IE").css('height', histogramScore1_`+ response.Trait +`_sub_IE +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-IE").css('height', histogramScore2_`+ response.Trait +`_sub_IE +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-IE").css('height', histogramScore3_`+ response.Trait +`_sub_IE +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-IE").css('height', histogramScore4_`+ response.Trait +`_sub_IE +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-IE").css('height', histogramScore5_`+ response.Trait +`_sub_IE +'%');

                          //peers/direct/internal/external section 2
                          let histogramScore1_`+ response.Trait +`_PDIE = rankRange(cohortScore1`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                          let histogramScore2_`+ response.Trait +`_PDIE = rankRange(cohortScore2`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                          let histogramScore3_`+ response.Trait +`_PDIE = rankRange(cohortScore3`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                          let histogramScore4_`+ response.Trait +`_PDIE = rankRange(cohortScore4`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                          let histogramScore5_`+ response.Trait +`_PDIE = rankRange(cohortScore5`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                          //section 2
                          $(".histogram-score1-`+ response.Trait +`-PDIE").css('height', histogramScore1_`+ response.Trait +`_PDIE +'%');
                          $(".histogram-score2-`+ response.Trait +`-PDIE").css('height', histogramScore2_`+ response.Trait +`_PDIE +'%');
                          $(".histogram-score3-`+ response.Trait +`-PDIE").css('height', histogramScore3_`+ response.Trait +`_PDIE +'%');
                          $(".histogram-score4-`+ response.Trait +`-PDIE").css('height', histogramScore4_`+ response.Trait +`_PDIE +'%');
                          $(".histogram-score5-`+ response.Trait +`-PDIE").css('height', histogramScore5_`+ response.Trait +`_PDIE +'%');
                          //peers/direct/internal/external section 3-8
                          let histogramScore1_`+ response.Trait +`_sub_PDIE = rankRange(score1`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                          let histogramScore2_`+ response.Trait +`_sub_PDIE = rankRange(score2`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                          let histogramScore3_`+ response.Trait +`_sub_PDIE = rankRange(score3`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                          let histogramScore4_`+ response.Trait +`_sub_PDIE = rankRange(score4`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                          let histogramScore5_`+ response.Trait +`_sub_PDIE = rankRange(score5`+ response.Trait +`_PDIE, array`+ response.Trait +`_PDIE)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-PDIE").css('height', histogramScore1_`+ response.Trait +`_sub_PDIE +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-PDIE").css('height', histogramScore2_`+ response.Trait +`_sub_PDIE +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-PDIE").css('height', histogramScore3_`+ response.Trait +`_sub_PDIE +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-PDIE").css('height', histogramScore4_`+ response.Trait +`_sub_PDIE +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-PDIE").css('height', histogramScore5_`+ response.Trait +`_sub_PDIE +'%');

                          //All Nominees section3-8
                          let histogramScore1_`+ response.Trait +`_sub_Others = rankRange(score1`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                          let histogramScore2_`+ response.Trait +`_sub_Others = rankRange(score2`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                          let histogramScore3_`+ response.Trait +`_sub_Others = rankRange(score3`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                          let histogramScore4_`+ response.Trait +`_sub_Others = rankRange(score4`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                          let histogramScore5_`+ response.Trait +`_sub_Others = rankRange(score5`+ response.Trait +`_Others, array`+ response.Trait +`_Others)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-sub-Others").css('height', histogramScore1_`+ response.Trait +`_sub_Others +'%');
                          $(".histogram-score2-`+ response.Trait +`-sub-Others").css('height', histogramScore2_`+ response.Trait +`_sub_Others +'%');
                          $(".histogram-score3-`+ response.Trait +`-sub-Others").css('height', histogramScore3_`+ response.Trait +`_sub_Others +'%');
                          $(".histogram-score4-`+ response.Trait +`-sub-Others").css('height', histogramScore4_`+ response.Trait +`_sub_Others +'%');
                          $(".histogram-score5-`+ response.Trait +`-sub-Others").css('height', histogramScore5_`+ response.Trait +`_sub_Others +'%');
                          
                        

                      `
                    })
                  }
                }
                // part 6 of Histogram COHORT DATA 
                if (data.Template === 'Add-Histogram') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        // for Rolled-up Total Cohort
                        if(isDisplay_PD === 1 && isDisplay_IE === 0){
                          //section 3-8 compute average per COHORT score
                          cohortArraySub_`+ response.Trait +` = []

                          let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PD + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                        
                          let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PD + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                        
                          let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PD + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                        
                          let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PD + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                        
                          let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PD + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                        
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                      
                          //rank computed here section3-8 COHORT
                          let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                        
                        }
                        else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                          //section 3-8 compute average per COHORT score
                          cohortArraySub_`+ response.Trait +` = []

                          let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                        
                          let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                        
                          let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                        
                          let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                        
                          let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                        
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                      
                          //rank computed here section3-8
                          let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');

                        }
                        else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                          //section 3-8 compute average per COHORT score
                          cohortArraySub_`+ response.Trait +` = []

                          let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_IE + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_PD)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                        
                          let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_IE + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_PD)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                        
                          let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_IE + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_PD)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                        
                          let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_IE + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_PD)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                        
                          let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_IE + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_PD)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                        
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                      
                          //rank computed here section3-8 COHORT
                          let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                        
                        }
                        
                        if (isDisplay_PDIE === 1) {
                          // section 3-8 compute average per COHORT score
                          cohortArraySub_`+ response.Trait +` = []

                          let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_PDIE)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                        
                          let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_PDIE)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                        
                          let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_PDIE)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                        
                          let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_PDIE)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                        
                          let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_PDIE)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                        
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                      
                          // rank computed here section3-8 COHORT
                          let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');
                        
                        }

                        else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                          // section 3-8 compute average per COHORT score
                          cohortArraySub_`+ response.Trait +` = []

                          let cohortNumSubscore1_`+ response.Trait +` = (cohortScore1`+ response.Trait +`_PSup + cohortScore1`+ response.Trait +`_P + cohortScore1`+ response.Trait +`_D + cohortScore1`+ response.Trait +`_I + cohortScore1`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`)
                        
                          let cohortNumSubscore2_`+ response.Trait +` = (cohortScore2`+ response.Trait +`_PSup + cohortScore2`+ response.Trait +`_P + cohortScore2`+ response.Trait +`_D + cohortScore2`+ response.Trait +`_I + cohortScore2`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore2_`+ response.Trait +`)
                        
                          let cohortNumSubscore3_`+ response.Trait +` = (cohortScore3`+ response.Trait +`_PSup + cohortScore3`+ response.Trait +`_P + cohortScore3`+ response.Trait +`_D + cohortScore3`+ response.Trait +`_I + cohortScore3`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore3_`+ response.Trait +`)
                        
                          let cohortNumSubscore4_`+ response.Trait +` = (cohortScore4`+ response.Trait +`_PSup + cohortScore4`+ response.Trait +`_P + cohortScore4`+ response.Trait +`_D + cohortScore4`+ response.Trait +`_I + cohortScore4`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore4_`+ response.Trait +`)
                        
                          let cohortNumSubscore5_`+ response.Trait +` = (cohortScore5`+ response.Trait +`_PSup + cohortScore5`+ response.Trait +`_P + cohortScore5`+ response.Trait +`_D + cohortScore5`+ response.Trait +`_I + cohortScore5`+ response.Trait +`_E)
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore5_`+ response.Trait +`)
                        
                          cohortArraySub_`+ response.Trait +`.push(cohortNumSubscore1_`+ response.Trait +`+ cohortNumSubscore2_`+ response.Trait +`+ cohortNumSubscore3_`+ response.Trait +`+ cohortNumSubscore4_`+ response.Trait +`+ cohortNumSubscore5_`+ response.Trait +`)
                      
                          // rank computed here section3-8 COHORT
                          let histogramScore1_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore2_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore3_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore4_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          let histogramScore5_`+ response.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ response.Trait +`, cohortArraySub_`+ response.Trait +`)
                          //section3-8
                          $(".histogram-score1-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score2-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score3-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score4-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ response.Trait +`_CohortSub +'%');
                          $(".histogram-score5-`+ response.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ response.Trait +`_CohortSub +'%');

                        }

                      `
                    })
                  }
                }
                // part 6.1 of Histogram COHORT DATA 
                if (data.Template === 'Add-Histogram') { 
                  let obj = JSON.parse(data.Text)
                  for (const res of obj) {
                    res.Elements.forEach((response) => {
                      output += `

                        //self section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                        let histogramScore2_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                        let histogramScore3_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                        let histogramScore4_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                        let histogramScore5_`+ response.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ response.Trait +`_Self, arrayCohort`+ response.Trait +`_Self)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ response.Trait +`_cohortSub_Self +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ response.Trait +`_cohortSub_Self +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ response.Trait +`_cohortSub_Self +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ response.Trait +`_cohortSub_Self +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ response.Trait +`_cohortSub_Self +'%');

                        //psup section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                        let histogramScore2_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                        let histogramScore3_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                        let histogramScore4_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                        let histogramScore5_`+ response.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ response.Trait +`_PSup, arrayCohort`+ response.Trait +`_PSup)
                        //section3-8 
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PSup +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PSup +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PSup +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PSup +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PSup +'%');

                        //peers section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore1`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                        let histogramScore2_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore2`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                        let histogramScore3_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore3`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                        let histogramScore4_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore4`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                        let histogramScore5_`+ response.Trait +`_cohortSub_P = rankRange(cohortScore5`+ response.Trait +`_P, arrayCohort`+ response.Trait +`_P)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-P").css('height', histogramScore1_`+ response.Trait +`_cohortSub_P +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-P").css('height', histogramScore2_`+ response.Trait +`_cohortSub_P +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-P").css('height', histogramScore3_`+ response.Trait +`_cohortSub_P +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-P").css('height', histogramScore4_`+ response.Trait +`_cohortSub_P +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-P").css('height', histogramScore5_`+ response.Trait +`_cohortSub_P +'%');

                        //direct reports section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore1`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                        let histogramScore2_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore2`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                        let histogramScore3_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore3`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                        let histogramScore4_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore4`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                        let histogramScore5_`+ response.Trait +`_cohortSub_D = rankRange(cohortScore5`+ response.Trait +`_D, arrayCohort`+ response.Trait +`_D)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-D").css('height', histogramScore1_`+ response.Trait +`_cohortSub_D +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-D").css('height', histogramScore2_`+ response.Trait +`_cohortSub_D +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-D").css('height', histogramScore3_`+ response.Trait +`_cohortSub_D +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-D").css('height', histogramScore4_`+ response.Trait +`_cohortSub_D +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-D").css('height', histogramScore5_`+ response.Trait +`_cohortSub_D +'%');

                        //internal stakeholders section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore1`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                        let histogramScore2_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore2`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                        let histogramScore3_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore3`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                        let histogramScore4_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore4`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                        let histogramScore5_`+ response.Trait +`_cohortSub_I = rankRange(cohortScore5`+ response.Trait +`_I, arrayCohort`+ response.Trait +`_I)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-I").css('height', histogramScore1_`+ response.Trait +`_cohortSub_I +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-I").css('height', histogramScore2_`+ response.Trait +`_cohortSub_I +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-I").css('height', histogramScore3_`+ response.Trait +`_cohortSub_I +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-I").css('height', histogramScore4_`+ response.Trait +`_cohortSub_I +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-I").css('height', histogramScore5_`+ response.Trait +`_cohortSub_I +'%');

                        //external stakeholders section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore1`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                        let histogramScore2_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore2`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                        let histogramScore3_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore3`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                        let histogramScore4_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore4`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                        let histogramScore5_`+ response.Trait +`_cohortSub_E = rankRange(cohortScore5`+ response.Trait +`_E, arrayCohort`+ response.Trait +`_E)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-E").css('height', histogramScore1_`+ response.Trait +`_cohortSub_E +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-E").css('height', histogramScore2_`+ response.Trait +`_cohortSub_E +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-E").css('height', histogramScore3_`+ response.Trait +`_cohortSub_E +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-E").css('height', histogramScore4_`+ response.Trait +`_cohortSub_E +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-E").css('height', histogramScore5_`+ response.Trait +`_cohortSub_E +'%');

                        //peers/direct section 3-8 cohort
                        let histogramScore1_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                        let histogramScore2_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                        let histogramScore3_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                        let histogramScore4_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                        let histogramScore5_`+ response.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ response.Trait +`_PD, arrayCohort`+ response.Trait +`_PD)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PD +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PD +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PD +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PD +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PD +'%');

                        //internal/external section 3-8
                        let histogramScore1_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                        let histogramScore2_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                        let histogramScore3_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                        let histogramScore4_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                        let histogramScore5_`+ response.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ response.Trait +`_IE, arrayCohort`+ response.Trait +`_IE)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ response.Trait +`_cohortSub_IE +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ response.Trait +`_cohortSub_IE +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ response.Trait +`_cohortSub_IE +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ response.Trait +`_cohortSub_IE +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ response.Trait +`_cohortSub_IE +'%');

                        //peers/direct/internal/external section 3-8
                        let histogramScore1_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                        let histogramScore2_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                        let histogramScore3_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                        let histogramScore4_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                        let histogramScore5_`+ response.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ response.Trait +`_PDIE, arrayCohort`+ response.Trait +`_PDIE)
                        //section3-8
                        $(".histogram-score1-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ response.Trait +`_cohortSub_PDIE +'%');
                        $(".histogram-score2-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ response.Trait +`_cohortSub_PDIE +'%');
                        $(".histogram-score3-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ response.Trait +`_cohortSub_PDIE +'%');
                        $(".histogram-score4-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ response.Trait +`_cohortSub_PDIE +'%');
                        $(".histogram-score5-`+ response.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ response.Trait +`_cohortSub_PDIE +'%');



                      `
                    })
                  }
                }

                // NPS HISTOGRAM
                if (data.Template === 'Bar-Chart-New-360-Sub-NPS') {
                  let obj = JSON.parse(data.Text)
                  console.log('obj upper level :', obj)
                  for (let i = 0; i < input.length; i++) {
                  const data = input[i]
                     // part 4 of Histogram
                     if (data.Template === 'Add-Histogram') { 
                        console.log('obj lower level :', obj)
                        for (const res of obj) {
                            output += `
                            //individual array
                                let array`+ res.Elements.Trait +`_Self = []
                                let array`+ res.Elements.Trait +`_PSup = []
                                let array`+ res.Elements.Trait +`_P = []
                                let array`+ res.Elements.Trait +`_D = []
                                let array`+ res.Elements.Trait +`_I = []
                                let array`+ res.Elements.Trait +`_E = []
                                let array`+ res.Elements.Trait +`_PD = []
                                let array`+ res.Elements.Trait +`_IE = []
                                let array`+ res.Elements.Trait +`_PDIE = []
                                let array`+ res.Elements.Trait +`_Others = []
                                //cohort array
                                let arrayCohort`+ res.Elements.Trait +`_Self = []
                                let arrayCohort`+ res.Elements.Trait +`_PSup = []
                                let arrayCohort`+ res.Elements.Trait +`_P = []
                                let arrayCohort`+ res.Elements.Trait +`_D = []
                                let arrayCohort`+ res.Elements.Trait +`_I = []
                                let arrayCohort`+ res.Elements.Trait +`_E = []
                                let arrayCohort`+ res.Elements.Trait +`_PD = []
                                let arrayCohort`+ res.Elements.Trait +`_IE = []
                                let arrayCohort`+ res.Elements.Trait +`_PDIE = []
                                let arrayCohort`+ res.Elements.Trait +`_Others = []

                                // score 1
                                let score1`+ res.Elements.Trait +`_Self = 0
                                let score1`+ res.Elements.Trait +`_PSup = 0
                                let score1`+ res.Elements.Trait +`_P = 0
                                let score1`+ res.Elements.Trait +`_D = 0
                                let score1`+ res.Elements.Trait +`_I = 0
                                let score1`+ res.Elements.Trait +`_E = 0
                                let score1`+ res.Elements.Trait +`_PD = 0
                                let score1`+ res.Elements.Trait +`_IE = 0
                                let score1`+ res.Elements.Trait +`_PDIE = 0
                                let score1`+ res.Elements.Trait +`_Others = 0
                                // score 2
                                let score2`+ res.Elements.Trait +`_Self = 0
                                let score2`+ res.Elements.Trait +`_PSup = 0
                                let score2`+ res.Elements.Trait +`_P = 0
                                let score2`+ res.Elements.Trait +`_D = 0
                                let score2`+ res.Elements.Trait +`_I = 0
                                let score2`+ res.Elements.Trait +`_E = 0
                                let score2`+ res.Elements.Trait +`_PD = 0
                                let score2`+ res.Elements.Trait +`_IE = 0
                                let score2`+ res.Elements.Trait +`_PDIE = 0
                                let score2`+ res.Elements.Trait +`_Others = 0
                                // score 3
                                let score3`+ res.Elements.Trait +`_Self = 0
                                let score3`+ res.Elements.Trait +`_PSup = 0
                                let score3`+ res.Elements.Trait +`_P = 0
                                let score3`+ res.Elements.Trait +`_D = 0
                                let score3`+ res.Elements.Trait +`_I = 0
                                let score3`+ res.Elements.Trait +`_E = 0
                                let score3`+ res.Elements.Trait +`_PD = 0
                                let score3`+ res.Elements.Trait +`_IE = 0
                                let score3`+ res.Elements.Trait +`_PDIE = 0
                                let score3`+ res.Elements.Trait +`_Others = 0
                                // score 4
                                let score4`+ res.Elements.Trait +`_Self = 0
                                let score4`+ res.Elements.Trait +`_PSup = 0
                                let score4`+ res.Elements.Trait +`_P = 0
                                let score4`+ res.Elements.Trait +`_D = 0
                                let score4`+ res.Elements.Trait +`_I = 0
                                let score4`+ res.Elements.Trait +`_E = 0
                                let score4`+ res.Elements.Trait +`_PD = 0
                                let score4`+ res.Elements.Trait +`_IE = 0
                                let score4`+ res.Elements.Trait +`_PDIE = 0
                                let score4`+ res.Elements.Trait +`_Others = 0
                                // score 5
                                let score5`+ res.Elements.Trait +`_Self = 0
                                let score5`+ res.Elements.Trait +`_PSup = 0
                                let score5`+ res.Elements.Trait +`_P = 0
                                let score5`+ res.Elements.Trait +`_D = 0
                                let score5`+ res.Elements.Trait +`_I = 0
                                let score5`+ res.Elements.Trait +`_E = 0
                                let score5`+ res.Elements.Trait +`_PD = 0
                                let score5`+ res.Elements.Trait +`_IE = 0
                                let score5`+ res.Elements.Trait +`_PDIE = 0
                                let score5`+ res.Elements.Trait +`_Others = 0
                                

                                // cohort score 1
                                let cohortScore1`+ res.Elements.Trait +`_Self = 0
                                let cohortScore1`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore1`+ res.Elements.Trait +`_P = 0
                                let cohortScore1`+ res.Elements.Trait +`_D = 0
                                let cohortScore1`+ res.Elements.Trait +`_I = 0
                                let cohortScore1`+ res.Elements.Trait +`_E = 0
                                let cohortScore1`+ res.Elements.Trait +`_PD = 0
                                let cohortScore1`+ res.Elements.Trait +`_IE = 0
                                let cohortScore1`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore1`+ res.Elements.Trait +`_Others = 0
                                // cohort score 2
                                let cohortScore2`+ res.Elements.Trait +`_Self = 0
                                let cohortScore2`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore2`+ res.Elements.Trait +`_P = 0
                                let cohortScore2`+ res.Elements.Trait +`_D = 0
                                let cohortScore2`+ res.Elements.Trait +`_I = 0
                                let cohortScore2`+ res.Elements.Trait +`_E = 0
                                let cohortScore2`+ res.Elements.Trait +`_PD = 0
                                let cohortScore2`+ res.Elements.Trait +`_IE = 0
                                let cohortScore2`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore2`+ res.Elements.Trait +`_Others = 0
                                // cohort score 3
                                let cohortScore3`+ res.Elements.Trait +`_Self = 0
                                let cohortScore3`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore3`+ res.Elements.Trait +`_P = 0
                                let cohortScore3`+ res.Elements.Trait +`_D = 0
                                let cohortScore3`+ res.Elements.Trait +`_I = 0
                                let cohortScore3`+ res.Elements.Trait +`_E = 0
                                let cohortScore3`+ res.Elements.Trait +`_PD = 0
                                let cohortScore3`+ res.Elements.Trait +`_IE = 0
                                let cohortScore3`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore3`+ res.Elements.Trait +`_Others = 0
                                // cohort score 4
                                let cohortScore4`+ res.Elements.Trait +`_Self = 0
                                let cohortScore4`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore4`+ res.Elements.Trait +`_P = 0
                                let cohortScore4`+ res.Elements.Trait +`_D = 0
                                let cohortScore4`+ res.Elements.Trait +`_I = 0
                                let cohortScore4`+ res.Elements.Trait +`_E = 0
                                let cohortScore4`+ res.Elements.Trait +`_PD = 0
                                let cohortScore4`+ res.Elements.Trait +`_IE = 0
                                let cohortScore4`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore4`+ res.Elements.Trait +`_Others = 0
                                // cohort score 5
                                let cohortScore5`+ res.Elements.Trait +`_Self = 0
                                let cohortScore5`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore5`+ res.Elements.Trait +`_P = 0
                                let cohortScore5`+ res.Elements.Trait +`_D = 0
                                let cohortScore5`+ res.Elements.Trait +`_I = 0
                                let cohortScore5`+ res.Elements.Trait +`_E = 0
                                let cohortScore5`+ res.Elements.Trait +`_PD = 0
                                let cohortScore5`+ res.Elements.Trait +`_IE = 0
                                let cohortScore5`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore5`+ res.Elements.Trait +`_Others = 0

                                for (let i=0; i<resHistogram.data.length; i++){
                                  // get the score 1
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 2
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 3
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 4
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 5
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                //put data into array
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }
                                }

                                // add numbers in 1 row for ranking 0 to 100%
                                array`+ res.Elements.Trait +`_Self.push(score1`+ res.Elements.Trait +`_Self+score2`+ res.Elements.Trait +`_Self+score3`+ res.Elements.Trait +`_Self+score4`+ res.Elements.Trait +`_Self+score5`+ res.Elements.Trait +`_Self)
                                array`+ res.Elements.Trait +`_PSup.push(score1`+ res.Elements.Trait +`_PSup+score2`+ res.Elements.Trait +`_PSup+score3`+ res.Elements.Trait +`_PSup+score4`+ res.Elements.Trait +`_PSup+score5`+ res.Elements.Trait +`_PSup)
                                array`+ res.Elements.Trait +`_P.push(score1`+ res.Elements.Trait +`_P+score2`+ res.Elements.Trait +`_P+score3`+ res.Elements.Trait +`_P+score4`+ res.Elements.Trait +`_P+score5`+ res.Elements.Trait +`_P)
                                array`+ res.Elements.Trait +`_D.push(score1`+ res.Elements.Trait +`_D+score2`+ res.Elements.Trait +`_D+score3`+ res.Elements.Trait +`_D+score4`+ res.Elements.Trait +`_D+score5`+ res.Elements.Trait +`_D)
                                array`+ res.Elements.Trait +`_I.push(score1`+ res.Elements.Trait +`_I+score2`+ res.Elements.Trait +`_I+score3`+ res.Elements.Trait +`_I+score4`+ res.Elements.Trait +`_I+score5`+ res.Elements.Trait +`_I)
                                array`+ res.Elements.Trait +`_E.push(score1`+ res.Elements.Trait +`_E+score2`+ res.Elements.Trait +`_E+score3`+ res.Elements.Trait +`_E+score4`+ res.Elements.Trait +`_E+score5`+ res.Elements.Trait +`_E)
                                array`+ res.Elements.Trait +`_PD.push(score1`+ res.Elements.Trait +`_PD+score2`+ res.Elements.Trait +`_PD+score3`+ res.Elements.Trait +`_PD+score4`+ res.Elements.Trait +`_PD+score5`+ res.Elements.Trait +`_PD)
                                array`+ res.Elements.Trait +`_IE.push(score1`+ res.Elements.Trait +`_IE+score2`+ res.Elements.Trait +`_IE+score3`+ res.Elements.Trait +`_IE+score4`+ res.Elements.Trait +`_IE+score5`+ res.Elements.Trait +`_IE)
                                array`+ res.Elements.Trait +`_PDIE.push(score1`+ res.Elements.Trait +`_PDIE+score2`+ res.Elements.Trait +`_PDIE+score3`+ res.Elements.Trait +`_PDIE+score4`+ res.Elements.Trait +`_PDIE+score5`+ res.Elements.Trait +`_PDIE)
                                array`+ res.Elements.Trait +`_Others.push(score1`+ res.Elements.Trait +`_Others+score2`+ res.Elements.Trait +`_Others+score3`+ res.Elements.Trait +`_Others+score4`+ res.Elements.Trait +`_Others+score5`+ res.Elements.Trait +`_Others)
                                //cohort
                                arrayCohort`+ res.Elements.Trait +`_Self.push(cohortScore1`+ res.Elements.Trait +`_Self+cohortScore2`+ res.Elements.Trait +`_Self+cohortScore3`+ res.Elements.Trait +`_Self+cohortScore4`+ res.Elements.Trait +`_Self+cohortScore5`+ res.Elements.Trait +`_Self)
                                arrayCohort`+ res.Elements.Trait +`_PSup.push(cohortScore1`+ res.Elements.Trait +`_PSup+cohortScore2`+ res.Elements.Trait +`_PSup+cohortScore3`+ res.Elements.Trait +`_PSup+cohortScore4`+ res.Elements.Trait +`_PSup+cohortScore5`+ res.Elements.Trait +`_PSup)
                                arrayCohort`+ res.Elements.Trait +`_P.push(cohortScore1`+ res.Elements.Trait +`_P+cohortScore2`+ res.Elements.Trait +`_P+cohortScore3`+ res.Elements.Trait +`_P+cohortScore4`+ res.Elements.Trait +`_P+cohortScore5`+ res.Elements.Trait +`_P)
                                arrayCohort`+ res.Elements.Trait +`_D.push(cohortScore1`+ res.Elements.Trait +`_D+cohortScore2`+ res.Elements.Trait +`_D+cohortScore3`+ res.Elements.Trait +`_D+cohortScore4`+ res.Elements.Trait +`_D+cohortScore5`+ res.Elements.Trait +`_D)
                                arrayCohort`+ res.Elements.Trait +`_I.push(cohortScore1`+ res.Elements.Trait +`_I+cohortScore2`+ res.Elements.Trait +`_I+cohortScore3`+ res.Elements.Trait +`_I+cohortScore4`+ res.Elements.Trait +`_I+cohortScore5`+ res.Elements.Trait +`_I)
                                arrayCohort`+ res.Elements.Trait +`_E.push(cohortScore1`+ res.Elements.Trait +`_E+cohortScore2`+ res.Elements.Trait +`_E+cohortScore3`+ res.Elements.Trait +`_E+cohortScore4`+ res.Elements.Trait +`_E+cohortScore5`+ res.Elements.Trait +`_E)
                                arrayCohort`+ res.Elements.Trait +`_PD.push(cohortScore1`+ res.Elements.Trait +`_PD+cohortScore2`+ res.Elements.Trait +`_PD+cohortScore3`+ res.Elements.Trait +`_PD+cohortScore4`+ res.Elements.Trait +`_PD+cohortScore5`+ res.Elements.Trait +`_PD)
                                arrayCohort`+ res.Elements.Trait +`_IE.push(cohortScore1`+ res.Elements.Trait +`_IE+cohortScore2`+ res.Elements.Trait +`_IE+cohortScore3`+ res.Elements.Trait +`_IE+cohortScore4`+ res.Elements.Trait +`_IE+cohortScore5`+ res.Elements.Trait +`_IE)
                                arrayCohort`+ res.Elements.Trait +`_PDIE.push(cohortScore1`+ res.Elements.Trait +`_PDIE+cohortScore2`+ res.Elements.Trait +`_PDIE+cohortScore3`+ res.Elements.Trait +`_PDIE+cohortScore4`+ res.Elements.Trait +`_PDIE+cohortScore5`+ res.Elements.Trait +`_PDIE)
                                arrayCohort`+ res.Elements.Trait +`_Others.push(cohortScore1`+ res.Elements.Trait +`_Others+cohortScore2`+ res.Elements.Trait +`_Others+cohortScore3`+ res.Elements.Trait +`_Others+cohortScore4`+ res.Elements.Trait +`_Others+cohortScore5`+ res.Elements.Trait +`_Others)
                            `
                        }
                      }
                      // part 5 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `
                            // for Rolled-up Total
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)

                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)
                                
                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PD + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PD + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PD + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PD + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PD + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)
                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }
                              
                              if (isDisplay_PDIE === 1) {
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');
                                
                              }
                            `
                        }
                      }
                      // part 5.1 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self
                                let histogramScore1_`+ res.Elements.Trait +`_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_Self +'%');
                                //self section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Self = rankRange(score1`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Self = rankRange(score2`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Self = rankRange(score3`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Self = rankRange(score4`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Self = rankRange(score5`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Self +'%');

                                //psup section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_PSup +'%');
                                //psup section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PSup = rankRange(score1`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PSup = rankRange(score2`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PSup = rankRange(score3`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PSup = rankRange(score4`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PSup = rankRange(score5`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PSup +'%');

                                //peers section 2
                                let histogramScore1_`+ res.Elements.Trait +`_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-P").css('height', histogramScore1_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-P").css('height', histogramScore2_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-P").css('height', histogramScore3_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-P").css('height', histogramScore4_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-P").css('height', histogramScore5_`+ res.Elements.Trait +`_P +'%');
                                //peers section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_P = rankRange(score1`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_P = rankRange(score2`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_P = rankRange(score3`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_P = rankRange(score4`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_P = rankRange(score5`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_P +'%');

                                //direct reports section 2
                                let histogramScore1_`+ res.Elements.Trait +`_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-D").css('height', histogramScore1_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-D").css('height', histogramScore2_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-D").css('height', histogramScore3_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-D").css('height', histogramScore4_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-D").css('height', histogramScore5_`+ res.Elements.Trait +`_D +'%');
                                //direct reports section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_D = rankRange(score1`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_D = rankRange(score2`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_D = rankRange(score3`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_D = rankRange(score4`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_D = rankRange(score5`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_D +'%');
                                
                                //internal stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-I").css('height', histogramScore1_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-I").css('height', histogramScore2_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-I").css('height', histogramScore3_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-I").css('height', histogramScore4_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-I").css('height', histogramScore5_`+ res.Elements.Trait +`_I +'%');
                                //internal stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_I = rankRange(score1`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_I = rankRange(score2`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_I = rankRange(score3`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_I = rankRange(score4`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_I = rankRange(score5`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_I +'%');

                                //external stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-E").css('height', histogramScore1_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-E").css('height', histogramScore2_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-E").css('height', histogramScore3_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-E").css('height', histogramScore4_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-E").css('height', histogramScore5_`+ res.Elements.Trait +`_E +'%');
                                //external stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_E = rankRange(score1`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_E = rankRange(score2`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_E = rankRange(score3`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_E = rankRange(score4`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_E = rankRange(score5`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_E +'%');

                                
                                //peers/direct section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_PD +'%');
                                //peers/direct section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PD = rankRange(score1`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PD = rankRange(score2`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PD = rankRange(score3`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PD = rankRange(score4`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PD = rankRange(score5`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PD +'%');

                                //internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_IE +'%');
                                //internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_IE = rankRange(score1`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_IE = rankRange(score2`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_IE = rankRange(score3`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_IE = rankRange(score4`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_IE = rankRange(score5`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_IE +'%');

                                //peers/direct/internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_PDIE +'%');
                                //peers/direct/internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score1`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score2`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score3`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score4`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score5`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PDIE +'%');

                                //All Nominees section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Others = rankRange(score1`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Others = rankRange(score2`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Others = rankRange(score3`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Others = rankRange(score4`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Others = rankRange(score5`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Others +'%');
                                
                              

                            `
                        }
                      }
                      // part 6 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            // for Rolled-up Total Cohort
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              
                              if (isDisplay_PDIE === 1) {
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }

                            `
                        }
                      }
                      // part 6.1 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self +'%');

                              //psup section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              //section3-8 
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup +'%');

                              //peers section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_P +'%');

                              //direct reports section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_D +'%');

                              //internal stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_I +'%');

                              //external stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_E +'%');

                              //peers/direct section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD +'%');

                              //internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE +'%');

                              //peers/direct/internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');



                            `   
                        }
                      }
                  }
                }
                if (data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others-NPS') {
                  let obj = JSON.parse(data.Text)
                  console.log('obj upper level :', obj)
                  for (let i = 0; i < input.length; i++) {
                  const data = input[i]
                    // part 4 of Histogram
                    if (data.Template === 'Add-Histogram') { 
                        console.log('obj lower level :', obj)
                        for (const res of obj) {
                            output += `
                            //individual array
                                let array`+ res.Elements.Trait +`_Self = []
                                let array`+ res.Elements.Trait +`_PSup = []
                                let array`+ res.Elements.Trait +`_P = []
                                let array`+ res.Elements.Trait +`_D = []
                                let array`+ res.Elements.Trait +`_I = []
                                let array`+ res.Elements.Trait +`_E = []
                                let array`+ res.Elements.Trait +`_PD = []
                                let array`+ res.Elements.Trait +`_IE = []
                                let array`+ res.Elements.Trait +`_PDIE = []
                                let array`+ res.Elements.Trait +`_Others = []
                                //cohort array
                                let arrayCohort`+ res.Elements.Trait +`_Self = []
                                let arrayCohort`+ res.Elements.Trait +`_PSup = []
                                let arrayCohort`+ res.Elements.Trait +`_P = []
                                let arrayCohort`+ res.Elements.Trait +`_D = []
                                let arrayCohort`+ res.Elements.Trait +`_I = []
                                let arrayCohort`+ res.Elements.Trait +`_E = []
                                let arrayCohort`+ res.Elements.Trait +`_PD = []
                                let arrayCohort`+ res.Elements.Trait +`_IE = []
                                let arrayCohort`+ res.Elements.Trait +`_PDIE = []
                                let arrayCohort`+ res.Elements.Trait +`_Others = []

                                // score 1
                                let score1`+ res.Elements.Trait +`_Self = 0
                                let score1`+ res.Elements.Trait +`_PSup = 0
                                let score1`+ res.Elements.Trait +`_P = 0
                                let score1`+ res.Elements.Trait +`_D = 0
                                let score1`+ res.Elements.Trait +`_I = 0
                                let score1`+ res.Elements.Trait +`_E = 0
                                let score1`+ res.Elements.Trait +`_PD = 0
                                let score1`+ res.Elements.Trait +`_IE = 0
                                let score1`+ res.Elements.Trait +`_PDIE = 0
                                let score1`+ res.Elements.Trait +`_Others = 0
                                // score 2
                                let score2`+ res.Elements.Trait +`_Self = 0
                                let score2`+ res.Elements.Trait +`_PSup = 0
                                let score2`+ res.Elements.Trait +`_P = 0
                                let score2`+ res.Elements.Trait +`_D = 0
                                let score2`+ res.Elements.Trait +`_I = 0
                                let score2`+ res.Elements.Trait +`_E = 0
                                let score2`+ res.Elements.Trait +`_PD = 0
                                let score2`+ res.Elements.Trait +`_IE = 0
                                let score2`+ res.Elements.Trait +`_PDIE = 0
                                let score2`+ res.Elements.Trait +`_Others = 0
                                // score 3
                                let score3`+ res.Elements.Trait +`_Self = 0
                                let score3`+ res.Elements.Trait +`_PSup = 0
                                let score3`+ res.Elements.Trait +`_P = 0
                                let score3`+ res.Elements.Trait +`_D = 0
                                let score3`+ res.Elements.Trait +`_I = 0
                                let score3`+ res.Elements.Trait +`_E = 0
                                let score3`+ res.Elements.Trait +`_PD = 0
                                let score3`+ res.Elements.Trait +`_IE = 0
                                let score3`+ res.Elements.Trait +`_PDIE = 0
                                let score3`+ res.Elements.Trait +`_Others = 0
                                // score 4
                                let score4`+ res.Elements.Trait +`_Self = 0
                                let score4`+ res.Elements.Trait +`_PSup = 0
                                let score4`+ res.Elements.Trait +`_P = 0
                                let score4`+ res.Elements.Trait +`_D = 0
                                let score4`+ res.Elements.Trait +`_I = 0
                                let score4`+ res.Elements.Trait +`_E = 0
                                let score4`+ res.Elements.Trait +`_PD = 0
                                let score4`+ res.Elements.Trait +`_IE = 0
                                let score4`+ res.Elements.Trait +`_PDIE = 0
                                let score4`+ res.Elements.Trait +`_Others = 0
                                // score 5
                                let score5`+ res.Elements.Trait +`_Self = 0
                                let score5`+ res.Elements.Trait +`_PSup = 0
                                let score5`+ res.Elements.Trait +`_P = 0
                                let score5`+ res.Elements.Trait +`_D = 0
                                let score5`+ res.Elements.Trait +`_I = 0
                                let score5`+ res.Elements.Trait +`_E = 0
                                let score5`+ res.Elements.Trait +`_PD = 0
                                let score5`+ res.Elements.Trait +`_IE = 0
                                let score5`+ res.Elements.Trait +`_PDIE = 0
                                let score5`+ res.Elements.Trait +`_Others = 0
                                

                                // cohort score 1
                                let cohortScore1`+ res.Elements.Trait +`_Self = 0
                                let cohortScore1`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore1`+ res.Elements.Trait +`_P = 0
                                let cohortScore1`+ res.Elements.Trait +`_D = 0
                                let cohortScore1`+ res.Elements.Trait +`_I = 0
                                let cohortScore1`+ res.Elements.Trait +`_E = 0
                                let cohortScore1`+ res.Elements.Trait +`_PD = 0
                                let cohortScore1`+ res.Elements.Trait +`_IE = 0
                                let cohortScore1`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore1`+ res.Elements.Trait +`_Others = 0
                                // cohort score 2
                                let cohortScore2`+ res.Elements.Trait +`_Self = 0
                                let cohortScore2`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore2`+ res.Elements.Trait +`_P = 0
                                let cohortScore2`+ res.Elements.Trait +`_D = 0
                                let cohortScore2`+ res.Elements.Trait +`_I = 0
                                let cohortScore2`+ res.Elements.Trait +`_E = 0
                                let cohortScore2`+ res.Elements.Trait +`_PD = 0
                                let cohortScore2`+ res.Elements.Trait +`_IE = 0
                                let cohortScore2`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore2`+ res.Elements.Trait +`_Others = 0
                                // cohort score 3
                                let cohortScore3`+ res.Elements.Trait +`_Self = 0
                                let cohortScore3`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore3`+ res.Elements.Trait +`_P = 0
                                let cohortScore3`+ res.Elements.Trait +`_D = 0
                                let cohortScore3`+ res.Elements.Trait +`_I = 0
                                let cohortScore3`+ res.Elements.Trait +`_E = 0
                                let cohortScore3`+ res.Elements.Trait +`_PD = 0
                                let cohortScore3`+ res.Elements.Trait +`_IE = 0
                                let cohortScore3`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore3`+ res.Elements.Trait +`_Others = 0
                                // cohort score 4
                                let cohortScore4`+ res.Elements.Trait +`_Self = 0
                                let cohortScore4`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore4`+ res.Elements.Trait +`_P = 0
                                let cohortScore4`+ res.Elements.Trait +`_D = 0
                                let cohortScore4`+ res.Elements.Trait +`_I = 0
                                let cohortScore4`+ res.Elements.Trait +`_E = 0
                                let cohortScore4`+ res.Elements.Trait +`_PD = 0
                                let cohortScore4`+ res.Elements.Trait +`_IE = 0
                                let cohortScore4`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore4`+ res.Elements.Trait +`_Others = 0
                                // cohort score 5
                                let cohortScore5`+ res.Elements.Trait +`_Self = 0
                                let cohortScore5`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore5`+ res.Elements.Trait +`_P = 0
                                let cohortScore5`+ res.Elements.Trait +`_D = 0
                                let cohortScore5`+ res.Elements.Trait +`_I = 0
                                let cohortScore5`+ res.Elements.Trait +`_E = 0
                                let cohortScore5`+ res.Elements.Trait +`_PD = 0
                                let cohortScore5`+ res.Elements.Trait +`_IE = 0
                                let cohortScore5`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore5`+ res.Elements.Trait +`_Others = 0

                                for (let i=0; i<resHistogram.data.length; i++){
                                  // get the score 1
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 2
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 3
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 4
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 5
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                //put data into array
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }
                                }

                                // add numbers in 1 row for ranking 0 to 100%
                                array`+ res.Elements.Trait +`_Self.push(score1`+ res.Elements.Trait +`_Self+score2`+ res.Elements.Trait +`_Self+score3`+ res.Elements.Trait +`_Self+score4`+ res.Elements.Trait +`_Self+score5`+ res.Elements.Trait +`_Self)
                                array`+ res.Elements.Trait +`_PSup.push(score1`+ res.Elements.Trait +`_PSup+score2`+ res.Elements.Trait +`_PSup+score3`+ res.Elements.Trait +`_PSup+score4`+ res.Elements.Trait +`_PSup+score5`+ res.Elements.Trait +`_PSup)
                                array`+ res.Elements.Trait +`_P.push(score1`+ res.Elements.Trait +`_P+score2`+ res.Elements.Trait +`_P+score3`+ res.Elements.Trait +`_P+score4`+ res.Elements.Trait +`_P+score5`+ res.Elements.Trait +`_P)
                                array`+ res.Elements.Trait +`_D.push(score1`+ res.Elements.Trait +`_D+score2`+ res.Elements.Trait +`_D+score3`+ res.Elements.Trait +`_D+score4`+ res.Elements.Trait +`_D+score5`+ res.Elements.Trait +`_D)
                                array`+ res.Elements.Trait +`_I.push(score1`+ res.Elements.Trait +`_I+score2`+ res.Elements.Trait +`_I+score3`+ res.Elements.Trait +`_I+score4`+ res.Elements.Trait +`_I+score5`+ res.Elements.Trait +`_I)
                                array`+ res.Elements.Trait +`_E.push(score1`+ res.Elements.Trait +`_E+score2`+ res.Elements.Trait +`_E+score3`+ res.Elements.Trait +`_E+score4`+ res.Elements.Trait +`_E+score5`+ res.Elements.Trait +`_E)
                                array`+ res.Elements.Trait +`_PD.push(score1`+ res.Elements.Trait +`_PD+score2`+ res.Elements.Trait +`_PD+score3`+ res.Elements.Trait +`_PD+score4`+ res.Elements.Trait +`_PD+score5`+ res.Elements.Trait +`_PD)
                                array`+ res.Elements.Trait +`_IE.push(score1`+ res.Elements.Trait +`_IE+score2`+ res.Elements.Trait +`_IE+score3`+ res.Elements.Trait +`_IE+score4`+ res.Elements.Trait +`_IE+score5`+ res.Elements.Trait +`_IE)
                                array`+ res.Elements.Trait +`_PDIE.push(score1`+ res.Elements.Trait +`_PDIE+score2`+ res.Elements.Trait +`_PDIE+score3`+ res.Elements.Trait +`_PDIE+score4`+ res.Elements.Trait +`_PDIE+score5`+ res.Elements.Trait +`_PDIE)
                                array`+ res.Elements.Trait +`_Others.push(score1`+ res.Elements.Trait +`_Others+score2`+ res.Elements.Trait +`_Others+score3`+ res.Elements.Trait +`_Others+score4`+ res.Elements.Trait +`_Others+score5`+ res.Elements.Trait +`_Others)
                                //cohort
                                arrayCohort`+ res.Elements.Trait +`_Self.push(cohortScore1`+ res.Elements.Trait +`_Self+cohortScore2`+ res.Elements.Trait +`_Self+cohortScore3`+ res.Elements.Trait +`_Self+cohortScore4`+ res.Elements.Trait +`_Self+cohortScore5`+ res.Elements.Trait +`_Self)
                                arrayCohort`+ res.Elements.Trait +`_PSup.push(cohortScore1`+ res.Elements.Trait +`_PSup+cohortScore2`+ res.Elements.Trait +`_PSup+cohortScore3`+ res.Elements.Trait +`_PSup+cohortScore4`+ res.Elements.Trait +`_PSup+cohortScore5`+ res.Elements.Trait +`_PSup)
                                arrayCohort`+ res.Elements.Trait +`_P.push(cohortScore1`+ res.Elements.Trait +`_P+cohortScore2`+ res.Elements.Trait +`_P+cohortScore3`+ res.Elements.Trait +`_P+cohortScore4`+ res.Elements.Trait +`_P+cohortScore5`+ res.Elements.Trait +`_P)
                                arrayCohort`+ res.Elements.Trait +`_D.push(cohortScore1`+ res.Elements.Trait +`_D+cohortScore2`+ res.Elements.Trait +`_D+cohortScore3`+ res.Elements.Trait +`_D+cohortScore4`+ res.Elements.Trait +`_D+cohortScore5`+ res.Elements.Trait +`_D)
                                arrayCohort`+ res.Elements.Trait +`_I.push(cohortScore1`+ res.Elements.Trait +`_I+cohortScore2`+ res.Elements.Trait +`_I+cohortScore3`+ res.Elements.Trait +`_I+cohortScore4`+ res.Elements.Trait +`_I+cohortScore5`+ res.Elements.Trait +`_I)
                                arrayCohort`+ res.Elements.Trait +`_E.push(cohortScore1`+ res.Elements.Trait +`_E+cohortScore2`+ res.Elements.Trait +`_E+cohortScore3`+ res.Elements.Trait +`_E+cohortScore4`+ res.Elements.Trait +`_E+cohortScore5`+ res.Elements.Trait +`_E)
                                arrayCohort`+ res.Elements.Trait +`_PD.push(cohortScore1`+ res.Elements.Trait +`_PD+cohortScore2`+ res.Elements.Trait +`_PD+cohortScore3`+ res.Elements.Trait +`_PD+cohortScore4`+ res.Elements.Trait +`_PD+cohortScore5`+ res.Elements.Trait +`_PD)
                                arrayCohort`+ res.Elements.Trait +`_IE.push(cohortScore1`+ res.Elements.Trait +`_IE+cohortScore2`+ res.Elements.Trait +`_IE+cohortScore3`+ res.Elements.Trait +`_IE+cohortScore4`+ res.Elements.Trait +`_IE+cohortScore5`+ res.Elements.Trait +`_IE)
                                arrayCohort`+ res.Elements.Trait +`_PDIE.push(cohortScore1`+ res.Elements.Trait +`_PDIE+cohortScore2`+ res.Elements.Trait +`_PDIE+cohortScore3`+ res.Elements.Trait +`_PDIE+cohortScore4`+ res.Elements.Trait +`_PDIE+cohortScore5`+ res.Elements.Trait +`_PDIE)
                                arrayCohort`+ res.Elements.Trait +`_Others.push(cohortScore1`+ res.Elements.Trait +`_Others+cohortScore2`+ res.Elements.Trait +`_Others+cohortScore3`+ res.Elements.Trait +`_Others+cohortScore4`+ res.Elements.Trait +`_Others+cohortScore5`+ res.Elements.Trait +`_Others)
                            `
                        }
                      }
                      // part 5 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `
                            // for Rolled-up Total
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)

                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)
                                
                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PD + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PD + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PD + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PD + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PD + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)
                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }
                              
                              if (isDisplay_PDIE === 1) {
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');
                                
                              }
                            `
                        }
                      }
                      // part 5.1 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self
                                let histogramScore1_`+ res.Elements.Trait +`_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_Self +'%');
                                //self section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Self = rankRange(score1`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Self = rankRange(score2`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Self = rankRange(score3`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Self = rankRange(score4`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Self = rankRange(score5`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Self +'%');

                                //psup section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_PSup +'%');
                                //psup section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PSup = rankRange(score1`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PSup = rankRange(score2`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PSup = rankRange(score3`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PSup = rankRange(score4`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PSup = rankRange(score5`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PSup +'%');

                                //peers section 2
                                let histogramScore1_`+ res.Elements.Trait +`_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-P").css('height', histogramScore1_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-P").css('height', histogramScore2_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-P").css('height', histogramScore3_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-P").css('height', histogramScore4_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-P").css('height', histogramScore5_`+ res.Elements.Trait +`_P +'%');
                                //peers section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_P = rankRange(score1`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_P = rankRange(score2`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_P = rankRange(score3`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_P = rankRange(score4`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_P = rankRange(score5`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_P +'%');

                                //direct reports section 2
                                let histogramScore1_`+ res.Elements.Trait +`_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-D").css('height', histogramScore1_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-D").css('height', histogramScore2_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-D").css('height', histogramScore3_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-D").css('height', histogramScore4_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-D").css('height', histogramScore5_`+ res.Elements.Trait +`_D +'%');
                                //direct reports section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_D = rankRange(score1`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_D = rankRange(score2`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_D = rankRange(score3`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_D = rankRange(score4`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_D = rankRange(score5`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_D +'%');
                                
                                //internal stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-I").css('height', histogramScore1_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-I").css('height', histogramScore2_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-I").css('height', histogramScore3_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-I").css('height', histogramScore4_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-I").css('height', histogramScore5_`+ res.Elements.Trait +`_I +'%');
                                //internal stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_I = rankRange(score1`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_I = rankRange(score2`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_I = rankRange(score3`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_I = rankRange(score4`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_I = rankRange(score5`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_I +'%');

                                //external stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-E").css('height', histogramScore1_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-E").css('height', histogramScore2_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-E").css('height', histogramScore3_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-E").css('height', histogramScore4_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-E").css('height', histogramScore5_`+ res.Elements.Trait +`_E +'%');
                                //external stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_E = rankRange(score1`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_E = rankRange(score2`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_E = rankRange(score3`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_E = rankRange(score4`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_E = rankRange(score5`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_E +'%');

                                
                                //peers/direct section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_PD +'%');
                                //peers/direct section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PD = rankRange(score1`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PD = rankRange(score2`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PD = rankRange(score3`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PD = rankRange(score4`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PD = rankRange(score5`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PD +'%');

                                //internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_IE +'%');
                                //internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_IE = rankRange(score1`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_IE = rankRange(score2`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_IE = rankRange(score3`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_IE = rankRange(score4`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_IE = rankRange(score5`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_IE +'%');

                                //peers/direct/internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_PDIE +'%');
                                //peers/direct/internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score1`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score2`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score3`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score4`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score5`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PDIE +'%');

                                //All Nominees section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Others = rankRange(score1`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Others = rankRange(score2`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Others = rankRange(score3`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Others = rankRange(score4`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Others = rankRange(score5`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Others +'%');
                                
                              

                            `
                        }
                      }
                      // part 6 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            // for Rolled-up Total Cohort
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              
                              if (isDisplay_PDIE === 1) {
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }

                            `
                        }
                      }
                      // part 6.1 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self +'%');

                              //psup section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              //section3-8 
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup +'%');

                              //peers section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_P +'%');

                              //direct reports section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_D +'%');

                              //internal stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_I +'%');

                              //external stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_E +'%');

                              //peers/direct section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD +'%');

                              //internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE +'%');

                              //peers/direct/internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');



                            `   
                        }
                      }
                  }
                }
                if (data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others-NPS') {
                  let obj = JSON.parse(data.Text)
                  console.log('obj upper level :', obj)
                  for (let i = 0; i < input.length; i++) {
                  const data = input[i]
                      // part 4 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        console.log('obj lower level :', obj)
                        for (const res of obj) {
                            output += `
                            //individual array
                                let array`+ res.Elements.Trait +`_Self = []
                                let array`+ res.Elements.Trait +`_PSup = []
                                let array`+ res.Elements.Trait +`_P = []
                                let array`+ res.Elements.Trait +`_D = []
                                let array`+ res.Elements.Trait +`_I = []
                                let array`+ res.Elements.Trait +`_E = []
                                let array`+ res.Elements.Trait +`_PD = []
                                let array`+ res.Elements.Trait +`_IE = []
                                let array`+ res.Elements.Trait +`_PDIE = []
                                let array`+ res.Elements.Trait +`_Others = []
                                //cohort array
                                let arrayCohort`+ res.Elements.Trait +`_Self = []
                                let arrayCohort`+ res.Elements.Trait +`_PSup = []
                                let arrayCohort`+ res.Elements.Trait +`_P = []
                                let arrayCohort`+ res.Elements.Trait +`_D = []
                                let arrayCohort`+ res.Elements.Trait +`_I = []
                                let arrayCohort`+ res.Elements.Trait +`_E = []
                                let arrayCohort`+ res.Elements.Trait +`_PD = []
                                let arrayCohort`+ res.Elements.Trait +`_IE = []
                                let arrayCohort`+ res.Elements.Trait +`_PDIE = []
                                let arrayCohort`+ res.Elements.Trait +`_Others = []

                                // score 1
                                let score1`+ res.Elements.Trait +`_Self = 0
                                let score1`+ res.Elements.Trait +`_PSup = 0
                                let score1`+ res.Elements.Trait +`_P = 0
                                let score1`+ res.Elements.Trait +`_D = 0
                                let score1`+ res.Elements.Trait +`_I = 0
                                let score1`+ res.Elements.Trait +`_E = 0
                                let score1`+ res.Elements.Trait +`_PD = 0
                                let score1`+ res.Elements.Trait +`_IE = 0
                                let score1`+ res.Elements.Trait +`_PDIE = 0
                                let score1`+ res.Elements.Trait +`_Others = 0
                                // score 2
                                let score2`+ res.Elements.Trait +`_Self = 0
                                let score2`+ res.Elements.Trait +`_PSup = 0
                                let score2`+ res.Elements.Trait +`_P = 0
                                let score2`+ res.Elements.Trait +`_D = 0
                                let score2`+ res.Elements.Trait +`_I = 0
                                let score2`+ res.Elements.Trait +`_E = 0
                                let score2`+ res.Elements.Trait +`_PD = 0
                                let score2`+ res.Elements.Trait +`_IE = 0
                                let score2`+ res.Elements.Trait +`_PDIE = 0
                                let score2`+ res.Elements.Trait +`_Others = 0
                                // score 3
                                let score3`+ res.Elements.Trait +`_Self = 0
                                let score3`+ res.Elements.Trait +`_PSup = 0
                                let score3`+ res.Elements.Trait +`_P = 0
                                let score3`+ res.Elements.Trait +`_D = 0
                                let score3`+ res.Elements.Trait +`_I = 0
                                let score3`+ res.Elements.Trait +`_E = 0
                                let score3`+ res.Elements.Trait +`_PD = 0
                                let score3`+ res.Elements.Trait +`_IE = 0
                                let score3`+ res.Elements.Trait +`_PDIE = 0
                                let score3`+ res.Elements.Trait +`_Others = 0
                                // score 4
                                let score4`+ res.Elements.Trait +`_Self = 0
                                let score4`+ res.Elements.Trait +`_PSup = 0
                                let score4`+ res.Elements.Trait +`_P = 0
                                let score4`+ res.Elements.Trait +`_D = 0
                                let score4`+ res.Elements.Trait +`_I = 0
                                let score4`+ res.Elements.Trait +`_E = 0
                                let score4`+ res.Elements.Trait +`_PD = 0
                                let score4`+ res.Elements.Trait +`_IE = 0
                                let score4`+ res.Elements.Trait +`_PDIE = 0
                                let score4`+ res.Elements.Trait +`_Others = 0
                                // score 5
                                let score5`+ res.Elements.Trait +`_Self = 0
                                let score5`+ res.Elements.Trait +`_PSup = 0
                                let score5`+ res.Elements.Trait +`_P = 0
                                let score5`+ res.Elements.Trait +`_D = 0
                                let score5`+ res.Elements.Trait +`_I = 0
                                let score5`+ res.Elements.Trait +`_E = 0
                                let score5`+ res.Elements.Trait +`_PD = 0
                                let score5`+ res.Elements.Trait +`_IE = 0
                                let score5`+ res.Elements.Trait +`_PDIE = 0
                                let score5`+ res.Elements.Trait +`_Others = 0
                                

                                // cohort score 1
                                let cohortScore1`+ res.Elements.Trait +`_Self = 0
                                let cohortScore1`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore1`+ res.Elements.Trait +`_P = 0
                                let cohortScore1`+ res.Elements.Trait +`_D = 0
                                let cohortScore1`+ res.Elements.Trait +`_I = 0
                                let cohortScore1`+ res.Elements.Trait +`_E = 0
                                let cohortScore1`+ res.Elements.Trait +`_PD = 0
                                let cohortScore1`+ res.Elements.Trait +`_IE = 0
                                let cohortScore1`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore1`+ res.Elements.Trait +`_Others = 0
                                // cohort score 2
                                let cohortScore2`+ res.Elements.Trait +`_Self = 0
                                let cohortScore2`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore2`+ res.Elements.Trait +`_P = 0
                                let cohortScore2`+ res.Elements.Trait +`_D = 0
                                let cohortScore2`+ res.Elements.Trait +`_I = 0
                                let cohortScore2`+ res.Elements.Trait +`_E = 0
                                let cohortScore2`+ res.Elements.Trait +`_PD = 0
                                let cohortScore2`+ res.Elements.Trait +`_IE = 0
                                let cohortScore2`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore2`+ res.Elements.Trait +`_Others = 0
                                // cohort score 3
                                let cohortScore3`+ res.Elements.Trait +`_Self = 0
                                let cohortScore3`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore3`+ res.Elements.Trait +`_P = 0
                                let cohortScore3`+ res.Elements.Trait +`_D = 0
                                let cohortScore3`+ res.Elements.Trait +`_I = 0
                                let cohortScore3`+ res.Elements.Trait +`_E = 0
                                let cohortScore3`+ res.Elements.Trait +`_PD = 0
                                let cohortScore3`+ res.Elements.Trait +`_IE = 0
                                let cohortScore3`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore3`+ res.Elements.Trait +`_Others = 0
                                // cohort score 4
                                let cohortScore4`+ res.Elements.Trait +`_Self = 0
                                let cohortScore4`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore4`+ res.Elements.Trait +`_P = 0
                                let cohortScore4`+ res.Elements.Trait +`_D = 0
                                let cohortScore4`+ res.Elements.Trait +`_I = 0
                                let cohortScore4`+ res.Elements.Trait +`_E = 0
                                let cohortScore4`+ res.Elements.Trait +`_PD = 0
                                let cohortScore4`+ res.Elements.Trait +`_IE = 0
                                let cohortScore4`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore4`+ res.Elements.Trait +`_Others = 0
                                // cohort score 5
                                let cohortScore5`+ res.Elements.Trait +`_Self = 0
                                let cohortScore5`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore5`+ res.Elements.Trait +`_P = 0
                                let cohortScore5`+ res.Elements.Trait +`_D = 0
                                let cohortScore5`+ res.Elements.Trait +`_I = 0
                                let cohortScore5`+ res.Elements.Trait +`_E = 0
                                let cohortScore5`+ res.Elements.Trait +`_PD = 0
                                let cohortScore5`+ res.Elements.Trait +`_IE = 0
                                let cohortScore5`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore5`+ res.Elements.Trait +`_Others = 0

                                for (let i=0; i<resHistogram.data.length; i++){
                                  // get the score 1
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 2
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 3
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 4
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 5
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                //put data into array
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }
                                }

                                // add numbers in 1 row for ranking 0 to 100%
                                array`+ res.Elements.Trait +`_Self.push(score1`+ res.Elements.Trait +`_Self+score2`+ res.Elements.Trait +`_Self+score3`+ res.Elements.Trait +`_Self+score4`+ res.Elements.Trait +`_Self+score5`+ res.Elements.Trait +`_Self)
                                array`+ res.Elements.Trait +`_PSup.push(score1`+ res.Elements.Trait +`_PSup+score2`+ res.Elements.Trait +`_PSup+score3`+ res.Elements.Trait +`_PSup+score4`+ res.Elements.Trait +`_PSup+score5`+ res.Elements.Trait +`_PSup)
                                array`+ res.Elements.Trait +`_P.push(score1`+ res.Elements.Trait +`_P+score2`+ res.Elements.Trait +`_P+score3`+ res.Elements.Trait +`_P+score4`+ res.Elements.Trait +`_P+score5`+ res.Elements.Trait +`_P)
                                array`+ res.Elements.Trait +`_D.push(score1`+ res.Elements.Trait +`_D+score2`+ res.Elements.Trait +`_D+score3`+ res.Elements.Trait +`_D+score4`+ res.Elements.Trait +`_D+score5`+ res.Elements.Trait +`_D)
                                array`+ res.Elements.Trait +`_I.push(score1`+ res.Elements.Trait +`_I+score2`+ res.Elements.Trait +`_I+score3`+ res.Elements.Trait +`_I+score4`+ res.Elements.Trait +`_I+score5`+ res.Elements.Trait +`_I)
                                array`+ res.Elements.Trait +`_E.push(score1`+ res.Elements.Trait +`_E+score2`+ res.Elements.Trait +`_E+score3`+ res.Elements.Trait +`_E+score4`+ res.Elements.Trait +`_E+score5`+ res.Elements.Trait +`_E)
                                array`+ res.Elements.Trait +`_PD.push(score1`+ res.Elements.Trait +`_PD+score2`+ res.Elements.Trait +`_PD+score3`+ res.Elements.Trait +`_PD+score4`+ res.Elements.Trait +`_PD+score5`+ res.Elements.Trait +`_PD)
                                array`+ res.Elements.Trait +`_IE.push(score1`+ res.Elements.Trait +`_IE+score2`+ res.Elements.Trait +`_IE+score3`+ res.Elements.Trait +`_IE+score4`+ res.Elements.Trait +`_IE+score5`+ res.Elements.Trait +`_IE)
                                array`+ res.Elements.Trait +`_PDIE.push(score1`+ res.Elements.Trait +`_PDIE+score2`+ res.Elements.Trait +`_PDIE+score3`+ res.Elements.Trait +`_PDIE+score4`+ res.Elements.Trait +`_PDIE+score5`+ res.Elements.Trait +`_PDIE)
                                array`+ res.Elements.Trait +`_Others.push(score1`+ res.Elements.Trait +`_Others+score2`+ res.Elements.Trait +`_Others+score3`+ res.Elements.Trait +`_Others+score4`+ res.Elements.Trait +`_Others+score5`+ res.Elements.Trait +`_Others)
                                //cohort
                                arrayCohort`+ res.Elements.Trait +`_Self.push(cohortScore1`+ res.Elements.Trait +`_Self+cohortScore2`+ res.Elements.Trait +`_Self+cohortScore3`+ res.Elements.Trait +`_Self+cohortScore4`+ res.Elements.Trait +`_Self+cohortScore5`+ res.Elements.Trait +`_Self)
                                arrayCohort`+ res.Elements.Trait +`_PSup.push(cohortScore1`+ res.Elements.Trait +`_PSup+cohortScore2`+ res.Elements.Trait +`_PSup+cohortScore3`+ res.Elements.Trait +`_PSup+cohortScore4`+ res.Elements.Trait +`_PSup+cohortScore5`+ res.Elements.Trait +`_PSup)
                                arrayCohort`+ res.Elements.Trait +`_P.push(cohortScore1`+ res.Elements.Trait +`_P+cohortScore2`+ res.Elements.Trait +`_P+cohortScore3`+ res.Elements.Trait +`_P+cohortScore4`+ res.Elements.Trait +`_P+cohortScore5`+ res.Elements.Trait +`_P)
                                arrayCohort`+ res.Elements.Trait +`_D.push(cohortScore1`+ res.Elements.Trait +`_D+cohortScore2`+ res.Elements.Trait +`_D+cohortScore3`+ res.Elements.Trait +`_D+cohortScore4`+ res.Elements.Trait +`_D+cohortScore5`+ res.Elements.Trait +`_D)
                                arrayCohort`+ res.Elements.Trait +`_I.push(cohortScore1`+ res.Elements.Trait +`_I+cohortScore2`+ res.Elements.Trait +`_I+cohortScore3`+ res.Elements.Trait +`_I+cohortScore4`+ res.Elements.Trait +`_I+cohortScore5`+ res.Elements.Trait +`_I)
                                arrayCohort`+ res.Elements.Trait +`_E.push(cohortScore1`+ res.Elements.Trait +`_E+cohortScore2`+ res.Elements.Trait +`_E+cohortScore3`+ res.Elements.Trait +`_E+cohortScore4`+ res.Elements.Trait +`_E+cohortScore5`+ res.Elements.Trait +`_E)
                                arrayCohort`+ res.Elements.Trait +`_PD.push(cohortScore1`+ res.Elements.Trait +`_PD+cohortScore2`+ res.Elements.Trait +`_PD+cohortScore3`+ res.Elements.Trait +`_PD+cohortScore4`+ res.Elements.Trait +`_PD+cohortScore5`+ res.Elements.Trait +`_PD)
                                arrayCohort`+ res.Elements.Trait +`_IE.push(cohortScore1`+ res.Elements.Trait +`_IE+cohortScore2`+ res.Elements.Trait +`_IE+cohortScore3`+ res.Elements.Trait +`_IE+cohortScore4`+ res.Elements.Trait +`_IE+cohortScore5`+ res.Elements.Trait +`_IE)
                                arrayCohort`+ res.Elements.Trait +`_PDIE.push(cohortScore1`+ res.Elements.Trait +`_PDIE+cohortScore2`+ res.Elements.Trait +`_PDIE+cohortScore3`+ res.Elements.Trait +`_PDIE+cohortScore4`+ res.Elements.Trait +`_PDIE+cohortScore5`+ res.Elements.Trait +`_PDIE)
                                arrayCohort`+ res.Elements.Trait +`_Others.push(cohortScore1`+ res.Elements.Trait +`_Others+cohortScore2`+ res.Elements.Trait +`_Others+cohortScore3`+ res.Elements.Trait +`_Others+cohortScore4`+ res.Elements.Trait +`_Others+cohortScore5`+ res.Elements.Trait +`_Others)
                            `
                        }
                      }
                      // part 5 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `
                            // for Rolled-up Total
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)

                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)
                                
                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PD + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PD + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PD + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PD + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PD + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)
                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }
                              
                              if (isDisplay_PDIE === 1) {
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');
                                
                              }
                            `
                        }
                      }
                      // part 5.1 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self
                                let histogramScore1_`+ res.Elements.Trait +`_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_Self +'%');
                                //self section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Self = rankRange(score1`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Self = rankRange(score2`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Self = rankRange(score3`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Self = rankRange(score4`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Self = rankRange(score5`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Self +'%');

                                //psup section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_PSup +'%');
                                //psup section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PSup = rankRange(score1`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PSup = rankRange(score2`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PSup = rankRange(score3`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PSup = rankRange(score4`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PSup = rankRange(score5`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PSup +'%');

                                //peers section 2
                                let histogramScore1_`+ res.Elements.Trait +`_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-P").css('height', histogramScore1_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-P").css('height', histogramScore2_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-P").css('height', histogramScore3_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-P").css('height', histogramScore4_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-P").css('height', histogramScore5_`+ res.Elements.Trait +`_P +'%');
                                //peers section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_P = rankRange(score1`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_P = rankRange(score2`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_P = rankRange(score3`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_P = rankRange(score4`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_P = rankRange(score5`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_P +'%');

                                //direct reports section 2
                                let histogramScore1_`+ res.Elements.Trait +`_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-D").css('height', histogramScore1_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-D").css('height', histogramScore2_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-D").css('height', histogramScore3_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-D").css('height', histogramScore4_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-D").css('height', histogramScore5_`+ res.Elements.Trait +`_D +'%');
                                //direct reports section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_D = rankRange(score1`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_D = rankRange(score2`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_D = rankRange(score3`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_D = rankRange(score4`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_D = rankRange(score5`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_D +'%');
                                
                                //internal stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-I").css('height', histogramScore1_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-I").css('height', histogramScore2_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-I").css('height', histogramScore3_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-I").css('height', histogramScore4_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-I").css('height', histogramScore5_`+ res.Elements.Trait +`_I +'%');
                                //internal stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_I = rankRange(score1`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_I = rankRange(score2`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_I = rankRange(score3`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_I = rankRange(score4`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_I = rankRange(score5`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_I +'%');

                                //external stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-E").css('height', histogramScore1_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-E").css('height', histogramScore2_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-E").css('height', histogramScore3_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-E").css('height', histogramScore4_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-E").css('height', histogramScore5_`+ res.Elements.Trait +`_E +'%');
                                //external stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_E = rankRange(score1`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_E = rankRange(score2`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_E = rankRange(score3`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_E = rankRange(score4`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_E = rankRange(score5`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_E +'%');

                                
                                //peers/direct section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_PD +'%');
                                //peers/direct section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PD = rankRange(score1`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PD = rankRange(score2`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PD = rankRange(score3`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PD = rankRange(score4`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PD = rankRange(score5`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PD +'%');

                                //internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_IE +'%');
                                //internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_IE = rankRange(score1`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_IE = rankRange(score2`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_IE = rankRange(score3`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_IE = rankRange(score4`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_IE = rankRange(score5`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_IE +'%');

                                //peers/direct/internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_PDIE +'%');
                                //peers/direct/internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score1`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score2`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score3`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score4`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score5`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PDIE +'%');

                                //All Nominees section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Others = rankRange(score1`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Others = rankRange(score2`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Others = rankRange(score3`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Others = rankRange(score4`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Others = rankRange(score5`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Others +'%');
                                
                              

                            `
                        }
                      }
                      // part 6 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            // for Rolled-up Total Cohort
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              
                              if (isDisplay_PDIE === 1) {
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }

                            `
                        }
                      }
                      // part 6.1 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self +'%');

                              //psup section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              //section3-8 
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup +'%');

                              //peers section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_P +'%');

                              //direct reports section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_D +'%');

                              //internal stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_I +'%');

                              //external stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_E +'%');

                              //peers/direct section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD +'%');

                              //internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE +'%');

                              //peers/direct/internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');



                            `   
                        }
                      }                 
                  }
                }
                if (data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort-NPS') {
                  let obj = JSON.parse(data.Text)
                  console.log('obj upper level :', obj)
                  for (let i = 0; i < input.length; i++) {
                  const data = input[i]
                    // part 4 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        console.log('obj lower level :', obj)
                        for (const res of obj) {
                            output += `
                            //individual array
                                let array`+ res.Elements.Trait +`_Self = []
                                let array`+ res.Elements.Trait +`_PSup = []
                                let array`+ res.Elements.Trait +`_P = []
                                let array`+ res.Elements.Trait +`_D = []
                                let array`+ res.Elements.Trait +`_I = []
                                let array`+ res.Elements.Trait +`_E = []
                                let array`+ res.Elements.Trait +`_PD = []
                                let array`+ res.Elements.Trait +`_IE = []
                                let array`+ res.Elements.Trait +`_PDIE = []
                                let array`+ res.Elements.Trait +`_Others = []
                                //cohort array
                                let arrayCohort`+ res.Elements.Trait +`_Self = []
                                let arrayCohort`+ res.Elements.Trait +`_PSup = []
                                let arrayCohort`+ res.Elements.Trait +`_P = []
                                let arrayCohort`+ res.Elements.Trait +`_D = []
                                let arrayCohort`+ res.Elements.Trait +`_I = []
                                let arrayCohort`+ res.Elements.Trait +`_E = []
                                let arrayCohort`+ res.Elements.Trait +`_PD = []
                                let arrayCohort`+ res.Elements.Trait +`_IE = []
                                let arrayCohort`+ res.Elements.Trait +`_PDIE = []
                                let arrayCohort`+ res.Elements.Trait +`_Others = []

                                // score 1
                                let score1`+ res.Elements.Trait +`_Self = 0
                                let score1`+ res.Elements.Trait +`_PSup = 0
                                let score1`+ res.Elements.Trait +`_P = 0
                                let score1`+ res.Elements.Trait +`_D = 0
                                let score1`+ res.Elements.Trait +`_I = 0
                                let score1`+ res.Elements.Trait +`_E = 0
                                let score1`+ res.Elements.Trait +`_PD = 0
                                let score1`+ res.Elements.Trait +`_IE = 0
                                let score1`+ res.Elements.Trait +`_PDIE = 0
                                let score1`+ res.Elements.Trait +`_Others = 0
                                // score 2
                                let score2`+ res.Elements.Trait +`_Self = 0
                                let score2`+ res.Elements.Trait +`_PSup = 0
                                let score2`+ res.Elements.Trait +`_P = 0
                                let score2`+ res.Elements.Trait +`_D = 0
                                let score2`+ res.Elements.Trait +`_I = 0
                                let score2`+ res.Elements.Trait +`_E = 0
                                let score2`+ res.Elements.Trait +`_PD = 0
                                let score2`+ res.Elements.Trait +`_IE = 0
                                let score2`+ res.Elements.Trait +`_PDIE = 0
                                let score2`+ res.Elements.Trait +`_Others = 0
                                // score 3
                                let score3`+ res.Elements.Trait +`_Self = 0
                                let score3`+ res.Elements.Trait +`_PSup = 0
                                let score3`+ res.Elements.Trait +`_P = 0
                                let score3`+ res.Elements.Trait +`_D = 0
                                let score3`+ res.Elements.Trait +`_I = 0
                                let score3`+ res.Elements.Trait +`_E = 0
                                let score3`+ res.Elements.Trait +`_PD = 0
                                let score3`+ res.Elements.Trait +`_IE = 0
                                let score3`+ res.Elements.Trait +`_PDIE = 0
                                let score3`+ res.Elements.Trait +`_Others = 0
                                // score 4
                                let score4`+ res.Elements.Trait +`_Self = 0
                                let score4`+ res.Elements.Trait +`_PSup = 0
                                let score4`+ res.Elements.Trait +`_P = 0
                                let score4`+ res.Elements.Trait +`_D = 0
                                let score4`+ res.Elements.Trait +`_I = 0
                                let score4`+ res.Elements.Trait +`_E = 0
                                let score4`+ res.Elements.Trait +`_PD = 0
                                let score4`+ res.Elements.Trait +`_IE = 0
                                let score4`+ res.Elements.Trait +`_PDIE = 0
                                let score4`+ res.Elements.Trait +`_Others = 0
                                // score 5
                                let score5`+ res.Elements.Trait +`_Self = 0
                                let score5`+ res.Elements.Trait +`_PSup = 0
                                let score5`+ res.Elements.Trait +`_P = 0
                                let score5`+ res.Elements.Trait +`_D = 0
                                let score5`+ res.Elements.Trait +`_I = 0
                                let score5`+ res.Elements.Trait +`_E = 0
                                let score5`+ res.Elements.Trait +`_PD = 0
                                let score5`+ res.Elements.Trait +`_IE = 0
                                let score5`+ res.Elements.Trait +`_PDIE = 0
                                let score5`+ res.Elements.Trait +`_Others = 0
                                

                                // cohort score 1
                                let cohortScore1`+ res.Elements.Trait +`_Self = 0
                                let cohortScore1`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore1`+ res.Elements.Trait +`_P = 0
                                let cohortScore1`+ res.Elements.Trait +`_D = 0
                                let cohortScore1`+ res.Elements.Trait +`_I = 0
                                let cohortScore1`+ res.Elements.Trait +`_E = 0
                                let cohortScore1`+ res.Elements.Trait +`_PD = 0
                                let cohortScore1`+ res.Elements.Trait +`_IE = 0
                                let cohortScore1`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore1`+ res.Elements.Trait +`_Others = 0
                                // cohort score 2
                                let cohortScore2`+ res.Elements.Trait +`_Self = 0
                                let cohortScore2`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore2`+ res.Elements.Trait +`_P = 0
                                let cohortScore2`+ res.Elements.Trait +`_D = 0
                                let cohortScore2`+ res.Elements.Trait +`_I = 0
                                let cohortScore2`+ res.Elements.Trait +`_E = 0
                                let cohortScore2`+ res.Elements.Trait +`_PD = 0
                                let cohortScore2`+ res.Elements.Trait +`_IE = 0
                                let cohortScore2`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore2`+ res.Elements.Trait +`_Others = 0
                                // cohort score 3
                                let cohortScore3`+ res.Elements.Trait +`_Self = 0
                                let cohortScore3`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore3`+ res.Elements.Trait +`_P = 0
                                let cohortScore3`+ res.Elements.Trait +`_D = 0
                                let cohortScore3`+ res.Elements.Trait +`_I = 0
                                let cohortScore3`+ res.Elements.Trait +`_E = 0
                                let cohortScore3`+ res.Elements.Trait +`_PD = 0
                                let cohortScore3`+ res.Elements.Trait +`_IE = 0
                                let cohortScore3`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore3`+ res.Elements.Trait +`_Others = 0
                                // cohort score 4
                                let cohortScore4`+ res.Elements.Trait +`_Self = 0
                                let cohortScore4`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore4`+ res.Elements.Trait +`_P = 0
                                let cohortScore4`+ res.Elements.Trait +`_D = 0
                                let cohortScore4`+ res.Elements.Trait +`_I = 0
                                let cohortScore4`+ res.Elements.Trait +`_E = 0
                                let cohortScore4`+ res.Elements.Trait +`_PD = 0
                                let cohortScore4`+ res.Elements.Trait +`_IE = 0
                                let cohortScore4`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore4`+ res.Elements.Trait +`_Others = 0
                                // cohort score 5
                                let cohortScore5`+ res.Elements.Trait +`_Self = 0
                                let cohortScore5`+ res.Elements.Trait +`_PSup = 0
                                let cohortScore5`+ res.Elements.Trait +`_P = 0
                                let cohortScore5`+ res.Elements.Trait +`_D = 0
                                let cohortScore5`+ res.Elements.Trait +`_I = 0
                                let cohortScore5`+ res.Elements.Trait +`_E = 0
                                let cohortScore5`+ res.Elements.Trait +`_PD = 0
                                let cohortScore5`+ res.Elements.Trait +`_IE = 0
                                let cohortScore5`+ res.Elements.Trait +`_PDIE = 0
                                let cohortScore5`+ res.Elements.Trait +`_Others = 0

                                for (let i=0; i<resHistogram.data.length; i++){
                                  // get the score 1
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score1`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 1 || resHistogram.data[i].score == 2)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore1`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 2
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score2`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 3 || resHistogram.data[i].score == 4)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore2`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 3
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score3`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 5 || resHistogram.data[i].score == 6)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore3`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 4
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score4`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 7 || resHistogram.data[i].score == 8)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore4`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                  // get the score 5
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Self += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Self = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PSup += resHistogram.data[i].qty
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PSup = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_P += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_P = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_D += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_D = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_I += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_I = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_E += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_E = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PD += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PD = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_IE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_IE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_PDIE += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_PDIE = resHistogram.data[i].qty
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    score5`+ res.Elements.Trait +`_Others += resHistogram.data[i].qty
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`' && (resHistogram.data[i].score == 9 || resHistogram.data[i].score == 10)) && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    cohortScore5`+ res.Elements.Trait +`_Others = resHistogram.data[i].qty
                                  }

                                //put data into array
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 0 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Self.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  } if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 1 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PSup.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 2 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_P.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 3 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_D.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 4 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_I.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 5 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_E.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 996 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PD.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 997 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_IE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 998 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_PDIE.push(resHistogram.data[i].qty)
                                  }
                                  if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id > 0)) {
                                    array`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }if((resHistogram.data[i].element === '`+ res.Elements.Trait +`') && (resHistogram.data[i].relationship_id == 999 && resHistogram.data[i].survey_assignment_id == 0)){
                                    arrayCohort`+ res.Elements.Trait +`_Others.push(resHistogram.data[i].qty)
                                  }
                                }

                                // add numbers in 1 row for ranking 0 to 100%
                                array`+ res.Elements.Trait +`_Self.push(score1`+ res.Elements.Trait +`_Self+score2`+ res.Elements.Trait +`_Self+score3`+ res.Elements.Trait +`_Self+score4`+ res.Elements.Trait +`_Self+score5`+ res.Elements.Trait +`_Self)
                                array`+ res.Elements.Trait +`_PSup.push(score1`+ res.Elements.Trait +`_PSup+score2`+ res.Elements.Trait +`_PSup+score3`+ res.Elements.Trait +`_PSup+score4`+ res.Elements.Trait +`_PSup+score5`+ res.Elements.Trait +`_PSup)
                                array`+ res.Elements.Trait +`_P.push(score1`+ res.Elements.Trait +`_P+score2`+ res.Elements.Trait +`_P+score3`+ res.Elements.Trait +`_P+score4`+ res.Elements.Trait +`_P+score5`+ res.Elements.Trait +`_P)
                                array`+ res.Elements.Trait +`_D.push(score1`+ res.Elements.Trait +`_D+score2`+ res.Elements.Trait +`_D+score3`+ res.Elements.Trait +`_D+score4`+ res.Elements.Trait +`_D+score5`+ res.Elements.Trait +`_D)
                                array`+ res.Elements.Trait +`_I.push(score1`+ res.Elements.Trait +`_I+score2`+ res.Elements.Trait +`_I+score3`+ res.Elements.Trait +`_I+score4`+ res.Elements.Trait +`_I+score5`+ res.Elements.Trait +`_I)
                                array`+ res.Elements.Trait +`_E.push(score1`+ res.Elements.Trait +`_E+score2`+ res.Elements.Trait +`_E+score3`+ res.Elements.Trait +`_E+score4`+ res.Elements.Trait +`_E+score5`+ res.Elements.Trait +`_E)
                                array`+ res.Elements.Trait +`_PD.push(score1`+ res.Elements.Trait +`_PD+score2`+ res.Elements.Trait +`_PD+score3`+ res.Elements.Trait +`_PD+score4`+ res.Elements.Trait +`_PD+score5`+ res.Elements.Trait +`_PD)
                                array`+ res.Elements.Trait +`_IE.push(score1`+ res.Elements.Trait +`_IE+score2`+ res.Elements.Trait +`_IE+score3`+ res.Elements.Trait +`_IE+score4`+ res.Elements.Trait +`_IE+score5`+ res.Elements.Trait +`_IE)
                                array`+ res.Elements.Trait +`_PDIE.push(score1`+ res.Elements.Trait +`_PDIE+score2`+ res.Elements.Trait +`_PDIE+score3`+ res.Elements.Trait +`_PDIE+score4`+ res.Elements.Trait +`_PDIE+score5`+ res.Elements.Trait +`_PDIE)
                                array`+ res.Elements.Trait +`_Others.push(score1`+ res.Elements.Trait +`_Others+score2`+ res.Elements.Trait +`_Others+score3`+ res.Elements.Trait +`_Others+score4`+ res.Elements.Trait +`_Others+score5`+ res.Elements.Trait +`_Others)
                                //cohort
                                arrayCohort`+ res.Elements.Trait +`_Self.push(cohortScore1`+ res.Elements.Trait +`_Self+cohortScore2`+ res.Elements.Trait +`_Self+cohortScore3`+ res.Elements.Trait +`_Self+cohortScore4`+ res.Elements.Trait +`_Self+cohortScore5`+ res.Elements.Trait +`_Self)
                                arrayCohort`+ res.Elements.Trait +`_PSup.push(cohortScore1`+ res.Elements.Trait +`_PSup+cohortScore2`+ res.Elements.Trait +`_PSup+cohortScore3`+ res.Elements.Trait +`_PSup+cohortScore4`+ res.Elements.Trait +`_PSup+cohortScore5`+ res.Elements.Trait +`_PSup)
                                arrayCohort`+ res.Elements.Trait +`_P.push(cohortScore1`+ res.Elements.Trait +`_P+cohortScore2`+ res.Elements.Trait +`_P+cohortScore3`+ res.Elements.Trait +`_P+cohortScore4`+ res.Elements.Trait +`_P+cohortScore5`+ res.Elements.Trait +`_P)
                                arrayCohort`+ res.Elements.Trait +`_D.push(cohortScore1`+ res.Elements.Trait +`_D+cohortScore2`+ res.Elements.Trait +`_D+cohortScore3`+ res.Elements.Trait +`_D+cohortScore4`+ res.Elements.Trait +`_D+cohortScore5`+ res.Elements.Trait +`_D)
                                arrayCohort`+ res.Elements.Trait +`_I.push(cohortScore1`+ res.Elements.Trait +`_I+cohortScore2`+ res.Elements.Trait +`_I+cohortScore3`+ res.Elements.Trait +`_I+cohortScore4`+ res.Elements.Trait +`_I+cohortScore5`+ res.Elements.Trait +`_I)
                                arrayCohort`+ res.Elements.Trait +`_E.push(cohortScore1`+ res.Elements.Trait +`_E+cohortScore2`+ res.Elements.Trait +`_E+cohortScore3`+ res.Elements.Trait +`_E+cohortScore4`+ res.Elements.Trait +`_E+cohortScore5`+ res.Elements.Trait +`_E)
                                arrayCohort`+ res.Elements.Trait +`_PD.push(cohortScore1`+ res.Elements.Trait +`_PD+cohortScore2`+ res.Elements.Trait +`_PD+cohortScore3`+ res.Elements.Trait +`_PD+cohortScore4`+ res.Elements.Trait +`_PD+cohortScore5`+ res.Elements.Trait +`_PD)
                                arrayCohort`+ res.Elements.Trait +`_IE.push(cohortScore1`+ res.Elements.Trait +`_IE+cohortScore2`+ res.Elements.Trait +`_IE+cohortScore3`+ res.Elements.Trait +`_IE+cohortScore4`+ res.Elements.Trait +`_IE+cohortScore5`+ res.Elements.Trait +`_IE)
                                arrayCohort`+ res.Elements.Trait +`_PDIE.push(cohortScore1`+ res.Elements.Trait +`_PDIE+cohortScore2`+ res.Elements.Trait +`_PDIE+cohortScore3`+ res.Elements.Trait +`_PDIE+cohortScore4`+ res.Elements.Trait +`_PDIE+cohortScore5`+ res.Elements.Trait +`_PDIE)
                                arrayCohort`+ res.Elements.Trait +`_Others.push(cohortScore1`+ res.Elements.Trait +`_Others+cohortScore2`+ res.Elements.Trait +`_Others+cohortScore3`+ res.Elements.Trait +`_Others+cohortScore4`+ res.Elements.Trait +`_Others+cohortScore5`+ res.Elements.Trait +`_Others)
                            `
                        }
                      }
                      // part 5 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `
                            // for Rolled-up Total
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                            
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)

                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)
                                
                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PD + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PD + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PD + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PD + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PD + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)
                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                
                              //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 2 compute average per score
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_PD)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_IE + score1`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_IE + score2`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_IE + score3`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_IE + score4`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_IE + score5`+ res.Elements.Trait +`_PD)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }
                              
                              if (isDisplay_PDIE === 1) {
                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_PDIE)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');


                                
                                }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){

                                let arrayMain_`+ res.Elements.Trait +` = []

                                  let numMainscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_Self + cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`)
                                
                                  let numMainscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_Self + cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore2_`+ res.Elements.Trait +`)
                                
                                  let numMainscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_Self + cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore3_`+ res.Elements.Trait +`)
                                
                                  let numMainscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_Self + cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore4_`+ res.Elements.Trait +`)
                                
                                  let numMainscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_Self + cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore5_`+ res.Elements.Trait +`)
                                
                                  arrayMain_`+ res.Elements.Trait +`.push(numMainscore1_`+ res.Elements.Trait +`+ numMainscore2_`+ res.Elements.Trait +`+ numMainscore3_`+ res.Elements.Trait +`+ numMainscore4_`+ res.Elements.Trait +`+ numMainscore5_`+ res.Elements.Trait +`)

                              //section 3-8 compute average per score
                                arraySub_`+ res.Elements.Trait +` = []

                                  let numSubscore1_`+ res.Elements.Trait +` = (score1`+ res.Elements.Trait +`_PSup + score1`+ res.Elements.Trait +`_P + score1`+ res.Elements.Trait +`_D + score1`+ res.Elements.Trait +`_I + score1`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`)
                                
                                  let numSubscore2_`+ res.Elements.Trait +` = (score2`+ res.Elements.Trait +`_PSup + score2`+ res.Elements.Trait +`_P + score2`+ res.Elements.Trait +`_D + score2`+ res.Elements.Trait +`_I + score2`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore2_`+ res.Elements.Trait +`)
                                
                                  let numSubscore3_`+ res.Elements.Trait +` = (score3`+ res.Elements.Trait +`_PSup + score3`+ res.Elements.Trait +`_P + score3`+ res.Elements.Trait +`_D + score3`+ res.Elements.Trait +`_I + score3`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore3_`+ res.Elements.Trait +`)
                                
                                  let numSubscore4_`+ res.Elements.Trait +` = (score4`+ res.Elements.Trait +`_PSup + score4`+ res.Elements.Trait +`_P + score4`+ res.Elements.Trait +`_D + score4`+ res.Elements.Trait +`_I + score4`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore4_`+ res.Elements.Trait +`)
                                
                                  let numSubscore5_`+ res.Elements.Trait +` = (score5`+ res.Elements.Trait +`_PSup + score5`+ res.Elements.Trait +`_P + score5`+ res.Elements.Trait +`_D + score5`+ res.Elements.Trait +`_I + score5`+ res.Elements.Trait +`_E)
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore5_`+ res.Elements.Trait +`)
                                
                                  arraySub_`+ res.Elements.Trait +`.push(numSubscore1_`+ res.Elements.Trait +`+ numSubscore2_`+ res.Elements.Trait +`+ numSubscore3_`+ res.Elements.Trait +`+ numSubscore4_`+ res.Elements.Trait +`+ numSubscore5_`+ res.Elements.Trait +`)

                              //rank computed here section 2
                                let histogramScore1_`+ res.Elements.Trait +`_Head = rankRange(numMainscore1_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Head = rankRange(numMainscore2_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Head = rankRange(numMainscore3_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Head = rankRange(numMainscore4_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Head = rankRange(numMainscore5_`+ res.Elements.Trait +`, arrayMain_`+ res.Elements.Trait +`)
                              //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-head").css('height', histogramScore1_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-head").css('height', histogramScore2_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-head").css('height', histogramScore3_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-head").css('height', histogramScore4_`+ res.Elements.Trait +`_Head +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-head").css('height', histogramScore5_`+ res.Elements.Trait +`_Head +'%');
                              //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore1_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore2_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore3_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore4_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_Sub = rankRange(numSubscore5_`+ res.Elements.Trait +`, arraySub_`+ res.Elements.Trait +`)
                              //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_Sub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_Sub +'%');
                                
                              }
                            `
                        }
                      }
                      // part 5.1 of Histogram
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self
                                let histogramScore1_`+ res.Elements.Trait +`_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_Self +'%');
                                //self section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Self = rankRange(score1`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Self = rankRange(score2`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Self = rankRange(score3`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Self = rankRange(score4`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Self = rankRange(score5`+ res.Elements.Trait +`_Self, array`+ res.Elements.Trait +`_Self)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Self +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Self +'%');

                                //psup section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_PSup +'%');
                                //psup section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PSup = rankRange(score1`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PSup = rankRange(score2`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PSup = rankRange(score3`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PSup = rankRange(score4`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PSup = rankRange(score5`+ res.Elements.Trait +`_PSup, array`+ res.Elements.Trait +`_PSup)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PSup +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PSup +'%');

                                //peers section 2
                                let histogramScore1_`+ res.Elements.Trait +`_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-P").css('height', histogramScore1_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-P").css('height', histogramScore2_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-P").css('height', histogramScore3_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-P").css('height', histogramScore4_`+ res.Elements.Trait +`_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-P").css('height', histogramScore5_`+ res.Elements.Trait +`_P +'%');
                                //peers section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_P = rankRange(score1`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_P = rankRange(score2`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_P = rankRange(score3`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_P = rankRange(score4`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_P = rankRange(score5`+ res.Elements.Trait +`_P, array`+ res.Elements.Trait +`_P)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_P +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_P +'%');

                                //direct reports section 2
                                let histogramScore1_`+ res.Elements.Trait +`_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-D").css('height', histogramScore1_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-D").css('height', histogramScore2_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-D").css('height', histogramScore3_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-D").css('height', histogramScore4_`+ res.Elements.Trait +`_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-D").css('height', histogramScore5_`+ res.Elements.Trait +`_D +'%');
                                //direct reports section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_D = rankRange(score1`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_D = rankRange(score2`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_D = rankRange(score3`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_D = rankRange(score4`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_D = rankRange(score5`+ res.Elements.Trait +`_D, array`+ res.Elements.Trait +`_D)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_D +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_D +'%');
                                
                                //internal stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-I").css('height', histogramScore1_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-I").css('height', histogramScore2_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-I").css('height', histogramScore3_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-I").css('height', histogramScore4_`+ res.Elements.Trait +`_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-I").css('height', histogramScore5_`+ res.Elements.Trait +`_I +'%');
                                //internal stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_I = rankRange(score1`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_I = rankRange(score2`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_I = rankRange(score3`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_I = rankRange(score4`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_I = rankRange(score5`+ res.Elements.Trait +`_I, array`+ res.Elements.Trait +`_I)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_I +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_I +'%');

                                //external stakeholders section 2
                                let histogramScore1_`+ res.Elements.Trait +`_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-E").css('height', histogramScore1_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-E").css('height', histogramScore2_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-E").css('height', histogramScore3_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-E").css('height', histogramScore4_`+ res.Elements.Trait +`_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-E").css('height', histogramScore5_`+ res.Elements.Trait +`_E +'%');
                                //external stakeholders section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_E = rankRange(score1`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_E = rankRange(score2`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_E = rankRange(score3`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_E = rankRange(score4`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_E = rankRange(score5`+ res.Elements.Trait +`_E, array`+ res.Elements.Trait +`_E)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_E +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_E +'%');

                                
                                //peers/direct section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_PD +'%');
                                //peers/direct section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PD = rankRange(score1`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PD = rankRange(score2`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PD = rankRange(score3`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PD = rankRange(score4`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PD = rankRange(score5`+ res.Elements.Trait +`_PD, array`+ res.Elements.Trait +`_PD)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PD +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PD +'%');

                                //internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_IE +'%');
                                //internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_IE = rankRange(score1`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_IE = rankRange(score2`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_IE = rankRange(score3`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_IE = rankRange(score4`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_IE = rankRange(score5`+ res.Elements.Trait +`_IE, array`+ res.Elements.Trait +`_IE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_IE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_IE +'%');

                                //peers/direct/internal/external section 2
                                let histogramScore1_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                                //section 2
                                $(".histogram-score1-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_PDIE +'%');
                                //peers/direct/internal/external section 3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score1`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score2`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score3`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score4`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_PDIE = rankRange(score5`+ res.Elements.Trait +`_PDIE, array`+ res.Elements.Trait +`_PDIE)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_PDIE +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_PDIE +'%');

                                //All Nominees section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_sub_Others = rankRange(score1`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore2_`+ res.Elements.Trait +`_sub_Others = rankRange(score2`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore3_`+ res.Elements.Trait +`_sub_Others = rankRange(score3`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore4_`+ res.Elements.Trait +`_sub_Others = rankRange(score4`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                let histogramScore5_`+ res.Elements.Trait +`_sub_Others = rankRange(score5`+ res.Elements.Trait +`_Others, array`+ res.Elements.Trait +`_Others)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore1_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore2_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore3_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore4_`+ res.Elements.Trait +`_sub_Others +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-sub-Others").css('height', histogramScore5_`+ res.Elements.Trait +`_sub_Others +'%');
                                
                              

                            `
                        }
                      }
                      // part 6 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            // for Rolled-up Total Cohort
                              if(isDisplay_PD === 1 && isDisplay_IE === 0){
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PD + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PD + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PD + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PD + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PD + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }
                              else if (isDisplay_IE === 1 && isDisplay_PD === 1 && isDisplay_PDIE === 0) {
                                //section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_IE + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_IE + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_IE + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_IE + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_IE + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_PD)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                //rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }
                              
                              if (isDisplay_PDIE === 1) {
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_PDIE)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');
                              
                              }

                              else if(isDisplay_P === 1 && isDisplay_D === 1 && isDisplay_I === 1 && isDisplay_E === 1){
                                // section 3-8 compute average per COHORT score
                                cohortArraySub_`+ res.Elements.Trait +` = []

                                let cohortNumSubscore1_`+ res.Elements.Trait +` = (cohortScore1`+ res.Elements.Trait +`_PSup + cohortScore1`+ res.Elements.Trait +`_P + cohortScore1`+ res.Elements.Trait +`_D + cohortScore1`+ res.Elements.Trait +`_I + cohortScore1`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore2_`+ res.Elements.Trait +` = (cohortScore2`+ res.Elements.Trait +`_PSup + cohortScore2`+ res.Elements.Trait +`_P + cohortScore2`+ res.Elements.Trait +`_D + cohortScore2`+ res.Elements.Trait +`_I + cohortScore2`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore2_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore3_`+ res.Elements.Trait +` = (cohortScore3`+ res.Elements.Trait +`_PSup + cohortScore3`+ res.Elements.Trait +`_P + cohortScore3`+ res.Elements.Trait +`_D + cohortScore3`+ res.Elements.Trait +`_I + cohortScore3`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore3_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore4_`+ res.Elements.Trait +` = (cohortScore4`+ res.Elements.Trait +`_PSup + cohortScore4`+ res.Elements.Trait +`_P + cohortScore4`+ res.Elements.Trait +`_D + cohortScore4`+ res.Elements.Trait +`_I + cohortScore4`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore4_`+ res.Elements.Trait +`)
                              
                                let cohortNumSubscore5_`+ res.Elements.Trait +` = (cohortScore5`+ res.Elements.Trait +`_PSup + cohortScore5`+ res.Elements.Trait +`_P + cohortScore5`+ res.Elements.Trait +`_D + cohortScore5`+ res.Elements.Trait +`_I + cohortScore5`+ res.Elements.Trait +`_E)
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore5_`+ res.Elements.Trait +`)
                              
                                cohortArraySub_`+ res.Elements.Trait +`.push(cohortNumSubscore1_`+ res.Elements.Trait +`+ cohortNumSubscore2_`+ res.Elements.Trait +`+ cohortNumSubscore3_`+ res.Elements.Trait +`+ cohortNumSubscore4_`+ res.Elements.Trait +`+ cohortNumSubscore5_`+ res.Elements.Trait +`)
                            
                                // rank computed here section3-8 COHORT
                                let histogramScore1_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore1_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore2_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore2_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore3_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore3_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore4_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore4_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                let histogramScore5_`+ res.Elements.Trait +`_CohortSub = rankRange(cohortNumSubscore5_`+ res.Elements.Trait +`, cohortArraySub_`+ res.Elements.Trait +`)
                                //section3-8
                                $(".histogram-score1-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore1_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score2-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore2_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score3-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore3_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score4-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore4_`+ res.Elements.Trait +`_CohortSub +'%');
                                $(".histogram-score5-`+ res.Elements.Trait +`-cohortSub-Total").css('height', histogramScore5_`+ res.Elements.Trait +`_CohortSub +'%');

                              }

                            `
                        }
                      }
                      // part 6.1 of Histogram COHORT DATA 
                      if (data.Template === 'Add-Histogram') { 
                        for (const res of obj) {
                            output += `

                            //self section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore1`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore2`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore3`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore4`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self = rankRange(cohortScore5`+ res.Elements.Trait +`_Self, arrayCohort`+ res.Elements.Trait +`_Self)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_Self +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Self").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_Self +'%');

                              //psup section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore1`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore2`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore3`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore4`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup = rankRange(cohortScore5`+ res.Elements.Trait +`_PSup, arrayCohort`+ res.Elements.Trait +`_PSup)
                              //section3-8 
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PSup +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-Psup").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PSup +'%');

                              //peers section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore1`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore2`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore3`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore4`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_P = rankRange(cohortScore5`+ res.Elements.Trait +`_P, arrayCohort`+ res.Elements.Trait +`_P)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_P +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-P").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_P +'%');

                              //direct reports section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore1`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore2`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore3`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore4`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_D = rankRange(cohortScore5`+ res.Elements.Trait +`_D, arrayCohort`+ res.Elements.Trait +`_D)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_D +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-D").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_D +'%');

                              //internal stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore1`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore2`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore3`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore4`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_I = rankRange(cohortScore5`+ res.Elements.Trait +`_I, arrayCohort`+ res.Elements.Trait +`_I)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_I +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-I").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_I +'%');

                              //external stakeholders section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore1`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore2`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore3`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore4`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_E = rankRange(cohortScore5`+ res.Elements.Trait +`_E, arrayCohort`+ res.Elements.Trait +`_E)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_E +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-E").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_E +'%');

                              //peers/direct section 3-8 cohort
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore1`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore2`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore3`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore4`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD = rankRange(cohortScore5`+ res.Elements.Trait +`_PD, arrayCohort`+ res.Elements.Trait +`_PD)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PD +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PD").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PD +'%');

                              //internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore1`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore2`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore3`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore4`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE = rankRange(cohortScore5`+ res.Elements.Trait +`_IE, arrayCohort`+ res.Elements.Trait +`_IE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_IE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-IE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_IE +'%');

                              //peers/direct/internal/external section 3-8
                              let histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore1`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore2`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore3`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore4`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              let histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE = rankRange(cohortScore5`+ res.Elements.Trait +`_PDIE, arrayCohort`+ res.Elements.Trait +`_PDIE)
                              //section3-8
                              $(".histogram-score1-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore1_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score2-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore2_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score3-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore3_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score4-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore4_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');
                              $(".histogram-score5-`+ res.Elements.Trait +`-cohortsub-PDIE").css('height', histogramScore5_`+ res.Elements.Trait +`_cohortSub_PDIE +'%');



                            `   
                        }
                      }
                  }
                }
              }
              output += `
              })
              .catch((err) => {
                console.log(err)
              })
            `
          }
          output += `
          })
          .catch((err) => {
            console.log(err)
          })
          `
        }
      }
      // hide the histogram and change the alignment of the column and rows in the table
      for(let i = 0; i < input.length; i++){
        const data = input[i];

        if(data.Template === 'Without-Histogram'){
          output += `
            $('.histo-hide-show').addClass('d-none')
            $('.slider-pr').css('padding-right', '50px')
          `

          for(let i = 0; i < input.length; i++){
            const data = input[i];
            if(data.Template === 'Bar-Chart-New-360-Sub') {
              output +=`
                $('.td-slider-width-with-others-cohort').css('width','30%')
              `
            }
            if(data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others') {
              output +=`
                $('.th-slider-colspan-without-cohort-others').attr('colspan','2')
                $('.td-slider-colspan-without-cohort-others').attr('colspan','2')
                $('.td-slider-colspan-without-cohort-others').css('padding-right','50px')

                $('.th-barchart-colspan-without-cohort-others').attr('colspan','2')
              `
            }
            if(data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others') {
              output +=`
                $('.th-slider-colspan-with-cohort-no-others').attr('colspan','2')
                $('.td-slider-colspan-with-cohort-no-others').attr('colspan','2')
                $('.td-slider-colspan-with-cohort-no-others').css('padding-right','50px')
              `
            }
            if(data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort') {
              output +=`
                $('.th-barchart-colspan-with-others-no-cohort').attr('colspan','2')
                $('.td-slider-width-with-others-no-cohort').css('width','30%')
              `
            }

                        //setion for multiple nps
            if(data.Template === 'Bar-Chart-New-360-Sub-NPS') {
              output +=`
                $('.td-slider-width-with-others-cohort').css('width','30%')
              `
            }

            if(data.Template === 'Bar-Chart-New-360-Sub-Without-Cohort-And-Others-NPS') {
              output +=`
                $('.th-slider-colspan-without-cohort-others').attr('colspan','2')
                $('.td-slider-colspan-without-cohort-others').attr('colspan','2')
                $('.td-slider-colspan-without-cohort-others').css('padding-right','50px')

                $('.th-barchart-colspan-without-cohort-others').attr('colspan','2')
              `
            }
            if(data.Template === 'Bar-Chart-New-360-Sub-With-Cohort-And-No-Others-NPS') {
              output +=`
                $('.th-slider-colspan-with-cohort-no-others').attr('colspan','2')
                $('.td-slider-colspan-with-cohort-no-others').attr('colspan','2')
                $('.td-slider-colspan-with-cohort-no-others').css('padding-right','50px')
              `
            }
            if(data.Template === 'Bar-Chart-New-360-Sub-With-Others-And-No-Cohort-NPS') {
              output +=`
                $('.th-barchart-colspan-with-others-no-cohort').attr('colspan','2')
                $('.td-slider-width-with-others-no-cohort').css('width','30%')
              `
            }
          }
        }
      }

      // hide the Net Promoter Score if not selected
      for(let i = 0; i < input.length; i++){
        const data = input[i];

        if(data.Template === 'Without-NPS'){
          output += `
            console.log("hide NPS")
            $('.npsData').addClass('d-none')
          `
        }
      }

      output += `
        })
      `
      return output;
    }, //end of addJS

  }, //end of methods
};
</script>


<style scoped>

</style>
