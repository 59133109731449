<script setup>
import MakeNominationsBox from "./MakeNominationsBox.vue";
</script>

<template>
    <div class="body-container">
        <MakeNominationsBox :userData="userData" :brandData="brandData"/>
    </div>
</template>

<script>
export default {
    component: [MakeNominationsBox],
    props: {
        userData: Object,
        brandData: Object,
    },
    data: () => ({
    }),
    
}
</script>

<style scoped>
.body-container {
  background: white;
  margin: 20px;
  padding: 40px;
  box-shadow: 0px 2px 10px -4px #000000;
  border-radius: 20px;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin:25px;
  width:95%;
}
</style>
