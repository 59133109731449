<template>
    <div>
        <span class="font-size-14 font-bold">Instructions for nominating respondents</span>

        <p class="font-size-14">Nominate between 9 and 15 people who work with you to complete this survey, to evaluate how you work with other people. Don&rsquo;t forget to complete your own 360 self assessment too, the link is on the survey tile.</p>

        <p class="font-size-14">Using the categories listed below, invite your nominees to complete this survey by reaching out to them as soon as possible to confirm their:</p>
        
        <div>
            <ul>
                <li class="font-size-13">willingness to participate in the survey.</li>
                <li class="font-size-13">permission to share their email address with us.</li>
                <li class="font-size-13">understanding that all respondents are guaranteed confidentiality, with the exception of your primary supervisor.</li>
            </ul>
        </div>

        <p class="font-size-14">Remember the 360 is your best tool for getting feedback on your leadership style from those who work with you. If you aren't currently employed, feel free to ask others from previous jobs. You can also add a personal message when entering their details below.</p>

        <span class="font-size-14 font-bold">Nominee categories</span>

        <ol>
            <li class="font-size-13"><b> Primary Supervisor</b> <em class="font-size-13">Nominate at least 1 person.</em><br /> Someone with power or authority over you. Examples of these include: Line Manager / Boss / Mentor / Board members / Business Partners, etc.</li>
            <li class="font-size-13"><b> Peers</b> <em class="font-size-13">Nominate at least 2 people.<br /> </em>People you work with or have worked alongside you at the same level as you.</li>
            <li class="font-size-13"><b> Direct Reports</b> <em class="font-size-13">Nominate at least 2 people.<br /> </em>People who work for you, or you have power or authority over. Examples of these include: Team members / Secretary / Assistant / Consultants / Analysts, etc.</li>
            <li class="font-size-13"><b> Internal Stakeholders</b> <em class="font-size-13">Nominate at least 2 people.<br /> </em>Colleagues in your current organization not included above. Examples of these include people from: Other functions / Business lines / Business units / Head or Regional office, etc.</li>
            <li class="font-size-13"><b> External Stakeholders</b> <em class="font-size-13">Nominate at least 2 people.<br /> </em>People who work outside of your current organization. Examples of these include: Clients / Suppliers / Strategic partners / Business partners / Public officials, etc.</li>
        </ol>

        <span class="font-size-14 font-bold">Your report data</span>
        <p class="font-size-14">Two responses per category (from nominees) are required to break out the data for that category (avoid being combined). Single responses to any one category must be &lsquo;rolled up&rsquo; into a larger group to avoid violating the confidentiality promise made to that nominee.</p>
        <p class="font-size-14 text-blue">HINT! NOMINATE MORE THAN 2 PEOPLE PER CATEGORY WHEREVER POSSIBLE to increase the likelihood of having at least two people from each category in your report.</p>
    </div>
</template>

<script>
    export default {
        data: () => ({

        })
    }
</script>

<style scoped>
    .font-size-14 {
        font-size: 14px;
    }
    .font-size-13 {
        font-size: 13px;
    }
    .font-bold {
        font-weight: bold;
    }
    .text-blue {
        color: #073763;
    }
</style>