/* #version=0.0.0-2#3 jb 2024-07-02T08:02:46 26657574A13A624C */
/* #version=0.0.0-2#2 jb 2024-07-02T08:02:38 7662D3B443C12A26 */
import axios from "axios";

export default axios.create({
  baseURL: 'https://be.talentsage.com/',
  // baseURL: 'https://uatbe.talentsage.com/',
  // baseURL: 'https://betest.gcm3.com/',
  // baseURL: 'http://localhost:443/',
  headers: { 'token': 'cCW7PW2CRotxuALrBuMob5lXgVhY4xo' }
});
